export enum GROUP_BY {
  DATE_RANGE = 'dateRange',
  DAY = 'day',
  MONTH = 'month',
  TIME_CATEGORY_1_ID = 'timeCategory1Id',
  TIME_CATEGORY_2_ID = 'timeCategory2Id',
  USER_ID = 'userId',
  WEEK = 'week',
  YEAR = 'year',
}
