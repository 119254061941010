import produce from 'immer';
import { createSelector } from 'reselect';
import { WorkplacesType } from '../types';

import { selectCurrentCrewId } from './crews-selectors';
import { selectUserIdFromProps } from './users-selectors';
import { findByProps, createPropsSelector, createPropsFinder } from './utils';

export const selectWorkplaces = (state: any): WorkplacesType[] =>
  (state.bo.workplaces && state.bo.workplaces.workplaces) || produce([], () => {});

export const selectWorkplaceId = (_state: any, props: any) => (props && props.workplaceId ? props.workplaceId : null);

export const selectWorkplacesForCurrentCrew = createSelector(
  selectWorkplaces,
  selectCurrentCrewId,
  (workplaces, crewId) => workplaces.filter((workplace: WorkplacesType) => workplace.crewId === crewId)
);

export const selectWorkplacesByProps = createPropsSelector(selectWorkplacesForCurrentCrew);

export const selectWorkplacesForCurrentUserAndCrew = createSelector(
  selectWorkplaces,
  selectCurrentCrewId,
  selectUserIdFromProps,
  (workplaces, crewId, userId) =>
    workplaces.filter((workplace: WorkplacesType) => workplace.crewId === crewId && workplace._sync && workplace._sync.userId === userId)
);

export const findUserWorkplace = createSelector(
  selectWorkplacesForCurrentUserAndCrew,
  selectWorkplaceId,
  (workplaces, workplaceId) => findByProps(workplaces, { id: workplaceId })
);

export const findWorkplaceByProps = createPropsFinder(selectWorkplacesForCurrentCrew);
