import { factory, random, sequence } from 'stahlwerk';

import { FOLDERS, INPUT_TYPES, SETTING_ELEMENT_TYPE } from '../constants';

export const createSettingDefinitions = factory({
  definition: {
    applicableForCrews: random([true, false]),
    applicableForMembers: random([true, false]),
    description:
      'Das Zeitkonto zu deaktivieren versteckt die Übersicht darüber, wie viel Mitarbeiter im Vergleich zur Soll-Arbeitszeit arbeiten',
    descriptionMember:
      'Das Zeitkonto zu deaktivieren versteckt die Übersicht darüber, wie viel {name} im Vergleich zur Soll-Arbeitszeit arbeiten',
    id: `setting-definition-id-${sequence()}`,
    subgroups: [
      {
        settingDefinitions: [
          {
            id: `input-id-${sequence()}`,
            type: random(Object.values(INPUT_TYPES).map(({ name }) => name)),
            label: 'Zeitkonto führen',
          },
        ],
      },
    ],
    title: 'Zeitkonto',
    versions: random([true, false]),
  },
  folder: random(Object.values(FOLDERS)),
  type: random(Object.values(SETTING_ELEMENT_TYPE)),
  language: random(['en', 'de']),
});
