import { createSelector } from 'reselect';
import {
  differenceInSeconds,
  isAfter,
  isBefore,
  addDays,
  startOfDay,
  endOfDay,
  isSameSecond,
  compareAsc,
} from 'date-fns';
import { selectors } from '@crewmeister/shared';
import { addSeconds } from 'pomeranian-durations';
import { array, stampchain } from '../utils';

import { TIME_ACCOUNTS } from '../constants';

import { blocksAsDay, createBlocks, createWorkingTimeMap } from '../containers/live-view-list/blocks'; // TODO: extract to utils
import { createMetaLabels } from '../containers/live-view-list/meta-labels';

const { groupBy } = array;
const {
  getStampsRelevantInDateRange,
  getStampsWithChainStartingInDateRange,
  makeStampChains,
  stampsForOpenStampChainsToVirtualBookings,
  sortStampsAsc,
} = stampchain;
const {
  selectEnabledMembersInCurrentCrew,
  selectMembersInCurrentCrew,
  selectStampsInCurrentCrew,
  selectStampsInCurrentCrewOnSelectedDate,
  selectCurrentUserId,
} = selectors;

const groupByUserId = (stamps) => {
  const groupedStamps = {};
  stamps.forEach((stamp) => {
    if (!groupedStamps[stamp.userId]) groupedStamps[stamp.userId] = [];
    groupedStamps[stamp.userId].push(stamp);
  });
  return groupedStamps;
};

const transformUserStamps = (userStamps, currentTime, selectedDate) => {
  const blocks = createBlocks(userStamps, currentTime, selectedDate);
  return {
    data: blocksAsDay(blocks),
    meta: createMetaLabels(userStamps, selectedDate),
  };
};

const transformGroupedStamps = (groupedStamps, currentTime, selectedDate) => {
  const transformedStamps = {};
  Object.keys(groupedStamps).forEach((userId) => {
    transformedStamps[userId] = transformUserStamps(groupedStamps[userId], currentTime, selectedDate);
  });
  return transformedStamps;
};

const addMembersWithoutStamps = (stamps, members) => {
  const additional = {};
  members.forEach((member) => {
    if (!stamps[member.userId]) additional[member.userId] = [];
  });
  return { ...stamps, ...additional };
};

export const selectStampsWithTransform = (stampsSelector) =>
  createSelector(
    stampsSelector,
    (_, props) => props.date,
    (_, props) => props.currentTime,
    selectEnabledMembersInCurrentCrew,
    (stamps, selectedDate, currentTime, members) => {
      const relevantStamps = stamps;
      const groupedStamps = addMembersWithoutStamps(groupByUserId(relevantStamps), members);
      return transformGroupedStamps(groupedStamps, currentTime.toISOString(), selectedDate.toISOString());
    }
  );

export const selectStampsInCurrentCrewOnSelectedDateWithTransformation = selectStampsWithTransform(
  selectStampsInCurrentCrewOnSelectedDate
);

export const selectDurationsUserMap = createSelector(selectStampsInCurrentCrewOnSelectedDate, (stamps) =>
  createWorkingTimeMap(groupBy('userId', stamps))
);

export const workDurationsWithinPeriod = (stamps, from, to) => {
  const ranges = {
    before: [],
    inRange: [],
    after: [],
  };
  stamps.map((stamp) => {
    if (isBefore(new Date(stamp.timestamp), new Date(from))) {
      return ranges.before.push(stamp);
    }
    if (isAfter(new Date(stamp.timestamp), new Date(to))) {
      return ranges.after.push(stamp);
    }
    return ranges.inRange.push(stamp);
  });
  return [ranges.before.pop(), ...ranges.inRange, ranges.after[0]];
};

export const parseDurationToFitGivenDates = (stamps, from, to) => {
  const length = stamps.length - 1;
  if (stamps[0] && isBefore(new Date(stamps[0].timestamp), new Date(from))) {
    /* eslint-disable no-param-reassign */
    stamps[0] = {
      ...stamps[0],
      timestamp: startOfDay(new Date(addDays(new Date(stamps[0].timestamp), 1))),
    };
  }
  if (stamps[length] && isAfter(new Date(stamps[length].timestamp), new Date(to))) {
    stamps[length] = {
      ...stamps[length],
      timestamp: endOfDay(addDays(new Date(stamps[length].timestamp), -1)),
    };
  }
  return stamps;
};

export const workDurationForStamps = (stamps) =>
  stamps.reduce((acc, currentStamp, index) => {
    const previousStamp = stamps[index - 1];
    const nextStamp = stamps[index + 1];
    if (!currentStamp) return acc;
    if (!nextStamp && currentStamp.timeAccount === TIME_ACCOUNTS.START_WORK) {
      return addSeconds(differenceInSeconds(new Date(), new Date(currentStamp.timestamp)), acc);
    }
    if (!previousStamp || previousStamp.timeAccount !== TIME_ACCOUNTS.START_WORK) {
      return acc;
    }
    return addSeconds(differenceInSeconds(new Date(currentStamp.timestamp), new Date(previousStamp.timestamp)), acc);
  }, 'P');

export const selectStampsInCurrentCrewForUserId = createSelector(
  selectStampsInCurrentCrew,
  (_, props) => props.userId,
  (stamps, userId) => stamps.filter((stamp) => stamp.userId === userId)
);

export const selectStampsInCurrentCrewForMember = (memberSelector) =>
  createSelector(selectStampsInCurrentCrew, memberSelector, (stamps, member) =>
    stamps.filter((stamp) => isStampFromMember(stamp, member))
  );

export const selectStampsRelevantInDateRange = (stampsSelector, startTimeSelector, endTimeSelector) =>
  createSelector(stampsSelector, startTimeSelector, endTimeSelector, (stamps, startTime, endTime) =>
    getStampsRelevantInDateRange(stamps, startTime, endTime)
  );

export const selectActiveStampChainForUserId = createSelector(selectStampsInCurrentCrewForUserId, (stamps) => {
  const stampsFromPast = stamps.filter(
    (stamp) =>
      isBefore(new Date(stamp.clockInTimestamp), new Date()) ||
      isSameSecond(new Date(stamp.clockInTimestamp), new Date())
  );

  if (stampsFromPast.size === 0) {
    return stampsFromPast;
  }
  const activeClockInTimestamp = Math.max(...stampsFromPast.map((stamp) => new Date(stamp.clockInTimestamp)));

  return stampsFromPast.filter((stamp) =>
    isSameSecond(new Date(stamp.clockInTimestamp), new Date(activeClockInTimestamp))
  );
});

export const selectStampsWithChainStartingInRange = (stampsSelector, startTimeSelector, endTimeSelector) =>
  createSelector(stampsSelector, startTimeSelector, endTimeSelector, (stamps, startTime, endTime) => {
    return getStampsWithChainStartingInDateRange(stamps, startTime, endTime);
  });

const selectRelevantStamps = selectStampsWithChainStartingInRange(
  selectStampsInCurrentCrewForUserId,
  (_, props) => props.startTime,
  (_, props) => props.endTime
);

export const selectStampChains = createSelector(selectRelevantStamps, (stamps) =>
  makeStampChains(stamps.sort(sortStampsAsc))
);

export const selectStampsInCurrentCrewNotAfterDate = (dateSelector) =>
  createSelector(dateSelector, selectStampsInCurrentCrew, (date, stamps) =>
    stamps.filter((stamp) => isStampNotAfterDate(stamp, date)).sort(sortStampsByDateDesc)
  );

// get the last stamp for each member in the crew regarding a given date
// can be null if there is no data in the appstate for a given user
export const selectLastStampFromMembersInCurrentCrewOnDate = (dateSelector) =>
  createSelector(
    selectMembersInCurrentCrew,
    selectStampsInCurrentCrewNotAfterDate(dateSelector),
    (
      members,
      stampsNotAfterDateSortedDesc // get the last stamp for each member
    ) =>
      members
        .map((member) => stampsNotAfterDateSortedDesc.find((stamp) => stamp.userId === member.userId))
        .filter((stamp) => stamp !== undefined)
  );

export const selectStampsForMember = createSelector(selectCurrentUserId, selectStampsInCurrentCrew, (userId, stamps) =>
  stamps.filter((stamp) => stamp.userId === userId)
);

export const selectLastValidStamp = createSelector(
  selectStampsForMember,
  (stamps) => stamps.sort((a, b) => compareAsc(new Date(b.timestamp), new Date(a.timestamp)))[0]
);

const selectEndOfCurrentTime = createSelector((currentTime = new Date()) => endOfDay(currentTime));

export const selectLastStampWithLocation = createSelector(
  selectLastStampFromMembersInCurrentCrewOnDate(selectEndOfCurrentTime),
  (stamps) => stamps.filter((stamp) => !!stamp.location)
);

export const selectOrderedActiveStampChainForUserId = createSelector(
  (state, props) => selectActiveStampChainForUserId(state, props),
  (stamps) => stamps.sort((stamp1, stamp2) => stamp1.timestamp.localeCompare(stamp2.timestamp))
);

// Create virtual bookings for open (unprocessed) stampchains,
// so we can show them along to regular bookings
// Open stamp chains have null values for duration and from properties.
export const getVirtualBookingsFromStamps = createSelector(selectStampsInCurrentCrew, (stamps) =>
  stampsForOpenStampChainsToVirtualBookings(stamps)
);

const isStampFromMember = (stamp, member) => stamp.userId === member.userId;
const isStampNotAfterDate = (stamp, date) => new Date(stamp.timestamp) <= date;
export const sortStampsByDateDesc = (stamp1, stamp2) => -1 * stamp1.timestamp.localeCompare(stamp2.timestamp);
