import { object, string } from '../utils';

const { objectToArray } = object;
const { isJsonString } = string;

const FULL_DISK_STORAGE_ERROR = {
  chrome: 'QuotaExceededError',
  firefox: 'NS_ERROR_DOM_QUOTA_REACHED',
  safari: 'QUOTA_EXCEEDED_ERR',
};

/**
 * deleteFirstItemFromLocalStorage - Deletes the first item, which is the one located in the first position of the local storage,
 *                                   to free some disk space
 */
const deleteFirstItemFromLocalStorage = () => {
  const item = getFirstItem();
  window.localStorage.removeItem(item.key);
};

/**
 * getFirstItem - Retrieves the first item which is the one located in the first position of the local storage
 *
 * @return {object} with properties 'key' and 'position'
 */
const getFirstItem = () => {
  const items = { ...window.localStorage };
  let firstItem = {};

  Object.keys(items).forEach((key) => {
    if (isJsonString(items[key])) {
      if (!firstItem.position || JSON.parse(items[key]).position < firstItem.position) {
        firstItem = { position: JSON.parse(items[key]).position, key: key };
      }
    }
  });

  return firstItem;
};

/**
 * getItem - Retrieves the item value of key @param key from the local storage
 *
 * @param {string} key
 *
 * @return {any} which is the value stored by the @param key in the local storage
 */
export const getItem = (key) => {
  if (!window || !window.localStorage) return;

  const localStorageValue = window.localStorage.getItem(key);

  // Checks if the value has the new representation (value + position)
  // if so, it will be returned "localStorageValueParsed.value"
  // if not, it will be returned the value as it was stored ("localStorageValue")
  if (isJsonString(localStorageValue)) {
    const localStorageValueParsed = JSON.parse(localStorageValue);

    if (localStorageValueParsed && localStorageValueParsed.value) {
      setItem(key, localStorageValueParsed.value); // to update this key's position to the last one
      return localStorageValueParsed.value;
    }
  }

  return localStorageValue;
};

window.getItemFromLocalStorage = getItem;

/**
 * getLastItem - Retrieves the last item which is the one located in the last position of the local storage
 *
 * @return {object} with properties 'key' and 'position'
 */
const getLastItem = () => {
  const items = { ...window.localStorage };
  let lastItem = { position: 0 };

  Object.keys(items).forEach((key) => {
    if (isJsonString(items[key]) && JSON.parse(items[key]).position > lastItem.position) {
      lastItem = { position: JSON.parse(items[key]).position, key: key };
    }
  });

  return lastItem;
};

/**
 * isFullDiskStorageError - Checks if the error passed is caused by a full disk storage
 *
 * @param {string} errorMessage
 *
 * @return {boolean} true if the error passed is caused by a full disk storage,
 *                   false otherwise
 */
const isFullDiskStorageError = (errorMessage) =>
  objectToArray(FULL_DISK_STORAGE_ERROR).find((error) => error === errorMessage);

/**
 * setItem - Saves an item in the local storage with the key and value passed, setting its position as the last one.
 *           If the local storage is full, the first item will be deleted.
 *
 * @param {string} key
 * @param {any} value
 */
export const setItem = (key, value) => {
  if (!window || !window.localStorage) return;

  const lastItem = getLastItem();

  try {
    window.localStorage.setItem(
      key,
      JSON.stringify({
        value: value,
        position: lastItem.key === key ? lastItem.position : lastItem.position + 1,
      })
    );
  } catch (e) {
    if (isFullDiskStorageError(e.name)) {
      deleteFirstItemFromLocalStorage();
      setItem(key, value);
    }
  }
};
