/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { ReactElement, ReactNode } from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES, ICON_NAMES } from '../../constants';
import { FontWeightType } from '../../types';
import Icon from '../core/icon';

type ElementProps = {
  children: ReactNode;
  className: string;
  onClick?: () => void;
};

const InlineElement = ({ className, children, onClick }: ElementProps) => (
  <span onClick={onClick} className={className}>
    {children}
  </span>
);

const BlockElement = ({ className, children, onClick }: ElementProps) => (
  <h2 onClick={onClick} className={className}>
    {children}
  </h2>
);

type HeadlineProps = {
  bold?: boolean;
  center?: boolean;
  children: ReactNode;
  className?: string;
  fontWeight?: FontWeightType;
  iconName?: ICON_NAMES | 'favorite';
  inline?: boolean;
  medium?: boolean;
  moreSpaceAround?: boolean;
  narrow?: boolean;
  noBottomSpacing?: boolean;
  noTopSpacing?: boolean;
  onClick?: () => void;
  small?: boolean;
  spaceBetween?: boolean;
};

const Headline: (props: HeadlineProps) => ReactElement = ({
  bold = false,
  center = false,
  children,
  className,
  fontWeight = 700,
  iconName,
  inline = false,
  medium = false,
  moreSpaceAround = false,
  narrow = false,
  noBottomSpacing = false,
  noTopSpacing = false,
  onClick,
  small = false,
  spaceBetween = false,
}) => {
  const Element = inline ? InlineElement : BlockElement;
  const styles = getStyles({ fontWeight });

  return (
    <Element
      onClick={onClick}
      className={`${css(
        styles.bigHeadline,
        small && styles.smallHeadline,
        medium && styles.mediumHeadline,
        center && styles.center,
        noTopSpacing && styles.noTopSpacing,
        noBottomSpacing && styles.noBottomSpacing,
        moreSpaceAround && styles.moreSpaceAround,
        spaceBetween && styles.spaceBetween,
        narrow && styles.narrow,
        onClick && styles.clickable,
        bold && styles.bold
      )}
      ${className}`}
    >
      {iconName && (
        <span className={css(styles.withIcon) as string}>
          {iconName === 'favorite' ? <FavoriteIcon /> : <Icon name={iconName} />}
        </span>
      )}
      {children}
    </Element>
  );
};

const getStyles = ({ fontWeight }: { fontWeight: FontWeightType }) =>
  StyleSheet.create({
    bigHeadline: {
      ...FONT_STYLES.big,
      marginBottom: 10,
      display: 'flex',
      alignItems: 'baseline',
      marginTop: '20px',
      marginRight: '10px',
      textAlign: 'left',
      fontWeight,
    },
    narrow: {
      fontSize: '20px',
      fontWeight: 'normal',
    },
    noTopSpacing: {
      marginTop: 0,
    },
    noBottomSpacing: {
      marginBottom: 0,
    },
    mediumHeadline: {
      ...FONT_STYLES.medium,
    },
    smallHeadline: {
      ...FONT_STYLES.small,
    },
    center: {
      justifyContent: 'center',
    },
    bold: {
      fontWeight: fontWeight,
    },
    moreSpaceAround: {
      marginTop: '40px',
      marginBottom: '40px',
    },
    spaceBetween: {
      justifyContent: 'space-between',
    },
    withIcon: {
      marginRight: '10px',
      alignSelf: 'flex-end',
      color: COLORS.MAIN_COLOR_A_BIT_LITER,
    },
    clickable: {
      marginBottom: 40,
      cursor: 'pointer',
      transition: 'all 200ms',
      ':hover': {
        color: COLORS.ORANGE_MAIN,
      },
    },
  });

export default Headline;
