/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildDangerouslyTranslateNeverWithUserContent, buildScopedTranslationFunction } from '../../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    betweenTime: 'Zeit erfasst von {{startTime}} bis {{endTime}}',
    break: 'Pause',
    cancel: 'Abbrechen',
    done: 'Fertig',
    notice: 'Pausen können im nachhinein nur erhöht werden.',
    save: 'Speichern',
  },
  en: {
    betweenTime: 'Time was tracked between {{startTime}} and {{endTime}}',
    break: 'Break',
    cancel: 'Cancel',
    done: 'Done',
    notice: 'Afterwards, breaks can only be increased.',
    save: 'Save',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
