import { createSelector } from 'reselect';
import { areIntervalsOverlapping, startOfDay, endOfDay, differenceInSeconds } from 'date-fns';

import { selectCurrentCrewId } from './crews-selectors';
import { selectCurrentUserId } from './authentication-selectors';
import { createPropsSelector } from './utils';
import { isWithinRange } from '../utils/date';

export const selectAbsences = (state) => state.bo.absences.absences;
export const selectAbsencesByProps = createPropsSelector(selectAbsences);
export const selectAbsencesInCurrentCrew = createSelector(selectAbsences, selectCurrentCrewId, (absences, crewId) =>
  absences.filter((absence) => absence.crewId === crewId)
);

export const selectUserAbsences = createSelector(
  selectAbsences,
  selectCurrentCrewId,
  selectCurrentUserId,
  (absences, crewId, userId) => absences.filter((absence) => absence.crewId === crewId && absence.userId === userId)
);

export const selectConfirmedAbsencesInCurrentCrew = createSelector(selectAbsencesInCurrentCrew, (absences) =>
  absences.filter((absence) => absence.confirmedAt)
);

export const selectAbsencesInCurrentCrewOnDate = (dateSelector) =>
  createSelector(dateSelector, selectAbsencesInCurrentCrew, (date, absences) =>
    absences.filter((absence) => isAbsenceOnDate(absence, date))
  );

export const selectConfirmedAbsencesInCurrentCrewOnSelectedDate = createSelector(
  selectAbsencesInCurrentCrewOnDate((_, props) => props.date),
  (absences) => absences.filter((absence) => absence.confirmedAt)
);

export const selectNotRejectedAbsencesInCurrentCrew = createSelector(selectAbsencesInCurrentCrew, (absences) =>
  absences.filter((absence) => !absence.rejectedAt)
);

export const selectPendingAbsencesInCurrentCrew = createSelector(selectAbsencesInCurrentCrew, (absences) =>
  absences.filter((absence) => isPending(absence))
);

export const isAbsenceOnDate = (absence, date) =>
  isWithinRange(date, new Date(absence.startDate), new Date(absence.endDate));

// TODO: discuss where to put those helpers
export const isAdmitted = (absence) => absence.confirmedAt || absence.rejectedAt;
export const isPending = (absence) => !isAdmitted(absence);
export const isRejected = (absence) => !!absence.rejectedAt;
export const isConfirmed = (absence) => !!absence.confirmedAt;
export const isCreated = (absence) =>
  absence.confirmedAt && Math.abs(differenceInSeconds(new Date(absence.confirmedAt), new Date(absence.createdAt))) < 3;

// TODO: change function name or use isWithinInterval instead of overlap, change start and end to be date objects
export const filterAbsencesInRange = (absences, start, end) =>
  absences.filter((absence) =>
    areIntervalsOverlapping(
      { start: new Date(absence.startDate), end: new Date(absence.endDate) },
      { start: startOfDay(new Date(start)), end: endOfDay(new Date(end)) }
    )
  );
