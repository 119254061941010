import { COLORS, FONT_STYLES } from '.';

export enum THEMES {
  DEFAULT = 'default',
  ORANGE = 'orange',
  TURQUOISE = 'turquoise',
  TURQUOISE_WHITE = 'turquoise-white',
}

export const generateBaseThemeForMaterialUi = (additionalThemes?: Record<string, any>): Record<string, any> => ({
  root: {
    width: '100%',
    '& input': {
      ...FONT_STYLES.base,
      fontSize: 16,
      color: COLORS.GREY_DARK,
      caretColor: COLORS.ORANGE_MAIN,
      paddingRight: 4,
      '&::placeholder': {
        color: COLORS.ORANGE_MEDIUM,
      },
      '&:disabled': {
        cursor: 'not-allowed',
      },
    },
    '& label': {
      ...FONT_STYLES.base,
      fontSize: 16,
    },
    '& label.Mui-focused': {
      color: COLORS.ORANGE_MAIN,
    },
    '& .MuiInputLabel-filled': {
      color: COLORS.ORANGE_MAIN,
    },
    '& .MuiInputLabel-outlined': {
      color: COLORS.GREY_DARK,
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
      borderRadius: 0,
      '& label': {
        color: COLORS.ORANGE_MAIN,
      },
      '&:hover': {
        backgroundColor: COLORS.ORANGE_OPAQUE,
      },
      '&.Mui-focused': {
        backgroundColor: COLORS.ORANGE_OPAQUE,
      },
    },
    '& .MuiFilledInput-underline': {
      '&:before': {
        borderBottomColor: COLORS.GREY_LIGHT,
        '&:hover': {
          borderBottomColor: COLORS.GREY_MEDIUM,
        },
      },
      '&:hover::before': {
        borderBottomColor: COLORS.GREY_MEDIUM,
      },
      '&:after': {
        borderBottomColor: COLORS.ORANGE_MAIN,
      },
    },
    '& .Mui-error': {
      '&:after': {
        borderColor: `${COLORS.RED_MAIN} !important`,
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: COLORS.GREY_LIGHT,
      },
      '&:hover fieldset': {
        borderColor: COLORS.GREY_MEDIUM,
      },
      '&.Mui-focused': {
        backgroundColor: COLORS.ORANGE_OPAQUE,
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.ORANGE_MAIN,
      },
    },
    '& .MuiSelect-select:focus': { backgroundColor: 'unset' },
    ...additionalThemes,
  },
});
