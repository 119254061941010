import React from 'react';
import PropTypes from 'prop-types';
import { rethrowError, ignoreReturnFor } from 'promise-frites';
import { collections, react } from '../../utils';
import { StyleSheet, css } from '../../_external-deps/stylesheet';

import { BREAKPOINTS } from '../../constants';

const { composeReverse } = collections;
const { withLoadingState, withUIState, prepareProp } = react;

const preventDefaultFor = (onSubmit) => (form) => {
  form.preventDefault();
  return onSubmit(form.target);
};

/**
 * USAGE
 * All inner inputs must have a NAME tag otherwise the wrapper won't be able to get the data
 * You need to pass a onSubmit attribute when using the wrapper component (to actively submit the form)
 * You need to specify a input type="submit" in order to trigger the form
 * The isSubmitting property will be set while the promise is resolved (we can use this property to disable inputs/actions)
 * In order to use the errors you need to reference them using errors["inputName"]
 **/

class Form extends React.Component {
  deleteErrorByName = (evt) => {
    if (!evt.target.name) return;
    if (!this.props.onErrorsSet) return;

    const errors = { ...this.props.errors };
    if (errors[evt.target.name]) {
      delete errors[evt.target.name];
      this.props.onErrorsSet(errors);
    }
    return evt;
  };

  componentDidMount() {
    if (this.props.autoSubmit && Object.keys(this.props.errors).length === 0) {
      this.props.onSubmit(this.formRef);
    }
  }

  render() {
    const { children, onSubmit, className = '', disabled = false, style = {}, resetKey } = this.props;

    return (
      <form
        ref={(ref) => {
          this.formRef = ref;
        }}
        onChange={this.deleteErrorByName}
        onSubmit={preventDefaultFor(onSubmit)}
        className={`${css(styles.form, disabled && styles.formDisabled)} ${className}`}
        style={style}
        key={resetKey}
      >
        <fieldset className={css(styles.fieldset, disabled && styles.fieldsetDisabled)} disabled={disabled}>
          {children}
        </fieldset>
      </form>
    );
  }
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  autoSubmit: PropTypes.bool,
};

const styles = StyleSheet.create({
  formDisabled: {
    cursor: 'not-allowed',
    opacity: 0.7,
  },
  form: {
    transition: 'opacity 200ms',
    [BREAKPOINTS.mobilePortraitMode]: {
      overflow: 'auto',
    },
  },
  fieldset: {
    border: 'none',
    padding: 0,
    [BREAKPOINTS.mobilePortraitMode]: {
      minWidth: 0,
    },
  },
  fieldsetDisabled: {
    pointerEvents: 'none',
  },
});

export default Form;

const noop = () => {};
export const isForm = composeReverse(
  // Reset the form by a simple rerender of the children
  withUIState('wasSubmitted', 'setWasSubmitted', false),
  withUIState('resetKey', 'onReset', 0),
  withUIState('errors', 'onErrorsSet', {}),
  withUIState('errorsV1', 'onErrorsV1Set', []),
  prepareProp(
    'onReset',
    ({ onReset, resetKey }) =>
      () =>
        onReset(resetKey + 1)
  ),
  prepareProp('onSubmit', ({ onSubmit }) => (form) => {
    const values = Array.from(form.querySelectorAll('[name]')).reduce((result, input) => {
      return input.name ? { ...result, [input.name]: input.type === 'checkbox' ? input.checked : input.value } : result;
    }, {});
    return onSubmit(values);
  }),
  withLoadingState('onSubmit', 'isSubmitting', false),
  prepareProp(
    'onSubmit',
    ({ onSubmit, onReset, onSuccess = noop, onError = noop, onErrorsSet, onErrorsV1Set, setWasSubmitted }) =>
      (form) => {
        return Promise.resolve()
          .then(() => onErrorsSet({}))
          .then(() => onErrorsV1Set([]))
          .then(() => onSubmit(form))
          .then(onSuccess)
          .then(ignoreReturnFor(() => Promise.all([setWasSubmitted(true), onReset()])))
          .catch(
            rethrowError((response) =>
              Array.isArray(response.errors) ? onErrorsV1Set(response.errors || []) : onErrorsSet(response.errors || {})
            )
          )
          .catch(onError);
      }
  ),
  prepareProp('disabled', ({ disabled, isSubmitting }) => disabled || isSubmitting),
  (Component) => (props) =>
    (
      <Form {...props}>
        <Component {...props} />
      </Form>
    )
);
