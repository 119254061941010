import React from 'react';
import { StyleSheet, css } from '../../../_external-deps/stylesheet';
import { COLORS, BREAKPOINTS, THEMES, TIME_ACCOUNTS } from '../../../constants';
import * as Fonts from '../../../styles/fonts';
import { duration as durationUtils } from '../../../utils';
import Icon from '../../../components/core/icon';

const { durationToMinutes, formatIsoDuration } = durationUtils;

export const PrimaryDuration = ({
  className,
  duration,
  icon,
  bold = false,
  showInMinutes,
  theme,
  timeAccount,
  wide,
}) => {
  const stylesWithTimeAccount = createStylesWithThemes(wide)[theme];
  const styles = stylesWithTimeAccount[timeAccount] || stylesWithTimeAccount.default;

  return (
    <section className={className}>
      {icon && <Icon name={icon} className={css(styles.icon)} />}
      <div className={css(styles.default, bold && styles.bold)}>
        {showInMinutes ? durationToMinutes(duration) : formatIsoDuration(duration)}
      </div>
    </section>
  );
};

const createStylesWithThemes = (wide) => ({
  [THEMES.ORANGE]: {
    default: StyleSheet.create({
      default: {
        ...Fonts.base,
        fontSize: wide ? '136px' : '88.5px',
        fontWeight: Fonts.fontWeights.narrow,
        color: COLORS.ORANGE_MAIN,
        lineHeight: 0.8,
        textAlign: 'center',

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '60px',
        },
      },
      bold: {
        fontWeight: Fonts.fontWeights.bold,
      },
      icon: {
        display: 'block',
        textAlign: 'center',
        fontSize: '35px',
        marginBottom: '10px',
        color: COLORS.ORANGE_MAIN,

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '25px',
        },
      },
    }),
  },
  [THEMES.TURQUOISE]: {
    default: StyleSheet.create({
      default: {
        ...Fonts.base,
        fontSize: wide ? '136px' : '88.5px',
        fontWeight: Fonts.fontWeights.narrow,
        color: COLORS.TURQUOISE_MAIN,
        lineHeight: 0.8,
        textAlign: 'center',

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '60px',
        },
      },
      bold: {
        fontWeight: Fonts.fontWeights.bold,
      },
      icon: {
        display: 'block',
        textAlign: 'center',
        fontSize: '35px',
        marginBottom: '10px',
        color: COLORS.TURQUOISE_MAIN,

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '25px',
        },
      },
    }),
  },
  [THEMES.TURQUOISE_WHITE]: {
    [TIME_ACCOUNTS.WORKING]: StyleSheet.create({
      default: {
        ...Fonts.base,
        fontSize: wide ? '100px' : '88.5px',
        fontWeight: Fonts.fontWeights.narrow,
        color: COLORS.WHITE,
        lineHeight: 0.8,
        textAlign: 'center',

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '60px',
        },
      },
      bold: {
        fontWeight: Fonts.fontWeights.bold,
      },
      icon: {
        display: 'block',
        textAlign: 'center',
        fontSize: '35px',
        marginBottom: '10px',
        color: COLORS.TURQUOISE_MAIN,

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '25px',
        },
      },
    }),
    [TIME_ACCOUNTS.NOT_WORKING]: StyleSheet.create({
      default: {
        ...Fonts.base,
        fontSize: wide ? '100px' : '88.5px',
        fontWeight: Fonts.fontWeights.narrow,
        color: COLORS.TURQUOISE_MAIN,
        lineHeight: 0.8,
        textAlign: 'center',

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '60px',
        },
      },
      bold: {
        fontWeight: Fonts.fontWeights.bold,
      },
      icon: {
        display: 'block',
        textAlign: 'center',
        fontSize: '35px',
        marginBottom: '10px',
        color: COLORS.TURQUOISE_MAIN,

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '25px',
        },
      },
    }),
    [TIME_ACCOUNTS.BREAK]: StyleSheet.create({
      default: {
        ...Fonts.base,
        fontSize: wide ? '100px' : '88.5px',
        fontWeight: Fonts.fontWeights.narrow,
        color: COLORS.TURQUOISE_MAIN,
        lineHeight: 0.8,
        textAlign: 'center',

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '60px',
        },
      },
      bold: {
        fontWeight: Fonts.fontWeights.bold,
      },
      icon: {
        display: 'block',
        textAlign: 'center',
        fontSize: '35px',
        marginBottom: '10px',
        color: COLORS.TURQUOISE_MAIN,

        [BREAKPOINTS.mobilePortraitMode]: {
          fontSize: '25px',
        },
      },
    }),
  },
});

export default PrimaryDuration;
