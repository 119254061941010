import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadAbsenceTypesForCrew = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_ABSENCE_TYPES',
        permission: 'canUseAbsenceManagement',
        fnParams: { crewId: Number.parseInt(crewId, 10) },
        fn: (params) => api.crewAbsenceTypes(params),
      })
);
