import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadTeamMembers = memoize(
  {},
  ({ api, crewId, userId, teamId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_TEAM_MEMBERS',
        fnParams: {
          crewId: Number.parseInt(crewId, 10),
          ...(userId ? { userId: Number.parseInt(userId, 10) } : {}),
          ...(teamId ? { teamId: Number.parseInt(teamId, 10) } : {}),
        },
        fn: (params) => api.getTeamMembers(params),
      })
);

export const createTeamMembersForMember =
  ({ api, crewId, userId, teamIds }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'CREATE_TEAM_MEMBERS_FOR_MEMBER',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        userId: Number.parseInt(userId, 10),
        teamIds,
      },
      fn: (params) => api.createTeamMembersForMember(params),
    });

export const createTeamMembersForTeam =
  ({ api, crewId, teamId, userIds }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'CREATE_TEAM_MEMBERS_FOR_TEAM',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        teamId: Number.parseInt(teamId, 10),
        userIds,
      },
      fn: (params) => api.createTeamMembersForTeam(params),
    });
