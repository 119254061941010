import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ teams: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const teamsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_TEAMS_SUCCESS':
        applyMinimalChangesToArray(draft.teams, action.result, (team) => team.crewId === action.crewId);
        break;
      case 'CREATE_TEAM_SUCCESS':
      case 'UPDATE_TEAM_SUCCESS':
        applyMinimalChangesToArray(
          draft.teams,
          action.result,
          (team) => team.crewId === action.crewId && team.id === action.id
        );
        break;
      case 'DELETE_TEAM_SUCCESS':
        applyMinimalChangesToArray(draft.teams, [], (team) => team.crewId === action.crewId && team.id === action.id);
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
