import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadPermissions = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_PERMISSIONS',
        fnParams: { crewId: Number.parseInt(crewId, 10) },
        fn: (params) => (params.crewId ? api.getPermissions(params) : Promise.resolve()),
      })
);
