import { createSelector } from 'reselect';
import produce from 'immer';

export const selectCurrentCrewId = (_state: any, props: any) => (props && props.crewId ? props.crewId : null);

export const selectCrews = (state: any) => state.bo.crews.crews || produce([], () => {});

export const selectCurrentCrew = createSelector(selectCrews, selectCurrentCrewId, (crews, crewId) =>
  crews.find((crew: { id: number }) => crew.id === crewId)
);
