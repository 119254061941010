import { factory, sequence, random } from 'stahlwerk';

export const createPublicHoliday = factory({
  country: random(['de', 'at', 'ch']),
  dayKind: random([3, 4]),
  date: '2000-01-01',
  fraction: random([1, 2]),
  id: sequence(),
  name: 'Holiday name',
});
