import produce, { current } from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ teamMembers: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const teamMembersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_TEAM_MEMBERS_SUCCESS':
        applyMinimalChangesToArray(
          draft.teamMembers,
          action.result,
          ((userId, teamId) => {
            if (userId)
              return (teamMember) => teamMember.crewId === action.crewId && teamMember.userId === action.userId;
            if (teamId)
              return (teamMember) => teamMember.crewId === action.crewId && teamMember.teamId === action.teamId;
            return (teamMember) => teamMember.crewId === action.crewId;
          })(!!action.userId, !!action.teamId)
        );
        break;
      case 'CREATE_TEAM_MEMBERS_FOR_MEMBER_SUCCESS': {
        const teamMembers = current(draft.teamMembers);

        // Filter out the team member that matches the action's crewId and userId
        const filteredTeamMembers = teamMembers.filter(
          (teamMember) => !(teamMember.crewId === action.crewId && teamMember.userId === action.userId)
        );

        // Create new team members based on the provided teamIds
        const newTeamMembers = action.teamIds.map((teamId) => ({
          crewId: action.crewId,
          teamId,
          userId: action.userId,
        }));
        draft.teamMembers.splice(0, draft.teamMembers.length);

        filteredTeamMembers.forEach((member) => draft.teamMembers.push(member));
        newTeamMembers.forEach((member) => draft.teamMembers.push(member));

        break;
      }
      case 'CREATE_TEAM_MEMBERS_FOR_TEAM_SUCCESS':
        applyMinimalChangesToArray(
          draft.teamMembers,
          action.result,
          (teamMember) => teamMember.crewId === action.crewId && teamMember.teamId === action.teamId
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
