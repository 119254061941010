import { memoize } from 'redux-memoize';
import { onEnterTerminal } from './ui-actions';
import { dispatchAsyncFnStatusActions } from '../../lib';

export const loadTerminalCrewMembers = memoize(
  { ttl: 60000 },
  ({ api, crewId, disabledAt }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_TERMINAL_CREW_MEMBERS',
        fnParams: { crewId, disabledAt },
        fn: async (params) => {
          await dispatch(onEnterTerminal({ api }));
          return api.getCrewMembers(params);
        },
      })
);
