import * as absence from './absence';
import * as actionCreatorHelpers from './actionCreator';
import * as array from './array';
import * as bookings from './bookings';
import * as collections from './collections';
import * as dataTypes from './data-types';
import * as date from './date';
import * as dayKindCalendar from './day-kind-calendar';
import * as duration from './duration';
import * as env from './env';
import * as event from './event';
import * as fetch from '../api/_external-deps/fetch';
import * as location from './location';
import * as integer from './integer';
import * as matrix from './matrix';
import * as memoize from './memoize';
import * as number from './number';
import * as object from './object';
import * as promise from './promise';
// eslint-disable-next-line import/no-unresolved
import * as react from './React';
import * as shift from './shift';
import * as sort from './sort';
import * as stampchain from './stampchain';
import * as stamp from './stamp';
import * as string from './string';
import * as sync from './sync';
import * as timeCategory from './time-category';
import * as time from './time';
import * as type from './type';
import * as tweaks from './tweaks';
import * as user from './user';
import * as window from './window';
import { newsTypeIcons } from './news-type-icons';

const helpers = { ...actionCreatorHelpers };

export {
  absence,
  array,
  bookings,
  collections,
  dataTypes,
  date,
  dayKindCalendar,
  duration,
  env,
  event,
  fetch,
  helpers,
  integer,
  location,
  matrix,
  memoize,
  newsTypeIcons,
  number,
  object,
  promise,
  react,
  shift,
  sort,
  stamp,
  stampchain,
  string,
  sync,
  time,
  timeCategory,
  tweaks,
  type,
  user,
  window,
};
