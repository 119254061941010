import { createSelector } from 'reselect';
import { selectCurrentCrewId } from './crews-selectors';
import { createPropsSelector } from './utils';

export const selectAbsenceEntitlements = (state) => state.bo.absenceEntitlements.absenceEntitlements;
export const selectAbsenceEntitlementsForProps = createPropsSelector(selectAbsenceEntitlements);
export const findAbsenceEntitlementForProps = createSelector(
  selectAbsenceEntitlementsForProps,
  (entitlements) => entitlements[0]
);

export const selectAbsenceEntitlementsForCurrentCrew = createSelector(
  selectAbsenceEntitlements,
  selectCurrentCrewId,
  (entitlements, crewId) => entitlements.filter((entitlement) => entitlement.crewId === crewId)
);

export const selectAbsenceEntitlementsForCurrentCrewInYear = (yearSelector) =>
  createSelector(selectAbsenceEntitlementsForCurrentCrew, yearSelector, (entitlementsForCrew, year) =>
    entitlementsForCrew.filter((entitlement) => entitlement.year === year)
  );

export const selectAbsenceEntitlementForCurrentCrewAndUserInYear = (yearSelector) =>
  createSelector(
    selectAbsenceEntitlementsForCurrentCrew,
    yearSelector,
    (_, props) => props.userId,
    (entitlementsForCrew, year, userId) =>
      entitlementsForCrew
        .filter((entitlement) => entitlement.year === year)
        .find((entitlement) => entitlement.userId === userId)
  );
