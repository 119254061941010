import produce from 'immer';
import { selectors } from '@crewmeister/shared';
import { dataTypes, object } from '../utils';
import { ACTION_NAMES } from '../constants';

const { pick } = object;
const { isArray } = dataTypes;
const { createPropsSelector } = selectors;

const initialState = produce({ workingTimeModels: [] }, () => {});

const selectWorkingTimeModelsByProps = createPropsSelector((state) => state.workingTimeModels);

const mergeBlankWorkingTimeModel = (state, action) => {
  return selectWorkingTimeModelsByProps(state, {}, pick(['userId', 'crewId'], action)).concat(
    isArray(action.result) ? action.result : [action.result]
  );
};

const removeBlankWorkingTimeModels = (state) => {
  return selectWorkingTimeModelsByProps(state, {}, { isNew: true });
};

const removeWorkingTimeModel = (state, props) => {
  const { crewId, userId, validFrom } = props;
  return selectWorkingTimeModelsByProps(state, {}, { userId, crewId, validFrom });
};

const removeAllWorkingTimeModel = (state, props) => {
  const { crewId, userId } = props;
  return selectWorkingTimeModelsByProps(state, {}, { userId, crewId });
};

const modelsNotInCrew = (crewId) => (model) => model.crewId !== crewId;

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const workingTimeModelsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'DELETE_WORKING_TIME_MODEL_SUCCESS':
        draft.workingTimeModels = removeWorkingTimeModel(draft, action.result);
        break;
      case `${ACTION_NAMES.DELETE_ALL_WORKING_TIME_MODEL}_SUCCESS`:
        draft.workingTimeModels = removeAllWorkingTimeModel(draft, action.result);
        break;
      case 'CREATE_WORKING_TIME_MODELS_SUCCESS':
        draft.workingTimeModels = removeWorkingTimeModel(draft, action.result);
        draft.workingTimeModels = draft.workingTimeModels.concat(
          isArray(action.result) ? action.result : [action.result]
        );
        break;
      case 'LOAD_WORKING_TIME_MODELS_SUCCESS':
        draft.workingTimeModels = draft.workingTimeModels
          .filter(modelsNotInCrew(action.crewId))
          .concat(isArray(action.result) ? action.result : [action.result]);
        break;
      case 'LOAD_BLANK_WORKING_TIME_MODEL_SUCCESS':
        draft.workingTimeModels = mergeBlankWorkingTimeModel(draft, action);
        break;
      case 'REMOVE_BLANK_WORKING_TIME_MODELS':
        draft.workingTimeModels = removeBlankWorkingTimeModels(draft);
        break;
      default:
      // nothing to do => immer returns the original object
    }
  });
