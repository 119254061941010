import { diff } from 'deep-diff';
import EventBus from '../flutter-integration/EventBus';

const ACTION_TYPES = {
  NEW: 'N',
  DELETE: 'D',
  EDIT: 'E',
  ARRAY: 'A',
};

const eventBusMiddleware = (store) => (next) => (action) => {
  const prevState = store.getState();
  const result = next(action);
  const nextState = store.getState();

  const differences = diff(prevState, nextState);
  if (differences) {
    differences.forEach((difference) => {
      const { path, item } = difference;
      let { lhs, rhs, kind } = difference;
      const key = path.join('.');

      let actionType;
      if (kind === ACTION_TYPES.ARRAY) {
        kind = item.kind;
        lhs = item.lhs;
        rhs = item.rhs;
      }
      switch (kind) {
        case ACTION_TYPES.NEW:
          actionType = 'CREATE';
          break;
        case ACTION_TYPES.DELETE:
          actionType = 'DELETE';
          break;
        case ACTION_TYPES.EDIT:
          actionType = 'UPDATE';
          break;
        default:
          actionType = 'UNKNOWN';
      }
      EventBus.publish(`${key}_${actionType}`, {
        oldData: lhs ? JSON.stringify(lhs) : lhs,
        newData: rhs ? JSON.stringify(rhs) : rhs,
      });
    });
  }

  return result;
};

export default eventBusMiddleware;
