import React from 'react';
import { COLORS, FONT_STYLES, IDS_FOR_GOOGLE_TAG_MANAGER } from '../../constants';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import Button from '../../components/core/button';
import t, { dangerouslyTranslateNeverWithUserContent } from './translate';

const CrewMaintenance = ({ crew = {} }) => (
  <div className={css(styles.container)}>
    <h1 className={css(styles.h1)}>{t('header')}</h1>
    <h2 className={css(styles.h2)}>
      {dangerouslyTranslateNeverWithUserContent('h2', { crewName: crew.name || t('crew') })}
    </h2>
    <p className={css(styles.text)}>
      {dangerouslyTranslateNeverWithUserContent('text', { crewName: crew.name || t('crew') })}
    </p>
    <div className={css(styles.button)}>
      <Button id={IDS_FOR_GOOGLE_TAG_MANAGER.CREW_MAINTENANCE.RELOAD} onClick={() => window.location.reload()}>
        {t('reload')}
      </Button>
    </div>
  </div>
);

const mobilePortraitMode = '@media screen and (max-width: 640px)';

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    minHeight: '100vh',
    width: '100vw',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
    ':after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: '8%',
      left: '55%',
      backgroundImage: 'url(/assets/images/404.png)',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100%',
      opacity: '0.2',
    },
  },
  h1: {
    fontWeight: FONT_STYLES.fontWeights.bold,
    color: COLORS.GREY_MAIN,
    fontSize: '150px',
    padding: '10px 0',
    letterSpacing: '2.4px',
    margin: 0,
    [mobilePortraitMode]: {
      fontSize: '80px',
    },
  },
  h2: {
    ...FONT_STYLES.big,
    margin: 0,
  },
  text: {
    ...FONT_STYLES.medium,
    margin: 0,
  },
  button: {
    display: 'inline-table',
    marginTop: '20px',
  },
  link: {
    ...FONT_STYLES.medium,
    padding: '20px 0',
  },
});

export default CrewMaintenance;
