import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../constants';
import { SettingDefinitionType } from '../types';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;

interface SettingsDefinitionsState {
  settingsDefinitions: SettingDefinitionType[];
}

const initialState: SettingsDefinitionsState = {
  settingsDefinitions: [],
};

export const settingsDefinitionsReducer = (state = initialState, action: AnyAction): SettingsDefinitionsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${ACTION_NAMES.LOAD_SETTINGS_DEFINITIONS}_SUCCESS`:
        applyMinimalChangesToArray(
          draft.settingsDefinitions,
          action.result,
          (settingDefinition: SettingDefinitionType) =>
            action.result.some(
              (element: SettingDefinitionType) =>
                element.definition.id === settingDefinition.definition.id &&
                element.language === settingDefinition.language
            )
        );
        break;

      default:
    }
  });
