import { SyncType, DateString } from '.';

export enum DurationTypes {
  ABSENCE = 'absence',
  BREAK = 'break',
  EDUCATION = 'education',
  EDUCATION_HOURS = 'educationHours',
  OTHER_ABSENCE = 'otherAbsence',
  OTHER_ABSENCE_HOURS = 'otherAbsenceHours',
  PRODUCTIVE_WORK = 'productiveWork',
  PUBLIC_HOLIDAY_HOURS = 'publicHolidayHours',
  SICKNESS = 'sickness',
  SICKNESS_HOURS = 'sicknessHours',
  VACATION = 'vacation',
  VACATION_HOURS = 'vacationHours',
  WORK = 'work',
  WORK_DIFFERENCE = 'workDifference',
  WORK_TARGET = 'workTarget',
}

export type DurationType = {
  crewId: number;
  groupBy: Record<string, number>;
  userId: number;
  type: DurationTypes;
  value: ISO8601Duration;
  from: DateString;
  to: DateString;
  month?: number;
  _sync?: SyncType;
};

export type ComparableDurationType = {
  crewId: number;
  userId: number;
  type: DurationTypes;
  value: ISO8601Duration;
  from: DateString;
  to: DateString;
};

export type DurationBalanceType = {
  crewId: number;
  date: string;
  value: ISO8601Duration;
  groupBy?: GroupBy;
  _sync?: SyncType;
};

export type ISO8601Duration = `P${string}`;

export type GroupBy = {
  crewId?: number;
  userId?: number;
};
