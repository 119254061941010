/* eslint-disable jsx-control-statements/jsx-use-if-tag */
/* eslint-disable no-nested-ternary */
import React, { ReactElement, ReactNode, CSSProperties } from 'react';
import Link from '../link';

type ButtonOrLinkProps = {
  id: string | undefined;
  children: ReactNode;
  className: string;
  href?: string;
  disabled: boolean;
  style: CSSProperties;
  target?: string;
  onClick: () => void;
  type: 'button' | 'submit' | 'reset' | undefined;
};

const ButtonOrLink: (props: ButtonOrLinkProps) => ReactElement = (props) =>
  props.href && !props.disabled ? (
    props.href.indexOf('://') !== -1 || props.href.startsWith('tel') ? (
      <a {...props} />
    ) : (
      <Link {...props} to={props.href} target={props.target} />
    )
  ) : (
    <button {...props} />
  );

export default ButtonOrLink;
