import { snakeCase, camelCase, paramCase } from 'change-case';
import { isNumber } from './data-types';

/**
 * capitalize - Capitalizes the first letter of a string
 *
 * @param {string} str (ex: 'crewmeister')
 *
 * @return {string} (ex: 'Crewmeister')
 */
export const capitalize = <T extends string>(str?: T | null): Capitalize<T> =>
  (str ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : '') as unknown as Capitalize<T>;

/**
 * findNumberInString - Retrieves the number that exists in a string
 *
 * @param {string} str string that one wants to retrieve a number from
 *
 * @return {integer} first integer found in the given string
 */
export const findIntegerInString = (str: string) => {
  const number = str.match(/\d+/);
  if (number && number.length) return Number(number[0]);
};

/**
 * fromNumberToString — Converts a given number to string
 *
 * @param number (e.g. 10)
 * @returns {string} (e.g "10")
 */
export const fromNumberToString = <T>(number: number | T): string | T =>
  isNumber(number) ? number.toString(10) : number;

/**
 * getInitials - Retrieves the initials of the first two words of the string passed
 *
 * @param {string} name (ex: 'Crewmeister Best Company')
 *
 * @return {string} (ex: 'CB')
 */
export const getInitials = (name: string) =>
  name
    .replace(/[^A-Z a-zÀ-ž]+/g, '')
    .trim()
    .split(' ')
    .map((word) => word[0])
    .slice(0, 2)
    .join('');

/**
 * insertStringAt - Inserts a string as a substring of another string
 *
 * @param {string} str original string
 * @param {integer} index position where the insertion will occur
 * @param {string} value string that will be inserted
 *
 * @return {string} original string with the value inserted into it at given index
 */
export const insertStringAt = (str: string, index: number, value: string) =>
  str.substr(0, index) + value + str.substr(index);

/**
 * isJsonString - Checks if @param str can be converted to JSON
 *
 * @param {string} str
 *
 * @return {boolean} true, if @param str can be converted to JSON,
 *                   false, otherwise
 */
export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * leftPadNumberWithZero - Adds a 0 to the beginning of the given number if it doesn't already have one
 *
 * @param {number} number (ex: 5)
 *
 * @return {number or string} which is the given number with left pad zero (ex: '05')
 *                            if number greater than or equal to 100, returns number
 */
export const leftPadNumberWithZero: (number: number | string) => number | string = (number = 0) => {
  if (Math.abs(number as number) < 100) {
    const sign = number < 0 ? '-' : '';
    return sign + `00${Math.abs(number as number)}`.substr(-2);
  }
  return number;
};

/**
 * parseJsonString - Parse @param str to JSON object, if its a valid JSON string
 *
 * @param {string} str (ex: '{"result": 5}')
 *
 * @return {boolean} JSON object, if @param str can be converted to JSON, (ex: {result: 5})
 *                   undefined, otherwise
 */
export const parseJsonString = (str: string | undefined) => {
  let parsedValue;
  try {
    parsedValue = JSON.parse(str as string);
  } catch (e) {
    parsedValue = undefined;
  }
  return parsedValue;
};

/**
 * htmlToMarkdown - Converts from HTML syntax to Markdown syntax
 *
 * @param {string} htmlString (ex: '<b>Philosophy</b>')
 *
 * @return {string} (ex: '**Philosophy**')
 */
export const htmlToMarkdown = (htmlString: string) => {
  return htmlString
    .replace(/\<p\>|\<\/p\>/gi, '\n\n')
    .replace(/\<br \/\>/gi, '\n')
    .replace(/\<b\>|\<\/b\>|\<strong\>|\<\/strong\>/gi, '**')
    .replace(/\<i\>|\<\/i\>|\<em\>|\<\/em\>/gi, '**');
};

/**
 * searchString - Verifies if "firstString" has the "secondString" as a substring
 *
 * @param {string} firstString
 * @param {string} secondString
 *
 * @returns true, if firstString includes secondString, false otherwise
 */
export const searchString = (firstString: string | undefined, secondString?: string) =>
  !secondString ||
  !!(firstString && firstString.includes && firstString.toLowerCase().includes(secondString.toLowerCase()));

/**
 * sliceString - Returns string with length up to the maximum passed
 *
 * @param {string} string
 * @param {integer} maximumLength
 *
 * @return {string}
 */
export const sliceString = (string: string, maximumLength: number) =>
  string && maximumLength && string.length > maximumLength ? `${string.slice(0, maximumLength - 3)}...` : string;

/**
 * toCamelCase - Formats string or array of strings to the camel case pattern
 *
 * @param {array or string} arrayOrString (ex: 'camel_case')
 *
 * @return {array or string} (ex: 'camelCase')
 */
export const toCamelCase = (arrayOrString: string | string[]) =>
  typeof arrayOrString === 'string'
    ? camelCase(arrayOrString)
    : arrayOrString.map((string: string) => camelCase(string));

/**
 * toSnakeCase - Formats string or array of strings to the snake case pattern
 *
 * @param {array or string} arrayOrString (ex: 'snakeCase')
 *
 * @return {array or string} (ex: 'snake_case')
 */
export const toSnakeCase = (arrayOrString: string | string[]) =>
  typeof arrayOrString === 'string'
    ? snakeCase(arrayOrString)
    : arrayOrString.map((string: string) => snakeCase(string));

/**
 * toKebabCase - Formats string or array of strings to the kebab case (param case) pattern
 *
 * @param {array or string} arrayOrString (ex: 'paramCase')
 *
 * @return {array or string} (ex: 'param-case')
 */
export const toKebabCase = (arrayOrString: string | string[]) =>
  typeof arrayOrString === 'string'
    ? paramCase(arrayOrString)
    : arrayOrString.map((string: string) => paramCase(string));

/**
 * trimStringWithMultipleWords - Trim string according to the three limits: word length, overall string length and
 *                               quantity of words.
 *
 * @param {string} string
 * @param {integer} wordLengthLimit
 * @param {integer} overallStringLengthLimit
 * @param {integer} numberOfWordsInStringLimit
 *
 * @returns {string} which is the string trimmed accordingly
 */
export const trimStringWithMultipleWords = (
  string: string,
  wordLengthLimit: number,
  overallStringLengthLimit: number,
  numberOfWordsInStringLimit: number
) => {
  let nameMinimized = string.split(` `).map((word) => sliceString(word, wordLengthLimit));

  if (nameMinimized.length > numberOfWordsInStringLimit) {
    nameMinimized = nameMinimized.splice(0, numberOfWordsInStringLimit);
    nameMinimized[nameMinimized.length - 1] += '...';
  }

  return sliceString(nameMinimized.join(` `), overallStringLengthLimit);
};
