import { factory, sequence } from 'stahlwerk';

export const createChatMessage = factory({
  id: sequence(),
  roomId: sequence(),
  transactionID: sequence(),
  createdBy: sequence(),
  message: sequence((id) => `Message ${id}`),
  sentAt: new Date().toISOString(),
  readAt: null,
  readBy: [],
});
