import { BEGIN, COMMIT, REVERT } from 'redux-optimist';
import { rethrowError, ignoreReturnFor } from 'promise-frites';
let transactionID = 0;

const makeActionCreator =
  (type) =>
  (args = {}) => ({
    ...args,
    type,
  });

const toActionName = (namespace, actionName) => (namespace ? `${namespace}_${actionName}` : actionName);

const processSingleAction = ({
  dispatch,
  id,
  namespace,
  afterSuccessFn,
  fn,
  actionName,
  fnParamsActions,
  fnParams,
  fnParamsInActions,
}) => {
  const commitAction = makeActionCreator(`${toActionName(namespace.request, actionName)}_COMMIT`);
  const revertAction = makeActionCreator(`${toActionName(namespace.request, actionName)}_REVERT`);
  return Promise.resolve()
    .then(() => fn(fnParams))
    .then(
      ignoreReturnFor((result) =>
        dispatch(commitAction({ result, ...fnParamsInActions(fnParamsActions), optimist: { type: COMMIT, id } }))
      )
    )
    .then(ignoreReturnFor((result) => afterSuccessFn && afterSuccessFn({ result, ...fnParams })))
    .catch(
      rethrowError((error) =>
        dispatch(
          revertAction({ error, ...fnParamsInActions(fnParamsActions), optimist: { type: REVERT, id: transactionID } })
        )
      )
    );
};

export const dispatchOptimisticAsyncFnStatusActions = ({
  dispatch,
  fn,
  fnParams = {},
  // by default, we keep the same fn parameter names in the actions
  fnParamsInActions = (fnParameters) => fnParameters,
  actionName,
  afterSuccessFn = undefined,
  optimisticParseFn = (fnParameters) => fnParameters,
  namespace = {
    request: null,
    failure: null,
    success: null,
  },
}) => {
  transactionID = transactionID + 1;
  const beginAction = makeActionCreator(`${toActionName(namespace.request, actionName)}_BEGIN`);
  // with fnParamsInActions, we can rename function parameter names in the actions
  const fnParamsActions = fnParamsInActions(fnParams);
  // transform action parameters into fake results for the optimistic store update
  const optimisticParams = optimisticParseFn({ ...fnParamsActions, transactionID });
  dispatch(
    beginAction({
      result: optimisticParams,
      ...fnParamsInActions(fnParamsActions),
      optimist: { type: BEGIN, id: transactionID },
    })
  );

  return processSingleAction({
    dispatch,
    id: transactionID,
    namespace,
    afterSuccessFn,
    fn,
    actionName,
    fnParamsActions,
    fnParams,
    fnParamsInActions,
  });
};
