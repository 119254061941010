import { string as stringUtils } from '../utils';

const { toKebabCase } = stringUtils;

export const IDS_FOR_GOOGLE_TAG_MANAGER = {
  NAVIGATION: {
    ABSENCE_MANAGEMENT: 'navigation-absence-management',
    CREW_CHAT: 'navigation-crew-chat',
    CREW_SELECT: 'navigation-crew-select',
    LIVE: 'navigation-live',
    NEWS_CENTER: 'navigation-news-center',
    NEWS_CENTER_SEE_ALL_CTA: 'navigation-news-center-see-all-cta',
    NEWS_CENTER_SELECT_NEWS: 'navigation-news-center-select-news-',
    SALARY_EXPORT: 'navigation-salary-export',
    SETTINGS: 'navigation-settings',
    SHIFT_PLANNER: 'navigation-shift-planner',
    SHIFT_PLANNER_V3: 'navigation-shift-planner-v3',
    SIGN_OUT: 'navigation-sign-out',
    TIME_TRACKING: 'navigation-time-tracking',
    TIME_TRACKING_REPORTS: 'navigation-time-tracking-reports',
    USER_SETTINGS: 'navigation-user-settings',
    INTEGRATIONS: 'navigation-integrations',
  },
  HELP: {
    FLAP_BUTTON: 'help-flap-button',
    VIDEOS: 'help-video-tutorials',
    GUIDE: 'help-guides',
    CHAT: 'help-live-chat',
    VIDEO_1: 'help-video-time-tracking',
    VIDEO_2: 'help-video-invite-employees',
    VIDEO_3: 'help-video-add-projects',
    VIDEO_4: 'help-video-mobile-apps',
    VIDEO_5: 'help-video-gps-tracking',
    VIDEO_6: 'help-video-user-management',
    VIDEO_7: 'help-video-excel-export',
    VIDEO_8: 'help-video-change-information',
  },
  CHAT_OVERLAY: {
    LIST: 'chat-overlay-list',
    CHAT: 'chat-overlay-chat',
    LIST_ITEM: 'chat-overlay-list-item',
  },
  FEEDBACK_BANNER: {
    REFUSE_FEEDBACK: 'feedback-banner-refuse-feedback',
    GIVE_FEEDBACK: 'feedback-banner-give-feedback',
  },
  USER: {
    SIGN_IN: 'user-sign-in',
    SIGN_UP: 'user-sign-up',
    SIGN_UP_CHECKBOX: 'user-sign-up-checkbox',
    SIGN_UP_LINK_TO_TERMS: 'user-sign-up-link-to-terms',
    PASSWORD_RECOVERY_REQUEST: 'user-password-recovery-request',
    PASSWORD_RECOVERY_RESET: 'user-password-recovery-reset',
    AUTHENTICATION_LINKS: {
      SIGN_IN: 'authentication-link-sign-in',
      SIGN_UP: 'authentication-link-sign-up',
      RESET_PASSWORD: 'authentication-link-reset-password',
    },
  },
  ERROR_PAGE: {
    MORE_INFO: 'error-page-more-info',
    TRY_AGAIN: 'error-page-try-again',
  },
  CREW_MAINTENANCE: {
    RELOAD: 'crew-maintenance-reload',
  },
  MEMBER_FILTER: {
    ALL: 'member-filter-all',
    NONE: 'member-filter-none',
    SELECT: 'member-filter-select-one',
  },
  PAGES: {
    LIVE: {
      SORT_BY: 'page-live-sort-by',
      TOGGLE_INACTIVE_EMPLOYEES: 'page-live-toggle-inactive-employees',
      SHOW_MAP: 'page-live-show-map',
      MAP_PIN: 'page-live-map-pin',
      TEAM_FILTER: 'page-live-team-filter',
      TEAM_SEARCH: 'page-live-team-search',
      TEAM_SELECT_OPTION: 'page-live-team-filter-option',
      TEAM_SELECT_ALL_OPTION: 'page-live-team-filter-all-enabled-option',
    },
    TIME_TRACKING: {
      NAME: 'page-time-tracking-',
      START_WORK: 'page-time-tracking-start-work',
      START_BREAK: 'page-time-tracking-start-break',
      STOP_WORK: 'page-time-tracking-stop-work',
      DONE: 'page-time-tracking-done',
      TIME_CATEGORIES: {
        OPEN: 'page-time-tracking-time-categories-open-button',
        CANCEL: 'page-time-tracking-time-categories-cancel-button',
        SAVE: 'page-time-tracking-time-categories-save-button',
        FIRST_TIME_CATEGORY: 'page-time-tracking-time-categories-first-time-category',
        SECOND_TIME_CATEGORY: 'page-time-tracking-time-categories-second-time-category',
      },
      NOTES: 'page-time-tracking-notes',
      DATE_LEFT_DOUBLE: 'page-time-tracking-date-left-double',
      DATE_LEFT: 'page-time-tracking-date-left',
      DATE: 'page-time-tracking-date',
      DATE_ICON: 'page-time-tracking-date-icon',
      DATE_RIGHT: 'page-time-tracking-date-right',
      DATE_RIGHT_DOUBLE: 'page-time-tracking-date-right-double',
      GRANULARITY_DAY: 'page-time-tracking-granularity-day',
      GRANULARITY_WEEK: 'page-time-tracking-granularity-week',
      GRANULARITY_MONTH: 'page-time-tracking-granularity-month',
      VIEW_ACCOUNT: 'page-time-tracking-view-account',
      VIEW_STAMPS: 'page-time-tracking-view-stamps',
      TABLE: {
        HEADER_EDIT_ENTRIES: 'page-time-tracking-table-edit-entries',
        HEADER_DELETE_ENTRIES: 'page-time-tracking-table-delete-entries',
        HEADER_DELETE_ENTRIES_CANCEL: 'page-time-tracking-table-delete-entries-cancel',
        HEADER_DELETE_ENTRIES_CONFIRM: 'page-time-tracking-table-delete-entries-confirm',
        COLUMN_HEADER: 'page-time-tracking-table-header-column',
        COLUMN_HEADER_CHECKBOX: 'page-time-tracking-table-header-column-checkbox',
        COLUMN_CHECKBOX: 'page-time-tracking-table-column-checkbox',
        COLUMN_EDIT: 'page-time-tracking-table-column-edit',
      },
      DETAILS: {
        ADD_OR_EDIT_STAMPS: 'page-time-tracking-add-or-edit-stamps',
        OVERTIME_MANUAL_CORRECTION_TOGGLE: 'page-time-tracking-overtime-manual-correction-toggle',
        OVERTIME_MANUAL_CORRECTION_INPUT: 'page-time-tracking-overtime-manual-correction-input',
        OVERTIME_PAYOUT_INPUT: 'page-time-tracking-overtime-payout-input',
        CLOSE: 'page-time-tracking-close-details',
        SUBMIT: 'page-time-tracking-submit-details',
        MAP_PIN: 'page-time-tracking-map-pin',
      },
      EDIT_STAMPS: {
        CLOSE: 'page-time-tracking-edit-stamps-close',
        LINK_OR_UNLINK: 'page-time-tracking-edit-stamps-link-or-unlink',
        ADD_STAMP: 'page-time-tracking-edit-stamps-add-stamp',
        DELETE_ENTRY: 'page-time-tracking-edit-stamps-delete-entry',
        DELETE_ENTRY_CANCEL: 'page-time-tracking-edit-stamps-delete-entry-cancel',
        DELETE_ENTRY_CONFIRM: 'page-time-tracking-edit-stamps-delete-entry-confirm',
        SUBMIT: 'page-time-tracking-edit-stamps-submit',
        FIELD_START: 'page-time-tracking-edit-stamps-field-start',
        FIELD_END: 'page-time-tracking-edit-stamps-field-end',
        FIELD_BREAK: 'page-time-tracking-edit-stamps-field-break',
        FIELD_TIME_CATEGORY_1: 'page-time-tracking-edit-stamps-field-time-category-1',
        FIELD_TIME_CATEGORY_2: 'page-time-tracking-edit-stamps-field-time-category-2',
        FIELD_NOTE: 'page-time-tracking-edit-stamps-field-note',
        FIELD_DELETE_STAMP: 'page-time-tracking-edit-stamps-field-delete-stamp',
      },
      BATCH_EDIT_STAMPS: {
        CLOSE: 'page-time-tracking-batch-edit-stamps-close',
        LINK_OR_UNLINK: 'page-time-tracking-batch-edit-stamps-link-or-unlink',
        DELETE_STAMP: 'page-time-tracking-batch-edit-stamps-delete-stamp',
        ADD_STAMP: 'page-time-tracking-batch-edit-stamps-add-stamp',
        DELETE_ENTRY: 'page-time-tracking-batch-edit-stamps-delete-entry',
        DELETE_ENTRY_CANCEL: 'page-time-tracking-batch-edit-stamps-delete-entry-cancel',
        DELETE_ENTRY_CONFIRM: 'page-time-tracking-batch-edit-stamps-delete-entry-confirm',
        SUBMIT: 'page-time-tracking-batch-edit-stamps-submit',
        FIELD_START: 'page-time-tracking-batch-edit-stamps-field-start',
        FIELD_END: 'page-time-tracking-batch-edit-stamps-field-end',
        FIELD_BREAK: 'page-time-tracking-batch-edit-stamps-field-break',
        FIELD_TIME_CATEGORY_1: 'page-time-tracking-batch-edit-stamps-field-time-category-1',
        FIELD_TIME_CATEGORY_2: 'page-time-tracking-batch-edit-stamps-field-time-category-2',
        FIELD_NOTE: 'page-time-tracking-batch-edit-stamps-field-note',
        FIELD_DELETE_STAMP: 'page-time-tracking-batch-edit-stamps-field-delete-stamp',
      },
      TEAM_FILTER: 'time-tracking-team-filter',
      TEAM_SEARCH: 'time-tracking-team-search',
      TEAM_SELECT_OPTION: 'time-tracking-team-filter-option',
      TEAM_SELECT_ALL_OPTION: 'time-tracking-team-filter-all-enabled-option',
      TERMINAL: {
        OPEN_TERMINAL: 'page-time-tracking-open-time-tracking-terminal-button',
        DROPDOWN: 'page-time-tracking-open-time-tracking-terminal-dropdown',
        DROPDOWN_SELECT_OPTION: 'page-time-tracking-open-time-tracking-terminal-dropdown-select-option-',
        SWITCH_THIS_DEVICE_INTO_TERMINAL_MODE: 'page-time-tracking-switch-this-device-into-terminal-mode',
        SWITCH_THIS_DEVICE_INTO_TERMINAL_MODE_MODAL: 'page-time-tracking-switch-this-device-into-terminal-mode-modal',
        SWITCH_THIS_DEVICE_INTO_TERMINAL_MODE_MODAL_CONFIRM:
          'page-time-tracking-switch-this-device-into-terminal-mode-modal-confirm',
        TERMINAL_MODE: 'page-time-tracking-terminal-mode',
      },
      MEMBER_FILTER: 'time-tracking-member-filter',
      MEMBER_SEARCH: 'time-tracking-member-search',
      MEMBER_SELECT_ALL_CTA: 'time-tracking-member-filter-all-cta',
      MEMBER_SELECT_OPTION: 'time-tracking-member-filter-option',
      MEMBER_SELECT_ALL_OPTION: 'time-tracking-member-filter-all-enabled-option',
    },
    TIME_TRACKING_REPORTS: {
      CANCEL_LOADING: 'page-time-tracking-reports-cancel-loading',
      SELECT_REPORT_TYPE: 'page-time-tracking-reports-select-report-type',
      SELECT_DATE_RANGE: 'page-time-tracking-reports-select-date-range',
      FILTER_FOR_CHART: 'page-time-tracking-reports-filter-for-employees',
      TABLE_HEADER_SELECT: 'page-time-tracking-reports-table-header-select',
      TABLE_ROW_SELECT: 'page-time-tracking-reports-table-row-select',
      TABLE_ROW_DOWNLOAD: 'page-time-tracking-reports-table-row-download',
      TABLE_ROW_DOWNLOAD_ALL: 'page-time-tracking-reports-table-row-download-all',
      TEAM_FILTER: 'page-time-tracking-reports-team-filter',
      TEAM_SEARCH: 'page-time-tracking-reports-team-search',
      TEAM_SELECT_OPTION: 'page-time-tracking-reports-team-filter-option',
      TEAM_SELECT_ALL_OPTION: 'page-time-tracking-reports-team-filter-all-enabled-option',
    },
    SALARY_EXPORT: {
      NAME: 'page-salary-export',
      SELECT_DATE_RANGE: 'page-salary-export-select-date-range',
      DOWNLOAD: 'page-salary-export-download',
      UPLOAD: 'page-salary-export-upload',
    },
    SHIFT_PLANNER: {
      SWITCH_VIEW_TO_USER: 'page-shift-planner-switch-view-to-user',
      SWITCH_VIEW_TO_WORKPLACE: 'page-shift-planner-switch-view-to-workplace',
      SELECT: 'page-shift-planner-select-all-or-active-employees',
      DROPDOWN: 'page-shift-planner-more-options',
      DOWNLOAD_PDF: 'page-shift-planner-download-pdf',
      COPY_LAST_WEEK: 'page-shift-planner-copy-last-week',
      COPY_TEMPLATE: 'page-shift-planner-copy-template',
      SAVE_TEMPLATE: 'page-shift-planner-save-template',
      DELETE_ALL_SHIFTS: 'page-shift-planner-delete-all-shifts',
      DATE_LEFT_DOUBLE: 'page-shift-planner-date-left-double',
      DATE_LEFT: 'page-shift-planner-date-left',
      DATE: 'page-shift-planner-date',
      DATE_RIGHT: 'page-shift-planner-date-right',
      DATE_RIGHT_DOUBLE: 'page-shift-planner-date-right-double',
      ADD_OR_EDIT_SHIFT: 'page-shift-planner-add-or-edit-shift',
      ADD_OR_EDIT_SHIFT_TOGGLE_EDIT_SHIFT_OR_SHIFT_OFFER_REPLY:
        'page-shift-planner-add-or-edit-shift-toggle-edit-shift-or-shift-offer-reply',
      ADD_OR_EDIT_SHIFT_CLOSE: 'page-shift-planner-add-or-edit-shift-close-button',
      ADD_OR_EDIT_SHIFT_TAB_SHIFT: 'page-shift-planner-add-or-edit-shift-tab-shift',
      ADD_OR_EDIT_SHIFT_TAB_NOTES: 'page-shift-planner-add-or-edit-shift-tab-notes',
      ADD_OR_EDIT_SHIFT_TAB_NOTES_TEXT_AREA: 'page-shift-planner-add-or-edit-shift-tab-notes-text-area',
      ADD_OR_EDIT_SHIFT_TAB_SHIFT_OFFER: 'page-shift-planner-add-or-edit-shift-tab-shift-offer',
      ADD_OR_EDIT_SHIFT_DELETE: 'page-shift-planner-add-or-edit-shift-delete',
      ADD_OR_EDIT_SHIFT_CANCEL: 'page-shift-planner-add-or-edit-shift-cancel',
      ADD_OR_EDIT_SHIFT_CONFIRM: 'page-shift-planner-add-or-edit-shift-confirm',
      SHIFT_OFFER_ACCEPT: 'page-shift-planner-shift-offer-accept',
      SHIFT_OFFER_REJECT: 'page-shift-planner-shift-offer-reject',
      SHIFT_OFFER_WITHDRAW: 'page-shift-planner-shift-offer-withdraw',
      UNPUBLISHED_SHIFTS: {
        DETAILS: 'page-shift-planner-unpublished-shifts-details-button',
        SELECT_ROW_CHECKBOX: 'page-shift-planner-unpublished-shifts-select-row-checkbox',
        DISCARD_ROW: 'page-shift-planner-unpublished-shifts-discard-row-button',
        DISCARD: 'page-shift-planner-unpublished-shifts-discard-button',
        PUBLISH: 'page-shift-planner-unpublished-shifts-publish-button',
      },
      TEAM_FILTER: 'page-shift-planner-team-filter',
      TEAM_SEARCH: 'page-shift-planner-team-search',
      TEAM_SELECT_OPTION: 'page-shift-planner-team-filter-option',
      TEAM_SELECT_ALL_OPTION: 'page-shift-planner-team-filter-all-enabled-option',
      SHIFT_OFFERS_ROW: 'page-shift-planner-shift-offers-row',
      MEMBER_FILTER: 'page-shift-planner-member-filter',
      MEMBER_SEARCH: 'page-shift-planner-member-search',
      MEMBER_SELECT_ALL_CTA: 'page-shift-planner-member-filter-all-cta',
      MEMBER_SELECT_OPTION: 'page-shift-planner-member-filter-option',
      MEMBER_SELECT_ALL_OPTION: 'page-shift-planner-member-filter-all-enabled-option',
      OPEN_SHIFTS_ROW: 'page-shift-planner-open-shifts-row',
    },
    USER_PROFILE: {
      AVATAR: 'page-user-profile-avatar',
      PERSONAL_INFO: 'page-user-profile-presonal-info-save-button',
      PERSONAL_INFO_LANGUAGE_DROPDOWN: 'page-user-profile-presonal-info-language-dropdown',
      NEWS: 'page-user-profile-news',
      NEWS_SAVE: 'page-user-profile-news-save-button',
      CHANGE_PASSWORD: 'page-user-profile-change-password-button',
      REFERRAL_PROGRAM: 'page-user-profile-referral-program-button',
    },
    SETTINGS: {
      CREW_NAME_SAVE: 'page-settings-crew-name-save-button',
      TIME_AND_LOCATION_TRACKING: {
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_CHECKBOX:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-checkbox',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_EXTENDED_OPTIONS_LINK:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-extended-options-link',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_ON_ALL_OTHER_DEVICES_CHECKBOX:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-on-all-other-devices-checkbox',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_ON_ALL_OTHER_DEVICES_SELECT:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-on-all-other-devices-select',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_ON_ALL_OTHER_DEVICES_SELECT_OPTION:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-on-all-other-devices-select-option-',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_ON_MOBILE_DEVICES_CHECKBOX:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-on-mobile-devices-checkbox',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_ON_MOBILE_DEVICES_SELECT:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-on-mobile-devices-select',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_ON_MOBILE_DEVICES_SELECT_OPTION:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-on-mobile-devices-select-option-',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_ON_THE_TERMINAL_CHECKBOX:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-on-the-terminal-checkbox',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_ON_THE_TERMINAL_SELECT:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-on-the-terminal-checkbox-select',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_ON_THE_TERMINAL_SELECT_OPTION:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-on-the-terminal-checkbox-select-option-',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_SELECT:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-select',
        EMPLOYEES_CAN_CLOCK_IN_AND_OUT_SELECT_OPTION:
          'page-settings-time-and-location-tracking-employees-can-clock-in-and-out-select-option-',
        EMPLOYEES_CAN_EDIT_OWN_TIMES_CHECKBOX:
          'page-settings-time-and-location-tracking-employees-can-edit-own-times-checkbox',
        EMPLOYEES_CAN_EDIT_OWN_TIMES_SELECT:
          'page-settings-time-and-location-tracking-employees-can-edit-own-times-select',
        EMPLOYEES_CAN_EDIT_OWN_TIMES_SELECT_OPTION:
          'page-settings-time-and-location-tracking-employees-can-edit-own-times-select-option-',
        EMPLOYEES_CAN_ENTER_OWN_TIMES_MANUALLY_CHECKBOX:
          'page-settings-time-and-location-tracking-employees-can-enter-own-times-manually-checkbox',
        EMPLOYEES_CAN_ENTER_OWN_TIMES_MANUALLY_SELECT:
          'page-settings-time-and-location-tracking-employees-can-enter-own-times-manually-select',
        EMPLOYEES_CAN_ENTER_OWN_TIMES_MANUALLY_SELECT_OPTION:
          'page-settings-time-and-location-tracking-employees-can-enter-own-times-manually-select-option-',
        SAVE_BUTTON: 'page-settings-time-and-location-tracking-save-button',
      },
      ABSENCES: {
        absenceCheckbox: (type) => `page-settings-absences-absence-type-${type}-checkbox`,
        absenceSelect: (type) => `page-settings-absences-absence-type-${type}-select`,
        absenceSelectOption: (type) => `page-settings-absences-absence-type-${type}-select-option-`,
        ALLOW_MEMBERS_TO_SEE_ABSENCES_OF_ALL_OTHER_MEMBERS_CHECKBOX:
          'page-settings-absences-allow-members-to-see-absences-of-all-other-members',
        SAVE_BUTTON: 'page-settings-absences-save-button',
      },
      SHIFT_PLANNER: {
        ALLOW_MEMBERS_TO_SEE_SHIFTS_OF_ALL_OTHER_MEMBERS_CHECKBOX:
          'page-settings-shift-planner-allow-members-to-see-shifts-of-all-other-members-checkbox',
        SAVE_BUTTON: 'page-settings-shift-planner-save-button',
      },
      SHIFT_GRACE_PERIOD: {
        SAVE_BUTTON: 'page-settings-shift-grace-period-save-button',
        UP_TO_30_MINUTES_AFTER_SHIFT_START: 'page-settings-shift-grace-up-to-30-minutes-after-shift-start',
        UP_TO_30_MINUTES_BEFORE_SHIFT_START: 'page-settings-shift-grace-up-to-30-minutes-before-shift-start',
      },
      CREW_TIME_RULES: {
        BREAK_SELECT: 'page-settings-crew-time-rules-break-select',
        BREAK_SELECT_OPTION: 'page-settings-crew-time-rules-break-select-option-',
        DELETE_TIME_RULES_FOR_THIS_PERIOD_BUTTON:
          'page-settings-crew-time-rules-delete-time-rules-for-this-period-button',
        EXCEPTIONS_SEE_DETAILS: 'page-settings-crew-time-rules-exception-see-details',
        EXPANDABLE_CONTAINER: 'page-settings-crew-time-rules-expandable-container',
        SAVE_BUTTON: 'page-settings-crew-time-rules-save-button',
        TARGET_WORK_HOURS: 'page-settings-crew-time-rules-target-work-hours',
      },
      PUBLIC_HOLIDAYS: {
        addHolidayName: (name) => `page-settings-public-holidays-add-holiday-${name}`,
        HOLIDAY: 'page-settings-public-holidays-holiday',
        COUNTRY_SELECT: 'page-settings-public-holidays-country-select',
        COUNTRY_SELECT_OPTION: 'page-settings-public-holidays-country-select-option-',
      },
      MEMBER: {
        TEAMS_LIST_OPTION: 'page-settings-member-overlay-teams-list-team-id-',
        TEAMS_LIST_SAVE_BUTTON: 'page-settings-member-overlay-teams-list-save-button',
      },
      ADD_EMPLOYEE: {
        WITH_EMAIL: 'page-settings-add-employee-with-email',
        WITH_EMAIL_SUBMIT_BUTTON: 'page-settings-add-employee-with-email-submit-button',
        WITHOUT_EMAIL: 'page-settings-add-employee-without-email',
        WITHOUT_EMAIL_SUBMIT_BUTTON: 'page-settings-add-employee-without-email-submit-button',
      },
      ADD_TIME_CATEGORY: 'page-settings-add-time-category',
      CREATE_WORKSPACE: 'page-settings-create-workspace',
      BUY_LICENSE: 'page-settings-buy-license',
      EMPLOYEES: {
        MEMBER_BADGE: 'pages-settings-employees-member-badge',
        SHOW_DEACTIVATED_EMPLOYEES_TOGGLE: 'pages-settings-employees-show-deactivated-employees-toggle',
      },
      TEAMS: {
        ADD: 'page-settings-team-add',
        DELETE: 'page-settings-team-delete',
        UPDATE: 'page-settings-team-update',
        OPEN_TEAM: 'page-settings-team-open-team-details',
        TEAM_VIEW_MEMBER_LIST_SEARCH_HEADER: 'page-settings-team-details-member-list-search-header',
        TEAM_VIEW_MEMBER_LIST_SEARCH: 'page-settings-team-details-member-list-search',
        TEAM_VIEW_MEMBER_LIST_SELECT_OPTION: 'page-settings-team-details-member-list-option',
      },
    },
    SETTINGS_NEW: {
      BUY_LICENSE: 'page-settings-new-button-buy-license',
      USER_LEVEL_BADGE: {
        BACK_BUTTON: 'user-level-badge-button-back-to-crew-settings',
        DROPDOWN: 'user-level-badge-dropdown',
      },
      FOLDERS: 'folders-list-option',
      VIEW_EXCEPTION: 'working-time-model-tab-exceptions-view-exception-for-user-id',
      VERSION: 'working-time-model-tab-versions',
    },
    NEWS: {
      DELETE_ALL: 'pages-news-delete-all-',
      DELETE_NEWS: 'pages-news-delete-news-',
      FILTER_CTA: 'pages-news-filter-cta',
      FILTER_CTA_RESET_FILTER: 'pages-news-filter-cta-reset-filter',
      FILTER_CTA_SELECT_OPTION: 'pages-news-filter-cta-select-option-',
      LOAD_MORE_CTA: 'pages-news-load-more-cta',
      MARK_ALL_AS_READ: 'pages-news-mark-all-as-read-',
      SELECT_NEWS: 'pages-news-select-news-',
    },
    ABSENCE_MANAGEMENT: {
      absenceStatusFilterAllCheckbox: (view) =>
        `page-absence-management-${view}-tab-absence-status-filter-all-checkbox`,
      absenceStatusFilterOption: (view) => `page-absence-management-${view}-tab-absence-status-filter-option-`,
      absenceStatusFilter: (view) => `page-absence-management-${view}-tab-absence-status-filter`,
      absenceTypeFilter: (view) => `page-absence-management-${view}-tab-absence-type-filter`,
      absenceTypeFilterAllCheckbox: (view) => `page-absence-management-${view}-tab-absence-type-filter-all-checkbox`,
      absenceTypeFilterOption: (view) => `page-absence-management-${view}-tab-absence-type-filter-option-`,
      APPROVE_ABSENCE: 'page-absence-management-approve-absence-button',
      CALENDAR_DAY: 'page-absence-management-calendar-day-',
      CALENDAR_TAB: 'page-absence-management-calendar-tab',
      date: (view) => ({
        DATE: `page-absence-management-${view}-tab-date`,
        DATE_ICON: `page-absence-management-${view}-tab-date-icon`,
        DATE_LEFT: `page-absence-management-${view}-tab-date-left`,
        DATE_LEFT_DOUBLE: `page-absence-management-${view}-tab-date-left-double`,
        DATE_RIGHT: `page-absence-management-${view}-tab-date-right`,
        DATE_RIGHT_DOUBLE: `page-absence-management-${view}-tab-date-right-double`,
      }),
      DOWNLOAD_PDF_CTA: 'page-absence-management-download-pdf-cta',
      GRAPH_TAB: 'page-absence-management-graph-tab',
      LIST_TAB: 'page-absence-management-list-tab',
      teamFilter: (view) => ({
        TEAM_FILTER: `page-absence-management-${view}-tab-team-filter`,
        TEAM_SEARCH: `page-absence-management-${view}-tab-team-search`,
        TEAM_SELECT_OPTION: `page-absence-management-${view}-tab-team-filter-option`,
        TEAM_SELECT_ALL_OPTION: `page-absence-management-${view}-tab-team-filter-all-enabled-option`,
      }),
      memberFilter: (view) => `page-absence-management-${view}-tab-member-filter`,
      memberFilterAllEnabledCheckbox: (view) =>
        `page-absence-management-${view}-tab-member-filter-all-enabled-checkbox`,
      memberFilterAllEnabledCTA: (view) => `page-absence-management-${view}-tab-member-filter-all-enabled-cta`,
      memberFilterDisabledMembers: (view) => `page-absence-management-${view}-tab-member-filter-disabled-members`,
      memberFilterSearch: (view) => `page-absence-management-${view}-tab-member-filter-search`,
      memberRow: (view) => `pages-absence-management-${view}-tab-member-row`,
      MODAL_ABSENCE_TYPES: 'page-absence-management-request-absence-modal-absence-types',
      MODAL_ABSENCE_TYPE_FILTER_OPTION: 'page-absence-management-modal-absence-type-filter-option-',
      MODAL_BACK_BUTTON: 'page-absence-management-modal-back-button',
      MODAL_CREATE_BUTTON: 'page-absence-management-modal-create-button',
      MODAL_COMMENT: 'page-absence-management-modal-comment',
      MODAL_DELETE_BUTTON: 'page-absence-management-modal-delete-button',
      MODAL_END_DAY_PART: 'page-absence-management-modal-end-day-part',
      MODAL_END_DAY_PART_SELECT_AFTERNOON: 'page-absence-management-modal-end-day-part-select-afternoon',
      MODAL_END_DAY_PART_SELECT_MORNING: 'page-absence-management-modal-end-day-part-select-morning',
      MODAL_FROM_DATE: 'page-absence-management-modal-from-date',
      MODAL_MEMBERS: 'page-absence-management-modal-members',
      MODAL_MEMBERS_FILTER_SEARCH: 'page-absence-management-modal-members-filter-search',
      MODAL_MEMBERS_SELECT_MEMBER: 'page-absence-management-modal-members-select-member-',
      MODAL_NOTE: 'page-absence-management-modal-note',
      MODAL_REQUEST_BUTTON: 'page-absence-management-modal-request-button',
      MODAL_SHOW_OTHER_ABSENCES: 'page-absence-management-modal-show-other-absences',
      MODAL_SHOW_OTHER_ABSENCES_BACK_BUTTON: 'page-absence-management-modal-show-other-absences-back-button',
      MODAL_START_DAY_PART: 'page-absence-management-modal-start-day-part',
      MODAL_START_DAY_PART_SELECT_AFTERNOON: 'page-absence-management-modal-start-day-part-select-afternoon',
      MODAL_START_DAY_PART_SELECT_MORNING: 'page-absence-management-modal-start-day-part-select-morning',
      MODAL_TO_DATE: 'page-absence-management-modal-to-date',
      NEW_ABSENCE_CTA: 'page-absence-management-new-absence-cta',
      OPEN_REQUESTS_TAB: 'page-absence-management-open-requests-tab',
      OPEN_REQUESTS_TAB_REJECT_ABSENCE_MODAL_CANCEL_BUTTON:
        'page-absence-management-open-requests-tab-reject-absence-modal-cancel-button',
      OPEN_REQUESTS_TAB_REJECT_ABSENCE_MODAL_REJECT_BUTTON:
        'page-absence-management-open-requests-tab-reject-absence-modal-reject-button',
      OPEN_REQUESTS_TAB_REJECT_ABSENCE_MODAL_TEXT_AREA:
        'page-absence-management-open-requests-tab-reject-absence-modal-text-area',
      REJECT_ABSENCE: 'page-absence-management-reject-absence-button',
      STATISTICS_TAB: 'page-absence-management-statistics-tab',
    },
  },
  TERMINAL: {
    CLICK_MEMBER_TILE: 'terminal-click-member-tile',
    SIGNIN: {
      CLOSE: 'terminal-signin-close-button',
      SUBMIT: 'terminal-signin-submit-button',
      RESET_PASSWORD: 'terminal-signin-reset-password-button',
    },
    TIME_TRACKING: {
      NAME: 'terminal-time-tracking-',
      ENTER_FULL_APP: 'terminal-time-tracking-back-to-full-app',
      CLOSE: 'terminal-time-tracking-close-button',
    },
  },
  TERMINAL_3_COLUMN: {
    CLICK_MEMBER: 'terminal-3-column-click-member',
  },
  TERMINAL_PIN_CODE: {
    SEARCH_USER: 'terminal-pin-code-search-user',
    SELECT_USER_FROM_DROPDOWN: 'terminal-pin-code-select-user-from-dropdown-option-',
  },
  STAMP_WATCH: {
    START_WORK: 'start-work',
    START_BREAK: 'start-break',
    STOP_WORK: 'stop-work',
    DONE: 'done',
    TIME_CATEGORIES: {
      OPEN: 'time-categories-open-button',
      CANCEL: 'time-categories-cancel-button',
      SAVE: 'time-categories-save-button',
      FIRST_TIME_CATEGORY: 'time-categories-first-time-category',
      SECOND_TIME_CATEGORY: 'time-categories-second-time-category',
    },
    NOTES: 'notes',
  },
  CUSTOM_EVENTS: {
    CHECK_MARKETING_BANNER: 'check-marketing-banner',
  },
  DOM_ELEMENTS_IDS: {
    MARKETING_BANNER: 'marketing-banner',
  },
};

export const extendOptionsWithId = (options, baseObject, baseId) =>
  options.map((option) => ({
    ...option,
    optionId: (baseObject[`${baseId}_SELECT_OPTION`] || baseObject) + toKebabCase(option.id || option.value),
  }));
