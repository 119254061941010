import produce from 'immer';
import { array, dataTypes } from '../utils';

const { applyMinimalChangesToArray } = array;
const { isArray } = dataTypes;

const initialState = produce({ messages: [] }, () => {});

export const messagesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_MESSAGES_SUCCESS':
        applyMinimalChangesToArray(draft.messages, action.result.payload, (message) =>
          action.result.payload.map((m) => m.id).includes(message.id)
        );
        break;
      case 'CREATE_MESSAGE_BEGIN':
      case 'UPDATE_MESSAGE_SUCCESS':
        applyMinimalChangesToArray(draft.messages, action.result, (message) =>
          isArray(action.result) ? action.result.map((m) => m.id).includes(message.id) : message.id === action.result.id
        );
        break;
      case 'RECEIVED_MESSAGE_SUCCESS':
        // When a message is created the CREATE_MESSAGE and the RECEIVED_MESSAGE actions are dispatched and to
        // prevent having duplicated messages in the store when the RECEIVED_MESSAGE action is dispatched before
        // the message is synced (finishes CREATE_MESSAGE_COMMIT) we need to replace the action result message
        // with the unsynced message from the store. The transactionID can only be used for the same action, the
        // action result has different id from the optimistic message id and the createdBy also can't be used to
        // replace the messages because when a user sends a message the other devices for that user must be
        // updated as well.
        applyMinimalChangesToArray(draft.messages, action.result, (message) =>
          isArray(action.result)
            ? action.result.map((m) => m.id).includes(message.id)
            : message.id === action.result.id ||
              (!!message.transactionID &&
                message.message === action.result.message &&
                message.createdBy === action.result.createdBy &&
                message.roomId === action.result.roomId)
        );
        break;
      case 'CREATE_MESSAGE_COMMIT':
        applyMinimalChangesToArray(
          draft.messages,
          action.result,
          (message) => message.id === action.result.id || message.transactionID === action.optimist.id
        );
        break;
      case 'CREATE_MESSAGE_REVERT':
        applyMinimalChangesToArray(draft.messages, [], (message) => message.transactionID === action.optimist.id);
        break;
      case 'CLEAR_APP_STATE':
        return initialState;
      default:
    }
  });
