import produce from 'immer';
import { createSelector } from 'reselect';

import { date } from '../../utils';
import { selectCurrentCrewId } from '../crews-selectors';

import { Shift } from '../../models';
import { SHIFT_TYPE, V3Shift } from '../../types';
import { selectCurrentUserId } from '../authentication-selectors';
import { createPropsFinder, createPropsSelector } from '../utils';

const { isWithinRange } = date;

export const v3SelectShifts = (state: any): V3Shift[] => state.boV3.shifts.shifts || produce([], () => {});

export const v3SelectCrewShifts = createSelector(v3SelectShifts, selectCurrentCrewId, (shifts, crewId) =>
  shifts.filter((shift) => shift.crewId === crewId)
);

export const v3SelectCrewShiftsInPeriod = createSelector(
  (_: any, props: any) => props,
  v3SelectCrewShifts,
  (props, shifts) => {
    const rangeShifts = shifts.filter(
      (shift) => shift.id && isWithinRange(new Date(shift.from), new Date(props.startDate), new Date(props.endDate))
    );
    const published = rangeShifts.filter((shift: V3Shift) => shift.type === SHIFT_TYPE.PUBLISHED);
    const drafts = rangeShifts.filter((shift: V3Shift) => shift.type !== SHIFT_TYPE.PUBLISHED);

    const publishedWithDraftFlag = published.map((shift) => {
      const hasDraft = drafts.some((_draft) => _draft.publishedShiftId === shift.id);
      return { ...shift, hasDraft } as V3Shift;
    });

    return publishedWithDraftFlag.concat(drafts).map((shift) => new Shift(shift));
  }
);

export const v3SelectShiftsByProps = createPropsSelector(v3SelectShifts);

export const v3FindShiftByProps = createPropsFinder(v3SelectShifts);

export const v3SelectUserShifts = (state: any, props: any): V3Shift[] =>
  createSelector(v3SelectShifts, selectCurrentCrewId, selectCurrentUserId, (shifts, crewId, userId) =>
    shifts.filter((shift) => shift.crewId === crewId && shift.userId === userId)
  )(state, props);
