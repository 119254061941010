import { createSelector } from 'reselect';
import { selectUserIdFromProps } from './users-selectors';

const selectUserSettingsFromState = (state) => state.bo.userSettings.userSettings;

const selectAllUserSettings = createSelector(selectUserSettingsFromState, (userSettings) => userSettings.concat());

export const selectUserSettings = createSelector(selectAllUserSettings, selectUserIdFromProps, (userSettings, userId) =>
  userSettings.find((settings) => settings.userId === userId)
);
