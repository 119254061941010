import produce from 'immer';
import { array } from '../utils';
const initialState = produce({ dayKindCalendar: [] }, () => {});

const { applyMinimalChangesToArray } = array;
// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const dayKindCalendarReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // LOAD_DAY_KIND_CALENDAR is used to load a calendars for a whole crew
      // or for individual users
      // if it is for the whole crew, the userId parameter is not set
      // if it is filtered by userIds, there is an array property userId (singular!)
      case 'LOAD_DAY_KIND_CALENDAR_SUCCESS':
        applyMinimalChangesToArray(
          draft.dayKindCalendar,
          action.result,
          (dayKindCalendarEntry) =>
            dayKindCalendarEntry.crewId === action.crewId &&
            dayKindCalendarEntry.date >= action.from &&
            dayKindCalendarEntry.date <= action.to &&
            (!action.dayKindId || action.dayKindId.includes(dayKindCalendarEntry.dayKindId)) &&
            (action.userId ? action.userId.some((userId) => dayKindCalendarEntry.userId === userId) : true)
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
