import TagManager from 'react-gtm-module';
import { isProduction, isStage } from '../utils';
import { isE2ETest } from './';

export const GTM_PROPERTIES = {
  production: {
    auth: 'GMe-m0ejzrdaqWsmxsz7nw',
    preview: 'env-1',
  },
  stage: {
    auth: 'vXmii-pREmm0SMRYl5tK_g',
    preview: 'env-65',
  },
  development: {
    auth: 'nJKPA0z69SaFW__naBltLw',
    preview: 'env-64',
  },
};

const currentEnvironment = (environment) => environment && !isE2ETest();

const getEnvironmentSpecificGTMProperties = () => {
  if (currentEnvironment(isProduction)) return GTM_PROPERTIES.production;
  if (currentEnvironment(isStage)) return GTM_PROPERTIES.stage;
  return GTM_PROPERTIES.development;
};

export const initializeGoogleTagManager = () =>
  TagManager.initialize({
    gtmId: 'GTM-K43PC8F',
    ...getEnvironmentSpecificGTMProperties(),
  });
