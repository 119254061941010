/* eslint-disable no-unused-expressions */
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDrop } from 'react-dnd';

import Icon from '../../../components/core/icon';
import Modal from '../../../components/core/modal';
import Button from '../../../components/core/button';

import { COLORS, LOCATION_OPTIONS, TERMINAL_AUTHENTICATION_MODES, THEMES } from '../../../constants';
import { event as eventUtils } from '../../../utils';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';

import TerminalMemberTimeTrackingInfo from '../../terminal-member-time-tracking-info';
import TerminalSignIn from '../../terminal-sign-in';
import TerminalSignInWithPinCode from '../../terminal-sign-in-with-pin-code';
import t from '../translate';
import { Member } from './member';
import { setScrollable } from './utils';

const { debounceEvent } = eventUtils;

const activeTimeCategories = {
  overlayText: 'dragHereToClockInWithTimeCategory',
  overlayIcon: 'tags',
};

const Overlay = ({ icon, text, hover, styles, isOnClockInColumn }) => (
  <div className={css(styles.overlay, isOnClockInColumn && styles.rowOverlay, hover && styles.hoverOverlay)}>
    <div className={css(styles.overlayContent)}>
      <Icon name={icon} className={css(styles.overlayIcon)} />
      {t(text)}
    </div>
  </div>
);

const MembersClockInWithTC = ({
  styles,
  setOpenedTimeCategories,
  setIsOverTimeCategories,
  canDrop,
  canClockInWithTimeCategories,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ['0', '1', '2'],
    drop: () => setOpenedTimeCategories(true),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  useMemo(() => setIsOverTimeCategories(isOver), [isOver]);

  return (
    <div ref={drop}>
      {isOver && canDrop && canClockInWithTimeCategories && (
        <Overlay
          styles={styles}
          icon={activeTimeCategories.overlayIcon}
          text={activeTimeCategories.overlayText}
          hover
          isOnClockInColumn
        />
      )}
      {!isOver && canDrop && canClockInWithTimeCategories && (
        <Overlay
          styles={styles}
          icon={activeTimeCategories.overlayIcon}
          text={activeTimeCategories.overlayText}
          isOnClockInColumn
        />
      )}
    </div>
  );
};

const MemberComponent = ({ member, memberStyles, memberType, setIsOverlayOpen, setMemberForOverlay }) =>
  React.useMemo(
    () => (
      <Member
        member={member}
        memberStyles={memberStyles}
        memberType={memberType}
        setIsOverlayOpen={setIsOverlayOpen}
        setMemberForOverlay={setMemberForOverlay}
      />
    ),
    [member]
  );

const MembersColumn = (props) => {
  const {
    getSettingsForMember,
    style,
    memberStyles,
    headingText,
    onStartBreak,
    onStartWork,
    onStopWork,
    overlayText,
    overlayIcon,
    members = [],
    memberType,
    moveMemberToList,
    canMoveMemberToList,
    setIsOverlayOpen,
    setMemberForOverlay,
    isOnClockInColumn,
    isDroppingFromBreakColumn,
    crewHasTimeCategories,
  } = props;

  const columnRef = useRef(null);
  const [scrolledToBottom, isScrolledToBottom] = useState(false);
  const [requestMemberPassword, setRequestMemberPassword] = useState();
  const [openedTimeCategories, setOpenedTimeCategories] = useState(false);
  const [isOverTimeCategories, setIsOverTimeCategories] = useState(false);
  const [selectedTimeCategory1Id, setSelectedTimeCategory1Id] = useState(undefined);
  const [selectedTimeCategory2Id, setSelectedTimeCategory2Id] = useState(undefined);
  const [usePassword, setUsePassword] = useState(false);
  const [scrollable, isScrollable] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const timeTrackingAPICalls = {
    0: onStopWork,
    1: onStartWork,
    2: onStartBreak,
  };

  const [{ isOver, canDrop, item: selectedItem }, drop] = useDrop({
    accept: ['0', '1', '2'],
    canDrop: (item) => canMoveMemberToList(item, memberType, setOpenedTimeCategories),
    drop: (item) =>
      getSettingsForMember(item.member.userId)?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.NONE
        ? onSuccess(item)
        : setRequestMemberPassword(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const handleResize = debounceEvent(
    () =>
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      }),
    100
  );

  const handleScroll = (event) => {
    const target = event.target;
    // Check if scrolled completely to bottom of div
    target.scrollHeight - target.scrollTop === target.clientHeight
      ? isScrolledToBottom(true)
      : isScrolledToBottom(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // This timeout is necessary to allow React to get the scrollHeight from the useRef!
    setTimeout(() => setScrollable(columnRef, isScrollable), 0);
  }, [members.length, dimensions]);

  const onSuccess = (droppedItem) => {
    setRequestMemberPassword(undefined);
    setUsePassword(false);
    if (!openedTimeCategories) {
      setSelectedTimeCategory1Id(undefined);
      setSelectedTimeCategory2Id(undefined);
    }
    setOpenedTimeCategories(false);
    timeTrackingAPICalls[memberType](
      {
        ...droppedItem.member,
        timestamp: new Date().toISOString(),
        useLocation: LOCATION_OPTIONS[getSettingsForMember(droppedItem.member.userId)?.timeTrackingAllowedTerminal],
        clockInTimeCategory1Id: selectedTimeCategory1Id,
        clockInTimeCategory2Id: selectedTimeCategory2Id,
      },
      () =>
        moveMemberToList({
          ...droppedItem,
          destinationType: memberType,
          setSelectedTimeCategory1Id,
          setSelectedTimeCategory2Id,
        })
    );
  };

  const clockInWithTimeCategories = (timeCategory1Id, timeCategory2Id) => {
    setSelectedTimeCategory1Id(timeCategory1Id);
    setSelectedTimeCategory2Id(timeCategory2Id);
  };

  const memberSetting = getSettingsForMember(requestMemberPassword?.member.userId);

  const canClockInWithTimeCategories =
    crewHasTimeCategories &&
    isOnClockInColumn &&
    !isDroppingFromBreakColumn &&
    memberSetting?.terminalAuthenticationMode !== TERMINAL_AUTHENTICATION_MODES.NONE;

  const styles = stylesWithAlignment[canClockInWithTimeCategories ? 'row' : 'column'];

  return (
    <React.Fragment>
      <If
        condition={
          !!requestMemberPassword && memberSetting?.terminalAuthenticationMode !== TERMINAL_AUTHENTICATION_MODES.NONE
        }
      >
        <Modal
          className={css(styles.modal)}
          isOpen={!!requestMemberPassword}
          onClose={() => {
            setRequestMemberPassword(undefined);
            setUsePassword(false);
          }}
          preventBackgroundScrolling
        >
          <If condition={openedTimeCategories}>
            <TerminalMemberTimeTrackingInfo
              clockInWithTimeCategories={clockInWithTimeCategories}
              member={requestMemberPassword?.member}
              theme={THEMES.TURQUOISE}
              withoutButtons
              withoutClockedInSinceInStampWatch
              withoutDateInfo
              withoutDateTimeInfo
              withoutTimeInfo
              withoutTimeTrackingInfo
              withoutNotes
            />
          </If>
          <If
            condition={
              memberSetting?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.PASSWORD || usePassword
            }
          >
            <TerminalSignIn
              className={css(styles.terminalSignInContainer)}
              crewId={requestMemberPassword.member.crewId}
              userId={requestMemberPassword.member.userId}
              setPreventClosing={() => {}}
              success={() => onSuccess(requestMemberPassword)}
              withoutHeader
              withoutRedirection
            />
          </If>
          <If
            condition={
              memberSetting?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.PIN_CODE && !usePassword
            }
          >
            <div className={css(styles.pinCodeInputContainer)}>
              <TerminalSignInWithPinCode
                userId={requestMemberPassword.member.userId}
                label={t('enter4DigitPin')}
                success={() => onSuccess(requestMemberPassword)}
                wide
                labelSmall
              />
              <div className={css(styles.pinCodeInputButtonsContainer)}>
                <Button className={css(styles.noTextTransform)} narrow inline onClick={() => setUsePassword(true)}>
                  {t('usePassword')}
                </Button>
                <Button
                  className={css(styles.noTextTransform)}
                  narrow
                  inline
                  onClick={() => {
                    setRequestMemberPassword(undefined);
                    setUsePassword(false);
                  }}
                >
                  {t('cancel')}
                </Button>
              </div>
            </div>
          </If>
        </Modal>
      </If>

      <div className={css(styles.columnWrapper, style, selectedItem && !canDrop && styles.cannotDrop)} ref={drop}>
        <div className={css(styles.column, selectedItem && styles.opacity)}>
          <h1 className={css(styles.columnHeading)}>{t(headingText)}</h1>
          <ul className={css(styles.list)} ref={columnRef} onScroll={handleScroll}>
            {members.map((member) => (
              <MemberComponent
                {...props}
                key={`member-component-${member.userId}`}
                member={member}
                memberStyles={memberStyles}
                setIsOverlayOpen={setIsOverlayOpen}
                setMemberForOverlay={setMemberForOverlay}
              />
            ))}
          </ul>
          <If condition={scrollable && !scrolledToBottom}>
            <ArrowDownwardIcon
              style={{ position: 'absolute', fontSize: 36, color: COLORS.ORANGE_MAIN, bottom: 30, right: 20 }}
            />
          </If>
        </div>
        {isOver && canDrop && !isOverTimeCategories && (
          <Overlay styles={styles} icon={overlayIcon} text={overlayText} hover />
        )}
        {canDrop && <Overlay styles={styles} icon={overlayIcon} text={overlayText} />}
        <MembersClockInWithTC
          styles={styles}
          setOpenedTimeCategories={setOpenedTimeCategories}
          setIsOverTimeCategories={setIsOverTimeCategories}
          canDrop={canDrop}
          canClockInWithTimeCategories={canClockInWithTimeCategories}
        />
      </div>
    </React.Fragment>
  );
};

const stylesWithAlignment = {
  ['column']: StyleSheet.create({
    columnWrapper: {
      position: 'relative',
      padding: '0 32px',
    },
    cannotDrop: {
      backgroundColor: COLORS.WHITE,
    },
    column: {
      height: 'calc(100vh - 78px)',
    },
    opacity: {
      opacity: 0.3,
    },
    columnHeading: {
      margin: '14px 16px 12px 12px',
      fontSize: 24,
      fontWeight: 600,
    },
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      overflowY: 'scroll',
      maxHeight: 'calc(100vh - 117px)',
      /* hide the scrollbar in webkit based browsers - Chrome, Edge, Safari */
      '::-webkit-scrollbar': {
        display: 'none',
      },
      /* hide the scrollbar in mozilla based browsers - Firefox */
      scrollbarWidth: 'none',
      /* hide the scrollbar in Internet Explorer browsers - IE 10/11 */
      overflowStyle: 'none',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      padding: '9px',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: COLORS.TURQUOISE_MAIN,
      backgroundColor: COLORS.TURQUOISE_LIGHT_2,
      fontSize: 24,
      fontWeight: 600,
    },
    hoverOverlay: {
      boxShadow: `inset 0 0 16px 0 ${COLORS.TURQUOISE_MAIN}`,
      border: `solid 1px ${COLORS.TURQUOISE_MAIN}`,
    },
    overlayContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    overlayIcon: {
      fontSize: 80,
      fontWeight: 100,
      marginBottom: 16,
    },
    terminalSignInContainer: {
      padding: 20,
    },
    pinCodeInputContainer: {
      padding: 48,
    },
    pinCodeInputButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 32,
    },
    noTextTransform: {
      textTransform: 'none !important',
    },
  }),
  ['row']: StyleSheet.create({
    columnWrapper: {
      position: 'relative',
      padding: '0 32px',
    },
    cannotDrop: {
      backgroundColor: COLORS.WHITE,
    },
    column: {
      height: 'calc(100vh - 78px)',
    },
    opacity: {
      opacity: 0.3,
    },
    columnHeading: {
      margin: '14px 16px 12px 12px',
      fontSize: 24,
      fontWeight: 600,
    },
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      overflowY: 'scroll',
      maxHeight: 'calc(100vh - 117px)',
      /* hide the scrollbar in webkit based browsers - Chrome, Edge, Safari */
      '::-webkit-scrollbar': {
        display: 'none',
      },
      /* hide the scrollbar in mozilla based browsers - Firefox */
      scrollbarWidth: 'none',
      /* hide the scrollbar in Internet Explorer browsers - IE 10/11 */
      overflowStyle: 'none',
    },
    overlay: {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '50%',
      width: '100%',
      padding: '9px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: COLORS.TURQUOISE_MAIN,
      backgroundColor: COLORS.TURQUOISE_LIGHT_2,
      fontSize: 24,
      fontWeight: 600,
    },
    rowOverlay: {
      position: 'absolute',
      left: 0,
      top: '50%',
      height: '50%',
      width: '100%',
      padding: '9px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: COLORS.TURQUOISE_MAIN,
      backgroundColor: COLORS.TURQUOISE_LIGHT_2,
      fontSize: 24,
      fontWeight: 600,
      borderTop: `solid 1px ${COLORS.TURQUOISE_MAIN}`,
    },
    hoverOverlay: {
      boxShadow: `inset 0 0 16px 0 ${COLORS.TURQUOISE_MAIN}`,
      border: `solid 1px ${COLORS.TURQUOISE_MAIN}`,
    },
    overlayContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    overlayIcon: {
      fontSize: 80,
      fontWeight: 100,
      marginBottom: 16,
    },
    terminalSignInContainer: {
      padding: 20,
    },
    pinCodeInputContainer: {
      padding: 48,
    },
    pinCodeInputButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 32,
    },
    noTextTransform: {
      textTransform: 'none !important',
    },
  }),
};

export { MembersColumn };
