import React from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import TimeTrackingStampWatch from '../../containers/time-tracking-stamp-watch';
import Modal from '../../components/core/modal';
import Button from '../../components/core/button';
import { BREAKPOINTS, IDS_FOR_GOOGLE_TAG_MANAGER } from '../../constants';
import LoadingSpinnerWithText from '../../components/core/loading-spinner-with-text';
import MemberBadge from '../../components/core/member-badge';
import InactivityAction from '../../components/core/inactivity-action';
import t from './translate';

const Component = ({ member, userId, crewId, onSignOutUser, onEnterUserApp, isOpen }) => {
  return (
    <div className={css(styles.container)}>
      <InactivityAction
        callback={onSignOutUser}
        startAfterSeconds={10}
        maxInactivitySeconds={30}
        isActive
        message={t('inactivity')}
      />
      <Modal
        idForCloseButton={IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL.TIME_TRACKING.CLOSE}
        isOpen={isOpen}
        fullscreen
        padding
        onClose={onSignOutUser}
        showCloseButton
        alignCenter
      >
        <Choose>
          <When condition={member}>
            <div className={css(styles.innerContainer)}>
              <div className={css(styles.memberBadge)}>
                <MemberBadge member={member} maxWidth />
              </div>

              <TimeTrackingStampWatch
                id={IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL.TIME_TRACKING.NAME}
                crewId={crewId}
                userId={userId}
                onSummaryClose={onSignOutUser}
                channel={'Terminal'}
                unmemoized
                inTerminal
              />

              <Button
                id={IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL.TIME_TRACKING.ENTER_FULL_APP}
                narrow
                onClick={onEnterUserApp}
                spacingTop
                inline
                className={css(styles.enterUserAppButton)}
              >
                <span data-e2e-test="terminal-back-to-app">{t('enterUserApp')}</span>
              </Button>
            </div>
          </When>
          <Otherwise>
            <LoadingSpinnerWithText />
          </Otherwise>
        </Choose>
      </Modal>
    </div>
  );
};

const styles = StyleSheet.create({
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
    [BREAKPOINTS.mobilePortraitMode]: {
      display: 'block',
    },
  },
  memberBadge: {
    position: 'absolute',
    top: 20,
    left: 0,
    width: '100%',
    [BREAKPOINTS.mobilePortraitMode]: {
      position: 'static',
      marginBottom: '20px',
    },
  },
  container: {
    textAlign: 'center',
  },
  enterUserAppButton: {
    position: 'absolute !important',
    bottom: 20,
    right: 20,
    [BREAKPOINTS.mobilePortraitMode]: {
      position: 'static !important',
    },
  },
});

export default Component;
