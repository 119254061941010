const errors = {
  NO_VALID_EMAIL: 'Please enter a valid email address.',
  USER_IDENTIFIER_MISSING: 'Please enter email or username.',
  MISSING_NAME: 'Please enter your name.',
  TERMS_NOT_ACCEPTED: 'Please accept the terms.',
  MISSING_PASSWORD: 'Please enter a password.',
  REENTER_PASSWORD: 'Please enter the password again.',
  PASSWORDS_MISMATCH: "Passwords don't match.",

  INVALID_PWD_RESET_TOKEN: 'Invalid password reset token.',
  PWD_RESET_TOKEN_MISSING: "Token missing, can't reset password, please see your email or go to reset-pwd page!",

  INVALID_CREDENTIALS: 'Sign-in failed. Wrong credentials?',
  USER_WITHOUT_EMAIL: 'No email given for this username, ask crew owner to reset password.',
  USER_NOT_FOUND: 'No user found, please check again.',
  UNKNOWN_NETWORK_ERROR: 'A connection error occured. Are you online? Please retry.',
  UNKNOWN_ERROR: 'Unknown error. Please try again!',

  AUTOMATIC_SIGN_IN_FAILED: 'Automatic sign-in failed. Please go to sign-in page and sign-in with your credentials.',
};

export default errors;
