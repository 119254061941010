import { factory, sequence } from 'stahlwerk';
import { TIME_ACCOUNTS } from '../constants';

const crewId = sequence();
const location = '+48.1235298+11.6000145/';
const userId = sequence();

const startStamp = {
  clockInTimeStamp: '2000-01-01T12:00:00',
  crewId,
  location,
  timeAccount: TIME_ACCOUNTS.WORKING,
  timeCategory1Id: sequence(),
  timeCategory2Id: sequence(),
  timestamp: `2000-01-01T12:00:00`,
  note: sequence((id) => `Just a random note with id ${id}`),
  userId,
};

const stopStamp = {
  clockInTimeStamp: '2000-01-01T12:00:00',
  crewId,
  location,
  timeAccount: TIME_ACCOUNTS.NOT_WORKING,
  timeCategory1Id: sequence(),
  timeCategory2Id: sequence(),
  timestamp: '2000-01-01T13:00:00',
  note: sequence((id) => `Just a random note with id ${id}`),
  userId,
};

export const createPeriod = factory({
  stamps: [startStamp, stopStamp],
  startStamp,
  stopStamp,
  ...startStamp,
  breakDuration: 'PT120S',
  breakTimestamp: '2000-01-01T12:40:00',
  startTimestamp: startStamp.timestamp,
  stopTimestamp: startStamp.timestamp,
});
