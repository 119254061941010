import { createSelector } from 'reselect';
import { selectCurrentCrewId } from './crews-selectors';
import { createPropsSelector } from './utils';

export const selectTimeCategoriesUnsorted = (state) => state.bo.timeCategories.timeCategories;

export const selectTimeCategories = createSelector(selectTimeCategoriesUnsorted, (timeCategories) =>
  timeCategories.concat().sort((a, b) => {
    if (a.id === null) {
      return -1;
    }
    if (b.id === null) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  })
);

export const selectTimeCategoriesForCurrentCrew = createSelector(
  selectTimeCategories,
  selectCurrentCrewId,
  (timeCategories, crewId) => timeCategories.filter((timeCategory) => timeCategory.crewId === crewId)
);

export const selectFistTimeCategoriesForCurrentCrew = createSelector(
  selectTimeCategoriesForCurrentCrew,
  (timeCategories) => timeCategories.filter(({ level }) => level === 1)
);

export const selectSecondTimeCategoriesForCurrentCrew = createSelector(
  selectTimeCategoriesForCurrentCrew,
  (timeCategories) => timeCategories.filter(({ level }) => level === 2)
);

export const selectTimeCategoriesInCurrentCrew = createSelector(selectTimeCategoriesForCurrentCrew, (timeCategories) =>
  timeCategories.filter((timeCategory) => !timeCategory.disabled)
);

export const selectEnabledTimeCategoriesInCurrentCrew = createSelector(
  selectTimeCategoriesInCurrentCrew,
  (timeCategories) => timeCategories.filter((timeCategory) => !timeCategory.disabled)
);

export const selectEnabledFirstTimeCategoriesInCurrentCrew = createSelector(
  selectFistTimeCategoriesForCurrentCrew,
  (timeCategories) => timeCategories.filter((timeCategory) => !timeCategory.disabled)
);

export const selectEnabledSecondTimeCategoriesInCurrentCrew = createSelector(
  selectSecondTimeCategoriesForCurrentCrew,
  (timeCategories) => timeCategories.filter((timeCategory) => !timeCategory.disabled)
);

export const selectTimeCategoriesByProps = createPropsSelector(selectTimeCategoriesForCurrentCrew);
