import { endOfDay, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../action-creators';

import { PERMISSIONS, THEMES } from '../../constants';
import { api, callFunctionWhenPropsChange, channel } from '../../lib';
import * as selectors from '../../selectors';
import { collections, dataTypes, react, string } from '../../utils';

import TimeTrackingStampWatch from './organism/index';
import {
  loadActiveStamps,
  onStartBreak,
  onStartWork,
  onStopWork,
  onUpdateBookingsAndDurations,
  onUpdateBreak,
  onUpdateNote,
} from './utils';

const {
  addErrorNotification,
  addInfoNotification,
  addSuccessNotification,
  loadCrewSettings,
  loadCrewMembers,
  loadTimeCategories,
} = actions;
const {
  findPermissionsByProps,
  readUiValue,
  selectCurrentCrew,
  selectEnabledTimeCategoriesInCurrentCrew,
  selectEnabledTimeCategoriesInCurrentCrewForTerminal,
  selectOrderedActiveStampChainForUserId,
  selectLastValidStamp,
  selectSettingsForMemberOrCrew,
  selectDurationsByProps,
  selectBookingsByProps,
} = selectors;
const { composeReverse } = collections;
const { prepareProps, withUIState } = react;
const { isNumber } = dataTypes;
const { capitalize } = string;

const mapStateToProps = (state, props) => {
  const deleteProgress = readUiValue(state, 'timeTrackingDeleteProgress');
  const activeTimeCategoriesForTerminal = selectEnabledTimeCategoriesInCurrentCrewForTerminal(state, props);
  const dontShowDefaultTimeCategories = props.inTerminal && activeTimeCategoriesForTerminal.length === 0;
  const timeCategories = props.withoutTimeCategories ? [] : selectEnabledTimeCategoriesInCurrentCrew(state, props);
  const start = startOfDay(new Date());
  const end = endOfDay(new Date());
  const bookingsForUserCrewInRange = selectBookingsByProps(state, {
    userId: props.userId,
    crewId: props.crewId,
    start,
    end,
    filter: (duration) =>
      duration.userId === props.userId &&
      duration.crewId === props.crewId &&
      new Date(duration.from) >= start &&
      new Date(duration.to) <= end,
  });

  const durationsForUserCrewInRange = selectDurationsByProps(state, {
    userId: props.userId,
    crewId: props.crewId,
    start,
    end,
    filter: (duration) =>
      duration.userId === props.userId &&
      duration.crewId === props.crewId &&
      new Date(duration.from) >= start &&
      new Date(duration.to) <= end,
  });
  const lastValidStamp = selectLastValidStamp(state, { crewId: props.crewId, userId: props.userId });
  const lastStamp = lastValidStamp !== undefined ? lastValidStamp.clockInTimestamp : undefined;

  return {
    crew: selectCurrentCrew(state, props),
    timeCategories: timeCategories,
    timestampChain: selectOrderedActiveStampChainForUserId(state, props),
    crewSettings: selectSettingsForMemberOrCrew(state, props),
    canEditTimeTrackingStamps: findPermissionsByProps(
      state,
      {
        userId: props.userId,
        crewId: props.crewId,
        name: 'canEditTimeTrackingStamps',
      },
      {},
      'allowed'
    ),
    canSeeSecondTimeCategory: findPermissionsByProps(
      state,
      { crewId: props.crewId, name: PERMISSIONS.SECOND_TIME_CATEGORY },
      {},
      'allowed'
    ),
    disableStampWatch: isNumber(deleteProgress) && deleteProgress < 100,
    bookingsForUserCrewInRange: bookingsForUserCrewInRange,
    durationsForUserCrewInRange: durationsForUserCrewInRange,
    lastStamp,
    dontShowDefaultTimeCategories,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    functionToCall: () => {
      return Promise.all([
        /*
          Commented this line to fix the issue with the crew not being loaded 
          when the user switches frequently between crews in the terminal mode
          JIRA Ticket: https://crewmeister.atlassian.net/browse/CREW-10690
        */
        // ownProps.withoutCrews ? Promise.resolve() : dispatch(loadCrews({ api, source: '15' })),
        dispatch(loadCrewSettings({ api, crewId: ownProps.crewId })),
        dispatch(loadCrewMembers({ api, crewId: ownProps.crewId })),
        ownProps.withoutTimeCategories
          ? Promise.resolve()
          : dispatch(loadTimeCategories.unmemoized({ api, crewId: ownProps.crewId })),
        dispatch(loadActiveStamps(ownProps)),
      ]);
    },
    notify: {
      success: ({ message }) => dispatch(addSuccessNotification({ message })),
      info: ({ message }) => dispatch(addInfoNotification({ message })),
      error: ({ message }) => dispatch(addErrorNotification({ message })),
    },
    onStartWork: onStartWork(dispatch, ownProps),
    onStartBreak: onStartBreak(dispatch, ownProps),
    onStopWork: onStopWork(dispatch, ownProps),
    onUpdateNote: onUpdateNote(dispatch, ownProps),
    onUpdateBreak: onUpdateBreak(dispatch, ownProps),
    onUpdateBookingsAndDurations: onUpdateBookingsAndDurations(dispatch, ownProps),
  };
};

const TimeTrackingStampWatchContainer = composeReverse(
  withUIState('isSummaryScreenOpen', 'setIsSummaryScreenOpen', false),
  withUIState('isTimeCategoryOverlayOpen', 'setIsTimeCategoryOverlayOpen', false),
  withUIState('selectedTimeCategory1Id', 'setSelectedTimeCategory1Id', null),
  withUIState('selectedTimeCategory2Id', 'setSelectedTimeCategory2Id', null),
  prepareProps((props) => ({
    ...props,
    channel: props.channel || capitalize(channel),
    theme: props.theme === THEMES.TURQUOISE || props.theme === THEMES.TURQUOISE_WHITE ? props.theme : THEMES.ORANGE,
    alignment: props.alignment === 'vertical' ? props.alignment : 'horizontal',
  })),
  connect(mapStateToProps, mapDispatchToProps),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId', 'userId'] })
)(TimeTrackingStampWatch);

TimeTrackingStampWatchContainer.propTypes = {
  crewId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  displaySmall: PropTypes.bool,
  onSummaryClose: PropTypes.func,
};

TimeTrackingStampWatchContainer.displayName = 'TimeTrackingStampWatchContainer';

export default TimeTrackingStampWatchContainer;
