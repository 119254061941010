import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadDayKindCalendar = memoize(
  {},
  ({ api, crewId, from, to, userId, dayKindId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_DAY_KIND_CALENDAR',
        fnParams: { crewId, from, to, ...(userId ? { userId } : {}), ...(dayKindId ? { dayKindId } : {}) },
        fn: (params) => api.dayKindCalendar(params),
      })
);
