import produce from 'immer';
import { AnyAction } from 'redux';

import { applyMinimalChangesToArray } from '@crewmeister/shared/src/utils/array';
import { ACTION_NAMES } from '../../constants';
import { V3MemberRolesContent } from '../../types';

type V3MemberRolesAction = AnyAction & { crewId: number; result: { content: V3MemberRolesContent[] } };

type V3MemberRolesSliceInRedux = { memberRoles: V3MemberRolesContent[]; memberRolesLoaded: boolean };

const initialState = produce({ memberRoles: [], memberRolesLoaded: false }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const v3MemberRolesReducer = (state = initialState, action: V3MemberRolesAction): V3MemberRolesSliceInRedux =>
  produce(state, (draft: V3MemberRolesSliceInRedux) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_LOAD_MEMBER_ROLES}_SUCCESS`:
        applyMinimalChangesToArray(draft.memberRoles, action.result.content, (element) => {
          return element.crewId === action.crewId && (!action.userId || element.userId === action.userId);
        });
        draft.memberRolesLoaded = true;
        break;

      case `${ACTION_NAMES.V3_CREATE_MEMBER_ROLE}_SUCCESS`: {
        const createdMemberRole = action.result?.resourceAfterWrite;
        draft.memberRoles = draft.memberRoles.concat(createdMemberRole);
        break;
      }
      case `${ACTION_NAMES.V3_UPDATE_MEMBER_ROLE}_SUCCESS`: {
        const idToBeUpdated = action.result?.request?.id;
        const updatedMemberRole = action.result?.resourceAfterWrite;
        draft.memberRoles = draft.memberRoles.map((memberRole) => {
          if (memberRole.id === idToBeUpdated) {
            return updatedMemberRole;
          }
          return memberRole;
        });
        break;
      }
      case `${ACTION_NAMES.V3_DELETE_MEMBER_ROLE}_SUCCESS`: {
        const deletedMemberRoleId = action.result?.request?.id;
        // remove deleted team role from the list

        draft.memberRoles = draft.memberRoles.filter((memberRole) => memberRole.id !== deletedMemberRoleId);

        break;
      }
      default:
      // nothing to do => immer returns the original object
    }
  });
