/**
 * This is a general module for doing query params manipulations. In an application
 * there are certain parameters which you want to keep on certain page transitions
 * and others which should be removed on any transition. An example would be the
 * selected date in our absence management calendar. Switching to a different time
 * account shouldn't change the selected date, but any other filter like an absenceId
 * should be removed. That's why the query params are divided into 2 groups:
 * - temporary params
 * - persistant params
 *
 * Temporary params are prefixed with an underscore so that we can detect while reading
 * from window.location. Peristant params don't have this prefix.
 *
 * Creating a persistant param can be archived like this:
 *
 * stringifyPropertiesFromObject({
 *   aPersistantParam: persistParam('persistant'),
 *   aTempParam: 'not persistant',
 * });
 *
 * On a route change the temporary params can be removed easily (this isn't done automatically...)
 *
 * removeTemporaryPropertiesFromSearch(search)
 */

import qs from 'query-string';
import { dataTypes, object as objectUtils } from '../utils';

const { pick } = objectUtils;
const { isArray, isNumber } = dataTypes;

export const parseSearchValue = (value) => {
  if (isArray(value)) {
    return value.map((singleValue) => parseSearchValue(singleValue));
  }
  if (isNumber(value)) {
    return parseFloat(value);
  }
  if (typeof value !== 'string') {
    return value;
  }
  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
      return true;
    case 'false':
    case 'no':
      return false;
    default:
      return value;
  }
};

export const readPropertyFromLocation = (ownProps, key, defaultValue) => {
  const parsedProperties = parsePropertiesFromSearch(ownProps.location.search);
  return key in parsedProperties ? parsedProperties[key] : defaultValue;
};

export const writePropertyToLocation = (ownProps, key, value) => writePropertiesToLocation(ownProps, { [key]: value });

export const removePropertyFromLocation = (ownProps, key) => {
  const queryParams = parsePropertiesFromSearch(ownProps.location.search);
  const search = stringifyPropertiesFromObject({ ...queryParams, [key]: void 0 });
  ownProps.history.replace({ search });
  return queryParams[key];
};

/**
 * writePropertiesToLocation - Write properties to the ownProps location, and
 * update the URL with the corresponding parameters matching the properties.
 *
 * @param {object} ownProps - the props that contains a state called 'location'
 * @param {object} properties - the props that should be write to the location.
 *
 *   Example:
 *     {
 *       showAddons: true,
 *     }
 *
 *     will provoke the query parameter `_showAddons=true` being written on the
 *     URL and `ownProps.location.showAddons` should return true.
 *
 * @param {object} options object with the following possible properties:
 *    callback — a function that is called after the update and receives the query parameters as string;
 *    remove — an array which contains all the parameters one may want to remove;
 *    withHistoryReplace — a boolean, by default true, which will define if history.replace will be called or not.
 */
export const writePropertiesToLocation = (ownProps, properties, options = { callback: null, remove: [] }) => {
  const { callback, remove, withHistoryReplace = true } = options;
  const queryParams = parsePropertiesFromSearch(ownProps.location.search);
  const search = stringifyPropertiesFromObject({
    ...queryParams,
    ...properties,
    ...(remove ? remove.reduce((obj, key) => ({ ...obj, [key]: void 0 }), {}) : {}),
  });
  if (callback) {
    callback(search);
  }

  if (withHistoryReplace) ownProps.history.replace({ search });
};

/**
 * writePropertiesToLocationWithHistory - a version of
 * #writePropertiesToLocation that stores the path with the parameters in the
 * history stack, so that the URL can be returned from the browser go back
 * action.
 *
 * @param {object} ownProps - the props that contains a state called 'location'
 * @param {object} properties - the props that should be write to the location.
 */
export const writePropertiesToLocationWithHistory = (ownProps, properties, options) => {
  const { remove } = options || { remove: [] };
  writePropertiesToLocation(ownProps, properties, {
    callback: (queryParams) => {
      const path = ownProps.location.pathname;
      ownProps.history.push(`${path}?${queryParams}`);
    },
    remove,
    withHistoryReplace: false,
  });
};

export const writeUnsetPropertiesToLocation = (ownProps, properties) => {
  const queryParams = parsePropertiesFromSearch(ownProps.location.search);
  const search = stringifyPropertiesFromObject({ ...properties, ...queryParams });
  ownProps.history.replace({ search });
};

export const persistParam = (value) => ({ persist: true, value });

export const parsePropertiesFromSearch = (search) => {
  const object = qs.parse(search, { arrayFormat: 'index' });
  return Object.keys(object).reduce(
    (aggregate, key) => ({
      ...aggregate,
      [key.replace(/^_/, '')]: parseSearchValue(object[key]),
    }),
    {}
  );
};

export const stringifyPropertiesFromObject = (object) => {
  const objectToBeConverted = Object.keys(object).reduce(
    (aggregate, key) => ({
      ...aggregate,
      ...(object[key] && object[key].hasOwnProperty('persist')
        ? { [key]: object[key].value }
        : { [`_${key}`]: object[key] }),
    }),
    {}
  );

  return qs.stringify(objectToBeConverted, { arrayFormat: 'index' });
};

export const removeTemporaryPropertiesFromSearch = (search) => {
  const object = qs.parse(search, { arrayFormat: 'index' });
  const keys = Object.keys(object).filter((key) => key[0] !== '_');
  return qs.stringify(pick(keys, { ...object }), { arrayFormat: 'index' });
};
