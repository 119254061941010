import curry from 'lodash.curry';
import { ignoreReturnFor, rethrowError, waitAtLeastSeconds } from 'promise-frites';
import React, { Component } from 'react';

import { ErrorType, FC } from '../types';
import { composeReverse } from './collections';
import { getEnv } from './env';

/**
 * actionWrapper - Retrieves a value in the cookies for the key 'DISABLE_UI_DELAYS' after
 *                 'initialValue' seconds
 *
 * @param {integer} initialValue time in seconds that will be used to delay the call to `getEnv` function
 *
 * @return {string} which may be
 *                  the value stored in the cookies for the desired key or
 *                  'DISABLE_UI_DELAYS', otherwise, if the desired value isn't stored in the cookies
 */
const actionWrapper = composeReverse(
  waitAtLeastSeconds(getEnv(process.env.DISABLE_UI_DELAYS, 'DISABLE_UI_DELAYS') ? 0 : 0.5)
);

/**
 * @class
 * ComponentData - Renders a simple component with the given props but additionally it implements the functions
 *                 (1) componentDidMount, called immediately after the component is mounted,
 *                 (2) componentWillUnmount, called immediately before the component is about to be unmounted,
 *                 (3) shouldComponentUpdate, called to check if a re-render should be triggered,
 *                 (4) componentDidUpdate, called immediately after the component is updated,
 */
type ComponentDataProps = {
  Component: React.FC<any>;
  onComponentMount?: (props: ComponentDataProps) => void;
  onComponentUnmount?: (props: ComponentDataProps) => void;
  onComponentUpdate?: (props: ComponentDataProps, prevProps: ComponentDataProps, state: unknown) => void;
  shouldComponentUpdate?: (props: ComponentDataProps, prevProps: ComponentDataProps) => boolean;
};

class ComponentData extends Component<ComponentDataProps> {
  componentDidMount() {
    if (this.props.onComponentMount) {
      this.props.onComponentMount(this.props);
    }
  }

  componentWillUnmount() {
    if (this.props.onComponentUnmount) {
      this.props.onComponentUnmount(this.props);
    }
  }

  shouldComponentUpdate(nextProps: ComponentDataProps) {
    if (this.props.shouldComponentUpdate) {
      return this.props.shouldComponentUpdate(this.props, nextProps);
    }
    return true;
  }

  componentDidUpdate(prevProps: ComponentDataProps, prevState: unknown) {
    if (this.props.onComponentUpdate) {
      this.props.onComponentUpdate(this.props, prevProps, prevState);
    }
  }

  render() {
    const Component = this.props.Component;
    return <Component {...this.props} />;
  }
}

/**
 * getDisplayName - Retrieves the WrappedComponent's name or in case there is none defined returns
 *                  a generic name
 *
 * @param {component} WrappedComponent which one may want to retrieve its name
 *
 * @return {string} which may be the value of the property 'displayName' or 'name' of @param WrappedComponent,
 *                  if one of them is defined, otherwise it will be returned the string 'Component'
 */
const getDisplayName = (WrappedComponent: React.FC): string =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

/**
 * onComponentMount - Generates a curried function that returns a component which implements the function
 *                    'componentMountFn'
 *
 * @param {function} componentMountFn function that one may want to execute right after the component
 *                                    has been mounted
 * @param {component} Component
 * @param {any} props props that will be passed to the component and to 'componentMountFn'
 *
 * @return {function} curried function that returns a component which implements the function 'componentMountFn'
 */
export const onComponentMount = curry((componentMountFn: () => void, Component: FC, props: Record<string, unknown>) => (
  <ComponentData {...props} Component={Component} onComponentMount={componentMountFn} />
));

/**
 * onComponentUnmount - Generates a curried function that returns a component which implements the function
 *                      'componentUnmountFn'
 *
 * @param {function} componentUnmountFn function that one may want to execute right before the component
 *                                      is about to get unmounted
 * @param {component} Component
 * @param {any} props props that will be passed to the component and to 'componentUnmountFn'
 *
 * @return {function} curried function that returns a component which implements the function 'componentUnmountFn'
 */
export const onComponentUnmount = curry(
  (componentUnmountFn: () => void, Component: FC, props: Record<string, unknown>) => (
    <ComponentData {...props} Component={Component} onComponentUnmount={componentUnmountFn} />
  )
);

/**
 * onComponentUpdate - Generates a curried function that returns a component which implements the function
 *                     'componentUpdateFn'
 *
 * @param {function} componentUpdateFn function that one may want to execute right after the component
 *                                     updates
 * @param {component} Component
 * @param {any} props props that will be passed to the component and to 'componentUpdateFn'
 *
 * @return {function} curried function that returns a component which implements the function 'componentUpdateFn'
 */
export const onComponentUpdate = curry(
  (componentUpdateFn: () => void, Component: FC, props: Record<string, unknown>) => (
    <ComponentData {...props} Component={Component} onComponentUpdate={componentUpdateFn} />
  )
);

/**
 * prepareProp - Generates a curried function that returns the given component with the
 *               given props passed to it and, additionally, a new prop is also passed with
 *               name 'propName' and value generated by the function 'fn' passing 'props'
 *               as the parameters
 *
 * @param {string} propName name of the new prop generated
 * @param {function} fn function that receives @param props as the params and generates @param propName's value
 * @param {component} Component
 * @param {any} props
 *
 * @return {function} curried function that returns a component with the given props passed to it plus
 *                    one prop which is generated by the given function
 *
 */
export const prepareProp = curry(
  (
    propName: string,
    fn: (props: Record<string, unknown>) => unknown,
    Component: FC,
    props: Record<string, unknown>
  ) => {
    try {
      return <Component {...props} {...{ [propName]: fn(props) }} />;
    } catch (e: any) {
      throw Error(`Couldn\'t prepare prop ${propName}: ${e.message}`);
    }
  }
);

/**
 * prepareProps - Generates a curried function that returns the given component with the
 *                given props to it after being processed by the function 'fn'
 *
 * @param {function} fn function that processes the @param props
 * @param {component} Component
 * @param {any} props
 *
 * @return {function} curried function that returns a component with the props processed by the function
 */
export const prepareProps = curry(
  (fn: (props: Record<string, any>) => Record<string, any>, Component: FC<any>, props: Record<string, any>) => (
    <Component {...fn(props)} />
  )
);

/**
 * @class
 * RefWrapper - Renders a component with its props and additionally it sets a reference to a prop called
 *              'propName' and allows to update its reference using the function 'registerName'
 */
type RefWrapperProps = {
  Component: React.FC<any>;
  propName: string;
  registerName: string;
};

class RefWrapper extends Component<RefWrapperProps> {
  ref?: RefWrapper;

  render() {
    const Component = this.props.Component;
    return (
      <Component
        {...this.props}
        {...{
          [this.props.registerName]: (ref: RefWrapper) => {
            this.ref = ref;
          },
        }}
        {...{ [this.props.propName]: this.ref }}
      />
    );
  }
}

/**
 * shouldComponentUpdate - Generates a curried function that returns a component which implements the function
 *                         'shouldComponentUpdateFn'
 *
 * @param {function} shouldComponentUpdateFn function that evaluates if the component should update
 * @param {component} Component
 * @param {any} props props that will be passed to the component and to 'shouldComponentUpdateFn'
 *
 * @return {function} curried function that returns a component which implements the function 'shouldComponentUpdateFn'
 */
export const shouldComponentUpdate = curry(
  (
    shouldComponentUpdateFn: (props: ComponentDataProps, prevProps: ComponentDataProps) => boolean,
    Component: FC,
    props: Record<string, unknown>
  ) => <ComponentData {...props} Component={Component} shouldComponentUpdate={shouldComponentUpdateFn} />
);

/**
 * @class
 * UiStateWrapper - Class that renders the given component with the given props and, additionally, it
 *                  sets a new prop with name 'stateName' and value 'props[props.stateName]' and it allows
 *                  to update this value using the function name 'props.stateUpdaterName'
 */
interface UiStateWrapperProps {
  [key: string]: any;
  _initialValue: unknown;
  stateName: string;
  stateUnsetName?: string;
  stateUpdaterName: string;
}

interface UiStateWrapperState {
  uiStateWasSet?: boolean;
}

class UiStateWrapper extends Component<UiStateWrapperProps, UiStateWrapperState> {
  unmounted: boolean;

  constructor(props: UiStateWrapperProps) {
    super(props);
    this.unmounted = false;
    this.state = { [props.stateName]: props[props.stateName] || this.initialValue() };
  }

  initialValue() {
    return typeof this.props._initialValue === 'function'
      ? this.props._initialValue(this.props)
      : this.props._initialValue;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const Component = this.props.Component;
    Component.displayName = 'UiStateWrapper';
    const updateFn = (value: unknown) =>
      new Promise((resolve) => {
        if (this.unmounted) return;
        this.setState({ uiStateWasSet: true, [this.props.stateName]: value }, () => resolve(value));
      });
    const unsetFn = () =>
      new Promise((resolve: (value?: unknown) => void) => {
        if (this.unmounted) return;
        this.setState({ uiStateWasSet: false, [this.props.stateName]: this.initialValue() }, resolve);
      });

    const props = this.state.uiStateWasSet ? { ...this.props, ...this.state } : { ...this.state, ...this.props };

    return (
      <Component
        {...props}
        {...(this.props.stateUnsetName ? { [this.props.stateUnsetName]: unsetFn } : {})}
        {...{ [this.props.stateUpdaterName]: updateFn }}
      />
    );
  }
}

/**
 * withLoadingState - Generates a curried function that will create a new pop called 'propertyName'
 *                    which is a boolean that when it is true it means that the function 'actionName'
 *                    is ongoing and false otherwise.
 *
 * @param {string} actionName function name that one may want to know if it is loading or not
 * @param {string} propertyName property name of a boolean variable which tells if the function
 *                              @param actionName is ongoing or not
 * @param {boolean} currentValue which is the initial value for @param propertyName's value
 *
 * @return {function} curried function that adds a new prop to a given component responsible for
 *                    telling if a given function is ongoing or not
 */
export const withLoadingState = (
  actionName: string,
  propertyName: string = 'isLoading',
  currentValue: boolean = false
) =>
  composeReverse(
    withUIState(propertyName, 'setLoading', currentValue, 'unsetLoading'),
    prepareProps((props: Record<string, any>) => ({
      ...props,
      [actionName]: (...args: Array<unknown>) =>
        Promise.resolve()
          .then(() => {
            if (props[propertyName] !== true) {
              props.setLoading(true);
            }
          })
          .then(actionWrapper(() => props[actionName](...args)))
          .then(ignoreReturnFor(() => props.setLoading(false)))
          .catch(rethrowError(() => props.setLoading(false))),
    }))
  );

/**
 * withRef - Adds to a given component reference to a prop called 'propName' and allows to update this
 *           reference by calling a function called 'registerName'
 *
 * @param {string} propName prop that one may want to have reference of
 * @param {string} registerName function name responsible for updating 'propName' reference
 *
 * @return {function} function which accepts a component as parameter and returns another function
 *                    which receives the component's props and sets reference to the 'propName' prop and
 *                    allows to update its reference by calling the function 'registerName'
 */
export const withRef = (propName: string, registerName: string) => (Component: FC) => (
  props: Record<string, unknown>
) => <RefWrapper {...props} Component={Component} propName={propName} registerName={registerName} />;

/**
 * withUIState - Generates a function that accepts a component and posteriorly its props in order to add
 *               to this component a new prop (stateName) with a function that updates it (stateUpdaterName)
 *
 * @param {string} stateName a new prop that one wants to pass to the component that will be passed posteriorly
 * @param {string} stateUpdaterName function name that can be used to update @param stateName value
 * @param {any} initialValue initial value that will be set initially to @param stateName
 * @param {string} stateUnsetName function name that can be used to unset the value of @param stateName
 *
 * @return {function} function that receives a component as a parameter and returns a function
 *                    that accepts its props that will be passed to the component and, additionally,
 *                    a new prop is set to this component with name @param stateName and value @param initialValue
 *                    and it allows to update this value using the function name @param props.stateUpdaterName
 */
export const withUIState = (
  stateName: string,
  stateUpdaterName: string,
  initialValue: unknown,
  stateUnsetName?: string
) => (Component: FC) => (props: Record<string, unknown>) => {
  const WithUiState = (UiStateWrapper as unknown) as React.FC<UiStateWrapperProps>;
  WithUiState.displayName = `WithUIState(${stateName} for ${getDisplayName(Component)})`;
  return (
    <WithUiState
      {...props}
      stateName={stateName}
      stateUpdaterName={stateUpdaterName}
      stateUnsetName={stateUnsetName}
      _initialValue={initialValue}
      Component={Component}
    />
  );
};
