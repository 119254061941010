import { createSelector } from 'reselect';
import { createPropsFinder, createPropsSelector } from './utils';

export const selectRoundingRules = (state) => state.bo.roundingRules.roundingRules;

export const selectRoundingRulesForCurrentCrew = createSelector(
  selectRoundingRules,
  (_, props) => props.crewId,
  (roundingRules, crewId) => roundingRules.filter((roundingRule) => roundingRule.crewId === crewId)
);

export const selectRoundingRulesByProps = createPropsSelector(selectRoundingRules);
export const findRoundingRulesByProps = createPropsFinder(selectRoundingRules);
