import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES } from '../../constants';

import Checkbox from './checkbox';
import Icon from './icon';
import Image from './image';
import Toggle from './ToggleV1';

export const ListElement = ({
  avatarUrl,
  children,
  className,
  childrenClassName,
  disabledItem,
  iconName,
  id,
  index,
  inOrange,
  inOrangeLight,
  isSelected,
  onSelect,
  selectedRowClassName = '',
  style,
  withAvatar,
  withCheckbox,
  withoutSpaceBetween,
  withToggle,
}) => {
  const styles = getStyles({ withoutSpaceBetween });
  const preventClickForToggle = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  return (
    <div
      id={id}
      onClick={!disabledItem ? (evt) => onSelect(!isSelected, evt) : () => {}}
      className={`${css(
        styles.wrapper,
        inOrange && styles.inOrange,
        inOrangeLight && styles.inOrangeLight,
        disabledItem && styles.disabled
      )} ${className} ${isSelected && selectedRowClassName}`}
      style={style}
    >
      {withAvatar && <Image className={css(styles.image)} src={avatarUrl} />}
      <span className={`${css(styles.text)} ${childrenClassName}`}>{children}</span>
      <Choose>
        <When condition={withCheckbox}>
          <Checkbox
            className={css(styles.input, disabledItem && styles.disabledInput)}
            disabled={disabledItem}
            id={id ? `${id}-checkbox-${index}` : undefined}
            value={isSelected}
          />
        </When>
        <When condition={iconName}>
          <Icon name={iconName} className={css(styles.icon)} />
        </When>
        <When condition={withToggle}>
          <Toggle
            id={id ? `${id}-toggle-${index}` : undefined}
            checked={isSelected}
            disabled={disabledItem}
            onClick={preventClickForToggle}
          />
        </When>
      </Choose>
    </div>
  );
};

ListElement.propTypes = {
  withCheckbox: PropTypes.bool,
  withToggle: PropTypes.bool,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
};

const getStyles = ({ withoutSpaceBetween }) =>
  StyleSheet.create({
    wrapper: {
      ...FONT_STYLES.small,
      color: COLORS.GREY_DARK,
      fontSize: 16,
      display: 'flex',
      ...(withoutSpaceBetween ? {} : { justifyContent: 'space-between' }),
      alignItems: 'center',
      flex: 1,
      position: 'relative',
      cursor: 'pointer',
      lineHeight: '18px',
      ':hover': {
        backgroundColor: COLORS.GREY_EXTRA_LIGHT,
      },
    },
    image: {
      flexShrink: 1,
      display: 'block',
      borderRadius: '50%',
      width: 30,
      height: 30,
      marginRight: 10,
    },
    inOrange: {
      color: COLORS.ORANGE_DARK,
      ':hover': {
        backgroundColor: COLORS.ORANGE_MAIN_BACKGROUND,
      },
    },
    inOrangeLight: {
      ':hover': {
        backgroundColor: COLORS.ORANGE_OPAQUE,
      },
    },
    disabled: {
      color: COLORS.GREY_LIGHT,
      cursor: 'not-allowed',
    },
    text: {
      alignItems: 'center',
      display: 'flex',
      lineHeight: 1.2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'wrap',
    },
    icon: {
      color: COLORS.GREY_DARK,
      fontSize: 14,
      marginLeft: 16,
    },
    input: {
      marginLeft: 16,
    },
    disabledInput: {
      borderColor: COLORS.GREY_LIGHT,
      backgroundColor: `${COLORS.ORANGE_LIGHT} !important`,
    },
  });

export default ListElement;
