import produce from 'immer';
import { absence as absenceUtils, array, date } from '../utils';

const initialState = produce({ absences: [] }, () => {});

const { isSameAbsence } = absenceUtils;
const { areRangesOverlapping } = date;
const { applyMinimalChangesToArray } = array;

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const absencesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_ABSENCES_SUCCESS':
        applyMinimalChangesToArray(
          draft.absences,
          action.result,
          (absence) =>
            absence.crewId === action.crewId &&
            areRangesOverlapping(absence.startDate, absence.endDate, action.from, action.to)
        );
        break;
      case 'REQUEST_ABSENCE_SUCCESS':
      case 'CREATE_ABSENCE_SUCCESS':
      case 'GRANT_ABSENCE_SUCCESS':
      case 'REJECT_ABSENCE_SUCCESS':
        applyMinimalChangesToArray(draft.absences, action.result, (absence) => isSameAbsence(absence, action.result));
        break;
      case 'DESTROY_ABSENCE_SUCCESS':
        applyMinimalChangesToArray(draft.absences, [], (absence) => isSameAbsence(absence, action.result));
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
