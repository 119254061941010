import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const deleteShiftDraft = memoize(
  {},
  ({ api, crewId, guid }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'DELETE_SHIFT_DRAFT',
        fnParams: { crewId, guid },
        fn: (params) => api.deleteShiftDraft(params),
      })
);

export const transformShiftsDraft = memoize(
  { ttl: 0 },
  ({ api, crewId, values }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'TRANSFORM_SHIFTS_DRAFT',
        fnParams: { crewId, values },
        fn: (params) => api.transformShiftsDraft(params),
      })
);

export const publishShifts = memoize(
  { ttl: 0 },
  ({ api, crewId, guids }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'PUBLISH_SHIFTS',
        fnParams: { crewId, guids },
        fn: (params) => api.publishShifts(params),
      })
);
