import PropTypes from 'prop-types';
import React from 'react';

import Image from '../../components/core/image';

import { COLORS, IDS_FOR_GOOGLE_TAG_MANAGER, THEMES, TIME_ACCOUNTS } from '../../constants';
import { string } from '../../utils';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import TerminalTimeTrackingStampWatch from '../terminal-time-tracking-stamp-watch';

import t from './translate';

const { trimStringWithMultipleWords } = string;

export const Organism = ({
  activeTimeAccount,
  className,
  clockInWithTimeCategories,
  clockedInSince,
  member,
  showPauseInMinutes,
  success,
  theme,
  withoutButtons,
  withoutClockedInSinceInStampWatch,
  withoutDateInfo,
  withoutDateTimeInfo,
  withLoadingSpinner,
  withoutNotes,
  withoutTimeInfo,
  withoutTimeCategories,
  withoutTimeTrackingInfo,
  wide,
  onSummaryClose,
  notSignedIn,
  isIn3ColumnTerminalMode,
}) => {
  const stylesWithTimeAccounts = createStylesWithThemes(wide)[theme];
  const styleTimeAccount = activeTimeAccount || member.activeTimeAccount;
  const styles = stylesWithTimeAccounts[styleTimeAccount] || stylesWithTimeAccounts.default;

  return (
    <React.Fragment>
      <div className={css(withoutTimeTrackingInfo && styles.mainContainerTimeCategories, styles.mainContainer)}>
        <Image className={css(styles.memberPhoto)} src={member.avatarUrl || ''} alt={member.name} />
        <div className={css(styles.memberNameContainer)}>
          <div className={css(styles.memberName)}>{trimStringWithMultipleWords(member.name, 50, 18)}</div>
          <If condition={withoutClockedInSinceInStampWatch && styleTimeAccount !== TIME_ACCOUNTS.NOT_WORKING}>
            <div className={css(styles.clockedInSince)}>{t('clockedInSince', { time: clockedInSince })}</div>
          </If>
        </div>
      </div>

      <TerminalTimeTrackingStampWatch
        alignment="vertical"
        channel={'Terminal'}
        className={className}
        crewId={member.crewId}
        clockInWithTimeCategories={clockInWithTimeCategories}
        id={IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL.TIME_TRACKING.NAME}
        showPauseInMinutes={showPauseInMinutes}
        success={success}
        theme={theme}
        unmemoized
        userId={member.userId}
        wide={wide}
        withoutButtons={withoutButtons}
        withoutClockedInSince={withoutClockedInSinceInStampWatch}
        withoutDateInfo={withoutDateInfo}
        withoutDateTimeInfo={withoutDateTimeInfo}
        withLoadingSpinner={withLoadingSpinner}
        withoutMargin
        withoutNotes={withoutNotes}
        withoutTimeInfo={withoutTimeInfo}
        withoutTimeCategories={withoutTimeCategories}
        withoutTimeTrackingInfo={withoutTimeTrackingInfo}
        onSummaryClose={onSummaryClose}
        withoutCrews
        inTerminal
        notSignedIn={notSignedIn}
        isIn3ColumnTerminalMode={isIn3ColumnTerminalMode}
      />
    </React.Fragment>
  );
};

const createStylesWithThemes = (wide) => ({
  [THEMES.TURQUOISE]: {
    default: StyleSheet.create({
      mainContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      mainContainerTimeCategories: {
        paddingTop: 20,
      },
      memberPhoto: {
        backgroundColor: COLORS.WHITE,
        height: wide ? 178 : 85,
        width: wide ? 178 : 85,
        borderRadius: '50%',
      },
      memberPhotoFramed: {},
      memberName: {
        color: COLORS.TURQUOISE_MAIN,
        fontSize: wide ? 40 : 28,
        fontWeight: 600,
        letterSpacing: '0.03em',
        marginLeft: 16,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    }),
    [TIME_ACCOUNTS.WORKING]: StyleSheet.create({
      clockedInSince: {
        color: COLORS.TURQUOISE_MAIN,
        fontSize: 24,
        fontWeight: 600,
      },
      mainContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      memberPhoto: {
        backgroundColor: COLORS.WHITE,
        border: `3px solid ${COLORS.GREEN_TEXT}`,
        borderRadius: '50%',
        height: wide ? 178 : 85,
        width: wide ? 178 : 85,
      },
      memberPhotoFramed: {
        border: `3px solid ${COLORS.GREEN_TEXT}`,
      },
      memberName: {
        color: COLORS.TURQUOISE_MAIN,
        fontSize: wide ? 40 : 28,
        fontWeight: 600,
        letterSpacing: '0.03em',
        marginLeft: 16,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    }),
    [TIME_ACCOUNTS.BREAK]: StyleSheet.create({
      clockedInSince: {
        color: COLORS.TURQUOISE_MAIN,
        fontSize: 24,
        fontWeight: 600,
      },
      mainContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      memberPhoto: {
        backgroundColor: COLORS.WHITE,
        border: `3px solid ${COLORS.GREEN_TEXT}`,
        borderRadius: '50%',
        height: wide ? 178 : 85,
        width: wide ? 178 : 85,
      },
      memberPhotoFramed: {
        border: `3px solid ${COLORS.GREEN_TEXT}`,
      },
      memberName: {
        color: COLORS.TURQUOISE_MAIN,
        fontSize: wide ? 40 : 28,
        fontWeight: 600,
        letterSpacing: '0.03em',
        marginLeft: 16,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    }),
  },
  [THEMES.TURQUOISE_WHITE]: {
    default: StyleSheet.create({
      mainContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      memberPhoto: {
        backgroundColor: COLORS.WHITE,
        height: wide ? 140 : 85,
        width: wide ? 140 : 85,
        borderRadius: '50%',
      },
      memberName: {
        color: COLORS.TURQUOISE_MAIN,
        fontSize: wide ? 40 : 28,
        fontWeight: 600,
        letterSpacing: '0.03em',
        marginLeft: 16,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    }),
    [TIME_ACCOUNTS.WORKING]: StyleSheet.create({
      clockedInSince: {
        color: COLORS.WHITE,
        fontSize: 24,
        fontWeight: 600,
        marginLeft: 18,
      },
      mainContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      memberPhoto: {
        backgroundColor: COLORS.WHITE,
        border: `3px solid ${COLORS.GREEN_TEXT}`,
        borderRadius: '50%',
        height: wide ? 140 : 85,
        width: wide ? 140 : 85,
      },
      memberPhotoFramed: {
        border: `3px solid ${COLORS.GREEN_TEXT}`,
      },
      memberName: {
        color: COLORS.WHITE,
        fontSize: wide ? 40 : 28,
        fontWeight: 600,
        letterSpacing: '0.03em',
        marginLeft: 16,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      memberNameContainer: {
        display: 'flex',
        flexDirection: 'column',
      },
    }),
    [TIME_ACCOUNTS.BREAK]: StyleSheet.create({
      clockedInSince: {
        color: COLORS.TURQUOISE_MAIN,
        fontSize: 24,
        fontWeight: 600,
        marginLeft: 18,
      },
      mainContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      memberPhoto: {
        backgroundColor: COLORS.WHITE,
        border: `3px solid ${COLORS.GREEN_TEXT}`,
        borderRadius: '50%',
        height: wide ? 140 : 85,
        width: wide ? 140 : 85,
      },
      memberPhotoFramed: {
        border: `3px solid ${COLORS.GREEN_TEXT}`,
      },
      memberName: {
        color: COLORS.TURQUOISE_MAIN,
        fontSize: wide ? 40 : 28,
        fontWeight: 600,
        letterSpacing: '0.03em',
        marginLeft: 16,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    }),
  },
});

Organism.propTypes = {
  member: PropTypes.object.isRequired,
};

export default Organism;
