import produce from 'immer';
import { createSelector } from 'reselect';

import { selectCurrentCrewId } from '../crews-selectors';

import { V3TemplateType } from '../../types/v3/template';

export const v3SelectTemplates = (state: any): V3TemplateType[] =>
  state.boV3.templates.templates || produce([], () => {});

export const v3SelectCrewTemplates = createSelector(v3SelectTemplates, selectCurrentCrewId, (templates, crewId) =>
  templates.filter((template) => template.crewId === crewId)
);
