import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { collections, react } from '../../utils';
import * as actions from '../../action-creators';
import * as selectors from '../../selectors';

import { api, callFunctionWhenPropsChange, wrapWithNotification } from '../../lib';
import t from './translate';

import Organism from './organism/index';

const { createOrUpdateGeofence, deleteGeofence, loadGeofences, loadTimeCategories, updateTimeCategory } = actions;
const { selectGeofenceForTimeCategory } = selectors;
const { composeReverse } = collections;
const { withLoadingState } = react;

const mapStateToProps = (state, props) => ({
  ...props,
  geofence: selectGeofenceForTimeCategory(state, props),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  functionToCall: ({ crewId }) =>
    Promise.all([dispatch(loadTimeCategories({ api, crewId })), dispatch(loadGeofences({ api, crewId }))]),
  onRadiusChange: (radius) => ownProps.setRadius(radius),
  onSubmit: ({ markers, radius, id }) =>
    wrapWithNotification(
      async () => {
        await dispatch(
          createOrUpdateGeofence({
            api,
            latitude: `${markers[0].lat}`,
            longitude: `${markers[0].lng}`,
            radius,
            crewId: ownProps.crewId,
            groupId: ownProps.timeCategory.id,
            id,
          })
        );
      },
      { dispatch, success: t('createGeofenceSuccess'), error: t('createGeofenceError') }
    ),
  onDelete: ({ id }) =>
    wrapWithNotification(
      async () => {
        await dispatch(
          deleteGeofence({
            api,
            crewId: ownProps.crewId,
            id,
          })
        );
      },
      { dispatch, success: t('deleteGeofenceSuccess'), error: t('deleteGeofenceError') }
    ),
  onUpdateName: ({ name }) =>
    wrapWithNotification(
      async () => {
        await dispatch(
          updateTimeCategory({
            api,
            crewId: ownProps.crewId,
            id: ownProps.timeCategory.id,
            level: ownProps.level,
            name,
          })
        ).then(() => ownProps.setSelectedTimeCategory({ ...ownProps.timeCategory, name }));
      },
      { dispatch, success: t('updateNameSuccess'), error: t('updateNameError') }
    ),
  onUpdateStatus: (disabled) =>
    wrapWithNotification(
      async () => {
        await dispatch(
          updateTimeCategory({
            api,
            crewId: ownProps.crewId,
            id: ownProps.timeCategory.id,
            level: ownProps.level,
            disabled,
          })
        ).then(() => ownProps.setSelectedTimeCategory({ ...ownProps.timeCategory, disabled }));
      },
      {
        dispatch,
        success: disabled ? t('disableTimeCategorySuccess') : t('enableTimeCategorySuccess'),
        error: disabled ? t('disableTimeCategoryError') : t('enableTimeCategoryError'),
      }
    ),
});

const TimeCategoryUpdateContainer = composeReverse(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withLoadingState('onSubmit', 'isSubmitting'),
  withLoadingState('onDelete', 'isDeleting'),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId'] })
)(Organism);

TimeCategoryUpdateContainer.displayName = 'TimeCategoryUpdateContainer';

export default TimeCategoryUpdateContainer;
