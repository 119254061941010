import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../../constants';
import { V3Teams } from '../../types';

type V3TeamAction = AnyAction & { crewId: number; result: { content: V3Teams[] } };

type V3TeamsSliceInRedux = { teams: V3Teams[] };

const initialState = produce({ teams: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const v3TeamsReducer = (state = initialState, action: V3TeamAction): V3TeamsSliceInRedux =>
  produce(state, (draft: V3TeamsSliceInRedux) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_LOAD_TEAMS}_SUCCESS`:
        draft.teams = action.result.content;
        break;
      default:
      // nothing to do => immer returns the original object
    }
  });
