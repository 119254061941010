/**
 * getWindowHeight - Retrieves the window height, regardless of the browser used
 *
 * @param {object} window
 * @param {object} document
 *
 * @return {integer} integer which is the window width
 */
export const getWindowHeight = (window, document) =>
  window.innerHeight ||
  (document && document.documentElement && document.documentElement.clientHeight) ||
  (document && document.body && document.body.clientHeight);

/**
 * getWindowWidth - Retrieves the window width, regardless of the browser used
 *
 * @param {object} window
 * @param {object} document
 *
 * @return {integer} integer which is the window width
 */
export const getWindowWidth = (window, document) =>
  window.innerWidth ||
  (document && document.documentElement && document.documentElement.clientWidth) ||
  (document && document.body && document.body.clientWidth);

/**
 * scrollAfterRender - Make a scroll in the window after the window is completely loaded
 *
 * @param {object} window
 * @param {object} options:
 *    x: a number or a function that returns a number that indicate the scroll X. (default: 0)
 *    y: a number or a function that returns a number that indicate the scroll Y. (default: 0)
 *    top: a number or a function indicating the padding top for the scroll. (default: 0)
 *    left: a number or a function indicating the padding left for the scroll (default: 0)
 *    behavior: the scroll behavior, it can be 'smooth' or 'auto' (default: 'auto')
 *
 * Note: use function calls for 'x' and 'y' when the value must be returned
 * only after the window is loaded
 */
export const scrollAfterRender = (window, options) => {
  const { x = 0, y = 0, top = 0, left = 0, behavior = 'auto' } = options;
  const funcX = typeof x === 'function' ? x : () => x;
  const funcY = typeof y === 'function' ? y : () => y;
  const funcLeft = typeof left === 'function' ? left : () => left;
  const funcTop = typeof top === 'function' ? top : () => top;
  window.requestAnimationFrame(() => {
    window.scroll({
      left: funcX() - funcLeft(),
      top: funcY() - funcTop(),
      behavior,
    });
  });
};
