import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import t from './translate';
import { IDS_FOR_GOOGLE_TAG_MANAGER } from '../../../constants';

const AuthenticationLinks = ({ signIn = false, signUp = false, resetPassword = false }) => (
  <nav>
    {signIn && (
      <Button id={IDS_FOR_GOOGLE_TAG_MANAGER.USER.AUTHENTICATION_LINKS.SIGN_IN} href="/sign-in" narrow>
        {t('signIn')}
      </Button>
    )}
    {signUp && (
      <Button id={IDS_FOR_GOOGLE_TAG_MANAGER.USER.AUTHENTICATION_LINKS.SIGN_UP} href="/sign-up" narrow>
        {t('signUp')}
      </Button>
    )}
    {resetPassword && (
      <Button
        id={IDS_FOR_GOOGLE_TAG_MANAGER.USER.AUTHENTICATION_LINKS.RESET_PASSWORD}
        href="/password-recovery-request"
        narrow
      >
        {t('resetPassword')}
      </Button>
    )}
  </nav>
);

AuthenticationLinks.propTypes = {
  signIn: PropTypes.bool,
  signUp: PropTypes.bool,
  resetPassword: PropTypes.bool,
};

export default AuthenticationLinks;
