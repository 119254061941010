import qs from 'query-string';

const startsWith = (stringToCheck, start) => stringToCheck.indexOf(start) === 0;

export const isGerman = (language) => startsWith(language, 'de');

export const browserLanguage = () => {
  if (global.navigator && global.navigator.language) {
    return global.navigator.language;
  }
  if (global.navigator && global.navigator.userLanguage) {
    return global.navigator.userLanguage;
  }
  return '';
};

export const globalCrewmeisterLanguage = () => global.__CREWMEISTER_LANGUAGE;
export const languageFromLocation = () => {
  const search = global.location && global.location.search;
  return qs.parse(search).language;
};

export const languageWithoutRegion = (language = '') => language.split('-')[0];
