import produce from 'immer';
import { isSameDay } from 'date-fns';

import { array, dataTypes, stampchain } from '../utils';

const { applyMinimalChangesToArray } = array;
const { isArray } = dataTypes;
const { getAllStampsFromStampChains, getStampsRelevantInDateRange, makeStampChains, stampChainOpenFilter } = stampchain;

const initialState = produce({ stamps: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const stampsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_STAMPS_SUCCESS':
      case 'START_BREAK_STAMP_SUCCESS':
      case 'START_WORK_STAMP_SUCCESS':
      case 'STOP_WORK_STAMP_SUCCESS': {
        // We want to retrieve all stamps that belong to an open stamp chain
        const allStampChains = makeStampChains(state.stamps);
        const allOpenStampChains = allStampChains.filter(stampChainOpenFilter);
        const allStampsThatBelongToOpenStampChains = getAllStampsFromStampChains(allOpenStampChains);

        const currentStampsInDateRange = getStampsRelevantInDateRange(
          state.stamps.filter((stamp) =>
            // Get stamps for only the particular userId when it is available in response from server
            action.userIds
              ? stamp.crewId === action.crewId && stamp.userId === +action.userIds
              : stamp.crewId === action.crewId
          ),
          new Date(action.startTime),
          new Date(action.endTime)
        ).filter(
          // This filter stops the reducer from removing stamps from open stamp chains unnecessarily
          (stamp) =>
            action.result.some((stampResult) => isSameStamp(stamp, stampResult)) ||
            allStampsThatBelongToOpenStampChains.every(
              (stampFromOpenStampChain) => !isSameStamp(stamp, stampFromOpenStampChain)
            )
        );

        applyMinimalChangesToArray(
          draft.stamps,
          isArray(action.result) ? sortStamps(action.result) : action.result,
          (stamp) => currentStampsInDateRange.some((stampInRange) => isSameStamp(stamp, stampInRange))
        );
        break;
      }
      case 'REPLACE_STAMP_CHAIN_SUCCESS': {
        const datesForUsers = action.userDates.reduce((acc, userDate) => {
          acc[userDate.userId] = acc[userDate.userId] ? [...acc[userDate.userId], userDate.date] : [userDate.date];
          return acc;
        }, {});
        applyMinimalChangesToArray(draft.stamps, action.result, (stamp) =>
          (datesForUsers[stamp.userId] || []).some((date) =>
            isSameDay(new Date(stamp.clockInTimestamp), new Date(date))
          )
        );
        break;
      }
      case 'CREATE_STAMP_CHAINS_SUCCESS':
        applyMinimalChangesToArray(draft.stamps, sortStamps(action.result), (stamp) =>
          action.deleteStampChainAt
            ? action.deleteStampChainAt === stamp.clockInTimestamp &&
              action.crewId === stamp.crewId &&
              action.userId === stamp.userId
            : false
        );
        break;
      case 'FORCE_CREATE_STAMP_CHAINS_SUCCESS':
        applyMinimalChangesToArray(draft.stamps, sortStamps(action.result), (stamp) =>
          action.result.some(
            (actionStamp) =>
              isSameDay(new Date(actionStamp.clockInTimestamp), new Date(stamp.clockInTimestamp)) &&
              actionStamp.crewId === stamp.crewId &&
              actionStamp.userId === stamp.userId
          )
        );
        break;
      case 'BATCH_DELETE_STAMP_CHAINS_SUCCESS':
      case 'DELETE_STAMP_CHAINS_SUCCESS':
        applyMinimalChangesToArray(draft.stamps, [], (stamp) =>
          action.result.some((stampInChain) => isSameStamp(stamp, stampInChain))
        );
        break;
      case 'UPDATE_STAMP_SUCCESS':
        applyMinimalChangesToArray(draft.stamps, action.result, (stamp) => isSameStamp(action.result, stamp));
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });

const sortStamps = (stamps) => stamps.concat().sort(stampsSorter);

const stampsSorter = (a, b) => {
  if (a.userId !== b.userId) return a.userId < b.userId ? -1 : 1;
  if (a.clockInTimestamp !== b.clockInTimestamp) return a.clockInTimestamp < b.clockInTimestamp ? -1 : 1;
  return a.timestamp < b.timestamp ? -1 : 1;
};

const isSameStamp = (stamp1, stamp2) =>
  stamp1.timestamp === stamp2.timestamp && stamp1.userId === stamp2.userId && stamp1.crewId === stamp2.crewId;
