import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;

const initialState = produce({ dayKinds: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const dayKindsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_DAY_KINDS_SUCCESS':
        applyMinimalChangesToArray(draft.dayKinds, action.result);
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
