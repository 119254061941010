import { hasWindow } from '@crewmeister/shared/src/api/_external-deps/fetch';
import { FC } from '@crewmeister/shared/src/types/React';
import React from 'react';

import Button from '../../components/core/button';
import { BREAKPOINTS, COLORS, FONT_STYLES } from '../../constants';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import t, { dangerouslyTranslateNeverWithUserContent } from './translate';

const onClickGoHomeButton = () => {
  if (hasWindow()) {
    window.location.href = '/';
  }
};

const GenericError: FC = () => (
  <div className={`${css(styles.container)}`}>
    <h1 className={`${css(styles.h1)}`}>{t('title')}</h1>
    <h2 className={`${css(styles.h2)}`}>{t('subtitle')}</h2>
    <div className={`${css(styles.button)}`}>
      <Button onClick={onClickGoHomeButton}>{t('home')}</Button>
    </div>
    <p className={`${css(styles.text)}`}>{dangerouslyTranslateNeverWithUserContent('text')}</p>
  </div>
);

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    minHeight: '100vh',
    width: '100vw',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
    ':after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: '8%',
      left: '55%',
      backgroundImage: 'url(../../public/assets/images/404.png)',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100%',
      opacity: 0.2,
    },
  },
  h1: {
    fontWeight: FONT_STYLES.fontWeightsEnum.bold,
    color: COLORS.GREY_MAIN,
    fontSize: '200px',
    padding: '10px 0',
    letterSpacing: '2.4px',
    margin: 0,
    [BREAKPOINTS.mobilePortraitMode]: {
      fontSize: '80px',
    },
  },
  h2: {
    ...FONT_STYLES.big,
    margin: 0,
  },
  text: {
    ...FONT_STYLES.medium,
    margin: 0,
    marginTop: '20px',
  },
  button: {
    display: 'inline-table',
    marginTop: '20px',
  },
  link: {
    ...FONT_STYLES.medium,
    padding: '20px 0',
  },
});

export default GenericError;
