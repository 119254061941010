/**
 * debounceEvent - Generates a function which will call the function @param fn after @param delay milliseconds
 *
 * @param {function} fn desired function that will be called
 * @param {integer} delay time in milliseconds
 *
 * @return {function} which will receive the params that will be forwarded to "fn" and "fn" will be called after
 *                     "delay" milliseconds
 */
export const debounceEvent = (fn: (...args: any) => unknown, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

/**
 * throttleEvent - Generates a function that will not call @param fn twice within @param wait milliseconds
 *
 * @param {function} fn desired function that will be called
 * @param {integer} wait time in milliseconds
 *
 * @return {function} which will receive the params that will be forwarded to "fn" and "fn" won't be called twice
 *                    within "delay" milliseconds
 */
export const throttleEvent = (fn: (...args: any) => unknown, wait: number) => {
  let time = Date.now();
  return (...args: any) => {
    if (time + wait - Date.now() > 0) {
      return;
    }

    fn(...args);
    time = Date.now();
  };
};

/**
 * wait - Generates a function that will not call @param fn twice within @param wait milliseconds
 *
 * @param {integer} timeout time in milliseconds
 *
 * @return {promise} returns a resolved promise after timeout.
 */
export const wait = (timeout: number): Promise<unknown> => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};
