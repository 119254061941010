import { createSelector } from 'reselect';

import { createPropsFinder, createPropsSelector } from './utils';
import { selectCurrentUserId } from './authentication-selectors';

const selectDurationsFromState = (state) => state.bo.durations.durations;

export const selectDurations = createSelector(selectDurationsFromState, (durations) => durations.concat());

export const selectDurationsByUserId = createSelector(selectDurationsFromState, selectCurrentUserId, (items, userId) =>
  items.filter((item) => item.userId === userId)
);

export const selectDurationsByProps = createPropsSelector(selectDurationsFromState);
export const findDurationsByProps = createPropsFinder(selectDurationsFromState);
