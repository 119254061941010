export const NEWS_PER_PAGE = 25;

export const NEWS_ACTIONS = {
  membership: ['create_member', 'invite_existing_user', 'invite_new_user'],
  shiftPlanner: ['publish'],
  absenceManagement: ['request', 'grant', 'reject'],
  timeEvaluation: ['ready_to_download'],
};

export enum NEWS_TYPES {
  ABSENCE_MANAGEMENT = 'absenceManagement',
  SHIFT_PLANNER = 'shiftPlanner',
  TIME_EVALUATION = 'timeEvaluation',
  TIME_TRACKING = 'timeTracking',
  WELCOME = 'welcome',
};
