import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  areIntervalsOverlapping,
  differenceInCalendarDays,
  differenceInCalendarWeeks as _differenceInCalendarWeeks,
  differenceInHours,
  endOfWeek as _endOfWeek,
  endOfYear,
  format,
  formatISO9075,
  getDate,
  getDay,
  getISOWeek,
  getMonth,
  getYear,
  intervalToDuration,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  isTomorrow,
  isWithinInterval,
  isYesterday,
  lightFormat,
  setDate,
  setHours,
  setMilliseconds,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
  startOfDay,
  startOfHour,
  startOfWeek as _startOfWeek,
  startOfYear,
  subDays,
  subHours,
} from 'date-fns';
import { de, enGB } from 'date-fns/locale';
import { toFragments } from 'pomeranian-durations';

import { DateString, LocaleType } from '../types';
import { compose } from './collections';
import { isDate } from './data-types';

export const locales = {
  en: enGB,
  de: de,
};

export const BEGINNING_OF_TIME = new Date('1970-01-01').toISOString().substring(0, 10);
export const END_OF_TIME = new Date('2222-01-01').toISOString().substring(0, 10);

/**
 * areISODatesDifferent - Checks if both dates are different
 *
 * @param {date} date1
 * @param {date} date2
 *
 * @return {boolean} returns true if both dates are different, false otherwise
 */
export const areISODatesDifferent = (date1: Date, date2: Date) =>
  new Date(date1).toISOString() !== new Date(date2).toISOString();

/**
 * differenceInDays - Returns the difference between both the days, i.e., (date1 - date2)
 *                    Though date-fns has a built-in function called differenceInDays, it has
 *                    issues when dealing with Daylight Savings Time as documented here:
 *                    https://date-fns.org/v2.21.1/docs/differenceInDays
 * Note: This function will round up for positive difference (1.2 => 2) & round down for negative difference (-1.2 to -2).
 *
 * @param {date} date1 2020-02-08T16:52:00.000Z
 * @param {date} date2 2020-02-05T16:52:00.000Z
 *
 * @return {boolean} returns the difference (date1 - date2) i.e., 3
 */
export const differenceInDays = (date1: Date, date2: Date) =>
  // eslint-disable-next-line no-bitwise
  Math.floor(differenceInHours(new Date(date1), new Date(date2)) / 24) | 0;

/**
 * differenceInHoursAndMinutes - Returns the hours & minutes of the difference,
 *                               between both the dates, formatted as a string
 *
 *
 * @param {date} date1 2020-04-04T16:52:00.000Z
 * @param {date} date2 2020-05-05T20:58:00.000Z
 * @param {string} hourText 'hour'
 * @param {string} minutesText 'min'
 *
 * @return {boolean} returns the hours & minutes of the difference (date2 - date1) i.e., '4 hour 8 min'
 * Warning: The difference is actually { days: 1, hours: 4, minutes: 8 },
 *          but only hours & minutes are considered!!!
 */
export const differenceInHoursAndMinutes = (date1: Date, date2: Date, hourText: string, minutesText: string) => {
  if (!isDate(date1) || !isDate(date2)) return '';

  const duration = intervalToDuration({ start: new Date(date1), end: new Date(date2) });
  const hoursString = duration.hours ? `${duration.hours} ${hourText}` : '';
  const separatorString = duration.hours && duration.minutes ? ' ' : '';
  const minutesString = duration.minutes ? `${duration.minutes} ${minutesText}` : '';

  return `${hoursString}${separatorString}${minutesString}`;
};

/**
 * earliestDate - Checks which date is the earliest and return it
 *
 * @param {date} date1 (e.g. 20-01-2020)
 * @param {date} date2 (e.g. 20-01-2021)
 *
 * @returns {date} which is the earliest date (e.g. 20-01-2020)
 */
export const earliestDate = (date1: Date, date2: Date) => (date1 < date2 ? date1 : date2);

/**
 * getWeekDays - Generates the names of the days of the week based on the given locale
 *               (supports just german and english)
 *
 * @param {string} locale code that represents a country (e.g. 'de' => Germany)
 *
 * @return {array} of string containing the names of the days of the week
 *                 from Sunday to Saturday
 */
export const getWeekDays = (locale: LocaleType) =>
  locale === 'de'
    ? ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * getWeekDaysShort - Generates the name abbreviations of the days of the week based on the
 *                    given locale (supports just german and english)
 *
 * @param {string} locale code that represents a country (e.g. 'de' => Germany)
 *
 * @return {array} of string containing the names abbreviations of the days of the week
 *                 from Sunday to Saturday
 */
export const getWeekDaysShort = (locale: LocaleType) =>
  locale === 'de' ? ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'] : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

/**
 * getWeekDaysShortStartMonday - Generates the name abbreviations of the days of the week based on the
 *                               given locale (supports just german and english)
 *
 * @param {string} locale code that represents a country (e.g. 'de' => Germany)
 *
 * @return {array} of string containing the names abbreviations of the days of the week
 *                 from Monday to Sunday
 */
export const getWeekDaysShortStartMonday = (locale: LocaleType) =>
  locale === 'de' ? ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'] : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

/**
 * getMonthNames - Generates the names of months based on the given locale
 *                 (supports just german and english)
 *
 * @param {string} locale code that represents a country (e.g. 'de' => Germany)
 *
 * @return {array} of string containing the names of months
 */
export const getMonthNames = (locale: LocaleType) =>
  locale === 'de'
    ? [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ]
    : [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

/**
 * getMonthNamesShort - Generates the month name abbreviations based on the
 *                      given locale (supports just german and english)
 *
 * @param {string} locale code that represents a country (e.g. 'de' => Germany)
 *
 * @return {array} of string containing the months names abbreviations
 */
export const getMonthNamesShort = (locale: LocaleType) =>
  locale === 'de'
    ? ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
    : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const isDateInTheFuture = (date: Date) => new Date(date).getTime() > new Date().getTime();

/**
 * isWithinRange - Checks if a given date is within two other given dates
 *
 * @param {date} date that one wants to check if it is within range
 * @param {date} start start date of the range
 * @param {date} end end date of the range
 *
 * @return {boolean} true if start <= date <= end,
 *                   false otherwise
 */
export const isWithinRange = (date: Date, start: Date, end: Date) =>
  isBefore(new Date(start), new Date(end))
    ? isWithinInterval(new Date(date), { start: new Date(start), end: new Date(end) }) ||
      isSameDay(new Date(date), new Date(start)) ||
      isSameDay(new Date(date), new Date(end))
    : isWithinInterval(new Date(date), { start: new Date(end), end: new Date(start) }) ||
      isSameDay(new Date(date), new Date(start)) ||
      isSameDay(new Date(date), new Date(end));

/**
 * isValidInterval - Checks if the given dates form a valid interval
 *
 * @param {date} start start date of the range
 * @param {date} end end date of the range
 *
 * @return {boolean} true if start & end are valid dates & start <= end,
 *                   false otherwise
 */
export const isValidInterval = (start: Date, end: Date) =>
  !isDate(start) || !isDate(end) || new Date(start) > new Date(end) ? false : true;

/**
 * areRangesOverlapping - Checks if two intervals intersect
 *
 * @param {date} firstStartDate start date of the #1 interval
 * @param {date} firstEndDate end date of the #1 interval
 * @param {date} startDate start date of the #2 interval
 * @param {date} endDate end date of the #2 interval
 *
 * @return {boolean} true if both intervals intersect,
 *                   false otherwise
 */
export const areRangesOverlapping = (firstStartDate: Date, firstEndDate: Date, startDate: Date, endDate: Date) =>
  (isValidInterval(firstStartDate, firstEndDate) &&
    isValidInterval(startDate, endDate) &&
    areIntervalsOverlapping(
      { start: new Date(firstStartDate), end: new Date(firstEndDate) },
      { start: new Date(startDate), end: new Date(endDate) }
    )) ||
  isSameDay(new Date(firstStartDate), new Date(startDate)) ||
  isSameDay(new Date(firstEndDate), new Date(endDate)) ||
  isSameDay(new Date(firstStartDate), new Date(endDate)) ||
  isSameDay(new Date(firstEndDate), new Date(startDate));

/**
 * setTime - Sets a given time to a given date
 *
 * @param {date} date that one wants to add the time to
 * @param {string} time in 'hh:mm:ss' format
 *
 * @return {date} which is the result of 'date + time'
 */
export const setTime = (date: Date | string, time: string = '') => {
  const [hours = 0, minutes = 0, seconds = 0] = time.split(':');
  return compose(
    (_date: Date | string) => setMilliseconds(new Date(_date), 0),
    (_date: Date | string) => setSeconds(new Date(_date), Number(seconds)),
    (_date: Date | string) => setMinutes(new Date(_date), Number(minutes)),
    (_date: Date | string) => setHours(new Date(_date), Number(hours))
  )(date);
};

/**
 * setCalendarDate - Set day, month and year to a given timestamp
 *
 * @param {date} date that one wants to set day, month and year to
 * @param {date} timestamp that one wants to set day, month and year to
 *
 * @return {date} which has day, month and year from @param date and the
 *                other time properties from @param timestamp
 */
export const setCalendarDate = (date: Date, timestamp: Date) =>
  compose(
    (updatedTimestamp: string) => setDate(new Date(updatedTimestamp), getDate(new Date(date))),
    (updatedTimestamp: string) => setMonth(new Date(updatedTimestamp), getMonth(new Date(date))),
    (updatedTimestamp: string) => setYear(new Date(updatedTimestamp), getYear(new Date(date)))
  )(timestamp);

/**
 * addTimezoneOffset - Sums a date's timezone to itself
 *
 * @param {date} date
 *
 * @return {string} which is the result of 'date + timezone' converted to string
 */
export const addTimezoneOffset = (date: Date) => {
  const newDate = new Date(date);
  const timezoneOffset = newDate.getTimezoneOffset();
  return addMinutes(newDate, timezoneOffset).toString();
};

/**
 * @param {Date} date 2020-02-05T16:52:20.717Z
 * @return {string} 2020-02-05T16:52:00.000Z
 */
export const isoDatetimeWithoutSeconds = (date: Date = new Date()) => {
  date.setSeconds(0, 0);
  return date.toISOString();
};

/**
 * @param {any} date
 * @return {string} 2019-11-06
 */
export const formatIso8601Date = (date: Date | string): DateString =>
  lightFormat(new Date(date), 'yyyy-MM-dd') as DateString;

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} 06.11.2019 | 11/06/2019
 */
export const formatDate = (date: Date, locale: LocaleType) => {
  return format(new Date(date), 'P', { locale: locales[locale] });
};

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} 06. November 2019 | November 06, 2019
 */
export const formatDateLong = (date: Date, locale: LocaleType) =>
  format(new Date(date), locale === 'de' ? 'dd. MMMM yyyy' : 'MMMM dd, yyyy', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} 6. Nov 2019 | Nov 6th 2019
 */
export const formatDateShort = (date: Date, locale: LocaleType) =>
  format(new Date(date), locale === 'de' ? 'do MMM yyyy' : 'MMM do yyyy', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} 6. November 2019 | November 6th 2019
 */
export const formatDateDefault = (date: Date, locale: LocaleType) =>
  format(new Date(date), locale === 'de' ? 'do MMMM yyyy' : 'MMMM do yyyy', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} 6. Nov | Nov 6th
 */
export const formatDayAndMonthShort = (date: Date, locale: LocaleType) =>
  format(new Date(date), locale === 'de' ? 'do MMM' : 'MMM do', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} 6. November | November 6th
 */
export const formatDayAndMonthLong = (date: Date, locale: LocaleType) =>
  format(new Date(date), locale === 'de' ? 'do MMMM' : 'MMMM do', { locale: locales[locale] });

/**
 * @param {any} date
 * @return {string} 6.11
 */
export const formatDayAndMonthNumerical = (date: Date) => format(new Date(date), 'd.M');

/**
 * @param {any} date
 * @return {string} 06.11
 */
export const formatDayAndMonthNumericalWithZero = (date: Date, locale: LocaleType = LocaleType.DE) =>
  format(new Date(date), locale === 'de' ? 'dd.MM' : 'dd/MM', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} Mittwoch | Wednesday
 */
export const formatWeekDays = (date: Date, locale: LocaleType, short = false) =>
  format(new Date(date), short ? 'EEE' : 'EEEE', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} Mi, 06.11.2019 | We, 11/06/2019
 */
export const formatWeekDay = (date: Date, locale: LocaleType) =>
  format(new Date(date), 'EEEEEE, P', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} Mi. 06.11 | Wed, 06.11
 */
export const formatWeekDayShort = (date: Date, locale: LocaleType) =>
  format(new Date(date), locale === 'de' ? 'iii, dd.MM' : 'iii, dd/MM', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} Januar 2019 | January 2019
 */
export const formatMonthLongYear = (date: Date, locale: LocaleType) =>
  format(new Date(date), 'MMMM y', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} KW 45 | CW 45
 */
export const formatCalendarWeekWithLabel = (date: Date | DateString, locale?: LocaleType) =>
  `${locale === 'de' ? 'KW' : 'CW'} ${getISOWeek(new Date(date))}`;

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} KW 45 (2. Okt - 9. Oktober) | CW 45 (2. Oct - 9. Oct)
 */
export const formatCalendarWeekLong = (date: Date, locale: LocaleType) =>
  `${formatCalendarWeekWithLabel(date, locale)} (${formatDateExtraShort(
    startOfWeek(new Date(date)),
    locale
  )} - ${formatDateExtraShort(endOfWeek(new Date(date)), locale)})`;

/**
 * @param {any} startDate
 * @param {any} endDate
 * @param {string} locale de / en
 * @param {boolean} hideStartDateYearIfSameYear if true, when both dates happen in the same year, the first one will have its year hidden
 * @return {string} 06.11.2019 - 08.11.2019 | 11/06/2019 - 11/08/2019} | 06.11 - 08.11.2019 | 11/06 - 11/08/2019
 */
export const formatDateRange = (
  startDate: Date,
  endDate: Date,
  locale: LocaleType,
  hideStartDateYearIfSameYear: boolean = false
) => {
  if (startDate || endDate) {
    const arrDates = [
      hideStartDateYearIfSameYear && new Date(startDate).getFullYear() === new Date(endDate).getFullYear()
        ? formatDayAndMonthNumericalWithZero(startDate || endDate, locale)
        : formatDate(startDate || endDate, locale),
      formatDate(endDate || startDate, locale),
    ];
    return arrDates.filter((date, index) => arrDates.indexOf(date) === index).join(' - ');
  }
  return '';
};

/**
 * @param {} date
 * @param {string} locale de / en
 * @param {} formatFn formatter function
 * @param {} translation translates the string
 * @param {boolean} withDate
 * @return {string} today/ tomorrow/ yesterday/ dayAfterTomorrow 06.11.2019 | 11/06/2019
 */
export const formatDateRelative = ({
  date,
  locale,
  formatFn,
  translation,
  withDate = false,
  considerDayOnlyTillTomorrow = false,
}: {
  date: Date;
  locale: LocaleType;
  formatFn: (date: Date, locale: LocaleType) => string;
  translation: Record<string, (translation: string) => string>;
  withDate?: boolean;
  considerDayOnlyTillTomorrow?: boolean;
}) => {
  if (isToday(new Date(date))) {
    return translation.t('today');
  } else if (isTomorrow(new Date(date))) {
    return translation.t('tomorrow') + (withDate ? `, ${formatDate(date, locale)}` : '');
  } else if (isYesterday(new Date(date))) {
    return translation.t('yesterday') + (withDate ? `, ${formatDate(date, locale)}` : '');
  } else if (!considerDayOnlyTillTomorrow && differenceInCalendarDays(new Date(date), new Date()) === 2) {
    return translation.t('dayAfterTomorrow') + (withDate ? `, ${formatDate(date, locale)}` : '');
  }

  return formatFn(date, locale);
};

/**
 * formatDateRelativeWithTime - Formats date to return the relative date information and the time
 *
 * @param {Date} date
 * @param {string} locale 'en' | 'de'
 * @param {function} formatFn responsible for formatting the date in case it is not "yesterday, today, tomorrow and day after tomorrow"
 * @param {object} translation object that should contain a function "t()" with translations for  "yesterday, today, tomorrow and dayAfterTomorrow"
 *
 * @returns {string} 'Today, 11:30' / 'Tomorrow, 13:00'
 */
export const formatDateRelativeWithTime = (
  date: Date,
  locale: LocaleType,
  formatFn: (date: Date, locale: LocaleType) => string,
  translation: Record<string, (translation: string) => string>
) => {
  const dateRelative = formatDateRelative({ date, locale, formatFn, translation });
  const timeWithSeconds = formatISO9075(date, { representation: 'time' });
  const timeWithoutSeconds = timeWithSeconds.slice(0, 5); // hh:mm

  return `${dateRelative}, ${timeWithoutSeconds}`;
};

/**
 * generateDatesWithinRange — Generate array of dates starting from 'start' until 'end'
 *
 * @param {Date} start
 * @param {Date} end
 *
 * @returns {Array<Date>}
 */
export const generateDatesWithinRange = (start: Date, end: Date): Array<Date> => {
  const arrayOfDates = [];

  const startDate = startOfDay(start);
  const endDate = startOfDay(end);

  for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
    arrayOfDates.push(new Date(day));
  }

  return arrayOfDates;
};

/**
 * @param {} local de / en
 * @return {string} Januar / January
 */
export const getMonthName = (local: LocaleType, date: Date) =>
  format(new Date(date), 'MMMM', { locale: locales[local] });

const MONDAY = 1;
const hardCodedDateConfig: Record<'weekStartsOn', 1> = { weekStartsOn: MONDAY };
/**
 * @param {} date
 * @return {Date} begin of the week
 */
export const startOfWeek = (date: Date) => _startOfWeek(new Date(date), hardCodedDateConfig);

/**
 * @param {} date
 * @return {Date} end of the week
 */
export const endOfWeek = (date: Date) => _endOfWeek(new Date(date), hardCodedDateConfig);

/**
 * @param {} to
 * @param {} from
 * @return {Integer} number of calendar weeks between the given dates
 */
export const differenceInCalendarWeeks = (to: Date, from: Date) =>
  _differenceInCalendarWeeks(to, from, hardCodedDateConfig);

/**
 * @param {} dateTime 2020-02-05T16:52:20.717Zs
 * @return {string} 2020-02-05T16:52:20
 */
export const dateTimeWithoutTimezone = (dateTime: Date) => {
  return format(new Date(dateTime), "yyyy-MM-dd'T'HH:mm:ss");
};

/**
 * @param {any} startDate 2000-01-01T01:20:00
 * @param {any} endDate 2000-01-01T03:20:00
 * @return {array<Date>} 2000-01-01T01:20:00, 2000-01-01T02:00:00, 2000-01-01T03:00:00
 */
export const eachHour = (startDate: Date, endDate: Date) => {
  let currentDate = startOfHour(new Date(startDate));
  const compareFn = isBefore(new Date(startDate), new Date(endDate)) ? isBefore : isAfter;
  const addFn = isBefore(new Date(startDate), new Date(endDate)) ? addHours : subHours;
  const result = [addFn(new Date(startDate), 0)];

  while (compareFn(addFn(currentDate, 1), new Date(endDate))) {
    currentDate = addFn(currentDate, 1);
    result.push(currentDate);
  }

  return result;
};

/**
 * @param {any} startDate
 * @param {any} duration P2W
 * @return {Date} Date with added duration
 */
export const addDuration = (startDate: Date, duration: string) => {
  let result = new Date(startDate);
  const fragments = toFragments(duration);
  if (fragments.years) result = addYears(new Date(result), fragments.years);
  if (fragments.months) result = addMonths(new Date(result), fragments.months);
  if (fragments.weeks) result = addWeeks(new Date(result), fragments.weeks);
  if (fragments.days) result = addDays(new Date(result), fragments.days);
  if (fragments.hours) result = addHours(new Date(result), fragments.hours);
  if (fragments.seconds) result = addSeconds(new Date(result), fragments.seconds);
  return result;
};

/**
 * @param {any} date
 * @param {any} locale de / en
 * @return {number} So / Sun
 */
const weekdayShortFromDate = (date: Date, locale: LocaleType) => getWeekDaysShort(locale)[getDay(new Date(date))];

/**
 * @param {any} startDate
 * @param {any} duration P2W
 * @return {date}
 */
export const lastDateWithinDuration = (startDate: Date, duration: string) =>
  subDays(new Date(addDuration(startDate, duration)), 1);

/**
 * latestDate - Checks which date is the latest and return it
 *
 * @param {date} date1 (e.g. 20-01-2020)
 * @param {date} date2 (e.g. 20-01-2021)
 *
 * @returns {date} which is the latest date (e.g. 20-01-2021)
 */
export const latestDate = (date1: Date, date2: Date) => (date1 < date2 ? date2 : date1);

/**
 * @param {any} date
 * @return {number} 2019
 */
export const formatYear = (date: Date) => getYear(new Date(date));

/**
 * @param {any} date
 * @param {any} locale de / en
 * @return {string} Oktober 2019 / October 2019
 */
export const formatYearAndMonth = (date: Date, locale: LocaleType) =>
  format(new Date(date), 'MMMM yyyy', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {any} locale de / en
 * @return {string} Okt 2019 / Oct 2019
 */
const formatYearAndMonthShort = (date: Date, locale: LocaleType) =>
  format(new Date(date), 'MMM yyyy', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} 2. Okt / 2. Oct
 */
export const formatDateExtraShort = (date: Date | DateString, locale: LocaleType = LocaleType.DE) =>
  `${getDate(new Date(date))}. ${format(new Date(date), 'MMM', { locale: locales[locale || LocaleType.DE] })}`;

/**
 * @param {any} date
 * @param {string} locale de / en
 * @return {string} So, 6. Mär 2019 / Sun, 6. Mar 2019
 */
export const formatDateShortWithWeekday = (date: Date, locale: LocaleType) =>
  `${weekdayShortFromDate(date, locale)}, ${getDate(new Date(date))}. ${formatYearAndMonthShort(
    new Date(date),
    locale
  )}`;

/**
 * @param {any} date
 * @param {string} locale en / de
 * @return {string} January 5 / Januar 5
 */
export const formatMonthAndDay = (date: Date | DateString, locale: LocaleType = LocaleType.DE) =>
  format(new Date(date), 'MMMM dd', { locale: locales[locale] });

/**
 * @param {any} date
 * @param {string} locale en / de
 * @return {string} 2020-02-05 / 05-02-2020
 */
export const formatLocalDate = (date: Date, locale: LocaleType): DateString =>
  format(new Date(date), locale === 'en' ? 'yyyy-MM-dd' : 'dd-MM-yyyy') as DateString;

/**
 * @param {any} date
 * @return {string} 2020-02-07T10:43:15.002Z
 */
export const formatIso8601DateDatetime = (date: Date) => new Date(date).toISOString();

/**
 * isLastDayOfTheYear - checks whether the given date is the last day of the year
 *
 * @param {Date} date - the date to check
 * @return {boolean} true is the date is the last day of the year
 */
export const isLastDayOfYear = (date: Date): boolean =>
  startOfDay(date).getTime() === startOfDay(endOfYear(date)).getTime();

/**
 * isFirstDayOfTheYear - checks whether the given date is the first day of the year
 *
 * @param {Date} date - the date to check
 * @return {boolean} true is the date is the first day of the year
 */
export const isFirstDayOfYear = (date: Date): boolean =>
  startOfDay(date).getTime() === startOfDay(startOfYear(date)).getTime();

/**
 * removeTimeZoneOffset — Remove timezone time from date
 *
 * @param date
 * @returns {Date} date without timezone
 */
export const removeTimeZoneOffset = (date: Date): Date => addMinutes(date, date.getTimezoneOffset());

/**
 * parseDate - parses a date string to a Date object
 *
 * @param {String | Date} date - the date to parse
 * @return {Date} parsed date using separator
 */
export const parseDate = (date: String | Date): Date => {
  if (isDate(date)) return date;

  const [day, month, year] = date.split(/[./]/);
  return new Date(Number(year), Number(month) - 1, Number(day));
};
