import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import Button from '../../components/core/button';
import InactivityAction from '../../components/core/inactivity-action';
import Modal from '../../components/core/modal';

import { THEMES, TERMINAL_AUTHENTICATION_MODES } from '../../constants';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import TerminalMemberTimeTrackingInfo from '../terminal-member-time-tracking-info';
import TerminalSignIn from '../terminal-sign-in';
import TerminalSignInWithPinCode from '../terminal-sign-in-with-pin-code';
import t from './translate';
import { history } from '../../lib';

const Organism = ({
  enterUserApp,
  isOpen,
  isSignedIn,
  crewSettings,
  loadTimeTrackingInformation,
  member,
  signOutUser,
}) => {
  if (!member) return null;
  const [usePassword, setUsePassword] = useState(false);
  const [needsAuthenticationToEnterUserApp, setNeedsAuthenticationToEnterUserApp] = useState(false);

  const passwordRef = useRef(null);
  const executeScroll = () => setTimeout(() => passwordRef.current.scrollIntoView(), 500);

  return (
    <React.Fragment>
      <InactivityAction
        callback={() => {
          signOutUser();
          setUsePassword(false);
        }}
        startAfterSeconds={10}
        maxInactivitySeconds={30}
        isActive
        message={t('inactivity')}
      />

      <Modal
        className={css(styles.modal)}
        isOpen={isOpen}
        onClose={() => {
          signOutUser(isSignedIn);
          setUsePassword(false);
        }}
        preventBackgroundScrolling
      >
        <Grid container className={css(styles.root)} spacing={2}>
          <Grid item lg className={css(styles.timeTrackingStampWatchContainer)}>
            <If condition={crewSettings?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.NONE}>
              <TerminalMemberTimeTrackingInfo
                className={css(styles.stampWatchContainer)}
                member={member}
                theme={THEMES.TURQUOISE}
                withoutButtons
                withoutDateInfo
                withoutDateTimeInfo
                withoutTimeInfo
                withoutNotes
                withoutTimeCategories
                isIn3ColumnTerminalMode
              />
            </If>
            <If
              condition={
                crewSettings?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.PASSWORD ||
                crewSettings?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.PIN_CODE
              }
            >
              <TerminalMemberTimeTrackingInfo
                className={css(styles.stampWatchContainer)}
                member={member}
                theme={THEMES.TURQUOISE}
                withoutButtons
                withoutDateInfo
                withoutDateTimeInfo
                withoutTimeInfo
                notSignedIn={isSignedIn}
                isIn3ColumnTerminalMode
              />
            </If>
          </Grid>
          <If condition={!isSignedIn}>
            <Grid item lg className={css(styles.terminalSignInContainer)}>
              <If
                condition={
                  crewSettings?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.PASSWORD ||
                  usePassword ||
                  needsAuthenticationToEnterUserApp
                }
              >
                <TerminalSignIn
                  crewId={member.crewId}
                  onInputClick={executeScroll}
                  inputRef={passwordRef}
                  userId={member.userId}
                  setPreventClosing={() => {}}
                  success={needsAuthenticationToEnterUserApp ? enterUserApp : loadTimeTrackingInformation}
                  withoutHeader
                  withoutRedirection
                />
              </If>
              <If
                condition={
                  crewSettings?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.PIN_CODE && !usePassword
                }
              >
                <TerminalSignInWithPinCode
                  userId={member.userId}
                  label={t('enter4DigitPin')}
                  success={loadTimeTrackingInformation}
                  wide
                  labelSmall
                />
              </If>
            </Grid>
          </If>
          <Grid item xs={12}>
            <div className={css(styles.buttonContainer)}>
              <div>
                <If
                  condition={
                    isSignedIn || crewSettings?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.NONE
                  }
                >
                  <Button
                    className={css(styles.noTextTransform)}
                    inline
                    narrow
                    onClick={
                      isSignedIn
                        ? () => {
                            enterUserApp();
                            history.push('/');
                          }
                        : () => setNeedsAuthenticationToEnterUserApp(true)
                    }
                  >
                    <div className={css(styles.openFullApplicationButtonContent)}>
                      <div> {t('openFullApplication')} </div>
                      <div className={css(styles.arrowForwardIosIconContainer)}>
                        <ArrowForwardIosIcon style={{ fontSize: 14 }} />
                      </div>
                    </div>
                  </Button>
                </If>
                <If
                  condition={
                    !isSignedIn &&
                    crewSettings?.terminalAuthenticationMode === TERMINAL_AUTHENTICATION_MODES.PIN_CODE &&
                    !usePassword
                  }
                >
                  <Button className={css(styles.noTextTransform)} narrow inline onClick={() => setUsePassword(true)}>
                    {t('usePassword')}
                  </Button>
                </If>
              </div>

              <Button
                className={css(styles.noTextTransform)}
                narrow
                inline
                onClick={() => {
                  signOutUser(isSignedIn);
                  setUsePassword(false);
                }}
              >
                {t('close')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  arrowForwardIosIconContainer: { marginLeft: '13px' },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  modal: {
    padding: 48,
    maxWidth: 824,
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  stampWatchContainer: {
    margin: '36px 0 64.5px 0',
  },
  terminalSignInContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  openFullApplicationButtonContent: { display: 'flex', flexDirection: 'row' },
  noTextTransform: {
    textTransform: 'none',
  },
});

Organism.propTypes = {
  enterUserApp: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  member: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  signOutUser: PropTypes.func.isRequired,
};

export default Organism;
