import { connect } from 'react-redux';

import * as actions from '../../action-creators';
import { api, apiV3, callFunctionWhenPropsChange } from '../../lib';
import * as selectors from '../../selectors';
import { findTerminalCrewMembersByProps } from '../../selectors/terminal/crew-members-selectors';
import { collections, react } from '../../utils';

import Component from './organism';
import { enterUserApp } from './utils';

const { loadTerminalCrewMembers, signOutCrew, signOutUser, v3SignOutCrew, v3SignOutUser } = actions;
const { selectCurrentCrewId } = selectors;
const { composeReverse } = collections;
const { withUIState } = react;

const mapStateToProps = (state, props) => {
  return {
    isOpen: props.isOpen,
    member: findTerminalCrewMembersByProps(state, props),
    crewId: selectCurrentCrewId(state, props),
    userId: props.userId,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  functionToCall: () =>
    Promise.all([dispatch(loadTerminalCrewMembers({ api, crewId: props.crewId, disabledAt: null }))]),
  enterUserApp: enterUserApp(dispatch),
  signOutCrewWithId: ({ crewId }) => {
    props.setIsOpen(false);
    dispatch(v3SignOutCrew({ apiV3, crewId }));
    return dispatch(signOutCrew({ api, crewId }));
  },
  signOutUser: () => {
    props.setIsOpen(false);
    return Promise.all([
      dispatch(signOutUser({ api, clearCache: false })),
      dispatch(v3SignOutUser({ apiV3 })),
      dispatch(loadTerminalCrewMembers.unmemoized({ api, crewId: props.crewId, disabledAt: null })),
    ]);
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  functionToCall: propsFromDispatch.functionToCall,
  onLeaveTerminalMode: () => propsFromDispatch.signOutCrewWithId({ crewId: propsFromState.crewId }),
  onSignOutUser: propsFromDispatch.signOutUser,
  onEnterUserApp: propsFromDispatch.enterUserApp,
});

const TerminalTimeTracking = composeReverse(
  withUIState('isOpen', 'setIsOpen', true),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId', 'userId'] })
)(Component);

TerminalTimeTracking.displayName = 'TerminalTimeTracking';

export default TerminalTimeTracking;
