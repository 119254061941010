import { actions } from '@crewmeister/shared';
import { date } from '../utils';

import { dispatchAsyncFnStatusActions } from '../lib';

import {
  reloadTimeDurations,
  reloadTimeDurationsForDays,
  reloadDurationBalances,
  reloadDurationBalancesForDays,
} from './ui-actions';

const { loadStamps } = actions;
const { formatIso8601Date } = date;

export const deleteStampChains =
  ({ api, crewId, userId, timestamp }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'DELETE_STAMP_CHAINS',
      fnParams: { crewId, userId, timestamp },
      fn: (params) => api.deleteStampChains(params),
      afterSuccessFn: () => {
        dispatch(reloadTimeDurations());
        dispatch(reloadTimeDurationsForDays(userId, formatIso8601Date(timestamp), formatIso8601Date(timestamp)));
        dispatch(reloadDurationBalances());
        dispatch(reloadDurationBalancesForDays(userId, formatIso8601Date(timestamp)));
      },
    });

export const createStampChains =
  ({ api, crewId, stamps, deleteStampChainAt }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'CREATE_STAMP_CHAINS',
      fnParams: { crewId, userId: stamps[0].userId, stamps, deleteStampChainAt },
      fn: (params) =>
        api.createStampChains(params).then((newStamps) =>
          // TODO: make our @crewmeister/shared / our api add the clockInTimestamp value
          withClockInTimestamp(newStamps, newStamps[0].timestamp)
        ),
      afterSuccessFn: () => {
        dispatch(reloadTimeDurations());
        dispatch(
          reloadTimeDurationsForDays(
            stamps[0].userId,
            formatIso8601Date(stamps[0].timestamp),
            formatIso8601Date(stamps[stamps.length - 1].timestamp)
          )
        );
        dispatch(reloadDurationBalances());
        dispatch(reloadDurationBalancesForDays(stamps[0].userId, formatIso8601Date(stamps[0].timestamp)));
      },
    });

export const forceCreateStampChains =
  ({ api, crewId, stamps, userId }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'FORCE_CREATE_STAMP_CHAINS',
      fnParams: { crewId, userId, stamps },
      fn: (params) => api.forceCreateStampChains(params),
    });

export const createStartWorkStamp =
  ({ api, crewId, userId, timestamp, timeCategory1Id, timeCategory2Id, location, note }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'START_WORK_STAMP',
      fnParams: { api, crewId, userId, timestamp, timeCategory1Id, timeCategory2Id, location, note },
      fn: (params) => api.startWorkTimestamp(params),
      afterSuccessFn: () => {
        dispatch(reloadTimeDurations());
        dispatch(reloadTimeDurationsForDays(userId, formatIso8601Date(timestamp), formatIso8601Date(timestamp)));
      },
    });

export const createStartBreakStamp =
  ({ api, crewId, userId, timestamp, location }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'START_BREAK_STAMP',
      fnParams: { crewId, userId, timestamp, location },
      fn: (params) => api.startBreakTimestamp(params),
      afterSuccessFn: () => {
        dispatch(reloadTimeDurations());
        dispatch(reloadTimeDurationsForDays(userId, formatIso8601Date(timestamp), formatIso8601Date(timestamp)));
      },
    });

export const createStopWorkStamp =
  ({ api, crewId, userId, timestamp, location }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'STOP_WORK_STAMP',
      fnParams: { crewId, userId, timestamp, location },
      fn: (params) => api.stopWorkTimestamp(params),
      afterSuccessFn: () => {
        dispatch(reloadTimeDurations());
        dispatch(reloadTimeDurationsForDays(userId, formatIso8601Date(timestamp), formatIso8601Date(timestamp)));
        dispatch(reloadDurationBalances());
        dispatch(reloadDurationBalancesForDays(userId, formatIso8601Date(timestamp)));
      },
    });

export const updateStamp =
  ({ api, userId, crewId, timestamp, note }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_STAMP',
      fnParams: { userId, crewId, timestamp, note },
      fn: (params) => api.updateTimestamp(params),
      afterSuccessFn: () => {
        dispatch(reloadTimeDurations());
        dispatch(reloadTimeDurationsForDays(userId, formatIso8601Date(timestamp), formatIso8601Date(timestamp)));
        dispatch(reloadDurationBalances());
        dispatch(reloadDurationBalancesForDays(userId, formatIso8601Date(timestamp)));
      },
    });

export const updateStampBreak =
  ({ api, userId, crewId, timestamp, duration }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_STAMP_BREAK',
      fnParams: { crewId, userId, timestamp, duration },
      fn: (params) => api.updateBreakForStamps(params),
      afterSuccessFn: () => {
        dispatch(reloadTimeDurations());
        dispatch(reloadTimeDurationsForDays(userId, formatIso8601Date(timestamp), formatIso8601Date(timestamp)));
        return dispatch(
          loadStamps.unmemoized({
            api,
            crewId,
            startTime: timestamp,
            endTime: timestamp,
          })
        );
      },
    });

export const replaceStampChain =
  ({ api, crewId, userId, stamps, userDates }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'REPLACE_STAMP_CHAIN',
      fnParams: { crewId, userId, stamps, userDates },
      fn: (params) => api.replaceStampChain(params),
    });

const withClockInTimestamp = (stamps, clockInTimestamp) =>
  stamps.map((stamp) => ({
    ...stamp,
    clockInTimestamp,
  }));

export const batchDeleteStampChain =
  ({ api, crewId, userId, stamps }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'BATCH_DELETE_STAMP_CHAINS',
      fnParams: { crewId, userId, stamps },
      fn: (params) => api.batchDeleteStampChain(params),
    });
