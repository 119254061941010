/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildDangerouslyTranslateNeverWithUserContent, buildScopedTranslationFunction } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    close: 'Schließen',
    enter4DigitPin: 'Bitte gebe deine 4 stellige Pin ein um loszulegen:',
    inactivity: 'Aufgrund Inaktivität werden Sie in Kürze automatisch ausgeloggt.',
    openFullApplication: 'Zur vollen Anwendung wechseln',
    usePassword: 'Passwort benutzen',
  },
  en: {
    close: 'Close',
    enter4DigitPin: 'Please enter your 4-digit PIN to get started:',
    inactivity: 'Due to inactivity you will be automatically signed out shortly.',
    openFullApplication: 'Open the full Crewmeister App',
    usePassword: 'Use password',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
