export const FOLDERS = {
  GENERAL_SETTINGS: 'generalSettings',
  TIME_TRACKING: 'timeTracking',
  ABSENCE_MANAGER: 'absenceManager',
  SHIFT_PLANNER: 'shiftPlanner',
  WORKFORCE_MANAGEMENT: 'workforceManagement',
  BUY_LICENSE: 'buyLicense',
};

export const FOLDER_CAN_BE_VIEWED = {
  [FOLDERS.ABSENCE_MANAGER]: 'canUseAbsenceManagement',
  [FOLDERS.TIME_TRACKING]: 'canUseTimeTracking',
  [FOLDERS.SHIFT_PLANNER]: 'canUseShiftPlanner',
};

export const INPUT_TYPES = {
  GPS_LOCATION: { name: 'geofence', initialValue: {} },
  HEADER_LABEL: { name: 'header-label', initialValue: '' },
  INPUT_BOOLEAN: { name: 'input-boolean', initialValue: false },
  INPUT_DATE: { name: 'input-date', initialValue: new Date('01/01/1970') },
  INPUT_DAYS: { name: 'input-days', initialValue: 0 },
  INPUT_HOURS: { name: 'input-hours', initialValue: 0 },
  INPUT_PASSWORD: { name: 'input-password', initialValue: '' },
  INPUT_STRING: { name: 'input-string', initialValue: '' },
  MEMBER_OVERWRITE: { name: 'member-overwrite', initialValue: {} },
  PROFILE_PICTURE: { name: 'profile-picture', initialValue: '' },
  SELECTION_LIST: { name: 'selection-list', initialValue: [] },
  SINGLE_SELECTION: { name: 'single-selection', initialValue: '' },
  TAGGED_LIST: { name: 'tagged-list', initialValue: [] },
  VERSION_INPUT: { name: 'version-input', initialValue: {} },
};

export const PRIMARY_SETTING_PRIORITY = {
  HIGH_PRIORITY: 1,
  MEDIUM_PRIORITY: 2,
  LOW_PRIORITY: 3,
};

export const SETTING_ELEMENT_TYPE = {
  SETTING_GROUP: 'settingGroupDefinition',
  SETTING_OBJECT_TYPE: 'settingObjectTypeDefinition',
};

export const SETTINGS_IDS = {
  TIME_ACCOUNT: 'com.crewmeister.platform.time.account',
  SHIFT_TO_WORKING_TIME: 'com.crewmeister.platform.shift.to.working.time',
};

export const SETTINGS_COMING_FROM_THE_BACKEND = [SETTINGS_IDS.SHIFT_TO_WORKING_TIME, SETTINGS_IDS.TIME_ACCOUNT];

export const SETTINGS_WHICH_CAN_SET_VALID_FROM_AND_TO = [SETTINGS_IDS.SHIFT_TO_WORKING_TIME, SETTINGS_IDS.TIME_ACCOUNT];

export const SETTINGS_WHICH_SHOULD_ALWAYS_ASK_FOR_VALID_FROM_AND_TO = [
  SETTINGS_IDS.SHIFT_TO_WORKING_TIME,
  SETTINGS_IDS.TIME_ACCOUNT,
];
