// Add-ons for Crewmeister products
const ADD_ONS = {
  TIME_TRACKING_PLUS: 'canUseTimeTrackingPlus',
};

// Crewmeister products
const PRODUCTS = {
  TIME_TRACKING: 'canUseTimeTracking',
  SALARY_EXPORT: 'canManageSalaryExport',
  ABSENCE_MANAGER: 'canUseAbsenceManagement',
  SHIFT_PLANNER: 'canUseShiftPlanner',
};

// The permissions below each represent a single functionality that is enabled
// as part of an add-on or product.
export const PERMISSIONS = {
  ABSENCE_MANAGER: PRODUCTS.ABSENCE_MANAGER,
  BATCH_OPERATIONS: ADD_ONS.TIME_TRACKING_PLUS,
  PIN_CODE_TERMINAL: ADD_ONS.TIME_TRACKING_PLUS,
  SALARY_EXPORT: PRODUCTS.SALARY_EXPORT,
  SECOND_TIME_CATEGORY: ADD_ONS.TIME_TRACKING_PLUS,
  SHIFT_PLANNER: PRODUCTS.SHIFT_PLANNER,
  THREE_COLUMN_TERMINAL: ADD_ONS.TIME_TRACKING_PLUS,
  VIEW_NEXT_SHIFT_TILE: PRODUCTS.SHIFT_PLANNER,
  VIEW_OVERTIME_AND_WORKING_TIME_TILES: PRODUCTS.TIME_TRACKING,
  VIEW_PIN_CODE: ADD_ONS.TIME_TRACKING_PLUS,
  VIEW_VACATION_DAYS_TILE: PRODUCTS.ABSENCE_MANAGER,
};

export const PERMISSIONS_NAMES = {
  CAN_BUY_SUBSCRIPTION: 'canBuySubscription',
  CAN_CREATE_TIME_TRACKING_STAMPS: 'canCreateTimeTrackingStamps',
  CAN_EDIT_CREW: 'canEditCrew',
  CAN_EDIT_TIME_TRACKING_STAMPS: 'canEditTimeTrackingStamps',
  CAN_MANAGE_ABSENCES: 'canManageAbsences',
  CAN_MANAGE_ABSENCE_ENTITLEMENTS: 'canManageAbsenceEntitlements',
  CAN_MANAGE_BOOKINGS: 'canManageBookings',
  CAN_MANAGE_CREW_SIGN_IN: 'canManageCrewSignIn',
  CAN_MANAGE_SALARY_EXPORT: 'canManageSalaryExport',
  CAN_SEE_CREW_ABSENCES: 'canSeeCrewAbsences',
  CAN_SEE_CREW_REPORTS: 'canSeeCrewReports',
  CAN_SEE_CREW_TIME_TRACKING_STAMPS: 'canSeeCrewTimeTrackingStamps',
  CAN_SEE_LIVE: 'canSeeLive',
  CAN_USE_ABSENCE_MANAGEMENT: 'canUseAbsenceManagement',
  CAN_USE_ANY_PRODUCT: 'canUseAnyProduct',
  CAN_USE_SHIFT_PLANNER: 'canUseShiftPlanner',
  CAN_USE_TIME_TRACKING: 'canUseTimeTracking',
};
