import fetch from './fetch';

const fetchServerTimeReal = ({ headers }) =>
  fetch
    .serverTimestamp({ headers })
    .then((response) => response.json())
    .then((json) => new Date(json.timestamp));

export const timeAheadOfServer = async (headers, fetchServerTime = fetchServerTimeReal) => {
  const serverTime = await fetchServerTime({ headers });
  const clientTime = new Date();
  return clientTime.getTime() - serverTime.getTime();
};

export const timeAheadOfServerApp = async (headers, fetchServerTime = fetchServerTimeReal) => {
  const serverTime = await fetchServerTime({ headers });
  const clientTime = new Date();
  return {
    clientTime: clientTime,
    serverTime: serverTime,
    serverTimeOffset: clientTime.getTime() - serverTime.getTime(),
  };
};
