/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction } from '../../lib/translate';

export default buildScopedTranslationFunction({
  de: {
    addressNotFound: 'Adresse nicht gefunden',
    addressSearch: 'Adresse suchen',
    area: 'Gebiet',
    cancel: 'Abbrechen',
    createGeofenceError: 'Gebiet konnte nicht gespeichert werden',
    createGeofenceSuccess: 'Gebiet erfolgreich gespeichert',
    delete: 'Löschen',
    deleteGeofenceError: 'Gebiet konnte nicht gelöscht werden',
    deleteGeofenceSuccess: 'Gebiet wurde gelöscht',
    disabledTimeCategory: 'Zeitkategorie ist deaktiviert. Aktivieren?',
    disableTimeCategoryError: 'Zeitkategorie konnte nicht deaktiviert werden.',
    disableTimeCategorySuccess: 'Zeitkategorie wurde deaktiviert.',
    enabledTimeCategory: 'Zeitkategorie ist aktiviert. Deaktivieren?',
    enableTimeCategoryError: 'Zeitkategorie konnte nicht aktiviert werden.',
    enableTimeCategorySuccess: 'Zeitkategorie wurde aktiviert.',
    instructions: 'Wählen Sie ein Gebiet, indem Sie die Karte mit der Maus verschieben oder eine Adresse eingeben.',
    instructionsCont:
      'Mitarbeiter können in der Crewmeister für Mitarbeiter App einstellen, dass sie in diesem Gebiet per Push-Nachricht an das Umstempeln auf die neue Zeitkategorie erinnert werden.' /* eslint-disable-line max-len */,
    moreInfo: 'Mehr Infos.',
    name: 'Name',
    save: 'Änderungen speichern',
    updateNameError: 'Name der Zeitkategorie konnte nicht gespeichert werden.',
    updateNameSuccess: 'Name der Zeitkategorie wurde gespeichert.',
    withArea: 'GPS-Stempelerinnerung aktiviert',
    withoutArea: 'Keine GPS-Stempelerinnerung hinterlegt',
  },
  en: {
    addressNotFound: 'Address not found',
    addressSearch: 'Address search',
    area: 'Area',
    cancel: 'Cancel',
    createGeofenceError: 'Area could not be saved',
    createGeofenceSuccess: 'Area successfully saved',
    delete: 'Delete',
    deleteGeofenceError: 'Area could not be deleted',
    deleteGeofenceSuccess: 'Area was deleted',
    disabledTimeCategory: 'Time category is disabled. Enable?',
    disableTimeCategoryError: "Time category couldn't be disabled.",
    disableTimeCategorySuccess: 'Time category was disabled.',
    enabledTimeCategory: 'Time category is enabled. Disable?',
    enableTimeCategoryError: "Time category couldn't be enabled.",
    enableTimeCategorySuccess: 'Time category was enabled.',
    instructions: 'Define the area by moving the map with your mouse or entering an address.',
    instructionsCont:
      'In the Crewmeister für Mitarbeiter App, employees can activate push notification reminders to clock-in and clock-out for this time category when being near the given area.',
    moreInfo: 'More info.',
    name: 'Name',
    save: 'Save',
    updateNameError: "Name of time category couldn't be updated.",
    updateNameSuccess: 'Name of time category was updated.',
    withArea: 'GPS stamp reminder activated',
    withoutArea: 'No GPS stamp reminder defined',
  },
});
