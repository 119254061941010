import { factory, trait, sequence } from 'stahlwerk';

export const createCrew = factory({
  id: sequence(),
  name: 'Springfield High',
  trial: false,
  password: 'skinner sucks',
  settings: {},

  isTrail: trait({
    trail: true,
  }),
});
