import React from 'react';
import { useDrag } from 'react-dnd';

import Image from '../../../components/core/image';
import { IDS_FOR_GOOGLE_TAG_MANAGER } from '../../../constants';
import { string as stringUtils } from '../../../utils';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';

import { isWorkingMembersColumn } from './utils';

const { trimStringWithMultipleWords } = stringUtils;

const ImageComponent = ({ memberStyles, memberType, member }) =>
  React.useMemo(
    () => (
      <Image
        className={css(memberStyles[0], isWorkingMembersColumn(memberType) && memberStyles[1])}
        src={member.avatarUrl || ''}
        alt={member.name}
      />
    ),
    [member.avatarUrl]
  );

const Member = (props) => {
  const { member, memberStyles, memberType, setIsOverlayOpen, setMemberForOverlay } = props;

  const [{ isDragging }, drag] = useDrag({
    item: { type: memberType, member: member },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <li
      ref={drag}
      id={IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL_3_COLUMN.CLICK_MEMBER}
      className={css(styles.listItem, isDragging && styles.memberPhotoIsDragging)}
      onClick={() => {
        setMemberForOverlay(member);
        setIsOverlayOpen(true);
      }}
    >
      <ImageComponent {...props} />
      <div className={css(memberStyles[2])}>{trimStringWithMultipleWords(member.name, 10, 18, 2)}</div>
    </li>
  );
};

const styles = StyleSheet.create({
  listItem: {
    width: 110,
    cursor: 'pointer',
    listStyle: 'none',
    margin: '16px 6px 20px',
    textAlign: 'center',
  },
  memberPhotoIsDragging: {
    opacity: 0.25,
  },
});

export { Member };
