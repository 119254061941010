export enum ABSENCE_TYPES {
  unknown,
  vacation,
  sickness,
  compensatoryTimeOff,
  education,
  other,
  vacationHours,
  sicknessHours,
  compensatoryTimeOffHours,
  educationHours,
  otherAbsenceHours,
}
