import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadUserSettings = memoize(
  {},
  ({ api, userId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_USER_SETTINGS',
        fnParams: { userId },
        fn: (params) => api.getUserSettings(params),
      })
);

export const updateUserSettings = memoize(
  {},
  ({ api, userId, userSettings }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'UPDATE_USER_SETTINGS',
        fnParams: { userId, ...userSettings },
        fn: (params) => api.updateUserSettings(params),
      })
);
