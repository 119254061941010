import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadSalaryExportConfigurations = memoize({}, ({ api, crewId }) => (dispatch) => {
  return dispatchAsyncFnStatusActions({
    dispatch,
    actionName: 'LOAD_SALARY_EXPORT_CONFIGURATIONS',
    fnParams: { crewId: Number.parseInt(crewId, 10) },
    fn: (params) => api.getSalaryExportConfigurations(params),
  });
});
