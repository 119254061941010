import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadNewsCount = memoize(
  {},
  ({ api, userId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_NEWS_COUNT',
        fnParams: { userId },
        fn: (params) => api.getNewsCount(params),
      })
);

export const loadNews = memoize(
  {},
  ({ api, userId, perPage, page, types }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_NEWS',
        fnParams: { userId, perPage, page, ...(types ? { types } : {}) },
        fn: (params) => api.getNews(params),
      })
);

export const receivedNews = memoize(
  {},
  (payload) => (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'RECEIVED_NEWS',
      fnParams: payload,
      fn: (params) => Promise.resolve(params),
    })
);

export const updateNews = memoize(
  {},
  ({ api, userId, ids, seenAt, readAt, preserve }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'UPDATE_NEWS',
        fnParams: { userId, ids, seenAt, readAt, preserve },
        fn: (params) => api.updateNews(params),
      })
);

export const markAllAsSeen = memoize(
  {},
  ({ api, userId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'MARK_NEWS_AS_SEEN',
        fnParams: { userId },
        fn: (params) => api.markAllAsSeen(params),
      })
);

export const deleteNews = memoize(
  {},
  ({ api, userId, ids }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'DELETE_NEWS',
        fnParams: { userId, ids },
        fn: (params) => api.deleteNews(params),
      })
);
