import { constants } from '@crewmeister/shared';
const SHARED_COLORS = constants.COLORS;

// Crewmeister Color Scheme https://zpl.io/1V5X8p
// (light there is medium here, extra light there is light here)
// !!Don't use any colors which aren't defined here!!
export const COLORS = {
  ...SHARED_COLORS,

  // --------- NEEDS CLEANUP ------------

  TIME_GREEN: '#58bfbf',
  TIME_GREEN_LIGHT: '#bce4df',
  SPECIAL_DAY: 'rgba(202, 133, 57, 0.11)',
};

export const CREWMEISTER_FONTS = {
  DEFAULT: 'Source Sans Pro',
  FALLBACK: 'sans-serif',
};

export const ANIMATIONS = {
  IN_APP_NOTIFICATION: 'IN_APP_NOTIFICATION_ANIMATION 0.5s ease',
  ANIMATE_THE_DOTS: 'ANIMATE_THE_DOTS 2s ease infinite',
};

export const Z_INDEX = {
  dateNavigation: 11,
  navigationContainer: 10,
  navigation: 11,
  offlineBannerWrapper: 13,
  shiftInfo: 14,
  notifications: 50,
  transactionOverlay: 51,
};
