import { isArray, isObject } from './data-types';

/**
 * isArrayOfObjectsOfType - Check if the type of elements of an array match with object <T>
 *
 * @param elements - Array to check type for
 * @param properties - Properties of the type T
 * @returns
 */
export const isArrayOfObjectsOfType = <T>(elements: unknown, properties: string[]): elements is Array<T> =>
  isArray(elements) && !!elements.length && properties.every((property) => !!elements[0][property]);
