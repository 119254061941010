import React, { ChangeEvent } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import { COLORS, FONT_STYLES } from '../../constants';
import { FC } from '../../types';

type SearchV1Props = {
  id: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  placeholder: string;
};

const SearchBar: FC<SearchV1Props> = ({ id, onChange, placeholder }) => {
  const theme = createTheme({
    overrides: {
      MuiFormLabel: {
        root: {
          ...FONT_STYLES.medium,
          fontSize: '16px',
        } as CreateCSSProperties,
      },
      MuiIconButton: {
        root: {
          color: `${COLORS.ORANGE_MEDIUM} !important`,
        },
      },
      MuiInput: {
        underline: {
          '&::after': {
            borderBottom: 'unset !important',
          },
          '&::before': {
            borderBottom: 'unset !important',
          },
        },
      },
      MuiInputLabel: {
        formControl: {
          color: `${COLORS.ORANGE_MEDIUM} !important`,
          left: '35px',
        },
      },
      MuiInputBase: {
        root: {
          backgroundColor: COLORS.ORANGE_OPAQUE,
          borderRadius: '18px',
          flexDirection: 'row-reverse',
        },
      },
      MuiTextField: {
        root: {
          width: '100%',
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        id={id}
        label={placeholder}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MuiThemeProvider>
  );
};

export default SearchBar;
