import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../../constants';
import { V3SetPublishShiftAction, PatchResource, HTTP_METHOD, SHIFT_TYPE } from '../../types';

const initialState = produce({ excludedIds: [] }, () => {});

type V3ShouldPublishSliceInRedux = { excludedIds: number[] };

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const shiftsToPublishReducer = (
  state = initialState,
  action: AnyAction & V3SetPublishShiftAction
): V3ShouldPublishSliceInRedux =>
  produce(state, (draft: V3ShouldPublishSliceInRedux) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_SET_PUBLISH_SHIFT}`: {
        const resultId = action?.result?.resourceBeforeWrite?.id || action?.result?.id;
        draft.excludedIds = draft.excludedIds.filter((id) => id !== resultId);
        if (!action.result.shouldPublish) {
          draft.excludedIds.push(action.result.id);
        }
        break;
      }
      case `${ACTION_NAMES.V3_TRANSFORM_SHIFTS_DRAFT}_SUCCESS`: {
        action.values.map((value: PatchResource) => {
          if (!(value.operation === HTTP_METHOD.POST && value.resource.type === SHIFT_TYPE.UNPUBLISHED_CREATE)) {
            draft.excludedIds = draft.excludedIds.filter((id) => id !== value.resource.id);
          }
        });
        break;
      }
      default:
      // nothing to do => immer returns the original object
    }
  });
