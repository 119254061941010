import React from 'react';
import * as SentryService from '../../services/sentry';
import GenericError from '../GenericError';

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<unknown, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // For better debugging when an error occurs
    console.log({ error, errorInfo });

    // You can also log the error to an error reporting service
    SentryService.withScope((scope) => {
      scope.setExtras({
        errorInfo,
      });
      SentryService.addBreadcrumb({
        category: 'render',
        message: 'A Javascript error happened during rendering of React tree.',
      });
      SentryService.captureException(error);
    });
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return <GenericError />;
    }

    return children;
  }
}

export default ErrorBoundary;
