import { actions, selectors } from '@crewmeister/shared';
import { ignoreReturnFor } from 'promise-frites';
import { statusCodes } from '.';
import { DispatchType } from '../types';
import { reduxStore } from './redux-store';

const { updateCrewMaintenanceStatus } = actions;
const { selectPermissions, createPropsFinder } = selectors;

const findPermissionsByProps = createPropsFinder(selectPermissions);

// Factory for action creators that just pass on all arguments
// that they receive (typical for our async function status actions)
const makeActionCreator =
  (type: string) =>
  (args = {}) => ({
    ...args,
    type,
  });

const toActionName = (namespace: string | null, actionName: string) =>
  namespace ? `${namespace}_${actionName}` : actionName;

const hasPermission = (crewId?: number, name?: string) =>
  name ? findPermissionsByProps(reduxStore.getState(), { crewId, name }, {}, 'allowed') : true;

type DispatchAsyncFnStatusActionsType = {
  dispatch: DispatchType;
  fn: (...args: any) => Record<string, any>;
  fnParams?: any;
  fnParamsInActions?: (...args: any) => Record<string, unknown>;
  actionName: string;
  permission?: string;
  afterSuccessFn?: (...args: any) => unknown;
  namespace?: Record<string, string | null>;
};

// Dispatches the _REQUEST, _SUCCESS, and _FAILURE actions
// when calling a certain fn with fnParams
// The success action carries the result of the fn as property 'result'
// The failure action carries the error of the fn call as property 'error'
export const dispatchAsyncFnStatusActions = ({
  dispatch,
  fn,
  fnParams = {},
  // by default, we keep the same fn parameter names in the actions
  fnParamsInActions = (fnParameters) => fnParameters,
  actionName,
  permission,
  afterSuccessFn,
  namespace = {
    request: null,
    failure: null,
    success: null,
  },
}: DispatchAsyncFnStatusActionsType): Promise<unknown> => {
  if (!hasPermission(fnParams.crewId, permission)) {
    return Promise.resolve();
  }

  const requestAction = makeActionCreator(`${toActionName(namespace.request, actionName)}_REQUEST`);
  const successAction = makeActionCreator(`${toActionName(namespace.success, actionName)}_SUCCESS`);
  const failureAction = makeActionCreator(`${toActionName(namespace.failure, actionName)}_FAILURE`);
  // with fnParamsInActions, we can rename function parameter names in the actions
  const fnParamsActions = fnParamsInActions(fnParams);
  dispatch(requestAction(fnParamsActions));
  return Promise.resolve()
    .then(() => fn(fnParams))
    .then(
      ignoreReturnFor((result: Record<string, unknown>) =>
        dispatch(successAction({ result, ...fnParamsInActions(fnParamsActions) }))
      )
    )
    .then(
      ignoreReturnFor((result: Record<string, unknown>) => afterSuccessFn && afterSuccessFn({ result, ...fnParams }))
    )
    .catch((error) => {
      if (error && error.id === statusCodes.CREW_MAINTENANCE) {
        return dispatch(updateCrewMaintenanceStatus(fnParams.crewId, true));
      }
      dispatch(failureAction({ error, ...fnParamsInActions(fnParamsActions) }));
      throw error;
    });
};
