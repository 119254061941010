import React from 'react';

import { statusCodes } from '../lib';
import { object as objectUtils } from '../utils';

const { areObjectsDifferent, getAllPropertyNamesStartingWith } = objectUtils;

/**
 * **Higher Order Component (HOC)** calling a function
 * whenever certain props for the wrapped component change
 *
 * Pass an **options** object:
 *
 *  * **propNameOfFunctionToCall**:
 *    The name of the function to call when the props changed
 *
 *  * **propNamesTriggeringFunctionCall**:
 *    all prop names that, when at least one of their values is changing, will call the function
 *
 *  * **functionRanOncePropName**:
 *    the prop name to pass to the wrapped component,
 *    indicating with a bool if the function has been called at least once
 *
 *  * **functionIsRunningPropName**:
 *    the prop name to pass to the wrapped component,
 *    indicating with a bool if the function is running right now
 *
 *  * **makeFunctionIsRunningPropNameUnique**:
 *    Make the 'functionIsRunningPropName' unique by adding the current time in milliseconds.
 *    This ensures we can track multiple calls to callFunction.
 */

export const callFunctionWhenPropsChange =
  ({
    propNameOfFunctionToCall = 'functionToCall',
    propNamesTriggeringFunctionCall = [],
    propNamesWithValuesTriggeringFunctionCall = [],
    propNamesTriggeringFunctionUnmemoizedCall = [],
    functionRanOncePropName = 'loadedOnce',
    functionIsRunningPropName = 'loading',
    makeFunctionIsRunningPropNameUnique = false,
    unmemoizeOnMount = false,
    LoadingComponent = false,
  }) =>
  (WrappedComponent) => {
    return class CallFunctionWhenPropsChange extends React.Component {
      constructor(props) {
        super(props);
        this.callFunction = this.callFunction.bind(this);
        this.functionProps = this.functionProps.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.callFunctionIfPropsChanged = this.callFunctionIfPropsChanged.bind(this);
        this.state = {
          functionRanAtLeastOnce: false,
          functionIsRunning: false,
        };
        this.mounted = true;
      }

      componentWillUnmount() {
        this.mounted = false;
      }

      setStateSave(...args) {
        if (this.mounted) {
          this.setState(...args);
        }
      }

      callFunctionIfPropsChanged(currentProps, nextProps, unmemoized) {
        if (propsChanged(propNamesTriggeringFunctionUnmemoizedCall, currentProps, nextProps)) {
          return this.callFunction(nextProps, true);
        }
        if (
          propsChanged(propNamesTriggeringFunctionCall, currentProps, nextProps) ||
          propsWithValues(propNamesWithValuesTriggeringFunctionCall, currentProps, nextProps)
        ) {
          this.callFunction(nextProps, unmemoized);
        }
      }

      callFunction(props, unmemoized = false) {
        const functionToCall = this.props[propNameOfFunctionToCall];
        const propName = makeFunctionIsRunningPropNameUnique
          ? `${functionIsRunningPropName}${new Date().getTime()}`
          : functionIsRunningPropName;
        if (functionToCall) {
          this.setStateSave({ functionIsRunning: true, [propName]: true });
          return Promise.resolve()
            .then(() => functionToCall({ ...props, unmemoized }))
            .then(() =>
              this.setStateSave({
                functionIsRunning: false,
                [propName]: false,
                functionRanAtLeastOnce: true,
              })
            )
            .catch((e) => {
              this.handleError(e);
              this.setStateSave({
                functionIsRunning: false,
                [propName]: false,
                functionRanAtLeastOnce: true,
              });
            });
        }
      }

      handleError = (err) => {
        if (err && err.id !== statusCodes.UNAUTHORIZED) {
          throw err;
        }
      };

      componentDidMount() {
        this.callFunctionIfPropsChanged(null, this.props, unmemoizeOnMount);
      }

      /* eslint-disable-next-line camelcase*/
      UNSAFE_componentWillReceiveProps(nextProps) {
        this.callFunctionIfPropsChanged(this.props, nextProps);
      }

      functionProps() {
        const functionProps = {};
        functionProps[functionRanOncePropName] = this.state.functionRanAtLeastOnce;

        if (makeFunctionIsRunningPropNameUnique) {
          const propNamesOfLoadingStateVars = getAllPropertyNamesStartingWith(this.state, functionIsRunningPropName);
          propNamesOfLoadingStateVars.forEach((propName) => {
            functionProps[propName] = this.state[propName];
          });
        } else {
          functionProps[functionIsRunningPropName] = this.state.functionIsRunning;
        }
        return functionProps;
      }

      render() {
        const { ...propsCopy } = this.props;
        delete propsCopy[propNameOfFunctionToCall];

        if (this.state.functionIsRunning && !this.state.functionRanAtLeastOnce && LoadingComponent) {
          return <LoadingComponent {...this.functionProps()} {...propsCopy} />;
        }

        return <WrappedComponent {...this.functionProps()} {...propsCopy} />;
      }
    };
  };

// did any of the props change when moving from current to next?
export const propsChanged = (propNames, currentProps = null, nextProps = null) => {
  if (currentProps === null && nextProps === null) {
    return false;
  } else if (currentProps === null) {
    return atLeastOnePropDefined(nextProps, propNames);
  } else if (nextProps === null) {
    return atLeastOnePropDefined(currentProps, propNames);
  }

  // if both props object are defined, we compare the required prop values
  return propNames.some((propName) => areObjectsDifferent(currentProps, nextProps, [propName]));
};

export const propsWithValues = (propNames, currentProps = null, nextProps = null) => {
  if (currentProps === null || nextProps === null) {
    return false;
  }
  return propNames.some(
    (propName) => nextProps[propName.name] === propName.value && currentProps[propName.name] !== propName.value
  );
};

export const atLeastOnePropDefined = (props, propNames) =>
  props ? propNames.some((propName) => props[propName]) : false;
