import { createSelector } from 'reselect';

// Selectors are required to access the state (only read the state via selectors!)
export const selectAuthentication = (state) => state.authentication && state.authentication.authentication;
export const selectCurrentAuthenticationLoading = (state) => state.authentication.isLoadingCurrent;

export const selectCurrentUserId = (state) => {
  const authentication = selectAuthentication(state);
  if (authentication && authentication.userAuthentication) {
    return authentication.userAuthentication.userId;
  }
  return null;
};

export const selectCurrentCrewAuthentication = (state) => {
  const authentication = selectAuthentication(state);
  if (authentication) {
    return authentication.crewAuthentication;
  }
  return null;
};

export const selectCurrentAdminId = (state) => {
  const authentication = selectAuthentication(state);
  if (authentication && authentication.adminAuthentication) {
    return authentication.adminAuthentication.adminId;
  }
  return null;
};

const selectCurrentContextFromState = (state) => state.authentication.currentContext;

export const selectCurrentContext = createSelector(selectCurrentContextFromState, (context) => ({ ...context }));
