import { factory, trait, sequence } from 'stahlwerk';

export const createMember = factory({
  id: sequence(),
  userId: sequence(),
  crewId: sequence(),

  name: 'The Hoff',
  email: 'the@hoff.rocks',
  username: 'the_hoff',
  pinCode: 1234,

  role: 'owner',
  disabledAt: null,
  hasAvatar: true,
  avatarUrl: 'https://picsum.photos/200/300',

  withoutImage: trait({
    hasAvatar: false,
    avatarUrl: null,
  }),

  member: trait({
    role: 'member',
  }),

  manager: trait({
    role: 'manager',
  }),

  owner: trait({
    role: 'owner',
  }),

  isDisabled: trait({
    disabledAt: '2000-01-01',
  }),
});
