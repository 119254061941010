import produce from 'immer';
import { createSelector } from 'reselect';
import { V3TemplateShiftType } from '../../types';

import { selectCurrentCrewId } from '../crews-selectors';

export const v3SelectTemplateShifts = (state: any): Array<V3TemplateShiftType> =>
  state.boV3.templateShifts.templateShifts || produce([], () => {});

export const v3SelectCrewTemplateShifts = createSelector(
  v3SelectTemplateShifts,
  selectCurrentCrewId,
  (templateShifts, crewId) => templateShifts.filter((templateShift) => templateShift.crewId === crewId)
);
