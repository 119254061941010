import { connect } from 'react-redux';
import { collections } from '../../utils';
import Organism from './organism';
import * as selectors from '../../selectors';

const { selectCurrentCrew } = selectors;
const { composeReverse } = collections;

const mapStateToProps = (state, props) => ({
  crew: selectCurrentCrew(state, props),
});

const CrewMaintenance = composeReverse(connect(mapStateToProps))(Organism);

export default CrewMaintenance;
