import { memoize } from 'redux-memoize';
import uuid from 'uuid-js';
import { dispatchAsyncFnStatusActions, dispatchOptimisticAsyncFnStatusActions } from '../lib';

export const loadMessages = memoize(
  { ttl: 0 },
  ({ api, userId, roomId, perPage, page }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_MESSAGES',
        fnParams: { userId, roomId, perPage, page },
        fn: (params) => api.getRoomMessages(params),
      })
);

export const createMessage = memoize(
  {},
  ({ api, userId, roomId, message, state }) =>
    (dispatch) =>
      dispatchOptimisticAsyncFnStatusActions({
        dispatch,
        actionName: 'CREATE_MESSAGE',
        fnParams: { userId, roomId, message },
        fn: (params) => api.createRoomMessage(params),
        state,
        optimisticParseFn: (params) => ({
          ...params,
          id: uuid.create().toString(),
          createdBy: params.userId,
          sentAt: new Date().toISOString(),
          readBy: [],
        }),
      })
);

export const updateMessage = memoize(
  {},
  ({ api, userId, roomId, messages }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'UPDATE_MESSAGE',
        fnParams: { userId, roomId, messages },
        fn: (params) => api.updateRoomMessages(params),
      })
);

export const receivedMessage = memoize(
  {},
  (payload) => (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'RECEIVED_MESSAGE',
      fnParams: payload,
      fn: (params) => Promise.resolve(params),
    })
);
