import { connect } from 'react-redux';
import { collections } from '../../utils';
import { selectNotifications } from '../../selectors/notifications-selectors';
import { removeNotification } from '../../action-creators/notifications-actions';
import Notifications from './organism';

const { composeReverse } = collections;

const mapStateToProps = (appState) => {
  return {
    notifications: selectNotifications(appState),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRemove: ({ id }) => dispatch(removeNotification({ id })),
});

const NotificationContainer = composeReverse(connect(mapStateToProps, mapDispatchToProps))(Notifications);

NotificationContainer.displayName = 'NotificationContainer';

export default NotificationContainer;
