// TODO: move this file to utils

import { isBefore, isSameDay, differenceInCalendarDays } from 'date-fns';
import { array as arrayUtils, date as dateUtils, time } from '../../utils';
import t from './translate';
import { getTranslationLanguage } from '../../lib/translate';

const { groupBy } = arrayUtils;
const { formatDateExtraShort, formatIso8601Date } = dateUtils;
const { formatIsoTimeShort } = time;

const isStopWork = (stamp) => stamp.timeAccount === 0;
const isStartWork = (stamp) => stamp.timeAccount === 1;
const isPauseWork = (stamp) => stamp.timeAccount === 2;
const round = (value) => Math.round(value * 100) / 100;

const formatDate = (date, selectedDate) => {
  const diff = differenceInCalendarDays(new Date(date), new Date(selectedDate));
  if (diff === 1) return `${t('till')} ${t('tomorrow')} ${formatIsoTimeShort(date)}`;
  if (diff === -1) return `${t('since')} ${t('yesterday')} ${formatIsoTimeShort(date)}`;
  if (diff > 1) return `${t('till')} ${formatDateExtraShort(date, getTranslationLanguage())}`;
  if (diff < -1) return `${t('since')} ${formatDateExtraShort(date, getTranslationLanguage())}`;
};

const isOpenStampChain = (stamps, clockInStamp) => {
  return !stamps.some((stamp) => stamp.clockInStamp === clockInStamp && isStopWork(stamp));
};

const messageBefore = (stamps, selectedDate) => {
  let index = 0;
  while (stamps[index] && isBefore(new Date(stamps[index].timestamp), new Date(selectedDate))) index++;
  const lastStampBefore = index ? stamps[index - 1] : stamps[0];
  return isPauseWork(lastStampBefore)
    ? `Pause ${formatDate(lastStampBefore.timestamp, selectedDate)}`
    : formatDate(stamps[0].timestamp, selectedDate);
};
const messageAfter = (stamps, selectedDate, isOpen) => {
  return !isOpen ? formatDate(stamps[stamps.length - 1].timestamp, selectedDate) : '';
};

const chainStartsWithin = (stamps, iso) => {
  return stamps.length && isSameDay(new Date(stamps[0].timestamp), new Date(iso));
};

const chainEndsWithin = (stamps, iso) => {
  return stamps.length && isSameDay(new Date(stamps[stamps.length - 1].timestamp), new Date(iso));
};

const extractHoursMinutes = (stamp) => {
  const date = new Date(stamp && stamp.timestamp);
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
    time: formatIsoTimeShort(date),
  };
};

const hasNotOnlyStartWork = (stamps) => !!stamps.filter((stamp) => !isStartWork(stamp)).length;
const hasTailMeta = (stamps) => stamps.length > 1 && hasNotOnlyStartWork(stamps);

const chainMeta = (stamps, selectedDate) => {
  const isOpen = stamps[0] && isOpenStampChain(stamps, stamps[0].clockInStamp);
  const endsWithin = chainEndsWithin(stamps, selectedDate);
  const startsWithin = chainStartsWithin(stamps, selectedDate);
  const first = extractHoursMinutes(stamps[0]);
  const last = extractHoursMinutes(stamps[stamps.length - 1]);
  const firstTimeStamp = stamps[0].timestamp;
  const lastTimeStamp = stamps[stamps.length - 1].timestamp;
  return {
    firstTimeInChain: first.time,
    lastTimeInChain: last.time,
    head: startsWithin ? formatIsoTimeShort(firstTimeStamp) : messageBefore(stamps, selectedDate),
    headOffset: startsWithin ? round((100 / 24) * (first.minutes / 60 + first.hours)) : 0,
    headAction: formatIso8601Date(firstTimeStamp),
    tail:
      endsWithin && !isOpen && stamps.length > 1
        ? formatIsoTimeShort(lastTimeStamp)
        : messageAfter(stamps, selectedDate, isOpen),
    tailOffset: endsWithin && hasTailMeta(stamps) ? 100 - round((100 / 24) * (last.minutes / 60 + last.hours)) : 0,
    tailAction: hasTailMeta(stamps) ? formatIso8601Date(lastTimeStamp) : '',
  };
};

export const groupByClockIn = (stamps) => groupBy('clockInTimestamp', stamps);

export const createMetaLabels = (stamps, selectedDate) => {
  const chains = groupByClockIn(stamps);
  const result = Object.keys(chains).map((clockIn) => {
    return chainMeta(chains[clockIn], selectedDate);
  });
  return [].concat(...result);
};
