import produce from 'immer';
import { UI_STATE_KEY_CURRENT_DATE, UI_STATE_KEY_USER_APP_IN_TERMINAL_MODE } from '../action-creators/ui-actions';

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
const initialState = produce(
  {
    reloadTimeDurations: 0,
    reloadTimeDurationsForDays: { value: 0 },
    reloadDurationBalances: 0,
    reloadDurationBalancesForDays: { value: 0 },
    firstSignIn: false,
    absenceManagerSelectedUserIds: [],
    absenceManagerQuickFilter: null,
    timeTrackingSelectedUserIds: [],
  },
  (draft) => {
    draft[UI_STATE_KEY_CURRENT_DATE] = new Date();
  }
);

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const uiReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_UI_STATE_VALUE':
        draft[action.key] = action.value;
        break;
      case 'RELOAD_TIME_DURATIONS':
        draft.reloadTimeDurations = draft.reloadTimeDurations + 1;
        break;
      case 'RELOAD_TIME_DURATIONS_FOR_DAYS':
        draft.reloadTimeDurationsForDays.value = draft.reloadTimeDurationsForDays.value + 1;
        draft.reloadTimeDurationsForDays.userId = action.result.userId;
        draft.reloadTimeDurationsForDays.startDate = action.result.startDate;
        draft.reloadTimeDurationsForDays.endDate = action.result.endDate;
        break;
      case 'RELOAD_DURATION_BALANCES':
        draft.reloadDurationBalances = draft.reloadDurationBalances + 1;
        break;
      case 'RELOAD_DURATION_BALANCES_FOR_DAYS':
        draft.reloadDurationBalancesForDays.value = draft.reloadDurationBalancesForDays.value + 1;
        draft.reloadDurationBalancesForDays.userId = action.result.userId;
        draft.reloadDurationBalancesForDays.startDate = action.result.startDate;
        draft.reloadDurationBalancesForDays.endDate = action.result.endDate;
        break;
      case 'SIGNOUT_USER_SUCCESS':
        draft[UI_STATE_KEY_CURRENT_DATE] = new Date();
        draft[UI_STATE_KEY_USER_APP_IN_TERMINAL_MODE] = false;
        break;
      case 'SIGN_IN_CREW_AND_SIGN_OUT_USER_SUCCESS':
      case 'SIGNOUT_CREW_SUCCESS':
        draft[UI_STATE_KEY_USER_APP_IN_TERMINAL_MODE] = false;
        break;
      default:
      // nothing to do => immer returns the draft state unmodified
    }
  });
