import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../../constants';
import { V3Workplace } from '../../types';

type V3WorkplaceAction = AnyAction & { crewId: number; result: { content: V3Workplace[] } };

type V3WorkplacesSliceInRedux = { workplaces: V3Workplace[] };

const initialState = produce({ workplaces: [] }, () => {});

const workplacesNotInCrew = (crewId: number) => (model: V3Workplace) => model.crewId !== crewId;

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const workplacesReducer = (state = initialState, action: V3WorkplaceAction): V3WorkplacesSliceInRedux =>
  produce(state, (draft: V3WorkplacesSliceInRedux) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_LOAD_WORKPLACES}_SUCCESS`:
        draft.workplaces = draft.workplaces.filter(workplacesNotInCrew(action.crewId)).concat(action.result.content);
        break;
      default:
      // nothing to do => immer returns the original object
    }
  });
