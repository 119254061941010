import { createSelector } from 'reselect';
import produce from 'immer';
import { createPropsFinder, createPropsSelector } from './utils';
import { selectCurrentCrewId } from './crews-selectors';
import { CrewSettingsType } from '../types';

export const selectCrewSettings = (state: any) => state.bo.crewSettings.crewSettings || produce([], () => {});

export const selectCurrentCrewSettings = createSelector(
  selectCrewSettings,
  selectCurrentCrewId,
  (crewSettings: CrewSettingsType[], crewId: number) => crewSettings.find((settings: CrewSettingsType) => settings.crewId === crewId && !settings.userId)
);

export const selectCurrentSettingsInMultiCrew = createSelector(
  selectCrewSettings,
  selectCurrentCrewId,
  (crewSettings: CrewSettingsType[], crewId: number) => crewSettings.filter((settings: CrewSettingsType) => settings.crewId === crewId)
);

export const selectSettingsForAllMembersOfCrew = createSelector(
  selectCrewSettings,
  selectCurrentCrewId,
  (crewSettings: CrewSettingsType[], crewId: number) => crewSettings.filter((setting: CrewSettingsType) => setting.crewId === crewId && !!setting.userId)
);

export const selectSettingsForMemberOrCrew = createSelector(
  createPropsFinder(selectCrewSettings),
  selectCurrentCrewSettings,
  (memberSettings: CrewSettingsType | undefined, crewSettings: CrewSettingsType | undefined) => memberSettings || crewSettings
);

export const selectCrewSettingsByProps = createPropsSelector(selectCrewSettings);
export const findCrewSettingsByProps = createPropsFinder(selectCrewSettings);
