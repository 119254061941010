/* eslint-disable no-param-reassign */
import produce from 'immer';

const initialState = produce(
  {
    liveView: {
      userSort: '',
      showMap: true,
      showInactive: true,
    },
    timeTrackingReports: {
      xAxis: 'week',
      yAxis: 'userId',
      timeType: 'work',
      dateRange: [],
      filter: '',
      selectedUserIds: [],
      selectedGroupIds: [],
    },
    absenceManagement: {
      selectedUserIds: [],
      activeMembersFilter: false,
    },
  },
  () => {}
);

export const paramsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_PARAM_VALUE':
        if (draft[action.product]) {
          if (action.crewId) {
            draft[action.product][action.crewId] = {
              ...(draft[action.product][action.crewId] || {}),
              [action.key]: action.value,
            };
          } else {
            draft[action.product][action.key] = action.value;
          }
        } else {
          draft[action.key] = action.value;
        }
        break;
      case 'SET_PARAM_VALUES':
        Object.keys(action.params).forEach((key) => {
          if (draft[action.product]) {
            if (action.crewId) {
              draft[action.product][action.crewId] = {
                ...(draft[action.product][action.crewId] || {}),
                [key]: action.params[key],
              };
              draft[action.product][action.crewId][key] = action.params[key];
            } else {
              draft[action.product][key] = action.params[key];
            }
          } else {
            draft[key] = action.params[key];
          }
        });
        break;
      default:
      // nothing to do => immer returns the same object
    }
  });
