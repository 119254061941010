import Cookies from 'js-cookie';

import { COOKIE_NAME } from './types';

const EXPIRY_DATE = new Date('9999-12-31');

export class CookieStore {
  getToken = (name: COOKIE_NAME) => this.#getCookie(name);

  setToken = (name: COOKIE_NAME, v3Token: string, domain?: string) => this.#setCookie(name, v3Token, domain);

  removeToken = (name: COOKIE_NAME, domain?: string) => this.#removeCookie(name, domain);

  #getCookie = (name: string) => Cookies.get(name);

  #setCookie = (name: string, value: string, domain?: string) =>
    Cookies.set(name, value, {
      domain: this.#getDomain() || domain,
      expires: EXPIRY_DATE,
    });

  #removeCookie = (name: string, domain?: string) =>
    Cookies.remove(name, {
      domain: this.#getDomain() || domain,
      expires: EXPIRY_DATE,
    });

  #getDomain = () => window?.location?.hostname?.split('.')?.slice(-2)?.join('.');
}
