import { isSameDay } from 'date-fns';
import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ absenceBalances: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const absenceBalancesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_ABSENCE_BALANCES_SUCCESS':
        applyMinimalChangesToArray(
          draft.absenceBalances,
          action.result,
          (balance) =>
            balance.crewId === action.crewId &&
            balance.typeId === action.typeId &&
            (!action.userIds || !action.userIds.length || action.userIds.includes(balance.userId)) &&
            (!action.date || isSameDay(new Date(balance.date), new Date(action.date)))
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
