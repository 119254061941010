/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction, buildDangerouslyTranslateNeverWithUserContent } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    crew: 'Deine Crew',
    h2: '{{crewName}} wird gerade gewartet.',
    header: 'Einen Moment bitte...',
    reload: 'Neuladen',
    text: `
      Crewmeister optimiert gerade {{crewName}} um in Zukunft noch schneller arbeiten zu können.<br>
      Dieser Prozess kann ein paar Minuten dauern, wir entschuldigen uns für die Umstände.<br>
      In ein paar Minuten geht’s weiter!
    `,
  },
  en: {
    crew: 'Your crew',
    h2: '{{crewName}} is under maintenance.',
    header: 'One moment please...',
    reload: 'Reload',
    text: `
      Crewmeister is optimizing {{crewName}} to work faster in the future.<br>
      This process can take a few minutes, we apologize for the incovenience.<br>
      You can continue in a few minutes!
    `,
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
