import React, { useState } from 'react';

import LoadingSpinnerWithText from '../../../components/core/loading-spinner-with-text';
import Terminal3ColumnMemberOverlay from '../../terminal-3-column-member-overlay';

import Members3ColumnList from './members-3-column-list';

const Members3ColumnListComponent = ({
  getSettingsForMember,
  members,
  setIsOverlayOpen,
  setMemberForOverlay,
  onStartBreak,
  onStartWork,
  onStopWork,
  crewHasTimeCategories,
}) =>
  React.useMemo(
    () => (
      <Members3ColumnList
        getSettingsForMember={getSettingsForMember}
        members={members}
        setIsOverlayOpen={setIsOverlayOpen}
        setMemberForOverlay={setMemberForOverlay}
        onStartBreak={onStartBreak}
        onStartWork={onStartWork}
        onStopWork={onStopWork}
        crewHasTimeCategories={crewHasTimeCategories}
      />
    ),
    [members.map((member) => member.activeTimeAccount)]
  );

const Component = (props) => {
  const { loadedOnce } = props;

  if (!loadedOnce) {
    return <LoadingSpinnerWithText fullscreen />;
  }

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [memberForOverlay, setMemberForOverlay] = useState();

  return (
    <section>
      <If condition={!!memberForOverlay && isOverlayOpen}>
        <Terminal3ColumnMemberOverlay isOpen={isOverlayOpen} member={memberForOverlay} setIsOpen={setIsOverlayOpen} />
      </If>
      <Members3ColumnListComponent
        {...props}
        setIsOverlayOpen={setIsOverlayOpen}
        setMemberForOverlay={setMemberForOverlay}
      />
    </section>
  );
};

export default Component;
