import fetch from './fetch';
import { ApiError } from '../../error';

import { INVALID_ARGUMENT, STATUS_CODE_KEY } from './status-codes';

const throwErrors = (json) => {
  const status = json[STATUS_CODE_KEY];

  if (status === INVALID_ARGUMENT) {
    throw new ApiError(status, ApiError.INVALID_ARGUMENT, json.errors);
  }

  throw new ApiError(status, ApiError.UNKNOWN);
};

export function setPasswordByToken({ headers, password, token }) {
  let hasError = false;
  const handleJsonResponse = (json) => {
    if (hasError) {
      throwErrors(json);
    }
  };

  return fetch
    .setPasswordByToken({ headers, password, token })
    .then((response) => {
      hasError = response.status !== 200;
      return response;
    })
    .then((response) => response.json())
    .then(handleJsonResponse);
}
