import React from 'react';
import { toIso, asMinutes } from 'pomeranian-durations';
import withLabel from './with-label';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS } from '../../constants';

const MinutesInput = withLabel(
  ({ value, onChange, step, onFocus, onBlur, styles, required, isExpanded, isFocused, disabled, name, ...props }) => {
    return (
      <div className={css(baseStyles.wrapper)}>
        <input
          data-e2e-test={props['data-e2e-test']}
          {...(value ? { value: Math.floor(asMinutes(value)) } : { value: 0 })}
          type="number"
          min="0"
          name={name}
          onChange={(e) => {
            onChange(toIso({ seconds: e.target.value * 60 }, { includeZeroValues: true }));
            if (!isFocused) onBlur(e); // Firefox doesn't focus when using input field arrows
          }}
          className={css(styles.textInput, isExpanded && styles.textInputExpanded, disabled && baseStyles.disabled)}
          step={step}
          onFocus={(e) => {
            onFocus(e);
            autoSelect(e);
          }}
          onBlur={onBlur}
          required={required}
          disabled={disabled}
        />
        <span
          className={css(
            !isExpanded && baseStyles.hideMinText,
            isExpanded && baseStyles.expanded,
            disabled && baseStyles.disabled
          )}
        >
          min
        </span>
      </div>
    );
  }
);

const autoSelect = (event) => {
  const elem = event.target;
  setTimeout(() => elem.select(), 100); // delay needed for WebKit/Edge
};

const baseStyles = StyleSheet.create({
  showButton: {
    cursor: 'pointer',
  },
  expanded: {
    transition: 'opacity 0.4s',
    opacity: '1',
    height: '20px',
    lineHeight: 1.2,
  },
  wrapper: {
    display: 'flex',
  },
  hideMinText: {
    transition: 'opacity 0.4s',
    opacity: '0',
  },
  disabled: {
    color: COLORS.GREY_LIGHT,
  },
});

export default MinutesInput;
