import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ salaryExportConfigurations: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const salaryExportsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_SALARY_EXPORT_CONFIGURATIONS_SUCCESS':
        applyMinimalChangesToArray(draft.salaryExportConfigurations, action.result);
        break;
      default:
      // nothing to do => immer returns the same object
    }
  });
