import PropTypes from 'prop-types';
import React from 'react';
import { collections } from '../../utils';
import { StyleSheet, css } from '../../_external-deps/stylesheet';

const { composeReverse } = collections;

const ComponentWithDisabled = (Component) => (props) =>
  (
    <div className={css(styles.regular, props.disabled && styles.disabled)}>
      <Component {...props} />
    </div>
  );

ComponentWithDisabled.propTypes = {
  disabled: PropTypes.bool,
};

const withDisabled = composeReverse(ComponentWithDisabled);

const styles = StyleSheet.create({
  regular: {
    width: '100%',
  },
  disabled: {
    opacity: 0.6,
  },
});

export default withDisabled;
