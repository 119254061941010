import React from 'react';
import NotificationItem from './notification-item';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { Z_INDEX } from '../../styles/config';
import { isE2ETest } from '../../lib';

const noopForSignInScreens = () => {};

const Notifications = ({ notifications, onRemove = noopForSignInScreens }) => (
  <If condition={!isE2ETest()}>
    <aside className={css(styles.wrapper)}>
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} onRemove={onRemove} />
      ))}
    </aside>
  </If>
);

const ensureNotificationsDontOverlayNavigation = {
  height: 0,
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'fixed',
    right: '1rem',
    top: '14px',
    zIndex: Z_INDEX.notifications,
    width: '100%',
    maxWidth: '250px',
    ...ensureNotificationsDontOverlayNavigation,
  },
});

export default Notifications;
