// TODO: refactor this
import {
  fetchPost as nativeFetchPost,
  fetchGet as nativeFetchGet,
  fetchDelete as nativeFetchDelete,
  fetchPut as nativeFetchPut,
} from './fetch';

import { stringifyQueryParams } from '../_external-deps/url';
import { buildUrl } from '../config';
import { NetworkError } from '../error';
import { NETWORK_TIMEOUT, throwIfError } from './v2/status-codes';
import { extractCrewmeisterCookie } from './v1/headers';
import validateParams from './validate-params';

const updateContext = (context) => (response) => {
  const setCookieHeader = response.headers.get('set-cookie');
  const crewmeisterCookie = extractCrewmeisterCookie(setCookieHeader);
  context.headers.setHeaders({ cookie: crewmeisterCookie });
  return response;
};

const toPayload = (response) =>
  Promise.resolve()
    .then(() => response.json())
    .then((json) => (json.pagination ? { pagination: json.pagination, payload: json.payload } : json.payload));

const executers = {
  GET: (url, headers, params) => {
    const queryParams = stringifyQueryParams(params);
    return fetchJsonGet(url + queryParams, { headers });
  },
  DELETE: (url, headers, params) => {
    const queryParams = stringifyQueryParams(params);
    return fetchJsonDelete(url + queryParams, { headers });
  },
  POST: (url, headers, body) => {
    return fetchJsonPost(url, { headers, body });
  },
  PUT: (url, headers, body) => {
    return fetchJsonPut(url, { headers, body });
  },
};
const processResponse = (context) => (response) =>
  Promise.resolve(response).then(updateContext(context)).then(throwIfError).then(toPayload);

const processResponseWithTraceId = (context) => (response) => {
  const xTraceId = response.headers.map['x-trace-id'];
  return Promise.resolve(response)
    .then(updateContext(context))
    .then(throwIfError)
    .then(toPayload)
    .then((result) => ({ ...result, xTraceId }))
    .catch((error) => {
      const enhancedError = Object.assign({}, error);
      enhancedError.xTraceId = xTraceId;
      throw enhancedError;
    });
};

const asIs = (value) => value;
const defaultErrorHandler = (error) => {
  throw error;
};
export const execute = (
  config,
  context,
  tokenStore,
  params = {},
  errorHandler = defaultErrorHandler,
  withTraceId = false
) => {
  const { requiredParams = [], optionalParams = [], postProcess = asIs } = config;

  const filteredParams = pick(
    params,
    [...(config.params || []), ...requiredParams, ...optionalParams],
    config.method === 'GET' || config.method === 'DELETE'
  );

  const preProcessedParams = config.preProcessParams
    ? config.preProcessParams(filteredParams, tokenStore)
    : filteredParams;

  const url = config.buildUrl(params);
  // wrap console in closure since IE 11 throws when pasing an reference
  // eslint-disable-next-line no-console
  const log = (...args) => {
    if (console) {
      console.log(...args); // eslint-disable-line
    }
  };
  validateParams(params, requiredParams, optionalParams, url, log);

  const headers = {
    ...context.toHeaders(),
    ...(config.additionalHeaders || {}),
  };

  if (withTraceId) {
    return Promise.resolve()
      .then(() => tokenStore.toHeaders())
      .then((tokenHeaders) => {
        return executers[config.method](url, { ...tokenHeaders, ...headers }, preProcessedParams);
      })
      .catch((e) => {
        throw new NetworkError(`Unknown error occurred (${e})`, NETWORK_TIMEOUT);
      })
      .then((response) => processResponseWithTraceId(context)(response)) // Use processResponseWithTraceId here
      .then((result) => postProcess(result, tokenStore))
      .catch(errorHandler);
  }

  return Promise.resolve()
    .then(() => tokenStore.toHeaders())
    .then((tokenHeaders) => {
      return executers[config.method](url, { ...tokenHeaders, ...headers }, preProcessedParams);
    })
    .catch((e) => {
      throw new NetworkError(`Unknown error occurred (${e})`, NETWORK_TIMEOUT);
    })
    .then(config.processResponse || processResponse(context))
    .then((result) => postProcess(result, tokenStore))
    .catch(errorHandler);
};

const isArray = (ar) => Object.prototype.toString.call(ar) === '[object Array]';

const pick = (object, whitelist, parseArray = false) => {
  const params = whitelist
    .filter((prop) => prop in object)
    .map((prop) => {
      const key = isArray(object[prop]) && parseArray ? `${prop}[]` : prop;
      return { [key]: object[prop] };
    });

  return Object.assign({}, ...params);
};

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const buildJsonHeaders = (headers) => ({ ...jsonHeaders, ...headers });
const buildJsonBody = (body) => JSON.stringify(body);

const fetchJsonDelete = (url, { headers, body }) =>
  nativeFetchDelete(buildUrl(url), {
    headers: buildJsonHeaders(headers),
    body: buildJsonBody(body),
  });

const fetchJsonPost = (url, { headers, body }) =>
  nativeFetchPost(buildUrl(url), {
    headers: buildJsonHeaders(headers),
    body: buildJsonBody(body),
  });

const fetchJsonGet = (url, { headers, body }) =>
  nativeFetchGet(buildUrl(url), {
    headers: buildJsonHeaders(headers),
    body: buildJsonBody(body),
  });

const fetchJsonPut = (url, { headers, body }) =>
  nativeFetchPut(buildUrl(url), {
    headers: buildJsonHeaders(headers),
    body: buildJsonBody(body),
  });
