import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../../constants';
import { V3ShiftOfferReply } from '../../types';
import { array as arrayUtils } from '../../utils';

const { applyMinimalChangesToArray } = arrayUtils;

type V3ShiftOfferRepliesSliceInRedux = { shiftOfferReplies: V3ShiftOfferReply[] };

const initialState = produce({ shiftOfferReplies: [] }, () => {});

export const ShiftOfferRepliesReducer = (state = initialState, action: AnyAction): V3ShiftOfferRepliesSliceInRedux =>
  produce(state, (draft: V3ShiftOfferRepliesSliceInRedux) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_LOAD_SHIFT_OFFER_REPLIES}_SUCCESS`: {
        applyMinimalChangesToArray(
          draft.shiftOfferReplies,
          action.result.content,
          (shiftOfferReply) =>
            shiftOfferReply.crewId === action.crewId && (!action.userId || shiftOfferReply.userId === action.userId)
        );
        break;
      }

      case `${ACTION_NAMES.V3_CREATE_SHIFT_OFFER_REPLY}_SUCCESS`: {
        const result = action?.result?.resourceAfterWrite || action?.result;
        applyMinimalChangesToArray(draft.shiftOfferReplies, result, () => false);
        break;
      }

      case `${ACTION_NAMES.V3_DELETE_SHIFT_OFFER_REPLY}_SUCCESS`: {
        const id = action?.result?.resourceBeforeWrite?.id || action?.result?.id;
        applyMinimalChangesToArray(draft.shiftOfferReplies, [], (shiftOfferReply) => shiftOfferReply?.id === id);
        break;
      }

      default:
      // nothing to do => immer returns the original object
    }
  });
