import { addDays, addMonths, addWeeks, endOfMonth, startOfMonth } from 'date-fns';
import { memoize } from 'redux-memoize';
import { delay } from 'promise-frites';
import { ASYNC_RESOURCE_REQ_DELAY, NUMBER_OF_RETRIES } from '@crewmeister/shared/src/constants';
import { dataTypes, date, duration as durationUtils, string } from '../utils';

import { dispatchAsyncFnStatusActions } from '../lib';
import { areDurationsEqual } from './utils';
const ATC_PROCESSING_DELAY_SECONDS = 2; // TODO: move to constants
const { isString } = dataTypes;
const { earliestDate, endOfWeek, formatIso8601Date, latestDate, startOfWeek } = date;
const { getGroupByForDurationApi } = durationUtils;
const { toSnakeCase, toCamelCase } = string;

const transformCommaSeparatedValue = (value) => (isString(value) ? value.split(',') : value);

const prepareDurations = ({ additionalFilters, from, result, to, type, ...fnParams }) =>
  result.map((duration) => {
    const userId = duration.groupBy.userId ? duration.groupBy.userId : fnParams.userId;

    const timeCategory1Id = transformCommaSeparatedValue(fnParams.timeCategory1Id);
    const timeCategory2Id = transformCommaSeparatedValue(fnParams.timeCategory2Id);
    const userIdForFilter = transformCommaSeparatedValue(fnParams.userId);

    const filters = { ...additionalFilters, from, timeCategory1Id, timeCategory2Id, to, type, userId: userIdForFilter };

    return {
      ...duration,
      crewId: fnParams.crewId,
      type: toCamelCase(duration.type),
      ...(userId ? { userId } : {}),
      ...getFromAndTo(duration, from, to),
      filters: { ...filters, id: JSON.stringify(filters) },
    };
  });

const getFromAndTo = (duration, from, to) => {
  if (duration.groupBy.hasOwnProperty('day')) {
    const fromGroupByDay = formatIso8601Date(addDays(new Date(from), duration.groupBy.day));
    return {
      from: fromGroupByDay,
      to: fromGroupByDay,
    };
  } else if (duration.groupBy.hasOwnProperty('week')) {
    return {
      from: latestDate(from, formatIso8601Date(startOfWeek(addWeeks(new Date(from), duration.groupBy.week)))),
      to: earliestDate(to, formatIso8601Date(endOfWeek(addWeeks(new Date(from), duration.groupBy.week)))),
    };
  } else if (duration.groupBy.hasOwnProperty('month')) {
    return {
      from: latestDate(from, formatIso8601Date(startOfMonth(addMonths(new Date(from), duration.groupBy.month)))),
      to: earliestDate(to, formatIso8601Date(endOfMonth(addMonths(new Date(from), duration.groupBy.month)))),
    };
  }

  return { from, to };
};

export const loadDurations = memoize(
  { ttl: 5000 },
  ({
      actionName = 'LOAD_DURATIONS',
      additionalFilters = {},
      api,
      byUser = false,
      crewId,
      enableCache = false,
      from,
      granularity,
      groupBy,
      groupByGranularity = true,
      timeCategory1Id,
      timeCategory2Id,
      to,
      typeName,
      userId,
    }) =>
    (dispatch) => {
      const groupByGenerated = groupBy || getGroupByForDurationApi(granularity, byUser, groupByGranularity);
      const fnParams = {
        crewId,
        from,
        to,
        ...(userId ? { userId } : {}),
        ...(typeName ? { type: toSnakeCase(typeName) } : {}),
        ...(groupByGenerated ? { groupBy: groupByGenerated } : {}),
        enableCache,
        ...(timeCategory1Id ? { timeCategory1Id: timeCategory1Id } : {}),
        ...(timeCategory2Id ? { timeCategory2Id: timeCategory2Id } : {}),
      };
      const fnParamsInActions = (fnParamsApi) => ({
        ...fnParamsApi,
        typeName,
        granularity,
        byUser,
        timeCategory1Id,
        timeCategory2Id,
      });

      return dispatchAsyncFnStatusActions({
        permission: 'canUseTimeTracking',
        dispatch,
        actionName,
        fnParams,
        fnParamsInActions,
        fn: () =>
          api
            .durations(fnParams)
            .then((result) =>
              prepareDurations({ additionalFilters, result, ...fnParams, timeCategory1Id, timeCategory2Id })
            ),
      });
    }
);

export const loadDurationsWithDelay = ({
  actionName = 'LOAD_DURATIONS_WITH_DELAY',
  additionalFilters = {},
  api,
  byUser = false,
  crewId,
  enableCache = false,
  from,
  granularity,
  groupBy,
  groupByGranularity = true,
  timeCategory1Id,
  timeCategory2Id,
  to,
  typeName,
  userId,
  delayTime = ASYNC_RESOURCE_REQ_DELAY,
  retries = NUMBER_OF_RETRIES,
  durationsForUserCrewInRange = [],
}) => {
  return (dispatch) => {
    return Promise.resolve()
      .then(() => delay(ATC_PROCESSING_DELAY_SECONDS))
      .then(() => {
        const groupByGenerated = groupBy || getGroupByForDurationApi(granularity, byUser, groupByGranularity);
        const fnParams = {
          crewId,
          from,
          to,
          ...(userId ? { userId } : {}),
          ...(typeName ? { type: toSnakeCase(typeName) } : {}),
          ...(groupByGenerated ? { groupBy: groupByGenerated } : {}),
          enableCache,
          ...(timeCategory1Id
            ? { timeCategory1Id: timeCategory1Id.includes('null') ? timeCategory1Id : [...timeCategory1Id, null] }
            : {}),
          ...(timeCategory2Id
            ? { timeCategory2Id: timeCategory2Id.includes('null') ? timeCategory2Id : [...timeCategory2Id, null] }
            : {}),
        };
        const fnParamsInActions = (fnParamsApi) => ({
          ...fnParamsApi,
          typeName,
          granularity,
          byUser,
          timeCategory1Id,
          timeCategory2Id,
        });

        return dispatchAsyncFnStatusActions({
          permission: 'canUseTimeTracking',
          dispatch,
          actionName,
          fnParams,
          fnParamsInActions,
          fn: () =>
            new Promise((resolve, reject) => {
              let counter = 0;
              let oldDurations = durationsForUserCrewInRange;
              const delayInterval = setInterval(() => {
                api
                  .durations(fnParams)
                  .then((result) => {
                    const durations = prepareDurations({
                      additionalFilters,
                      result,
                      ...fnParams,
                      timeCategory1Id,
                      timeCategory2Id,
                    });
                    if (counter > retries || !areDurationsEqual(oldDurations, durations)) {
                      clearInterval(delayInterval);
                      resolve(durations);
                    }
                    oldDurations = result;
                    counter++;
                  })
                  .catch((e) => {
                    reject('Failed to fetch durations. Reason:', e);
                  });
              }, delayTime);
            }),
        });
      });
  };
};
