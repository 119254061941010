import '@babel/polyfill';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'whatwg-fetch';
import { history, initializeGoogleTagManager, reduxStore } from './lib';
import './utils/immer-features';

import ErrorBoundary from './containers/ErrorBoundary';
import App from './routing/index';

const activateWhyDidYouUpdate = false;
if (activateWhyDidYouUpdate && process.env.NODE_ENV !== 'production') {
  const { whyDidYouUpdate } = require('why-did-you-update');
  whyDidYouUpdate(React);
}

initializeGoogleTagManager();

/* eslint-disable no-console, max-len */
console.log(`
   ______                                 _      __
  / ____/_______ _      ______ ___  ___  (_)____/ /____  _____
 / /   / ___/ _ \\ | /| / / __ \`__ \\/ _ \\/ / ___/ __/ _ \\/ ___/
/ /___/ /  /  __/ |/ |/ / / / / / /  __/ (__  ) /_/  __/ /
\\____/_/   \\___/|__/|__/_/ /_/ /_/\\___/_/____/\\__/\\___/_/

Welcome to Crewmeister. If you read this message and are interested in JavaScript development we would love to talk to you. Please contact us via dev@crewmeister.com
`);
/* eslint-enable no-console */

const renderComponentIfDomElementWithIdExists = (domElementId, reactComponent) => {
  if (window.document.getElementById(domElementId)) {
    ReactDOM.render(reactComponent, window.document.getElementById(domElementId));
  }
};

renderComponentIfDomElementWithIdExists(
  '__cm-app',
  <Provider store={reduxStore}>
    <CssBaseline />
    <Router history={history}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>
);
