import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadTeams = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_TEAMS',
        fnParams: { crewId: Number.parseInt(crewId, 10) },
        fn: (params) => api.getTeams(params),
      })
);

export const createTeam =
  ({ api, crewId, name }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'CREATE_TEAM',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        name,
      },
      fn: (params) => api.createTeam(params),
    });

export const updateTeam =
  ({ api, crewId, id, name }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_TEAM',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        id,
        name,
      },
      fn: (params) => api.updateTeam(params),
    });

export const deleteTeam =
  ({ api, crewId, id }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'DELETE_TEAM',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        id,
      },
      fn: (params) => api.deleteTeam(params),
    });
