import { connect } from 'react-redux';

import * as selectors from '../../selectors';
import { PERMISSIONS } from '../../constants';

import Organism from './organism';

const { findPermissionsByProps, selectByProps } = selectors;

const mapStateToProps = (state, props) => {
  return {
    timeCategories: selectByProps(props.timeCategories, {
      crewId: props.crewId,
      level: props.level,
      disabled: false,
    }),
    canSeeSecondTimeCategory: findPermissionsByProps(
      state,
      { crewId: props.crewId, name: PERMISSIONS.SECOND_TIME_CATEGORY },
      {},
      'allowed'
    ),
  };
};

const TimeCategorySelectionContainer = connect(mapStateToProps)(Organism);

export default TimeCategorySelectionContainer;
