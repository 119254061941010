import * as selectors from '../selectors';

import { isArray, isDate, isNumber, isObject } from './data-types';
import { formatIso8601Date } from './date';

const { findByProps } = selectors;

/**
 * isUserActive - Checks if user is active in the given date
 *
 * @param {array} members
 * @param {integer} userId
 * @param {date} date
 *
 * @return {boolean} returns true if user is active, false otherwise
 */
export const isUserActive = (members, userId, date) => {
  if (!isDate(date) || !isArray(members) || !isNumber(userId)) return false;

  const member = findByProps(members, { userId }, {});
  if (!isObject(member)) return false;

  const { disabledAt, firstWorkDayAt } = member;

  return (
    (!isDate(disabledAt) ||
      (formatIso8601Date(new Date(date)) !== formatIso8601Date(new Date(disabledAt)) &&
        new Date(date) < new Date(disabledAt))) &&
    (!isDate(firstWorkDayAt) ||
      formatIso8601Date(new Date(date)) === formatIso8601Date(new Date(firstWorkDayAt)) ||
      new Date(date) >= new Date(firstWorkDayAt))
  );
};
