import { factory, sequence, trait } from 'stahlwerk';

export const createWorkingTimeModel = factory({
  id: sequence(),
  crewId: sequence(),
  userId: sequence(),
  firstWeekDay: 2,
  validFrom: '2000-01-01',
  workingTimeModelDays: [
    {
      breakRule: null,
      roundingRule: null,
      day: sequence(),
      target: 'PT3600S',
    },
    {
      breakRule: null,
      roundingRule: null,
      day: sequence(),
      target: 'PT3600S',
    },
    {
      breakRule: null,
      roundingRule: null,
      day: sequence(),
      target: 'PT3600S',
    },
    {
      breakRule: null,
      roundingRule: null,
      day: sequence(),
      target: 'PT3600S',
    },
    {
      breakRule: null,
      roundingRule: null,
      day: sequence(),
      target: 'PT3600S',
    },
    {
      breakRule: null,
      roundingRule: null,
      day: sequence(),
      target: 'PT3600S',
    },
    {
      breakRule: null,
      roundingRule: null,
      day: sequence(),
      target: 'PT3600S',
    },
  ],
  noDays: trait({
    workingTimeModelDays: [],
  }),
});
