import PropTypes from 'prop-types';
import React from 'react';
import { collections, react } from '../../utils';
import { InputWithLabel, styles } from './text-with-label';

const { composeReverse } = collections;
const { withUIState, withRef, prepareProp } = react;

const ComponentWithLabel = (Component) => (props) =>
  (
    <InputWithLabel {...props}>
      <Component {...props} styles={styles} />
    </InputWithLabel>
  );

ComponentWithLabel.propTypes = {
  placeholder: PropTypes.string,
  placeholderInfo: PropTypes.string,
  hint: PropTypes.string,
  isExpanded: PropTypes.bool,
  isFocused: PropTypes.bool,
  errors: PropTypes.array,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export const withInputLabel = composeReverse(
  withUIState('valueWhenNoneGiven', 'onValueChange', ({ initialValue }) => initialValue),
  prepareProp('value', (props) => ('value' in props ? props.value : props.valueWhenNoneGiven)),
  withUIState('isFocused', 'onChangeFocus', false),
  withRef('inputRef', 'registerInputRef'),
  prepareProp('onFocus', ({ onChangeFocus, onFocus }) => (...args) => {
    if (onFocus) {
      onFocus(...args);
    }
    return onChangeFocus(true);
  }),
  prepareProp('onBlur', ({ onChangeFocus, onBlur }) => (...args) => {
    if (onBlur) {
      onBlur(...args);
    }
    return onChangeFocus(false);
  }),
  prepareProp(
    'isExpanded',
    ({ isExpanded, isFocused, value, isExpandedFn = () => true }) =>
      isExpanded || isFocused || (isExpandedFn({ value }) && value !== void 0 && value !== '')
  ),
  prepareProp('onChange', ({ onValueChange, onChange }) => (nextValue) => {
    onValueChange(nextValue);
    if (onChange) {
      onChange(nextValue);
    }
  }),
  ComponentWithLabel
);

export default withInputLabel;
export { styles } from './text-with-label';
