import { isArray } from './data-types';
import { isEmpty } from './object';

export const addSyncFlagToResult = (result, userId) => {
  const now = new Date().toISOString();
  if (isArray(result)) {
    return result.map((item) => ({ ...item, _sync: { synced: now, userId } }));
  }

  if (result === true) {
    return { _sync: { synced: now, userId } };
  }

  return !result || isEmpty(result) ? {} : { ...result, _sync: { synced: now, userId } };
};
