import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES } from '../../constants';

// http://jsfiddle.net/andsens/mLA7X/
const CircleProgress = ({ progress, value }) => {
  const transform = { transform: `rotate(${(180 / 100) * progress}deg)` };
  const transform2x = { transform: `rotate(${(180 / 100) * progress * 2}deg)` };
  return (
    <div className={css(styles.circle)}>
      <div>
        <div className={css(styles.mask)} style={transform}>
          <div className={css(styles.fill)} style={transform} />
        </div>
        <div className={css(styles.mask)}>
          <div className={css(styles.fill)} style={transform} />
          <div className={css(styles.fill)} style={transform2x} />
        </div>
      </div>
      <div className={css(styles.inset)}>
        <div className={css(styles.progress)}>{value}</div>
      </div>
    </div>
  );
};

CircleProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const CIRCLE_SIZE = 70;
const INSET_SIZE = 60;
const PROGRESS_SIZE = 40;
const CIRCLE_BACKGROUND = COLORS.BROWN_EXTRA_LIGHT;
const CIRCLE_COLOR = COLORS.ORANGE_MAIN;
const INSET_COLOR = COLORS.EGGSHELL_MAIN;

const styles = StyleSheet.create({
  circle: {
    width: `${CIRCLE_SIZE}px`,
    height: `${CIRCLE_SIZE}px`,
    backgroundColor: CIRCLE_BACKGROUND,

    borderRadius: '50%',
    position: 'relative',
  },
  mask: {
    width: `${CIRCLE_SIZE}px`,
    height: `${CIRCLE_SIZE}px`,
    clip: `rect(0px, ${CIRCLE_SIZE}px, ${CIRCLE_SIZE}px, ${CIRCLE_SIZE / 2}px)`,

    position: 'absolute',
    borderRadius: '50%',
    transition: 'transform 0.5s ease',
  },
  fill: {
    width: `${CIRCLE_SIZE}px`,
    height: `${CIRCLE_SIZE}px`,
    clip: `rect(0px, ${CIRCLE_SIZE / 2}px, ${CIRCLE_SIZE}px, 0px)`,
    backgroundColor: CIRCLE_COLOR,

    position: 'absolute',
    borderRadius: '50%',
    transition: 'transform 0.5s ease',
  },
  inset: {
    width: `${INSET_SIZE}px`,
    height: `${INSET_SIZE}px`,
    marginLeft: `${(CIRCLE_SIZE - INSET_SIZE) / 2}px`,
    marginTop: `${(CIRCLE_SIZE - INSET_SIZE) / 2}px`,
    backgroundColor: INSET_COLOR,

    position: 'absolute',
    borderRadius: '50%',
  },
  progress: {
    width: `${PROGRESS_SIZE}px`,
    height: `${PROGRESS_SIZE}px`,
    marginLeft: `-${PROGRESS_SIZE / 2}px`,
    marginTop: `-${PROGRESS_SIZE / 2}px`,
    lineHeight: `${PROGRESS_SIZE}px`,

    left: '50%',
    top: '50%',
    overflow: 'hidden',
    position: 'absolute',
    textAlign: 'center',
    ...FONT_STYLES.medium,
  },
});

export default CircleProgress;
