import { getItem, setItem } from './local-storage';

const removeItemFromStorage = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    return {};
  }
};

const readFromStorage = (key) => {
  try {
    return JSON.parse(getItem(key)) || undefined;
  } catch (e) {
    return;
  }
};

const writeToStorage = (key, value) => {
  try {
    setItem(key, JSON.stringify(value));
  } catch (e) {
    removeItemFromStorage(key);
  }
};

export const persistReducer = (storageKey, reducer) => {
  const KEY = `__CM_${storageKey}`;
  const cachedOfflineValue = readFromStorage(KEY);
  return (state = cachedOfflineValue, action) => {
    const stateAfter = reducer(state, action);
    writeToStorage(KEY, stateAfter);
    return stateAfter;
  };
};
