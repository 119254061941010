import React from 'react';

const findAllTranslationsIn = (toBeTranslated, map) =>
  Object.keys(map)
    .filter((english) => toBeTranslated === english)
    .map((english) => map[english]);
const addDynamicValues = (rawTranslation, dynamicValues, logMissingKeys) => {
  const replaceDynamicValues = () =>
    Object.keys(dynamicValues).reduce((translation, key) => {
      const value = dynamicValues[key];
      const keyInTranslation = `{{${key}}}`;
      return translation.replace(new RegExp(keyInTranslation, 'g'), value);
    }, rawTranslation);

  const warnAboutUnreplacedValues = (replaced = '') => {
    const keysMissed = replaced.match(/\{\{\w+\}\}/g);
    if (keysMissed && keysMissed.length) {
      const keys = keysMissed.map((key) => `${key}`).join(', ');
      const givenKeys = JSON.stringify(Object.keys(dynamicValues));
      const msg = `No value for "${keys}" when translating "${rawTranslation}". Keys given: ${givenKeys}`;
      logMissingKeys(msg);
    }
  };

  const replaced = replaceDynamicValues();
  warnAboutUnreplacedValues(replaced);
  return replaced;
};

const firstTranslation = (toBeTranslated, map) => findAllTranslationsIn(toBeTranslated, map)[0];

const tryToTranslate = (toBeTranslated, map, untranslatedFn) => {
  const hasTranslation = toBeTranslated in map;
  if (hasTranslation) {
    return firstTranslation(toBeTranslated, map);
  }
  if (toBeTranslated !== '') {
    untranslatedFn(`Missing translation for "${toBeTranslated}"`);
  }
  return toBeTranslated;
};

const _translate = (toBeTranslated, dynamicValues = {}, map, untranslatedFn, logMissingKeys) => {
  const translated = tryToTranslate(toBeTranslated, map, untranslatedFn);
  return addDynamicValues(translated, dynamicValues, logMissingKeys);
};

const buildTranslateFunction = (map, logMissingTranslation, logMissingKeys) => (s, values) =>
  _translate(s, values, map, logMissingTranslation, logMissingKeys);

export const buildDangerouslyTranslateNeverWithUserContent =
  (translate) =>
  (...args) => {
    const translated = translate(...args);
    return <span dangerouslySetInnerHTML={{ __html: translated }} />;
  };

export default buildTranslateFunction;
