import { fetchPost as nativeFetchPost, fetchGet as nativeFetchGet } from '../fetch';

import { buildUrl } from '../../config';
import { removeEmptyObjectValues } from '../../is-empty';
import { stringifyQueryParams } from '../../_external-deps/url';

const ROOT_PREFIX = '/api/v2';
const CREW_URL_PREFIX = `${ROOT_PREFIX}/crew/\${crew_id}`;
const TIME_TRACKING_URL_PREFIX = `${CREW_URL_PREFIX}/time-tracking`;

const REQUEST_PASSWORD_RESET = `${ROOT_PREFIX}/request-password-reset`;
const SET_PASSWORD_BY_TOKEN = `${ROOT_PREFIX}/set-password-by-token`;

const CREW_CALENDAR = `${CREW_URL_PREFIX}/calendar`;

const UPDATE_TIMESTAMP = `${TIME_TRACKING_URL_PREFIX}/update-stamp`;

const ABSENCE_MANAGEMENT = `${CREW_URL_PREFIX}/absence-management`;
const UPDATE_ABSENCE_ENTITLEMENTS = `${ABSENCE_MANAGEMENT}/update-entitlement`;

const SERVER_TIMESTAMP = `${ROOT_PREFIX}/server-time`;

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const buildJsonHeaders = (headers) => ({ ...jsonHeaders, ...headers });
const buildJsonBody = (body) => JSON.stringify(body);

const fetchGet = (urlPath, ...rest) => nativeFetchGet(`${buildUrl(urlPath)}`, ...rest);

const fetchJsonPost = (url, { headers, body }) =>
  nativeFetchPost(buildUrl(url), {
    headers: buildJsonHeaders(headers),
    body: buildJsonBody(body),
  });

const fetchJsonGet = (url, { headers, body }) =>
  nativeFetchGet(buildUrl(url), {
    headers: buildJsonHeaders(headers),
    body: buildJsonBody(body),
  });

const fetch = {
  requestPasswordReset({ headers, userIdentifier }) {
    const body = { userIdentifier };
    return fetchJsonPost(REQUEST_PASSWORD_RESET, { headers, body });
  },
  setPasswordByToken({ headers, password, token }) {
    const body = { password, token };
    return fetchJsonPost(SET_PASSWORD_BY_TOKEN, { headers, body });
  },
  crewCalendar({ headers, crewId, from, to }) {
    const params = removeEmptyObjectValues({ from, to });
    const queryParams = stringifyQueryParams(params);
    const url = CREW_CALENDAR.replace('${crew_id}', crewId) + queryParams;
    return fetchJsonGet(url, { headers });
  },
  updateTimestamp({ headers, payload = {} }) {
    const url = UPDATE_TIMESTAMP.replace('${crew_id}', payload.crewId);
    return fetchJsonPost(url, { headers, body: payload });
  },
  serverTimestamp({ headers }) {
    return fetchGet(SERVER_TIMESTAMP, { headers });
  },
  setAbsenceEntitlement({ headers, crewId, payload = {} }) {
    const url = UPDATE_ABSENCE_ENTITLEMENTS.replace('${crew_id}', crewId);
    return fetchJsonPost(url, { headers, body: payload });
  },
};

export default fetch;
