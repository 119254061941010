const TERMINAL_VIEW_MODES = {
  PIN_CODE: 'pin_code',
  THREE_COLUMN: 'drag_and_drop',
  BASIC: 'basic',
};

const TERMINAL_AUTHENTICATION_MODES = {
  PIN_CODE: 'pin_code',
  PASSWORD: 'password',
  NONE: 'none',
};

const TERMINAL_DROPDOWN_OPTIONS = [
  {
    value: 'pinCodeTerminal',
    viewMode: TERMINAL_VIEW_MODES.PIN_CODE,
    authenticationMode: TERMINAL_AUTHENTICATION_MODES.PIN_CODE,
    disabled: false,
  },
  {
    value: 'threeColumnTerminalWithPassword',
    viewMode: TERMINAL_VIEW_MODES.THREE_COLUMN,
    authenticationMode: TERMINAL_AUTHENTICATION_MODES.PASSWORD,
    disabled: false,
  },
  {
    value: 'threeColumnTerminalWithPinCode',
    viewMode: TERMINAL_VIEW_MODES.THREE_COLUMN,
    authenticationMode: TERMINAL_AUTHENTICATION_MODES.PIN_CODE,
    disabled: false,
  },
  {
    value: 'threeColumnTerminalWithNoAuthentication',
    viewMode: TERMINAL_VIEW_MODES.THREE_COLUMN,
    authenticationMode: TERMINAL_AUTHENTICATION_MODES.NONE,
    disabled: false,
  },
  {
    value: 'basicTerminal',
    viewMode: TERMINAL_VIEW_MODES.BASIC,
    authenticationMode: TERMINAL_AUTHENTICATION_MODES.PASSWORD,
    disabled: false,
  },
];

const getTerminalValue = (viewMode, authenticationMode) => {
  const terminal = TERMINAL_DROPDOWN_OPTIONS.find(
    (terminalSelected) =>
      terminalSelected.viewMode === viewMode && terminalSelected.authenticationMode === authenticationMode
  );
  return terminal ? terminal.value : false;
};

const getModes = (value) => {
  const terminal = TERMINAL_DROPDOWN_OPTIONS.find((terminalSelected) => terminalSelected.value === value);
  return terminal ? { viewMode: terminal.viewMode, authenticationMode: terminal.authenticationMode } : {};
};

export { TERMINAL_DROPDOWN_OPTIONS, TERMINAL_VIEW_MODES, TERMINAL_AUTHENTICATION_MODES, getTerminalValue, getModes };
