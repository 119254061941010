const contains = (ar, val) => ar.indexOf(val) === -1;

export default (params = {}, requiredParams, optionalParams, url, logError) => {
  requiredParams
    .filter((required) => contains(Object.keys(params), required))
    .forEach((missing) => {
      logError(`Required param "${missing}" for route "${url}" missing.`);
    });

  Object.keys(params)
    .filter((param) => contains(requiredParams, param) && contains(optionalParams, param))
    .forEach((overload) => {
      logError(`Unknown param "${overload}" for route "${url}". Param is neither required nor optional.`);
    });
};
