import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadGeofences = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_GEOFENCES',
        fnParams: { crewId },
        fn: (params) => api.getGeofences(params),
      })
);

export const createOrUpdateGeofence =
  ({ api, crewId, groupId, longitude, latitude, radius, id }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'CREATE_OR_UPDATE_GEOFENCE',
      fnParams: { crewId: Number.parseInt(crewId, 10), groupId, longitude, latitude, radius, id },
      fn: (params) => (id ? api.updateGeofence(params) : api.createGeofence(params)),
    });

export const deleteGeofence =
  ({ api, crewId, id }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'DELETE_GEOFENCE',
      fnParams: { crewId, id },
      fn: (params) => api.deleteGeofence(params),
    });
