import produce from 'immer';
import { createSelector } from 'reselect';

import { selectCurrentCrewId } from '../crews-selectors';

import { V3ShiftOfferReply } from '../../types';
import { selectCurrentUser } from '../users-selectors';

export const v3SelectShiftOfferReplies = (state: any): V3ShiftOfferReply[] =>
  state.boV3.shiftOfferReplies.shiftOfferReplies || produce([], () => {});

export const v3SelectShiftOfferRepliesForCurrentCrew = createSelector(
  v3SelectShiftOfferReplies,
  selectCurrentCrewId,
  (shiftOfferReplies, crewId) => shiftOfferReplies.filter((shiftOfferReply) => shiftOfferReply.crewId === crewId)
);

export const v3SelectShiftOfferRepliesForCurrentCrewAndUser = createSelector(
  v3SelectShiftOfferReplies,
  selectCurrentCrewId,
  selectCurrentUser,
  (shiftOfferReplies, crewId, user) =>
    shiftOfferReplies.filter(
      (shiftOfferReply) => shiftOfferReply.crewId === crewId && shiftOfferReply.userId === user.id
    )
);
