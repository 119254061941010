import { createSelector } from 'reselect';
import { createPropsSelector } from './utils';
import { selectCurrentCrewId } from './crews-selectors';

export const selectGroupsUnsorted = (state) => state.bo.groups.groups;

export const selectGroupIdFromProps = (_state, props) => props.groupId;

export const selectGroups = createSelector(selectGroupsUnsorted, (groups) =>
  groups.concat().sort((a, b) => {
    if (a.id === null) {
      return -1;
    }
    if (b.id === null) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  })
);

export const selectGroupsForCurrentCrew = createSelector(selectGroups, selectCurrentCrewId, (groups, crewId) =>
  groups.filter((group) => group.crewId === crewId)
);

export const selectEnabledGroupsInCurrentCrew = createSelector(selectGroupsForCurrentCrew, (groups) =>
  groups.filter((group) => !group.disabled)
);

export const selectGroupsByProps = createPropsSelector(selectGroupsForCurrentCrew);
