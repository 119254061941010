import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { collections } from '../../utils';
import * as actions from '../../action-creators';

import { api, apiV3, callFunctionWhenPropsChange, wrapWithNotification } from '../../lib';
import t from './translate';

import Component from './organism';
import { selectCurrentTerminalCrewMember } from '../../selectors/terminal/crew-members-selectors';

const {
  loadContext,
  loadTerminalCrewMembers,
  makeSignInIdentifierFromUserId,
  requestPasswordReset,
  signInUser,
  v3SignInUser,
} = actions;
const { composeReverse } = collections;

const mapStateToProps = (state, props) => {
  return {
    member: selectCurrentTerminalCrewMember(state, props),
    onClose: () => props.history.push('/'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  functionToCall: ({ crewId, disabledAt }) => dispatch(loadTerminalCrewMembers({ api, crewId, disabledAt })),
  onSubmitWithoutIdentifier:
    (member, setPreventClosing) =>
    ({ password }) =>
      wrapWithNotification(
        async () => {
          const identifier = makeSignInIdentifierFromUserId(member?.userId || ownProps.userId);
          setPreventClosing(true);
          await dispatch(signInUser({ api, identifier, password }));
          await dispatch(v3SignInUser({ apiV3, username: identifier, password }));
          await dispatch(loadContext.unmemoized({ api }));
          if (!ownProps.withoutRedirection) ownProps.history.push('/');
          if (ownProps.success) ownProps.success();
        },
        {
          dispatch,
          succes: () => setPreventClosing(false),
          error: () => {
            setPreventClosing(false);
            return t('passwordFailed');
          },
        }
      ),

  onSubmitResetPassword:
    (member) =>
    ({}) =>
      wrapWithNotification(
        async () => {
          const userIdentifier = makeSignInIdentifierFromUserId(member.userId);
          await dispatch(requestPasswordReset({ api, userIdentifier }));
        },
        { dispatch, success: t('onPasswordSubmitSuccess'), error: t('onPasswordSubmitError') }
      ),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...ownProps,
  ...propsFromState,
  onSubmit: propsFromDispatch.onSubmitWithoutIdentifier(propsFromState.member, ownProps.setPreventClosing),
  onClickReset: propsFromDispatch.onSubmitResetPassword(propsFromState.member),
  crewId: ownProps.crewId,
  setPreventClosing: ownProps.setPreventClosing,
});

const TerminalSignInContainer = composeReverse(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId'] })
)(Component);

TerminalSignInContainer.displayName = 'TerminalSignInContainer';

export default TerminalSignInContainer;
