import { addDays } from 'date-fns';
import { LocaleType, ShiftOffset, SHIFT_TYPE, V3Shift } from '../types';
import { array as arrayUtils, date as dateUtils } from '../utils';

const { moveElement } = arrayUtils;
const { formatWeekDays, getWeekDays } = dateUtils;

// getWeekDays returns array from "Sunday" to "Monday"
// moveElement will move the "Sunday" to the last position of the array
const FIRST_WEEK_INDEX = 0;
const LAST_WEEK_INDEX = 6;
const WEEK_DAYS = moveElement(getWeekDays(LocaleType.DE), FIRST_WEEK_INDEX, LAST_WEEK_INDEX);

export const getDateOffset = (dateString: string, mondayDate: Date): ShiftOffset => {
  const SUNDAY_BEFORE_CURRENT_MONDAY = -1;
  const MONDAY_FROM_NEXT_WEEK = 7;

  const date = new Date(dateString);
  const weekDayIndex = WEEK_DAYS.indexOf(formatWeekDays(new Date(date), LocaleType.DE)) as ShiftOffset;

  if (weekDayIndex === LAST_WEEK_INDEX && date < mondayDate) {
    return SUNDAY_BEFORE_CURRENT_MONDAY;
  } else if (weekDayIndex === FIRST_WEEK_INDEX && date >= addDays(mondayDate, 7)) {
    return MONDAY_FROM_NEXT_WEEK;
  }
  return weekDayIndex;
};

export const getValidUntilDateOffset = (dateString: string, mondayDate: Date): ShiftOffset => {
  const date = new Date(dateString);
  const timeDifference = date.getTime() - mondayDate.getTime();
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return dayDifference as ShiftOffset;
};

export const isAnAssignedShift = (shift: V3Shift) => !!shift.userId;

export const isAnOpenShift = (shift: V3Shift) => !isAShiftOffer(shift) && !isAnAssignedShift(shift);

export const isAnUnpublishedShift = (shift: V3Shift) => [
  SHIFT_TYPE.UNPUBLISHED_CREATE,
  SHIFT_TYPE.UNPUBLISHED_DELETE,
  SHIFT_TYPE.UNPUBLISHED_UPDATE,
].includes(shift.type);

export const isAShiftOffer = (shift: V3Shift) => shift.shiftOffer;

