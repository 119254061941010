import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

const enhanceGroupAttributes = (group) => ({
  ...group,
  disabled: group.disabledAt !== null,
});

export const loadGroups = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_GROUPS',
        fnParams: { crewId: Number.parseInt(crewId, 10) },
        fn: (params) => api.getGroups(params).then((groups) => groups.map(enhanceGroupAttributes)),
      })
);

export const enableGroup =
  ({ api, crewId, groupId }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'ENABLE_GROUP',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        groupId,
      },
      fn: (params) => api.enableGroup(params).then(enhanceGroupAttributes),
    });

export const disableGroup =
  ({ api, crewId, groupId }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'DISABLE_GROUP',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        groupId,
      },
      fn: (params) => api.disableGroup(params).then(enhanceGroupAttributes),
    });

export const addGroup =
  ({ api, crewId, name }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'ADD_GROUP',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        name,
      },
      fn: (params) => api.addGroup(params).then(enhanceGroupAttributes),
    });

export const updateGroup =
  ({ api, crewId, groupId, name }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_GROUP',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        groupId,
        name,
      },
      fn: (params) => api.updateGroup(params).then(enhanceGroupAttributes),
    });
