import produce from 'immer';

const initialState = produce({ excludedGuids: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const shiftsToPublishReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_PUBLISH_SHIFT':
        draft.excludedGuids = draft.excludedGuids.filter((guid) => guid !== action.result.guid);
        if (!action.result.shouldPublish) {
          draft.excludedGuids.push(action.result.guid);
        }
        break;
      case 'TRANSFORM_SHIFTS_DRAFT_SUCCESS':
        action.values.map((shift) => {
          if (shift[0]) {
            draft.excludedGuids = draft.excludedGuids.filter((guid) => guid !== shift[0].guid);
          }
        });
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the original object
    }
  });
