import cookies from 'js-cookie';

// Pass in the process.env[name] as envify as it cannot be processed otherwise
const getEnv = (envValue, name) => {
  try {
    return cookies.get(`ENV_${name}`) || envValue;
  } catch (e) {
    return envValue;
  }
};

const domain = process.env.CREWMEISTER_API_DOMAIN || '';
const isInternalMode = domain.includes('localhost') || domain.includes('stage');
const isProduction = getEnv(process.env.NODE_ENV, 'NODE_ENV') === 'production';
const isStage = getEnv(process.env.NODE_ENV, 'NODE_ENV') === 'stage';
const isDevelopment = getEnv(process.env.NODE_ENV, 'NODE_ENV') === 'development';
const isLocalDevelopment = getEnv(process.env.ENVIRONMENT, 'ENVIRONMENT') === 'development';
const isUserInMobileDevice = (window) =>
  window &&
  window.navigator &&
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

export { getEnv, isInternalMode, isLocalDevelopment, isProduction, isStage, isDevelopment, isUserInMobileDevice };
