/* eslint-disable no-console */
import { CrewmeisterApiV3, RESOURCES } from '@crewmeister/shared';
import { ACTION_NAMES } from '../../constants';
import { dispatchAsyncFnStatusActions } from '../../lib';
import { AsynchronousDispatchFunction, POST_PROCESS_FUNCTION_NAME } from '../../types';

type V3SignInUserProps = {
  apiV3: CrewmeisterApiV3;
  username: string;
  password: string;
};

type V3SignInUserWithPinProps = {
  apiV3: CrewmeisterApiV3;
  pin: string;
};

type V3SignOutProps = {
  apiV3: CrewmeisterApiV3;
};

type V3SignInCrewAndSignOutUserProps = {
  apiV3: CrewmeisterApiV3;
  crewId: string;
};

export const v3SignInUser =
  ({ apiV3, username, password }: V3SignInUserProps): AsynchronousDispatchFunction =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: ACTION_NAMES.V3_SIGN_IN_USER,
      fnParams: { username, password },
      fn: async (params) =>
        (await apiV3.getService(RESOURCES.AUTHENTICATION_USER, POST_PROCESS_FUNCTION_NAME.SET_USER_TOKEN)).createOne(
          params
        ),
    }).catch((e) => {
      console.warn('sign-in failed. Reason:', e);
    });

export const v3SignInUserWithPin =
  ({ apiV3, pin }: V3SignInUserWithPinProps): AsynchronousDispatchFunction =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: ACTION_NAMES.V3_SIGN_IN_USER_WITH_PIN,
      fnParams: { pin },
      fn: async (params) =>
        (await apiV3.getService(RESOURCES.AUTHENTICATION_PIN, POST_PROCESS_FUNCTION_NAME.SET_USER_TOKEN)).createOne(
          params
        ),
    }).catch((e) => {
      console.warn('sign-in with pin failed. Reason:', e);
    });

export const v3SignOutUser =
  ({ apiV3 }: V3SignOutProps): AsynchronousDispatchFunction =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: ACTION_NAMES.V3_SIGN_OUT_USER,
      fn: async () => apiV3.removeToken(POST_PROCESS_FUNCTION_NAME.REMOVE_USER_TOKEN),
    });

export const v3SignInCrewAndSignOutUser =
  ({ apiV3, crewId }: V3SignInCrewAndSignOutUserProps): AsynchronousDispatchFunction =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: ACTION_NAMES.V3_SIGN_IN_CREW_AND_SIGN_OUT_USER,
      fnParams: { crewId },
      fn: async (params) => {
        await (
          await apiV3.getService(RESOURCES.AUTHENTICATION_CREW, POST_PROCESS_FUNCTION_NAME.SET_CREW_TOKEN)
        ).createOne(params);
        apiV3.removeToken(POST_PROCESS_FUNCTION_NAME.REMOVE_USER_TOKEN);
      },
    }).catch((e) => {
      console.warn('V3 sign-in crew has failed. Reason:', e);
    });

export const v3SignOutCrew =
  ({ apiV3 }: V3SignOutProps): AsynchronousDispatchFunction =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: ACTION_NAMES.V3_SIGN_OUT_CREW,
      fn: () => Promise.resolve().then(() => apiV3.removeToken(POST_PROCESS_FUNCTION_NAME.REMOVE_CREW_TOKEN)),
    });
