import PropTypes from 'prop-types';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { COLORS, FONT_STYLES } from '../../constants';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import Badge from './badge';
import Image from './image';

const MemberBadge = ({ badgeClassName, children, disable, id, maxWidth, member, onClick, withDropdownIcon }) => (
  <Badge onClick={onClick} maxWidth={maxWidth} className={badgeClassName} id={id}>
    <Choose>
      <When condition={member.avatarUrl}>
        <Image className={css(styles.image, disable && styles.imageDisable)} src={member.avatarUrl} alt={member.name} />
      </When>
      <Otherwise>
        <span className={css(styles.noImage)} />
      </Otherwise>
    </Choose>

    <div className={css(styles.textWrapper)}>
      <div className={css(styles.nameAndIcon)}>
        <div className={css(styles.name, disable && styles.textDisabled)}>
          <If condition={member}>
            <span data-e2e-test={`member-name-${member.name}`}>{member.name}</span>
          </If>
        </div>
        {withDropdownIcon && <ExpandMoreIcon />}
      </div>
      <div className={css(styles.info, disable && styles.textDisabled)}>{children}</div>
    </div>
  </Badge>
);

MemberBadge.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  maxWidth: PropTypes.bool,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: COLORS.WHITE,
    boxShadow: `1px 1px 4px 0 ${COLORS.GREY_EXTRA_LIGHT}`,
    padding: '10px  20px',
  },
  textWrapper: {
    overflow: 'hidden',
    width: 'auto',
  },
  image: {
    flexShrink: 1,
    display: 'block',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    marginRight: '10px',
  },
  name: {
    ...FONT_STYLES.medium,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  info: {
    ...FONT_STYLES.small,
  },
  clickable: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'all 200ms',
    ':hover': {
      boxShadow: `0px 0px 2px 0 ${COLORS.GREY_EXTRA_LIGHT}`,
      transform: 'translateY(2px)',
    },
  },
  textDisabled: {
    color: 'rgba(222, 222, 222, 1)',
  },
  imageDisable: {
    opacity: '0.3',
    filter: 'alpha(opacity=50)',
  },
  nameAndIcon: {
    display: 'flex',
  },
  noImage: {
    height: '50px',
  },
});

export default MemberBadge;
