import { createSelector } from 'reselect';
import produce from 'immer';
import { selectUserIdFromProps } from './users-selectors';

const selectNewsCountsFromState = (state) => state.bo.news.counts || produce([], () => {});
const selectNewsFromState = (state) => state.bo.news.news || produce([], () => {});

export const selectNewsCounts = createSelector(selectNewsCountsFromState, (newsCounts) => newsCounts);

export const selectUserNewsCount = createSelector(selectNewsCounts, selectUserIdFromProps, (newsCounts, userId) =>
  newsCounts.find((count) => count.userId === userId)
);

export const selectNews = createSelector(selectNewsFromState, (news) => news.concat());

export const selectUserNews = createSelector(selectNews, selectUserIdFromProps, (news, userId) =>
  news.filter((newsItem) => newsItem.receiverId === userId)
);

export const selectUnreadUserNews = createSelector(selectUserNews, (news) =>
  news.filter((newsItem) => !newsItem.readAt)
);
