/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
/* eslint-disable max-len */
import { buildDangerouslyTranslateNeverWithUserContent, buildScopedTranslationFunction } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    break: 'Pause',
    cancel: 'Abbrechen',
    clockedIn: 'Eingestempelt',
    clockedOut: 'Ausgestempelt',
    dragHereToBreak: 'Ziehe dein Profil hierhin um in Pause zu gehen.',
    dragHereToClockIn: 'Ziehe dein Profil hierhin um sofort einzustempeln.',
    dragHereToClockInWithTimeCategory: 'Hierhin ziehen um vor dem Einstempeln eine Zeitkategorie auszuwählen.',
    dragHereToClockOut: 'Ziehe dein Profil hierhin um auszustempeln.',
    enter4DigitPin: 'Bitte gebe noch deine 4 stellige Pin ein um dich zu authentifizieren:',
    exit: 'Den Terminal-Modus kann nur der Administrator einer Crew wieder verlassen. Dafür klickt der Administrator auf seinen Namen und gibt sein Passwort ein. Über den Link „Zur vollen Anwendung“ rechts unten im auftauchenden Fenster, kann der Administrator den Crewmeister Terminal verlassen und zurück zu seinem vollen Crewmeister Profil kehren.',
    help: 'Sie befinden sich gerade im Crewmeister Terminal zur Zeiterfassung. Über diesen können mehrere Mitarbeiter schnell über ein einziges Gerät ihre Zeiten stempeln. Dafür klickt man einfach auf den eigenen Namen, gibt sein Passwort ein und stempelt wie gewohnt.',
    stampError: 'Der Zeitstempel konnte nicht erfasst werden',
    startBreakSuccess: 'Sie sind in der Pause',
    startWorkSuccess: 'Erfolgreich eingestempelt',
    stopWorkSuccess: 'Erfolgreich ausgestempelt',
    usePassword: 'Passwort benutzen',
  },
  en: {
    break: 'Break',
    cancel: 'Cancel',
    clockedIn: 'Clocked in',
    clockedOut: 'Clocked out',
    dragHereToBreak: 'Drag your profile here to go on a break.',
    dragHereToClockIn: 'Drag your profile here to clock in now.',
    dragHereToClockInWithTimeCategory: 'Drag here to select a time category before clocking in.',
    dragHereToClockOut: 'Drag your profile here to clock out.',
    enter4DigitPin: 'Please enter your 4 digit pin to authenticate:',
    exit: 'To exit the terminal on this device, you need to be an admin. To do so, the admin needs to click on his name and login by entering his password. Now, clicking on the link on the bottom right corner „Open full application“ closes the terminal and opens the full account of the admin.',
    help: 'You are right now in the crewmeister terminal for time tracking. With this terminal, multiple employees can track their times comfortably with only one device. Just click on your name, enter your password and track your time.',
    stampError: "Couldn't add time stamp",
    startBreakSuccess: "You're on break",
    startWorkSuccess: "You're clocked in",
    stopWorkSuccess: "You're clocked out",
    usePassword: 'Use password',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
