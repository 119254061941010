import { factory, sequence } from 'stahlwerk';
import { ROOM_TYPES } from '../constants';

export const createChatRoom = factory({
  id: sequence().toString(),
  name: sequence((id) => `Room ${id}`),
  createdBy: sequence(),
  updatedBy: sequence(),
  crewId: sequence(),
  createdAt: new Date(),
  type: ROOM_TYPES.SINGLE,
});
