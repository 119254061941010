import { memoize } from 'redux-memoize';
import { delay } from 'promise-frites';
import { ASYNC_RESOURCE_REQ_DELAY, NUMBER_OF_RETRIES } from '@crewmeister/shared/src/constants';
import { dispatchAsyncFnStatusActions } from '../lib';
import { reloadDurationBalances, reloadDurationBalancesForDays } from './ui-actions';

const ATC_PROCESSING_DELAY_SECONDS = 2; // TODO: move to constants

export const loadBookings = memoize(
  {},
  ({ api, crewId, from, to, userId, timeAccount, actionName = 'LOAD_BOOKINGS' }) =>
    (dispatch) => {
      const fnParams = { crewId, from, to }; // mandatory params
      if (userId) fnParams.userId = userId;
      if (timeAccount) fnParams.timeAccount = timeAccount;
      return Promise.resolve()
        .then(() => delay(ATC_PROCESSING_DELAY_SECONDS))
        .then(() =>
          dispatchAsyncFnStatusActions({
            dispatch,
            actionName,
            fnParams,
            fn: (params) => {
              return api.bookings(params);
            },
          })
        );
    }
);

export const createBooking = memoize(
  { ttl: 0 },
  ({ api, crewId, userId, timeAccount, duration, date, isNew, additionalData }) =>
    (dispatch) =>
      Promise.resolve()
        .then(() => delay(ATC_PROCESSING_DELAY_SECONDS))
        .then(() => delay(ATC_PROCESSING_DELAY_SECONDS))
        .then(() =>
          dispatchAsyncFnStatusActions({
            dispatch,
            actionName: 'CREATE_BOOKING',
            fnParams: { date, crewId, userId, timeAccount, duration, additionalData },
            fn: (params) => (isNew ? api.createBooking(params) : api.updateBooking(params)),
            afterSuccessFn: () => {
              dispatch(reloadDurationBalances());
              dispatch(reloadDurationBalancesForDays(userId, date));
            },
          })
        )
);

export const loadBookingsWithDelay =
  ({
    api,
    crewId,
    from,
    to,
    userId,
    timeAccount,
    actionName = 'LOAD_BOOKINGS_WITH_DELAY',
    delayTime = ASYNC_RESOURCE_REQ_DELAY,
    retries = NUMBER_OF_RETRIES,
    bookingsForUserCrewInRange = [],
  }) =>
  (dispatch) => {
    const fnParams = { crewId, from, to }; // mandatory params
    if (userId) fnParams.userId = userId;
    if (timeAccount) fnParams.timeAccount = timeAccount;
    return Promise.resolve()
      .then(() => delay(ATC_PROCESSING_DELAY_SECONDS))
      .then(() =>
        dispatchAsyncFnStatusActions({
          dispatch,
          actionName,
          fnParams,
          fn: (params) =>
            new Promise((resolve, reject) => {
              let counter = 0;
              let oldBookings = bookingsForUserCrewInRange;
              const delayInterval = setInterval(() => {
                api
                  .bookings(params)
                  .then((response) => {
                    if (counter > retries || JSON.stringify(oldBookings) !== JSON.stringify(response)) {
                      clearInterval(delayInterval);
                      resolve(response);
                    }
                    oldBookings = response;
                    counter++;
                  })
                  .catch((e) => {
                    reject(e);
                  });
              }, delayTime);
            }),
        })
      );
  };
