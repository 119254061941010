import { isAfter, isSameDay } from 'date-fns';
import { ABSENCE_MANAGEMENT_THEMES, DAY_KINDS } from '../constants';
import { flatten } from './array';
import { formatDate, isWithinRange } from './date';

/**
 * absencesToAbsenceDays - Calculates for each day of each absence if it is a half-day
 * or a full-day of absence.
 *
 * @param {array} absences array of objects with properties startDate, endDate, startDayPart and endDayPart
 * @param {array} dayKindCalendar each element represents a day of the calendar
 *
 * @return {array} each element represents a working day with the following two properties:
 *    date
 *    fraction: if 0.5, means the absence represents a half-day period for the given date and
 *              if 1, means the absence represents a full-day period for the given date
 */
export const absencesToAbsenceDays = (absences, dayKindCalendar) =>
  flatten(
    absences.map((absence) =>
      absenceToAbsenceDays(absence, dayKindCalendar).map((absenceDay) => ({
        ...absenceDay,
        typeId: absence.typeId,
        userId: absence.userId,
        crewId: absence.crewId,
      }))
    )
  ).filter((absenceDay) => !!absenceDay.date);

/**
 * absenceToAbsenceDays - Calculates for each day of the absence if it is a half-day
 *                        or a full-day of absence.
 *
 * @param {object} absence with properties startDate, endDate, startDayPart and endDayPart
 * @param {array} dayKindCalendar each element represents a day of the calendar
 *
 * @return {array} each element represents a working day with the following two properties:
 *    date
 *    fraction: if 0.5, means the absence represents a half-day period for the given date and
 *              if 1, means the absence represents a full-day period for the given date
 */
export const absenceToAbsenceDays = (absence, dayKindCalendar) => {
  const startDate = new Date(absence.startDate);
  const endDate = new Date(absence.endDate);

  return dayKindCalendar
    .filter(
      (dayKind) => dayKind.dayKindId === DAY_KINDS.WORK && isWithinRange(new Date(dayKind.date), startDate, endDate)
    )
    .map((workDay) => {
      const fraction = getFractionFromWorkDay({ workDay, absence, date: new Date(workDay.date) });
      return {
        date: workDay.date,
        fraction: fraction === 0.5 ? 2 : fraction,
      };
    });
};

/**
 * absenceToDayValues - Generates the theme color for an absence in a given day and sends a boolean telling
 *                      if it should look stripped on the calendar view (when the absence is still pending for
 *                      approval)
 *
 * @param {object} absence with properties startDate, endDate, startDayPart, endDayPart and typeId
 * @param {date} date that is within absence.startDate and absence.endDate or equals one of them
 *
 * @return {object} with 4 properties:
 *    fraction: if it equals 2, means that the absence for the given date happens on a half-day period
 *              if it equals 1, means that the absence for the given date happens on a full-day period
 *              if it equals 0, means that the absence happens on a holiday
 *    offset: in case fraction equals 2, the offset is used to determine if the absence happens just on
 *            the morning (offset = 0) or on the afternoon (offset = 2)
 *    color: RGBA color property
 *    stripped: if true, the absence for the given day on the calendar will look striped
 */
export const absenceToDayValues = (absence, date) => {
  const fractions = toFractions(absence, new Date(date));
  const theme = themeForAbsenceTypeId(absence.typeId);

  return {
    ...fractions,
    color: isAdmitted(absence) ? theme.regular : theme.extraLight,
    striped: !isAdmitted(absence),
  };
};

/**
 * adjustDateRangeAndDayParts - Adjusts the dates and fractions according to the newest
 *                              user input to avoid requesting absence with invalid data.
 *
 * @param {date} startDate of an absence
 * @param {date} endDate of an absence
 * @param {string} startDayPart of an absence
 * @param {string} endDayPart of an absence
 * @param {date} newStartDate recently informed by the user as a replacement of startDate (can be null)
 * @param {date} newEndDate recently informed by the user as a replacement of endDate (can be null)
 *
 * @return {object} with the properties below with no inconsistency (example: if the user sets endDate
 *                  a date before the startDate, this function will adjust the startDate to be the same as endDate)
 *    startDate
 *    endDate
 *    startDayPart
 *    endDayPart
 */
export const adjustDateRangeAndDayParts = ({
  startDate,
  endDate,
  startDayPart,
  endDayPart,
  newStartDate,
  newEndDate,
}) => {
  const adjustedDates = adjustDates({ startDate, endDate, newStartDate, newEndDate });
  return {
    ...adjustedDates,
    ...adjustDayParts({
      startDayPart,
      endDayPart,
      startDate: adjustedDates.startDate,
      endDate: adjustedDates.endDate,
    }),
  };
};

/**
 * adjustDateRangeAndFractions - Adjusts the dates and day parts according to the newest
 *                               user input to avoid requesting absence with invalid data.
 *
 * @param {date} startDate of an absence
 * @param {date} endDate of an absence
 * @param {number} startFraction of an absence
 * @param {number} endFraction of an absence
 * @param {date} newStartDate recently informed by the user as a replacement of startDate (can be null)
 * @param {date} newEndDate recently informed by the user as a replacement of endDate (can be null)
 *
 * @return {object} with the properties below with no inconsistency (example: if the user sets endDate
 *                  a date before the startDate, this function will adjust the startDate to be the same as endDate)
 *    startDate
 *    endDate
 *    startFraction
 *    endFraction
 */
export const adjustDateRangeAndFractions = ({
  startDate,
  endDate,
  startFraction,
  endFraction,
  newStartDate,
  newEndDate,
}) => {
  const adjustedDates = adjustDates({ startDate, endDate, newStartDate, newEndDate });
  const adjustedFractions = adjustFractions({
    startDate,
    endDate,
    startFraction,
    endFraction,
    newStartDate: adjustedDates.startDate,
    newEndDate: adjustedDates.endDate,
  });

  return { ...adjustedDates, ...adjustedFractions };
};

/**
 * adjustDates - Adjusts the dates according to the newest user input to avoid requesting absence with
 *               invalid data.
 *
 * @param {date} startDate of an absence
 * @param {date} endDate of an absence
 * @param {date} newStartDate recently informed by the user as a replacement of startDate (can be null)
 * @param {date} newEndDate recently informed by the user as a replacement of endDate (can be null)
 *
 * @return {object} with startDate and endDate properties with no inconsistency (example: if the user sets endDate
 *                  a date before the startDate, this function will adjust the startDate to be the same as endDate)
 */
const adjustDates = ({ startDate, endDate, newStartDate, newEndDate }) => {
  let newDates = {};
  if (newStartDate) {
    newDates = isAfter(new Date(newStartDate), new Date(endDate))
      ? { newStartDate, newEndDate: newStartDate }
      : { newStartDate, newEndDate: endDate || newStartDate };
  } else {
    newDates = isAfter(new Date(startDate), new Date(newEndDate))
      ? { newStartDate: newEndDate, newEndDate }
      : { newStartDate: startDate || newEndDate, newEndDate };
  }
  return { startDate: newDates.newStartDate, endDate: newDates.newEndDate };
};

/**
 * adjustDayParts - Adjusts the day parts according to the newest
 *                  user input to avoid requesting absence with invalid data.
 *
 * @param {date} startDate of an absence
 * @param {date} endDate of an absence
 * @param {string} startDayPart of an absence
 * @param {string} endDayPart of an absence
 * @param {string} newDayPartValue recently informed by the user as a replacement of
 *                                 one of the two day parts above
 *
 * @return {object} containing the properties startDayPart and enDayPart without inconsistencies
 */
export const adjustDayParts = ({ startDayPart, endDayPart, startDate, endDate, newDayPartValue }) => {
  if (isSameDay(new Date(startDate), new Date(endDate))) {
    if (startDayPart === 'afternoon' && endDayPart === 'morning') {
      if (newDayPartValue) return { startDayPart: newDayPartValue, endDayPart: newDayPartValue };
      return { startDayPart: 'morning', endDayPart: 'afternoon' };
    }
  }
  return { startDayPart, endDayPart };
};

/**
 * adjustFractions - Adjusts the fractions according to the newest user input to avoid requesting absence
 *                   with invalid data.
 *
 * @param {date} startDate of an absence
 * @param {date} endDate of an absence
 * @param {number} startFraction of an absence
 * @param {number} endFraction of an absence
 * @param {date} newStartDate recently informed by the user as a replacement of startDate (can be null)
 * @param {date} newEndDate recently informed by the user as a replacement of endDate (can be null)
 *
 * @return {object} with startFraction and endFraction properties with no inconsistency
 */
const adjustFractions = ({ startDate, endDate, startFraction, endFraction, newStartDate, newEndDate }) => {
  if (isSameDay(new Date(startDate), new Date(endDate))) {
    if (isSameDay(new Date(newStartDate), new Date(newEndDate))) {
      return { startFraction, endFraction };
    }

    if (startFraction === endFraction) {
      return { startFraction: 1, endFraction: 1 };
    }
  }

  if (isSameDay(new Date(newStartDate), new Date(newEndDate))) {
    return { startFraction: 2, endFraction: 2 };
  }
  return { startFraction: 1, endFraction: 1 };
};

/**
 * adjustFractionsFromDayParts - Generates startFraction and endFraction properties based on the given
 *                               dates and day parts
 *
 * @param {date} startDate of an absence
 * @param {date} endDate of an absence
 * @param {string} startDayPart of an absence (can be 'morning' or 'afternoon')
 * @param {string} endDayPart of an absence (can be 'morning' or 'afternoon')
 * @param {number} startFraction of an absence. Used in case of an inconsistency:
 *                               startDayPart = 'afternoon' and endDayPart = 'morning' for an absence of one day long
 * @param {number} endFraction of an absence. Used in case of an inconsistency:
 *                               startDayPart = 'afternoon' and endDayPart = 'morning' for an absence of one day long
 *
 * @return {object} containing startFraction and endFraction properties.
 */
export const adjustFractionsFromDayParts = ({
  startDate,
  endDate,
  startDayPart,
  endDayPart,
  startFraction,
  endFraction,
}) => {
  if (!startDate || !endDate) {
    return { startDayPart, endDayPart };
  }

  if (isSameDay(new Date(startDate), new Date(endDate))) {
    if (startDayPart === 'morning' && endDayPart === 'morning') {
      return { startFraction: 0, endFraction: 2 };
    }
    if (startDayPart === 'morning' && endDayPart === 'afternoon') {
      return { startFraction: 2, endFraction: 2 };
    }
    if (startDayPart === 'afternoon' && endDayPart === 'afternoon') {
      return { startFraction: 2, endFraction: 0 };
    }
    if (startDayPart === 'afternoon' && endDayPart === 'morning') {
      return { startFraction: startFraction === 2 ? 0 : 2, endFraction: endFraction === 2 ? 0 : 2 };
    }
  }

  return { startFraction: startDayPart === 'morning' ? 1 : 2, endFraction: endDayPart === 'afternoon' ? 1 : 2 };
};

/**
 * formatDateRangeAbsence - Generates a string which is a concatenation of the start and end dates of an absence
 *
 * @param {date} startDate of an absence
 * @param {date} endDate of an absence
 * @param {string} startDayPart of an absence
 * @param {string} endDayPart of an absence
 * @param {object} translation { morning: 'translation for morning', afternoon: 'translation for afternoon' }
 * @param {string} locale 'de' or 'en'
 *
 * @return {string} which is a concatenation of startDate and endDate of an absence, each date formatted
 *                  accordingly with the locale
 * ex: '01/01/2020 - 02/01/2020'
 */
export const formatDateRangeAbsence = ({ startDate, endDate, startDayPart, endDayPart }, translation, locale) => {
  const initialDate = `${formatDate(startDate, locale)}${
    (startDate === endDate && startDayPart === endDayPart) || startDayPart !== 'morning'
      ? ` (${translation[startDayPart]})`
      : ''
  }`;
  const finalDate = `${formatDate(endDate, locale)}${
    (startDate === endDate && startDayPart === endDayPart) || endDayPart !== 'afternoon'
      ? ` (${translation[endDayPart]})`
      : ''
  }`;

  const arrDates = [initialDate, finalDate];

  return arrDates.filter((date, index) => arrDates.indexOf(date) === index).join(' - ');
};

/**
 * getAbsenceDayLength - Checks if an absence for the given date is a full-day absence or if it happens
 * just on the morning part or on the afternoon part
 *
 * @param {object} absence with properties startDate, endDate, startDayPart and endDayPart
 * @param {date} date that is within absence.startDate and absence.endDate or equals one of them
 *
 * @return {string} that can assume three possible values:
 *    'morning': represents that the absence for the given date happens just on the morning part
 *    'afternoon': represents that the absence for the given date happens just on the afternoon part
 *    'fullDay': represents that the absence for the given date happens for the whole day
 */
export const getAbsenceDayLength = (absence, date) => {
  const fractions = toFractions(absence, date);
  if (isHalfDayMorning(fractions)) {
    return 'morning';
  } else if (isHalfDayAfternoon(fractions)) {
    return 'afternoon';
  } else if (isFullDay(fractions)) {
    return 'fullDay';
  }
};

/**
 * getDayParts - Generates the day parts of an absence based on its dates and fractions
 *
 * @param {date} startDate of an absence
 * @param {date} endDate of an absence
 * @param {number} startFraction of an absence
 * @param {number} endFraction of an absence
 *    startFraction = 0   endFraction = 2   => startDayPart = 'morning' endDayPart = 'morning'
 *    startFraction = 2   endFraction = 0   => startDayPart = 'afternoon' endDayPart = 'afternoon'
 *    startFraction = 2   endFraction = 2   => startDayPart = 'morning' endDayPart = 'afternoon'
 *    startFraction = 0,1 endFraction = 0,1 => startDayPart = 'morning' endDayPart = 'afternoon'
 *
 * @return {object} with two properties:
 *    startDayPart: string that can be 'morning' or 'afternoon'
 *    endDayPart: string that can be 'morning' or 'afternoon'
 */
export const getDayParts = ({ startDate, endDate, startFraction, endFraction }) => {
  if (!startDate || !endDate) {
    return { startDayPart: 'morning', endDayPart: 'afternoon' };
  }

  if (isSameDay(new Date(startDate), new Date(endDate))) {
    if (startFraction === 2 && endFraction === 2) {
      return { startDayPart: 'morning', endDayPart: 'afternoon' };
    }
    if (startFraction === 0 && endFraction === 2) {
      return { startDayPart: 'morning', endDayPart: 'morning' };
    }
    return { startDayPart: 'afternoon', endDayPart: 'afternoon' };
  }

  return {
    startDayPart: startFraction === 0 || startFraction === 1 ? 'morning' : 'afternoon',
    endDayPart: endFraction === 0 || endFraction === 1 ? 'afternoon' : 'morning',
  };
};

/**
 * getFractionFromWorkDay - Calculates if the given absence for the given date is a half-day
 * absence or a full-day absence
 *
 * @param {object} workDay dayKind element that satisfies 'workDay.date === @param date' and
 * 'workDay.dayKindId === DAY_KINDS.WORK' and possesses the property fraction.
 * @param {object} absence with properties startDate, endDate, startDayPart and endDayPart
 * @param {date} date that is within absence.startDate and absence.endDate or equals one of them
 *
 * @return {number} which can be
 *    0.5, if the absence for the given date represents a half-day absence or
 *    1, if the absence for the given date represents a full-day absence
 */
export const getFractionFromWorkDay = ({ workDay, absence, date }) => {
  if (!absence) {
    return;
  }
  if (workDay && workDay.fraction === 2) {
    return 0.5;
  }

  switch (getAbsenceDayLength(absence, date)) {
    case 'morning':
    case 'afternoon':
      return 0.5;
    default:
      return 1;
  }
};

/**
 * isAdmitted - Checks if an absence was rejected or confirmed
 *
 * @param {object} absence which contains two properties:
 *                            confirmedAt: date when this absence was confirmed
 *                            rejectedAt: date when this absence was rejected
 *
 * @return {boolean} false if an absence is pending for approval, true otherwise
 */
const isAdmitted = (absence) => absence.confirmedAt || absence.rejectedAt;

/**
 * isFullDay - Checks if the properties offset and fraction represent a full-day of absence
 *
 * @param {number} fraction if it equals 2, means that the absence for the given date happens on a half-day period
 *                          if it equals 1, means that the absence for the given date happens on a full-day period
 *                          if it equals 0, means that the absence happens on a holiday
 * @param {number} offset in case fraction equals 2, the offset is used to determine if the absence happens just on
 *                        the morning (offset = 0) or on the afternoon (offset = 2)
 *
 * @return {boolean} true if the properties represent a full-day of absence,
 *                  false otherwise
 */
export const isFullDay = ({ offset, fraction }) => offset === 0 && fraction === 1;

/**
 * isHalfDayAfternoon - Checks if the properties offset and fraction represent a half-day of absence
 * that happens on the afternoon
 *
 * @param {number} fraction if it equals 2, means that the absence for the given date happens on a half-day period
 *                          if it equals 1, means that the absence for the given date happens on a full-day period
 *                          if it equals 0, means that the absence happens on a holiday
 * @param {number} offset in case fraction equals 2, the offset is used to determine if the absence happens just on
 *                        the morning (offset = 0) or on the afternoon (offset = 2)
 *
 * @return {boolean} true if the properties represent a half-day of absence that happens on the afternoon,
 *                  false otherwise
 */
export const isHalfDayAfternoon = ({ offset, fraction }) => offset === 2 && fraction === 2;

/**
 * isHalfDayMorning - Checks if the properties offset and fraction represent a half-day of absence
 * that happens on the morning
 *
 * @param {number} fraction if it equals 2, means that the absence for the given date happens on a half-day period
 *                          if it equals 1, means that the absence for the given date happens on a full-day period
 *                          if it equals 0, means that the absence happens on a holiday
 * @param {number} offset in case fraction equals 2, the offset is used to determine if the absence happens just on
 *                        the morning (offset = 0) or on the afternoon (offset = 2)
 *
 * @return {boolean} true if the properties represent a half-day of absence that happens on the morning,
 *                  false otherwise
 */
export const isHalfDayMorning = ({ offset, fraction }) => offset === 0 && fraction === 2;

/**
 * isSameAbsence - Verifies if two absences are the same
 *
 * @param {object} absence1 with userId, crewId, createdAt, startDate, endDate,
 *                          startDayPart and endDayPart properties
 * @param {object} absence2 with userId, crewId, createdAt, startDate, endDate,
 *                          startDayPart and endDayPart properties
 *
 * @return {boolean} true if the absences are the same,
 *                   false otherwise
 */
export const isSameAbsence = (absence1, absence2) =>
  absence1.userId === absence2.userId &&
  absence1.crewId === absence2.crewId &&
  absence1.createdAt === absence2.createdAt &&
  absence1.startDate === absence2.startDate &&
  absence1.endDate === absence2.endDate &&
  absence1.startDayPart === absence2.startDayPart &&
  absence1.endDayPart === absence2.endDayPart;

/**
 * themeForAbsenceTypeId - Fetches the theme properties for an absence type
 *
 * @param {number} absenceTypeId is between 0 and 5 (included)
 *
 * @return {object} which contains 4 color properties: regular, light, extraLight and transparent.
 * Each absence type has a theme associated to it.
 */
export const themeForAbsenceTypeId = (absenceTypeId) => ABSENCE_MANAGEMENT_THEMES[absenceTypeId];

/**
 * sumAbsenceDays - Calculates the total quantity of days off an absence requires
 *
 * @param {array} absenceDays each element represents a day and contains a property "faction" which
 *                            if equals 2 represents a half day of work and
 *                            if equals 1 represents a full day of work
 *
 * @return {number} which represents the total quantity of days off an absence requires
 */
export const sumAbsenceDays = (absenceDays) => absenceDays.reduce((sum, day) => sum + 1 / day.fraction, 0);

/**
 * sumAbsenceDaysCount - Calculates the total quantity of days off required for all absences requested for
 *                       the given days
 *
 * @param {array} absenceDays each element represents a day and contains a property "value" which
 *                            represents the total quantity of days off all the absences requested
 *                            on this day require
 *
 * @return {number} which represents the total quantity of days off is required for all absences requested
 *                  for the given days
 */
export const sumAbsenceDaysCount = (absenceDays) => absenceDays.reduce((sum, day) => sum + day.value, 0);

/**
 * toFractions - Checks if the given absence for the given date happens on a half-day period or
 * on a full-day period
 *
 * @param {object} absence with properties startDate, endDate, startDayPart and endDayPart
 * @param {date} date that is within absence.startDate and absence.endDate or equals one of them
 *
 * @return {object} with two properties:
 *    fraction: if it equals 2, means that the absence for the given date happens on a half-day period
 *              if it equals 1, means that the absence for the given date happens on a full-day period
 *              if it equals 0, means that the absence happens on a holiday
 *    offset: in case fraction equals 2, the offset is used to determine if the absence happens just on
 *            the morning (offset = 0) or on the afternoon (offset = 2)
 */
export const toFractions = (absence, date) => {
  const empty = { offset: 0, fraction: 0 };
  const fullDay = { offset: 0, fraction: 1 };
  const halfDayMorning = { offset: 0, fraction: 2 };
  const halfDayAfternoon = { offset: 2, fraction: 2 };

  if (isSameDay(new Date(absence.startDate), new Date(absence.endDate))) {
    if (absence.startDayPart === 'morning' && absence.endDayPart === 'afternoon') {
      return fullDay;
    }
    if (absence.startDayPart === 'morning' && absence.endDayPart === 'morning') {
      return halfDayMorning;
    }
    return halfDayAfternoon;
  }

  if (isSameDay(new Date(absence.startDate), new Date(date))) {
    if (absence.startDayPart === 'afternoon') {
      return halfDayAfternoon;
    }
    if (absence.startDayPart === 'morning') {
      return fullDay;
    }
  }

  if (isSameDay(new Date(absence.endDate), new Date(date))) {
    if (absence.endDayPart === 'morning') {
      return halfDayMorning;
    }
    if (absence.endDayPart === 'afternoon') {
      return fullDay;
    }
  }

  if (isWithinRange(date, new Date(absence.startDate), new Date(absence.endDate))) {
    return fullDay;
  }

  return empty;
};
