import { factory, trait, sequence } from 'stahlwerk';
import { ABSENCE_TYPES } from '../constants';

export const createAbsenceType = factory({
  id: sequence(),
  name: 'Sickness',
  hasEntitlement: true,
  memberCanCreate: false,
  isDisabled: false,

  withoutConfirmation: trait({
    memberCanCreate: true,
  }),

  withoutEntitlement: trait({
    hasEntitlement: false,
  }),

  disabled: trait({
    isDisabled: true,
  }),

  vacation: trait({
    name: 'Vacation',
    id: ABSENCE_TYPES.vacation,
  }),

  sickness: trait({
    name: 'Vacation',
    id: ABSENCE_TYPES.sickness,
  }),

  compensatoryTimeOff: trait({
    name: 'Vacation',
    id: ABSENCE_TYPES.compensatoryTimeOff,
  }),
});
