import { CSSProperties } from 'aphrodite';
import { COLORS, CREWMEISTER_FONTS } from './config';

export const fontWeights = {
  narrow: 200,
  regular: 400,
  bold: 700,
};

// eslint-disable-next-line no-shadow
export enum fontWeightsEnum {
  narrow = 200,
  regular = 400,
  bold = 700,
}

export const base = {
  fontFamily: `'${CREWMEISTER_FONTS.DEFAULT}', ${CREWMEISTER_FONTS.FALLBACK}`,
  color: COLORS.GREY_MAIN,
  fontDisplay: 'swap',
} as CSSProperties;

export const tiny = {
  ...base,
  fontSize: '0.75em',
} as CSSProperties;

export const small = {
  ...base,
  fontWeight: 400,
  fontSize: '14px',
} as CSSProperties;

export const normal = {
  ...base,
  fontWeight: 400,
  fontSize: '16px',
} as CSSProperties;

export const medium = {
  ...base,
  fontWeight: 400,
  fontSize: '18px',
} as CSSProperties;

export const big = {
  ...base,
  fontWeight: 700,
  fontSize: '25px',
} as CSSProperties;

export const emberHeading = {
  fontSize: 18,
  color: '#333333',
  '@media (min-width: 767px)': {
    fontSize: 25,
  },
} as CSSProperties;
