import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES } from '../../constants';

type BadgeProps = {
  id?: string;
  children: ReactNode;
  onClick?: () => void;
  alignCenter?: boolean;
  className?: string;
  column?: boolean;
  spacingTop?: boolean;
  spacingBottom?: boolean;
  maxWidth?: boolean;
  isLoading?: boolean;
  style?: CSSProperties;
};

const Badge: (props: BadgeProps) => ReactElement = ({
  id,
  children,
  onClick,
  alignCenter = false,
  className,
  column = false,
  spacingTop = false,
  spacingBottom = false,
  maxWidth = false,
  isLoading,
  style = {},
}) => (
  <div
    id={id}
    className={`${css(
      styles.container,
      !!onClick && styles.clickable,
      column && styles.column,
      alignCenter && styles.alignCenter,
      spacingTop && styles.spacingTop,
      spacingBottom && styles.spacingBottom,
      maxWidth && styles.maxWidth,
      isLoading && styles.isLoading
    )} ${className}`}
    onClick={onClick}
    style={style}
  >
    {children}
  </div>
);

const styles = StyleSheet.create({
  container: {
    ...FONT_STYLES.medium,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: COLORS.WHITE,
    padding: '10px 20px',
    boxShadow: '0 0 2px 0 rgba(104, 103, 102, 0.3)',
    width: '100%',
    height: 'auto',
  },
  clickable: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'all 150ms',
    ':hover': {
      boxShadow: '0 3px 6px 0 rgba(104, 103, 102, 0.3)',
    },
    ':focus': {
      boxShadow: '0 3px 6px 0 rgba(104, 103, 102, 0.3)',
    },
    ':active': {
      boxShadow: '0 0 3px 0 rgba(104, 103, 102, 0.3)',
    },
  },
  alignCenter: {
    justifyContent: 'center',
  },
  column: {
    display: 'block',
  },
  spacingTop: {
    marginTop: '20px',
  },
  spacingBottom: {
    marginBottom: '20px',
  },
  maxWidth: {
    maxWidth: '280px',
    margin: 'auto',
  },
  isLoading: {
    transition: 'opacity 300ms',
    opacity: 0.5,
  },
});

export default Badge;
