import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ absenceEntitlements: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const absenceEntitlementsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_ABSENCE_ENTITLEMENTS_SUCCESS':
        applyMinimalChangesToArray(
          draft.absenceEntitlements,
          action.result,
          (entitlement) => entitlement.year === action.year && entitlement.crewId === action.crewId
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
