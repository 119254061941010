import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '../../action-creators';
import { api, apiV3, callFunctionWhenPropsChange, wrapWithNotification } from '../../lib';
import { collections, react } from '../../utils';
import * as selectors from '../../selectors';

import Component from './organism';
import t from './translate';
import { v3SignInUserWithPin } from '../../action-creators';

const { loadContext, loadTerminalCrewMembers, signInUserWithPinCode } = actions;
const { composeReverse } = collections;
const { selectCurrentCrewId } = selectors;
const { withLoadingState } = react;

const mapStateToProps = (state, props) => ({
  crewId: selectCurrentCrewId(state, props),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  functionToCall: ({ crewId }) => dispatch(loadTerminalCrewMembers({ api, crewId, disabledAt: null })),
  onInputFinish: (pinCode, resetPinInput) =>
    wrapWithNotification(
      async () => {
        const params = ownProps.userId ? { api, pinCode, userId: ownProps.userId } : { api, pinCode };
        const userId = await dispatch(signInUserWithPinCode(params));
        await dispatch(v3SignInUserWithPin({ apiV3, pin: pinCode }));
        await dispatch(loadContext.unmemoized({ api }));
        if (ownProps.success) ownProps.success(userId);
      },
      {
        dispatch,
        error: () => {
          resetPinInput();
          return t('submitFailed');
        },
      }
    ),
});

const TerminalSignInWithPinCodeContainer = composeReverse(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withLoadingState('onInputFinish', 'isSubmitting', false),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId'] })
)(Component);

TerminalSignInWithPinCodeContainer.displayName = 'TerminalSignInWithPinCodeContainer';

export default TerminalSignInWithPinCodeContainer;
