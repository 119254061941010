import fetch from './fetch';
import { ApiError } from '../../error';

import { STATUS_CODE_KEY } from './status-codes';

const throwErrors = (json) => {
  const error = json[STATUS_CODE_KEY];
  throw new ApiError(error, STATUS_CODE_KEY);
};

export function requestPasswordReset({ headers, userIdentifier }) {
  let hasError = false;
  const handleJsonResponse = (json) => {
    if (hasError) {
      throwErrors(json);
    }
  };

  return fetch
    .requestPasswordReset({ headers, userIdentifier })
    .then((response) => {
      hasError = response.status !== 200;
      return response;
    })
    .then((response) => response.json())
    .then(handleJsonResponse);
}
