import { format } from 'date-fns';
import { setTime } from './date';

type DateOrString = Date | string;
/**
 * formatIsoTime - Converts the given date to the specified format, returns an empty string if
 * date is not valid
 *
 * @param  {string || date} date
 * @return  {string} Output format: 00:00:00 - 23:59:59
 */
export const formatIsoTime = (date: DateOrString): string => {
  return date ? format(new Date(date), 'HH:mm:ss') : '';
};

/**
 * formatIsoTimeShort- Converts the given date to the specified format, returns an empty string if
 * date is not valid
 *
 * @param  {string || date} date
 * @return  {string} Output format: 00:00 - 23:59
 */
export const formatIsoTimeShort = (date: DateOrString): string => {
  return date ? format(new Date(date), 'HH:mm') : '';
};

/**
 * formatIsoTimeShortSimpleHours - Converts the given date to the specified format, returns an empty
 * string if date is not valid
 *
 * @param  {string || date} date
 * @return  {string} Output format: 0:00 - 23:59
 */
export const formatIsoTimeShortSimpleHours = (date: DateOrString): string => {
  return date ? format(new Date(date), 'H:mm') : '';
};

/**
 * formatTimeRange - Converts the given date to the specified format, returns an empty string if
 * date is not valid
 *
 * @param  {DateOrString} startDate
 * @param  {DateOrString} endDate
 * @return  {string} Output format: 08:00 - 18:00
 */
export const formatTimeRange = (startDate: DateOrString, endDate: DateOrString): string => {
  return `${formatIsoTimeShort(startDate)} - ${formatIsoTimeShort(endDate)}`;
};

/**
 * getGreetingLabel - Returns the appropriate greeting label according to the current time
 *
 * @param {date} currentTime
 * @returns {string} which could be 'goodMorning', 'goodAfternoon' or 'goodEvening'.
 */
export const getGreetingLabel = (currentTime: Date = new Date()): string => {
  const currentHour = currentTime.getHours();
  const splitMorning = 4;
  const splitAfternoon = 12;
  const splitEvening = 17;

  if (currentHour >= splitMorning && currentHour < splitAfternoon) {
    return 'goodMorning';
  } else if (currentHour >= splitAfternoon && currentHour < splitEvening) {
    return 'goodAfternoon';
  }
  return 'goodEvening';
};

/**
 * getZoneId — Retrieves the zone id from the user's browser.
 *
 * @returns {string} containing the user's zone id
 */
export const getZoneId = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    return 'Europe/Berlin';
  }
};

/**
 * isValidTimeValue - Check if the passed value can be converted to a time value
 *
 * @param {string} value
 *
 * @return {boolean} true, if value can be converted to a time value,
 *                   false, otherwise
 */
export const isValidTimeValue = (value: string): boolean => {
  try {
    formatIsoTime(value);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * buildDateTime - Builds a valid date from given iso8601 date and time
 * @param {DateOrString} date 2010-01-01
 * @param {string} time 00:00
 */
export const buildDateTime = (date: DateOrString, time: string): Date => {
  return setTime(new Date(date), time);
};

/**
 * setHoursMinutesSecondsOfDate - Generates a new date based on the given date but with hours, minutes and
 *                                seconds given set to it
 * @param {date} date
 * @param {integer} hours
 * @param {integer} minutes
 * @param {integer} seconds
 *
 * @return {date}
 */
export const setHoursMinutesSecondsOfDate = (date: Date, hours: number, minutes: number, seconds: number): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds);
