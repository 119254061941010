/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { getEnv } from '../../utils';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { FONT_STYLES, BREAKPOINTS } from '../../constants';

// eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
const LinkOrSpan = ({ href, ...props }) => (href ? <a href={href} {...props} /> : <span {...props} />);

const LogoWithHeading = ({ children, withoutLink = false }) => (
  <header className={css(styles.container)}>
    <LinkOrSpan
      className={css(styles.logo)}
      href={!withoutLink && getEnv(process.env.CREWMEISTER_WEBSITE_DOMAIN, 'CREWMEISTER_WEBSITE_DOMAIN')}
    >
      <svg width="50" height="50" viewBox="0 0 148 64">
        <defs>
          <path
            id="path-1"
            d="M1.29617144,14.2204836 C1.73611503,16.0611971 3.10519621,17.009646 5.4020385,17.009646 L62.1629819,17.009646 C64.8993587,17.009646 66.422461,15.5590467 66.422461,12.7641191 L66.422461,8.20774945 C66.422461,5.41282183 64.8993587,3.96222251 62.1629819,3.96222251 L5.4020385,3.96222251 C3.10519621,3.96222251 1.73611503,4.91067142 1.29617144,6.75138499 L1.29617144,14.2204836 L1.29617144,14.2204836 Z"
          />
          <path
            id="path-3"
            d="M144.444349,52.990354 C147.180726,52.990354 148.703829,54.4409533 148.703829,57.2358809 L148.703829,61.7922506 C148.703829,64.5871782 147.180726,66.0377775 144.444349,66.0377775 L80.9166664,66.0377775 C78.0761458,66.0377775 76.6571873,64.5871782 76.6571873,61.7922506 L76.6571873,57.2358809 C76.6571873,54.4409533 78.0761458,52.990354 80.9166664,52.990354 L144.444349,52.990354"
          />
          <path
            id="path-5"
            d="M114.031773,28.4523773 C116.770753,28.4523773 118.293856,29.9029766 118.293856,32.6979042 L118.293856,37.2542738 C118.293856,40.0492015 116.770753,41.497144 114.031773,41.497144 L80.9505131,41.497144 C78.1099925,41.497144 76.6884304,40.0492015 76.6884304,37.2542738 L76.6884304,32.6979042 C76.6884304,29.9029766 78.1099925,28.4523773 80.9505131,28.4523773 L114.031773,28.4523773"
          />
        </defs>
        <g id="Style-Inventory" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Symbols" transform="translate(-3884.000000, -2829.000000)">
            <g id="logo/crewmeister.large" transform="translate(3883.000000, 2826.000000)">
              <rect
                id="Rectangle-1287"
                opacity="0"
                fillOpacity="0.1"
                fill="#62C6BD"
                x="0"
                y="0"
                width="150"
                height="70"
              />

              <use id="Clip-17" fill="#FF9419" xlinkHref="#path-1" />
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-3" />
              </mask>
              <use id="Mask-Copy" fill="#42403E" xlinkHref="#path-3" />
              <mask id="mask-6" fill="white">
                <use xlinkHref="#path-5" />
              </mask>
              <use id="Clip-21" fill="#FF9419" xlinkHref="#path-5" />
              <path
                d="M62.2358825,28.5427076 C64.974863,28.5427076 66.4979652,29.9906502 66.4979652,32.7855778 L66.4979652,37.3419474 C66.4979652,40.1368751 64.974863,41.5848176 62.2358825,41.5848176 L49.4496345,41.5848176 C46.6091139,41.5848176 45.1875518,40.1368751 45.1875518,37.3419474 L45.1875518,32.7855778 C45.1875518,29.9906502 46.6091139,28.5427076 49.4496345,28.5427076 L62.2358825,28.5427076"
                id="Fill-23"
                fill="#42403E"
              />
            </g>
          </g>
        </g>
      </svg>
    </LinkOrSpan>
    <h1 className={css(styles.heading)}>{children}</h1>
  </header>
);

LogoWithHeading.propTypes = {
  children: PropTypes.node.isRequired,
  withoutLink: PropTypes.bool,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [BREAKPOINTS.mobilePortraitMode]: {
      flexDirection: 'column',
    },
  },
  logo: {
    width: '50px',
    height: '50px',
    marginRight: '25px',
  },
  heading: {
    ...FONT_STYLES.big,
    lineHeight: 1.4,
    [BREAKPOINTS.mobilePortraitMode]: {
      textAlign: 'center',
    },
  },
});

export default LogoWithHeading;
