import _germanTranslations from '../../translation-de';
import _englishTranslations from '../../translation-en';
import {
  isGerman,
  browserLanguage,
  globalCrewmeisterLanguage,
  languageFromLocation,
  languageWithoutRegion,
} from '../../_external-deps/locale';
import buildTranslateFunction, { buildDangerouslyTranslateNeverWithUserContent } from './locale';

let language = void 0;
export const setLanguage = (newLanguage) => {
  language = newLanguage;
  if (global.api) global.api.setXLanguage(newLanguage);
};

export const getLanguage = () => language;

export const getTranslationLanguage = (
  _getGlobalCrewmeisterLanguage = globalCrewmeisterLanguage,
  _getLocallySetLanguage = () => language,
  _getBrowserLanguage = browserLanguage,
  _getLocationLanguage = languageFromLocation
) =>
  _getLocationLanguage() ||
  _getGlobalCrewmeisterLanguage() ||
  _getLocallySetLanguage() ||
  languageWithoutRegion(_getBrowserLanguage());

const consoleLog = (...args) => console.log(...args); // eslint-disable-line no-console
const logMissingKeys = consoleLog;
export const translateFunction = ({ de = _germanTranslations, en = _englishTranslations } = {}) => {
  if (isGerman(getTranslationLanguage())) {
    return buildTranslateFunction(de, consoleLog, logMissingKeys);
  }
  const noLogForMissingMessages = () => void 0;
  return buildTranslateFunction(en, noLogForMissingMessages, logMissingKeys);
};

export const buildScopedTranslationFunction =
  (...args) =>
  (...translateArgs) =>
    translateFunction(...args)(...translateArgs);

const translate = (...args) => translateFunction()(...args);

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);

export default translate;
export { buildDangerouslyTranslateNeverWithUserContent } from './locale';
