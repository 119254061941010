import produce from 'immer';
import { createSelector } from 'reselect';
import { selectCurrentCrewId } from './crews-selectors';
import { createPropsFinder, createPropsSelector } from './utils';
import { formatIso8601Date } from '../utils/date';

export const selectAbsenceDaysCount = (state) => state.bo.absenceDaysCount.absenceDaysCount || produce([], () => {});

export const selectAbsenceDaysCountForCurrentCrew = createSelector(
  selectAbsenceDaysCount,
  selectCurrentCrewId,
  (absenceDayCount, crewId) => absenceDayCount.filter((dayCount) => dayCount.crewId === crewId)
);

export const selectAbsenceDaysCountByProps = createPropsSelector(selectAbsenceDaysCount);
export const findAbsenceDaysCountByProps = createPropsFinder(selectAbsenceDaysCount);

export const selectAbsenceDaysCountInCurrentYear = createSelector(
  (absenceDaysCount, inclusiveFilter, exclusiveFilter, param) => {
    const absenceDayCount = createPropsSelector(() => absenceDaysCount)(
      absenceDaysCount,
      inclusiveFilter,
      exclusiveFilter
    );
    const reducedAbsenceDayCount = absenceDayCount.reduce(
      (acc, dayCount) => ({
        ...dayCount,
        from: formatIso8601Date(new Date(`${inclusiveFilter.year}-01-01`)),
        to: formatIso8601Date(new Date(`${inclusiveFilter.year}-12-31`)),
        value: acc.value + dayCount.value,
      }),
      { value: 0 }
    );
    return reducedAbsenceDayCount[param];
  },
  (result) => result
);
