const isEmpty = (value) => !(value === false || value === 0 || value);

export const removeEmptyObjectValues = (object) =>
  Object.keys(object).reduce((previous, key) => {
    const value = object[key];

    if (isEmpty(value)) {
      return previous;
    }

    previous[key] = value; // eslint-disable-line no-param-reassign
    return previous;
  }, {});
