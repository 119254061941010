import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadShifts = memoize(
  {},
  ({ api, userId, crewId, startDate, endDate }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_SHIFTS',
        fnParams: { ...(!!userId && { userId }), crewId, startDate, endDate },
        fn: (params) => api.getShifts(params),
      })
);

export const transformShifts = memoize(
  { ttl: 0 },
  ({ api, crewId, values }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'TRANSFORM_SHIFTS',
        fnParams: { crewId, values },
        fn: (params) => api.transformShifts(params),
      })
);

export const copyShifts = memoize(
  { ttl: 0 },
  ({ api, crewId, originStartDate, originEndDate, targetStartDate, targetEndDate }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'COPY_SHIFTS',
        fnParams: { crewId, originStartDate, originEndDate, targetStartDate, targetEndDate },
        fn: (params) => api.copyShifts(params),
      })
);
