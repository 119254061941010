import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const createTemplate = memoize(
  { ttl: 0 },
  ({ api, crewId, name, guids }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'CREATE_TEMPLATE',
        fnParams: { crewId, name, guids },
        fn: (params) => api.createTemplate(params),
      })
);

export const updateTemplate = memoize(
  { ttl: 0 },
  ({ api, crewId, templateId, name }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'UPDATE_TEMPLATE',
        fnParams: { crewId, templateId: parseInt(templateId, 10), name },
        fn: (params) => api.updateTemplate(params),
      })
);

export const deleteTemplate = memoize(
  {},
  ({ api, crewId, templateId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'DELETE_TEMPLATE',
        fnParams: { crewId, templateId: parseInt(templateId, 10) },
        fn: (params) => api.deleteTemplate(params),
      })
);

export const applyTemplate = memoize(
  { ttl: 0 },
  ({ api, crewId, templateId, date }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'APPLY_TEMPLATE',
        fnParams: { crewId, templateId, date },
        fn: (params) => api.applyTemplate(params),
      })
);
