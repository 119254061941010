import React from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS } from '../../constants';

const buildArrowStyles = (borderColor) => (borderWidth) => ({
  borderColor,
  borderWidth,
});

const buildVerticalArrowStyles = buildArrowStyles(`transparent ${COLORS.ORANGE_MAIN}`);
const buildHorizontalArrowStyles = buildArrowStyles(`${COLORS.ORANGE_MAIN} transparent`);

const buildArrow = (directionStyles) => (props) =>
  (
    <button
      {...(props.isButton && { type: 'button' })}
      tabIndex={-1}
      onClick={props.onClick}
      className={css(
        props.additionalClassName,
        styles.arrows,
        props.small ? directionStyles.small : directionStyles.big,
        props.onClick && styles.clickable
      )}
      id={props.id}
    >
      {props.children}
    </button>
  );

export const ArrowLeft = buildArrow(
  StyleSheet.create({
    small: buildVerticalArrowStyles('4px 0 4px 8px'),
    big: buildVerticalArrowStyles('7px 0 7px 14px'),
  })
);

export const ArrowRight = buildArrow(
  StyleSheet.create({
    small: buildVerticalArrowStyles('4px 8px 4px 0'),
    big: buildVerticalArrowStyles('7px 14px 7px 0'),
  })
);

export const ArrowDown = buildArrow(
  StyleSheet.create({
    small: buildHorizontalArrowStyles('8px 4px 0 4px'),
    big: buildHorizontalArrowStyles('14px 7px 0 7px'),
  })
);

export const ArrowUp = buildArrow(
  StyleSheet.create({
    small: buildHorizontalArrowStyles('0 4px 8px 4px'),
    big: buildHorizontalArrowStyles('0 7px 14px 7px'),
  })
);

const styles = StyleSheet.create({
  arrows: {
    display: 'inline-block',
    borderStyle: 'solid',
    width: 0,
    height: 0,
    overflow: 'hidden',
    background: 'none',
    padding: 0,
    ':focus': {
      outline: 'none',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
});
