import produce from 'immer';
import { selectors } from '@crewmeister/shared';
import { array } from '../utils';

const { findByProps } = selectors;
const { applyMinimalChangesToArray } = array;

const initialState = produce({ crews: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const crewsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'UPDATE_CREW_SUCCESS':
        applyMinimalChangesToArray(draft.crews, action.result, (crew) => crew.id === action.crewId);
        break;
      case 'LOAD_CREWS_SUCCESS':
        applyMinimalChangesToArray(
          draft.crews,
          action.result.map((crew) => ({
            ...crew,
            inMaintenance: !!findByProps(state.crews, { id: crew.id }, {}, 'inMaintenance'),
          }))
        );
        break;
      case 'UPDATE_CREW_MAINTENANCE_STATUS':
        applyMinimalChangesToArray(
          draft.crews,
          {
            id: action.result.crewId,
            ...findByProps(state.crews, { id: action.result.crewId }),
            inMaintenance: action.result.status,
          },
          (crew) => crew.id === action.result.crewId
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
