// list constants for UI state keys that you want to share across the app
export const UI_STATE_KEY_CURRENT_DATE = 'currentDate';
export const UI_STATE_KEY_USER_APP_IN_TERMINAL_MODE = 'userAppInTerminalMode';

export const setUiStateValue = ({ key, value }) => ({
  type: 'SET_UI_STATE_VALUE',
  key,
  value,
});

export const reloadTimeDurations = () => ({
  type: 'RELOAD_TIME_DURATIONS',
});

export const reloadTimeDurationsForDays = (userId, startDate, endDate) => ({
  type: 'RELOAD_TIME_DURATIONS_FOR_DAYS',
  result: {
    userId,
    startDate,
    endDate,
  },
});

export const reloadDurationBalances = () => ({
  type: 'RELOAD_DURATION_BALANCES',
});

export const reloadDurationBalancesForDays = (userId, date) => ({
  type: 'RELOAD_DURATION_BALANCES_FOR_DAY',
  result: {
    userId,
    date,
  },
});
