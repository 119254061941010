import { CSSProperties } from 'aphrodite';
import React, { CSSProperties as CSSPropertiesReact, ReactElement, ReactNode } from 'react';

import { ICON_NAMES } from '../../../constants';
import { COLORS } from '../../../styles/config';
import * as fonts from '../../../styles/fonts';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';

import Icon from '../icon';
import LoadingDots from '../loading-dots';
import ButtonOrLink from './button-or-link';

export type ButtonComponentProps = {
  noTextTransform?: boolean;
  onClick?: () => void;
  children: ReactNode;
  iconName?: ICON_NAMES;
  disabled?: boolean;
  narrow?: boolean;
  inline?: boolean;
  loading?: boolean;
  small?: boolean;
  type?: 'button' | 'submit' | 'reset';
  displayType?: string;
  style?: CSSPropertiesReact;
  className?: string;
  primary?: boolean;
  href?: string;
  spacingBottom?: boolean;
  spacingTop?: boolean;
  iconPosition?: 'left' | 'right';
  wrapWhitespace?: boolean;
  withBackground?: boolean;
  active?: boolean;
  target?: string;
  id?: string;
  'data-e2e-test'?: string;
};

const ButtonComponent: (props: ButtonComponentProps) => ReactElement = (props) => {
  const {
    noTextTransform,
    onClick = () => null,
    children,
    iconName,
    disabled = false,
    narrow = false,
    inline = false,
    loading = false,
    small = false,
    type = 'button',
    displayType = 'default',
    style = {},
    className = '',
    primary,
    href,
    spacingBottom,
    spacingTop,
    iconPosition = 'left',
    wrapWhitespace,
    withBackground,
    active,
    target,
    id,
  } = props;

  const hasLoadingFeature = 'loading' in props;

  let styles;

  switch (displayType) {
    case 'danger':
      styles = THEMES.danger;
      break;

    case 'turquoise':
      styles = THEMES.turquoise;
      break;

    case 'turquoise-white':
      styles = THEMES.turquoiseWhite;
      break;

    default:
      styles = THEMES.default;
  }

  return (
    <ButtonOrLink
      id={id}
      href={href}
      target={target}
      style={style}
      className={`${css(
        styles.default,
        loading && commonStyles.currentlyLoading,
        small && styles.small,
        narrow ? styles.narrow : styles.filled,
        hasLoadingFeature && commonStyles.withoutPadding,
        primary && commonStyles.primary,
        inline && styles.inline,
        spacingBottom && commonStyles.spacingBottom,
        spacingTop && commonStyles.spacingTop,
        wrapWhitespace && commonStyles.wrapWhitespace,
        withBackground && commonStyles.narrowWithBackground,
        active && commonStyles.active,
        noTextTransform && commonStyles.noTextTransform
      )} ${className}`}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
      data-e2e-test={props['data-e2e-test']}
    >
      {hasLoadingFeature && <LoadingDots hidden />} {/* 100% center for Button */}
      {iconName && iconPosition === 'left' && (
        <Icon name={iconName} className={css(!!children && commonStyles.iconLeft) as string} />
      )}
      {children}
      {iconName && iconPosition === 'right' && (
        <Icon name={iconName} className={css(!!children && commonStyles.iconRight) as string} />
      )}
      {hasLoadingFeature && <LoadingDots hidden={!loading} />}
    </ButtonOrLink>
  );
};

const defaultStyles: CSSProperties = {
  ...fonts.medium,
  fontSize: 16,
  lineHeight: 1.42857,
  padding: '7px 20px 6px',
  border: '0 solid transparent',
  width: '100%',
  margin: '0 auto',
  transition: 'all 200ms',
  position: 'relative',
  whiteSpace: 'nowrap',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 3,
  textDecoration: 'none',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  '-webkit-appearance': 'none !important',
  boxSizing: 'border-box',
  textAlign: 'center',
  flexShrink: 0,

  ':hover': {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  ':focus': {
    outline: 'none',
  },
  ':disabled:hover': {
    cursor: 'not-allowed',
  },
};

const commonStyles = StyleSheet.create({
  currentlyLoading: {
    ':disabled:hover': {
      cursor: 'wait',
    },
  },
  noTextTransform: {
    textTransform: 'none',
  },
  withoutPadding: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  iconLeft: {
    fontSize: '0.8em',
    paddingRight: '5px',
  },
  iconRight: {
    fontSize: '0.8em',
    paddingLeft: '5px',
  },
  primary: {
    padding: '26px 62px',
  },
  spacingBottom: {
    marginBottom: '10px',
  },
  spacingTop: {
    marginTop: '10px',
  },
  wrapWhitespace: {
    whiteSpace: 'normal',
  },
  narrowWithBackground: {
    background: COLORS.WHITE,
  },
  active: {
    color: COLORS.WHITE,
    background: COLORS.ORANGE_MEDIUM,
  },
});

const THEMES = {
  default: StyleSheet.create({
    default: defaultStyles,
    inline: { display: 'inline-flex', width: 'auto', margin: 0 },
    filled: {
      color: COLORS.WHITE,
      background: COLORS.ORANGE_MEDIUM,
      ':hover': {
        background: COLORS.ORANGE_MAIN,
        boxShadow: '0 0 9px 0 rgba(136, 116, 100, 0.5)',
      },
      ':active': {
        background: COLORS.ORANGE_DARK,
        boxShadow: '0 0 1px 0 rgba(179, 155, 136, 0.4)',
      },
      ':focus': {
        background: COLORS.ORANGE_DARK,
        boxShadow: '0 0 1px 0 rgba(179, 155, 136, 0.4)',
      },
      ':disabled': {
        background: COLORS.MAIN_COLOR_LITE,
        color: COLORS.ORANGE_MAIN,
      },
      boxShadow: '0 0 1px 0 rgba(179, 155, 136, 0.4)',
    },
    narrow: {
      color: COLORS.ORANGE_MEDIUM,
      padding: '7px 15px 6px',
      background: 'transparent',
      ':hover': {
        color: COLORS.ORANGE_MAIN,
        background: COLORS.ORANGE_OPAQUE,
      },
      ':active': {
        color: COLORS.ORANGE_DARK,
        background: COLORS.ORANGE_EXTRA_LIGHT,
      },
      ':focus': {
        color: COLORS.ORANGE_DARK,
        background: COLORS.ORANGE_LIGHT,
      },
      ':disabled': {
        color: COLORS.MAIN_COLOR_LITE,
      },
    },
    small: {
      ...fonts.small,
    },
  }),
  turquoise: StyleSheet.create({
    default: defaultStyles,
    inline: { display: 'inline-flex', width: 'auto', margin: 0 },
    filled: {
      color: COLORS.WHITE,
      background: COLORS.TURQUOISE_MEDIUM,
      ':hover': {
        background: COLORS.TURQUOISE_MAIN,
        boxShadow: '0 0 9px 0 rgba(136, 116, 100, 0.5)',
      },
      ':active': {
        background: COLORS.TURQUOISE_DARK,
        boxShadow: '0 0 1px 0 rgba(179, 155, 136, 0.4)',
      },
      ':focus': {
        background: COLORS.TURQUOISE_DARK,
        boxShadow: '0 0 1px 0 rgba(179, 155, 136, 0.4)',
      },
      ':disabled': {
        background: COLORS.MAIN_COLOR_LITE,
        color: COLORS.TURQUOISE_MAIN,
      },
      boxShadow: '0 0 1px 0 rgba(179, 155, 136, 0.4)',
    },
    narrow: {
      color: COLORS.TURQUOISE_MEDIUM,
      padding: '7px 15px 6px',
      background: 'transparent',
      ':hover': {
        color: COLORS.TURQUOISE_MAIN,
        background: COLORS.TURQUOISE_OPAQUE,
      },
      ':active': {
        color: COLORS.TURQUOISE_DARK,
        background: COLORS.TURQUOISE_LIGHT,
      },
      ':focus': {
        color: COLORS.TURQUOISE_DARK,
        background: COLORS.TURQUOISE_LIGHT,
      },
      ':disabled': {
        color: COLORS.TURQUOISE_OPAQUE_3,
      },
    },
    small: {
      ...fonts.small,
    },
  }),
  turquoiseWhite: StyleSheet.create({
    default: defaultStyles,
    inline: { display: 'inline-flex', width: 'auto', margin: 0 },
    filled: {
      color: COLORS.WHITE,
      background: 'transparent',
      ':hover': {
        background: COLORS.TURQUOISE_DARK,
        boxShadow: '0 0 9px 0 rgba(136, 116, 100, 0.5)',
      },
      ':active': {
        background: COLORS.TURQUOISE_DARK,
        boxShadow: '0 0 1px 0 rgba(179, 155, 136, 0.4)',
      },
      ':focus': {
        background: COLORS.TURQUOISE_DARK,
        boxShadow: '0 0 1px 0 rgba(179, 155, 136, 0.4)',
      },
      ':disabled': {
        background: COLORS.MAIN_COLOR_LITE,
        color: COLORS.TURQUOISE_MAIN,
      },
      boxShadow: '0 0 1px 0 rgba(179, 155, 136, 0.4)',
    },
    narrow: {
      color: COLORS.TURQUOISE_MEDIUM,
      padding: '7px 15px 6px',
      background: 'transparent',
      ':hover': {
        color: COLORS.TURQUOISE_MAIN,
        background: COLORS.TURQUOISE_OPAQUE,
      },
      ':active': {
        color: COLORS.TURQUOISE_DARK,
        background: COLORS.TURQUOISE_LIGHT,
      },
      ':focus': {
        color: COLORS.TURQUOISE_DARK,
        background: COLORS.TURQUOISE_LIGHT,
      },
      ':disabled': {
        color: COLORS.TURQUOISE_OPAQUE_3,
      },
    },
    small: {
      ...fonts.small,
    },
  }),
  danger: StyleSheet.create({
    default: defaultStyles,
    inline: { display: 'inline-flex', width: 'auto', margin: 0 },
    filled: {
      color: COLORS.WHITE,
      background: COLORS.RED_MEDIUM,
      boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.2)',
      ':hover': {
        background: COLORS.RED_MAIN,
      },
      ':active': {
        background: COLORS.RED_DARK,
        boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.2)',
      },
      ':focus': {
        background: COLORS.RED_DARK,
        boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.2)',
      },
      ':disabled': {
        background: COLORS.RED_LIGHT,
        color: COLORS.RED_MAIN,
      },
    },
    narrow: {
      color: COLORS.RED_MEDIUM,
      padding: '7px 15px 6px',
      background: 'transparent',
      ':hover': {
        color: COLORS.RED_MAIN,
        background: COLORS.RED_OPAQUE,
      },
      ':active': {
        color: COLORS.RED_DARK,
        background: COLORS.RED_LIGHT,
      },
      ':focus': {
        color: COLORS.RED_DARK,
        background: COLORS.RED_LIGHT,
      },
      ':disabled': {
        color: COLORS.RED_LIGHT,
      },
    },
    small: {
      ...fonts.small,
    },
  }),
};

export default ButtonComponent;
