import { createSelector } from 'reselect';

export const selectUserIdFromProps = (_state: any, props: any) => (props && props.userId ? props.userId : null);

export const selectUsers = (state: any) => state.bo.users.users;

type User = { id: number };

export const selectCurrentUser: (state: any, props: any) => User = createSelector(
  selectUsers,
  selectUserIdFromProps,
  (users, userId) => users.find((user: User) => user.id === userId)
);
