import React, { useState } from 'react';

import RadioButtons from '../../components/core/radio-buttons-v2';
import SearchBar from '../../components/core/SearchV1';

import { array as arrayUtils } from '../../utils';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import t from './translate';

const { searchFilter } = arrayUtils;

const Organism = (props) => {
  const { id, canSeeSecondTimeCategory, internalComponentStyles, level, onFormChange, timeCategories, withSearchBar } =
    props;

  const [searchText, setSearchText] = useState('');

  const { idSelected, setIdSelected } = {
    idSelected: props[`selectedTimeCategory${level}Id`],
    setIdSelected: props[`setSelectedTimeCategory${level}Id`],
  };

  const elements = timeCategories.map((category) => ({
    label: category.name,
    id: category.id || 'null',
    checked: idSelected === category.id,
  }));

  const handleSearchBarChange = (event) => setSearchText(event.target.value);

  return (
    <React.Fragment>
      {withSearchBar && (
        <div className={css(styles.searchBar)}>
          <SearchBar
            id={id ? `${id}-search` : undefined}
            placeholder={t('placeholder')}
            onChange={handleSearchBarChange}
          />
        </div>
      )}
      <div className={css(styles.mainCategoryContainer, internalComponentStyles)}>
        <RadioButtons
          id={id}
          elements={searchFilter(elements, searchText, 'label')}
          onChange={(event) => {
            const idOfTimeCategory = event.target.value === 'null' ? null : Number(event.target.value);
            setIdSelected(idOfTimeCategory);
            onFormChange(level);
          }}
          radio={canSeeSecondTimeCategory}
        />
      </div>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  mainCategoryContainer: {
    marginBottom: '24px',
    minHeight: '392px',
    width: '100%',
  },
  searchBar: {
    marginBottom: '25px',
  },
});

export default Organism;
