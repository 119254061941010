import { factory, random, sequence } from 'stahlwerk';

export const createTimeCategory = factory({
  id: sequence(),
  crewId: sequence(),
  name: sequence((id) => `Time category ${id}`),
  disabled: false,
  disabledAt: null,
  level: random([1, 2]),
});
