import { createSelector } from 'reselect';
import { selectCurrentUserId } from './authentication-selectors';
import { createPropsFinder, createPropsSelector } from './utils';
import { selectAbsencesInCurrentCrewOnDate } from './absences-selectors';
import { selectCurrentCrewId, selectCrews } from './crews-selectors';

const selectMembersFromState = (state) => state.bo.crewMembers.members;

const sortMembersByName = (a, b) => a.name.localeCompare(b.name);

export const selectMembers = createSelector(selectMembersFromState, (members) =>
  members.concat().sort(sortMembersByName)
);

export const selectMembersInCurrentCrew = createSelector(selectMembers, selectCurrentCrewId, (members, crewId) =>
  members.filter((member) => member.crewId === crewId)
);

export const selectEnabledMembersInCurrentCrew = createSelector(selectMembersInCurrentCrew, (members) =>
  members.filter((member) => !member.disabledAt)
);

export const selectDisabledMembersInCurrentCrew = createSelector(selectMembersInCurrentCrew, (members) =>
  members.filter((member) => member.disabledAt)
);

export const selectMembersByProps = createPropsSelector(selectMembers);
export const findMemberByProps = createPropsFinder(selectMembers);

export const selectMember = createSelector(
  selectMembers,
  selectCurrentCrewId,
  selectCurrentUserId,
  (members, crewId, userId) => members.find((member) => member.userId === userId && member.crewId === crewId)
);

const isAbsenceForMember = (absence, member) => absence.userId === member.userId;

// all members for the current crew that are absent on a selected date
export const selectMembersInCurrentCrewAbsentOnDate = (dateSelector) =>
  createSelector(selectMembersInCurrentCrew, selectAbsencesInCurrentCrewOnDate(dateSelector), (members, absences) =>
    members.filter((member) => absences.some((absence) => isAbsenceForMember(absence, member)))
  );

export const selectCurrentMember = createSelector(
  selectMembersInCurrentCrew,
  (_, props) => props.userId,
  (members, userId) => members.find((member) => member.userId === userId)
);

export const selectMemberById = createSelector(
  selectMembers,
  (_, props) => parseInt(props.id, 10),
  (members, id) => members.find((member) => member.id === id)
);

export const selectMembersByUserId = createSelector(selectMembers, selectCurrentUserId, (members, userId) =>
  members.filter((member) => member.userId === userId)
);

export const selectMembersWithCrewNameByCurrentUserId = createSelector(
  selectCrews,
  selectMembersByUserId,
  (crews, members) =>
    members.map((member) => ({ ...member, crewName: crews.find((crew) => crew.id === member.crewId).name }))
);
