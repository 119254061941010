import React from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

import Button from '../../../components/core/button';
import DateTimeInfo from '../../../components/core/date-time-info';
import SecondaryDuration from '../../../components/core/secondary-duration';

import { COLORS, FONT_STYLES, IDS_FOR_GOOGLE_TAG_MANAGER, THEMES, TIME_ACCOUNTS } from '../../../constants';
import translate from '../../../lib/translate';
import { duration as durationUtils } from '../../../utils';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';

import t from '../translate';
import PrimaryDuration from './primary-duration';

const { formatIsoDuration } = durationUtils;

export const BreakScreen = ({
  alignment,
  id,
  onStartWork,
  onStopWork,
  period,
  disableStampWatch,
  displaySmall,
  selectedTimeCategory1Id,
  selectedTimeCategory2Id,
  showPauseInMinutes,
  canTrackTimes,
  theme,
  wide,
  withoutButtons,
  withoutDateTimeInfo = false,
}) => {
  const dateTimeInfo = (
    <If condition={!displaySmall}>
      <DateTimeInfo prefix={translate('Begin:')} date={period.clockInTimestamp} theme={theme} />
    </If>
  );

  const styles = stylesWithThemes[theme];

  return (
    <section className={css(styles.container)}>
      <If condition={!withoutDateTimeInfo && theme !== THEMES.TURQUOISE}>{dateTimeInfo}</If>

      <If condition={theme === THEMES.TURQUOISE_WHITE}>
        <div className={css(styles.workDurationInfo)}>
          {t('workedFor', { duration: formatIsoDuration(period.workDuration) })}
        </div>
      </If>

      <PrimaryDuration
        bold
        className={css(styles.primaryDuration)}
        icon={theme !== THEMES.TURQUOISE_WHITE && 'pause'}
        duration={period.breakDuration}
        showInMinutes={showPauseInMinutes}
        theme={theme}
        timeAccount={TIME_ACCOUNTS.BREAK}
        wide={wide}
      />

      <If condition={theme === THEMES.TURQUOISE_WHITE}>
        <div className={css(styles.inPause)}>{t('inPause')}</div>
      </If>
      <If condition={theme !== THEMES.TURQUOISE_WHITE}>
        <SecondaryDuration icon="time-tracking" duration={period.workDuration} theme={theme} alignment={alignment} />
      </If>

      <If condition={!canTrackTimes}>
        <span className={css(styles.disabledLegend)}>{t('disableInputWarning')}</span>
      </If>

      <If condition={!withoutButtons}>
        <div className={css(styles.buttonGroup)}>
          <Button
            className={css(styles.button)}
            disabled={!canTrackTimes || disableStampWatch}
            id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.START_WORK}
            inline
            onClick={() =>
              onStartWork({ timeCategory1Id: selectedTimeCategory1Id, timeCategory2Id: selectedTimeCategory2Id })
            }
            spacingBottom
          >
            <If condition={theme === THEMES.TURQUOISE_WHITE}>
              <PlayArrowIcon className={css(styles.icon)} />
            </If>
            <span data-e2e-test="stamp-watch-start-work">{translate('Continue work')}</span>
          </Button>
          <Button
            className={css(styles.button)}
            disabled={!canTrackTimes || disableStampWatch}
            id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.STOP_WORK}
            inline
            onClick={onStopWork}
          >
            <If condition={theme === THEMES.TURQUOISE_WHITE}>
              <StopIcon className={css(styles.icon)} />
            </If>
            <span data-e2e-test="stamp-watch-stop-work">{translate('Stop work')}</span>
          </Button>
        </div>
      </If>
    </section>
  );
};

const stylesWithThemes = {
  [THEMES.ORANGE]: StyleSheet.create({
    button: {
      width: 'fit-content !important',
    },
    buttonGroup: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%',
    },
    disabledLegend: {
      ...FONT_STYLES.medium,
      textAlign: 'center',
      margin: '10px 0',
      color: 'red',
      display: 'block',
    },
  }),
  [THEMES.TURQUOISE]: StyleSheet.create({
    button: {
      backgroundColor: `${COLORS.WHITE} !important`,
      borderRadius: '88px !important',
      color: `${COLORS.TURQUOISE_MAIN} !important`,
      fontSize: '40px !important',
      height: '146px',
      textTransform: 'none !important',
      width: '644px !important',
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%',
    },
    disabledLegend: {
      ...FONT_STYLES.medium,
      textAlign: 'center',
      margin: '10px 0',
      color: 'red',
      display: 'block',
    },
    inPause: {
      color: COLORS.TURQUOISE_MAIN,
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 58,
    },
    primaryDuration: {
      margin: 35,
    },
    workDurationInfo: {
      color: COLORS.TURQUOISE_MEDIUM,
      fontSize: 24,
      fontWeight: 600,
    },
  }),
  [THEMES.TURQUOISE_WHITE]: StyleSheet.create({
    button: {
      backgroundColor: `${COLORS.WHITE} !important`,
      borderRadius: '88px !important',
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2) !important',
      color: `${COLORS.TURQUOISE_MAIN} !important`,
      fontSize: '40px !important',
      height: '112px',
      textTransform: 'none !important',
      width: '644px !important',
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%',
    },
    disabledLegend: {
      ...FONT_STYLES.medium,
      textAlign: 'center',
      margin: '10px 0',
      color: 'red',
      display: 'block',
    },
    icon: {
      fontSize: '50px !important',
      marginRight: 16,
    },
    inPause: {
      color: COLORS.TURQUOISE_MAIN,
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 25,
    },
    primaryDuration: {
      margin: 35,
    },
    workDurationInfo: {
      color: COLORS.TURQUOISE_MEDIUM,
      fontSize: 24,
      fontWeight: 600,
    },
  }),
};

export default BreakScreen;
