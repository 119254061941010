import React, { Component } from 'react';
import UpdateGeofenceContent from './update-geofence-content';
import CollapsableHeading from '../../../components/core/collapsable-heading';
import TextWithPlaceholder from '../../../components/core/text-with-label';
import t from '../translate';

class UpdateGeofence extends Component {
  constructor(props) {
    super(props);
    const { geofence } = props;
    this.state = {
      isOpen: geofence && !!geofence.id,
    };
  }

  onClickHandler = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  onDeleteProxy = (values) => {
    Promise.resolve()
      .then(() => this.props.onDelete(values))
      .then(() => this.setState({ isOpen: false }));
  };

  onSubmitProxy = (values) => {
    Promise.resolve()
      .then(() => this.props.onSubmit(values))
      .then(() => this.setState({ isOpen: false }));
  };

  render() {
    const withArea = this.props.geofence && !!this.props.geofence.id;
    return (
      <TextWithPlaceholder placeholder={t('area')}>
        <CollapsableHeading
          header={withArea ? t('withArea') : t('withoutArea')}
          isOpen={this.state.isOpen}
          onClick={this.onClickHandler}
        >
          <UpdateGeofenceContent
            {...this.props}
            onDelete={(values) => this.onDeleteProxy(values)}
            onSubmit={(values) => this.onSubmitProxy(values)}
            onCancel={() => this.setState({ isOpen: false })}
          />
        </CollapsableHeading>
      </TextWithPlaceholder>
    );
  }
}

export default UpdateGeofence;
