import React from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, IDS_FOR_GOOGLE_TAG_MANAGER } from '../../constants';
import * as Fonts from '../../styles/fonts';
import { isForm } from '../../components/core/form';
import Icon from '../../components/core/icon';
import PasswordInput from '../../components/core/password-input';
import Button from '../../components/core/button';
import t from './translate';

const SignIn = isForm(({ inputRef, isSubmitting, errors, onClick, onInputClick }) => (
  <div data-e2e-test="terminal-sign-in" ref={inputRef}>
    <PasswordInput
      placeholder={t('password')}
      name="password"
      errors={errors && errors.password}
      required
      autoFocus
      onClick={onInputClick}
    />
    <Button id={IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL.SIGNIN.SUBMIT} type="submit" loading={isSubmitting} spacingBottom>
      {t('submit')}
    </Button>
    <Button
      id={IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL.SIGNIN.RESET_PASSWORD}
      narrow
      onClick={onClick}
      loading={isSubmitting}
    >
      {t('resetPassword')}
    </Button>
  </div>
));

const SignInWithHeader = ({
  member,
  onClose,
  onInputClick,
  onSubmit,
  onClickReset,
  inputRef,
  withoutHeader = false,
  className,
}) => {
  return (
    <div className={className}>
      <If condition={!withoutHeader}>
        <h1 className={css(styles.heading)}>
          {member && `${t('greeting')}, ${member.name}`}
          <button
            id={IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL.SIGNIN.CLOSE}
            onClick={onClose}
            className={css(styles.closeButton)}
          >
            <Icon name="close" />
          </button>
        </h1>
      </If>
      <p className={css(styles.infoText)}>{t('info')}</p>
      <SignIn inputRef={inputRef} onSubmit={onSubmit} onClick={onClickReset} onInputClick={onInputClick} />
    </div>
  );
};

const styles = StyleSheet.create({
  infoText: {
    ...Fonts.medium,
  },
  heading: {
    ...Fonts.big,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 0,
    paddingRight: '30px',
  },
  closeButton: {
    ...Fonts.small,
    color: COLORS.ORANGE_MAIN,
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    right: '20px',
  },
});

export default SignInWithHeader;
