import { factory, trait, sequence, random } from 'stahlwerk';

export const createDuration = factory({
  type: 'work',
  value: random((rand) => `PT${Math.floor(rand * 3660)}S`),
  from: '2018-12-13',
  to: '2018-12-13',
  groupBy: {},
  groupedByOneValue: trait({
    userId: sequence(),
  }),
  groupedByTwoValues: trait({
    userId: sequence(),
    week: sequence(),
  }),
  break: trait({ type: 'break' }),
  work: trait({ type: 'work' }),
  workDifference: trait({ type: 'workDifference' }),
  workTarget: trait({ type: 'workTarget' }),
});
