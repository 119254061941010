import { factory } from 'stahlwerk';

export const createTimeTrackingSettings = factory({
  crewId: null,
  userId: null,
  timeTrackingAllowedMobile: 'withoutLocation',
  timeTrackingAllowedOther: 'withoutLocation',
  timeTrackingAllowedTerminal: 'withoutLocation',
  isNew: true,
});
