import { createSelector } from 'reselect';
import { selectors } from '@crewmeister/shared';
import produce from 'immer';

const { selectCurrentCrewId } = selectors;

const selectTerminalCrews = (state) => state.terminal.crews.crews || produce([], () => {});

export const selectCurrentTerminalCrew = createSelector(selectTerminalCrews, selectCurrentCrewId, (crews, crewId) =>
  crews.find((crew) => crew.id === crewId)
);
