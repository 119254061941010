import { FC } from '@crewmeister/shared';
import React, { ReactNode } from 'react';

import { COLORS, FONT_STYLES, THEMES } from '../../constants';
import translate from '../../lib/translate';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import LoadingText from './loading-text';

type LoadingSpinnerWithTextProps = {
  children?: ReactNode;
  fullscreen?: boolean;
  marginTop?: boolean;
  sectionClassName?: string;
  small?: boolean;
  text?: string;
  theme?: THEMES.ORANGE | THEMES.TURQUOISE_WHITE | THEMES.TURQUOISE;
  withLoadingIcon?: boolean;
  withoutText?: boolean;
};

const LoadingSpinnerWithText: FC<LoadingSpinnerWithTextProps> = ({
  children,
  fullscreen = false,
  marginTop,
  sectionClassName,
  small = false,
  text = translate('Loading'),
  theme = THEMES.ORANGE,
  withLoadingIcon = false,
  withoutText = false,
}) => (
  <section
    className={`${css(
      styles.container,
      marginTop && styles.marginTop,
      small && styles.small,
      fullscreen && styles.fullscreen
    )} ${sectionClassName}`}
  >
    <div>
      {withLoadingIcon && (
        <div className={`${css(styles.logoContainer)}`}>
          <img src="/assets/images/logo_without_bg.gif" className={`${css(styles.logo)}`} />
        </div>
      )}

      {!withLoadingIcon && (
        <div className={`${css(styles.outerSpinner)}`}>
          <div className={`${css(spinnerStylesWithTheme[theme].spinner)}`} />
        </div>
      )}

      {!withoutText && <LoadingText className={`${css(styles.text)}`}>{text}</LoadingText>}
      {children}
    </div>
  </section>
);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '30px',
  },
  text: {
    ...FONT_STYLES.medium,
    textAlign: 'center',
    marginTop: '30px',
  },
  logoContainer: {
    width: 200,
    display: 'inline-block',
  },
  logo: {
    width: 'inherit',
    height: 'inherit',
  },
  outerSpinner: {
    animationDuration: '10s',
    animationIterationCount: 'infinite',
    animationName: {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' },
    },
  },
  small: {
    transform: 'scale(0.35)',
  },
  fullscreen: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const spinnerStylesWithTheme = {
  [THEMES.ORANGE]: StyleSheet.create({
    spinner: {
      margin: 'auto',
      border: '16px solid #f3f3f3',
      borderTop: `16px solid ${COLORS.ORANGE_MAIN}`,
      borderRadius: '50%',
      width: '120px',
      height: '120px',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      animationName: {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
      },
    },
  }),
  [THEMES.TURQUOISE_WHITE]: StyleSheet.create({
    spinner: {
      margin: 'auto',
      border: '16px solid #f3f3f3',
      borderTop: `16px solid ${COLORS.TURQUOISE_DARK}`,
      borderRadius: '50%',
      width: '120px',
      height: '120px',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      animationName: {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
      },
    },
  }),
  [THEMES.TURQUOISE]: StyleSheet.create({
    spinner: {
      margin: 'auto',
      border: '16px solid #f3f3f3',
      borderTop: `16px solid ${COLORS.TURQUOISE_MAIN}`,
      borderRadius: '50%',
      width: '120px',
      height: '120px',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      animationName: {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
      },
    },
  }),
};

export default LoadingSpinnerWithText;
