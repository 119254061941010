import { createSelector } from 'reselect';
import { createPropsFinder, createPropsSelector } from './utils';

export const selectBreakRules = (state) => state.bo.breakRules.breakRules;

export const selectBreakRulesForCurrentCrew = createSelector(
  selectBreakRules,
  (_, props) => props.crewId,
  (breakRules, crewId) => breakRules.filter((breakRule) => breakRule.crewId === crewId)
);
export const selectBreakRulesByProps = createPropsSelector(selectBreakRules);
export const findBreakRulesByProps = createPropsFinder(selectBreakRules);
