import produce from 'immer';
import { ACTION_NAMES, DEFAULT_AVATAR } from '../constants';
import { array, updateGTMDataLayerContext } from '../utils';

const withDefaultAvatarUrl = (user) => ({
  ...user,
  avatarUrl: user.avatarUrl ? user.avatarUrl : DEFAULT_AVATAR,
});

const initialState = produce({ users: [] }, () => {});
const { applyMinimalChangesToArray } = array;
// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const usersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${ACTION_NAMES.LOAD_USER}_SUCCESS`:
      case `${ACTION_NAMES.UPDATE_USER_PROFILE}_SUCCESS`:
        applyMinimalChangesToArray(
          draft.users,
          withDefaultAvatarUrl(action.result),
          (user) => user.id === action.userId
        );
        updateGTMDataLayerContext({ userId: action.userId });
        break;
      case 'SIGNOUT_USER_SUCCESS':
        updateGTMDataLayerContext({
          userId: null,
          crewId: null,
          trial: null,
          role: null,
          terminalViewMode: null,
          terminalAuthenticationMode: null,
        });
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
