import React, { Component } from 'react';
import { fetch } from '../../../utils';
import MapGeofence from '../../../components/core/map-geofence';
import t from '../translate';
import Button from '../../../components/core/button';
import Heading from '../../../components/core/heading';
import SearchForm from './search-form';
import { StyleSheet, css } from '../../../_external-deps/stylesheet';
import { FONT_STYLES, COLORS } from '../../../constants';

export const RADIUS = {
  SMALL: 150,
  MEDIUM: 250,
  LARGE: 350,
};

export const INITIAL_VALUES = {
  SIZE: 150,
  ZOOM: 15,
  LAT: 48.13703175,
  LNG: 11.5759245905674,
};

class UpdateGeofenceContent extends Component {
  constructor(props) {
    super(props);
    const { geofence } = props;
    this.state = {
      radius: geofence ? geofence.radius : RADIUS.SMALL,
      markers: geofence
        ? [{ lat: Number(geofence.latitude), lng: Number(geofence.longitude) }]
        : [{ lat: INITIAL_VALUES.LAT, lng: INITIAL_VALUES.LNG }],
      size: INITIAL_VALUES.SIZE,
      zoom: INITIAL_VALUES.ZOOM,
      address: '',
      id: geofence && geofence.id,
      addressErrors: [],
    };
  }

  onValueChange = (value, field) => {
    this.setState({
      [field]: value,
    });
  };

  onSubmitHandler = async ({ address }) => {
    if (!address) {
      return void 0;
    }
    const response = await fetch.fetchGet(
      `https://nominatim.openstreetmap.org/search.php?q=${address}&format=json&addressdetails=1`,
      { credentials: 'omit' }
    );
    const body = await response.json();
    if (body.length) {
      const { lat, lon: lng } = body[0];
      Promise.resolve().then(() =>
        this.setState({
          address: '',
          markers: [
            {
              lat: Number(lat),
              lng: Number(lng),
            },
          ],
          addressErrors: [],
        })
      );
    } else {
      this.setState({
        addressErrors: [{ message: t('addressNotFound') }],
      });
    }
  };

  render() {
    const { onDelete, isDeleting, onSubmit, isSubmitting, onCancel, crewId, timeCategoryId, geofence } = this.props;
    const { zoom, markers, size, radius, addressErrors, id } = this.state;

    return (
      <div key={`${timeCategoryId}-${crewId}-${geofence && geofence.id}}`}>
        <Heading small noBottomSpacing>
          {t('instructions')}
        </Heading>
        <Heading small noTopSpacing>
          {t('instructionsCont')}
        </Heading>
        <a
          className={css(styles.link)}
          href="https://support.crewmeister.com/support/solutions/articles/44001755217-wie-richte-ich-stempelerinnerungen-ein-"
          target="_blank"
        >
          {t('moreInfo')}
        </a>
        <SearchForm
          style={{ marginBottom: 10 }}
          addressErrors={addressErrors}
          address={this.state.address}
          onSubmit={this.onSubmitHandler}
          onBlur={this.onSubmitHandler}
        />
        <MapGeofence zoom={zoom} markers={markers} size={size} radius={radius} onValueChange={this.onValueChange} />
        <div className={css(styles.actions)}>
          <If condition={id}>
            <Button
              onClick={() => onDelete(this.state)}
              displayType="danger"
              narrow
              inline
              disabled={isDeleting || isSubmitting}
            >
              {t('delete')}
            </Button>
          </If>
          <Button onClick={onCancel} style={{ marginLeft: 'auto' }} inline narrow>
            {t('cancel')}
          </Button>
          <Button onClick={() => onSubmit(this.state)} inline disabled={isDeleting || isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  actions: {
    display: 'flex',
  },
  link: {
    ...FONT_STYLES.small,
    color: COLORS.ORANGE_MAIN,
  },
});

export default UpdateGeofenceContent;
