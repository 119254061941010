import { connect } from 'react-redux';

import * as actions from '../../action-creators';
import { PERMISSIONS } from '../../constants';
import { api, callFunctionWhenPropsChange } from '../../lib';
import * as selectors from '../../selectors';
import { collections } from '../../utils';

import Organism from './organism';

const { loadGeofences, loadTimeCategories } = actions;
const { composeReverse } = collections;
const { findPermissionsByProps, selectTimeCategoriesByProps } = selectors;

const mapStateToProps = (state, props) => {
  return {
    canSeeSecondTimeCategory: findPermissionsByProps(
      state,
      { crewId: props.crewId, name: PERMISSIONS.SECOND_TIME_CATEGORY },
      {},
      'allowed'
    ),
    timeCategories: selectTimeCategoriesByProps(state, {
      crewId: props.crewId,
      ...(props.hideDisabled ? {} : { disabled: false }),
    }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  functionToCall: ({ crewId }) =>
    Promise.all([dispatch(loadTimeCategories({ api, crewId })), dispatch(loadGeofences({ api, crewId }))]),
});

const TimeCategoryTabsContainer = composeReverse(
  connect(mapStateToProps, mapDispatchToProps),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId'] })
)(Organism);

export default TimeCategoryTabsContainer;
