import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;

const initialState = produce({ news: [], counts: [] }, () => {});

const updateUserNewsCount = (draft, userId, countDeltas) => {
  const index = draft.counts.findIndex((count) => count.userId === userId);
  if (index > -1) {
    const newsCount = draft.counts[index];
    const updatedCounts = {};
    Object.entries(countDeltas).forEach(([type, delta]) => {
      const count = newsCount[type] + delta;
      updatedCounts[type] = count < 0 ? 0 : count;
    });

    // eslint-disable-next-line no-param-reassign
    draft.counts[index] = { ...newsCount, ...updatedCounts };
  }
};

export const newsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_NEWS_COUNT_SUCCESS':
        applyMinimalChangesToArray(draft.counts, action.result, (counts) => counts.userId === action.userId);
        break;
      case 'LOAD_NEWS_SUCCESS':
        applyMinimalChangesToArray(
          draft.news,
          action.result.payload.map((newsItem) => ({ ...newsItem, _sync: action.result._sync })),
          (newsItem) => action.result.payload.map((n) => n.id).includes(newsItem.id)
        );
        break;
      case 'RECEIVED_NEWS_SUCCESS':
        if (!draft.news.map((n) => n.id).includes(action.result.id)) {
          updateUserNewsCount(draft, action.result.receiverId, {
            total: 1,
            unseen: 1,
            unread: 1,
            [action.result.type]: 1,
          });
          applyMinimalChangesToArray(draft.news, action.result, () => false);
        }
        break;
      case 'UPDATE_NEWS_SUCCESS':
        if (action.result.length) {
          updateUserNewsCount(draft, action.result[0].receiverId, { unread: -action.result.length });
        }
        applyMinimalChangesToArray(draft.news, action.result, (newsItem) => action.ids.includes(newsItem.id));
        break;
      case 'MARK_NEWS_AS_SEEN_SUCCESS':
        if (action.result.length) {
          updateUserNewsCount(draft, action.result[0].receiverId, { unseen: -action.result.length });
        }
        applyMinimalChangesToArray(draft.news, action.result, (newsItem) =>
          action.result.map((n) => n.id).includes(newsItem.id)
        );
        break;
      case 'DELETE_NEWS_SUCCESS':
        if (action.result.length) {
          const countDeltas = {};
          action.result.forEach((newsItem) => {
            const types = [newsItem.type, 'total'];
            if (!newsItem.seenAt) types.push('unseen');
            if (!newsItem.readAt) types.push('unread');
            types.forEach((type) => {
              if (countDeltas[type]) countDeltas[type]--;
              else countDeltas[type] = -1;
            });
          });
          updateUserNewsCount(draft, action.result[0].receiverId, countDeltas);
        }
        applyMinimalChangesToArray(draft.news, [], (newsItem) => action.ids.includes(newsItem.id));
        break;
      case 'CLEAR_APP_STATE':
        return initialState;
      default:
    }
  });
