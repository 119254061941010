import React from 'react';
import LoadingSpinnerWithText from '../../../components/core/loading-spinner-with-text';

import { MemberList } from './member-list';
import { AboutCrewSignIn } from './about-crew-sign-in';

const Component = ({ members, onSignUpOpen, loadedOnce }) => {
  if (!loadedOnce) {
    return <LoadingSpinnerWithText fullscreen />;
  }

  return (
    <section>
      <AboutCrewSignIn />
      <MemberList members={members} onSignUpOpen={onSignUpOpen} />
    </section>
  );
};

export default Component;
