import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const requestAbsence =
  ({ api, crewId, userId, typeId, startDate, startDayPart, endDate, endDayPart, memberNote }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'REQUEST_ABSENCE',
      permission: 'canUseAbsenceManagement',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        userId,
        typeId,
        startDate,
        startDayPart,
        endDate,
        endDayPart,
        memberNote,
      },
      fn: (params) => api.requestAbsence(params),
    });

export const loadAbsences = memoize(
  {},
  ({ api, userId, crewId, from, to, hasPermission, status }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_ABSENCES',
        hasPermission,
        fnParams: { userId, crewId, from, to, status },
        fn: (params) => api.getAbsences(params),
      })
);

export const createAbsence =
  ({ api, crewId, userId, typeId, startDate, startDayPart, endDate, endDayPart, memberNote, admitterNote }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'CREATE_ABSENCE',
      permission: 'canUseAbsenceManagement',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        userId,
        typeId,
        startDate,
        startDayPart,
        endDate,
        endDayPart,
        memberNote,
        admitterNote,
      },
      fn: (params) => api.createAbsence(params),
    });

export const rejectAbsence =
  ({ api, userId, crewId, typeId, startDate, endDate, startDayPart, endDayPart, admitterNote }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'REJECT_ABSENCE',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        userId,
        typeId,
        startDate,
        endDate,
        startDayPart,
        endDayPart,
        admitterNote,
      },
      fn: (params) => api.rejectAbsence(params),
    });

export const destroyAbsence =
  ({ api, crewId, userId, typeId, startDate, endDate, startDayPart, endDayPart }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'DESTROY_ABSENCE',
      fnParams: { crewId: Number.parseInt(crewId, 10), userId, typeId, startDate, endDate, startDayPart, endDayPart },
      fn: (params) => api.deleteAbsence(params),
    });

export const grantAbsence =
  ({ api, crewId, userId, typeId, startDate, endDate, startDayPart, endDayPart, admitterNote }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'GRANT_ABSENCE',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        userId,
        typeId,
        startDate,
        endDate,
        startDayPart,
        endDayPart,
        admitterNote,
      },
      fn: (params) => api.grantAbsence(params),
    });
