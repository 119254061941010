import { AnyAction } from 'redux';

/**
 * buildActionObject - Generates an object which can be used to call a typical reducer function
 *
 * @param {string} type action name (ex: 'LOAD_ABSENCES_SUCCESS')
 * @param {array or object} results data the one may want to store in the redux store
 * @param {object} defaultValues default values for when the results does not contain a certain property
 *
 * @return {object} object with all the action properties properly set
 */
export const buildActionObject = (
  type: string,
  results: Array<Record<string, unknown>>,
  defaultValues = {}
): AnyAction => {
  const sampleObject = Array.isArray(results) ? results[0] : results;

  return {
    ...sampleObject,
    ...defaultValues,
    result: results,
    type,
  };
};

export const makeActionCreator = (type: string) => (args = {}) => ({
  ...args,
  type,
});

export const toActionName = (namespace: string, actionName: string) =>
  namespace ? `${namespace}_${actionName}` : actionName;
