import { ComparableDurationType, DurationType, DurationTypes } from '@crewmeister/shared/src/types';
import { object as objectUtils } from '../utils';
const { pick } = objectUtils;

export const pickSelectiveFieldsToCompare = (duration: DurationType): ComparableDurationType => {
  return pick(['type', 'value', 'crewId', 'userId', 'from', 'to'], duration);
};

export const areDurationsEqual = (oldDurations: DurationType[], newDurations: DurationType[]): boolean => {
  // To make sure there is an actual difference between 2 durations
  // only need to compare the types: work, workDifference or break.
  // as any change in stamps would have effect on at least one of these.
  const newComparableDurationsType = newDurations.filter((duration: DurationType) =>
    [DurationTypes.WORK, DurationTypes.WORK_DIFFERENCE, DurationTypes.BREAK].includes(duration.type)
  );
  const oldComparableDurations = oldDurations.filter((duration: DurationType) =>
    [DurationTypes.WORK, DurationTypes.WORK_DIFFERENCE, DurationTypes.BREAK].includes(duration.type)
  );
  return (
    JSON.stringify(oldComparableDurations.map(pickSelectiveFieldsToCompare)) ===
    JSON.stringify(newComparableDurationsType.map(pickSelectiveFieldsToCompare))
  );
};
