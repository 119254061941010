const smallestMobilePortraitModeMax = '@media screen and (max-width: 399px)';
const smallestMobilePortraitModeMin = '@media screen and (min-width: 400px)';
const mediumMobilePortraitModeMax = '@media screen and (max-width: 490px)';
const mediumMobilePortraitModeMin = '@media screen and (min-width: 491px)';
const largeMobilePortraitModeMax = '@media screen and (max-width: 560px)';
const mobilePortraitMode = '@media screen and (max-width: 640px)';
const desktopMode = '@media screen and (min-width: 641px)';
const ipadLandscapeMode = '@media screen and (max-width: 1024px)';
const ipadPortraitModeMin = '@media screen and (min-width: 768px)';
const ipadPortraitModeMax = '@media screen and (max-width: 768px)';
const tabletPortraitModeMax = '@media screen and (max-width: 960px)';
const tabletPortraitModeMin = '@media screen and (min-width: 961px)';
const largeTabletPortraitModeMax = '@media screen and (max-width: 1020px)';
const largeTabletPortraitModeMin = '@media screen and (min-width: 1021px)';
const laptopModeMax = '@media screen and (max-width: 1200px)';
const laptopMediumModeMax = '@media screen and (max-width: 1399px)';
const laptopMediumModeMin = '@media screen and (min-width: 1400px)';
const largeMonitorModeMax = '@media screen and (max-width: 1799px)';
const largeMonitorModeMin = '@media screen and (min-width: 1800px)';

export const BREAKPOINTS = {
  smallestMobilePortraitModeMax,
  smallestMobilePortraitModeMin,
  mediumMobilePortraitModeMax,
  mediumMobilePortraitModeMin,
  largeMobilePortraitModeMax,
  mobilePortraitMode,
  desktopMode,
  ipadLandscapeMode,
  ipadPortraitModeMin,
  ipadPortraitModeMax,
  tabletPortraitModeMax,
  tabletPortraitModeMin,
  largeTabletPortraitModeMax,
  largeTabletPortraitModeMin,
  laptopModeMax,
  laptopMediumModeMax,
  laptopMediumModeMin,
  largeMonitorModeMax,
  largeMonitorModeMin,
};
