import React from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import * as Fonts from '../../styles/fonts';
import { COLORS, THEMES } from '../../constants';
import { duration as durationUtils } from '../../utils';
import Icon from './icon';

const { formatIsoDuration } = durationUtils;

export const SecondaryDuration = ({ duration, icon, theme, alignment }) => {
  const styles = createStylesWithThemes(alignment)[theme] || createStylesWithThemes(alignment).orange;

  return (
    <div className={css(styles.wrapper)}>
      <If condition={theme !== THEMES.TURQUOISE_WHITE}>
        <Icon name={icon} className={css(styles.icon)} />
      </If>
      <div className={css(styles.default)}>{formatIsoDuration(duration)}</div>
    </div>
  );
};

const createStylesWithThemes = (alignment) => ({
  [THEMES.ORANGE]: StyleSheet.create({
    wrapper: {
      textAlign: 'center',
      marginBottom: '20px',
      marginTop: alignment === 'vertical' ? 36 : 'unset',
    },
    default: {
      ...Fonts.base,
      fontSize: '18px',
      color: COLORS.ORANGE_MAIN,
    },
    icon: {
      fontSize: '25px',
      textAlign: 'center',
      color: COLORS.ORANGE_MAIN,
      marginBottom: '10px',
      display: 'block',
    },
  }),
  [THEMES.TURQUOISE]: StyleSheet.create({
    wrapper: {
      textAlign: 'center',
      marginBottom: '20px',
      marginTop: alignment === 'vertical' ? 36 : 'unset',
    },
    default: {
      ...Fonts.base,
      fontSize: '18px',
      color: COLORS.TURQUOISE_MEDIUM,
    },
    icon: {
      fontSize: '25px',
      textAlign: 'center',
      color: COLORS.TURQUOISE_MAIN,
      marginBottom: '10px',
      display: 'block',
    },
  }),
  [THEMES.TURQUOISE_WHITE]: StyleSheet.create({
    wrapper: {
      textAlign: 'center',
      marginBottom: '20px',
      marginTop: alignment === 'vertical' ? 36 : 'unset',
    },
    default: {
      ...Fonts.base,
      color: COLORS.WHITE,
      fontSize: 24,
      marginTop: 31,
    },
    icon: {
      fontSize: '25px',
      textAlign: 'center',
      color: COLORS.TURQUOISE_MAIN,
      marginBottom: '10px',
      display: 'block',
    },
  }),
});

export default SecondaryDuration;
