import { memoize } from 'redux-memoize';
import { factories } from '@crewmeister/shared';
import { dispatchAsyncFnStatusActions } from '../lib';

const { createTimeTrackingSettings } = factories;

export const loadCrewSettings = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_CREW_SETTINGS',
        fnParams: { crewId },
        fn: (params) => api.getCrewSettings(params),
      })
);

export const loadMemberSettings = memoize(
  {},
  ({ api, crewId, userId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_MEMBER_SETTINGS',
        fnParams: { crewId, userId },
        fn: (params) => api.getMemberSettings(params),
      })
);

export const deleteMemberSettings = memoize(
  {},
  ({ api, crewId, userId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'DELETE_MEMBER_SETTINGS',
        fnParams: { crewId, userId },
        fn: (params) => api.deleteMemberSettings(params),
      })
);

export const updateCrewSettings =
  ({
    api,
    crewId,
    chatEnabled,
    terminalViewMode,
    terminalAuthenticationMode,
    timeTrackingAllowedMobile,
    timeTrackingAllowedTerminal,
    timeTrackingAllowedOther,
    absenceManagementSeeAllAbsences,
    shiftPlannerSeeAllShifts,
    shiftPlannerGracePeriodBegin,
    shiftPlannerGracePeriodEnd,
    createStampChainTimeLimit,
    editStampChainTimeLimit,
    absenceTypes,
  }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_CREW_SETTINGS',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        chatEnabled,
        terminalViewMode,
        terminalAuthenticationMode,
        timeTrackingAllowedMobile,
        timeTrackingAllowedTerminal,
        timeTrackingAllowedOther,
        shiftPlannerGracePeriodBegin,
        shiftPlannerGracePeriodEnd,
        absenceManagementSeeAllAbsences,
        shiftPlannerSeeAllShifts,
        createStampChainTimeLimit,
        editStampChainTimeLimit,
        absenceTypes,
      },
      fn: (params) => api.updateCrewSettings(params),
    });

export const updateMemberSettings =
  ({
    api,
    crewId,
    userId,
    timeTrackingAllowedMobile,
    timeTrackingAllowedTerminal,
    timeTrackingAllowedOther,
    absenceManagementSeeAllAbsences,
    shiftPlannerSeeAllShifts,
    createStampChainTimeLimit,
    editStampChainTimeLimit,
    absenceTypes,
  }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_MEMBER_SETTINGS',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        userId: Number.parseInt(userId, 10),
        timeTrackingAllowedMobile,
        timeTrackingAllowedTerminal,
        timeTrackingAllowedOther,
        absenceManagementSeeAllAbsences,
        shiftPlannerSeeAllShifts,
        createStampChainTimeLimit,
        editStampChainTimeLimit,
        absenceTypes,
      },
      fn: (params) => api.updateMemberSettings(params),
    });

export const loadBlankTimeTrackingSettings =
  ({ crewId, userId, crewSettings = {} }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'LOAD_BLANK_TIME_TRACKING_SETTINGS',
      fnParams: { ...crewSettings, crewId, userId },
      fn: (params) => Promise.resolve(createTimeTrackingSettings(params)),
    });

export const clearBlankTimeTrackingSettings = () => (dispatch) => {
  dispatch({
    type: 'REMOVE_BLANK_TIME_TRACKING_SETTINGS',
  });
};
