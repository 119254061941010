import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Modal from '../../components/core/modal';
import TimeCategoryUpdate from '../../containers/time-category-update';
import Badge from '../../components/core/badge';
import Heading from '../../components/core/heading';
import P from '../../components/core/paragraph';
import Tabs from '../../components/core/tabs';
import Toggle from '../../components/core/ToggleV1';
import { IDS_FOR_GOOGLE_TAG_MANAGER } from '../../constants';

import { string } from '../../utils';

import { StyleSheet } from '../../_external-deps/stylesheet';

import t from './translate';

const { sliceString } = string;

const FIRST_TIME_CATEGORY = 1;
const SECOND_TIME_CATEGORY = 2;

export const Organism = (props) => {
  const {
    id,
    canSeeSecondTimeCategory,
    Component,
    customTabName = false,
    selectedTimeCategory1Id,
    selectedTimeCategory2Id,
    setSelectedTimeCategory1Id,
    setSelectedTimeCategory2Id,
    showHeader = false,
    showBadge = false,
    showSecondTab = true,
    tabButtonAlignCenter = false,
    tabButtonWithPadding = false,
    tabContainerFullWidth = false,
    timeCategories = [],
  } = props;

  const [selectedTimeCategoryForUpdateModal, setSelectedTimeCategoryForUpdateModal] = useState({});
  const [isTimeCategoryUpdateModalOpen, setIsTimeCategoryUpdateModalOpen] = useState(false);
  const onCloseModal = () => {
    setIsTimeCategoryUpdateModalOpen(false);
  };

  useEffect(() => {
    const firstTimeCategory = timeCategories.find(
      (category) => category.level === FIRST_TIME_CATEGORY && category.id === selectedTimeCategory1Id
    );
    const secondTimeCategory = timeCategories.find(
      (category) => category.level === SECOND_TIME_CATEGORY && category.id === selectedTimeCategory2Id
    );

    if (!firstTimeCategory && setSelectedTimeCategory1Id) {
      setSelectedTimeCategory1Id(null);
    }
    if (!secondTimeCategory && setSelectedTimeCategory2Id) {
      setSelectedTimeCategory2Id(null);
    }
  }, []);

  const BadgeComponent = showBadge ? Badge : React.Fragment;
  const propsForBadgeComponent = showBadge
    ? {
        style: {
          backgroundColor: 'unset',
          boxShadow: 'unset',
          padding: '0',
        },
      }
    : {};
  const [hideDisabled, setHideDisabled] = useState(false);

  const firstTimeCategoryName = (
    timeCategories.find(
      (category) => category.level === FIRST_TIME_CATEGORY && category.id === selectedTimeCategory1Id
    ) ||
    timeCategories.find((category) => category.level === FIRST_TIME_CATEGORY && category.id === null) ||
    {}
  ).name;

  const secondTimeCategoryName = (
    timeCategories.find(
      (category) => category.level === SECOND_TIME_CATEGORY && category.id === selectedTimeCategory2Id
    ) ||
    timeCategories.find((category) => category.level === SECOND_TIME_CATEGORY && category.id === null) ||
    {}
  ).name;

  const timeCategoryComponentProps = {
    ...props,
    Component,
    hideDisabled,
    id,
    setIsTimeCategoryUpdateModalOpen,
    setSelectedTimeCategoryForUpdateModal,
  };
  const TimeCategory1Component = generateTimeCategoryComponent({
    ...timeCategoryComponentProps,
    level: FIRST_TIME_CATEGORY,
  });
  const TimeCategory2Component = generateTimeCategoryComponent({
    ...timeCategoryComponentProps,
    level: SECOND_TIME_CATEGORY,
  });

  const tabItems = [
    {
      label: customTabName ? sliceString(firstTimeCategoryName, 18) : t('firstTimeCategory'),
      toolTip: customTabName && firstTimeCategoryName?.length > 18 && firstTimeCategoryName,
      placeholder: customTabName ? t('firstTimeCategory') : '',
      id: id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.TIME_CATEGORIES.FIRST_TIME_CATEGORY,
      Component: TimeCategory1Component,
    },
  ];
  if (showSecondTab)
    tabItems.push({
      label: customTabName ? sliceString(secondTimeCategoryName, 18) : t('secondTimeCategory'),
      toolTip: customTabName && secondTimeCategoryName?.length > 18 && secondTimeCategoryName,
      placeholder: customTabName ? t('secondTimeCategory') : '',
      id: id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.TIME_CATEGORIES.SECOND_TIME_CATEGORY,
      Component: TimeCategory2Component,
    });

  return (
    <React.Fragment>
      <Modal halfscreen isOpen={isTimeCategoryUpdateModalOpen} onClose={onCloseModal} padding showCloseButton top>
        <TimeCategoryUpdate
          {...props}
          level={selectedTimeCategoryForUpdateModal.level}
          timeCategories={timeCategories}
          timeCategory={selectedTimeCategoryForUpdateModal}
          setSelectedTimeCategory={setSelectedTimeCategoryForUpdateModal}
        />
      </Modal>

      <If condition={showHeader}>
        <Heading spaceBetween>
          {t('heading')}
          <Toggle
            onChange={() => setHideDisabled(!hideDisabled)}
            checked={hideDisabled}
            placeholderLeft={hideDisabled ? t('hideDisabledCategories') : t('showDisabledCategories')}
          />
        </Heading>
        <P small>{t('description')}</P>
      </If>
      <BadgeComponent {...propsForBadgeComponent}>
        <Choose>
          <When condition={canSeeSecondTimeCategory}>
            <Tabs
              items={tabItems}
              onChange={props.onTabChange}
              propsForComponent={props}
              selectedTabIndex={props.selectedTabIndex}
              tabButtonStyle={[
                styles.tabButton,
                tabButtonWithPadding ? styles.tabButtonWithPadding : styles.empty,
                tabButtonAlignCenter ? styles.tabButtonAlignedCenter : styles.tabButtonAlignedLeft,
              ]}
              navigationContainerStyle={!tabContainerFullWidth && styles.navigationContainer}
            />
          </When>
          <Otherwise>
            <TimeCategory1Component />
          </Otherwise>
        </Choose>
      </BadgeComponent>
    </React.Fragment>
  );
};

const generateTimeCategoryComponent =
  ({
    Component,
    hideDisabled,
    id,
    level,
    setIsTimeCategoryUpdateModalOpen,
    setSelectedTimeCategoryForUpdateModal,
    ...props
  }) =>
  () =>
    (
      <Component
        {...props}
        hideDisabled={hideDisabled}
        id={
          id +
          IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.TIME_CATEGORIES[
            level === 1 ? 'FIRST_TIME_CATEGORY' : 'SECOND_TIME_CATEGORY'
          ]
        }
        level={level === 1 ? FIRST_TIME_CATEGORY : SECOND_TIME_CATEGORY}
        setIsTimeCategoryUpdateModalOpen={setIsTimeCategoryUpdateModalOpen}
        setSelectedTimeCategoryForUpdateModal={setSelectedTimeCategoryForUpdateModal}
      />
    );

const styles = StyleSheet.create({
  empty: {},
  navigationContainer: { padding: '0 26px 0 0' },
  tabButton: { flex: 1 },
  tabButtonAlignedCenter: {
    textAlign: 'center',
  },
  tabButtonAlignedLeft: {
    justifyContent: 'left',
  },
  tabButtonWithPadding: {
    padding: '12.5px 0',
  },
});

Organism.propTypes = {
  crewId: PropTypes.number.isRequired,
};

export default Organism;
