import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadStamps = memoize(
  {},
  ({ api, crewId, userIds, startTime, endTime }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_STAMPS',
        fnParams: { crewId, startTime, endTime, ...(userIds ? { userIds: userIds.join(',') } : {}) },
        fn: (params) => api.timestamps(params),
      })
);
