import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

const currentYear = new Date().getFullYear();

export const loadAbsenceEntitlementsForCrew = memoize(
  {},
  ({ api, crewId, year = currentYear }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_ABSENCE_ENTITLEMENTS',
        permission: 'canUseAbsenceManagement',
        fnParams: { crewId: Number.parseInt(crewId, 10), year },
        fn: (params) => api.crewAbsenceEntitlements(params),
      })
);

export const loadAbsenceEntitlementsForUser = memoize(
  {},
  ({ api, crewId, userId, year = currentYear }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_ABSENCE_ENTITLEMENTS',
        permission: 'canUseAbsenceManagement',
        fnParams: { crewId: Number.parseInt(crewId, 10), userId: Number.parseInt(userId, 10), year },
        fn: (params) => api.crewAbsenceEntitlements(params),
      })
);

export const setAbsenceEntitlements =
  ({ api, crewId, userId, typeId, year, number }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'SET_ABSENCE_ENTITLEMENTS',
      permission: 'canUseAbsenceManagement',
      fnParams: { crewId: Number.parseInt(crewId, 10), userId, year, typeId, number },
      fn: (params) => api.setAbsenceEntitlement(params),
      afterSuccessFn: () => dispatch(loadAbsenceEntitlementsForCrew.unmemoized({ api, crewId, year })),
    });
