import produce from 'immer';
import { createSelector } from 'reselect';
import { endOfDay } from 'date-fns';
import { getItem } from '../lib';
import { UI_STATE_KEY_CURRENT_DATE } from '../action-creators/ui-actions';

export const reloadTimeDurations = (state) => state.ui.ui.reloadTimeDurations;
export const reloadTimeDurationsForDays = (state) => state.ui.ui.reloadTimeDurationsForDays;
export const reloadDurationBalances = (state) => state.ui.ui.reloadDurationBalances;
export const reloadDurationBalancesForDays = (state) => state.ui.ui.reloadDurationBalancesForDays;
export const readUiValue = (state, key) => state.ui.ui[key];

const asNumber = (strNumber) => parseInt(strNumber, 10);

export const selectCrewId = (ids = []) =>
  ids.includes(asNumber(getItem('defaultCrewId'))) ? getItem('defaultCrewId') : ids[0];

export const extractCrewIdFromPath = (pathname = '') =>
  pathname.indexOf('crew/') !== -1 ? parseInt(pathname.match(/\d+/)[0], 10) : void 0;

export const currentTimeSelector = (state) => state.ui.ui[UI_STATE_KEY_CURRENT_DATE];

export const endOfDayForCurrentTimeSelector = createSelector(currentTimeSelector, (currentTime) =>
  endOfDay(currentTime)
);

export const selectShiftsToExclude = (state) => state.ui.shiftsToPublish.excludedGuids || produce([], () => {});
