import { constants } from '@crewmeister/shared';
import React, { CSSProperties, ReactElement } from 'react';

import { ICON_NAMES, THEMES } from '../../constants';
import { COLORS } from '../../styles/config';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

const { AVAILABLE_ICONS } = constants;

type IconThemes = THEMES.ORANGE | THEMES.DEFAULT | THEMES.TURQUOISE | 'unset';

const THEME_COLOR = {
  [THEMES.DEFAULT]: COLORS.GREY_MAIN,
  [THEMES.ORANGE]: COLORS.ORANGE_MEDIUM,
  [THEMES.TURQUOISE]: COLORS.TURQUOISE_MAIN,
  unset: 'unset',
};

type IconProps = {
  className?: string;
  clickable?: boolean;
  disabled?: boolean;
  id?: string;
  name: ICON_NAMES;
  noHover?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
  theme?: IconThemes;
};

const Icon: (props: IconProps) => ReactElement = ({
  className = '',
  clickable,
  disabled,
  id,
  name,
  noHover,
  onClick,
  style,
  theme,
}) => {
  if (!(name in AVAILABLE_ICONS)) {
    // eslint-disable-next-line no-console
    console.warn(`Icon '${name}' not found.`);
  }

  const styles = getStyles({ theme });

  return (
    <span
      id={id}
      onClick={disabled ? () => {} : onClick}
      className={`${css(
        styles.default,
        (onClick || clickable) && styles.clickable,
        noHover && styles.noHover
      )} ${className}`}
      style={style}
    >
      {AVAILABLE_ICONS[name]}
    </span>
  );
};

const getStyles = ({ theme = 'unset' }: { theme?: IconThemes }) =>
  StyleSheet.create({
    default: {
      color: THEME_COLOR[theme],
      fontFamily: 'crewmeistericons',
      fontDisplay: 'swap',
    },
    clickable: {
      cursor: 'pointer',
      ':hover': {
        color: COLORS.ORANGE_MAIN,
        transition: 'color 100ms linear',
      },
      ':active': {
        color: COLORS.ORANGE_DARK,
      },
    },
    noHover: {
      ':hover': {
        color: COLORS.GREY_MAIN,
      },
    },
  });

export default Icon;
