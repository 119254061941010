import { compareAsc } from 'date-fns';
import { add } from 'pomeranian-durations';
import { clone, compose } from './collections';
import { toCorrelated } from './stamp';
import { TIME_ACCOUNTS } from '../constants';

export const matchBookingList = (bookings) =>
  sortBookings(bookings).reduce((acc, booking) => {
    if (booking.timeAccount === TIME_ACCOUNTS.START_WORK) {
      acc.push({ workBooking: booking, breakBooking: null });
    }
    if (booking.timeAccount === TIME_ACCOUNTS.BREAK) {
      const lastBooking = (acc[acc.length - 1] || {}).breakBooking;
      if (lastBooking) {
        lastBooking.duration = add(lastBooking.duration, booking.duration);
        lastBooking.additionalData.to = booking.additionalData.to;
      } else {
        (acc[acc.length - 1] || {}).breakBooking = booking; // eslint-disable-line no-param-reassign
      }
    }
    return acc;
  }, []);

/**
 * matchBookings - Groups work and break bookings for a given user considering its from date
 *
 * @param {array} bookings
 *
 * @return {array} returns an array of bookings grouped by workBooking and breakBooking
 *
 */
export const matchBookings = (bookings) =>
  compose(toCorrelated, (lists) => lists.reduce((acc, bookingList) => [...matchBookingList(bookingList), ...acc], []))(
    clone(bookings)
  );

/**
 * getWorkBreakBookings - Filter bookings by time account and only returns working and break bookings
 *
 * @param {array} bookings
 *
 * @return {array} returns an array of bookings with given time accounts
 *
 */
export const getWorkBreakBookings = (bookings) =>
  bookings.filter(
    (booking) => booking.timeAccount === TIME_ACCOUNTS.WORKING || booking.timeAccount === TIME_ACCOUNTS.BREAK
  );

export const sortBookings = (bookings) =>
  bookings.sort(
    (a, b) =>
      compareAsc(new Date(a.additionalData.from), new Date(b.additionalData.from)) || a.timeAccount - b.timeAccount
  );
