import React from 'react';
import { StyleSheet, css } from '../_external-deps/stylesheet';
import { COLORS, BREAKPOINTS } from '../constants';

const AuthNavigation = ({ children }) => (
  <div className={css(styles.background)}>
    <div className={css(styles.modal)}>{children}</div>
  </div>
);

const styles = StyleSheet.create({
  background: {
    width: '100vw',
    height: '100vh',
    background: 'url(/assets/images/signin-bg.jpg)',
    backgroundSize: 'cover',
  },
  modal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: COLORS.EGGSHELL_MAIN,
    padding: '30px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.2)',
    [BREAKPOINTS.mobilePortraitMode]: {
      maxWidth: '9999px',
      position: 'static',
      transform: 'translate(0, 0)',
    },
  },
});

export default AuthNavigation;
