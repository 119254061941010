import bowser from 'bowser';

// When Bowser can't detect the browser we assume it is our phonegap app.

export const userAgent = (window || {}).navigator && window.navigator.userAgent;
const browser = bowser.getParser(userAgent);

export const platform = browser.getPlatform().type;
export const channel = 'other';
export const version = browser.getBrowserVersion();
export const osname = browser.getOSName();
export const isAndroid = window.navigator.userAgent.indexOf('Android') >= 0;
export const isCordova = /Crewmeister Cordova/i.test(userAgent) || (osname === 'iOS' && !version);
export const name = !isCordova ? browser.getBrowserName() : 'cordova';

export const Browser = { name, version };
export default { name, version };
