/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction, buildDangerouslyTranslateNeverWithUserContent } from '../../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    resetPassword: 'Passwort vergessen?',
    signIn: 'Zurück zum Login',
    signUp: 'Neue Testphase starten',
  },
  en: {
    resetPassword: 'Forgot password?',
    signIn: 'Back to sign in',
    signUp: 'Start new trial',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
