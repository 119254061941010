import Cookies from 'js-cookie';

const EXPIRY_DATE = new Date('9999-12-31');
export class CookieStore {
  setToken(name, value) {
    this._setCookie(this._generateCookieName(name, 'id'), value.id);
    this._setCookie(this._generateCookieName(name, 'token'), value.token);
    return Promise.resolve(value);
  }

  readToken(name) {
    return Promise.resolve({
      id: this._generateCookieName(name, 'id'),
      token: this._generateCookieName(name, 'token'),
    });
  }

  removeToken(name) {
    this._removeCookie(this._generateCookieName(name, 'id'));
    this._removeCookie(this._generateCookieName(name, 'token'));
  }

  toHeaders() {
    const cookies = Cookies.get();
    const headers = Object.keys(cookies).reduce((result, key) => {
      if (!key.startsWith('cmAuthentication')) {
        return result;
      }
      result[`X-${key}`] = cookies[key]; // eslint-disable-line no-param-reassign
      return result;
    }, {});
    return Promise.resolve(headers);
  }

  _getDomain = () => {
    return window.location.hostname.split('.').slice(-2).join('.');
  };

  _capitalize(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  _generateCookieName(name, type) {
    return `cmAuthentication${this._capitalize(name)}${this._capitalize(type)}`;
  }
  _setCookie(name, value) {
    Cookies.set(name, value, {
      domain: this._getDomain(),
      expires: EXPIRY_DATE,
    });
  }
  _removeCookie(name) {
    Cookies.remove(name, {
      domain: this._getDomain(),
      expires: EXPIRY_DATE,
    });
  }
}
