import { createSelector } from 'reselect';
import { isSameYear } from 'date-fns';
import { selectAbsencesByProps } from './absences-selectors';
import { createPropsSelector } from './utils';

export const selectAbsenceDays = createSelector(selectAbsencesByProps, (absences) =>
  absences.reduce((absenceDays, absence) => {
    const daysForAbsence = (absence.absenceDays || []).map((absenceDay) => ({
      ...absenceDay,
      typeId: absence.typeId,
      userId: absence.userId,
      crewId: absence.crewId,
    }));
    return absenceDays.concat(daysForAbsence);
  }, [])
);

export const selectAbsenceDaysByProps = createPropsSelector(selectAbsenceDays);

export const selectAbsenceDaysByPropsAndYear = createSelector(
  selectAbsenceDaysByProps,
  (_, props) => props.year,
  (absenceDays, year) => absenceDays.filter((absenceDay) => isSameYear(new Date(absenceDay.date), new Date(year, 0, 1)))
);

export const sumAbsenceDaysForPropsAndYear = createSelector(selectAbsenceDaysByPropsAndYear, (absenceDays) =>
  absenceDays.reduce((sum, absenceDay) => sum + 1 / absenceDay.fraction, 0)
);

export const sumAbsenceDays = (absenceDays) =>
  absenceDays.reduce((sum, absenceDay) => sum + 1 / absenceDay.fraction, 0);
