import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadAbsenceDaysCount = memoize(
  {},
  ({ api, crewId, groupBy, userIds, typeIds, from, to }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_ABSENCE_DAYS_COUNT',
        fnParams: { crewId, groupBy, ...(userIds ? { userIds } : {}), ...(typeIds ? { typeIds } : {}), from, to },
        fn: (params) => api.getAbsenceDaysCount(params),
      })
);
