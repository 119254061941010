export enum NEWS_FORWARD {
  PUSH = 'push',
  MAIL = 'mail',
}

export type NewsSettingsType = Record<NEWS_SETTING_TYPE_NAME, ProfileSettingType>;

export enum NEWS_SETTING_TYPE_NAME {
  ABSENCE_MANAGEMENT = 'absenceManagement',
  SHIFT_PLANNER = 'shiftPlanner',
  TIME_TRACKING_REPORTS = 'timeTrackingReports',
}

export type ProfileSettingType = Record<string, ProfileSettingsItemType>;

export type ProfileSettingsItemType = {
  enabled: boolean;
  forward: Array<NEWS_FORWARD>;
};
