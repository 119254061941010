import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;

const initialState = produce({ rooms: [] }, () => {});

export const roomsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_USER_ROOMS_SUCCESS':
        applyMinimalChangesToArray(draft.rooms, action.result, (room) =>
          action.result.map((r) => r.id).includes(room.id)
        );
        break;
      case 'CREATE_ROOM_SUCCESS':
      case 'ADDED_ROOM_SUCCESS':
      case 'UPDATE_ROOM_SUCCESS':
        applyMinimalChangesToArray(draft.rooms, action.result, (room) => action.result.id === room.id);
        break;
      case 'ADD_USERS_TO_ROOM_SUCCESS':
      case 'UPDATED_ROOM_SUCCESS':
      case 'REMOVE_USERS_FROM_ROOM_SUCCESS':
        applyMinimalChangesToArray(draft.rooms, action.result, (room) => action.result.id === room.id);
        break;
      case 'DELETE_ROOM_SUCCESS':
      case 'REMOVED_ROOM_SUCCESS':
        applyMinimalChangesToArray(draft.rooms, [], (room) => action.result.id === room.id);
        break;
      case 'CLEAR_APP_STATE':
        return initialState;
      default:
    }
  });
