import { createSelector } from 'reselect';
import { compareAsc } from 'date-fns';
import { createPropsFinder, createPropsSelector } from './utils';

export const selectDurationBalances = createSelector(
  (state) => state.bo.durationBalances.durationBalances,
  (durationBalances) => durationBalances.concat().sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))
);

export const selectDurationBalancesInCurrentCrewForGivenUser = createSelector(
  selectDurationBalances,
  (_, props) => props.crewId,
  (_, props) => props.userId,
  (items, crewId, userId) =>
    items.filter((item) => item.crewId === crewId && item.groupBy && item.groupBy.userId === userId)
);

export const selectDurationBalancesByProps = createPropsSelector(selectDurationBalances);
export const findDurationBalancesByProps = createPropsFinder(selectDurationBalances);
