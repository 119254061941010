import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import Icon from './icon';
import { COLORS, FONT_STYLES, Z_INDEX } from '../../constants';
import { isTouchDevice } from '../../utils';

const TooltipText = ({ isOpen, children }) => (isOpen ? children : null);

class IconTooltip extends React.Component {
  state = { isOpen: false };

  openTooltip = () => this.setState({ isOpen: true });
  closeTooltip = () => this.setState({ isOpen: false });
  toggleTooltip = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const toggleInfoEvents = isTouchDevice()
      ? { onClick: this.toggleTooltip }
      : { onMouseEnter: this.openTooltip, onMouseLeave: this.closeTooltip };

    const {
      bigText,
      children,
      defaultAlignment = true,
      iconName,
      invertedColors,
      mediumText,
      small,
      tiny,
      tinyText,
    } = this.props;

    return (
      <div {...toggleInfoEvents} className={this.props.wrapperClassName}>
        <Icon
          name={iconName}
          className={`${css(
            styles.icon,
            tiny && styles.tiny,
            small && styles.small,
            tinyText && styles.infoTextTiny,
            mediumText && styles.infoTextMedium,
            bigText && styles.infoTextBig,
            this.state.isOpen && styles.iconOpen,
            invertedColors && styles.iconInverted
          )} ${this.props.iconClassName}`}
        />
        <TooltipText isOpen={this.state.isOpen}>
          <div className={css([styles.increaseMouseOverArea, defaultAlignment && styles.defaultAlignment])}>
            <div className={`${css(styles.infoText, (tiny || small) && styles.infoTextSmall)} ${this.props.className}`}>
              {children}
            </div>
          </div>
        </TooltipText>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  icon: {
    color: COLORS.ORANGE_MAIN,
    border: `2px solid ${COLORS.ORANGE_MAIN}`,
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconInverted: {
    backgroundColor: COLORS.ORANGE_LIGHT,
    color: COLORS.ORANGE_MAIN,
    borderColor: COLORS.ORANGE_LIGHT,
  },
  tiny: {
    width: 24,
    height: 24,
  },
  small: {
    width: 30,
    height: 30,
  },
  iconOpen: {
    backgroundColor: COLORS.ORANGE_MAIN,
    color: COLORS.WHITE,
  },
  infoText: {
    ...FONT_STYLES.medium,
    lineHeight: 1.4,
    background: COLORS.WHITE,
    padding: 20,
    boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.2)',
  },
  infoTextTiny: {
    fontSize: 16,
  },
  infoTextSmall: {
    ...FONT_STYLES.small,
    padding: 10,
  },
  infoTextMedium: {
    fontSize: 18,
  },
  infoTextBig: {
    fontSize: 22,
  },
  increaseMouseOverArea: {
    position: 'absolute',
    maxWidth: 500,
    zIndex: Z_INDEX.OVERLAY,
    marginTop: -40,
    paddingTop: 50,
  },
  defaultAlignment: {
    right: 10,
  },
});

IconTooltip.propTypes = {
  bigText: PropTypes.string,
  children: PropTypes.node.isRequired,
  defaultAlignment: PropTypes.bool,
  iconName: PropTypes.string,
  invertedColors: PropTypes.bool,
  mediumText: PropTypes.bool,
  small: PropTypes.bool,
  tiny: PropTypes.bool,
  tinyText: PropTypes.bool,
};

export default IconTooltip;
