import { ignoreReturnFor, rethrowError } from 'promise-frites';
import React from 'react';

import { COLORS, FONT_STYLES } from '../../constants';
import { FC } from '../../types';
import { collections, react } from '../../utils';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

const { composeReverse } = collections;
const { withUIState, prepareProp } = react;

type TemplateProps = {
  checked: boolean;
  colors: Record<string, string | number>;
  customStyles: Record<string, string | number>;
  disabled: boolean;
  id: string;
  loading: boolean;
  name: string;
  onChange: (value: boolean) => void;
  onClick: () => void;
  placeholderLeft: string;
  placeholderRight: string;
  placeholderStyle: Record<string, string | number>;
  toggleClassName: string;
};

const Template: FC<TemplateProps> = ({
  checked,
  colors,
  customStyles = {},
  disabled = false,
  id,
  loading = false,
  name,
  onChange,
  onClick,
  placeholderLeft,
  placeholderRight,
  placeholderStyle,
  toggleClassName,
}) => {
  return (
    <label className={css(styles.wrapper, (disabled || loading) && styles.toggleDisabled, customStyles.wrapper)}>
      {!!placeholderLeft && (
        <span
          className={css(
            styles.placeholder,
            placeholderStyle,
            customStyles.placeholderLeft,
            checked && customStyles.placeholderLeftChecked
          )}
        >
          {placeholderLeft}
        </span>
      )}

      <div
        className={`${toggleClassName} ${css(styles.toggle, checked && styles.toggleChecked)}`}
        style={colors && { background: checked ? colors.toggleChecked : colors.toggle }}
        id={`${id}-container`}
      >
        <input
          id={`${id}-input`}
          name={name}
          type="checkbox"
          checked={!!checked}
          className={css(styles.checkbox)}
          disabled={disabled || loading}
          onChange={(evt) => onChange(evt.target.checked)}
          onClick={onClick}
        />
        <span
          className={css(
            styles.slider,
            checked && styles.sliderChecked,
            loading && styles.loading,
            customStyles.slider
          )}
          style={colors && { background: checked ? colors.sliderChecked : colors.slider }}
        />
      </div>

      {!!placeholderRight && (
        <span
          className={css(
            styles.placeholder,
            placeholderStyle,
            customStyles.placeholderRight,
            checked && customStyles.placeholderRightChecked
          )}
        >
          {placeholderRight}
        </span>
      )}
    </label>
  );
};

type ToggleProps = {
  checked?: boolean;
  checkedWhenNoneGiven?: boolean;
  onChange?: (...param: Array<unknown>) => void;
  onCheckedChange?: (...param: Array<unknown>) => void;
  setLoading?: (param: boolean) => void;
};

const Toggle = composeReverse(
  withUIState('loading', 'setLoading', false),
  withUIState('checkedWhenNotGiven', 'onCheckedChange', false),
  prepareProp('onChange', (props: ToggleProps) => (...args: Array<unknown>) => {
    return Promise.resolve()
      .then(() => props.setLoading && props.setLoading(true))
      .then(() =>
        Promise.all([
          props.onCheckedChange && props.onCheckedChange(...args),
          props.onChange && props.onChange(...args),
        ])
      )
      .then(ignoreReturnFor(() => props.setLoading && props.setLoading(false)))
      .catch(rethrowError(() => props.setLoading && props.setLoading(false)));
  }),
  prepareProp('checked', (props: ToggleProps) => ('checked' in props ? props.checked : props.checkedWhenNoneGiven))
)(Template);

const styles = StyleSheet.create({
  toggle: {
    position: 'relative',
    display: 'inline-block',
    width: 30,
    height: 10,
    backgroundColor: COLORS.GREY_EXTRA_LIGHT,
    borderRadius: 6,
    marginBottom: 0,
    marginLeft: '3px',
    marginRight: '3px',
  },
  checkbox: {
    display: 'none',
  },
  slider: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    transition: 'all 200ms',
    background: COLORS.GREY_MEDIUM,
    borderRadius: '50%',
    height: 16,
    width: 16,
    left: -3,
    boxShadow: '1px 1px 2px 0 rgba(0, 0, 0, 0.25)',
  },
  sliderChecked: {
    background: COLORS.ORANGE_MAIN,
    left: '100%',
    marginLeft: 3,
    transform: 'translate(-100%, -50%)',
    borderRadius: '50%',
  },
  loading: {
    transform: 'translate(-50%, -50%)',
    left: '50%',
    marginLeft: 0,
    animationName: [
      {
        '0%': { boxShadow: 'rgba(179, 155, 136, 0.4) 0px 0px 0 0px' },
        '100%': { boxShadow: 'rgba(179, 155, 136, 0) 0px 0px 0 10px' },
      },
    ],
    animationDuration: '500ms',
    animationIterationCount: 'infinite',
  },
  toggleChecked: {
    background: COLORS.MAIN_COLOR_MEDIUM_LITE,
  },
  toggleDisabled: {
    opacity: 0.6,
    cursor: 'not-allowed',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  placeholder: {
    ...FONT_STYLES.medium,
    display: 'inline-block',
    paddingLeft: '10px',
    paddingRight: '10px',
    transition: 'padding 200ms',
  },
});

export default Toggle;
