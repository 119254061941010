import { factory, random, sequence } from 'stahlwerk';

export const createAbsenceEntitlement = factory({
  id: sequence(),
  crewId: sequence(),
  userId: sequence(),
  typeId: random([1, 2, 3]),
  number: random(),
  year: 2010,
  name: 'Sickness',
});
