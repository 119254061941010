/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction } from '../../lib/translate';

export default buildScopedTranslationFunction({
  de: {
    absence: 'Abwesenheit',
    breakSince: 'In der Pause seit {{since}}',
    emptyState: 'Für diesen Tag gibt es keine Aktivitäten in Ihrer Crew',
    lastActivity: 'Letzte Aktivität',
    lastUpdated: 'Letzte Aktualisierung',
    loading: 'Lade',
    missingMemberName: 'Gelöschter Nutzer',
    name: 'Name',
    notWorkingSince: 'Ausgestempelt seit {{since}}',
    pause: 'Pause',
    refresh: 'Automatisch aktualisieren',
    showInactive: 'Heute inaktive Mitarbeiter anzeigen',
    showMap: 'Karte anzeigen',
    since: 'seit',
    sortBy: 'Sortieren nach',
    till: 'bis',
    timeAtWork: 'Stempelzeit',
    timeAtWorkDescription: `
      Die Zeit, die der Mitarbeiter am heutigen Tag (00:00 - 24:00) eingestempelt
      ist. Automatische Pausen oder Rundungen fließen in die Stempelzeit nicht ein.
    `,
    tomorrow: 'morgen',
    working: 'Arbeitet',
    workingSince: 'Arbeitet seit {{since}}',
    workingSinceOnTimeCategory: '{{timeCategoryName}} seit {{since}}',
    yesterday: 'gestern',
  },
  en: {
    absence: 'Absence',
    breakSince: 'In break since {{since}}',
    emptyState: 'No activity in your crew on this day so far',
    lastActivity: 'Last Activity',
    lastUpdated: 'Last updated',
    loading: 'Loading',
    missingMemberName: 'Deleted user',
    name: 'Name',
    notWorkingSince: 'Clocked out since {{since}}',
    pause: 'Pause',
    refresh: 'Auto refresh',
    showInactive: 'Show employees inactive today',
    showMap: 'Show map',
    since: 'since',
    sortBy: 'Sort by',
    till: 'till',
    timeAtWork: 'Clocked time',
    timeAtWorkDescription: `
      The time an employee is clocked in on the current day (from 0-24 o'clock).
      Automatic breaks or rounding rules are not applied for the clocked time.
    `,
    tomorrow: 'tomorrow',
    working: 'Working',
    workingSince: 'Working since {{since}}',
    workingSinceOnTimeCategory: '{{timeCategoryName}} since {{since}}',
    yesterday: 'yesterday',
  },
});
