import { ISO8601Duration } from '../durations';

export type ShiftOffset = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export enum SHIFT_TYPE {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED_CREATE = 'UNPUBLISHED_CREATE',
  UNPUBLISHED_UPDATE = 'UNPUBLISHED_UPDATE',
  UNPUBLISHED_DELETE = 'UNPUBLISHED_DELETE',
}

export type V3Shift = {
  breakDuration: ISO8601Duration | null;
  breakStart: string | null;
  crewId: number;
  from: string;
  hasDraft?: boolean;
  id: number;
  legacyId: string | null;
  notes: string | null;
  publishedShiftId: number | null;
  shiftOffer: boolean;
  shiftOfferAssignmentStrategy: string | null;
  shiftOfferEligibleTeams?: number[];
  shiftOfferEligibleUsers?: number[];
  shiftOfferPublishStrategy: string | null;
  shiftOfferValidUntil: string | null;
  to: string;
  transactionID?: number;
  type: SHIFT_TYPE;
  userId: number | null;
  workplaceId: number | null;
  _sync?: { synced?: Date };
};
