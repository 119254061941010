import { FOLDERS, INPUT_TYPES, SETTING_ELEMENT_TYPE } from '../constants';

const INPUT_TYPES_ENUM = Object.values(INPUT_TYPES).map(({ name }) => name);
const SETTING_TYPE_ENUM = Object.values(SETTING_ELEMENT_TYPE).map((value) => value);
export const FOLDERS_ENUM = Object.values(FOLDERS).map((value) => value);

type SettingDefinition = {
  id: string;
  type: typeof INPUT_TYPES_ENUM[number];
  label: string;
};

type SubGroup = {
  settingDefinitions: SettingDefinition[];
};

type PrimarySetting = {
  defaultFilter: boolean;
  defaultSort: boolean;
  label: string;
  searchable: boolean;
  settingId: string;
  priority: string;
};

type Definition = {
  applicableForCrews: boolean;
  applicableForMembers: boolean;
  creatable: boolean;
  createSettingGroups: Array<Definition>;
  deletable: boolean;
  deleteInfo?: {
    headerText: string;
    buttonLabel: string;
  };
  description: string;
  descriptionMember: string;
  disabledSettingData: {
    title: string;
    content: string;
    cta: {
      text: string;
      link: string;
    };
  };
  id: string;
  primarySettings: Array<PrimarySetting>;
  reprocessWarning?: string;
  subGroups: SubGroup[];
  title: string;
  titleMember: string;
  updateSettingGroups: Array<Definition>;
};

export type Folder = typeof FOLDERS_ENUM[number];

export type Type = typeof SETTING_TYPE_ENUM[number];

export type SettingDefinitionType = {
  definition: Definition;
  folder: Folder;
  type: Type;
  language: 'en' | 'de';
};
