import { api as CmApi, CrewmeisterApiV3, config, statusCodes as cmStatusCodes } from '@crewmeister/shared';
import { getTranslationLanguage } from './translate';
import { getEnv, isLocalDevelopment } from '../utils';
import { history } from '../lib';
import { channel } from './browser';
import { reduxStore } from './redux-store';
import { setUiStateValue } from '../action-creators';

const errorHandler = (error) => {
  const pathnamesThatCanBeIgnored = ['sign-in', 'login', ''];

  switch (error.id) {
    case cmStatusCodes.NOT_AUTHENTICATED:
      reduxStore.dispatch(
        setUiStateValue({
          key: 'unauthenticatedModalOpen',
          value: !isLocalDevelopment && !pathnamesThatCanBeIgnored.includes(history.location.pathname.split('/')[1]),
        })
      );
      throw error;
    default:
      throw error;
  }
};

const setDomain = () => {
  const envDomain = getEnv(process.env.CREWMEISTER_API_DOMAIN, 'CREWMEISTER_API_DOMAIN');
  if (envDomain) {
    const apiDomain = envDomain.split('//');

    config.update({
      protocol: apiDomain[0],
      host: apiDomain[1],
    });
  }
};

const initializeApi = () => {
  setDomain();
  const _api = new CmApi();

  if (_api.setXLanguage) _api.setXLanguage(getTranslationLanguage()); // not initialised on tests
  if (_api.setCmChannel) _api.setCmChannel(channel);
  if (_api.setErrorHandler) _api.setErrorHandler(errorHandler);

  return _api;
};

const initializeV3Api = () => {
  const _api = new CrewmeisterApiV3();
  return _api;
};

export const api = initializeApi();
export const apiV3 = initializeV3Api();
export const statusCodes = cmStatusCodes;
