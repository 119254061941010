import { createSelector } from 'reselect';
import { selectCurrentCrewId } from './crews-selectors';

export const selectTeamsUnsorted = (state) => state.bo.teams.teams;

export const selectTeams = createSelector(selectTeamsUnsorted, (teams) =>
  teams.concat().sort((a, b) => a.name.localeCompare(b.name))
);

export const selectTeamsForCurrentCrew = createSelector(selectTeams, selectCurrentCrewId, (teams, crewId) =>
  teams.filter((team) => team.crewId === crewId)
);
