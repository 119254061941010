import React from 'react';

import Button from '../../../components/core/button';
import DateTimeInfo from '../../../components/core/date-time-info';
import Icon from '../../../components/core/icon';

import { COLORS, FONT_STYLES, IDS_FOR_GOOGLE_TAG_MANAGER, THEMES, TIME_ACCOUNTS } from '../../../constants';
import translate from '../../../lib/translate';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';

import t from '../translate';

import PrimaryDuration from './primary-duration';
import TimeCategoriesOverlay from './time-categories-overlay';

export const NotWorkingScreen = (props) => {
  const {
    id,
    alignment,
    canTrackTimes,
    disableStampWatch,
    displaySmall,
    onChangeTimeCategories,
    onStartWork,
    selectedTimeCategory1Id,
    selectedTimeCategory2Id,
    theme,
    timeCategories,
    wide,
    withoutButtons,
    withoutDateInfo,
    withoutTimeCategories,
    withoutTimeInfo,
    withoutTimeTrackingInfo,
    isIn3ColumnTerminalMode,
    dontShowDefaultTimeCategories,
  } = props;

  const styles = createStylesWithThemes(alignment)[theme];

  const dateTimeInfo = (
    <If condition={!displaySmall}>
      <DateTimeInfo
        date={new Date().toISOString()}
        theme={theme}
        withoutDateInfo={withoutDateInfo}
        withoutTimeInfo={withoutTimeInfo}
      />
    </If>
  );

  const selectableTimeCategories = timeCategories.map((timeCategory = {}) => ({
    value: timeCategory.id || '',
    label: timeCategory.name,
  }));

  return (
    <section className={css(styles.container)}>
      <If condition={!withoutDateInfo || !withoutTimeInfo}>{dateTimeInfo}</If>
      <If condition={!withoutTimeTrackingInfo}>
        <PrimaryDuration duration="PT0S" theme={theme} timeAccount={TIME_ACCOUNTS.NOT_WORKING} bold wide={wide} />
      </If>

      <If condition={!!timeCategories.length && !withoutTimeCategories && !dontShowDefaultTimeCategories}>
        <div className={css(styles.overlayContainer)}>
          <TimeCategoriesOverlay
            {...props}
            data-e2e-test={'stamp-watch-time-category-select'}
            disabled={!canTrackTimes || disableStampWatch || isIn3ColumnTerminalMode}
            iconName="tags"
            options={selectableTimeCategories}
            onChange={(timeCategory1Id, timeCategory2Id) =>
              onChangeTimeCategories({ timeCategory1Id, timeCategory2Id })
            }
          />
        </div>
      </If>

      <If condition={!canTrackTimes}>
        <span className={css(styles.disabledLegend)}>{t('disableInputWarning')}</span>
      </If>

      <If condition={!withoutButtons}>
        <Button
          className={css(styles.button)}
          disabled={!canTrackTimes || disableStampWatch}
          id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.START_WORK}
          inline
          onClick={() =>
            onStartWork({ timeCategory1Id: selectedTimeCategory1Id, timeCategory2Id: selectedTimeCategory2Id })
          }
          spacingTop
        >
          <If condition={theme === THEMES.TURQUOISE_WHITE}>
            <Icon name="clock-working" className={css(styles.icon)} />
          </If>
          <span data-e2e-test="stamp-watch-start-work">{translate('Start work')}</span>
        </Button>
      </If>
    </section>
  );
};

const createStylesWithThemes = (alignment) => ({
  [THEMES.ORANGE]: StyleSheet.create({
    button: {
      marginTop: '20px !important',
    },
    container: {
      width: '100%',
      textAlign: 'center',
    },
    disabledLegend: {
      ...FONT_STYLES.medium,
      textAlign: 'center',
      margin: '10px 0',
      color: 'red',
      display: 'block',
    },
    icon: {
      marginRight: 16,
    },
    overlayContainer: {
      marginTop: alignment === 'vertical' ? 36 : 'unset',
    },
  }),
  [THEMES.TURQUOISE]: StyleSheet.create({
    button: {
      backgroundColor: `${COLORS.TURQUOISE_MAIN} !important`,
      borderRadius: '88px !important',
      fontSize: '40px !important',
      height: '146px',
      marginTop: '150px !important',
      textTransform: 'none !important',
      width: '644px !important',
    },
    container: {
      width: '100%',
      textAlign: 'center',
    },
    disabledLegend: {
      ...FONT_STYLES.medium,
      textAlign: 'center',
      margin: '10px 0',
      color: 'red',
      display: 'block',
    },
    icon: {
      marginRight: 16,
    },
    overlayContainer: {
      marginTop: alignment === 'vertical' ? 36 : 'unset',
    },
  }),
  [THEMES.TURQUOISE_WHITE]: StyleSheet.create({
    button: {
      backgroundColor: `${COLORS.TURQUOISE_MAIN} !important`,
      borderRadius: '88px !important',
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2) !important',
      fontSize: '40px !important',
      height: '112px',
      marginTop: '150px !important',
      textTransform: 'none !important',
      width: '644px !important',
    },
    container: {
      width: '100%',
      textAlign: 'center',
    },
    disabledLegend: {
      ...FONT_STYLES.medium,
      textAlign: 'center',
      margin: '10px 0',
      color: 'red',
      display: 'block',
    },
    icon: {
      marginRight: 16,
    },
    overlayContainer: {
      marginTop: alignment === 'vertical' ? 36 : 'unset',
    },
  }),
});

export default NotWorkingScreen;
