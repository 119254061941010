import { statusCodes } from '@crewmeister/shared';
import get from 'lodash.get';
import { asSeconds } from 'pomeranian-durations';
import PropTypes from 'prop-types';
import React from 'react';
import { clearSyncedInterval, setSyncedInterval } from 'synced-interval';

import LoadingSpinner from '../../../components/core/loading-spinner-with-text';

import { LOCATION_OPTIONS, OVERLONG, THEMES, TIME_ACCOUNTS } from '../../../constants';
import translate from '../../../lib/translate';
import {
  date,
  duration as durationUtils,
  object as objectUtils,
  timeCategory as timeCategoryUtils,
} from '../../../utils';

import { stampChainToPeriod } from '../period';
import BreakScreen from './break-screen';
import CompletedTrackingScreen from './completed-tracking-screen';
import LoadingProxy from './loading-proxy';
import NotWorkingScreen from './not-working-screen';
import OverlongPeriod from './overlong-period';
import SummaryScreen from './summary-screen';
import WorkingScreen from './working-screen';

const { formatIso8601DateDatetime } = date;
const { durationToHours } = durationUtils;
const { areObjectsDifferent } = objectUtils;
const { getTimeCategoryNamesByIds, isSelectedTimeCategoryEnabled } = timeCategoryUtils;

const TIME_TRACKING_SCREENS = {
  [TIME_ACCOUNTS.WORKING]: (props) => (
    <LoadingProxy theme={props.theme}>
      <WorkingScreen {...props} />
    </LoadingProxy>
  ),
  [TIME_ACCOUNTS.NOT_WORKING]: (props) => (
    <LoadingProxy theme={props.theme}>
      <NotWorkingScreen {...props} />
    </LoadingProxy>
  ),

  [TIME_ACCOUNTS.BREAK]: (props) => (
    <LoadingProxy theme={props.theme}>
      <BreakScreen {...props} />
    </LoadingProxy>
  ),

  overlongPeriod: (props) => (
    <LoadingProxy theme={props.theme}>
      <OverlongPeriod {...props} />
    </LoadingProxy>
  ),
  completedTracking: (props) => (
    <LoadingProxy theme={props.theme}>
      <CompletedTrackingScreen {...props} />
    </LoadingProxy>
  ),
  summary: (props) => (
    <LoadingProxy theme={props.theme}>
      <SummaryScreen {...props} />
    </LoadingProxy>
  ),
};

const getScreenToRender = ({
  isSummaryScreenOpen,
  activeTimeAccount,
  isCompleted,
  hasClockInTimestamps,
  isOverlongPeriod,
  isIn3ColumnTerminalMode,
}) => {
  if (isCompleted && activeTimeAccount !== TIME_ACCOUNTS.NOT_WORKING) {
    return TIME_TRACKING_SCREENS.completedTracking;
  }
  if (!hasClockInTimestamps) return TIME_TRACKING_SCREENS[activeTimeAccount];

  if (!isCompleted && Number(isOverlongPeriod) >= OVERLONG && isIn3ColumnTerminalMode !== true) {
    return TIME_TRACKING_SCREENS.overlongPeriod;
  }
  return isSummaryScreenOpen ? TIME_TRACKING_SCREENS.summary : TIME_TRACKING_SCREENS[activeTimeAccount];
};

export class TimeTracking extends React.Component {
  state = { rerender: 0 };

  componentDidMount() {
    this.interval = setSyncedInterval(() => this.setState({ rerender: this.state.rerender + 1 }), 1000);
  }

  componentWillUnmount() {
    clearSyncedInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    this.setSelectedTimeCategoriesBasedOnLastPeriod(areObjectsDifferent(prevProps, this.props, ['timestampChain']));
  }

  setSelectedTimeCategoriesBasedOnLastPeriod(alsoIfPeriodIsComplete) {
    const {
      selectedTimeCategory1Id,
      selectedTimeCategory2Id,
      setSelectedTimeCategory1Id,
      setSelectedTimeCategory2Id,
      timestampChain,
    } = this.props;

    const period = stampChainToPeriod(timestampChain);
    const isPeriodIncomplete = !period.isCompleted;

    const timeCategory1FromPeriod = period.activeTimeCategory1Id;
    const timeCategory2FromPeriod = period.activeTimeCategory2Id;
    const isSelectedTimeCategory1Enabled = isSelectedTimeCategoryEnabled(
      this.props.timeCategories,
      period.activeTimeCategory1Id
    );
    const isSelectedTimeCategory2Enabled = isSelectedTimeCategoryEnabled(
      this.props.timeCategories,
      period.activeTimeCategory2Id
    );

    if (
      !selectedTimeCategory1Id &&
      !selectedTimeCategory2Id &&
      (alsoIfPeriodIsComplete || isPeriodIncomplete) &&
      (period.activeTimeCategory1Id || period.activeTimeCategory2Id)
    ) {
      if (isSelectedTimeCategory1Enabled) setSelectedTimeCategory1Id(timeCategory1FromPeriod);
      if (isSelectedTimeCategory2Enabled) setSelectedTimeCategory2Id(timeCategory2FromPeriod);
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      !this.props.isTimeCategoryOverlayOpen ||
      this.props.selectedTimeCategory1Id !== nextProps.selectedTimeCategory1Id ||
      this.props.selectedTimeCategory2Id !== nextProps.selectedTimeCategory2Id ||
      this.props.disableStampWatch !== nextProps.disableStampWatch ||
      this.props.theme !== nextProps.theme
    );
  }

  render() {
    const props = this.props;
    const timestampChain = props.timestampChain;
    const period = stampChainToPeriod(timestampChain);

    if (props.withLoadingSpinner && props.loading) return <LoadingSpinner theme={props.theme} />;

    const userAndCrewId = {
      crewId: props.crewId,
      userId: props.userId,
      useLocation: LOCATION_OPTIONS[props.crewSettings && props.crewSettings[`timeTrackingAllowed${props.channel}`]],
    };

    const onChangeTimeCategories = ({ timeCategory1Id, timeCategory2Id }) => {
      props.setSelectedTimeCategory1Id(timeCategory1Id);
      props.setSelectedTimeCategory2Id(timeCategory2Id);
      if (props.clockInWithTimeCategories) {
        props.clockInWithTimeCategories(timeCategory1Id, timeCategory2Id);
      }
    };

    const onStartWork = ({ timeCategory1Id, timeCategory2Id }) =>
      Promise.resolve()
        .then(() =>
          props.onStartWork({
            timeCategory1Id,
            timeCategory2Id,
            ...userAndCrewId,
            timestamp: formatIso8601DateDatetime(new Date()),
          })
        )
        .then(() => {
          if (timeCategory1Id) {
            const name = getTimeCategoryNamesByIds(props.timeCategories, timeCategory1Id, timeCategory2Id);

            return props.notify.success({
              message: translate(
                timeCategory2Id
                  ? "You're clocked in on time categories: {{name}}"
                  : "You're clocked in on time category: {{name}}",
                { name }
              ),
            });
          }

          // no time category selected
          return props.notify.success({ message: translate("You're clocked in") });
        })
        .catch((e) => {
          const firstStampError = get(e, 'errors[0].errors.timestamp[0].message');
          if (firstStampError) {
            return props.notify.error({ message: firstStampError });
          }
          return props.notify.error({
            message: translate("Couldn't add time stamp"),
          });
        });

    const onStopWork = () =>
      Promise.resolve()
        .then(() =>
          props.onStopWork({
            ...userAndCrewId,
            bookingsForUserCrewInRange: props.bookingsForUserCrewInRange,
            durationsForUserCrewInRange: props.durationsForUserCrewInRange,
            timestamp: formatIso8601DateDatetime(new Date()),
            lastStamp: props.lastStamp,
          })
        )
        .then(() => props.notify.success({ message: translate("You're clocked out") }))
        .catch(() =>
          props.notify.error({
            message: translate("Couldn't add time stamp"),
          })
        );

    const onUpdateBookingsAndDurations = () =>
      props.onUpdateBookingsAndDurations({
        ...userAndCrewId,
        bookingsForUserCrewInRange: props.bookingsForUserCrewInRange,
        durationsForUserCrewInRange: props.durationsForUserCrewInRange,
        timestamp: formatIso8601DateDatetime(new Date()),
      });

    const onStartBreak = () =>
      Promise.resolve()
        .then(() => props.onStartBreak({ ...userAndCrewId, timestamp: formatIso8601DateDatetime(new Date()) }))
        .then(() => props.notify.success({ message: translate("You're on break") }))
        .catch(() =>
          props.notify.error({
            message: translate("Couldn't add time stamp"),
          })
        );

    const onUpdateNote = ({ note }) =>
      Promise.resolve()
        .then(() =>
          props.onUpdateNote({
            ...userAndCrewId,
            timestamp: period.timestamp,
            note: note,
          })
        )
        .catch((e) => {
          const errorMessage = get(e, 'errors.note[0].message');
          if (errorMessage) {
            return props.notify.error({ message: errorMessage });
          }
          return props.notify.error({
            message: translate("Couldn't update note"),
          });
        });

    const onUpdateBreak = ({ duration }) => {
      if (asSeconds(duration) === asSeconds(period.breakDuration)) {
        return Promise.resolve();
      }
      return props
        .onUpdateBreak({
          ...userAndCrewId,
          timestamp: period.clockInTimestamp,
          duration: duration,
        })
        .then(() => props.notify.success({ message: translate('Break was updated.') }))
        .catch((error) => {
          if (error.id === statusCodes.BREAK_LONGER_THAN_STAMPS) {
            return props.notify.error({
              message: translate("Couldn't update break. The given break is longer than the work time."), // eslint-disable-line max-len
            });
          }

          return props.notify.error({
            message: translate("Couldn't update break. (Error ID: {{errorId}})", { errorId: error.id }), // eslint-disable-line max-len
          });
        });
    };

    const renderScreen = getScreenToRender({
      isSummaryScreenOpen: this.props.isSummaryScreenOpen,
      activeTimeAccount: period.activeTimeAccount,
      isCompleted: period.isCompleted,
      hasClockInTimestamps: (period.clockInTimestamp || period.clockOutTimestamp) !== undefined,
      isOverlongPeriod: durationToHours(period.workDuration),
      isIn3ColumnTerminalMode: this.props.isIn3ColumnTerminalMode,
    });

    const channelBasedDisabling = () => {
      const { crewSettings, channel } = this.props;
      return !props.crewSettings || crewSettings[`timeTrackingAllowed${channel}`] !== 'false';
    };

    return (
      <section className={this.props.className}>
        {renderScreen({
          ...props,
          period,
          onChangeTimeCategories,
          onStartBreak,
          onStartWork,
          onStopWork,
          onUpdateNote,
          onUpdateBreak,
          onUpdateBookingsAndDurations,
          canTrackTimes: channelBasedDisabling(),
        })}
      </section>
    );
  }
}

TimeTracking.propTypes = {
  alignment: PropTypes.oneOf(['horizontal', 'vertical']),
  theme: PropTypes.oneOf([THEMES.ORANGE, THEMES.TURQUOISE, THEMES.TURQUOISE_WHITE]),
};

export default TimeTracking;
