import { fetchGet as nativeFetchGet, fetchPost as nativeFetchPost } from '../fetch';

import { buildUrl } from '../../config';

const fetchGet = (urlPath, ...rest) => nativeFetchGet(`${buildUrl(urlPath)}`, ...rest);
const fetchPost = (urlPath, ...rest) => nativeFetchPost(`${buildUrl(urlPath)}`, ...rest);

const CSRF_PATH = '/csrf_token';
const EMAIL_US_PATH = '/contact';

const fetch = {
  csrfToken() {
    return fetchGet(CSRF_PATH);
  },

  emailUs({ headers, body }) {
    const options = { headers, body };
    return fetchPost(EMAIL_US_PATH, options);
  },
};

export default fetch;
