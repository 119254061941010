/**
 * roundBasedOnStep - Rounds number according to given step
 *
 * @param {number} value (e.g. 10.157666666)
 * @param {number} decimalPlaces (e.g. 3)
 *
 * @returns {number} number rounded (e.g. 10.158)
 */
export const roundBasedOnDecimalPlaces = (value: number, decimalPlaces: number): number => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round((value + Number.EPSILON) * factor) / factor;
};

/**
 * roundBasedOnStep - Rounds number according to given step
 *
 * @param {number} value (e.g. 7.2)
 * @param {number} step (e.g. 0.5)
 * @returns {number} number rounded (e.g. 7)
 */
export const roundBasedOnStep = (value: number, step: number): number => {
  if (value % step === 0) return value;
  return Math.round(value / step) * step;
};
