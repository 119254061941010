import { addMinutes } from 'date-fns';
import { factory, sequence, trait } from 'stahlwerk';
import { TIME_ACCOUNTS } from '../constants';
import { getTimestamp } from '../utils/stamp';

const START_WORK = 'startWork';
const START_BREAK = 'startBreak';
const STOP_WORK = 'stopWork';

const SYNC_NAMES = {
  [START_WORK]: 'START_WORK_STAMP',
  [START_BREAK]: 'START_BREAK_STAMP',
  [STOP_WORK]: 'STOP_WORK_STAMP',
};

export const createStamp = factory({
  crewId: sequence(),
  userId: sequence(),
  clockInTimestamp: '2000-01-01T12:00:00',
  timestamp: '2000-01-01T12:00:00',
  startWork: trait({ timeAccount: TIME_ACCOUNTS.START_WORK }),
  stopWork: trait({ timeAccount: TIME_ACCOUNTS.STOP_WORK }),
  startBreak: trait({ timeAccount: TIME_ACCOUNTS.START_BREAK }),
  withLocation: trait({ location: '+12.122/-12.1233' }),
  _sync: {},
});

export const createNewStamp = ({ action, clockInTimestamp, timestamp, crewId, userId, isSynced = false }) =>
  createStamp(action, {
    clockInTimestamp,
    crewId,
    timestamp: getTimestamp(timestamp).toISOString(),
    userId,
    _sync: {
      name: SYNC_NAMES[action],
      synced: isSynced ? true : null,
    },
  });

export const createStartStamp = ({ clockInTimestamp, crewId, userId, isSynced }) =>
  createNewStamp({ action: START_WORK, clockInTimestamp, timestamp: clockInTimestamp, crewId, userId, isSynced });

export const createBreakStamp = ({
  clockInTimestamp,
  crewId,
  isSynced,
  offsetInMinutesFromClockInTimestamp = 60,
  userId,
}) =>
  createNewStamp({
    action: START_BREAK,
    clockInTimestamp,
    timestamp: addMinutes(new Date(clockInTimestamp), offsetInMinutesFromClockInTimestamp),
    crewId,
    userId,
    isSynced,
  });

export const createStopStamp = ({
  clockInTimestamp,
  crewId,
  isSynced,
  offsetInMinutesFromClockInTimestamp = 90,
  userId,
}) =>
  createNewStamp({
    action: STOP_WORK,
    clockInTimestamp,
    timestamp: addMinutes(new Date(clockInTimestamp), offsetInMinutesFromClockInTimestamp),
    crewId,
    userId,
    isSynced,
  });

export const createStartBreakStopStamps = ({ clockInTimestamp, crewId, isSynced, userId }) => [
  createStartStamp({ clockInTimestamp, crewId, userId, isSynced }),
  createBreakStamp({ clockInTimestamp, crewId, userId, isSynced }),
  createStopStamp({ clockInTimestamp, crewId, userId, isSynced }),
];
