import { factory, sequence, random } from 'stahlwerk';

export const createNewsCount = factory({
  total: random(),
  unread: random(),
  unseen: random(),
  absenceManagement: random(),
  shiftPlanner: random(),
  welcome: random(),
  userId: sequence(),
});
