import React from 'react';
import { StyleSheet, css } from '../../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES, Z_INDEX } from '../../../constants';

export const Menu = ({ crewName }) => (
  <div>
    <nav className={css(styles.default)}>{crewName}</nav>
    <div className={css(styles.navigationSpacer)} />
  </div>
);

const padding = 20;
const styles = StyleSheet.create({
  default: {
    zIndex: Z_INDEX.NAVIGATION,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: COLORS.ORANGE_MAIN,
    padding: padding,
    textAlign: 'center',
    ...FONT_STYLES.big,
    color: COLORS.WHITE,
  },
  navigationSpacer: {
    height: padding * 2 + parseInt(FONT_STYLES.big.fontSize, 10),
  },
});
