import produce from 'immer';
import { array, date as dateUtils } from '../utils';

const { applyMinimalChangesToArray } = array;
const { isWithinRange } = dateUtils;

const initialState = produce({ durationBalances: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const durationBalancesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_DURATION_BALANCES_SUCCESS':
        applyMinimalChangesToArray(
          draft.durationBalances,
          parseDurationBalances(action.result, action.crewId),
          (durationBalance) =>
            durationBalance.crewId === action.crewId &&
            action.date.some((date) => date === durationBalance.date) &&
            (action.userId ? action.userId.some((id) => id === durationBalance.groupBy.userId) : true)
        );
        break;
      case 'LOAD_DURATION_BALANCES_V2_SUCCESS':
        applyMinimalChangesToArray(
          draft.durationBalances,
          parseDurationBalancesV2(action.result, action.crewId),
          (durationBalance) =>
            durationBalance.crewId === action.crewId &&
            isWithinRange(durationBalance.date, action.from, action.to) &&
            (action.userId ? action.userId.some((id) => id === durationBalance.groupBy.userId) : true)
        );
        break;
      default:
      // nothing to do => immer returns the same object
    }
  });

const parseDurationBalances = (balances, crewId) => balances.map((balance) => ({ ...balance, crewId }));

const parseDurationBalancesV2 = (balances, crewId) =>
  balances.map((balance) => {
    const { groupBy, ...restOfBalance } = balance;
    const { date, ...restOfGroupBy } = groupBy;

    return { ...restOfBalance, groupBy: restOfGroupBy, date, crewId };
  });
