import React from 'react';

import BadgeNavigation from '../../../components/core/badge-navigation';
import LoadingSpinner from '../../../components/core/loading-spinner-with-text';

import { css, StyleSheet } from '../../../_external-deps/stylesheet';

import UpdateGeofence from './update-geofence';
import UpdateName from './update-name';
import UpdateStatus from './update-status';

const Organism = (props) => (
  <Choose>
    <When condition={props.loadedOnce}>
      <div key={props.timeCategory.id}>
        <BadgeNavigation
          onChange={props.setSelectedTimeCategory}
          collection={props.timeCategories}
          selected={props.timeCategory}
        />
        <UpdateName {...props} onSubmit={props.onUpdateName} onBlur={props.onUpdateName} />
        <UpdateStatus {...props} onSubmit={props.onUpdateStatus} />
        <If condition={props.level === 1}>
          <UpdateGeofence {...props} />
        </If>
      </div>
    </When>
    <Otherwise>
      <div className={css(styles.loadingSpinnerWrapper)}>
        <LoadingSpinner />
      </div>
    </Otherwise>
  </Choose>
);

const styles = StyleSheet.create({
  loadingSpinnerWrapper: {
    padding: '50px',
  },
});

export default Organism;
