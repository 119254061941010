import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { collections } from '../../utils';
import * as actions from '../../action-creators';
import * as selectors from '../../selectors';

import { api, callFunctionWhenPropsChange } from '../../lib';

import { readUiValue } from '../../selectors/ui-selectors';
import Component from './organism/index';

const { loadCrewSettings, loadTerminalCrew, loadTerminalCrewMembers, UI_STATE_KEY_CURRENT_DATE } = actions;
const { selectCurrentSettingsInMultiCrew, selectEnabledTerminalCrewMembersInCurrentCrew } = selectors;
const { composeReverse } = collections;

const mapStateToProps = (state, props) => {
  const settings = selectCurrentSettingsInMultiCrew(state, { crewId: props.crewId });

  return {
    settings,
    members: selectEnabledTerminalCrewMembersInCurrentCrew(state, { ...props, settings }),
    currentTime: readUiValue(state, UI_STATE_KEY_CURRENT_DATE),
    onSignUpOpen: ({ userId }) => props.history.push(`/login/${userId}`),
  };
};

const mapDispatchToProps = (dispatch) => ({
  functionToCall: ({ crewId, disabledAt }) => {
    if (crewId) {
      return Promise.all([
        dispatch(loadTerminalCrewMembers({ api, crewId, disabledAt })),
        dispatch(loadTerminalCrew({ api, crewId })),
        dispatch(loadCrewSettings({ api, crewId })),
      ]);
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  crewId: ownProps.crewId,
  userId: ownProps.userId,
});

const TerminalOverviewContainer = composeReverse(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId', 'currentTime', 'userId'] })
)(Component);

TerminalOverviewContainer.displayName = 'TerminalOverviewContainer';

export default TerminalOverviewContainer;
