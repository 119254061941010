import { createSelector } from 'reselect';
import { startOfDay, endOfDay } from 'date-fns';
import { selectCurrentCrewId } from './crews-selectors';
import { getStampsRelevantInDateRange } from '../utils/stampchain';

export const selectStamps = (state) => state.bo.stamps.stamps;

export const selectStampsInCurrentCrew = createSelector(selectStamps, selectCurrentCrewId, (stamps, crewId) =>
  stamps.filter((stamp) => stamp.crewId === crewId)
);

export const selectStampsInCurrentCrewBetween = (stampsSelector, startTimeSelector, endTimeSelector) =>
  createSelector(stampsSelector, startTimeSelector, endTimeSelector, (stamps, startTime, endTime) =>
    getStampsRelevantInDateRange(stamps, startTime, endTime)
  );

export const selectStampsInCurrentCrewOnSelectedDate = selectStampsInCurrentCrewBetween(
  selectStampsInCurrentCrew,
  (_, props) => startOfDay(new Date(props.date)),
  (_, props) => endOfDay(new Date(props.date))
);
