import React from 'react';
import PropTypes from 'prop-types';

import { COLORS, FONT_STYLES } from '../../constants';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import ButtonOrLink from './button-or-link';
import ToolTip from './Tooltip';

const SubNavigation = ({
  items,
  vertical = false,
  horizontalLeft = false,
  className = '',
  tabButtonStyle = '',
  navigationContainerStyle = '',
}) => {
  const showPlaceholder = items.every((item) => item.placeholder);

  return (
    <div className={css(navigationContainerStyle)}>
      {showPlaceholder && (
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
          {items.map(({ id, placeholder }, index) => (
            <div className={css(styles.placeholder)} key={id || index}>
              {placeholder}
            </div>
          ))}
        </div>
      )}
      <nav
        className={`${css(
          vertical ? styles.verticalWrapper : styles.horizontalWrapper,
          horizontalLeft && styles.horizontalLeftWrapper
        )} ${className}`}
      >
        {items.map(({ id, onClick, href, label, toolTip, isSelected, color }, index) => {
          const Button = () => (
            <ButtonOrLink
              id={`${id}-button-or-link`}
              href={href}
              onClick={onClick}
              isSelected={isSelected}
              vertical={vertical}
              horizontalLeft={horizontalLeft}
              color={color}
              customStyle={tabButtonStyle}
            >
              {label}
            </ButtonOrLink>
          );

          return (
            <div className={css(tabButtonStyle)} key={id}>
              <Choose>
                <When condition={toolTip}>
                  <ToolTip text={toolTip} style={{ ...FONT_STYLES.tiny }} key={index}>
                    <Button />
                  </ToolTip>
                </When>
                <Otherwise>
                  <Button key={index} />
                </Otherwise>
              </Choose>
            </div>
          );
        })}
      </nav>
    </div>
  );
};

SubNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      onClick: PropTypes.func,
      label: PropTypes.any.isRequired,
      isSelected: PropTypes.bool,
      color: PropTypes.string,
    })
  ).isRequired,
  vertical: PropTypes.bool,
  className: PropTypes.string,
};

const styles = StyleSheet.create({
  verticalWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  horizontalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 6,
  },
  horizontalLeftWrapper: {
    justifyContent: 'left',
  },
  item: {
    ...FONT_STYLES.medium,
    fontWeight: '700',
    border: 'none',
    padding: '3px 23px',
    textAlign: 'left',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    borderRadius: 0,
    ':hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    ':focus': {
      outline: 'none',
    },
  },
  verticalItem: {
    borderLeft: '3px solid transparent',
  },
  horizontalItem: {
    borderBottom: '3px solid transparent',
  },
  active: {
    borderColor: COLORS.ORANGE_MAIN,
    color: COLORS.ORANGE_MAIN,
  },
  placeholder: {
    ...FONT_STYLES.small,
    fontSize: '12px',
    display: 'flex',
    flex: 1,
  },
});

export default SubNavigation;
