/* eslint-disable quote-props, max-len */

/**
 * Important note for one word translations in lists!
 *
 * If there is a list with one word only we capitalize
 * the first character:
 *   - Beantragt
 *   - Bestätigt
 *   - Verbleibend
 */
import errorMessages from './utils/error-messages';

export default {
  // A
  '{{absenceName}}, {{date}}': '{{absenceName}}, {{date}}',
  'Absence days have been changed.': 'Abwesenheitstage gespeichert.',
  "Absence days couldn't be changed.": 'Abwesenheitstage konnten nicht gespeichert werden.',
  'Absence was created.': 'Abwesenheit eingetragen.',
  'Absence was deleted.': 'Abwesenheit gelöscht.',
  'Absence was granted.': 'Abwesenheitsantrag genehmigt.',
  'Absence was rejected.': 'Abwesenheitsantrag abgelehnt.',
  'Absence was requested.': 'Abwesenheit erfolgreich beantragt.',
  'Absences for member': 'Abwesenheiten für den Mitarbeiter',
  'Absences in this timeframe': 'Abwesenheiten in diesem Zeitraum',
  'Absences must not overlap': 'Abwesenheiten dürfen sich nicht überschneiden',
  'Admitter message:': 'Nachricht vom Manager:',
  'Add more stamps': 'Weitere Stempelung hinzufügen',
  afternoon: 'Nachmittag',
  'Already processed absences': 'Bereits bearbeitete Anträge',
  'A time period is already present for the current time frame.':
    'Es gibt bereits eine Zeitstempelung für den aktuellen Zeitraum.',
  'at {{date}}': 'am {{date}}',

  // B
  'Back to sign in': 'Zurück zum Login',
  'Back to the login mask': 'Zurück zur Anmeldemaske',
  'Begin:': 'Arbeitsbeginn:',
  'between {{startDate}} and {{endDate}}': 'vom {{startDate}} bis {{endDate}}',
  Break: 'Pause',
  'Break started on {{date}} at {{time}}': 'Pausenbeginn am {{date}} um {{time}}',
  'Break was updated.': 'Die Pause wurde erfolgreich gespeichert.',
  'Buy Crewmeister': 'Crewmeister kaufen',

  // C
  Cancel: 'Abbrechen',
  'Calendar info with confirmation #longtext': `
    <p>Sie sollten hier die Tage anklicken, die vom Urlaubsanspruch des Mitarbeiters abgezogen werden. In der Regel sind dies die Tage, an denen der Mitarbeiter normalerweise arbeitet.</p>

    <ul>
      <li>Ein einzelner Klick auf eine Kachel zeigt eine 1 an = 1 Tag wird vom Anspruch abgezogen.</li>
      <li>Ein weiterer Klick ändert die Ziffer zu einer 0,5 = ein halber Tag wird vom Anspruch abgezogen.</li>
    </ul>

    <p>Dies ist nicht nur wichtig, damit Crewmeister automatisch den Urlaubsanspruch des Mitarbeiters berechnen kann, sondern auch damit dass System genau weiß, an welchen Tagen genau der Mitarbeiter lohnrelevant abwesend war. Diese Information taucht u.a. auch im Excel Export auf und ist wichtig für die Lohnabrechnung des Steuerberaters.</p>

    <p>Wenn Sie die Urlaubstage der Mitarbeiter nicht zählen möchten, können Sie diesen Schritt ignorieren und Urlaubanträge direkt genehmigen oder ablehnen.</p>
  `,

  'Calendar info without confirmation #longtext': `
    <p>Dies ist wichtig wenn die Krankheitstage Ihrer Mitarbeiter im Excel Export auftauchen sollen oder Sie die Anzahl der Krankheitstage in Crewmeister zählen möchten.</p>

    <p>Klicken Sie dafür auf die Tage die tatsächlich als Krankheitstag gelten sollen. In der Regel sind dies die Tage, an denen der Mitarbeiter normalerweise arbeitet.</p>

    <ul>
      <li>Ein einzelner Klick auf eine Kachel zeigt eine 1 an = 1 Tag Krankheit wird gezählt.</li>
      <li>Ein weiterer Klick ändert die Ziffer zu einer 0,5 = ein halber Tag Krankheit wird gezählt</li>
    </ul>

    <p>Sollen Krankheitstage weder gezählt werden, noch im Excel Export auftauchen, können Sie die Krankheit direkt eintragen ohne einzelne Tage zu markieren.</p>
  `,
  Change: 'Ändern',
  'Confirmed request for {{absenceName}}, {{date}}': 'Genehmigter Antrag auf {{absenceName}}, {{date}}',
  'Create absence': 'Abwesenheit eintragen',
  'Click on your name to track times': 'Klicken Sie auf Ihren Namen um Zeiten zu erfassen',
  'Click the days on which {{name}} normally works.':
    'Klicken Sie auf die Tage, an denen {{name}} normalerweise arbeitet.',
  'clocked-in': 'eingestempelt',
  'Close terminal': 'Terminal beenden',
  'Close window': 'Fenster schließen',
  "Couldn't send password reset instructions. #longtext": `
    Wir konnten Ihnen keine Anleitung für das Zurücksetzen des Passwortes zusenden.
    Bitte kontaktieren Sie ihren Vorgesetzten
  `,
  Confirm: 'Bestätigen',
  Confirmed: 'Bestätigt',
  'Continue work': 'Weitermachen',
  "Couldn't add time stamp": 'Der Zeitstempel konnte nicht erfasst werden',
  "Couldn't update note": 'Die Notiz konnte nicht gespeichert werden',
  "Couldn't update break. The given break is longer than the work time.":
    'Die Pause konnte nicht gespeichert werden, da sie zu lange ist.',
  "Couldn't update break. (Error ID: {{errorId}})":
    'Die Pause konnte nicht gespeichert werden. (Error Code: {{errorId}})',
  'Crew management': 'Crewverwaltung',
  'CW {{calendarWeek}}': 'KW {{calendarWeek}}',
  'Invoice address': 'Rechnungsanschrift',

  // D
  'Data is being processed': 'Daten werden verarbeitet',
  Date: 'Datum',
  Days: 'Tage',
  Day: 'Tag',
  Delete: 'Löschen',
  Done: 'Fertig',
  'Direct Debit': 'SEPA-Lastschrift',
  Download: 'Herunterladen',

  // E
  email: 'E-Mail',
  Email: 'E-Mail',
  'email or username': 'E-Mail oder Benutzername',
  'Email or Username': 'E-Mail oder Benutzername',
  'Email already in use': 'Diese E-Mail ist bereits vergeben.',
  Employee: 'Mitarbeiter',
  End: 'Ende',
  'End time': 'Endzeit',
  English: 'Englisch',
  'Enjoy your break, {{name}}': 'Genießen Sie Ihre Pause, {{name}}',
  'Enter absence': 'Abwesenheit eintragen',
  'Enter {{name}}': '{{name}} eintragen',
  Entitlement: 'Anspruch',
  'Entitlement saved.': 'Anspruch gespeichert.',
  "Entitlement couldn't be saved.": 'Anspruch konnte nicht gespeichert werden.',

  // Emoji picker
  activity: 'Aktivität',
  categoriesLabel: 'Emoji-Kategorien',
  clear: 'Löschen',
  custom: 'Benutzerdefiniert',
  flags: 'Flaggen',
  foods: 'Essen & Trinken',
  nature: 'Tiere & Natur',
  notfound: 'Kein Emoji gefunden',
  objects: 'Objekte',
  people: 'Smileys & Menschen',
  places: 'Reisen & Orte',
  recent: 'Häufig verwendet',
  search: 'Suchen',
  searchResults: 'Suchergebnisse',
  skintext: 'Wählen Sie Ihren Standard-Hautton',
  skintone1: 'Standard Hautton',
  skintone2: 'Heller Hautton',
  skintone3: 'Mittelheller Hautton',
  skintone4: 'Mittlerer Hautton',
  skintone5: 'Mitteldunkler Hautton',
  skintone6: 'Dunkler Hautton',
  symbols: 'Symbole',
  title: 'Hautfarbe',

  // F
  Feedback: 'Feedback',
  'Forgot password?': 'Passwort vergessen?',
  From: 'Von',
  'Full application is launched...': 'Vollanwendung wird gestartet',

  // G
  German: 'Deutsch',
  Grant: 'Genehmigen',
  Group: 'Gruppe',
  'Group created successfully': 'Gruppe wurde erstellt',
  '{{count}} groups': '{{count}} Gruppen',

  // H
  'half day': 'halber Tag',
  'Hello, {{name}}': 'Hallo, {{name}}',
  Help: 'Hilfe',
  'Hi {{name}} - you are working right now': 'Hallo {{name}} - Sie arbeiten gerade',

  // I
  'I have read the {{linkOpen}} Terms {{linkClose}}':
    'Ich habe die {{linkOpen}} AGB {{linkClose}} gelesen und akzeptiere sie.',
  'In progress': 'Wird bearbeitet',
  'Instructions sent.': 'E-Mail versendet.',
  'In this period, no times have been tracked.': 'In dem gewählten Zeitraum befinden sich keine Zeiten.',
  'Invalid date format. (DD/MM/YYYY)': 'Ungültiges Datumsformat. (DD.MM.YYYY)',
  Invite: 'Einladen',
  invite: 'Einladen',
  // J
  Year: 'Jahr',

  // K
  // L
  Language: 'Sprache',
  'Last week': 'Letzte Woche',
  'Last month': 'Letzter Monat',
  'Loading...': 'Lade...',
  Loading: 'Lade',

  // M
  Month: 'Monat',
  morning: 'Vormittag',
  'My account': 'Mein Profil',
  'Member message:': 'Nachricht vom Mitarbeiter:',
  'Insert times manually': 'Zeiten manuell erfassen',
  'Insert times manually for {{name}}': 'Zeiten manuell erfassen für {{name}}',

  // N
  name: 'Name',
  Name: 'Name',
  'Next week': 'nächste Woche',
  'Next absence': 'nächste Abwesenheit',
  'Nothing entered so far': 'Noch nichts eingetragen',
  'No Group': 'Keine Gruppe',
  'No options': 'Keine Optionen',
  'No payment or cancellation necessary. Your trial will expire automatically.':
    'Es sind weder Zahlungsdaten noch eine Kündigung während der Testphase erforderlich.',
  Note: 'Notiz',
  Notes: 'Notizen',
  '{{number}} days deduction': '{{number}} Tage Abzug',
  Nobody: 'Niemand',
  'No times recorded for the selected time frame': 'Keine erfassten Zeiten im ausgewählten Zeitraum',

  // O
  'One moment please': 'Noch einen Moment bitte',
  'Only those get deducted.': 'Nur diese werden dem Mitarbeiter abgezogen.',
  'Open request for {{absenceName}}, {{date}}': 'Offener Antrag für {{absenceName}}, {{date}}',
  'Open absence requests for {{name}}': 'Offene Anträge auf {{name}}',
  'Open full application': 'Zur vollen Anwendung',
  Overview: 'Übersicht',
  'Other employees': 'Weitere Mitarbeiter',
  'Overlapping periods detected. There can only be one period at a time.':
    'Arbeitszeiten dürfen sich nicht überschneiden. Die Zeit wurde nicht gespeichert.',
  'An error occured.': 'Es ist ein Fehler aufgetreten.',

  // P
  password: 'Passwort',
  Password: 'Passwort',
  'password confirmation': 'Passwort bestätigen',
  'Password saved': 'Passwort gespeichert.',
  'Password reset instructions sent, check your emails.':
    'Passwort-Reset-Anweisungen versendet, bitte überprüfen Sie Ihre E-Mails.',
  Payment: 'Zahlungsart',
  'Please confirm or reject the all open requests.': 'Bitte genehmigen Sie den jeweiligen Antrag oder lehnen ihn ab.',
  'Please enter your password to continue': 'Bitte geben Sie Ihr Passwort ein um fortzufahren',
  'Previous absence': 'vorherige Abwesenheit',
  'Previous week': 'vorherige Woche',

  // Q
  // R
  Redeem: 'Einlösen',
  Reject: 'Ablehnen',
  'Rejected request for {{absenceName}}, {{date}}': 'Abgelehnter Antrag auf {{absenceName}}, {{date}}',
  Remaining: 'Verbleibend',
  'Delete all entries': 'Alle Einträge löschen',
  'Delete entry': 'Eintrag löschen',
  'Remove working period': 'Arbeitsperiode löschen',
  'Request absence': 'Abwesenheit beantragen',
  'Request pending': 'Offener Antrag',
  'Reset Password': 'Passwort zurücksetzen',
  'Resetting Password...': 'Passwort wird zurückgesetzt...',

  // S
  Save: 'Speichern',
  'Save changes': 'Änderung speichern',
  'Save password': 'Passwort speichern',
  'Saving password': 'Password speichern',
  Saving: 'wird gespeichert',
  Select: 'Auswählen',
  'select today': 'Heute auswählen',
  'Select the absence time period.': 'Wählen Sie den Zeitraum für die Abwesenheit aus.',

  'Sending password reset instructions...': 'Die Anleitung um Ihr Passwort zurückzusetzen wird versendet...',

  'SEPA Direct Debit Mandate (is automatically filled)': 'SEPA-Lastschrift-Mandat (wird automatisch ausgefüllt)',
  'Set new password': 'Neues Passwort eingeben',
  'Confirm new password': 'Neues Passwort bestätigen',
  'Current password': 'Aktuelles Passwort eingeben',
  'Sign in': 'Anmelden',
  'sign in hint #longtext': `
    Sie befinden sich gerade im Crewmeister Terminal zur Zeiterfassung. Über diesen können mehrere Mitarbeiter schnell über ein einziges Gerät ihre Zeiten stempeln. <br/><br>
    Dafür klickt man einfach auf den eigenen Namen, gibt sein Passwort ein und stempelt wie gewohnt.<br/><br>
    Den Terminal-Modus kann nur der Administrator einer Crew wieder verlassen.<br/><br>
    Dafür klickt der Administrator auf seinen Namen und gibt sein Passwort ein. Über den Link „Zur vollen Anwendung“ rechts unten im auftauchenden Fenster, kann der Administrator den Crewmeister Terminal verlassen und zurück zu seinem vollen Crewmeister Profil kehren.
  `,
  'Sign out': 'Abmelden',
  'Signing in': 'Anmeldung',
  'Signing up': 'Registrierung',
  'since {{date}}': 'seit {{date}}',
  Start: 'Start',
  'Start break': 'Pause',
  'Start new trial': 'Neue Testphase starten',
  'Start work': 'Einstempeln',
  'Start time': 'Startzeit',
  'Start your free trial': 'Starten Sie Ihre kostenfreie Testphase',
  'Started to work on {{date}} at {{time}}': 'Arbeitsbeginn am {{date}} um {{time}}',
  Step: 'Schritt',
  'Stop work': 'Ausstempeln',
  'Stopped to work on {{date}} at {{time}}': 'Ausgestempelt am {{date}} um {{time}}',
  Submit: 'Fortfahren',
  'Submitting...': 'Fortfahren...',

  // T
  Target: 'Soll',
  Total: 'Gesamt',
  'This month': 'Dieser Monat',
  'This week': 'Diese Woche',
  'Time tracking': 'Zeiterfassung',
  'Time reports': 'Zeitauswertung',
  'Time was tracked between {{startTime}} and {{endTime}}': 'Zeit erfasst von {{startTime}} bis {{endTime}}',
  To: 'Bis',
  Today: 'Heute',
  'Track working time for {{name}}': 'Arbeitszeit stempeln für {{name}}',
  'Tracked times': 'Erfasste Zeiten',
  'Tracked times for {{name}}': 'Erfasste Zeiten von {{name}}',
  'Type note': 'Nachricht eingeben',
  'The form has not yet been filled out correctly. Please check the marked fields.':
    'Das Formular ist noch nicht korrekt ausgefüllt. Bitte überprüfen Sie die markierten Felder.',
  'Times saved': 'Zeiten gespeichert',

  // U
  username: 'Benutzername',
  Unconfirmed: 'Beantragt',
  'User was invited.': 'Benutzer wurde eingeladen.',

  // V
  'Voucher Code': 'Gutscheincode',
  Vacation: 'Urlaub',
  'Vacation planning': 'Urlaubsplaner',

  // W
  "We're still trying to complete your request": 'Wir probieren noch immer Ihre Anfrage zu bearbeiten',
  Week: 'Woche',
  'Welcome to': 'Willkommen bei',
  'Welcome, {{name}}': 'Willkommen, {{name}}',
  'whole day': 'ganzer Tag',
  'Work duration': 'Arbeitszeit',
  'Work time': 'Arbeitszeit',
  'Working employees': 'Gerade arbeitende Mitarbeiter',
  'Working period': 'Arbeitsperiode',
  'Wrong password, please try again!': 'Falsches Passwort, bitte erneut versuchen!',

  // X
  // Y
  Yesterday: 'Gestern',
  "Your absence couldn't be created.": 'Ihre Abwesenheit konnte nicht gespeichert werden.',
  "Your absence couldn't be deleted.": 'Ihre Abwesenheit konnte nicht gelöscht werden.',
  "Your absence couldn't be granted.": 'Ihr Abwesenheitsantrag konnte nicht genehmigt werden.',
  "Your absence couldn't be rejected.": 'Ihr Abwesenheitsantrag konnte nicht abgelehnt werden.',
  "Your absence couldn't be requested.": 'Ihr Abwesenheitsantrag konnte nicht gestellt werden.',
  'You closed the terminal successfully.': 'Der Terminal zur Zeiterfassung wurde beendet.',
  'You can track times again at {{time}}.': 'Sie können ab {{time}} erneut Zeiten erfassen.',
  'Your new password has been saved. Please try it now to login.':
    'Neues Passwort gespeichert. Bitte versuchen Sie sich jetzt damit anzumelden.',
  "You're clocked in": 'Erfolgreich eingestempelt',
  "You're clocked in on time category: {{name}}": 'Sie sind in Zeitkategorie {{name}} eingestempelt',
  "You're clocked in on time categories: {{name}}": 'Sie sind in Zeitkategorien {{name}} eingestempelt',
  "You're clocked out": 'Erfolgreich ausgestempelt',
  "You're on break": 'Sie sind in der Pause',
  'You are working right now, {{name}}': 'Sie arbeiten gerade, {{name}}',
  'Your time was tracked, {{name}}': 'Ihre Arbeitszeit wurde erfasst, {{name}}',
  "You're signed in via terminal.": 'Sie sind über das Terminal eingeloggt.',

  // Z

  //
  // Price Calculator
  //
  'Payment period': 'Rechnungsintervall',
  Yes: 'Ja',
  No: 'Nein',
  Monthly: 'Monatlich',
  Yearly: 'Jährlich',
  '1 month for free on yearly payment': '1 Monat kostenlos bei jährlicher Zahlung',
  'Starting from the 13. month': 'Ab dem 13. Monat',
  'Starting from the second year': 'Ab dem zweiten Jahr',
  'Employees in your company': 'Anzahl Mitarbeiter im Betrieb',
  'Calculate the price for your company': 'Kalkulieren Sie den Preis von Crewmeister für Ihr Unternehmen',
  'Please choose your package': 'Wählen Sie Ihr Paket',
  'I have a Voucher': 'Ich habe einen Gutscheincode',
  'Please check the passed values.': 'Bitte prüfen Sie die eingegeben Werte.',
  'Voucher seems to be invalid. Please verify your input.':
    'Der eingegebene Gutscheincode ist nicht gültig. Bitte korrigieren Sie Ihre Eingabe.',

  Company: 'Unternehmen',
  Salutation: 'Anrede',
  Male: 'Herr',
  Female: 'Frau',
  Other: 'Andere',
  'First Name': 'Vorname',
  'Last Name': 'Nachname',
  Street: 'Straße',
  'Extra address information': 'Zusätzliche Adresszeile',
  'Postal code': 'Postleitzahl',
  City: 'Stadt',
  Country: 'Land',
  'Bank transfer': 'Überweisung',
  'Direct debit': 'SEPA-Lastschrift',
  'VAT ID': 'USt-ID',
  'Buy now': 'Kostenpflichtig buchen',

  'Payment method has been changed to invoice, since direct debit is not available in your country.':
    'Zahlungsart wurde auf Zahlung mit Rechnung geändert, da SEPA-Lastschrift in Ihrem Land nicht möglich ist.',
  'Payment period has been changed to yearly, since monthly payment is not available in your country.':
    'Rechnungsintervall wurde auf jährlich geändert, da monatliche Zahlung in Ihrem Land nicht möglich ist.',
  'In your country is only yearly payment available.': 'In dem gewählten Land ist nur jährliche Zahlung möglich.',

  'Please choose at least one product.': 'Bitte wählen Sie mindestens ein Produkt.',
  'In case you have any questions please get in touch with us.':
    'Für Fragen zu unseren Produkten stehen wir Ihnen gerne zur Verfügung.',
  'More than {{number}} employees?': 'Mehr als {{number}} Mitarbeiter?',
  'We would like to make you an individual offer!': 'Wir erstellen gerne ein individuelles Angebot!',

  'Please get in touch with our customer service. We would like to make you an indivitual offer!':
    'Kontaktieren Sie unseren preisgekrönten Kundenservice. Wir beraten Sie gerne persönlich!',
  'Congratulations, the purchase was succesfull.': 'Gratulation - Der Kauf war erfolgreich.',
  'You will receive the confirmation to your email address!':
    'Ihre Lizenz wurde freigeschaltet und Sie können in Crewmeister direkt weiterarbeiten. Sie erhalten eine Bestätigung des Kaufs per Email.',
  "The price calculator couldn't be loaded due technical reasons.":
    'Der Preisrechner konnte aufgrund eines technischen Fehlers leider nicht geladen werden.',
  'The purchase was not successfull due technical reasons.':
    'Der Kauf konnte aufgrund eines technischen Fehlers leider nicht abgeschlossen werden.',
  'Your price could not be calculated due technical reasons.':
    'Ihr Preis konnte aufgrund eines technischen Fehlers leider nicht ermittelt werden.',
  'Initialize price calculator': 'Initialisiere Preisrechner',

  // Misc
  '+1 Day': '+1 Tag',
  '+{{days}} Days': '+{{days}} Tage',

  //
  // Date stuff ...
  //
  Jan: 'Jan',
  January: 'Januar',
  Feb: 'Feb',
  February: 'Februar',
  Mar: 'Mär',
  March: 'März',
  Apr: 'Apr',
  April: 'April',
  May: 'Mai',
  Jun: 'Jun',
  June: 'Juni',
  Jul: 'Jul',
  July: 'Juli',
  Aug: 'Aug',
  August: 'August',
  Sep: 'Sep',
  September: 'September',
  Oct: 'Okt',
  October: 'Oktober',
  Nov: 'Nov',
  November: 'November',
  Dec: 'Dez',
  December: 'Dezember',

  Monday: 'Montag',
  Tuesday: 'Dienstag',
  Wednesday: 'Mittwoch',
  Thursday: 'Donnerstag',
  Friday: 'Freitag',
  Saturday: 'Samstag',
  Sunday: 'Sonntag',

  Mon: 'Mo',
  Tue: 'Di',
  Wed: 'Mi',
  Thu: 'Do',
  Fri: 'Fr',
  Sat: 'Sa',
  Sun: 'So',

  // TODO: maybe add a interpolate fn which doesn't need this dummy translations
  '{{memberCount}} people absent': '{{memberCount}} Personen abwesend',
  '{{absenceType}}, {{startDate}} - {{endDate}}': '{{absenceType}}, {{startDate}} - {{endDate}}',
  '{{absenceType}}, {{date}': '{{absenceType}}, {{date}',
  '{{number}} days already taken': '{{number}} Tage beansprucht',
  '{{number}} days remaining': '{{number}} Tage verbleibend',

  // Common, unknown, various
  [errorMessages.UNKNOWN_NETWORK_ERROR]: 'Ein Verbindungsfehler. Sind Sie online? Bitte versuchen Sie es nochmal.',
  [errorMessages.UNKNOWN_ERROR]: 'Unbekannter Fehler. Bitte noch einmal versuchen.',

  // Credentials, sign up, etc.
  [errorMessages.INVALID_CREDENTIALS]: 'Anmelden fehlgeschlagen. Bitte Login und Passwort überprüfen.',
  [errorMessages.AUTOMATIC_SIGN_IN_FAILED]:
    'Automatisches Anmelden fehlgeschlagen. Bitte melden gehen Sie zur Anmelde-Seite.',

  // Password related
  [errorMessages.USER_NOT_FOUND]: 'Benutzer nicht gefunden. Bitte prüfen Sie Ihre Eingabe.',
  [errorMessages.USER_WITHOUT_EMAIL]: `Benutzer hat keine E-Mail Adresse hinterlegt.
     Bitten Sie Ihren Crew-Eigentümer das Passwort zu ändern.`,
  [errorMessages.INVALID_PWD_RESET_TOKEN]: 'Ungültiger Token. Bitte überprüfen Sie den Link in der E-Mail.',
  [errorMessages.PWD_RESET_TOKEN_MISSING]:
    'Kein Token angegeben. Bitte klicken Sie auf den Link, den Sie per E-Mail erhalten haben.',
  [errorMessages.MISSING_PASSWORD]: 'Bitte geben Sie ein Passwort ein.',
  [errorMessages.REENTER_PASSWORD]: 'Bitte geben Sie das Passwort noch einmal ein.',
  [errorMessages.PASSWORDS_MISMATCH]: 'Die Passworte stimmen nicht überein.',

  // Various validations.
  [errorMessages.NO_VALID_EMAIL]: 'Bitte geben Sie eine gültige E-Mail ein.',
  [errorMessages.MISSING_NAME]: 'Bitte geben Sie Ihren Namen ein.',
  [errorMessages.TERMS_NOT_ACCEPTED]: 'Bitte akzeptieren Sie die AGB.',
  [errorMessages.USER_IDENTIFIER_MISSING]: 'Bitte E-Mail oder Benutzername eingeben.',
};
