import React from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, THEMES } from '../../constants';
import { time, date as dateUtils } from '../../utils';
import { getTranslationLanguage } from '../../lib/translate';

const { formatIsoTime } = time;
const { formatDateDefault } = dateUtils;

export const DateTimeInfo = ({
  date,
  children,
  prefix = '',
  theme,
  withoutDateInfo = false,
  withoutTimeInfo = false,
}) => {
  const styles = stylesWithThemes[theme] || stylesWithThemes.default;

  return (
    <section className={css(styles.wrapper)}>
      <If condition={!withoutDateInfo}>
        <div className={css(styles.date)}>{formatDateDefault(date, getTranslationLanguage())}</div>
      </If>

      <If condition={!withoutTimeInfo}>
        <div className={css(styles.time)}>
          {prefix} {children || formatIsoTime(date)}
        </div>
      </If>
    </section>
  );
};

const stylesWithThemes = {
  default: StyleSheet.create({
    wrapper: {
      marginBottom: '30px',
    },
    date: {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 700,
      color: COLORS.BROWN_MAIN,
      marginBottom: 4,
    },
    time: {
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 400,
      color: COLORS.BROWN_MAIN,
    },
  }),
  [THEMES.TURQUOISE]: StyleSheet.create({
    wrapper: {
      marginBottom: '30px',
    },
    date: {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 700,
      color: COLORS.TURQUOISE_MEDIUM,
      marginBottom: 4,
    },
    time: {
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 400,
      color: COLORS.TURQUOISE_MEDIUM,
    },
  }),
  [THEMES.TURQUOISE_WHITE]: StyleSheet.create({
    wrapper: {
      marginBottom: '30px',
    },
    date: {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 700,
      color: COLORS.TURQUOISE_MEDIUM,
      marginBottom: 4,
    },
    time: {
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 400,
      color: COLORS.TURQUOISE_MEDIUM,
    },
  }),
};

export default DateTimeInfo;
