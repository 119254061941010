import React from 'react';
import Icon from './icon';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES } from '../../constants';

const CollapsableHeading = ({ isOpen, onClick, header, children }) => {
  return (
    <div className={css(styles.container)}>
      <div onClick={onClick} className={css(styles.clickable)}>
        <label className={css(styles.clickable)}>{header}</label>
        <Icon name={isOpen ? 'up' : 'down'} style={{ fontSize: '75%' }} />
      </div>
      {isOpen && children}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  clickable: {
    ...FONT_STYLES.medium,
    ':hover': {
      background: COLORS.BROWN_EXTRA_LIGHT,
    },
    cursor: 'pointer',
  },
});

export default CollapsableHeading;
