import React from 'react';
import { dataTypes } from '../../utils';

const { isNumber } = dataTypes;

const isRepresentable = (value) => typeof value === 'string' || isNumber(value);

const representableValue = ({ value, defaultValue }) => {
  if (isRepresentable(value)) {
    return `${value}`;
  }
  if (isRepresentable(defaultValue)) {
    return `${defaultValue}`;
  }
  return '';
};

const TextInputField = (props) => (
  <input
    type="text"
    {...props}
    onChange={(evt) => props.onChange && props.onChange(evt.target.value, evt)}
    onBlur={(evt) => props.onBlur && props.onBlur(evt.target.value, evt)}
    value={representableValue(props)}
  />
);

export default TextInputField;
