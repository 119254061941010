import React from 'react';
import { isForm } from '../../../components/core/form';
import TextInputComponent from '../../../components/core/text-input';
import t from '../translate';

const SearchForm = isForm(({ address, addressErrors, onSubmit }) => (
  <TextInputComponent
    errors={addressErrors}
    onBlur={(_, evt) => onSubmit(evt.target.form)}
    name="address"
    placeholder={t('addressSearch')}
    initialValue={address}
  />
));

export default SearchForm;
