import deepEqual from 'deep-equal';
import curry from 'lodash.curry';
import { clone } from './collections';
import { areTypesDifferent, isArray, isFunction, isNumber, isObject, isString } from './data-types';

/**
 * addProperty — Inserts one "[key]: value" to all elements of the respective array
 *
 * @param {array} array
 * @param {string} key
 * @param {any} value
 *
 * @returns {array} array with the property added
 */
export const addProperty = <T>(array: T[], key: string, value: unknown) =>
  !key || !isArray(array) || !array.every((element) => isObject(element))
    ? array
    : array.map((element) => ({ ...element, [key]: value }));

/**
 * allValuesEqual - Checks if all elements of an array are the same
 *
 * @param {array} array of a simple type (strings, numbers, booleans, integers...)
 *
 * @return {boolean} true if all the elements of @param array are the same,
 *                   false otherwise
 */
export const allValuesEqual = (array: Array<unknown>) => array.every((elem) => elem === array[0]);

/**
 * applyMinimalChangesToArray - Creates a new array by merging all elements from targetSelection
 *                              and the ones from mutableArray that do not match the currentSelectionFilter
 *
 * @param {array} mutableArray array that is going to be filtered out
 * @param {array} targetSelection array that is going to be part of the returned array
 * @param {function} currentSelectionFilter responsible for filtering out the elements that will not appear
 *                                          in the returned array
 *
 * @return {array} which is a result of targetSelection + mutableArray (filtered out by currentSelectionFilter)
 *                 A) if a single element is passed as target, it will be converted to an array
 *                 B) if no filter is passed, the mutableArray will be completely replaced by the target
 */
export const applyMinimalChangesToArray = <T>(
  mutableArray: T[],
  targetSelection: T | T[] = [],
  currentSelectionFilter: (param: T) => boolean = () => true
) => {
  const targetSelectionArray = isArray(targetSelection) ? targetSelection : [targetSelection];
  const wantedDict = createDictFromArray(targetSelectionArray);
  const unwantedDict = createDictFromArray(mutableArray.filter(currentSelectionFilter));
  for (const key in wantedDict) {
    if (!unwantedDict[key]) {
      mutableArray.push(wantedDict[key]);
    } else {
      delete unwantedDict[key];
    }
  }
  for (const key in unwantedDict) {
    if (unwantedDict.hasOwnProperty(key)) {
      mutableArray.splice(mutableArray.indexOf(unwantedDict[key]), 1);
    }
  }
};

/**
 * areArraysDifferent - Checks if both arrays are different
 *
 * @param {array} array1
 * @param {array} array2
 * @param {integer} depthLimit
 *
 * @return {boolean} true if the arrays are different, false otherwise
 */
export const areArraysDifferent = (
  array1: Array<unknown> = [],
  array2: Array<unknown> = [],
  depthLimit: number = 3
) => {
  // Check if they have the same length
  if (array1.length !== array2.length) return true;

  // Iterate over the arrays
  return array1.some((element1, index) => {
    const element2 = array2[index];

    // Check if the elements have different types
    const areDifferent = areTypesDifferent(element1, element2, depthLimit);
    if (areDifferent !== undefined) return areDifferent;

    return element1 !== element2;
  });
};

/**
 * @param {array} array that will be partitioned
 * @param {(elem) => bool} predicate function that checks in which partition each element will be
 * @returns [pass,fail] two arrays.
 *    - The first contains the elements which was evaluated as true by the predicate
 *    - The second contains the elements evaluated as false by the predicate
 */
export function arrayPartition<T>(array: Array<T>, predicate: (param: T) => boolean) {
  if (isArray(array) && isFunction(predicate)) {
    return array.reduce(
      ([pass, fail]: Array<Array<T>>, element) =>
        predicate(element) ? [[...pass, element], fail] : [pass, [...fail, element]],
      [[], []]
    );
  }
  return [[], []];
}

/**
 * arraySearch - filter elements in an array based in a search string string
 *
 * @param {array} array - The array of elements that will be searched
 * @param {string} searchExpression - The expression that will be searched for
 * @param {object} options:
 *    - {(object) => string[]} extractor - A function that receives an element
 *      of the array and returns a list of strings that can be matched for the
 *      search.  Default: (element) => [element];
 *    - {(string,string) => bool} matcher - a function that receives the text
 *      string to search and the search expression and matches if it is
 *      applicable. Default: regex matching
 *    - {boolean} trimString - when is set, the search string
 *      is trimmed. Default: true
 *
 * @return the filtered array based on the search
 *
 * Note:
 *    - if the search is not recognized (It is not a string or a number) no
 *      error is thrown and an empty array is returned.
 */
export const arraySearch = (array: Array<unknown>, searchExpression: string, options: Record<string, unknown>) => {
  const { extractor, matcherFunction, trimString } = {
    extractor: (element: unknown) => [element],
    matcherFunction: (text: string, search: string) => `${text}`.match(new RegExp(search, 'gi')),
    trimString: true,
    ...options,
  };
  if (!isString(searchExpression) && !isNumber(searchExpression)) {
    return [];
  }
  return array.filter((element) => {
    // If it is an array of arrays, we should return the exact same array
    if (isArray(element)) return true;

    const values = extractor(element);
    const searchExpressionString = searchExpression.toString();
    const finalSearchExpression = trimString ? searchExpressionString.trim() : searchExpressionString;
    if (isArray(values)) {
      return values.some((value) => matcherFunction(`${value}`, finalSearchExpression));
    }
    return matcherFunction(JSON.stringify(values), finalSearchExpression);
  });
};

/**
 * arrayToDictionary - Converts an array of objects to a dictionary based on the given "id" and "value" properties
 *
 * @param {array} array (ex: [{ id: 'first-id', value: 1 }. { id: 'second-id', value: 2 }])
 * @param {string} idProperty (ex: 'id')
 * @param {string} valueProperty (ex: 'value')
 *
 * @returns {object} (ex: { 'first-id': 1, 'second-id': 2 })
 */
export const arrayToDictionary = <T, K extends keyof T>(array: Array<T>, idProperty: K, valueProperty: K) =>
  isArray(array) && typeof idProperty === 'string' && typeof valueProperty === 'string'
    ? array.reduce((accumulator, currentVal: T) => {
        if (!currentVal[idProperty]) return accumulator;
        const { [idProperty]: id, [valueProperty]: value } = currentVal;
        return { ...accumulator, [id as string]: value };
      }, {} as Pick<T, K>)
    : {};

/**
 * arrayToHashedObject - Maps an array to an object. Each element of the array is mapped to
 *                       a key property in the object, if property is passed. Otherwise,
 *                       the item itself will be used.
 *
 * @param {array} array of objects (ex: [{a: x, b: y}, {a: w, b: z}])
 * @param {string or number} keyField representing a common property of the objects above (ex: 'a')
 *
 * @return {object} which contains the same information as the array;
 *                  each element in the array is represented as a key property in the object
 *                  ex: {x: {a: x, b: y}, w: {a: w, b: z}}
 */
export const arrayToHashedObject = (array: Array<any>, keyField?: string | number) => {
  if (keyField !== undefined) {
    return array.reduce(
      (obj: Record<string | number, Record<string, string | number>>, item: Record<string, string | number>) => {
        const aux = obj;
        aux[item[keyField]] = item;
        return aux;
      },
      {}
    );
  }

  return array.reduce((obj, item) => ({ ...obj, [`${item}`]: item }), {});
};
/**
 * compact - Filters out all null and undefined elements of an array
 *
 * @param {array} array
 *
 * @return {array} which is the same array as passed but without null and undefined
 *                 elements
 */
export const compact = (array: Array<unknown>) => array.filter((item) => item);

/**
 * countOccurrences - Count the occurrences that a certain property with a certain value (or different from it)
 *                    appeared in the array
 *
 * @param {array} array (ex: [{ a: 1 }, { a: 2 }, { a: 3 }]
 * @param {any} value (ex: 1)
 * @param {string} property (ex: 'a')
 * @param {string} differentFrom if true, we will check for the amount of occurrences that are different from the passed value (ex: true)
 *
 * @returns {integer} (ex: 2)
 */
export const countOccurrences = (
  array: Array<Record<string, number>>,
  property: string,
  value: number,
  differentFrom: boolean
) =>
  array.reduce(
    (a, v) => ((!differentFrom && v[property] === value) || (differentFrom && v[property] !== value) ? a + 1 : a),
    0
  );

/**
 * createDictFromArray - Converts an array to an object, in which each property is the stringification
 *                       of the respective array's element and its value is the element itself
 *
 * @param {array} array (ex: [{a: 'a', c: 'c'}, {b: 'b'}, 'c'])
 *
 * @return {object} in which each property is the stringification of the respective array's element
 *                  and its value is the element itself
 *                  ex: {
 *                        "c": "c"
 *                        {"a":"a","c":"c"}: {a: "a", c: "c"}
 *                        {"b":"b"}: {b: "b"}
 *                      }
 */
const createDictFromArray = <T>(array: T[]): Record<string, T> => {
  const hash: Record<string, T> = {};
  array.forEach((el: T) => (hash[JSON.stringify(el)] = el));
  return hash;
};

/**
 * difference - Generates an array out of 2 arrays which is composed of the elements of the first one
 *              that does not exist on the second one
 *
 * @param {array} array1 (ex: [1, 2, 3, 4])
 * @param {array} array2 (ex: [3, 4, 5, 6])
 *
 * @return {array} ex: [1, 2]
 */
export const difference = <T>(array1: T[], array2: T[]) => {
  return array1.filter((item1) => !array2.some((item2) => deepEqual(item1, item2)));
};

/**
 * differenceInArraysWithKey - Generates an array out of 2 arrays which is composed of the elements of
 *                             the first one which given key value does not exist on the second one
 *
 * @param {array} arr1 of objects (ex: [{a: x}, {a: y}, {a: w}])
 * @param {array} arr2 of objects (ex: [{a: x}, {a: z}])
 * @param {string or number} key used to verify equality between objects (ex: 'a')
 *
 * @return {array} ex: ([{a: y}, {a: w}])
 */
export const differenceInArraysWithKey = <T>(arr1: T[] = [], arr2: T[] = [], key: keyof T) =>
  arr2.filter((item) => !arr1.find((item2) => item[key] === item2[key]));

/**
 * differenceInArraysWithKeys - Generates an array out of 2 arrays which is composed of the elements of
 *                              the first one which no given key values exist on the second one
 *
 * @param {array} arr1 of objects (ex: [{a: x}, {a: y}, {a: w}])
 * @param {array} arr2 of objects (ex: [{a: x}, {a: z}])
 * @param {string or number} key used to verify equality between objects (ex: 'a')
 *
 * @return {array} ex: ([{a: y}, {a: w}])
 */
export const differenceInArraysWithKeys = <T>(arr1: T[] = [], arr2: T[] = [], keys: Array<keyof T>) =>
  arr2.filter((item) => !arr1.find((item2) => !keys.some((key) => item[key] !== item2[key])));

/**
 * elementToArray — Puts element in an array in case it isn't an array already
 *
 * @param {any} element
 *
 * @returns {array} element, if element is an array
 *                  [element] otherwise
 */
export const elementToArray = (element: unknown): Array<unknown> => {
  if (isArray(element)) return element;
  return [element];
};

/**
 * findLast - Finds the last occurrence in array given filtering function
 *
 * @param {array} array (e.g. [1, 2, 3, 4])
 * @param {function} fn (e.g. (x) => x > 2)
 *
 * @returns {any} (e.g. 4)
 */
export const findLast = <T>(array: Array<T>, fn: () => T) => array.slice().reverse().find(fn);

/**
 * firstElement - Selects the first element of the array checking for the array size
 *
 * @param {array} array
 *
 * @returns {any} first element of the array or undefined if array is null
 */
export const firstElement = <T>(array: Array<T>) => (isArray(array) ? array[0] : undefined);

/**
 * flatten - Flats an array
 *
 * @param {array} array (ex: [[1, 2], [3]])
 *
 * @return {array} (ex: [1, 2, 3])
 */
export const flatten = <T>(array: T[][]) =>
  array.reduce((reducer, item) => (isArray(item) ? [...reducer, ...item] : [...reducer, item]), []);

/**
 * groupBy - Generates a curried function that returns an array which is the @param collection
 *           grouped by @param key
 *
 * @param {function, string or number} key by which the collection will be grouped by (ex: (x) => x > 3)
 * @param {array} collection (ex: [1, 2, 3, 4, 5])
 *
 * @return {function} curried function that groups by a collection by a key
 *                    ex: [true: [4, 5], false: [1, 2, 3]]
 */
export const groupBy = curry(
  (key: string | number | ((param: any) => string), collection: Array<Record<string, any>>) =>
    collection.reduce((previous: Record<string, any>, item) => {
      /* eslint-disable no-param-reassign */
      const objectKey = typeof key === 'function' ? key(item) : item[key];
      previous[`${objectKey}`] = previous[`${objectKey}`] || [];
      previous[`${objectKey}`].push(item);
      return previous;
    }, {})
);

/*
 * hashArray - hashes an array to an object given arbitrary keyExtractor and
 * valueExtractor functions
 *
 * @param array - the array to be hashed
 * @param options - one or more of the following options:
 *
 *   - keyExtractor: a function that receives an element of the array and its
 *   index and return a key (string). By default it returns its index
 *
 *   - valueExtractor: a function that receives an element of the array and
 *   returns some type of value. By default it returns the own element. Default
 *   (element) => element
 *
 *   - unique: when set to true, the hash will return only one element for each
 *   key, the last one that matches, instead of an array of elements. Default
 *   false
 *
 * @returns a object that hashes the array. If this is not possible, then
 * return an empty object.
 *
 * Examples:
 *
 *    > const data = [
 *    >  { name: 'one', type: 0 },
 *    >  { name: 'two', type: 1 },
 *    >  { name: 'three', type: 0 },
 *    >  { name: 'four', type: 2 },
 *    >  { name: 'five', type: 1 },
 *    >  { name: 'six', type: 3 },
 *    >  { name: 'seven', type: 1 },
 *    >  { name: 'eight', type: 2 }
 *    > ];
 *    > const hashed = hashArray(data, {
 *    >   keyExtractor: (element) => element.type,
 *    >   valueExtractor: (element) => element.name,
 *    > });
 *
 *    {
 *      0: ['one', 'three'],
 *      1: ['two', 'five', 'seven'],
 *      2: ['four', 'eight'],
 *      3: ['six']
 *    }
 */
export const hashArray = <T, R = T>(array: T[], options: Record<string, unknown> = {}) => {
  const { keyExtractor, valueExtractor, unique } = {
    keyExtractor: (_: unknown, index: number): number => index,
    valueExtractor: (element: T, _: number) => element as unknown as R,
    unique: false,
    ...options,
  };
  try {
    return array.reduce((current: Record<string, Array<string>>, element, index) => {
      const key = keyExtractor(element, index);
      if ((!isString(key) && !isNumber(key)) || (key as unknown as string) === '') {
        throw new TypeError('keyExtractor should return non empty string or number');
      }
      const value = valueExtractor(element, index);
      const previousValue = current[key] || [];
      const newValue = unique ? value : [...previousValue, value];
      return { ...current, [key]: newValue };
    }, {});
  } catch (_) {
    return {};
  }
};

/**
 * hasValue - Checks if the array has the value and returns a boolean indicating the same
 *
 * @param {Array} arr to find value in (ex: [1, 2, 3, 4])
 * @param {any} value to find (ex: 4)
 *
 * @returns {boolean}  (ex: true)
 */
export const hasValue = <T>(arr: Array<T | undefined | null>, value: T | undefined | null): boolean =>
  arr.indexOf(value) > -1;

/** initializeArray - Generates an array of size the given size in which its elements will be
 *                    generated by the given f(index)
 *
 * @param {integer} size of the array (ex: 5)
 * @param {function} f which will be responsible for generating the elements (ex: (x) => x * 2)
 *
 * @return {array} (ex: [0, 2, 4, 6, 8])
 */
export const initializeArray = (size: number, f: (index: number) => unknown) =>
  Array.apply(null, Array(size)).map((_, idx) => f(idx));

/**
 * insertAfter - Inserts to the given array a the given element in the position after the given index
 *
 * @param {number} index (ex: 2)
 * @param {array} array (ex: [1, 2, 3, 4, 5])
 * @param {any} value (ex: 'a')
 *
 * @return {array} (ex: [1, 2, 3, "a", 4, 5])
 */
export const insertAfter = <T, V>(index: number, array: Array<T>, value: V): Array<T & V> => {
  const dup = [...array];
  // @ts-ignore
  dup.splice(index + 1, 0, value);
  // @ts-ignore
  return dup;
};

/**
 * insertBefore - Inserts to the given array the given element in the position before the given index
 *
 * @param {number} index (ex: 2)
 * @param {array} array (ex: [1, 2, 3, 4, 5])
 * @param {any} value (ex: 'a')
 *
 * @return {array} (ex: [1, 2, "a", 3, 4, 5])
 */
export const insertBefore = <T>(index: number, array: Array<T>, value: T) => {
  const dup = [...array];
  dup.splice(index, 0, value);
  return dup;
};

/**
 * intersection - Generates an array which is the intersection between the given array1 and the given array2
 *
 * @param {array} array1 (ex: [1, 2, 3, 4, 5])
 * @param {array} array2 (ex: [4, 5, 6, 7, 8])
 *
 * @return {array} (ex: [4, 5])
 */
export const intersection = (array1: Array<unknown> = [], array2: Array<unknown> = []) =>
  array1.filter((value) => !!array2.find((_value) => deepEqual(value, _value)));

/**
 * isEmpty - Checks if the given array is empty
 *
 * @param {array} array
 *
 * @return {boolean} true if @param array is empty,
 *                   false otherwise
 */
export const isEmpty = (array: unknown[] = []) => array.length === 0;

/**
 * lastElement - Returns the last element of the given array
 *
 * @param {T[]} array
 *
 * @return {T|undefined} the last element of the given array
 */
export const lastElement = <T>(array: Array<T>) => (isArray(array) ? array[array.length - 1] : undefined);

/**
 * matchItem - Verifies if array contains value
 *
 * @param {array} items
 * @param {any} searchValue
 *
 * @return {any} searchValue, if the given array contains value
 *                            false, if the given array does not contain value
 *                            undefined, if the given value is undefined
 */
export const matchItem = (items: Array<unknown>, searchValue: string) =>
  !!searchValue && items.find((item) => item === searchValue);

/**
 * moveElement - Moves the element at fromIndex to toIndex.
 *
 * @param {array} array (ex: [1, 2, 3])
 * @param {number} fromIndex (ex: 2)
 * @param {number} toIndex (ex: 1)
 *
 * @return {array} (ex: [1, 3, 2])
 */
export const moveElement = <T>(array: Array<T>, fromIndex: number, toIndex: number) => {
  if (fromIndex === toIndex || fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length)
    return array;

  const element = array[fromIndex];
  const clonedArray = clone(array);

  clonedArray.splice(fromIndex, 1);
  clonedArray.splice(toIndex, 0, element);

  return clonedArray;
};

/**
 * nextIndexOf - Generates the next valid index of the given array after the given index
 *
 * @param {*} index (ex: 3)
 * @param {*} array (ex: [0, 1, 2, 3])
 *
 * @param {integer} (ex: 0)
 */
export const nextIndexOf = (index: number, array: Array<unknown>) => normalizeOverflowingIndex(index + 1, array);

/**
 * nextValueByKeyOf - Finds the element in the given array that is after element which has property
 *                    the given key that equals the given value
 *
 * @param {any} value (ex: 5)
 * @param {string or number} key (ex: 'a')
 * @param {array} array (ex: [{a: 4, b: 10}, {a: 5, b: 20}, {a: 6, b: 30}])
 *
 * @return {object} {a: 6, b: 30}
 *                  if the given value is located in the last position of the given array,
 *                  it will be returned the first element of the given array
 */
export const nextValueByKeyOf = (value: unknown, key: string | number, array: Array<Record<string, unknown>>) =>
  array[normalizeOverflowingIndex(array.map((val) => val[key]).indexOf(value) + 1, array)];

/**
 * nextValueOf - Retrieves the value after the given value in the given array
 *
 * @param {any} value (ex: 5)
 * @param {array} array (ex: [3, 4, 5, 6])
 *
 * @return {any} (ex: 6)
 *               if @param value is located in the last position of the given array,
 *               it will be returned the first element of the given array
 */
export const nextValueOf = (value: unknown, array: Array<unknown>) =>
  array[normalizeOverflowingIndex(array.indexOf(value) + 1, array)];

/**
 * normalizeOverflowingIndex - Generates a valid index in the given array which is the modulo operation of
 *                             the given index + length of the given array by the length of the given array
 *
 * @param {integer} index (ex: 4)
 * @param {*} array (ex: [0, 1, 2, 3])
 *
 * @return {integer} (ex: 0)
 */
export const normalizeOverflowingIndex = (index: number, array: Array<unknown>) =>
  Math.abs((index + array.length) % array.length);

/**
 * onSelect - Toggles the existence of a value in an array. In other words, if the value exists in the array,
 *            it will be removed, if it does not exist in the array, it will be added.
 *
 * @param {any} value the respective value
 * @param {array} array the array that will have the value added or removed
 * @param {function} setArrayFn the function that will be called to set the array result
 *
 * @returns {array} which is the final array
 */
export const onSelect = (
  value: Record<string, unknown>,
  array: Array<Record<string, unknown>>,
  setArrayFn: (array: Array<unknown>) => unknown
) => {
  const newArray = toggleValues([value], array);
  setArrayFn(newArray);
  return newArray;
};

/**
 * pairwise - Generates an array of arrays where each sub array has length 2 and its elements are the
 *            closest two elements in the given array
 *
 * @param {array} array (ex: [1, 2, 3, 4])
 *
 * @return {array} (ex: [[1, 2], [2, 3], [3, 4]])
 */
export const pairwise = (array: Array<number>) =>
  array.reduce((acc: Array<Array<number>>, currentItem, index) => {
    const nextItem = array[index + 1];
    if (nextItem) {
      acc.push([currentItem, nextItem]);
    }
    return acc;
  }, []);

/**
 * previousIndexOf - Retrieves a valid index previous to the given index in the given array
 *
 * @param {integer} index (ex: 0)
 * @param {array} array (ex: [1, 2, 3])
 *
 * @return {integer} (ex: 2)
 */
export const previousIndexOf = (index: number, array: Array<unknown>) => normalizeOverflowingIndex(index - 1, array);

/**
 * previousValueByKeyOf - Finds the element in the given array that is before element which has property
 *                        the given key that equals the given value
 *
 * @param {any} value (ex: 5)
 * @param {string or number} key (ex: 'a')
 * @param {array} array (ex: [{a: 4, b: 10}, {a: 5, b: 20}, {a: 6, b: 30}])
 *
 * @return {object} {a: 4, b: 10}
 *                  if the given value is located in the first position of the given array,
 *                  it will be returned the last element of the given array
 */
export const previousValueByKeyOf = (value: number, key: string, array: Array<Record<string, unknown>>) =>
  array[normalizeOverflowingIndex(array.map((val) => val[key]).indexOf(value) - 1, array)];

/**
 * previousValueOf - Retrieves the value before the given value in the given array
 *
 * @param {any} value (ex: 5)
 * @param {array} array (ex: [3, 4, 5, 6])
 *
 * @return {any} (ex: 4)
 *               if the given value is located in the first position of the given array,
 *               it will be returned the last element of the given array
 */
export const previousValueOf = (value: number, array: Array<unknown>) =>
  array[normalizeOverflowingIndex(array.indexOf(value) - 1, array)];

/**
 * propSearchFilter - Filters the array the given items by the elements that have the property the given prop
 *                    with value the given searchValue
 *
 * @param {array} items of objects (ex: [{ country: 'Australia' }, { country: 'Brazil' }, { country: 'Germany' }])
 * @param {string} prop property which may contain @param searchValue (ex: 'country')
 * @param {*} searchValue which one may want to filter by (ex: 'Australia')
 *
 * @return {array} containing the elements that possesses the desired give  prop with the desired given searchValue
 *                 ex: ({ country: 'Australia })
 */
export const propSearchFilter = (items: Array<Record<string, string>>, prop: string | number, searchValue: string) =>
  searchValue
    ? items.filter((item) => item[prop] && item[prop].toLowerCase().includes(searchValue.toLowerCase()))
    : items;

/**
 * pushElement - Pushes the given element to the given array
 *
 * @param {array} array (ex: [1, 2, 3])
 * @param {any} element (ex: 4)
 *
 * @return {array} which is the given array with the given element pushed to it
 *                 (ex: [1, 2, 3, 4])
 */
export const pushElement = (array: Array<unknown>, element: unknown) => {
  return [...array, element];
};

/**
 * pushToFirst - Finds the given filterParam in the given array and moves it to the first position
 *
 * @param {array} array of objects
 * @param {object} filterParam possible on element which is in @param array
 *
 * @return {array} if the given filterParam exists in the given array,
 *                  returns the given array with the given filterParam in the first position
 *                 else, return the given array
 */
export const pushToFirst = (array: Array<Record<string, unknown>>, filterParam: Record<string, unknown>) => {
  const index = array.findIndex((elem) => Object.keys(filterParam).every((key) => filterParam[key] === elem[key]));
  return index !== -1 ? [array[index], ...removeElement(index, array)] : array;
};

/**
 * range - Generate an array of numbers from start to end
 *
 * @param {number} start first number of the range (e.g. 2010)
 * @param {number} end last number of the range (e.g. 2020)
 *
 * @returns {array} array with the numbers within the range (e.g. [2010, 2011, ..., 2019, 2020])
 */
export const range = (start: number, end: number) => {
  const smallerNumber = Math.min(start, end);
  const biggerNumber = Math.max(start, end);

  return Array.from({ length: biggerNumber - smallerNumber + 1 }, (_, index) => index + smallerNumber);
};

/**
 * removeElement - Removes an element from the given array located in the given index
 *
 * @param {integer} index which is a valid index of the given array (ex: 1)
 * @param {array} array (ex: [1, 2, 3, 4])
 *
 * @return {array} array which is the given array without the index-th element (ex: [1, 3, 4])
 */
export const removeElement = <T>(index: number, array: T[]) => {
  return index >= 0 && index < array.length ? [...array.slice(0, index), ...array.slice(index + 1)] : array;
};

/**
 * reverse - Reverses the given array
 *
 * @param {array} array (ex: [1, 2, 3, 4])
 *
 * @return {array} which is the given array reversed  (ex: [4, 3, 2, 1])
 */
export const reverse = <T>(array: T[]) => [...array].reverse();

/**
 * sameValues - Checks if two arrays possesses the same values
 *
 * @param {array} a (ex: [1, 2, 3, 4])
 * @param {array} b (ex: [4, 3, 2, 1])
 *
 * @return {boolean} true, if the given a and the given b contain the same elements
 *                   false, otherwise
 *                   (ex: true)
 */
export const sameValues = (a: Array<unknown>, b: Array<unknown>) =>
  a.length === b.length && a.every((item) => b.includes(item));

/**
 * searchFilter - Filters the given array for the elements that possesses the given value for the given key
 *
 * @param {array} items array of objects (ex: [{a: '1', b: '2'}, {a: '1', c: '3'}, {a: '2', c: '3'}])
 * @param {any} searchValue (ex: '1')
 * @param {integer or string} key (ex: 'a')
 *
 * @return {array} (ex: [{a: "1", b: "2"}, {a: "1", c: "3"}])
 */
export const searchFilter = (items: Array<Record<string, unknown>>, searchValue: string, key: string) =>
  searchValue
    ? items.filter((item) =>
        JSON.stringify(key ? item[key] : item)
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      )
    : items;

/**
 * sortBy - Generates a function which sorts an array according to the given property prop
 *          (example of a call to this function: array.sortBy('name', 'desc'))
 *
 * @param {string or integer} prop which is a valid property of the array responsible for calling this function
 * @param {string} order if 'asc', sorts in the ascending order
 *                       else, sorts in the descending order
 *
 * @return {function} which is responsible for sorting an array in the ascending or descending order
 *                    according to the given prop property
 */
export const sortBy =
  (prop: string | number, order = 'asc') =>
  (a: Record<string, string>, b: Record<string, string>) => {
    const itemA = isString(a[prop]) ? a[prop].toLowerCase() : a[prop].toString();
    const itemB = isString(b[prop]) ? b[prop].toLowerCase() : b[prop].toString();

    return order === 'asc' ? itemA.localeCompare(itemB) : itemB.localeCompare(itemA);
  };

/**
 * sortByKey - Sorts the given array by the given key in the given order
 *
 * @param {array} array (ex: [{a: 1}, {a: 0}])
 * @param {number or string} key (ex: 'a')
 * @param {string} order 'asc' for ascending sorting or 'desc' for descending sorting
 *
 * @return {array} sorted (ex: [{a: 0}, {a: 1}])
 */
export const sortByKey = (array: Array<Record<string, number>>, key: number | string, order = 'asc'): Array<unknown> =>
  order === 'desc' ? sortByKeyDesc(array, key) : sortByKeyAsc(array, key);

/**
 * sortByKeyAsc - Sorts the given array by the given key in ascending order
 *
 * @param {array} array (ex: [{a: 1}, {a: 0}])
 * @param {number or string} key (ex: 'a')
 *
 * @return {array} sorted (ex: [{a: 0}, {a: 1}])
 */
const sortByKeyAsc = (array: Array<Record<string, number>>, key: number | string) =>
  [...array].sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });

/**
 * sortByKeyDesc - Sorts the given array by the given key in descending order
 *
 * @param {array} array (ex: [{a: 0}, {a: 1}])
 * @param {number or string} key (ex: 'a')
 *
 * @return {array} sorted (ex: [{a: 1}, {a: 0}])
 */
const sortByKeyDesc = (array: Array<Record<string, number>>, key: number | string) =>
  [...sortByKey(array, key)].reverse();

/**
 * splitIntoChunks - Split array into chunks of "chunkSize"
 *
 * @param {array of unknown} array
 * @param {number} chunkSize
 *
 * @returns {array of arrays of unknown}
 */
export const splitIntoChunks = (array: Array<unknown>, chunkSize: number) =>
  array.reduce((resultArray: Array<Array<unknown>>, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

/**
 * sumBy - Generates a curried function that reduces the given collection to a number which is the sum of
 *         all the given key properties
 *
 * @param {string or number} key (ex: 'a')
 * @param {array} collection (ex: [{a: 5, b: 100}, {a: 5, b: 200}, {a: 10, b: 300}])
 *
 * @return {function} curried function that reduces @param collection to a number which is the sum of
 *         all the given key properties (ex: 20)
 */
export const sumBy = curry((key: string, collection: Array<Record<string, number>>) =>
  collection.reduce((previous, item) => item[key] + previous, 0)
);

/**
 * symmetricDifference - Generates an array out of 2 arrays which is composed of the elements that exist only
 *                       in one of them
 *
 * @param {array} array1 (ex: [1, 2, 3, 4])
 * @param {array} array2 (ex: [3, 4, 5, 6])
 *
 * @return {array} ex: [1, 2, 5, 6]
 */
export const symmetricDifference = (array1: Array<Record<string, unknown>>, array2: Array<Record<string, unknown>>) =>
  difference(array1, array2).concat(difference(array2, array1));

/**
 * toggleValue - Toggles the given value in the given array
 *
 * @param {any} value (ex: 3)
 * @param {array} array (ex: [{ property: 1 }, { property: 2 }, { property: 3 }])
 * @param {function} fn a function that can be used to check if the given value is present in the given array
 *                      (ex: (value, element) => element.property === value)
 *
 * @return {array} (ex: [{ property: 1 }, { property: 2 }])
 *                 if the given array has the given value, the element will be removed,
 *                 else, the element will be added
 */
export const toggleValue = (
  value: number,
  array: Array<number> = [],
  fn: (param1: number, param2: number) => boolean
) => {
  const valueIsInArray = fn ? array.some((elem) => fn(value, elem)) : array.includes(value);
  const filterFunction = fn ? (elem: number) => !fn(value, elem) : (elem: number) => elem !== value;
  return valueIsInArray ? array.filter(filterFunction) : [...array, value];
};

/**
 * toggleValues - Toggles the given values in the given array
 *
 * @param {any} value (ex: [5, 6])
 * @param {array} array (ex: [1, 2, 3, 4, 5])
 *
 * @return {array} (ex: [1, 2, 3, 4, 6])
 *                 the values already present will be removed and the ones
 *                 missing will be added
 */
export const toggleValues = <T>(values: Array<T> = [], array: Array<T> = []): Array<T> => {
  const valuesToRemove = intersection(values, array);
  const valuesToAdd = difference(values, array);
  return [
    ...array.filter((element) => !valuesToRemove.find((_element) => deepEqual(element, _element))),
    ...valuesToAdd,
  ];
};

/**
 * unique - Generates an array with unique elements of the given array
 *
 * @param {array} array (ex: [1, 2, 3, 1, 2, 3, 3])
 *
 * @return {array} which possesses unique elements of the given array (ex: [1, 2, 3])
 */
export const unique = <T>(array: Array<T>) => Array.from(new Set(array));

/**
 * unique - Generates an array with unique elements of the given array
 *
 * @param {array} array (ex: [[1, 2], [1, 3], [1, 2]])
 *
 * @return {array} which possesses unique elements of the given array (ex: [[1, 2], [1, 3]])
 */
export const uniqueArrayOfArrays = <T>(array: Array<Array<T>>) => {
  const arraySet = new Set(array.map((element) => JSON.stringify(element)));
  return Array.from(arraySet).map((element) => JSON.parse(element));
};

/**
 * unique - Generates an array with unique elements of 'array' comparing the giving key
 *
 * @param {array} array (ex: [{a: 1, b: 2}, {a: 2, b: 2}, {a: 3, b: 2}, {a: 1, b: 2}, {a: 2, b: 2}, {a: 3, b: 2}, {a: 3, b: 2}])
 * @param {string or number} key (e.g. "a")
 *
 * @return {array} which possesses unique elements of 'key' in array (ex: [{a: 1, b: 2}, {a: 2, b: 2}, {a: 3, b: 2}])
 */
export const uniqueWithKey = (array: Array<Record<string, unknown>>, key: string) =>
  unique(array.map((element) => element[key])).map((id) => {
    return {
      ...array.find((element) => element[key] === id),
    };
  });

/**
 * isUniqueWithKey - checks if an array has only one unique element with the given key
 * Note: This won't work on an empty array !!!
 *
 * @param {array} array (ex: [{a: 1, b: 11}, {a: 1, b: 22}, {a: 1, b: 33}, {a: 1, b: 44}])
 * @param {string or number} key (ex: 'a' or 1)
 *
 * @return {array} true or false (ex: true)
 */
export const isUniqueWithKey = (array: Array<unknown>, key: string) => {
  if (!Array.isArray(array)) return false;
  if (array.length === 0) return false;
  if (array.length === 1) return true;
  return array.every((element) => !!element[key] && element[key] === array[0][key]);
};

/**
 * updateAtIndex - Updates the given index-th element of the given array by the given mergeValues
 *
 * @param {integer} index 0
 * @param {object} mergeValues (ex: {a: 1})
 * @param {array} array (ex: [{a: 0, b: 0}])
 *
 * @param {array} (ex: [{a: 1, b: 0}])
 */
export const updateAtIndex = curry(<T>(index: number, mergeValues: Array<T>, array: Array<Record<string, T>>) => {
  if (index === void 0) {
    return array;
  }
  const dup = [...array] as unknown as Array<T>;
  dup[index] = { ...dup[index], ...mergeValues };

  return dup;
});

/**
 * updateLastElement - Updates the last element of the given array by the given object
 *
 * @param {object} object (ex: {a: 1})
 * @param {array} array (ex: [{a: 0, b: 0}])
 *
 * @param {array} (ex: [{a: 1, b: 0}])
 */
export const updateLastElement = curry((object: Record<string, unknown>, array: Array<Record<string, unknown>>) => {
  // eslint-disable-next-line no-shadow
  const lastElement = array[array.length - 1];
  if (!lastElement) {
    return array;
  }

  const dup = [...array];
  return [...dup.slice(0, dup.length - 1), { ...lastElement, ...object }];
});

/**
 * valueAt - Retrieves the given index-th element of the given array
 *
 * @param {integer} index (ex: 0)
 * @param {array} array (ex: ['a', 'b', 'c'])
 *
 * @param {any} (ex: 'a')
 */
export const valueAt = <T>(index: number, array: Array<T>): T => array[normalizeOverflowingIndex(index, array)];

/**
 * withoutFirst - Removes the first element of the given array
 *
 * @param {array} array [1, 2, 3]
 *
 * @return {array} which is the given array without the first element
 *                 [2, 3]
 */
export const withoutFirst = <T extends {}>(array: T[]) => [...array].slice(1);

/**
 * toArray - Converts the given element to an array
 *
 * @param {any} element (ex: 'a')
 *
 * @return {array} which is the given element converted to an array
 */
export const toArray = <T extends {}>(element: T | T[]) => {
  if (Array.isArray(element)) {
    return element;
  }
  return [element];
};
