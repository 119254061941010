import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadContext = memoize(
  {},
  ({ api }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_CONTEXT',
        fn: () => api.getContext(),
      })
);

export const requestPasswordReset =
  ({ api, userIdentifier }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'REQUEST_PASSWORD_RESET',
      fnParams: { userIdentifier },
      fn: (params) => api.requestPasswordReset(params),
    });
