import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { callFunctionWhenPropsChange } from '../../lib';
import * as selectors from '../../selectors';
import { collections, time } from '../../utils';

import { stampChainToPeriod } from '../time-tracking-stamp-watch/period';
import { loadActiveStamps } from '../terminal-time-tracking-stamp-watch/utils';
import Organism from './organism';

const { composeReverse } = collections;
const { formatIsoTimeShort } = time;

const { selectOrderedActiveStampChainForUserId } = selectors;

const mapStateToProps = (state, props) => {
  const timestampChain = selectOrderedActiveStampChainForUserId(state, { ...props, ...props.member });
  const period = stampChainToPeriod(timestampChain);

  return {
    ...props,
    clockedInSince: formatIsoTimeShort(period.clockInTimestamp),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  functionToCall: () => {
    if (ownProps.shouldLoadStamps === false) return;
    dispatch(loadActiveStamps({ ...ownProps, ...ownProps.member, unmemoized: true }, ownProps.member.userId));
  },
});

const TerminalMemberTimeTrackingInfoContainer = composeReverse(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['member'] })
)(Organism);

export default TerminalMemberTimeTrackingInfoContainer;
