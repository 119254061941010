import produce from 'immer';
import { array } from '../utils';

const initialState = produce({ templates: [] }, () => {});
const { applyMinimalChangesToArray } = array;
// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const templatesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_TEMPLATES_SUCCESS':
        applyMinimalChangesToArray(draft.templates, action.result, (template) => template.crewId === action.crewId);
        break;
      case 'CREATE_TEMPLATE_SUCCESS':
      case 'UPDATE_TEMPLATE_SUCCESS':
        applyMinimalChangesToArray(draft.templates, action.result, (template) => template.id === action.templateId);
        break;
      case 'DELETE_TEMPLATE_SUCCESS':
        applyMinimalChangesToArray(draft.templates, [], (template) => template.id === action.templateId);
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
