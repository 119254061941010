/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction, buildDangerouslyTranslateNeverWithUserContent } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    cancel: 'Abbrechen',
    clockedInSince: 'Eingestempelt seit {{time}} Uhr',
    disableInputWarning: 'Stempeln ist auf diesem Gerät deaktiviert',
    header: 'Zeitkategorie auswählen',
    inPause: 'In Pause',
    noBreakRecord: 'Noch keine Pause erfasst',
    overlongPeriod: 'Sie arbeiten seit über 30 Stunden. Sie sollten sich ausruhen.',
    permissionDenied: 'Fehlende Berechtigung für Standorterfassung',
    positionRetrieved: 'Standort wurde ermittelt',
    positionUnavailable: 'Standort wurde nicht ermittelt',
    save: 'Speichern',
    stopWork: 'Ausstempeln',
    timeout: 'Positionsdaten waren zu ungenau ({{accuracy}} Meter)',
    workedFor: '{{duration}} Arbeitszeit',
  },
  en: {
    cancel: 'Cancel',
    clockedInSince: 'clocked in since {{time}}',
    disableInputWarning: 'Time clocking is disabled on this device',
    header: 'Select time category',
    inPause: 'In pause',
    noBreakRecord: 'No break recorded yet',
    overlongPeriod: 'You’ve been working for over 30 hours, you should get some rest.',
    permissionDenied: 'Location permission denied',
    positionRetrieved: 'GPS retrieved',
    positionUnavailable: "GPS wasn't found",
    save: 'Save',
    stopWork: 'Stop work',
    timeout: 'Location data was too inaccurate ({{accuracy}} metres)',
    workedFor: 'Worked for {{duration}}',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
