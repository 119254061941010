import PropTypes from 'prop-types';
import React from 'react';
import { collections, array, react } from '../../utils';
import MemberBadge from './member-badge';

import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { ArrowLeft, ArrowRight } from './arrow-buttons';

const { composeReverse } = collections;
const { prepareProp } = react;
const { nextValueByKeyOf, previousValueByKeyOf } = array;

class Template extends React.Component {
  onKeyDown = (event) => {
    // Only allow hacker mode when body is focused. Otherwise form elements can't be used correctly.
    const isBodyFocused = global.document.activeElement === global.document.body;

    if (isBodyFocused && event.key === 'ArrowRight') {
      this.props.onNextClick();
    }
    if (isBodyFocused && event.key === 'ArrowLeft') {
      this.props.onPrevClick();
    }
  };

  componentDidMount() {
    global.document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    global.document.removeEventListener('keydown', this.onKeyDown);
  }

  render() {
    const {
      selected,
      multipleMembers,

      onNextClick,
      onPrevClick,
      children,
      onChange,
      options,
      collection,
      spacingBottom = false,
    } = this.props;

    return (
      <nav className={css(styles.container, spacingBottom && styles.spacingBottom)}>
        {multipleMembers && <ArrowRight onClick={onPrevClick} />}

        <div className={css(styles.spacing)}>
          <MemberBadge member={selected} disable={!!selected.disabledAt}>
            {children}
          </MemberBadge>
          <If condition={multipleMembers}>
            <select
              className={css(styles.select)}
              onChange={(evt) => onChange(collection.find((member) => `${member.id}` === `${evt.target.value}`))}
              value={`${selected.id}`}
            >
              {options.map(({ label, value: _value }, index) => (
                <option key={`options-${index}-${_value}`} value={_value}>
                  {label}
                </option>
              ))}
            </select>
          </If>
        </div>

        {multipleMembers && <ArrowLeft onClick={onNextClick} />}
      </nav>
    );
  }
}

const BadgeNavigation = composeReverse(
  prepareProp('multipleMembers', ({ collection }) => collection.length > 1),

  prepareProp('nextItem', ({ collection, selected, selectedKey = 'id' }) =>
    nextValueByKeyOf(selected[selectedKey], selectedKey, collection)
  ),
  prepareProp(
    'onNextClick',
    ({ nextItem, onChange }) =>
      () =>
        onChange(nextItem)
  ),

  prepareProp('prevItem', ({ collection, selected, selectedKey = 'id' }) =>
    previousValueByKeyOf(selected[selectedKey], selectedKey, collection)
  ),
  prepareProp(
    'onPrevClick',
    ({ prevItem, onChange }) =>
      () =>
        onChange(prevItem)
  ),
  prepareProp('options', ({ collection }) =>
    collection.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  )
)(Template);

BadgeNavigation.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
  }).isRequired,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatarUrl: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.any,
  spacingBottom: PropTypes.bool,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spacing: {
    margin: '0 20px',
    width: '280px',
    position: 'relative',
  },
  select: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  spacingBottom: {
    marginBottom: '20px',
  },
});

export default BadgeNavigation;
