import { createSelector } from 'reselect';
import { selectCurrentUserId } from './authentication-selectors';
import { selectCurrentCrewId } from './crews-selectors';
import { findByProps, selectByProps, createPropsSelector, createPropsFinder } from './utils';
import { selectMembers } from './crew-members-selectors';

export const selectPermissions = (state) => state.bo.permissions.permissions;

export const selectCurrentCrewPermissions = createSelector(
  selectPermissions,
  selectCurrentCrewId,
  (permissions, crewId) => permissions.filter((permission) => permission.crewId === crewId)
);

export const selectPermissionsForMembers = createSelector(
  selectPermissions,
  selectMembers,
  selectCurrentUserId,
  (permissions, members, activeUserId) => {
    return (permissions ? members : [])
      .map((member) => {
        const crewPermissions = selectByProps(permissions, { crewId: member.crewId });
        const userId = member.userId;
        const crewId = member.crewId;

        const canUseAbsenceManagement = findByProps(
          crewPermissions,
          { name: 'canUseAbsenceManagement' },
          {},
          'allowed'
        );
        const canUseTimeTracking = findByProps(crewPermissions, { name: 'canUseTimeTracking' }, {}, 'allowed');
        const canManageAbsences = findByProps(crewPermissions, { name: 'canManageAbsences' }, {}, 'allowed');
        const canSeeCrewAbsences = findByProps(crewPermissions, { name: 'canSeeCrewAbsences' }, {}, 'allowed');
        const canSeeCrewReports = findByProps(crewPermissions, { name: 'canSeeCrewReports' }, {}, 'allowed');
        const canSeeCrewTimeTrackingStamps = findByProps(
          crewPermissions,
          { name: 'canSeeCrewTimeTrackingStamps' },
          {},
          'allowed'
        );

        const createPermission = ({ name, firstPermission, secondPermission }) => ({
          crewId,
          name,
          allowed: firstPermission && (activeUserId === userId || secondPermission),
        });

        const newPermissions = [
          createPermission({
            name: 'canSeeAbsenceDetails',
            firstPermission: canUseAbsenceManagement,
            secondPermission: canManageAbsences,
          }),
          createPermission({
            name: 'canSeeAbsences',
            firstPermission: canUseAbsenceManagement,
            secondPermission: canSeeCrewAbsences,
          }),
          createPermission({
            name: 'canSeeReports',
            firstPermission: canUseTimeTracking,
            secondPermission: canSeeCrewReports,
          }),
          createPermission({
            name: 'canSeeTimeTrackingStamps',
            firstPermission: canUseTimeTracking,
            secondPermission: canSeeCrewTimeTrackingStamps,
          }),
          createPermission({
            name: 'canSeeDurations',
            firstPermission: canUseTimeTracking,
            secondPermission: canSeeCrewReports,
          }),
        ];

        return crewPermissions.concat(newPermissions).map((permission) => ({ ...permission, userId }));
      })
      .reduce((array, item) => array.concat(item), []);
  }
);

export const selectPermissionsByProps = createPropsSelector(selectPermissionsForMembers);
export const findPermissionsByProps = createPropsFinder(selectPermissionsForMembers);
