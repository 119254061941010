import { subDays, subMonths, subWeeks, subYears } from 'date-fns';

import {
  addSeconds as _addSeconds,
  asHours,
  asMinutes,
  asSeconds as _asSeconds,
  isValid,
  toFragments,
  whenInvalid,
} from 'pomeranian-durations';
import { DurationType } from '../types';
import { compose, pipe } from './collections';
import { leftPadNumberWithZero, toSnakeCase } from './string';

/**
 * addSeconds - Adds a quantity of seconds to a time duration in '23:59:59' format
 *
 * @param {string} isoDuration time duration (ex1: '01:00:00', ex2: '24:01:01', ex3: '-02:39:00')
 * @param {number} number quantity of seconds the user wants to add to the isoDuration
 *
 * @returns {string} time duration result in ISO format
 * ex1: '01:00:30' if number = 30
 * ex2: '24:02:01' if number = 60
 * ex3: '-02:09:00' if number = -30
 */
export const addSeconds = (isoDuration: string, number: number) =>
  compose(
    whenInvalid(() => 'P'),
    _addSeconds(number)
  )(isoDuration);

/**
 * durationToObj - Converts a time duration string in 'PT23H59M59S' format to an object containing the
 * properties hours, minutes, seconds and negative
 *
 * @param {string} duration time duration (ex1: 'PT3600S', ex2: 'PT24H1M1S', ex3: 'PT-9596.0S')
 *
 * @returns {object} duration passed as hours, minutes, seconds and negative
 * ex1: { hours: 1, minutes: 0, seconds: 0, negative: false })
 * ex2: { hours: 24, minutes: 1, seconds: 1, negative: false })
 * ex3: { hours: 02, minutes: 39, seconds: 0, negative: true })
 */
export const durationToObj = (
  duration: string
): { hours: number; minutes: number; seconds: number; negative: boolean } => {
  const parsedSeconds = parseSeconds(duration);

  const negative = parsedSeconds < 0 || (isValid(duration) && duration[2] === '-');
  const parsedSecondsAbsolute = Math.abs(parsedSeconds);
  const hours = Math.floor(parsedSecondsAbsolute / 60 / 60);
  const minutes = Math.floor((parsedSecondsAbsolute - hours * 60 * 60 - (parsedSecondsAbsolute % 60)) / 60);
  const seconds = Math.floor(parsedSecondsAbsolute - hours * 60 * 60 - minutes * 60);
  return { hours, minutes, seconds, negative };
};

/**
 * durationToTime - Converts a time duration string in 'PT23H59M59S' format to '23:59:59' format containing just the
 * negative sign (if it exists), hours and minutes
 *
 * @param {string} duration time duration (ex1: 'PT3600S', ex2: 'PT24H1M1S', ex3: 'PT-9596.0S')
 *
 * @returns {string} duration as '[-]hh:mm'
 * ex1: '01:00'
 * ex2: '24:01'
 * ex3: '-02:39'
 */
export const durationToTime = (duration: string) => formatIsoDuration(duration).slice(0, -3);

/**
 * formatDuration - Converts a time duration string in 'PT23H59M59S' format to '[-,+]00h 00m 00s' format
 *
 * @param {string} value time duration (ex1: 'PT3600S', ex2: 'PT24H1M1S', ex3: 'PT-9596.0S')
 * @param {boolean} displayZero when false and the time duration equals 0, returns an empty string
 * @param {boolean} displaySeconds when true adds the time seconds to the returned string
 * @param {boolean} displaySign when true adds the time signal (+ or -) to the returned string
 * @param {boolean} displayInitials when true adds the initials to each time ('h', 'm' and 's')
 * @param {boolean} negate when true reverses the sign generated
 *
 * @returns {string} duration as '[-,+]00h 00m 00s'
 * ex1: '+01h 00m 00s' if displaySeconds = true, displaySign = true and displayInitials = true
 * ex2: '+24h 01m 00s' if displaySeconds = true, displaySign = true and displayInitials = true
 * ex3: '-02h 39m 00s' if displaySeconds = true and displayInitials = true
 * ex4: '-24h 01m 00s' if displaySeconds = true, displaySign = true, displayInitials = true and negate = true
 */
export const formatDuration = (
  value: string,
  { displayZero = false, displaySeconds = false, displaySign = false, displayInitials = true, negate = false } = {}
) => {
  const parsedSeconds = compose(
    whenInvalid(() => 'P'),
    _asSeconds
  )(value);

  const durationToBeDisplayedIsZero = displaySeconds ? parsedSeconds === 0 : Math.abs(parsedSeconds) < 60;

  if (!displayZero && durationToBeDisplayedIsZero) {
    return '';
  }
  const { hours, minutes, seconds, negative } = durationToObj(value);
  let sign = '';
  if (!durationToBeDisplayedIsZero) {
    if (negate) {
      if (negative) {
        if (parsedSeconds < 0 && displaySign) sign = '+';
      } else {
        sign = '-';
      }
    } else {
      if (negative) {
        sign = '-';
      } else if (parsedSeconds > 0 && displaySign) {
        sign = '+';
      }
    }
  }
  return (
    sign +
    (displayInitials
      ? `${leftPadNumberWithZero(hours)}h ${leftPadNumberWithZero(minutes)}m${
          displaySeconds ? ` ${leftPadNumberWithZero(seconds)}s` : ''
        }`
      : `${leftPadNumberWithZero(hours)} : ${leftPadNumberWithZero(minutes)}${
          displaySeconds ? ` : ${leftPadNumberWithZero(seconds)}` : ''
        }`)
  );
};

/**
 * formatIsoDuration - Converts a time duration string from 'PT23H59M59S' format to '[-]23:59:59' format
 *
 * @param {string} value time duration (ex1: 'PT3600S', ex2: 'PT24H1M1S', ex3: 'PT-9596.0S')
 *
 * @returns {string} duration as '[-]hh:mm:ss'
 * ex1: '01:00:00'
 * ex2: '24:01:01'
 * ex3: '-02:39:00'
 */
export const formatIsoDuration = (value: string) => {
  const { hours, minutes, seconds, negative } = durationToObj(value);
  return (
    `${negative ? '-' : ''}` +
    `${leftPadNumberWithZero(hours)}:${leftPadNumberWithZero(minutes)}:${leftPadNumberWithZero(seconds)}`
  );
};

/**
 * durationToHours - Calculates the quantity of hours a time duration represents
 *
 * @param {string} duration time duration (ex1: 'PT60M', ex2: 'PT-2H')
 *
 * @returns {string} quantity of hours the duration passed represents rounded to an integer
 * ex1: '0.5'
 * ex2: '-2'
 */
export const durationToHours = (isoDuration: string) => `${parseInt(parseHours(isoDuration).toString(), 10)}`;

/**
 * durationToMinutes - Calculates the quantity of minutes a time duration represents
 *
 * @param {string} duration time duration (ex1: 'PT60M', ex2: 'PT-2H')
 *
 * @returns {string} quantity of minutes the duration passed represents rounded to an integer
 * ex1: '60 min'
 * ex2: '-120 min'
 */
export const durationToMinutes = (isoDuration: DurationType | string) =>
  `${parseInt(parseMinutes(isoDuration).toString(), 10)} min`;

/**
 * getGroupByForDurationApi - Generates an array of strings that one may want to use for a group by function
 *
 * @param {string} granularity property which one may want to use for a group by function
 * @param {boolean} byUser when true means that 'user_id' will be part of the groupBy array response
 * @param {boolean} groupByGranularity when true, there will be a specific element in the groupByArray which will be the granularity
 *
 * @returns {array} containing the groupBy properties. The possible responses are
 * 1. ['user_id']
 * 2. [`${granularity}`]
 * 3. [`${granularity}`, `${granularity},user_id`]
 */
export const getGroupByForDurationApi = (granularity: string, byUser: boolean, groupByGranularity: boolean = true) => {
  const groupBy = [];
  if (!granularity) {
    if (byUser) {
      groupBy.push('user_id');
    }
  } else {
    if (groupByGranularity) groupBy.push(toSnakeCase(granularity));
    if (byUser) {
      groupBy.push(`${toSnakeCase(granularity)},user_id`);
    }
  }
  return groupBy;
};

/**
 * parseHours - Calculates the quantity of hours a time duration string represents
 *
 * @param {string} duration time duration (ex1: 'PT60M', ex2: 'PT-2H')
 *
 * @returns {number} quantity of hours the duration passed represents
 * ex1: 1
 * ex2: -2
 */
export const parseHours = compose(
  whenInvalid(() => 'PT0S'),
  asHours
);

/**
 * parseMinutes - Calculates the quantity of minutes a time duration represents
 *
 * @param {string} duration time duration (ex1: 'PT60M', ex2: 'PT-2H')
 *
 * @returns {number} quantity of minutes the duration passed represents
 * ex1: 60
 * ex2: -120
 */
export const parseMinutes = compose(
  whenInvalid(() => 'P'),
  asMinutes
);

/**
 * parseSeconds - Calculates the quantity of seconds a time duration represents
 *
 * @param {string} duration time duration (ex1: 'PT60M', ex2: 'PT-2H')
 *
 * @returns {number} quantity of seconds the duration passed represents
 * ex1: 3600
 * ex2: -7200
 */
export const parseSeconds = compose(
  whenInvalid(() => 'PT0S'),
  _asSeconds
);

/**
 * subDuration - Subtracts a given duration from a give date
 *
 * @param {date} date a date in ISO 8601 format
 * @param {string} duration time duration in 'PT23H59M59S' format
 *
 * @returns {date} which is the subtraction of the given date by the given duration
 */
export const subDuration = (date: string, duration: string) => {
  const fragments = toFragments(duration);
  return pipe(
    date,
    (d: string) => subYears(new Date(d), fragments.years),
    (d: string) => subMonths(new Date(d), fragments.months),
    (d: string) => subWeeks(new Date(d), fragments.weeks),
    (d: string) => subDays(new Date(d), fragments.days)
  );
};

/**
 * timeToDuration - Converts a string time duration from '[-]23:59:59' format to 'PT23H59M59S' format
 *
 * @param {string} time time duration in '[-]23:59:59' format
 *
 * @returns {string} representing the duration in 'PT23H59M59S' format
 */
export const timeToDuration = (time: string) => {
  const toInt = (value: string) => (/\d+/.test(value) ? parseInt(value, 10) : 0);
  const [hours = 0, minutes = 0, seconds = 0] = time.split(':').map((value) => toInt(value));
  return `PT${hours * 60 * 60 + minutes * 60 + seconds}S`;
};
