import * as STATUS_CODES from '../api/api/v2/status-codes';
export * from './absenceDayLength';
export * from './absenceTypes';
export * from './actions';
export * from './api';
export * from './colors';
export * from './day-kinds';
export * from './desiredHolidays';
export * from './duration';
export * from './icons';
export * from './memberRoles';
export * from './messages';
export * from './news';
export * from './newSettings';
export * from './origin';
export * from './permissions';
export * from './products';
export * from './rooms';
export * from './sentry';
export * from './settingsProperties';
export * from './shifts';
export * from './timeCategory';
export * from './stamps';
export * from './time-accounts';
export * from './timeTracking';

export * from './v3';

const MAX_SERVER_TIME_OFFSET = 300000;
const ONE_DAY_IN_SECONDS = 86400;

const SUPPORT_PHONE_NUMBER = '+49 89 / 215 368 470';
const SUPPORT_EMAIL_ADDRESS = { en: 'kundenberatung@crewmeister.com', de: 'kundenberatung@crewmeister.com' };

const DEFAULT_AVATAR = '/assets/images/avatar.svg';

export {
  DEFAULT_AVATAR,
  MAX_SERVER_TIME_OFFSET,
  ONE_DAY_IN_SECONDS,
  STATUS_CODES,
  SUPPORT_EMAIL_ADDRESS,
  SUPPORT_PHONE_NUMBER,
};
