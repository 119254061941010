// Currently for holidays processing we only use the value DAY_KINDS.HOLIDAY, but the other values are saved for future consistency.
// Now we process the difference between half-holiday and holiday according to the attribute "fraction"
// "fraction" is  1 for the full day holiday, and 2 for the half day holiday
import { DAY_KINDS } from './day-kinds';

export const DESIRED_HOLIDAYS = [
  DAY_KINDS.COMPANY_HOLIDAY,
  DAY_KINDS.COMPANY_HOLIDAY_HALF,
  DAY_KINDS.HOLIDAY,
  DAY_KINDS.HOLIDAY_HALF,
];
