import { clone } from './collections';
import { isArray, isBoolean, isDate, isString } from './data-types';

/**
 * Make a comparator which is composed by others comparators, in a sense that
 * if one comparator evaluates to 0, then the comparison is made by the next
 * comparator.
 *
 * Example: to compare by age (number) and then by name (string), if ages are
 * equal.
 *    composeComparator(
 *      (a, b) => a.age - b.age,
 *      (a, b) => a.name.localeCompare(b.name)
 *    )
 *
 * @note the order in which comparators is passed defines the order that those
 * will be applied
 *
 * @param  {...any} comparators list of comparators functions @returns a new
 * comparator that represents the composition of the given comparators
 */
export const composeComparator =
  (...comparators) =>
  (a, b) => {
    for (const comparator of comparators) {
      const value = comparator(a, b);
      if (value !== 0) {
        return value;
      }
    }
    return 0;
  };

/**
 * The default number comparator function:
 *
 *    (a, b) => a - b
 */
export const defaultNumberComparator = (first, second) => first - second;

/**
 * Given a base comparator, this function creates another comparator using the
 * given options.
 *
 * Example of base comparator:
 *    (a, b) => a - b
 *
 * @param {(a: T, b: T) => number} comparator function to make the comparison
 * @param {{ [key]: value }} options options to make the comparator:
 *    'reversed': {bool}. Whether the final comparator is a reversed version of
 *        the comparator
 *    'extractor': {(value: any) => T}. Unary function to extract the
 *        comparable value from element
 * @returns a comparator based in the comparator function, with the modified
 *    behaviour
 */
export const makeComparator = (comparator, options) => {
  const { reversed, extractor } = {
    reversed: false,
    extractor: (a) => a,
    ...options,
  };
  return (a, b) => comparator(extractor(reversed ? b : a), extractor(reversed ? a : b));
};

/**
 * sortByProperties - Sorts the array by the properties given
 *
 * @param {array} array
 * @param {array of strings} property
 *
 * @return {array} returns array sorted by the properties given
 */
export const sortByProperties = (array, properties, order = 'desc') => {
  if (!isArray(array) || array.length === 0) return array;

  const arrayCloned = clone(array);

  properties.forEach((property) => {
    arrayCloned.sort((a, b) => {
      const firstElement = a[property];
      const secondElement = b[property];

      if (isDate(firstElement)) return new Date(firstElement) - new Date(secondElement);
      if (isString(secondElement)) return firstElement.localeCompare(secondElement);
      if (isBoolean(firstElement)) {
        if (firstElement === secondElement) return 0;
        else if (firstElement !== secondElement && firstElement) return -1;
        return 1;
      }
      return firstElement - secondElement;
    });
  });

  return order === 'desc' ? arrayCloned.reverse() : arrayCloned;
};
