import { rethrowError, ignoreReturnFor } from 'promise-frites';
import {
  addSuccessNotification,
  addErrorNotification,
  addInfoNotification,
} from '../action-creators/notifications-actions';
import { location } from '../utils';
import { errorMessageByErrorId } from './error-message-by-error-id';
import t from './errors-translations';

const INFO_CODES = [location.LOCATION_ERROR_CODES.permissionDenied];
const isFunction = (maybeFn) => typeof maybeFn === 'function';

const errorMessageToDisplay = (error, defaultMessage) => {
  if (error && typeof error.id !== 'undefined' && errorMessageByErrorId[error.id] !== undefined) {
    const errorKey = errorMessageByErrorId[error.id];
    return t(errorKey);
  }
  return defaultMessage;
};

export const wrapWithNotification = (fn, { dispatch, begin, success, error }) => {
  return Promise.resolve()
    .then(() => begin && begin())
    .then(() => fn())
    .then(
      ignoreReturnFor(
        (...args) =>
          success &&
          dispatch(
            addSuccessNotification({
              message: isFunction(success) ? success(...args) : success,
            })
          )
      )
    )
    .catch(
      rethrowError((...args) => {
        const returnedError = args[0];
        const message = isFunction(error) ? error(...args) : errorMessageToDisplay(returnedError, error);
        return (
          error &&
          dispatch(
            INFO_CODES.includes(returnedError.code)
              ? addInfoNotification({ message: message })
              : addErrorNotification({ message: message })
          )
        );
      })
    );
};

export default wrapWithNotification;
