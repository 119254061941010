import React from 'react';

import LoadingSpinner from '../components/core/loading-spinner-with-text';
import Notifications from '../containers/app-notifications';
import Offline from '../containers/online-offline';
import Suspense from '../components/core/suspense';
import TerminalApps from './terminals';

import { lazyLoader } from '../utils';
import { COLORS } from '../constants';
import { StyleSheet, css } from '../_external-deps/stylesheet';

const UserApp = lazyLoader(() => import('./user'));

const AppSwitcher = ({ authentication, permissions, crews, isLoading, userAppInTerminalMode, match }) => (
  <div className={css(styles.eggshell)}>
    <Notifications />
    <Offline />
    <Choose>
      <When condition={authentication && authentication.crewAuthentication && !userAppInTerminalMode}>
        <TerminalApps
          authentication={authentication}
          userId={authentication.userAuthentication && authentication.userAuthentication.userId}
          crewId={authentication.crewAuthentication.crewId}
          crews={crews}
          match={match}
        />
      </When>
      <When condition={authentication && authentication.userAuthentication}>
        <Suspense>
          <UserApp
            authentication={authentication}
            permissions={permissions}
            crews={crews}
            userId={authentication.userAuthentication.userId}
          />
        </Suspense>
      </When>
      <When condition={isLoading}>
        <LoadingSpinner withLoadingIcon fullscreen />
      </When>
      <Otherwise>
        <Suspense>
          <UserApp authentication={authentication} permissions={permissions} crews={crews} />
        </Suspense>
      </Otherwise>
    </Choose>
  </div>
);

const styles = StyleSheet.create({
  eggshell: {
    background: COLORS.EGGSHELL_MAIN,
    minHeight: '100vh',
    minWidth: '100%',
  },
});

export default AppSwitcher;
