import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS } from '../../styles/config';

const ANIMATION_DURATION = 1000;
const DELAY_TO_ENSURE_ELEMENT_IS_REMOVED_FROM_DOM = 100;

const NotificationItem = ({ notification, onRemove }) => {
  const className = css(notificationStyles.base, notificationStyles[notification.type]);
  const content = (
    <If condition={notification.isVisible}>
      <span className={className} onClick={() => onRemove(notification)}>
        {notification.message}
      </span>
    </If>
  );

  return (
    <TransitionGroup>
      <CSSTransition
        component="label"
        className={css(notificationStyles.label)}
        classNames={{
          appear: css(notificationStyles.appear),
          exit: css(notificationStyles.exit),
        }}
        appear
        enter={false}
        exit
        timeout={{
          appear: ANIMATION_DURATION,
          exit: ANIMATION_DURATION,
        }}
      >
        <div>{content}</div>
      </CSSTransition>
    </TransitionGroup>
  );
};

const ANIMATIONS = {
  appear: {
    '0%': {
      transform: 'translateX(100%)',
      height: 0,
      padding: 0,
      opacity: 0,
    },
    '49%': {
      height: 'auto',
      padding: '1rem',
      opacity: 0,
    },
    '50%': {
      transform: 'translateX(100%)',
      opacity: 1,
    },
    '75%': {
      transform: 'translateX(-5%)',
    },
    '100%': {
      transform: 'translateX(0)',
      position: 'relative',
    },
  },
  exit: {
    '0%': {
      transform: 'translateX(0%)',
    },
    '50%': {
      transform: 'translateX(200%)',
      height: 'auto',
      padding: '1rem',
    },
    '100%': {
      height: 0,
      padding: 0,
      transform: 'translateX(200%)',
    },
  },
};

const notificationStyles = StyleSheet.create({
  base: {
    padding: '1rem',
    borderRadius: '0.5rem',
    marginBottom: '0.5rem',
    display: 'block',
    cursor: 'pointer',
  },
  label: {
    width: '100%',
    display: 'block',
  },
  appear: {
    animationName: ANIMATIONS.appear,
    animationDuration: `${ANIMATION_DURATION}ms`,
  },
  exit: {
    animationName: ANIMATIONS.exit,
    animationDuration: `${ANIMATION_DURATION + DELAY_TO_ENSURE_ELEMENT_IS_REMOVED_FROM_DOM}ms`,
  },
  error: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.RED_MAIN,
  },
  success: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.GREEN_MAIN,
  },
  info: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLUE_MAIN,
  },
});

export default NotificationItem;
