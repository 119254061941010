export const MOBILE_SCREEN_WIDTH = 640;
export const IPAD_SCREEN_WIDTH = 768;
export const TABLET_SCREEN_WIDTH = 960;
export const LARGE_TABLET_SCREEN_WIDTH = 1020;

export const OVERLONG = 30;

export const INFINITY_LIST_ROW_HEIGHT = 73;

export const NAVBAR_HEIGHT_MOBILE = 57;
export const NAVBAR_HEIGHT_LAPTOP = 70;
