import produce from 'immer';
import { array, dataTypes } from '../utils';

const { applyMinimalChangesToArray } = array;
const { isArray } = dataTypes;

const initialState = produce({ workplaces: [] }, () => {});

const workplacesNotInCrew = (crewId) => (model) => model.crewId !== crewId;

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const workplacesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'UPDATE_WORKPLACE_SUCCESS':
      case 'CREATE_WORKPLACE_SUCCESS':
        applyMinimalChangesToArray(draft.workplaces, action.result, (workplace) => workplace.id === action.workplaceId);
        break;
      case 'LOAD_WORKPLACES_SUCCESS':
        draft.workplaces = draft.workplaces
          .filter(workplacesNotInCrew(action.crewId))
          .concat(isArray(action.result) ? action.result : [action.result]);
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the original object
    }
  });
