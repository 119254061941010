/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction, buildDangerouslyTranslateNeverWithUserContent } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    home: 'Zur Crewmeister Startseite',
    subtitle:
      'Das hat leider nicht geklappt. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Kundenservice.',
    text: `
      Nicht verzweifeln, es ist nichts schlimmes passiert.</br>
      Gehen Sie einfach zurück und tun wir so als wäre nichts passiert ;)
    `,
    title: 'Hoppala',
  },
  en: {
    home: 'Go to Crewmeister Home',
    subtitle: "An error happened and we couldn't recover from it.",
    text: `
      Alternatively, use the back </br>
      button in your browser or </br>
      contact our support if the </br>
      error persists.
    `,
    title: 'Sorry',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
