import { getMonth, getYear, isSameDay, addDays } from 'date-fns';
import produce from 'immer';
import { array, date as dateUtils } from '../utils';

const { formatIso8601Date, isWithinRange } = dateUtils;
const { applyMinimalChangesToArray } = array;
const initialState = produce({ absenceDaysCount: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const absenceDaysCountReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_ABSENCE_DAYS_COUNT_SUCCESS': {
        const parsedAbsenceDayCounts = parseDayCountsFromServer(action);
        applyMinimalChangesToArray(
          draft.absenceDaysCount,
          parsedAbsenceDayCounts,
          (dayCount) =>
            dayCount.crewId === action.crewId &&
            (!action.typeIds || action.typeIds.includes(dayCount.typeId)) &&
            isSameDay(new Date(dayCount.from), new Date(action.from)) &&
            isSameDay(new Date(dayCount.to), new Date(action.to)) &&
            isSameUser(dayCount, action) &&
            dayIsWithinRange(dayCount, action) &&
            monthIsWithinRange(dayCount, action) &&
            yearIsWithinRange(dayCount, action)
        );
        break;
      }
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });

const parseDayCountsFromServer = (action) =>
  action.result.map((absenceDayCount) => ({
    ...absenceDayCount,
    from: action.from,
    to: action.to,
    ...(absenceDayCount.groupBy ? { userId: absenceDayCount.groupBy.userId } : {}),
    ...(absenceDayCount.groupBy && absenceDayCount.groupBy.hasOwnProperty('day')
      ? { date: formatIso8601Date(addDays(new Date(action.from), absenceDayCount.groupBy.day)) }
      : {}),
    ...(absenceDayCount.groupBy && absenceDayCount.groupBy.hasOwnProperty('month')
      ? { month: absenceDayCount.groupBy.month + getMonth(new Date(action.from)) }
      : {}),
    ...(absenceDayCount.groupBy && absenceDayCount.groupBy.hasOwnProperty('year')
      ? { year: absenceDayCount.groupBy.year + getYear(new Date(action.from)) }
      : {}),
  }));

const isSameUser = (dayCount, action) =>
  !action.userIds || !dayCount.groupBy || !dayCount.groupBy.userId || action.userIds.includes(dayCount.groupBy.userId);

const dayIsWithinRange = (dayCount, action) =>
  !dayCount.date || isWithinRange(new Date(dayCount.date), action.from, action.to);

const monthIsWithinRange = (dayCount, action) =>
  !dayCount.month ||
  (dayCount.month >= getMonth(new Date(action.from)) && dayCount.month <= getMonth(new Date(action.to)));

const yearIsWithinRange = (dayCount, action) =>
  !dayCount.year || (dayCount.year >= getYear(new Date(action.from)) && dayCount.year <= getYear(new Date(action.to)));
