import produce from 'immer';
import { AnyAction } from 'redux';

import { applyMinimalChangesToArray } from '@crewmeister/shared/src/utils/array';
import { ACTION_NAMES } from '../../constants';
import { V3UsageRightsContent } from '../../types';

type V3UsageRightsAction = AnyAction & { crewId: number; result: { content: V3UsageRightsContent[] } };

type V3UsageRightsSliceInRedux = { usageRights: V3UsageRightsContent[] };

const initialState = produce({ usageRights: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const v3UsageRightsReducer = (state = initialState, action: V3UsageRightsAction): V3UsageRightsSliceInRedux =>
  produce(state, (draft: V3UsageRightsSliceInRedux) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_LOAD_USAGE_RIGHTS}_SUCCESS`:
        applyMinimalChangesToArray(draft.usageRights, action.result.content, (element) => {
          return element.crewId === action.crewId;
        });
        break;

      default:
      // nothing to do => immer returns the original object
    }
  });
