import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadCrewMembers = memoize(
  {},
  ({ api, crewId, userIds = null, disabledAt }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_CREW_MEMBERS',
        // if userIds are given, we need to pass them as userId to the api
        fnParams: { crewId, ...(userIds && { userId: userIds }), ...(disabledAt !== undefined && { disabledAt }) },
        fnParamsInActions: (fnParameters) => ({ ...fnParameters, ...(userIds && { userIds }) }),
        fn: (params) => api.getCrewMembers(params),
      })
);

export const updateMember =
  ({ api, crewId, userId, firstWorkDayAt, avatar, employmentType, externalId, name }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_MEMBER',
      fnParams: { crewId, userId, firstWorkDayAt, avatar, employmentType, externalId, name },
      fn: (params) => api.updateMember(params),
    });

export const memberAvatarUpdated = ({ crewId, userId, avatarUrl }) => ({
  type: 'MEMBER_AVATAR_UPDATED',
  crewId,
  userId,
  avatarUrl,
});

export const inviteCrewMemberWithEmail =
  ({ api, crewId, email, name, language }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'INVITE_USER_WITH_EMAIL',
      fnParams: { crewId, email, name, language },
      fn: (params) => api.inviteUserWithEmail(params),
      afterSuccessFn: () => dispatch(loadCrewMembers.unmemoized({ api, crewId })),
    });

export const inviteCrewMemberWithUsername =
  ({ api, crewId, username, name, password, passwordConfirmation, language }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'INVITE_USER_WITH_USERNAME',
      fnParams: { crewId, username, name, password, passwordConfirmation, language },
      fn: (params) => api.inviteUserWithLoginName(params), // TODO: should return member
      afterSuccessFn: () => dispatch(loadCrewMembers.unmemoized({ api, crewId })),
    });

export const disableCrewMember =
  ({ api, crewId, memberId }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'DISABLE_CREW_MEMBER',
      fnParams: { crewId, memberId },
      fn: (params) => api.disableCrewMember(params), // TODO: reduce member returned from api
      afterSuccessFn: () => dispatch(loadCrewMembers.unmemoized({ api, crewId })),
    });

export const enableCrewMember =
  ({ api, crewId, memberId }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'ENABLE_CREW_MEMBER',
      fnParams: { crewId, memberId },
      fn: (params) => api.enableCrewMember(params),
      afterSuccessFn: () => dispatch(loadCrewMembers.unmemoized({ api, crewId })),
    });

export const setCrewMemberRole =
  ({ api, crewId, memberId, role }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'SET_CREW_MEMBER_ROLE',
      fnParams: { crewId, memberId, role },
      fn: (params) => api.setCrewMemberRole(params),
      afterSuccessFn: () => dispatch(loadCrewMembers.unmemoized({ api, crewId })),
    });

export const setPasswordToCrewPassword =
  ({ api, crewId, userId, password }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'SET_PASSWORD_TO_CREW_PASSWORD',
      fnParams: { crewId, userId, password },
      fn: (params) => api.resetPasswordToCrewPassword(params),
    });
