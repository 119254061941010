export const SETTINGS_PROPERTIES = {
  ABSENCE_MANAGEMENT_SEE_ALL_ABSENCES: 'absenceManagementSeeAllAbsences',
  ABSENCE_TYPES: 'absenceTypes',
  CHAT_ENABLED: 'chatEnabled',
  CREATE_STAMP_CHAIN_TIME_LIMIT: 'createStampChainTimeLimit',
  EDIT_STAMP_CHAIN_TIME_LIMIT: 'editStampChainTimeLimit',
  SECOND_TIME_CATEGORY_ENABLED: 'secondTimeCategoryEnabled',
  SHIFT_PLANNER_GRACE_PERIOD_BEGIN: 'shiftPlannerGracePeriodBegin',
  SHIFT_PLANNER_GRACE_PERIOD_END: 'shiftPlannerGracePeriodEnd',
  SHIFT_PLANNER_SEE_ALL_SHIFTS: 'shiftPlannerSeeAllShifts',
  TERMINAL_AUTHENTICATION_MODE: 'terminalAuthenticationMode',
  TERMINAL_VIEW_MODE: 'terminalViewMode',
  TIME_TRACKING_ALLOWED_MOBILE: 'timeTrackingAllowedMobile',
  TIME_TRACKING_ALLOWED_OTHER: 'timeTrackingAllowedOther',
  TIME_TRACKING_ALLOWED_TERMINAL: 'timeTrackingAllowedTerminal',
  TIME_TRACKING_EDIT_ALLOWED: 'timeTrackingEditAllowed',
  TIME_TRACKING_IN_FUTURE_ALLOWED: 'timeTrackingInFutureAllowed',
  TIME_TRACKING_LOCATION: 'timeTrackingLocation',
};
