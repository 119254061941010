import { max, min } from 'date-fns';
import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../../constants';
import { HTTP_METHOD, PatchResource, SHIFT_TYPE, V3Shift, V3ShiftPatchResponse } from '../../types';
import { array as arrayUtils, dataTypes as dataTypesUtils, date as dateUtils } from '../../utils';

const { isArray } = dataTypesUtils;
const { isWithinRange } = dateUtils;
const { applyMinimalChangesToArray } = arrayUtils;

type V3ShiftAction = {
  id: number;
  shift: V3Shift;
  result: { content: V3Shift[] };
};

type V3ShiftOptimisticAction = {
  id: number;
  shift: V3Shift;
  result: V3Shift[];
};

type V3ShiftTransformAction = AnyAction & {
  values: PatchResource[];
  result: V3ShiftPatchResponse[];
};

type V3ShiftCopyTaskAction = {
  crewId: number;
  originStartDate: string;
  originEndDate: string;
  targetStartDate: string;
  result: {
    creator: {
      id: string;
      type: string;
    };
    id: string;
    input: {
      crewId: number;
      originStartDate: string;
      originEndDate: string;
      targetStartDate: string;
    };
    output: {
      createdShifts: V3Shift[];
    };
  };
};

type V3ShiftPublishTaskAction = {
  shiftIdsToPublish: number[];
  result: Array<{
    body: {
      creator: {
        id: string;
        type: string;
      };
      id: string;
      input: {
        shiftIdToPublish: number[];
      };
      output: {
        createdShifts: V3Shift[];
        updatedShifts: V3Shift[];
        deletedShifts: V3Shift[];
      };
    };
  }>;
};

type V3ShiftsSliceInRedux = { shifts: V3Shift[] };

const initialState = produce({ shifts: [] }, () => {});

const shouldShiftBeDraft = (shift: V3Shift) => shift.type !== SHIFT_TYPE.PUBLISHED;

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const shiftsReducer = (
  state = initialState,
  action: AnyAction &
    V3ShiftAction &
    V3ShiftOptimisticAction &
    V3ShiftTransformAction &
    V3ShiftCopyTaskAction &
    V3ShiftPublishTaskAction
): V3ShiftsSliceInRedux =>
  produce(state, (draft: V3ShiftsSliceInRedux) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_APPLY_SHIFT_TEMPLATE_TASK}_SUCCESS`: {
        const createdShifts =
          action?.result?.resourceAfterWrite?.output?.createdShifts || action?.result?.output?.createdShifts;

        const areThereCreatedShifts = isArray(createdShifts) && createdShifts.length;

        if (areThereCreatedShifts) {
          const minDateFrom = min(createdShifts.map(({ from }) => new Date(from)));
          const maxDateTo = max(createdShifts.map(({ to }) => new Date(to)));

          applyMinimalChangesToArray(
            draft.shifts,
            createdShifts,
            (shift) =>
              shift.crewId === action.crewId &&
              (!action.userId || shift.userId === action.userId) &&
              isWithinRange(new Date(shift.from), minDateFrom, maxDateTo) &&
              isWithinRange(new Date(shift.to), minDateFrom, maxDateTo)
          );
        }
        break;
      }

      case `${ACTION_NAMES.V3_LOAD_SHIFTS}_SUCCESS`: {
        applyMinimalChangesToArray(
          draft.shifts,
          action.result.content,
          (shift) =>
            shift.crewId === action.crewId &&
            (!action.userId || shift.userId === action.userId) &&
            isWithinRange(new Date(shift.from), action.from, action.to) &&
            isWithinRange(new Date(shift.to), action.from, action.to)
        );
        break;
      }

      case `${ACTION_NAMES.V3_OPTIMISTIC_UPDATE_SHIFT}_BEGIN`: {
        applyMinimalChangesToArray(
          draft.shifts,
          action.result,
          (shift) => action.shift.type !== SHIFT_TYPE.PUBLISHED && shift.id === action.id
        );
        break;
      }

      case `${ACTION_NAMES.V3_OPTIMISTIC_UPDATE_SHIFT}_COMMIT`: {
        applyMinimalChangesToArray(
          draft.shifts,
          action.result,
          (shift) =>
            (action.shift.type !== SHIFT_TYPE.PUBLISHED && shift.id === action.id) ||
            shift.transactionID === action.optimist.id
        );
        break;
      }

      case `${ACTION_NAMES.V3_TRANSFORM_SHIFTS_DRAFT}_SUCCESS`: {
        const requestedShifts = action.values.filter((value) => value.resource.id).map((value) => value.resource);
        const result = action.result
          .map((singleResult: V3ShiftPatchResponse) => singleResult.body)
          .filter(
            (_shift, index) =>
              action.values[index].operation !== HTTP_METHOD.DELETE &&
              action.values[index].resource.type !== SHIFT_TYPE.PUBLISHED
          );
        applyMinimalChangesToArray(draft.shifts, result, (shift) =>
          requestedShifts.some(
            (requestedShift) => requestedShift && requestedShift.id === shift.id && shouldShiftBeDraft(shift)
          )
        );
        break;
      }

      case `${ACTION_NAMES.V3_COPY_SHIFTS}_SUCCESS`: {
        const createdShifts =
          action?.result?.resourceAfterWrite?.output?.createdShifts || action?.result?.output?.createdShifts;
        applyMinimalChangesToArray(draft.shifts, createdShifts, () => false);
        break;
      }

      case `${ACTION_NAMES.V3_PUBLISH_SHIFTS}_SUCCESS`: {
        let createdAndUpdatedShifts = [] as Array<V3Shift>;

        const allShiftIds = action.result.reduce((acc, { body }) => {
          const output = body?.resourceAfterWrite?.output || body?.output;
          const { createdShifts, deletedShifts, updatedShifts } = output;
          const allShifts = [...createdShifts, ...deletedShifts, ...updatedShifts];

          createdAndUpdatedShifts = [...createdAndUpdatedShifts, ...createdShifts, ...updatedShifts];

          return [...acc, ...allShifts.map(({ id }) => id)];
        }, [] as Array<number>);

        applyMinimalChangesToArray(draft.shifts, createdAndUpdatedShifts, (shift) =>
          allShiftIds.some((id) => id === shift.id)
        );
        break;
      }

      default:
      // nothing to do => immer returns the original object
    }
  });
