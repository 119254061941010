import React, { ReactElement, ReactNode } from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES } from '../../constants';

type ParagraphProps = {
  children: ReactNode;
  small?: boolean;
  align?: 'left' | 'center' | 'right' | 'justify';
  inline?: boolean;
  bold?: boolean;
  className?: string;
};

const Paragraph: (props: ParagraphProps) => ReactElement = ({
  children,
  small,
  align = 'left',
  inline = false,
  bold,
  className,
}) => (
  <p
    className={`${css(
      styles.default,
      small && styles.small,
      inline && styles.inline,
      styles[align],
      bold && styles.bold
    )} ${className}`}
  >
    {children}
  </p>
);

const styles = StyleSheet.create({
  default: {
    ...FONT_STYLES.medium,
    lineHeight: 1.3,
    '*a': {
      color: COLORS.ORANGE_MAIN,
    },
  },
  small: FONT_STYLES.small,
  left: { textAlign: 'left' },
  center: { textAlign: 'center' },
  right: { textAlign: 'right' },
  justify: { textAlign: 'justify' },
  inline: { display: 'inline' },
  bold: FONT_STYLES.big,
});

export default Paragraph;
