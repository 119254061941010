// TODO: move this file to utils

import { startOfDay, endOfDay, eachDayOfInterval, isBefore, getHours, getMinutes, isSameDay } from 'date-fns';
import { asSeconds } from 'pomeranian-durations';
import { collections, duration as durationUtils } from '../../utils';

import { groupByClockIn } from './meta-labels';
import {
  workDurationForStamps,
  workDurationsWithinPeriod,
  parseDurationToFitGivenDates,
} from '../../selectors/stamps-selectors';

const { compose } = collections;
const { formatDuration } = durationUtils;

const isStopWork = (stamp) => stamp.timeAccount === 0;

const isOpenChainAcrossDays = (stamp, nextStamp) =>
  nextStamp && !isStopWork(stamp) && !isSameDay(new Date(stamp.timestamp), new Date(nextStamp.timestamp));

const filterStampsForDay = (stamps, date) =>
  stamps.filter((stamp) => isSameDay(new Date(stamp.timestamp), new Date(date)));

const sortStampsByTimestamp = (stamps) => {
  return stamps.sort((current, next) => {
    const a = new Date(current.timestamp);
    const b = new Date(next.timestamp);
    return a - b;
  });
};

export const createDurationMap = (durations) => {
  const obj = {};
  durations.forEach((duration) => {
    if (duration.groupBy.userId !== undefined) {
      obj[duration.groupBy.userId] = {
        text: formatDuration(duration.value),
        seconds: asSeconds(duration.value),
      };
    }
  });
  return obj;
};

export const filterByUserId = (ar, userId) => {
  if (typeof userId !== 'number') throw new Error('userId should be a number');
  return ar.filter((item) => item.userId === userId);
};

export const currentTimeStamps = (stamps, currentTime) => {
  const last = stamps[stamps.length - 1] || {};
  const additionalStamps = [];
  if (!isStopWork(last) && isBefore(new Date(last.timestamp), new Date(currentTime))) {
    additionalStamps.push({
      ...last,
      timestamp: currentTime,
    });
  }
  return additionalStamps;
};

export const openChainStamps = (stamps) => {
  const addionalStamps = [];
  stamps.forEach((stamp, index) => {
    const nextStamp = stamps[index + 1];
    if (isOpenChainAcrossDays(stamp, nextStamp)) {
      const days = eachDayOfInterval({ start: new Date(stamp.timestamp), end: new Date(nextStamp.timestamp) });
      days.forEach((day, dayIndex) => {
        const nextDay = days[dayIndex + 1];
        if (!nextDay) return;
        addionalStamps.push({
          ...stamp,
          timestamp: endOfDay(new Date(day)).toISOString(),
        });
        addionalStamps.push({
          ...stamp,
          timestamp: startOfDay(new Date(nextDay)).toISOString(),
        });
      });
    }
  });

  return addionalStamps;
};

const convertToBlocks = (stamp, nextStamp) => {
  const result = [];

  const stampHours = getHours(new Date(stamp.timestamp));
  const nextStampHours = getHours(new Date(nextStamp.timestamp));
  const stampMinutes = getMinutes(new Date(stamp.timestamp));
  const nextStampMinutes = getMinutes(new Date(nextStamp.timestamp));

  for (let hour = stampHours; hour < nextStampHours; hour++) {
    result.push({
      hour: stampHours === hour ? stampHours : hour,
      from: stampHours === hour ? stampMinutes : 0,
      to: 60,
      timeAccount: stamp.timeAccount,
    });
  }

  result.push({
    hour: nextStampHours,
    from: stampHours === nextStampHours ? stampMinutes : 0,
    to: nextStampMinutes,
    timeAccount: stamp.timeAccount,
  });

  return result;
};

const createChainStamps = (stamps, currentTime, selectedDate) => {
  const nowStamps = currentTimeStamps(stamps, currentTime);
  const updatedStamps = [...stamps, ...nowStamps];
  const periodStamps = openChainStamps(updatedStamps);
  const allStamps = sortStampsByTimestamp([...updatedStamps, ...periodStamps]);
  const dayStamps = filterStampsForDay(allStamps, selectedDate);
  return dayStamps;
};

const createBlocksForChain = (chain) => {
  const blocks = chain
    .map((stamp, index) => {
      const nextStamp = chain[index + 1];
      return nextStamp && convertToBlocks(stamp, nextStamp);
    })
    .filter((item) => item)
    .reduce((a, b) => a.concat(b), []);
  return blocks;
};

export const createBlocks = (stamps, currentTime, selectedDate) => {
  if (!currentTime) throw new Error('currentTime not passed');
  if (!selectedDate) throw new Error('selectedDate not passed');
  const chains = groupByClockIn(stamps);
  const blocks = Object.keys(chains).map((key) => {
    const chainStamps = createChainStamps(chains[key], currentTime, selectedDate);
    return createBlocksForChain(chainStamps);
  });

  return [].concat(...blocks);
};

export const blocksAsDay = (period) => {
  const day = [];
  while (day.length < 24) day.push([]);
  period.forEach((item) => {
    day[item.hour].push(item);
  });
  return day;
};
const startOfDate = startOfDay(new Date());
const endOfDate = endOfDay(new Date());
const getWorkDurationsForStamps = compose(
  (stamps) => workDurationsWithinPeriod(stamps, startOfDate, endOfDate),
  (stamps) => parseDurationToFitGivenDates(stamps, startOfDate, endOfDate),
  (stamps) => workDurationForStamps(stamps)
);

export const createWorkingTimeMap = (stamps) => {
  const obj = {};
  Object.keys(stamps).map((key) => {
    obj[key] = {
      seconds: getWorkDurationsForStamps(stamps[key]),
    };
  });
  return obj;
};

export const createAbsenceBlocks = (absences, selectedDate) => {
  if (!selectedDate) throw new Error('selectedDate not passed');
  const day = [];
  while (day.length < 24) day.push(0);
  absences.forEach(({ startFraction, endFraction, startDate, endDate }) => {
    const isEndDay = isSameDay(new Date(selectedDate), new Date(endDate));
    const isStartDay = isSameDay(new Date(selectedDate), new Date(startDate));

    if (isEndDay && endFraction !== 0) {
      const end = 24 / endFraction;
      for (let i = 0; i < end; i++) day[i] = 1;
    }
    if (isStartDay && startFraction !== 0) {
      const start = 24 / startFraction;
      for (let i = 24 - start; i < 24; i++) day[i] = 1;
    }
    if (!isStartDay && !isEndDay) {
      for (let i = 0; i < 24; i++) day[i] = 1;
    }
  });
  return day;
};
