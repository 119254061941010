import { createSelector } from 'reselect';
import { selectByProps } from './utils';
import { selectCurrentCrewId } from './crews-selectors';

export const selectPublicHolidays = (state) => state.bo.publicHolidays.publicHolidays;

const getCountryFromProps = (_state, props) => props.country;

export const selectPublicHolidaysDefaults = createSelector(
  [selectPublicHolidays, getCountryFromProps],
  (publicHolidays, country) => selectByProps(publicHolidays, { crewId: null, country })
);

export const selectPublicHolidaysForAllCountries = createSelector([selectPublicHolidays], (publicHolidays) =>
  selectByProps(publicHolidays, { crewId: null })
);

export const selectPublicHolidaysByCrew = createSelector(
  [selectPublicHolidays, selectCurrentCrewId],
  (publicHolidays, crewId) => selectByProps(publicHolidays, { filter: (holiday) => holiday.crewId === crewId })
);
