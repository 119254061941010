const getBrowserPolyfill = () => {
  const originalSelf = global.self;
  global.self = {};
  require('isomorphic-fetch');
  const isomorphicFetch = global.self.fetch;
  global.self = originalSelf;
  return isomorphicFetch;
};

const getNodePolyfill = () => {
  return require('isomorphic-fetch');
};

export const hasWindow = () => typeof window !== 'undefined';
const userAgent = () => {
  // Explicitly check for `userAgent`, since `window` is defined in react-native,
  // but `userAgent` not, so let's check as explictly as possible.
  if (hasWindow() && window.navigator && window.navigator.userAgent) {
    return window.navigator.userAgent;
  }
  return '';
};
const isEdgeVersion = (version) => userAgent().indexOf(`edge/${version}`) !== -1;

const isUnsupported = () => typeof global.fetch === 'undefined';

const needsPolyfill = () => isUnsupported() || isEdgeVersion(12) || isEdgeVersion(13) || isEdgeVersion(14);

const getPolyfill = () => (hasWindow() ? getBrowserPolyfill() : getNodePolyfill());

const polyfilledFetch = needsPolyfill() ? getPolyfill() : (...args) => global.fetch(...args);

const _fetch = (url, options, method) => polyfilledFetch(url, { method, credentials: 'include', ...options });

export const fetchGet = (url, options) => _fetch(url, options, 'GET');
export const fetchPut = (url, options) => _fetch(url, options, 'PUT');
export const fetchPost = (url, options) => _fetch(url, options, 'POST');
export const fetchDelete = (url, options) => _fetch(url, options, 'DELETE');
