import { ignoreReturnFor, rethrowError } from 'promise-frites';
import { makeActionCreator, toActionName } from '../utils/actionCreator';
import { addSyncFlagToResult } from '../utils/sync';

// Dispatches the _REQUEST, _SUCCESS, and _FAILURE actions
// when calling a certain fn with fnParams
// The success action carries the result of the fn as property 'result'
// The failure action carries the error of the fn call as property 'error'
export const dispatchAsyncFnStatusActions = ({
  dispatch,
  fn,
  fnParams = {},
  // by default, we keep the same fn parameter names in the actions
  fnParamsInActions = (fnParameters) => fnParameters,
  actionName,
  afterSuccessFn,
  hasPermission = true,
  namespace = {
    request: null,
    failure: null,
    success: null,
  },
}) => {
  if (!hasPermission) {
    return;
  }

  const requestAction = makeActionCreator(`${toActionName(namespace.request, actionName)}_REQUEST`);
  const successAction = makeActionCreator(`${toActionName(namespace.success, actionName)}_SUCCESS`);
  const failureAction = makeActionCreator(`${toActionName(namespace.failure, actionName)}_FAILURE`);
  // with fnParamsInActions, we can rename function parameter names in the actions
  const fnParamsActions = fnParamsInActions(fnParams);
  dispatch(requestAction(fnParamsActions));
  return Promise.resolve()
    .then(() => fn(fnParams))
    .then(
      ignoreReturnFor((result) =>
        dispatch(
          successAction({
            result: addSyncFlagToResult(result),
            ...fnParamsInActions(fnParamsActions),
          })
        )
      )
    )
    .then(ignoreReturnFor((result) => afterSuccessFn && afterSuccessFn({ result, ...fnParams })))
    .catch(
      rethrowError((err) => {
        return dispatch(failureAction({ error: err, ...fnParamsInActions(fnParamsActions) }));
      })
    );
};
