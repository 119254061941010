import { constants } from '@crewmeister/shared';
import { array as arrayUtils, collections, object as objectUtils, stampchain } from '../../utils';

const { TIME_ACCOUNTS } = constants;

const { isEmpty, lastElement } = arrayUtils;
const { compose } = collections;
const { getProperty } = objectUtils;
const { findActiveTimeAccount, isCompleted } = stampchain;

const isTimeAccount =
  (timeAccount) =>
  (stamp = {}) =>
    stamp.timeAccount === timeAccount ? stamp : void 0;

const filterByTimeAccount = (timeAccount) => (timestampChain) =>
  timestampChain.filter((stamp) => stamp.timeAccount === timeAccount);

const secondsBetween = (first, second = { timestamp: new Date() }) =>
  Math.floor((new Date(second.timestamp) - new Date(first.timestamp)) / 1000);

const calculateDurationFor = (timeAccount) => {
  const isIrrelevantTimeAccount = (stamp) => stamp.timeAccount !== timeAccount;

  const calculate = (timestampChain, totalDuration = 0) => {
    const [currentStamp, ...otherStamps] = timestampChain;

    if (isEmpty(timestampChain)) {
      return totalDuration;
    }
    if (isIrrelevantTimeAccount(currentStamp)) {
      return calculate(otherStamps, totalDuration);
    }

    const durationAsSeconds = secondsBetween(currentStamp, otherStamps[0]);
    return calculate(otherStamps, durationAsSeconds + totalDuration);
  };

  return calculate;
};

const calculateSecondsOfWork = (timestampChain) => calculateDurationFor(TIME_ACCOUNTS.WORKING)(timestampChain);

const calculateSecondsOfBreak = (timestampChain) => calculateDurationFor(TIME_ACCOUNTS.BREAK)(timestampChain);

const toDuration =
  (fn) =>
  (...args) =>
    `PT${fn(...args)}S`;

const calculateWorkDuration = toDuration(calculateSecondsOfWork);
const calculateBreakDuration = toDuration(calculateSecondsOfBreak);
const calculateTotalDuration = toDuration((timestampChain) => {
  const workDuration = calculateSecondsOfWork(timestampChain);
  const breakDuration = calculateSecondsOfBreak(timestampChain);
  return workDuration + breakDuration;
});

export const stampChainToPeriod = (timestampChain) => {
  const activeTimeAccount = findActiveTimeAccount(timestampChain);

  const clockInTimestamp = !isEmpty(timestampChain) ? timestampChain[0].clockInTimestamp : void 0;

  const breakStartedAtTimestamp =
    activeTimeAccount === TIME_ACCOUNTS.BREAK ? timestampChain[timestampChain.length - 1].timestamp : void 0;

  const workDuration = calculateWorkDuration(timestampChain);
  const breakDuration = calculateBreakDuration(timestampChain);
  const totalDuration = calculateTotalDuration(timestampChain);

  const findActiveTimeCategoryLevelId = (chain, level) =>
    compose(filterByTimeAccount(TIME_ACCOUNTS.WORKING), lastElement, getProperty(`timeCategory${level}Id`))(chain);

  const findWorkStoppedAtTimestamp = (chain) =>
    compose(lastElement, isTimeAccount(TIME_ACCOUNTS.NOT_WORKING), getProperty('timestamp'))(chain);

  const findActiveNote = (chain) =>
    compose(lastElement, isTimeAccount(TIME_ACCOUNTS.WORKING), getProperty('note'))(chain);

  const timestamp = (chain) => compose(lastElement, getProperty('timestamp'))(chain);

  return {
    workDuration,
    breakDuration,
    totalDuration,
    activeTimeAccount,
    clockInTimestamp,
    breakStartedAtTimestamp,
    timestamp: timestamp(timestampChain),
    note: findActiveNote(timestampChain) || '',
    clockOutTimestamp: isCompleted(timestampChain) ? lastElement(timestampChain).timestamp : void 0,
    isCompleted: isCompleted(timestampChain),
    workStoppedAtTimestamp: findWorkStoppedAtTimestamp(timestampChain),
    activeTimeCategory1Id: findActiveTimeCategoryLevelId(timestampChain, 1) || null,
    activeTimeCategory2Id: findActiveTimeCategoryLevelId(timestampChain, 2) || null,
  };
};
