/* eslint-disable max-len */
import { ApiError } from '../../error';

export const STATUS_CODE_KEY = 'statusCode';
export const MESSAGE_KEY = 'message';
export const ERRORS_KEY = 'errors';

export const EXTERNAL_ERROR = 0;
// copied from server-app `error_codes.rb`
export const GONE = 410;

export const TOO_MANY_REQUESTS = 429;

export const EMAIL_SENDING_FAILED = 1000;
export const EMAIL_SENDING_FAILURE = EMAIL_SENDING_FAILED; // DEPRECATED just for backwards compatibility
export const USER_NOT_FOUND = 1001;
export const USER_WITHOUT_EMAIL_FOUND = 1002;
export const CREW_NOT_CREATED = 1003;
export const USER_NOT_CREATED = 1004;
export const INVALID_ARGUMENT = 1005;
export const UNAUTHORIZED = 1006; // deprecate ... be consistent with `NOT_*`, use `NOT_AUTHORIZED` instead
export const NOT_AUTHORIZED = UNAUTHORIZED;
export const INTERNAL_ERROR = 1007;
export const USER_NOT_ADDED_TO_CREW = 1008;
export const EMAIL_ALREADY_IN_CREW = 1009;
export const CONTEXT_VALIDATION_FAILED = 1010;
export const INTERACTION_ERROR = 1011;
export const INVALID_CSRF_TOKEN = 1012;
export const NOT_AUTHENTICATED = 1013;
export const NOT_SIGNED_OUT_OF_CREW = 1014;
export const OVERLAPPING_PERIODS = 1015;
export const BREAK_LONGER_THAN_STAMPS = 1016;
export const MEMBER_DISABLED_IN_TIMESPAN = 1023;
export const ATC_EMPTY_RESPONSE = 1024;
export const STAMP_EDITING_NOT_ALLOWED = 1025;

// cm.js only errors, counting down from 1999, so we don't collide soon with server-errors :)
export const NETWORK_TIMEOUT = 1999;

// Maintenance mode error message
export const CREW_MAINTENANCE = 1500;

// # 2xxx Warning
// # 3xxx Info
// # 4xxx Validation
export const EMAIL_FORMAT = 4000;
export const GPS_FORMAT = 4001;
export const PRESENCE = 4002;
export const TEXT_LENGTH = 4003;
export const TIMESTAMP_FORMAT = 4004;
export const TIMESTAMP_MUST_NOT_BE_IN_THE_FUTURE = 4005;
export const TIMESTAMP_NOT_IN_FUTURE = TIMESTAMP_MUST_NOT_BE_IN_THE_FUTURE; // Deprecated, use the one above
export const USERNAME_UNIQUENESS = 4006;
export const GPS_MUST_BE_PRESENT_IN_THIS_CREW = 4007;
export const GPS_PRESENCE = GPS_MUST_BE_PRESENT_IN_THIS_CREW; // Deprecated, use the one above
export const GROUP_NOT_AVAILABLE = 4008;
export const GROUP_ENABLED = GROUP_NOT_AVAILABLE; // Deprecated, use `GROUP_NOT_AVAILABLE`
export const EMAIL_UNIQUENESS_IN_CREW = 4009;
export const TIMESTAMP_NOT_FOUND = 4009;
export const UNSUPPORTED_LANGUAGE = 4010;
export const NUMERIC = 4011;
export const TIMESTAMP_DUPLICATED = 4012;
export const DEPRECATED_RAILS_VALIDATION = 4013;
export const LOGIN_NAME_FORMAT = 4014;
export const DURATION_FORMAT = 4015;
export const CLOSEST_CHAIN_NOT_FOUND = 4016;
export const TIME_ACCOUNT_NOT_ALLOWED = 4022;
export const ABSENCE_MANAGER_OVERLAPPING_ERROR = 4024;
export const ABSENCE_REQUEST_ALREADY_REJECTED = 4026;
export const CANNOT_TRACK_TIMES_FOR_THE_FUTURE = 4031;
export const STAMP_EDITING_OUTSIDE_TIME_LIMIT = 4046;

// # 5xxx Success
export const USER_INVITED = 5000;
export const TIMESTAMP_UPDATED = 5001;
export const TIMESTAMP_CREATED = 5002;
export const CALENDAR_FETCHED = 5003;
export const CREWS_FETCHED = 5004;
export const CREW_OWNER_SIGNED_UP = 5005;
export const PASSWORD_RESET_INSTRUCTIONS_SENT = 5006;
export const PASSWORD_WAS_RESET = 5007;
export const TIMESTAMPS_FETCHED = 5008;
export const SIGNED_IN_TO_CREW = 5009;
export const SIGNED_OUT_OF_CREW = 5010;
export const BREAK_WAS_UPDATED = 5011;

export const throwIfError = (response) => {
  if (response.status !== 200) {
    return response
      .json()
      .catch((e) => {
        throw new ApiError(`Unknown error occurred (status code=${response.status}) (${e})`, EXTERNAL_ERROR);
      })
      .then((payload) => {
        const statusCode = payload[STATUS_CODE_KEY];
        const message = payload[MESSAGE_KEY];
        const errors = payload[ERRORS_KEY];

        throw new ApiError(message, statusCode, errors);
      });
  }
  return response;
};
