import { compareAsc, differenceInMinutes, isEqual } from 'date-fns';
import { compose } from './collections';
import { groupBy } from './array';
import { objectToArray } from './object';
import { TIME_ACCOUNTS } from '../constants';
import { StampType, StampWithTimestampInMs } from '../types';
import { fromSeconds } from 'pomeranian-durations';

export const ERRORS_KEY = 'errors';
export const WARNINGS_KEY = 'warnings';

type Functions = {
  addStampFn: (args?: any) => void;
  gpsFn?: () => void;
};

/**
 * addStampWithoutGps - Creates a stamp without gps information
 *
 * @param {function} addStampFn function that creates a stamp
 *
 * @return {promise} returns a promise
 */
export const addStampWithoutGps = ({ addStampFn }: Functions) => {
  return Promise.resolve()
    .then(addStampFn)
    .catch((error) => {
      const err = { [ERRORS_KEY]: [error] };
      throw err;
    });
};

/**
 * addStampWithOptionalGps - Creates stamp with gps information if it's available
 *
 * @param {function} addStampFn function that creates a stamp
 * @param {function} gpsFn function that fetches the gps information
 *
 * @return {promise} returns a promise
 *
 */
export const addStampWithOptionalGps = ({ gpsFn, addStampFn }: Functions) => {
  const findLocation = () => Promise.resolve().then(gpsFn);

  const err: Record<string, unknown[]> = {};
  return findLocation()
    .catch((gpsError) => {
      err[WARNINGS_KEY] = [gpsError];
    })
    .then((args) => (WARNINGS_KEY in err ? addStampFn() : addStampFn(args)))
    .then(() => err)
    .catch((error) => {
      err[ERRORS_KEY] = [error];
      throw err;
    });
};

/**
 * addStampWithGpsRequired - Creates a stamp with gps information if it's available,
 *                           otherwise it fails the promise
 *
 * @param {function} addStampFn function that creates a stamp
 * @param {function} gpsFn function that fetches the gps information
 *
 * @return {promise} returns a promise
 *
 */
export const addStampWithGpsRequired = ({ gpsFn, addStampFn }: Functions) => {
  const addStamp = (...args: any) =>
    Promise.resolve()
      .then(() => addStampFn(...args))
      .catch((clockInError) => {
        const err = { [ERRORS_KEY]: [clockInError] };
        throw err;
      });

  const findGps = () =>
    Promise.resolve()
      .then(gpsFn)
      .catch((gpsError) => {
        const err = { [ERRORS_KEY]: [gpsError] };
        throw err;
      });

  return Promise.resolve().then(findGps).then(addStamp);
};

const SETTINGS = {
  yes: addStampWithGpsRequired,
  optional: addStampWithOptionalGps,
  no: addStampWithoutGps,
};

/**
 * addStampFnForSetting - Creates a stamp with gps information depending on given setting
 *
 * @param {string} setting value that determines if gps information is optional, required or not
 *
 * @return {promise} returns a promise
 *
 */
export const addStampFnForSetting = (setting: keyof typeof SETTINGS) => SETTINGS[setting];

/**
 * toCorrelated - Groups stamps or bookings by user id, crew id and clock in timestamp
 *
 * @param {array} stampsOrBookings array of stamps or bookings to be grouped
 *
 * @return {array} returns an array of arrays grouped by given params
 *
 */
export const toCorrelated = (stampsOrBookings: StampType[]) =>
  compose(
    groupBy((booking) => `${booking.userId}-${booking.crewId}-${booking.clockInTimestamp}`),
    objectToArray
  )(stampsOrBookings);

const matchStampList = (stamps: StampType[]) =>
  stamps.sort((a, b) => compareAsc(new Date(a.timestamp), new Date(b.timestamp)) || a.timeAccount - b.timeAccount);

/**
 * matchStamps - Groups stamps or bookings by user id, crew id and clock in timestamp and sorts them by from date
 *
 * @param {array} stampsOrBookings array of stamps or bookings to be grouped
 *
 * @return {array} returns an array of arrays grouped and sorted by given params
 *
 */
export const matchStamps = (stamps: StampType[]) =>
  compose(toCorrelated, (lists) => lists.map((stampList: StampType[]) => matchStampList(stampList)))(stamps);

/**
 * isActiveStamp - Verifies if a stamp is active; a stamp is believed active if its timestamp is not "STOP_WORK" OR
 *                 if it occurred less than 15 minutes from now
 *
 * @param {object} lastStamp which contains the properties "timestamp" and "timeAccount"
 *
 * @return {boolean} true if stamp is active,
 *                   false otherwise
 */
export const isActiveStamp = (lastStamp: StampType) => {
  if (!lastStamp) {
    return false;
  }
  const differenceToNow = differenceInMinutes(new Date(), new Date(lastStamp.timestamp));
  return lastStamp.timeAccount !== TIME_ACCOUNTS.STOP_WORK || differenceToNow <= 15;
};

/**
 * compareTimestamp - Compares two timestamps (timestamp being a representation of a date/time, not the business field timestamp)
 *
 * @param stampDate1
 * @param stampDate2
 * @returns -1 if stampDate1 is less than stampDate2, 0 if they are equal, 1 if stampDate1 is greater than stampDate2
 */
export const compareTimestamp = (timestamp1: string | Date | number, timestamp2: string | Date | number): number =>
  compareAsc(getTimestamp(timestamp1), getTimestamp(timestamp2));

/**
 * areStampsEqual - Verifies if two stamps are equal after setting their milliseconds to 0
 * @param {string | Date} stampDate1 first stamp date
 * @param {string | Date} stampDate2 second stamp date
 * @returns {boolean} true if stamps are equal, false otherwise
 */
export const areTimestampsEqual = (stampDate1: string | Date | number, stampDate2: string | Date | number): boolean => {
  return compareTimestamp(stampDate1, stampDate2) === 0;
};

/**
 * getDateForStamp - Returns the date for a stamp without milliseconds; in another words: with seconds precision
 * @param {string | Date} referenceDateValue
 * @returns {Date} date with seconds precision
 */
export const getTimestamp = (referenceDateValue: string | Date | number = new Date()): Date => {
  const epochDate = new Date(referenceDateValue).setMilliseconds(0);
  return new Date(epochDate);
};

/**
 * isSameStamp - Stamp is the same if timestamp, userId and crewId are the same
 *
 * @param {object} stamp1
 * @param {object} stamp2
 *
 * @return {boolean} true if stamps are the same,
 *                   false otherwise
 */
export const isSameStamp = (stamp1: StampType, stamp2: StampType) =>
  areTimestampsEqual(stamp1.timestamp, stamp2.timestamp) &&
  stamp1.userId === stamp2.userId &&
  stamp1.crewId === stamp2.crewId;

/**
 * addTimestampInMs - Adds a new field to a timestamp with the epoch value in milliseconds
 *                  Attention! Use it just for comparison, not for saving in database/API
 *
 * @param {StampType[]} stamps list of stamps to be modified
 * @returns {StampType[]} list of stamps with the new field
 */
export const addTimestampInMs = (stamps: StampType[]): StampWithTimestampInMs[] =>
  stamps.map((stamp) => ({ ...stamp, timestampInMs: getTimestamp(stamp.timestamp).getTime() }));

/**
 * stampTimeTillNow - Calculates the time from given stamp till now
 *
 * @param {StampType} stamp stamp to be calculated time till now
 * @returns {string} Pomerananian time in seconds
 */
export const stampTimeTillNow = (stamp: StampType): string => {
  const now = new Date();
  const time = new Date(stamp.clockInTimestamp);
  return fromSeconds(Math.abs((now.getTime() - time.getTime()) / 1000));
};
