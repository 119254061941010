import { createSelector } from 'reselect';
import { selectRoomIds, selectCurrentRoomId } from './rooms-selectors';
import { selectByProps } from './utils';

const selectMessagesFromState = (state) => state.bo.messages.messages;

export const selectMessages = createSelector(selectMessagesFromState, (messages) => messages.concat().reverse());

export const selectCurrentRoomMessages = createSelector(selectMessages, selectCurrentRoomId, (messages, roomId) =>
  messages.filter((message) => message.roomId === roomId)
);

export const selectRoomMessages = createSelector(selectMessages, selectRoomIds, (messages, roomIds) =>
  selectByProps(messages, { roomId: roomIds })
);
