import React from 'react';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';

import Button from '../../../components/core/button';
import DateTimeInfo from '../../../components/core/date-time-info';
import NoteEditor from '../../../components/core/note-editor';
import SecondaryDuration from '../../../components/core/secondary-duration';

import {
  BREAKPOINTS,
  COLORS,
  FONT_STYLES,
  IDS_FOR_GOOGLE_TAG_MANAGER,
  THEMES,
  TIME_ACCOUNTS,
} from '../../../constants';
import translate from '../../../lib/translate';
import { time as timeUtils } from '../../../utils';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';

import t from '../translate';

import PrimaryDuration from './primary-duration';
import TimeCategoriesOverlay from './time-categories-overlay';

const { formatIsoTimeShort } = timeUtils;

const TimeCategoriesOverlayComponent = (props) =>
  React.useMemo(() => {
    const {
      canTrackTimes,
      disableStampWatch,
      onStartWork,
      selectableTimeCategories,
      timeCategories,
      withoutTimeCategories,
      notSignedIn,
      isIn3ColumnTerminalMode,
      dontShowDefaultTimeCategories,
    } = props;

    const onTimeCategoryChangeProxy = (timeCategory1Id, timeCategory2Id) =>
      Promise.resolve().then(() => onStartWork({ timeCategory1Id, timeCategory2Id }));

    const disable = isIn3ColumnTerminalMode ? !notSignedIn : false;

    return (
      <If condition={!!timeCategories.length && !withoutTimeCategories && !dontShowDefaultTimeCategories}>
        <TimeCategoriesOverlay
          {...props}
          data-e2e-test={'stamp-watch-time-category-select'}
          disabled={!canTrackTimes || disableStampWatch || disable}
          iconName="tags"
          options={selectableTimeCategories}
          onChange={(timeCategory1Id, timeCategory2Id) => onTimeCategoryChangeProxy(timeCategory1Id, timeCategory2Id)}
          isOnWorkingScreen
        />
      </If>
    );
  }, [props.selectableTimeCategories]);

const NoteEditorComponent = (props) =>
  React.useMemo(() => {
    const {
      canTrackTimes,
      disableStampWatch,
      id,
      onUpdateNote,
      period,
      theme,
      withoutNotes,
      notSignedIn,
      isIn3ColumnTerminalMode,
    } = props;

    const disable = isIn3ColumnTerminalMode ? !notSignedIn : false;

    return (
      <If condition={!withoutNotes}>
        <NoteEditor
          id={id}
          onSave={onUpdateNote}
          note={period.note}
          disabled={!canTrackTimes || disableStampWatch || disable}
          theme={theme}
        />
      </If>
    );
  }, [props.period.note, props.notSignedIn]);

export const WorkingScreen = (props) => {
  const {
    alignment,
    canTrackTimes,
    disableStampWatch,
    displaySmall = false,
    id,
    onStartBreak,
    onStopWork,
    period,
    notSignedIn,
    theme,
    timeCategories,
    wide,
    withoutButtons,
    withoutClockedInSince,
    withoutDateTimeInfo = false,
    withoutMargin = false,
  } = props;

  const styles = stylesWithThemes[theme];
  const stylesColumns = createStylesColumns(withoutMargin, notSignedIn);

  const dateTimeInfo = (
    <If condition={!displaySmall && !withoutDateTimeInfo}>
      <DateTimeInfo prefix={translate('Begin:')} date={period.clockInTimestamp} theme={theme} />
    </If>
  );

  const selectableTimeCategories = timeCategories.map((timeCategory = {}) => ({
    value: timeCategory.id,
    label: timeCategory.name,
  }));

  return (
    <section className={css(styles.container)}>
      {dateTimeInfo}

      <div className={css(stylesColumns.flexContainer)}>
        <div className={css(stylesColumns.column)}>
          <If condition={!withoutClockedInSince}>
            <div className={css(styles.info)}>
              {t('clockedInSince', { time: formatIsoTimeShort(period.clockInTimestamp) })}
            </div>
          </If>

          <div className={css(stylesColumns.flexContainer, stylesColumns.durationContainer)}>
            <If condition={alignment === 'horizontal'}>
              <div className={css(stylesColumns.column, stylesColumns.paddingSides)}>
                <TimeCategoriesOverlayComponent {...props} selectableTimeCategories={selectableTimeCategories} />
              </div>
            </If>

            <div className={css(stylesColumns.column)}>
              <PrimaryDuration
                className={css(styles.primaryDuration)}
                duration={period.workDuration}
                theme={theme}
                timeAccount={TIME_ACCOUNTS.WORKING}
                bold
                wide={wide}
              />
              <Choose>
                <When condition={period.breakDuration !== 'PT0S'}>
                  <SecondaryDuration icon="pause" duration={period.breakDuration} theme={theme} />
                </When>
                <Otherwise>
                  <div className={css(styles.info)}>{t('noBreakRecord')}</div>
                </Otherwise>
              </Choose>

              <If condition={alignment === 'vertical'}>
                <div className={css(stylesColumns.row)}>
                  <TimeCategoriesOverlayComponent {...props} selectableTimeCategories={selectableTimeCategories} />
                  <NoteEditorComponent {...props} />
                </div>
              </If>
            </div>

            <If condition={alignment === 'horizontal'}>
              <div className={css(stylesColumns.column, stylesColumns.paddingSides)}>
                <NoteEditorComponent {...props} />
              </div>
            </If>
          </div>

          <If condition={!withoutButtons}>
            <div className={css(styles.buttonGroup)}>
              <Button
                className={css(styles.button)}
                disabled={!canTrackTimes || disableStampWatch}
                displayType={theme.toLowerCase()}
                id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.START_BREAK}
                inline
                onClick={onStartBreak}
                spacingBottom
              >
                <If condition={theme === THEMES.TURQUOISE_WHITE}>
                  <PauseIcon className={css(styles.icon)} />
                </If>
                <span data-e2e-test="stamp-watch-start-break">{translate('Start break')}</span>
              </Button>
              <Button
                className={css(styles.button)}
                disabled={!canTrackTimes || disableStampWatch}
                displayType={theme.toLowerCase()}
                id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.STOP_WORK}
                inline
                onClick={onStopWork}
                spacingBottom
              >
                <If condition={theme === THEMES.TURQUOISE_WHITE}>
                  <StopIcon className={css(styles.icon)} />
                </If>
                <span data-e2e-test="stamp-watch-stop-work">{translate('Stop work')}</span>
              </Button>
            </div>
          </If>
        </div>
      </div>
      <If condition={!canTrackTimes}>
        <span className={css(styles.disabledLegend)}>{t('disableInputWarning')}</span>
      </If>
    </section>
  );
};

const createStylesColumns = (withoutMargin, notSignedIn) =>
  StyleSheet.create({
    column: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    row: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginTop: notSignedIn ? 36 : 4,
    },
    durationContainer: {
      margin: withoutMargin ? 'unset' : 'auto',
    },
    flexContainer: {
      display: 'flex',
      [BREAKPOINTS.tabletPortraitModeMax]: {
        flexDirection: 'column',
      },
    },
    paddingSides: {
      padding: '0 25px',
    },
  });

const stylesWithThemes = {
  [THEMES.ORANGE]: StyleSheet.create({
    button: {
      width: 'fit-content !important',
    },
    buttonGroup: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      width: '100%',
    },
    disabledLegend: {
      ...FONT_STYLES.medium,
      textAlign: 'center',
      margin: '10px 0',
      color: 'red',
      display: 'block',
    },
    icon: {
      marginRight: 16,
      fontSize: '33px !important',
    },
    info: {
      ...FONT_STYLES.medium,
      color: COLORS.GREY_LIGHT,
      fontSize: '16px',
      fontWeight: 600,
      margin: 20,
      textAlign: 'center',
    },
  }),
  [THEMES.TURQUOISE]: StyleSheet.create({
    button: {
      backgroundColor: `${COLORS.WHITE} !important`,
      borderRadius: '88px !important',
      color: `${COLORS.WHITE} !important`,
      fontSize: '40px !important',
      height: '146px',
      textTransform: 'none !important',
      width: '644px !important',
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      width: '100%',
    },
    disabledLegend: {
      ...FONT_STYLES.medium,
      textAlign: 'center',
      margin: '10px 0',
      color: 'red',
      display: 'block',
    },
    icon: {
      marginRight: 16,
      fontSize: '33px !important',
    },
    info: {
      ...FONT_STYLES.medium,
      color: COLORS.TURQUOISE_MEDIUM,
      fontSize: '16px',
      fontWeight: 600,
      margin: 20,
      textAlign: 'center',
    },
    primaryDuration: {
      color: COLORS.WHITE,
    },
  }),
  [THEMES.TURQUOISE_WHITE]: StyleSheet.create({
    button: {
      backgroundColor: `${COLORS.WHITE} !important`,
      borderRadius: '88px !important',
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2) !important',
      color: `${COLORS.TURQUOISE_MAIN} !important`,
      fontSize: '40px !important',
      height: '112px',
      textTransform: 'none !important',
      width: '644px !important',
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      width: '100%',
    },
    disabledLegend: {
      ...FONT_STYLES.medium,
      textAlign: 'center',
      margin: '10px 0',
      color: 'red',
      display: 'block',
    },
    icon: {
      marginRight: 16,
      fontSize: '50px !important',
      color: COLORS.TURQUOISE_MAIN,
    },
    info: {
      ...FONT_STYLES.medium,
      color: COLORS.WHITE,
      fontSize: 24,
      fontWeight: 600,
      margin: 20,
      textAlign: 'center',
    },
    primaryDuration: {
      color: COLORS.WHITE,
    },
  }),
};

export default WorkingScreen;
