import { createSelector } from 'reselect';
import { selectors } from '@crewmeister/shared';

const { createPropsFinder, findByProps, selectCurrentCrewId } = selectors;

const selectTerminalCrewMembers = createSelector(
  (state) => state.terminal.crewMembers.members,
  (members) => members.concat().sort((a, b) => a.name.localeCompare(b.name))
);

const selectTerminalCrewMembersInCurrentCrew = createSelector(
  selectTerminalCrewMembers,
  selectCurrentCrewId,
  (members, crewId) => members.filter((member) => member.crewId === crewId)
);

export const selectEnabledTerminalCrewMembersInCurrentCrew = createSelector(
  selectTerminalCrewMembersInCurrentCrew,
  (_, props) => props.settings,
  (members, settings) =>
    members.filter(
      (member) =>
        !member.disabledAt && !findByProps(settings, { userId: member.userId, timeTrackingAllowedTerminal: 'false' })
    )
);

export const selectCurrentTerminalCrewMember = createSelector(
  selectTerminalCrewMembersInCurrentCrew,
  (_, props) => props.userId,
  (members, userId) => members.find((member) => member.userId === userId)
);

export const findTerminalCrewMembersByProps = createPropsFinder(selectTerminalCrewMembersInCurrentCrew);
