import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const updateCrew =
  ({ api, crewId, name, ownerId }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_CREW',
      fnParams: { crewId, name, ownerId },
      fn: (params) => api.updateCrew(params),
    });

export const loadCrews = memoize(
  { ttl: 20000 },
  ({ api, source }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_CREWS',
        fn: () => {
          return api
            .getCrews()
            .then((crews) => {
              return Array.isArray(crews) ? crews : Promise.reject();
            })
            .catch((err) => {
              console.warn(`[${source}] Load crews failed. \nerr:`, err);
              // ? The following lines of code are needed to show error page in case crew/new API fails with an HTTP 503
              // const errorPageUrl = `${window.location.origin}/error`;
              // if (
              //   (err.message.includes('503') || err.message.includes('Failed to fetch')) &&
              //   window.location.href !== errorPageUrl
              // ) {
              //   window.location.href = errorPageUrl;
              // }
            });
        },
      })
);

export const updateCrewMaintenanceStatus = (crewId, status) => ({
  type: 'UPDATE_CREW_MAINTENANCE_STATUS',
  result: { crewId, status },
});
