import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;

const initialState = produce({ absenceTypes: [] }, () => {});

/**
 * TODO: This functtion should be completely removed in the future since as soon
 * as merge request 4406 on server-app is merged into stage
 * https://gitlab.crewmeister.com/crewmeister/server-app/issues/4651
 */
const formatAbsenceType = (absenceType) => {
  const newAt = Object.assign({}, absenceType);
  if (newAt.hasOwnProperty('needsConfirmation')) {
    newAt.memberCanCreate = !newAt.canBeRequested;
  }
  if (newAt.hasOwnProperty('canBeRequested')) {
    newAt.memberCanRequest = newAt.canBeRequested;
  }
  return newAt;
};

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const absenceTypesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_ABSENCE_TYPES_SUCCESS':
        applyMinimalChangesToArray(
          draft.absenceTypes,
          withCrewId(action.result, action.crewId),
          (absenceType) => absenceType.crewId === action.crewId
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });

const withCrewId = (absenceTypes, crewId) =>
  absenceTypes.map((absenceType) => ({ ...formatAbsenceType(absenceType), crewId }));
