import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '../../action-creators';
import { PERMISSIONS } from '../../constants';
import { api, callFunctionWhenPropsChange } from '../../lib';
import * as selectors from '../../selectors';
import { collections, react } from '../../utils';

import Organism from './organism/index';

const {
  loadCrewSettings,
  loadCrewMembers,
  loadPermissions,
  loadTerminalCrew,
  setUiStateValue,
  UI_STATE_KEY_CURRENT_DATE,
} = actions;
const { findPermissionsByProps, selectCurrentCrewSettings, selectCurrentTerminalCrew } = selectors;
const { composeReverse } = collections;
const { withUIState } = react;

const mapStateToProps = (state, { crewId, userId }) => {
  const crew = selectCurrentTerminalCrew(state, { crewId });
  const crewSettings = selectCurrentCrewSettings(state, { crewId });

  const terminalViewMode = crewSettings?.terminalViewMode;
  const terminalAuthenticationMode = crewSettings?.terminalAuthenticationMode;

  return {
    crew,
    permissions: {
      canUseThreeColumnTerminal: findPermissionsByProps(
        state,
        { crewId: crewId, name: PERMISSIONS.THREE_COLUMN_TERMINAL },
        {},
        'allowed'
      ),
      canUsePinCodeTerminal: findPermissionsByProps(
        state,
        { crewId: crewId, name: PERMISSIONS.PIN_CODE_TERMINAL },
        {},
        'allowed'
      ),
    },
    terminalAuthenticationMode,
    terminalViewMode,
    userId: userId,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  functionToCall: () =>
    Promise.all([
      dispatch(loadTerminalCrew({ api, crewId: props.crewId })),
      dispatch(loadCrewMembers({ api, crewId: props.crewId, disabledAt: null })),
      dispatch(loadCrewSettings.unmemoized({ api, crewId: props.crewId })),
      dispatch(loadPermissions.unmemoized({ api, crewId: props.crewId })),
    ]),
  autoRefreshCallback: () => dispatch(setUiStateValue({ key: UI_STATE_KEY_CURRENT_DATE, value: new Date() })),
  autoRefreshPermissionsCallback: () => dispatch(loadPermissions.unmemoized({ api, crewId: props.crewId })),
});

const TerminalAppContainer = composeReverse(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withUIState('isSubmitting', 'setIsSubmitting', false),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId', 'userId'] })
)(Organism);

export default TerminalAppContainer;
