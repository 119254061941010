/* eslint-disable quote-props, max-len */
export default {
  // Emoji picker
  activity: 'Activity',
  categoriesLabel: 'Emoji categories',
  clear: 'Clear',
  custom: 'Custom',
  flags: 'Flags',
  foods: 'Food & Drink',
  nature: 'Animals & Nature',
  notFound: 'No Emoji Found',
  objects: 'Objects',
  people: 'Smileys & People',
  places: 'Travel & Places',
  recent: 'Frequently Used',
  search: 'Search',
  searchResults: 'Search Results',
  skinText: 'Choose your default skin tone',
  skintone1: 'Default Skin Tone',
  skintone2: 'Light Skin Tone',
  skintone3: 'Medium-Light Skin Tone',
  skintone4: 'Medium Skin Tone',
  skintone5: 'Medium-Dark Skin Tone',
  skintone6: 'Dark Skin Tone',
  symbols: 'Symbols',
  title: 'Skin tone',

  'sign in hint #longtext': `
    You are right now in the crewmeister terminal for time tracking. With this terminal, multiple employees can track their times comfortably with only one device.<br><br>
    Just click on your name, enter your password and track your time.<br><br>
    To exit the terminal on this device, you need to be an admin. To do so, the admin needs to click on his name and login by entering his password. Now, clicking on the link on the bottom right corner „Open full application“ closes the terminal and opens the full account of the admin.<br><br>
  `,
  'Calendar info with confirmation #longtext': `
    <p>Please click here on the days that should get deducted from the vacation entitlement of this employee. Usually those are the days when this employee would normally work.</p>

    <ul>
      <li>A single click on the tile will take one full day off the entitlement: the number "1" is displayed.</li>
      <li>A second click will change this to half a day: the number "0,5" is displayed.</li>
    </ul>

    <p>This is not only to automatically calculate the vacation entitlement, but also because this information to display is relevant in all reports. This is especially relevant for the payroll accounting.</p>

    <p>If you do not need to count the vacation days, you can ignore this step and approve or decline vacation requests straight away.</p>
  `,
  'Calendar info without confirmation #longtext': `
    <p>This is important to make sure sick leave days will appear in the excel export and to allow the System to count the number of sick leave days.</p>

    <p>To do this, please click on the days that when the employee is on sick leave. Usually those are the days when this employee would normally work.</p>

    <ul>
      <li>A single click on the tile will count one day of sick leave: the number "1" is displayed.</li>
      <li>A second click will change this to half a day: the number "0,5" is displayed.</li>
    </ul>
  `,
  "Couldn't send password reset instructions. #longtext":
    "Couldn't send password reset instructions. If you don't have an email, contact your crew owner",
};
