import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ publicHolidays: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const publicHolidaysReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_COUNTRY_PUBLIC_HOLIDAYS_SUCCESS':
        applyMinimalChangesToArray(draft.publicHolidays, action.result, (day) => day.country === action.country);
        break;
      case 'LOAD_CREW_PUBLIC_HOLIDAYS_SUCCESS':
      case 'CREATE_OR_UPDATE_PUBLIC_HOLIDAYS_SUCCESS':
        applyMinimalChangesToArray(draft.publicHolidays, action.result, (day) => day.crewId === action.crewId);
        break;
      default:
      // nothing to do => immer returns the same object
    }
  });
