import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;

const initialState = produce({ userSettings: [] }, () => {});

export const userSettingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_USER_SETTINGS_SUCCESS':
      case 'UPDATE_USER_SETTINGS_SUCCESS':
        applyMinimalChangesToArray(draft.userSettings, action.result, (settings) => settings.userId === action.userId);
        break;
      case 'CLEAR_APP_STATE':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
