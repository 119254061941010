import { addDays, endOfDay, startOfDay } from 'date-fns';

import * as actions from '../../action-creators';
import { api } from '../../lib';

const { loadStamps } = actions;

export const loadTimeTrackingInformationForMember = (dispatch, props) => () => {
  Promise.all([
    dispatch(
      loadStamps.unmemoized({
        api,
        crewId: props.member.crewId,
        userIds: [props.member.userId],
        startTime: startOfDay(addDays(new Date(), -2)).toISOString(),
        endTime: endOfDay(addDays(new Date(), 1)).toISOString(),
      })
    ),
  ]);
};
