import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ geofences: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const geofenceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_GEOFENCES_SUCCESS':
        applyMinimalChangesToArray(draft.geofences, action.result, isGeofenceForCrew(action.crewId));
        break;
      case 'CREATE_OR_UPDATE_GEOFENCE_SUCCESS':
        applyMinimalChangesToArray(draft.geofences, action.result, isSameGeofence(action.crewId, action.groupId));
        break;
      case 'DELETE_GEOFENCE_SUCCESS':
        applyMinimalChangesToArray(draft.geofences, [], isSameGeofence(action.crewId, action.result.groupId));
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });

const isGeofenceForCrew = (crewId) => (geofence) => geofence.crewId === crewId;
const isSameGeofence = (crewId, groupId) => (geofence) => geofence.crewId === crewId && geofence.groupId === groupId;
