import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';

import { COLORS, FONT_STYLES } from '../../constants';

import { buildScopedTranslationFunction } from '../../lib/translate';

import withDisabled from './with-disabled';

const t = buildScopedTranslationFunction();

const RadioButtons = ({
  elements,
  id,
  itemWidth,
  onChange,
  radio = true,
  withoutSelectedBackground,
  withSmallItemHeight,
}) => {
  const theme = createTheme({
    overrides: {
      MuiTypography: {
        body1: {
          ...FONT_STYLES.medium,
          fontSize: '16px',
        },
      },
    },
    palette: {
      primary: { main: COLORS.ORANGE_MAIN },
    },
  });

  const classes = useStyles({ itemWidth, withSmallItemHeight })();

  let ControlComponent;
  if (radio) {
    ControlComponent = ({ idFromParent, ...props }) => (
      <Radio id={idFromParent} {...props} hide="unset" color="primary" />
    );
  } else {
    ControlComponent = ({ idFromParent, hide, value }) => (
      <div>
        {!hide && (
          <Button
            id={idFromParent}
            className={`${classes.button}`}
            color="primary"
            onClick={() => onChange({ target: { value: value } })}
          >
            {t('Select')}
          </Button>
        )}
      </div>
    );
  }

  const selectedId = (elements.find((element) => element.checked) || {}).id;

  return (
    <MuiThemeProvider theme={theme}>
      <RadioGroup column="true" value={selectedId} onChange={onChange}>
        {elements.map((element, idx) => {
          const idFromParent = id ? `${id}-item-${idx}` : undefined;
          return (
            <FormControlLabel
              id={idFromParent}
              key={idFromParent}
              data-e2e-test={`form-control-label-${element.label}`}
              className={
                element.checked
                  ? `${!withoutSelectedBackground && classes.selected} ${classes.item}`
                  : `${classes.item}`
              }
              value={element.id}
              control={<ControlComponent idFromParent={idFromParent} hide={element.checked} onChange={onChange} />}
              label={element.label}
              labelPlacement="start"
            />
          );
        })}
      </RadioGroup>
    </MuiThemeProvider>
  );
};

const useStyles = ({ itemWidth, withSmallItemHeight }) =>
  makeStyles({
    button: {
      ...FONT_STYLES.medium,
      color: COLORS.ORANGE_MEDIUM,
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: 'normal',
      padding: '8px 16px',
    },
    item: {
      display: 'flex',
      height: 'fit-content',
      justifyContent: 'space-between',
      margin: 0,
      minHeight: withSmallItemHeight ? '39px' : '48px',
      padding: '0 12px 0 20px',
      width: itemWidth,
    },
    selected: {
      backgroundColor: COLORS.ORANGE_OPAQUE,
    },
  });

RadioButtons.protoType = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func,
  selectedValue: PropTypes.any,
  radio: PropTypes.bool,
};

export default withDisabled(RadioButtons);
