import { addDays, addMonths, addWeeks, endOfMonth, startOfMonth } from 'date-fns';
import produce from 'immer';
import { subtract } from 'pomeranian-durations';
import { GROUP_BY } from '../constants';
import { array, dataTypes, date as dateUtils, string as stringUtils } from '../utils';

const { applyMinimalChangesToArray, uniqueWithKey } = array;
const { formatIso8601Date, startOfWeek, endOfWeek } = dateUtils;
const { isArray } = dataTypes;
const { toSnakeCase } = stringUtils;

export const initialState = produce({ durations: [] }, () => {});

export const durationsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_DURATIONS_SUCCESS':
        mergeLoadedDurations(draft.durations, fixGroupByWhenThereIsNoTimeCategoryPassed(action));
        break;
      case 'LOAD_DURATIONS_WITH_DELAY_SUCCESS':
        mergeLoadedDurations(draft.durations, fixGroupByWhenThereIsNoTimeCategoryPassed(action));
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
        return state;
    }
  });

const fixGroupByWhenThereIsNoTimeCategoryPassed = (action) => {
  const { groupBy: groupByAction, result, timeCategory1Id, timeCategory2Id } = action;

  // The durations endpoint returns the "sum all" always considering the "No time category"
  // The goal of the condition below is to see if we need to manually subtract the "No time category" times from
  //    the summed all values
  if (
    (timeCategory1Id === undefined || timeCategory1Id?.includes('null')) &&
    (timeCategory2Id === undefined || timeCategory2Id?.includes('null'))
  ) {
    return action;
  }

  const timeCategory =
    (timeCategory1Id && GROUP_BY.TIME_CATEGORY_1_ID) || (timeCategory2Id && GROUP_BY.TIME_CATEGORY_2_ID);
  const groupByTimes = [];

  if (groupByAction.includes(GROUP_BY.DAY)) groupByTimes.push(GROUP_BY.DAY);
  if (groupByAction.includes(GROUP_BY.WEEK)) groupByTimes.push(GROUP_BY.WEEK);
  if (groupByAction.includes(GROUP_BY.MONTH)) groupByTimes.push(GROUP_BY.MONTH);
  if (groupByAction.includes(toSnakeCase(GROUP_BY.USER_ID))) groupByTimes.push(GROUP_BY.USER_ID);
  if (groupByAction.includes(GROUP_BY.YEAR)) groupByTimes.push(GROUP_BY.YEAR);

  const durationsForNoTimeCategory = result.filter(({ groupBy }) => groupBy[timeCategory] === null);

  return groupByTimes.reduce((actionEdited, groupByTime) => {
    const durationsForNoTimeCategoryHashedByXAxis = durationsForNoTimeCategory.reduce((acc, duration) => {
      if (duration.groupBy[groupByTime] === undefined) return acc;
      return {
        ...acc,
        [duration.groupBy[groupByTime]]: duration,
      };
    }, {});

    const totalDurationForNoTimeCategory = durationsForNoTimeCategory.find(
      ({ groupBy }) => groupBy[groupByTime] === undefined
    );

    return {
      ...actionEdited,
      result: actionEdited.result.map((duration) => {
        // If the duration does not contain a "sum all" which no time category would be present, return it the way it is
        if (
          (duration.groupBy[groupByTime] === undefined && duration.groupBy[GROUP_BY.DATE_RANGE] === undefined) ||
          duration.groupBy[timeCategory] !== undefined
        ) {
          return duration;

          // If the duration is about a group by (e.g. day, week or year), then remove the no time category share from it
        } else if (groupByTime !== undefined && duration.groupBy[groupByTime] >= 0) {
          return {
            ...duration,
            value: subtract(
              duration.value,
              durationsForNoTimeCategoryHashedByXAxis[duration.groupBy[groupByTime]]?.value || 'P0S'
            ),
          };
        }

        // Case when the duration is about the "date_range = 0" aka the "sum all". Remove the total value from no time category from it
        return {
          ...duration,
          value: subtract(duration.value, totalDurationForNoTimeCategory?.value || 'P0S'),
        };
      }),
    };
  }, action);
};

const mergeLoadedDurations = (durationInState, action) => {
  const fromAndToPairsInTimespan = getFromAndToPairsInTimespan(action.from, action.to, action.granularity);
  const uniqueFilters = uniqueWithKey(
    action.result.map(({ filters = {} }) => filters),
    'id'
  );

  applyMinimalChangesToArray(durationInState, action.result, (duration) => {
    return (
      duration.crewId === action.crewId &&
      ((duration.filters?.id && uniqueFilters.some(({ id }) => id && duration.filters.id === id)) ||
        // support the user filter
        ((!action.userId ||
          duration.userId === action.userId ||
          (isArray(action.userId) && action.userId.some((userId) => userId === duration.userId))) &&
          // support the type(Name) filter
          (!action.typeName || action.typeName.some((typeName) => duration.type === typeName)) &&
          // support the byUser grouping: if no granularity is given, all results are grouped by userId
          // if a granularity is given, we get the totals for the granularity AND the groupedBy userIds
          // => a userId is required only when byUser is given and no granularity is given
          (action.byUser && !action.granularity ? duration.userId : true) &&
          (!action.byUser && !action.userId ? !duration.userId : true) &&
          fromAndToPairsInTimespan.some(({ from, to }) => duration.from === from && duration.to === to)))
    );
  });
};

const getFromAndToPairsInTimespan = (from, to, granularity) => {
  const fromToPairs = [];
  const startOfTimespan = startOfTimespanFn(granularity);
  const endOfTimespan = endOfTimespanFn(granularity);

  let previousTo = null;
  while (
    fromToPairs.length === 0 ||
    (fromToPairs[fromToPairs.length - 1].to < to && previousTo !== fromToPairs[fromToPairs.length - 1].to)
  ) {
    previousTo = fromToPairs.length === 0 ? null : fromToPairs[fromToPairs.length - 1].to;
    fromToPairs.push({
      from: latestDate(from, formatIso8601Date(startOfTimespan(from, fromToPairs.length))),
      to: earliestDate(to, formatIso8601Date(endOfTimespan(from, fromToPairs.length))),
    });
  }

  return fromToPairs;
};

const startOfTimespanFn = (granularity) => (date, index) => {
  if (granularity === 'day') {
    return addDays(new Date(date), index);
  } else if (granularity === 'week') {
    return startOfWeek(addWeeks(new Date(date), index));
  } else if (granularity === 'month') {
    return startOfMonth(addMonths(new Date(date), index));
  }
  return date;
};

const endOfTimespanFn = (granularity) => (date, index) => {
  if (granularity === 'day') {
    return addDays(new Date(date), index);
  } else if (granularity === 'week') {
    return endOfWeek(addWeeks(new Date(date), index));
  } else if (granularity === 'month') {
    return endOfMonth(addMonths(new Date(date), index));
  }
  return date;
};

const latestDate = (date1, date2) => (date1 < date2 ? date2 : date1);
const earliestDate = (date1, date2) => (date1 < date2 ? date1 : date2);
