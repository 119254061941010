import React from 'react';
import { collections, react } from '../../utils';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES } from '../../constants';
import { Z_INDEX } from '../../styles/config';
import Icon from '../../components/core/icon';

const { composeReverse } = collections;
const { withUIState } = react;

const Template = (props) => (
  <If condition={!props.dismissed && props.display}>
    <div className={css(styles.wrapper)}>
      <section className={css(styles.container)}>
        {props.children}
        <If condition={props.allowDismiss}>
          <Icon className={css(styles.close)} name="close" onClick={() => props.setDismissed(true)} />
        </If>
      </section>
    </div>
  </If>
);

const OverlayMessage = composeReverse(withUIState('dismissed', 'setDismissed', false))(Template);

const styles = StyleSheet.create({
  wrapper: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    background: 'rgba(0,0,0,0.2)',
    zIndex: Z_INDEX.offlineBannerWrapper,
    cursor: 'not-allowed',
  },
  container: {
    ...FONT_STYLES.small,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    padding: '17px 40px',
    boxShadow: '0 0 2px 0 rgba(104, 103, 102, 0.3)',
    color: COLORS.WHITE,
    backgroundColor: COLORS.RED_MEDIUM,
    position: 'fixed',
    width: '100%',
  },
  close: {
    fontSize: 24,
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
});

export default OverlayMessage;
