import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadUserRooms =
  ({ api, userId }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'LOAD_USER_ROOMS',
      fnParams: { userId },
      fn: (params) => api.getUserRooms(params),
    });

export const createRoom = memoize(
  { ttl: 0 },
  ({ api, name, userId, crewId, userIds, type }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'CREATE_ROOM',
        fnParams: { name, userId, crewId, userIds, type },
        fn: (params) => api.createRoom(params),
      })
);

export const deleteRoom = memoize(
  { ttl: 0 },
  ({ api, userId, roomId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'DELETE_ROOM',
        fnParams: { userId, roomId },
        fn: (params) => api.deleteRoom(params),
      })
);

export const updateRoom = memoize(
  { ttl: 0 },
  ({ api, userId, roomId, name }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'UPDATE_ROOM',
        fnParams: { userId, roomId, name },
        fn: (params) => api.updateRoom(params),
      })
);

export const updatedRoom = (payload) => (dispatch) =>
  dispatchAsyncFnStatusActions({
    dispatch,
    actionName: 'UPDATED_ROOM',
    fnParams: payload,
    fn: (params) => Promise.resolve(params),
  });

export const addUsersToRoom = memoize(
  { ttl: 0 },
  ({ api, userId, roomId, userIds }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'ADD_USERS_TO_ROOM',
        fnParams: { userId, roomId, userIds },
        fn: (params) => api.addUsersToRoom(params),
      })
);

export const addedRoom = (payload) => (dispatch) =>
  dispatchAsyncFnStatusActions({
    dispatch,
    actionName: 'ADDED_ROOM',
    fnParams: payload,
    fn: (params) => Promise.resolve(params),
  });

export const removeUsersFromRoom = memoize(
  { ttl: 0 },
  ({ api, userId, roomId, userIds }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'REMOVE_USERS_FROM_ROOM',
        fnParams: { userId, roomId, userIds },
        fn: (params) => api.removeUsersFromRoom(params),
      })
);

export const removedRoom = (payload) => (dispatch) =>
  dispatchAsyncFnStatusActions({
    dispatch,
    actionName: 'REMOVED_ROOM',
    fnParams: payload,
    fn: (params) => Promise.resolve(params),
  });
