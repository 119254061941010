import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '../../action-creators';
import { api, apiV3, callFunctionWhenPropsChange } from '../../lib';
import * as selectors from '../../selectors';
import { array, collections } from '../../utils';

import { loadTimeTrackingInformationForMember } from './utils';
import { enterUserApp } from '../terminal-time-tracking/utils';
import Organism from './organism';

const { sortByKey } = array;
const { composeReverse } = collections;

const { loadCrewMembers, signOutUser, v3SignOutUser } = actions;
const { selectMembersInCurrentCrew } = selectors;

const mapStateToProps = (state, props) => ({
  ...props,
  members: sortByKey(selectMembersInCurrentCrew(state, props), 'id'),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const dispatchLoadCrewMembers = () => dispatch(loadCrewMembers.unmemoized({ api, crewId: ownProps.crewId }));

  return {
    functionToCall: () => {
      if (ownProps.crewId) dispatchLoadCrewMembers();
    },
    enterUserApp: enterUserApp(dispatch),
    loadCrewMembers: dispatchLoadCrewMembers,
    loadTimeTrackingInformation: (member) => loadTimeTrackingInformationForMember(dispatch, { ...ownProps, member }),
    signOutUser: () => {
      dispatch(signOutUser({ api, clearCache: false }));
      dispatch(v3SignOutUser({ apiV3 }));
    },
  };
};

const TerminalPinCode = composeReverse(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId'] })
)(Organism);

export default TerminalPinCode;
