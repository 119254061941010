import produce from 'immer';
import { ACTION_NAMES } from '../../constants';
import { SignOutActionType, V3TemplateShiftResponseType, V3TemplateShiftType, V3TemplateType } from '../../types';
import { array } from '../../utils';

const initialState = produce({ templateShifts: [] }, () => {});
const { applyMinimalChangesToArray } = array;

type V3TemplateShiftsAction = {
  result: V3TemplateShiftType[];
  type: ACTION_NAMES.V3_CREATE_TEMPLATE_SHIFTS | ACTION_NAMES.V3_GET_TEMPLATE_SHIFTS;
} & Pick<V3TemplateType, 'crewId' | 'name'>;

type V3ShiftsSliceInRedux = { templateShifts: V3TemplateShiftType[] };

export const templateShiftsReducer = (
  state = initialState,
  action: V3TemplateShiftsAction | SignOutActionType
): V3ShiftsSliceInRedux =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_CREATE_TEMPLATE_SHIFTS}_SUCCESS`: {
        const templateShiftsCreated = action.result.map(
          ({ body }: { body: V3TemplateShiftResponseType }) => body?.resourceAfterWrite || body
        ) as V3TemplateShiftType[];

        applyMinimalChangesToArray(draft.templateShifts, templateShiftsCreated, () => false);
        break;
      }

      case `${ACTION_NAMES.V3_DELETE_TEMPLATE_SHIFTS}_SUCCESS`: {
        const templateShiftIdsDeleted = action.result.map(
          ({ body }: { body: V3TemplateShiftResponseType }) => body?.resourceBeforeWrite?.id || body?.id
        );
        applyMinimalChangesToArray<V3TemplateShiftType>(draft.templateShifts, [], (templateShift) =>
          templateShiftIdsDeleted.includes(templateShift.id)
        );
        break;
      }

      case `${ACTION_NAMES.V3_DELETE_TEMPLATE}_SUCCESS`: {
        const id = action?.result?.resourceBeforeWrite?.id || action?.result?.id;
        applyMinimalChangesToArray<V3TemplateShiftType>(
          draft.templateShifts,
          [],
          (template) => template.templateId === id
        );
        break;
      }

      case `${ACTION_NAMES.V3_GET_TEMPLATE_SHIFTS}_SUCCESS`: {
        const templateIds = action.result.content.map(({ templateId }: { templateId: number }) => templateId);
        applyMinimalChangesToArray(draft.templateShifts, action.result.content, ({ templateId }) =>
          templateIds.includes(templateId)
        );
        break;
      }

      case `${ACTION_NAMES.SIGNOUT_USER}_SUCCESS`:
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
