import {
  fetchGet as nativeFetchGet,
  fetchPost as nativeFetchPost,
  fetchDelete as nativeFetchDelete,
  fetchPut as nativeFetchPut,
} from '../_external-deps/fetch';

export const fetchPost = nativeFetchPost;
export const fetchGet = nativeFetchGet;
export const fetchDelete = nativeFetchDelete;
export const fetchPut = nativeFetchPut;
