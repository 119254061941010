import produce from 'immer';
import { ACTION_NAMES } from '../constants';
import { array } from '../utils';

const initialState = produce({ userReferrals: [] }, () => {});
const { applyMinimalChangesToArray } = array;

export const userReferralsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${ACTION_NAMES.LOAD_REFERRAL_CODE}_SUCCESS`:
        applyMinimalChangesToArray(
          draft.userReferrals,
          action.result,
          (userReferral) => userReferral.userId === action.userId
        );
        break;
      default:
      // nothing to do => immer returns the same object
    }
  });
