import { statusCodes, selectors } from '@crewmeister/shared';

import * as SentryService from '../services/sentry/index';

const { selectCurrentUserId } = selectors;
let didInit = false;

export const crewIdFromPathname = (location = {}) => {
  const match = (location.pathname || '').match(/\/crew\/\d+/);
  if (!match) {
    return void 0;
  }
  return parseInt(match[0].replace('/crew/', ''), 10);
};

const createErrorReportingMiddleware = ({ dsn }) => {
  if (!didInit) {
    global.Sentry = SentryService.getSentryClient({ dsn }); // make client globally available for SentryLogService
    didInit = true;

    return () => (next) => (action) => next(action);
  }

  return (store) => (next) => (action) => {
    try {
      const crewId = crewIdFromPathname(global.location);
      const userId = selectCurrentUserId(store.getState());

      SentryService.addBreadcrumb({
        category: 'redux',
        message: action.type,
      });

      SentryService.configureScope((scope) =>
        scope.setUser({
          id: `crewId: ${crewId}, userId: ${userId}`,
          crewId,
          userId,
        })
      );

      return next(action);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      if (e.id !== statusCodes.NOT_AUTHENTICATED) {
        SentryService.withScope((scope) => {
          scope.setExtras({
            action,
            state: store.getState(),
          });
          SentryService.captureException(e);
        });
      }
    }
  };
};

export default createErrorReportingMiddleware;
