/* eslint-disable quote-props */

export enum ICON_NAMES {
  ABSENCE_MANAGEMENT = 'absence-management',
  ABSENCE_MANAGEMENT_BETA = 'absence-management-beta',
  ADD_GROUP = 'add-group',
  ADD_LARGE = 'add-large',
  ADD_MEMBER = 'add-member',
  ADD_SMALL = 'add-small',
  ALARM = 'alarm',
  ALARM_2 = 'alarm-2',
  ALARM_ACTIVE = 'alarm-active',
  ANALYSIS = 'analysis',
  ARROW_PEOPLE = 'arrow-people',
  ATTACHMENT = 'attachment',
  BACK = 'back',
  BELL_ALARM = 'bell-alarm',
  CALENDAR = 'calendar',
  CALENDAR_BLANK = 'calendar-blank',
  CALENDAR_WITH_DAY = 'calendar-with-day',
  CHAT = 'chat',
  CHAT_FILLED = 'chat-filled',
  CHECK = 'check',
  CHECK_2 = 'check-2',
  CHECK_NO = 'check-no',
  CHECK_YES = 'check-yes',
  CLOCK = 'clock',
  CLOCK_2 = 'clock-2',
  CLOCK_3 = 'clock-3',
  CLOSE = 'close',
  COPY = 'copy',
  CLOCK_BREAK = 'clock-break',
  CLOCK_FINISHED = 'clock-finished',
  CLOCK_WORKING = 'clock-working',
  COMPUTER_FILLED = 'computer-filled',
  COMPUTER = 'computer',
  CREWMEISTER = 'crewmeister',
  CREWMEMBERS = 'crewmembers',
  DELETE = 'delete',
  DOWN = 'down',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  EMOJI = 'emoji',
  EYE = 'eye',
  EYE2 = 'eye2',
  EYE_BLOCKED = 'eye-blocked',
  EYE_BLOCKED_2 = 'eye-blocked-2',
  FILE_EXCEL = 'file-excel',
  FUNNEL = 'funnel',
  HELP = 'help',
  HOME = 'home',
  ICE_CREAM = 'ice-cream',
  INFO = 'info',
  INFO_BUBBLE = 'info-bubble',
  INFO_CIRCLE = 'info-circle',
  LAW = 'law',
  LAYERS = 'layers',
  LAYOUT = 'layout',
  LEFT = 'left',
  LEFT_DOUBLE = 'left-double',
  LINK = 'link',
  LITTLE_CLOSE = 'little-close',
  LITTLE_MINUS = 'little-minus',
  LITTLE_PLUS = 'little-plus',
  LIVE = 'live',
  LOCATION = 'location',
  LOCK_OFF = 'lock-off',
  LOCK_ON = 'lock-on',
  LOGOUT = 'logout',
  LOVE = 'love',
  MAIL = 'mail',
  MAIL_FILLED = 'mail-filled',
  MAXIMIZE = 'maximize',
  MESSAGE = 'message',
  MICROPHONE = 'microphone',
  MOBILE = 'mobile',
  MOBILE_HAMBURGER = 'mobile-hamburger',
  MOBILE_HAMBURGER_WITH_NOTE = 'mobile-hamburger-with-note',
  MY_PROFILE = 'my-profile',
  MY_PROFILE_2 = 'my-profile-2',
  NAV_ABSENCE_ACTIVE = 'nav-absence-active',
  NAV_LIVE_ACTIVE = 'nav-live-active',
  NAV_SETTINGS_ACTIVE = 'nav-settings-active',
  NAV_TIME_ACTIVE = 'nav-time-active',
  NOTES = 'notes',
  OPTIONS = 'options',
  PALETTE = 'palette',
  PAUSE = 'pause',
  PERSON_DETAILS = 'person-details',
  PHONE = 'phone',
  PICTURE = 'picture',
  PIN = 'pin',
  QUESTION_MARK = 'question-mark',
  QUESTION_MARK_2 = 'question-mark-2',
  RELOAD = 'reload',
  RESEND = 'resend',
  RESET = 'reset',
  RESET_CIRCLE = 'reset-circle',
  RIGHT = 'right',
  RIGHT_DOUBLE = 'right-double',
  RIGHTS = 'rights',
  ROUNDING = 'rounding',
  SALARY_EXPORT = 'salary-export',
  SEARCH = 'search',
  SETTINGS = 'settings',
  SETTINGS_DOTS = 'settings-dots',
  SHIFT_PLANNER = 'shift-planner',
  SIGN_OUT = 'sign-out',
  SMARTPHONE = 'smartphone',
  SMARTPHONE_FILLED = 'smartphone-filled',
  SORT = 'sort',
  SORT_BY_TIME = 'sort-by-time',
  SWITCH = 'switch',
  SYNC = 'sync',
  SYNC_PROBLEM = 'sync-problem',
  SYNC_OFF = 'sync-off',
  SYNC_ON = 'sync-on',
  TAGS = 'tags',
  TECK = 'teck',
  TIME_TRACKING = 'time-tracking',
  TIME_TRACKING_2 = 'time-tracking-2',
  TIME_TRACKING_REPORT = 'time-tracking-report',
  TELEPHONE = 'telephone',
  THUMBS_DOWN = 'thumbs-down',
  THUMBS_UP = 'thumbs-up',
  UP = 'up',
  WARNING = 'warning',
  WRENCH = 'wrench',
}

export const AVAILABLE_ICONS = {
  [ICON_NAMES.ABSENCE_MANAGEMENT]: '\ue921',
  [ICON_NAMES.ABSENCE_MANAGEMENT_BETA]: '\ue96c',
  [ICON_NAMES.ADD_GROUP]: '\ue94e',
  [ICON_NAMES.ADD_LARGE]: '\ue900',
  [ICON_NAMES.ADD_MEMBER]: '\ue94f',
  [ICON_NAMES.ADD_SMALL]: '\ue901',
  [ICON_NAMES.ALARM]: '\ue925',
  [ICON_NAMES.ALARM_2]: '\ue96d',
  [ICON_NAMES.ALARM_ACTIVE]: '\ue961',
  [ICON_NAMES.ANALYSIS]: '\ue960',
  [ICON_NAMES.ARROW_PEOPLE]: '\ue96f',
  [ICON_NAMES.ATTACHMENT]: '\ue943',
  [ICON_NAMES.BACK]: '\ue950',
  [ICON_NAMES.BELL_ALARM]: '\ue970',
  [ICON_NAMES.CALENDAR]: '\ue923',
  [ICON_NAMES.CALENDAR_BLANK]: '\ue922',
  [ICON_NAMES.CALENDAR_WITH_DAY]: '\ue95f',
  [ICON_NAMES.CHAT]: '\ue945',
  [ICON_NAMES.CHAT_FILLED]: '\ue949',
  [ICON_NAMES.CHECK]: '\ue908',
  [ICON_NAMES.CHECK_2]: '\ue96e',
  [ICON_NAMES.CHECK_NO]: '\ue929',
  [ICON_NAMES.CHECK_YES]: '\ue906',
  [ICON_NAMES.CLOCK]: '\ue952',
  [ICON_NAMES.CLOCK_2]: '\ue976',
  [ICON_NAMES.CLOCK_3]: '\ue977',
  [ICON_NAMES.CLOSE]: '\ue909',
  [ICON_NAMES.COPY]: '\ue941',
  [ICON_NAMES.CLOCK_BREAK]: '\ue963',
  [ICON_NAMES.CLOCK_FINISHED]: '\ue966',
  [ICON_NAMES.CLOCK_WORKING]: '\ue965',
  [ICON_NAMES.COMPUTER_FILLED]: '\ue967',
  [ICON_NAMES.COMPUTER]: '\ue968',
  [ICON_NAMES.CREWMEISTER]: '\ue93f',
  [ICON_NAMES.CREWMEMBERS]: '\ue937',
  [ICON_NAMES.DELETE]: '\ue90b',
  [ICON_NAMES.DOWN]: '\uedc9',
  [ICON_NAMES.DOWNLOAD]: '\ue926',
  [ICON_NAMES.EDIT]: '\ue90c',
  [ICON_NAMES.EMOJI]: '\ue946',
  [ICON_NAMES.EYE]: '\ue939',
  [ICON_NAMES.EYE2]: '\ue93d',
  [ICON_NAMES.EYE_BLOCKED]: '\ue938',
  [ICON_NAMES.EYE_BLOCKED_2]: '\ue93e',
  [ICON_NAMES.FILE_EXCEL]: '\ueae2',
  [ICON_NAMES.FUNNEL]: '\ue936',
  [ICON_NAMES.HELP]: '\ue933',
  [ICON_NAMES.HOME]: '\ue951',
  [ICON_NAMES.ICE_CREAM]: '\ue928',
  [ICON_NAMES.INFO]: '\ue942',
  [ICON_NAMES.INFO_BUBBLE]: '\ue94d',
  [ICON_NAMES.INFO_CIRCLE]: '\ue948',
  [ICON_NAMES.LAW]: '\ue90e',
  [ICON_NAMES.LAYERS]: '\ue90f',
  [ICON_NAMES.LAYOUT]: '\ue910',
  [ICON_NAMES.LEFT]: '\uedca',
  [ICON_NAMES.LEFT_DOUBLE]: '\ued88',
  [ICON_NAMES.LINK]: '\ue95d',
  [ICON_NAMES.LITTLE_CLOSE]: '\ue91a',
  [ICON_NAMES.LITTLE_MINUS]: '\ue91d',
  [ICON_NAMES.LITTLE_PLUS]: '\ue924',
  [ICON_NAMES.LIVE]: '\ue93a',
  [ICON_NAMES.LOCATION]: '\ue911',
  [ICON_NAMES.LOCK_OFF]: '\ue913',
  [ICON_NAMES.LOCK_ON]: '\ue916',
  [ICON_NAMES.LOGOUT]: '\ue95c',
  [ICON_NAMES.LOVE]: '\ue95b',
  [ICON_NAMES.MAIL]: '\ue932',
  [ICON_NAMES.MAIL_FILLED]: '\ue969',
  [ICON_NAMES.MAXIMIZE]: '\ue92a',
  [ICON_NAMES.MESSAGE]: '\ue92b',
  [ICON_NAMES.MICROPHONE]: '\ue95a',
  [ICON_NAMES.MOBILE]: '\ue914',
  [ICON_NAMES.MOBILE_HAMBURGER]: '\ue934',
  [ICON_NAMES.MOBILE_HAMBURGER_WITH_NOTE]: '\ue935',
  [ICON_NAMES.MY_PROFILE]: '\ue92c',
  [ICON_NAMES.MY_PROFILE_2]: '\ue94c',
  [ICON_NAMES.NAV_ABSENCE_ACTIVE]: '\ue905',
  [ICON_NAMES.NAV_LIVE_ACTIVE]: '\ue904',
  [ICON_NAMES.NAV_SETTINGS_ACTIVE]: '\ue902',
  [ICON_NAMES.NAV_TIME_ACTIVE]: '\ue903',
  [ICON_NAMES.NOTES]: '\ue93b',
  [ICON_NAMES.OPTIONS]: '\ue962',
  [ICON_NAMES.PALETTE]: '\ue912',
  [ICON_NAMES.PAUSE]: '\ue915',
  [ICON_NAMES.PERSON_DETAILS]: '\ue959',
  [ICON_NAMES.PHONE]: '\ue947',
  [ICON_NAMES.PICTURE]: '\ue94b',
  [ICON_NAMES.PIN]: '\ue944',
  [ICON_NAMES.QUESTION_MARK]: '\ue95e',
  [ICON_NAMES.QUESTION_MARK_2]: '\ue975',
  [ICON_NAMES.RELOAD]: '\ue958',
  [ICON_NAMES.RESEND]: '\ue917',
  [ICON_NAMES.RESET]: '\ue92d',
  [ICON_NAMES.RESET_CIRCLE]: '\ue957',
  [ICON_NAMES.RIGHT]: '\uedc8',
  [ICON_NAMES.RIGHT_DOUBLE]: '\ued89',
  [ICON_NAMES.RIGHTS]: '\ue918',
  [ICON_NAMES.ROUNDING]: '\ue90d',
  [ICON_NAMES.SALARY_EXPORT]: '\ue93c',
  [ICON_NAMES.SEARCH]: '\ue919',
  [ICON_NAMES.SETTINGS]: '\ue931',
  [ICON_NAMES.SETTINGS_DOTS]: '\ue94a',
  [ICON_NAMES.SHIFT_PLANNER]: '\ue940',
  [ICON_NAMES.SIGN_OUT]: '\ue907',
  [ICON_NAMES.SMARTPHONE]: '\ue96a',
  [ICON_NAMES.SMARTPHONE_FILLED]: '\ue96b',
  [ICON_NAMES.SORT]: '\ue91b',
  [ICON_NAMES.SORT_BY_TIME]: '\ue956',
  [ICON_NAMES.SWITCH]: '\ue91c',
  [ICON_NAMES.SYNC]: '\ue92e',
  [ICON_NAMES.SYNC_PROBLEM]: '\ue955',
  [ICON_NAMES.SYNC_OFF]: '\ue953',
  [ICON_NAMES.SYNC_ON]: '\ue954',
  [ICON_NAMES.TAGS]: '\ue92f',
  [ICON_NAMES.TECK]: '\ue91e',
  [ICON_NAMES.TIME_TRACKING]: '\ue920',
  [ICON_NAMES.TIME_TRACKING_2]: '\ue964',
  [ICON_NAMES.TIME_TRACKING_REPORT]: '\ue927',
  [ICON_NAMES.TELEPHONE]: '\ue91f',
  [ICON_NAMES.THUMBS_DOWN]: '\ue973',
  [ICON_NAMES.THUMBS_UP]: '\ue971',
  [ICON_NAMES.UP]: '\uedc7',
  [ICON_NAMES.WARNING]: '\ue90a',
  [ICON_NAMES.WRENCH]: '\ue930',
};
