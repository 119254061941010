import { createSelector } from 'reselect';

const selectUserIdFromProps = (_state: any, props: any) => (props && props.userId ? props.userId : null);

const selectUserReferralsFromState = (state: any) => state.bo.userReferrals.userReferrals;

type UserReferral = { lastUsedAt: string; referralCode: string; userId: number };

export const selectUserReferral: (state: any, props: any) => UserReferral = createSelector(
  selectUserReferralsFromState,
  selectUserIdFromProps,
  (userReferrals, userId) => userReferrals.find((userReferral: UserReferral) => userReferral.userId === userId)
);
