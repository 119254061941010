import * as Sentry from '@sentry/browser';

import { blacklistUrls, ignoreErrors } from './constants';
import { getEnv } from '../../utils';

type SentryClient = typeof Sentry;

let didInit = false;

const getSentryClient = (options: Sentry.BrowserOptions = {}): SentryClient => {
  if (!didInit) {
    const dsn = options.dsn || getEnv(process.env.SENTRY_DSN, 'SENTRY_DSN');
    const environment = (process.env.CREWMEISTER_API_DOMAIN || '').includes('stage') ? 'stage' : process.env.NODE_ENV;
    const release = process.env.CI_COMMIT_SHA;

    Sentry.init({
      ...options,
      blacklistUrls,
      dsn,
      environment,
      ignoreErrors,
      release,
    });

    didInit = true;
  }

  return Sentry;
};

const addBreadcrumb = (breadcrumb: Sentry.Breadcrumb): void => {
  getSentryClient().addBreadcrumb(breadcrumb);
};

const configureScope = (callback: (scope: Sentry.Scope) => void): void => {
  getSentryClient().configureScope(callback);
};

const withScope = (callback: (scope: Sentry.Scope) => void): void => {
  getSentryClient().withScope(callback);
};

const captureException = (exception: Error): void => {
  getSentryClient().captureException(exception);
};

export { addBreadcrumb, captureException, configureScope, getSentryClient, withScope };
