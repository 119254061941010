import React from 'react';
import PropTypes from 'prop-types';
import SubNavigation from './sub-navigation';
import { array, collections, dataTypes, react } from '../../utils';

const { lastElement } = array;
const { composeReverse } = collections;
const { isFunction, isNumber } = dataTypes;
const { withUIState, prepareProps } = react;

const Template = ({
  items,
  Component,
  component,
  propsForComponent,
  center,
  tabContainerStyle = { width: '100%' },
  tabButtonStyle,
  navigationContainerStyle,
  id,
}) => (
  <div style={tabContainerStyle}>
    <SubNavigation
      items={items}
      horizontalLeft={!center}
      tabButtonStyle={tabButtonStyle}
      navigationContainerStyle={navigationContainerStyle}
    />
    {Component && <Component {...propsForComponent} id={id} />}
    {component}
  </div>
);

const Tabs = composeReverse(
  withUIState('selectedIndex', 'setSelectedIndex', (props) => props.selectedTabIndex || 0),
  prepareProps((props) => {
    if (isNumber(props.selectedTabIndex) && props.selectedTabIndex !== props.selectedIndex)
      props.setSelectedIndex(props.selectedTabIndex);

    return {
      ...props,
      items: props.items.map((item, index) => ({
        ...item,
        isSelected: index === props.selectedIndex,
        onClick: () => {
          props.setSelectedIndex(index);
          if (isFunction(props.onChange)) props.onChange(index);
        },
      })),
      Component:
        props.items.length > props.selectedIndex
          ? props.items.find((_, index) => index === props.selectedIndex).Component
          : lastElement(props.items).Component,
      component:
        props.items.length > props.selectedIndex
          ? props.items.find((_, index) => index === props.selectedIndex).component
          : lastElement(props.items).component,
    };
  })
)(Template);

Tabs.props = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      Component: PropTypes.any,
      component: PropTypes.any,
    })
  ).isRequired,
};

export default Tabs;
