import { factory, sequence, random } from 'stahlwerk';
import { NEWS_ACTIONS } from '../constants';

export const createNewsItem = factory({
  id: sequence(),
  crewId: sequence(),
  title: sequence((id) => `News title ${id}`),
  text: 'This is the news text',
  type: random(Object.keys(NEWS_ACTIONS)),
  description: 'Some description',
  senderId: sequence(),
  sentAt: new Date(),
});
