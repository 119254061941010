export enum GenericActionTypes {
  CLEAR_APP_STATE = 'CLEAR_APP_STATE', // used throughout the app to clear state

  LOAD_CALENDAR_DATA = 'LOAD_CALENDAR_DATA',
  LOAD_CALENDAR_DATA_FAILURE = 'LOAD_CALENDAR_DATA_FAILURE',
  LOAD_CALENDAR_DATA_SUCCESS = 'LOAD_CALENDAR_DATA_SUCCESS',
  LOAD_DAY_KIND_CALENDAR_SUCCESS = 'LOAD_DAY_KIND_CALENDAR_SUCCESS',

  SET_DEBUG_STOREDATA = 'SET_DEBUG_STOREDATA',
}
