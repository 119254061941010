import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadTimeCategories = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_TIME_CATEGORIES',
        fnParams: { crewId: Number.parseInt(crewId, 10) },
        fn: (params) => api.getTimeCategories(params),
      })
);

export const createTimeCategory =
  ({ api, crewId, name, level }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'CREATE_TIME_CATEGORY',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        name,
        level,
      },
      fn: (params) => api.createTimeCategory(params),
    });

export const addTimeCategory =
  ({ api, crewId, name }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'ADD_TIME_CATEGORY',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        name,
      },
      fn: (params) => api.addTimeCategory(params),
    });

export const updateTimeCategory =
  ({ api, crewId, id, level, name, disabled }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_TIME_CATEGORY',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        id,
        level,
        name,
        disabled,
      },
      fn: (params) => api.updateTimeCategory(params),
    });
