import { createSelector } from 'reselect';
import produce from 'immer';
import { selectCurrentUserId } from './authentication-selectors';
import { selectCurrentCrewId } from './crews-selectors';
import { selectCurrentCrewSettings } from './crew-settings-selectors';
import { findByProps, createPropsFinder, createPropsSelector } from './utils';
import { isWithinRange } from '../utils/date';
import { PUB_STATE } from '../constants';

export const selectShifts = (state) => state.bo.shifts.shifts || produce([], () => {});
export const selectShiftsByProps = createPropsSelector(selectShifts);
export const findShiftByProps = createPropsFinder(selectShifts);

export const selectShiftGuid = (_state, props) => (props && props.guid ? props.guid : null);

export const selectCrewShifts = createSelector(selectShifts, selectCurrentCrewId, (shifts, crewId) =>
  shifts.filter((shift) => shift.crewId === crewId)
);

export const selectUserShifts = createSelector(
  selectShifts,
  selectCurrentCrewId,
  selectCurrentUserId,
  (shifts, crewId, userId) => shifts.filter((shift) => shift.crewId === crewId && shift.userId === userId)
);

export const findShift = createSelector(selectUserShifts, selectShiftGuid, (shifts, guid) =>
  findByProps(shifts, { guid })
);

export const findNextShift = createSelector(selectUserShifts, selectCurrentUserId, (shifts, userId) =>
  findByProps(shifts, { filter: (shift) => shift.isNextShift && shift.userId === userId })
);

export const selectCrewShiftsInPeriod = createSelector(
  (_, props) => props,
  selectCrewShifts,
  (props, shifts) => {
    const rangeShifts = shifts.filter(
      (shift) => shift.guid && isWithinRange(new Date(shift.from), new Date(props.startDate), new Date(props.endDate))
    );
    const published = rangeShifts.filter((shift) => shift.pubState === PUB_STATE.PUBLISHED);
    const drafts = rangeShifts.filter((shift) => shift.pubState !== PUB_STATE.PUBLISHED);

    const publishedWithDraftFlag = published.map((shift) => {
      const hasDraft = drafts.some((_draft) => _draft.guid === shift.guid);
      return { ...shift, hasDraft };
    });

    return publishedWithDraftFlag.concat(drafts);
  }
);

export const findShiftGracePeriodActive = (param) =>
  createSelector(selectCurrentCrewSettings, (settings) => {
    const values = (settings || {})[param];
    if (!values || !values.length) {
      return false;
    }

    return values.some((value) => isWithinRange(new Date(), new Date(value.validFrom), new Date(value.validTo)));
  });
