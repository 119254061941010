import fetch from './fetch';
import { throwIfError } from './status-codes';

const toPayload = (response) => {
  return response.json().then(({ payload }) => payload);
};

export const updateTimestamp = ({ headers, payload }) => {
  return fetch.updateTimestamp({ headers, payload }).then(throwIfError).then(toPayload);
};
