import PropTypes from 'prop-types';
import React from 'react';

import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS } from '../../constants';

const Checkbox = ({ className, defaultChecked = false, disabled, id, name, onChange, required = false, value }) => {
  return (
    <div
      className={`${css(
        styles.wrapper,
        (value || defaultChecked) && styles.checked,
        disabled && styles.disabled
      )} ${className}`}
    >
      <input
        className={css(styles.input, disabled && styles.disabled)}
        disabled={disabled}
        id={id}
        name={name}
        onChange={() => onChange && onChange(!value)}
        required={required}
        type="checkbox"
        {...(value === undefined ? { defaultChecked } : { checked: value })}
      />
      <If condition={value || defaultChecked}>
        <i className={css(styles.icon, disabled && styles.disabled)} />
      </If>
    </div>
  );
};

Checkbox.propTypes = {
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  name: PropTypes.string,
};

const styles = StyleSheet.create({
  wrapper: {
    content: "''",
    border: `2px solid ${COLORS.GREY_DARK}`,
    borderRadius: 2,
    height: 18,
    width: 18,
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
  },
  input: {
    transform: 'scale(1.5)',
    opacity: 0,
    margin: 0,
    cursor: 'pointer',
  },
  icon: {
    content: "''",
    display: 'block',
    width: '12px',
    height: '6px',
    borderBottom: `2px solid ${COLORS.WHITE}`,
    borderLeft: `2px solid ${COLORS.WHITE}`,
    transform: 'rotate(-45deg)',
    position: 'absolute',
    top: '4px',
    left: '3px',
  },
  checked: {
    background: `${COLORS.MAIN_COLOR_A_BIT_LITER}`,
    borderWidth: 0,
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
});

export default Checkbox;
