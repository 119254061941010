import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../constants';
import { SettingValueType, SettingValueWithCrewIdType } from '../types';
import { array } from '../utils';

const { addProperty, applyMinimalChangesToArray } = array;

interface SettingsValuesState {
  settingsValues: SettingValueWithCrewIdType[];
}

const initialState: SettingsValuesState = {
  settingsValues: [],
};

export const settingsValuesReducer = (
  state = initialState,
  action: AnyAction & { crewId: number; result: SettingValueType[] }
): SettingsValuesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${ACTION_NAMES.LOAD_SETTINGS_VALUES}_SUCCESS`:
      case `${ACTION_NAMES.POST_SETTING_VALUES}_SUCCESS`:
      case `${ACTION_NAMES.DELETE_SETTING_VALUES}_SUCCESS`:
        applyMinimalChangesToArray(
          draft.settingsValues,
          // FIXME: This is a hack to make the reducer work with TS
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          addProperty(action.result, 'crewId', action.crewId) as any,
          (settingValue: SettingValueWithCrewIdType) => settingValue.crewId === action.crewId
        );
        break;

      default:
    }
  });
