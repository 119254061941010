import React from 'react';
import Tooltip from 'react-simple-tooltip';
import { css } from 'styled-components';

import { COLORS, FONT_STYLES } from '../../constants';
import { FCWithChildren } from '../../types';

interface ToolTipProps {
  style?: Record<string, number | string>;
  text: string;
  placement?: 'top' | 'bottom';
}

const ToolTip: FCWithChildren<ToolTipProps> = ({ children, style, text, placement = 'top' }) => (
  <React.Fragment>
    {text && (
      <Tooltip
        content={text}
        placement={placement}
        background={COLORS.MAIN_COLOR_A_BIT_LITER}
        border={COLORS.MAIN_COLOR_A_BIT_LITER}
        padding={8}
        radius={2}
        customCss={css`
          white-space: nowrap;
          position: initial;
        `}
        style={Object.assign(
          {
            ...FONT_STYLES.small,
            tableLayout: 'fixed',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            display: 'block',
            zIndex: 2,
          },
          style
        )}
      >
        <div>{children}</div>
      </Tooltip>
    )}

    {!text && children}
  </React.Fragment>
);

export default ToolTip;
