import { factory, trait, sequence, random } from 'stahlwerk';
import { formatIso8601Date } from '../utils/date';

export const createAbsenceDay = factory({
  id: sequence(),
  typeId: sequence(),
  crewId: sequence(),
  date: formatIso8601Date(new Date()),
  fraction: random([1, 2]),
  wholeDay: trait({
    fraction: 1,
    value: 1,
  }),
  halfDay: trait({
    fraction: 2,
    value: 0.5,
  }),
});
