import { createSelector } from 'reselect';
import { createPropsFinder, createPropsSelector } from './utils';
import { selectCurrentCrewId } from './crews-selectors';

const selectGeofencesFromState = (state) => state.bo.geofences.geofences;

export const selectGeofences = createSelector(selectGeofencesFromState, (geofences) => geofences.concat());

export const selectGeofencesForCurrentCrew = createSelector(selectGeofences, selectCurrentCrewId, (geofences, crewId) =>
  geofences.filter((geofence) => geofence.crewId === crewId)
);

export const selectGeofenceForTimeCategory = createSelector(
  [selectGeofences, (_state, props) => props.timeCategory.id],
  (geofences, timeCategoryId) => geofences.find((geofence) => geofence.groupId === timeCategoryId)
);

export const selectGeofencesByProps = createPropsSelector(selectGeofences);
export const findGeofenceByProps = createPropsFinder(selectGeofences);
