import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;

const initialState = produce({ bookings: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const bookingsReducer = (state = initialState, action) => {
  const loadBookingsFilter = (booking) =>
    booking.crewId === action.crewId &&
    booking.date >= action.from &&
    booking.date <= action.to &&
    (action.userId ? action.userId.find((userId) => userId === booking.userId) : true) &&
    (action.timeAccount ? action.timeAccount === booking.timeAccount : true);

  const selectBookingsFilter = (booking) =>
    booking.crewId === action.crewId &&
    booking.userId === action.userId &&
    booking.date === action.date &&
    booking.timeAccount === action.timeAccount;

  return produce(state, (draft) => {
    switch (action.type) {
      case 'CREATE_BOOKING_SUCCESS':
        applyMinimalChangesToArray(draft.bookings, action.result, selectBookingsFilter);
        break;
      case 'LOAD_BOOKINGS_SUCCESS':
        applyMinimalChangesToArray(draft.bookings, action.result, loadBookingsFilter);
        break;
      case 'LOAD_BOOKINGS_WITH_DELAY_SUCCESS':
        applyMinimalChangesToArray(draft.bookings, action.result, loadBookingsFilter);
        break;

      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
};
