import { Z_INDEX as GLOBAL_Z_INDEX } from '../styles/config';

export const Z_INDEX = {
  BACKGROUND: GLOBAL_Z_INDEX.navigation - 3,
  MEDIUM: GLOBAL_Z_INDEX.navigation - 2,
  USAGE_DISABLED: GLOBAL_Z_INDEX.navigation - 1,
  NAVIGATION: GLOBAL_Z_INDEX.navigation,
  SHIFTS_INFO: GLOBAL_Z_INDEX.navigation + 1,
  CHAT_OVERLAY: GLOBAL_Z_INDEX.navigation + 2,
  DROPDOWN: GLOBAL_Z_INDEX.navigation + 3,
  OVERLAY: GLOBAL_Z_INDEX.navigation + 4,
  INACTIVITY: GLOBAL_Z_INDEX.navigation + 5,
  BANNER: GLOBAL_Z_INDEX.navigation + 6,
};
