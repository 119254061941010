import * as PD from 'pomeranian-durations';
import { MemberType, TimeCategoryType } from '../types';

import { areObjectsDifferent } from './object';

/**
 * areTypesDifferent - Checks if the given values have different types, or
 *                                                 if they are different dates or different objects
 *
 * @param {any} value1
 * @param {any} value2
 * @param {integer} depthLimit
 *
 * @return {boolean or undefined} returns boolean if they are date (false equal; true different), or
 *                                if they have different types (true) or if they are objects (false if
 *                                equal; true different)
 *
 *                                returns undefined if they are not dates, objects or have the same type
 */
export const areTypesDifferent = (value1: unknown, value2: unknown, depthLimit = 3): boolean | undefined => {
  // If both of them are arrays, we can't ensure if the types of their elements are equal in this function
  if (isArray(value1) || isArray(value2)) return isArray(value1) && isArray(value2) ? undefined : true;

  // If values are dates, check equality according to ISO string
  if (isDate(value1) && isDate(value2)) return new Date(value1).toISOString() !== new Date(value2).toISOString();

  // Check if both values have the same type
  if (areTypeOfsDifferent(value1, value2)) return true;

  // If values are objects, make a recursive call decreasing one unit of the depthLimit
  if (isObject(value1)) return areObjectsDifferent(value1, value2 as Object, [], depthLimit - 1);
};

/**
 * areTypeOfsDifferent - Checks if both values have different types
 *
 * @param {any} value1
 * @param {any} value2
 *
 * @return {boolean} returns true if they have different types, false otherwise
 */
export const areTypeOfsDifferent = (value1: unknown, value2: unknown) => typeof value1 !== typeof value2;

/**
 * isArray - Checks if value is an array
 *
 * @param {any} obj
 *
 * @return {boolean} returns true if value is array, false otherwise
 */
export const isArray = (obj: any): obj is any[] => Array.isArray(obj);

/**
 * isBoolean - Checks if value is a boolean
 *
 * @param {any} value
 *
 * @returns {boolean} true if value is boolean, false otherwise
 */
export const isBoolean = (value: unknown): value is boolean => value === true || value === false;

/**
 * isDate - Checks if value is a date
 *
 * @param {any} value
 *
 * @return {boolean} returns true if value is date, false otherwise
 */
export const isDate = (value: unknown): value is Date =>
  (typeof value === 'string' || typeof value === 'object') && !!Date.parse(`${value}`);

/**
 * isFunction - Checks if value is a function
 *
 * @param {any} maybeFn
 *
 * @return {boolean} returns true if value is function, false otherwise
 */
export const isFunction = (maybeFn: unknown): maybeFn is Function => typeof maybeFn === 'function';

/**
 * isMemberType — Checks if obj is of type MemberType
 *
 * @param obj any object
 *
 * @returns {boolean} true if object is of type MemberType,
 *                    false otherwise
 */
export function isMemberType(obj: any): obj is MemberType {
  return obj.userId !== undefined;
}

/**
 * isNumber - Checks if value is number
 * 
 * Note: Here it is necessary to test if its an array, as a number array of length 1 returns true here!
 *
 * @param {any} value
 *
 * @return {boolean} returns true if value is number, false otherwise
 */
export const isNumber = (value: any): value is number => !isArray(value) && !isNaN(value - parseFloat(value));

/**
 * isObject - Checks if value is an object
 *
 * @param {any} obj
 *
 * @return {boolean} returns true if value is object, false otherwise
 */
export const isObject = (obj: unknown): obj is Object => obj instanceof Object;

/**
 * isString - Checks if value is a string
 *
 * @param {any} value
 *
 * @return {boolean} returns true if value is string, false otherwise
 */
export const isString = (value: unknown): value is string => typeof value === 'string';

/**
 * isTime - Checks if value is a time
 *
 * @param {any} value
 *
 * @return {boolean} returns true if value is of type time, false otherwise
 */
export const isTime = (value: unknown) => {
  const valueWithSeconds = `${value}`.length === 5 ? `${value}:00` : `${value}`;
  if (!/\d\d:\d\d:\d\d/.test(valueWithSeconds) || valueWithSeconds.length !== 8) return false;

  const time = valueWithSeconds.split(':');

  const hours = time[0];
  const minutes = time[1];
  const seconds = time[2];

  return hours >= '0' && hours <= '23' && minutes >= '0' && minutes <= '59' && seconds >= '0' && seconds <= '59';
};

/**
 * isTimeCategoryType — Checks if obj is of type TimeCategoryType
 *
 * @param obj any object
 *
 * @returns {boolean} true if object is of type TimeCategoryType,
 *                    false otherwise
 */
export function isTimeCategoryType(obj: any): obj is TimeCategoryType {
  return obj.level !== undefined;
}

export const isDuration = (value: any) => {
  return PD.isValid(value);
};

/**
 * Validator is a key-value mapping between datatypes and validators functions
 * to verify if a value is of that given datatype. The order in which keys are
 * inserted must be in the order of precedence that will be used by
 * `#getDataType`
 */
const validators = [
  { datatype: 'ARRAY', validator: isArray },
  { datatype: 'FUNCTION', validator: isFunction },
  { datatype: 'NUMBER', validator: isNumber },
  { datatype: 'DATE', validator: isDate },
  { datatype: 'TIME', validator: isTime },
  { datatype: 'DURATION', validator: isDuration },
  { datatype: 'STRING', validator: isString },
  { datatype: 'OBJECT', validator: isObject },
];

/**
 * getDataType - Returns a datatype based in the validator and datatype defined
 * in `#validators` object.
 *
 * @param {any} value
 *
 * @return {string} the datatype
 */
export const getDataType = (value: unknown) => {
  for (const { datatype, validator } of validators) {
    if (validator(value)) {
      return datatype;
    }
  }
  return undefined;
};
