import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadCountryPublicHolidays = memoize(
  {},
  ({ api, crewId, country }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_COUNTRY_PUBLIC_HOLIDAYS',
        fnParams: { crewId, country },
        fn: (params) => api.getDefaultPublicHolidayCalendar(params),
      })
);

export const loadCrewPublicHolidays = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_CREW_PUBLIC_HOLIDAYS',
        fnParams: { crewId },
        fn: (params) => api.getPublicHolidayCalendar(params),
      })
);

export const createOrUpdatePublicHolidays =
  ({ api, crewId, days }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'CREATE_OR_UPDATE_PUBLIC_HOLIDAYS',
      fnParams: { days, crewId },
      fn: (params) => api.updatePublicHolidayCalendar(params),
    });
