import { factory, random, sequence } from 'stahlwerk';

export const createCrewSettings = factory({
  workingTimeModelDays: [
    {
      breakRule: null,
      day: 1,
      roundingRule: null,
      target: 'PT0S',
    },
    {
      breakRule: null,
      day: 2,
      roundingRule: null,
      target: 'PT0S',
    },
    {
      breakRule: null,
      day: 3,
      roundingRule: null,
      target: 'PT0S',
    },
    {
      breakRule: null,
      day: 4,
      roundingRule: null,
      target: 'PT0S',
    },
    {
      breakRule: null,
      day: 5,
      roundingRule: null,
      target: 'PT0S',
    },
    {
      breakRule: null,
      day: 6,
      roundingRule: null,
      target: null,
    },
    {
      breakRule: null,
      day: 7,
      roundingRule: null,
      target: null,
    },
  ],
  isNew: true,
  crewId: sequence(),
  userId: sequence(),
  validFrom: '2015-01-01',
  changedFields: random([['breakRule'], ['breakRule', 'roundingRule']]),
  deletable: random([true, false]),
});
