import { factory, trait, sequence, execute } from 'stahlwerk';

export const createUser = factory({
  id: sequence(),
  username: execute(() => `unique-username-${+new Date()}-${Math.floor(Math.random() * 1000)}`),
  name: 'Bart Simpson',
  email: execute(() => `unique-email-${+new Date()}-${Math.floor(Math.random() * 1000)}@crewmeister.com`),
  avatarUrl: 'http://place-hoff.com/300/400',
  language: 'de',
  password: 'secret',

  withoutEmail: trait({
    email: null,
  }),

  withoutUsername: trait({
    username: null,
  }),
});
