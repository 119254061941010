import { addDays, endOfDay, startOfDay } from 'date-fns';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '../../action-creators';
import { TERMINAL_AUTHENTICATION_MODES, TIME_ACCOUNTS } from '../../constants';
import { api, apiV3, callFunctionWhenPropsChange, wrapWithNotification } from '../../lib';
import * as selectors from '../../selectors';
import { collections, react } from '../../utils';
import { onStartBreak, onStartWork, onStopWork } from '../time-tracking-stamp-watch/utils';

import Component from './organism/index';
import t from './translate';

const {
  signOutUser,
  loadCrewSettings,
  loadPermissions,
  loadStamps,
  loadTerminalCrew,
  loadTerminalCrewMembers,
  UI_STATE_KEY_CURRENT_DATE,
  v3SignOutUser,
} = actions;
const { prepareProp } = react;
const {
  selectCurrentSettingsInMultiCrew,
  selectEnabledTerminalCrewMembersInCurrentCrew,
  selectEnabledTimeCategoriesInCurrentCrewForTerminal,
  selectSettingsForMemberOrCrew,
  selectSettingsForAllMembersOfCrew,
  readUiValue,
} = selectors;
const { composeReverse } = collections;

const timeTrackingAPICalls = {
  0: { fn: onStopWork, successMessage: t('stopWorkSuccess') },
  1: { fn: onStartWork, successMessage: t('startWorkSuccess') },
  2: { fn: onStartBreak, successMessage: t('startBreakSuccess') },
};

const mapStateToProps = (state, props) => {
  const settings = selectCurrentSettingsInMultiCrew(state, { crewId: props.crewId });
  const timeCategories = selectEnabledTimeCategoriesInCurrentCrewForTerminal(state, props);
  const crewHasTimeCategories = timeCategories.length !== 0;

  return {
    members: selectEnabledTerminalCrewMembersInCurrentCrew(state, { ...props, settings }).sort((a, b) =>
      a.name.localeCompare(b.name)
    ),
    crewSettings: selectSettingsForMemberOrCrew(state, props),
    memberSettings: selectSettingsForAllMembersOfCrew(state, props),
    currentTime: readUiValue(state, UI_STATE_KEY_CURRENT_DATE),
    crewHasTimeCategories,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const loadTerminalCrewMember = ({ crewId }) =>
    dispatch(loadTerminalCrewMembers.unmemoized({ api, crewId, disabledAt: null }));
  const signOut = () =>
    Promise.all([
      dispatch(signOutUser({ api, clearCache: false })),
      dispatch(v3SignOutUser({ apiV3 })),
      dispatch(loadTerminalCrewMembers.unmemoized({ api, crewId: ownProps.crewId })),
    ]);

  const addStamp =
    ({ fn, successMessage }, isStartWork) =>
    (params, successFn) => {
      const { activeTimeAccount, crewId, userId, clockInTimeCategory1Id, clockInTimeCategory2Id } = params;

      return (
        isStartWork && activeTimeAccount !== TIME_ACCOUNTS.NOT_WORKING
          ? dispatch(
              loadStamps.unmemoized({
                api,
                crewId,
                startTime: startOfDay(addDays(new Date(), -2)).toISOString(),
                endTime: endOfDay(addDays(new Date(), 1)).toISOString(),
                userIds: [userId],
              })
            )
          : Promise.resolve([])
      ).then((result) => {
        const lastWorkingStamp =
          result
            .slice() // clone array
            .reverse() // reverse it because we're interested in the last matching stamp
            .find(({ timeAccount }) => timeAccount === TIME_ACCOUNTS.START_WORK) || {}; // find the last clock in

        const {
          timeCategory1Id = clockInTimeCategory1Id || undefined,
          timeCategory2Id = clockInTimeCategory2Id || undefined,
        } = lastWorkingStamp; // retrieve time categories so that we can persist them

        return wrapWithNotification(
          () =>
            fn(dispatch, { ...ownProps, inTerminal: true })({
              timeCategory1Id,
              timeCategory2Id,
              ...params,
              success: () => loadTerminalCrewMember(ownProps),
            }),
          {
            dispatch,
            success: () => {
              successFn();
              if (ownProps.crewSettings?.terminalAuthenticationMode !== TERMINAL_AUTHENTICATION_MODES.NONE) signOut();
              return successMessage;
            },
            error: () => {
              dispatch(loadPermissions.unmemoized({ api, crewId: ownProps.crewId }));
              if (ownProps.crewSettings?.terminalAuthenticationMode !== TERMINAL_AUTHENTICATION_MODES.NONE) signOut();
              return t('stampError');
            },
          }
        );
      });
    };

  return {
    functionToCall: ({ crewId }) =>
      Promise.all([
        loadTerminalCrewMember({ crewId }),
        dispatch(loadTerminalCrew({ api, crewId })),
        dispatch(loadCrewSettings({ api, crewId })),
      ]),
    onStartWork: addStamp(timeTrackingAPICalls[1], true),
    onStartBreak: addStamp(timeTrackingAPICalls[2]),
    onStopWork: addStamp(timeTrackingAPICalls[0]),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  crewId: ownProps.crewId,
  userId: ownProps.userId,
});

const Terminal3ColumnOverviewContainer = composeReverse(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  callFunctionWhenPropsChange({ propNamesTriggeringFunctionCall: ['crewId', 'currentTime', 'userId'] }),
  prepareProp('getSettingsForMember', ({ crewSettings, memberSettings }) => (userId) => {
    const memberSetting = memberSettings.find((setting) => setting.userId === userId);
    return memberSetting || crewSettings || {};
  })
)(Component);

Terminal3ColumnOverviewContainer.displayName = 'Terminal3ColumnOverviewContainer';

export default Terminal3ColumnOverviewContainer;
