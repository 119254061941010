/**
 * toSignedString - returns a number as string with + or - in front of it
 *
 * @param {number} number 23.313484
 * @returns {string} "+23.313484"
 */
const toSignedString = (number) => (number >= 0 ? `+${Math.abs(number)}` : `-${Math.abs(number)}`);

/**
 * toIsoLocation - converts latitude and longitude values to a string value
 *
 * @param {number} latitude the latitude of a coordinate, e.g. 31.23143
 * @param {number} longitude the longitude of a coordinate, e.g. -12.98133
 * @returns {string} "+31.23143-12.98133/"
 */
export const toIsoLocation = ({ latitude, longitude } = {}) =>
  latitude && longitude ? `${toSignedString(latitude)}${toSignedString(longitude)}/` : '';

/*eslint-disable */
// following https://developer.mozilla.org/en-US/docs/Web/API/PositionError
export const LOCATION_ERROR_CODES = {
  unknownError: -1,
  permissionDenied: 1,
  positionUnavailable: 2,
  timeout: 3,
};

const MAX_WAIT_TIME = 5 * 1000;
const MAX_AGE = 30 * 1000;
const MIN_ACCURACY = 80;

const hasWindow = () => typeof window !== 'undefined';

const triggerPostMessage = () => {
  if (hasWindow() && window.location.hostname) {
    try {
      window.postMessage('geolocationTriggered', window.location.origin);
    } catch (e) {
      console.error(e);
    }
  }
};

export const fetchAccuratePosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    const geolocation = global.navigator.geolocation;
    let lastCheckedPosition;
    let watchID;
    let timerID;

    if (!options.timeout) {
      options.timeout = MAX_WAIT_TIME;
    }
    if (!options.maxWait) {
      options.maxWait = MAX_WAIT_TIME;
    }
    if (!options.maximumAge) {
      options.maximumAge = MAX_AGE;
    }
    if (!options.desiredAccuracy) {
      options.desiredAccuracy = MIN_ACCURACY;
    }

    // Disabled because it causes issues with React Native on Android
    options.enableHighAccuracy = false;

    // Trigger postMessage so we can listen from outside
    triggerPostMessage();

    const foundPosition = () => {
      geolocation.clearWatch(watchID);
      clearTimeout(timerID);

      if (lastCheckedPosition && lastCheckedPosition.coords) {
        resolve(lastCheckedPosition.coords);
      } else {
        reject({ code: LOCATION_ERROR_CODES.positionUnavailable });
      }
    };

    const onSuccess = (position) => {
      lastCheckedPosition = position;
      if (position.coords.accuracy <= options.desiredAccuracy) {
        foundPosition();
      }
    };

    const onError = (error) => {
      geolocation.clearWatch(watchID);
      clearTimeout(timerID);

      if (error.code === LOCATION_ERROR_CODES.timeout) {
        if (lastCheckedPosition && lastCheckedPosition.coords) {
          error.accuracy = lastCheckedPosition.coords.accuracy;
        } else {
          error.accuracy = '>' + MIN_ACCURACY;
        }
      }
      reject(error);
    };

    watchID = geolocation.watchPosition(onSuccess, onError, options);

    // Fallback for Firefox 52 and below, see https://bugzilla.mozilla.org/show_bug.cgi?id=
    // also might catch other cirumstances where accuracy was never met
    timerID = setTimeout(() => onError({ code: LOCATION_ERROR_CODES.positionUnavailable }), options.timeout + 500);
  });
};
/* eslint-enable */
