export enum APPOINTMENT_TYPES {
  ABSENCE = 'absence',
  SHIFT = 'shift',
}

export const PUB_STATE = {
  OPEN_SHIFT: 'OPEN_SHIFT',
  PUBLISHED: 'PUBLISHED',
  SHIFT_OFFER: 'SHIFT_OFFER',
  UNPUBLISHED_CREATE: 'UNPUBLISHED_CREATE',
  UNPUBLISHED_DELETE: 'UNPUBLISHED_DELETE',
  UNPUBLISHED_UPDATE: 'UNPUBLISHED_UPDATE',
};

export enum SHIFT_RECURRENCES {
  DAILY = 'daily',
  NONE = 'none',
  WEEKLY = 'weekly',
}

export enum SHIFT_STATES {
  OFFER = 'OFFER',
  OPENED = 'OPENED',
  Z_ASSIGNED = 'Z_ASSIGNED',
}
