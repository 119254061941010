import {
  addDays,
  addMilliseconds,
  addSeconds,
  compareAsc,
  differenceInSeconds,
  getMilliseconds,
  getSeconds,
  isAfter,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMinute,
  isSameSecond,
  isWithinInterval,
} from 'date-fns';
import { add, asHours } from 'pomeranian-durations';
import { statusCodes } from '../api';
import { TIME_ACCOUNTS } from '../constants';
import { StampType } from '../types';
import { flatten, insertAfter, isEmpty, lastElement, removeElement, reverse } from './array';
import { compose } from './collections';
import { formatIso8601Date, setTime } from './date';
import { pick } from './object';
import { buildDateTime, formatIsoTime } from './time';
import { areTimestampsEqual, getTimestamp, isSameStamp as _isSameStamp, compareTimestamp } from './stamp';

type TripletType = {
  date: string;
  startTime: string;
  endTime: string;
  break?: string;
};

/**
 * adjustBreakDuration -  Adjusts the break duration for a stamp chain
 *                        It only affects the last clock in/break in stamp in the chain
 *                        If break stamp exists, replaces its timestamp with one that reflects the new duration
 *                        If break stamp doesn't exist, creates a new one with a time stamp directly before the
 *                        last clock out stamp which reflects the new duration
 *
 * @param {array} stampChain
 * @param {number} newDuration new break duration in seconds
 * @param {object} extraParams
 *
 * @return {array} stamp chain adjusted to the new duration
 */
export const adjustBreakDuration = (
  stampChain: StampType[],
  newDuration: number,
  extraParams: Partial<StampType> = {}
) => {
  if (newDuration === 0) return stampChain;

  const initialBreakDuration = calculateDurationForTimeAccount({ stampChain, timeAccount: TIME_ACCOUNTS.BREAK });
  const diffDuration = initialBreakDuration - newDuration;

  const { clockInStamp, breakInStamp } = findLastStamps(stampChain);
  const lastStamp = lastElement(stampChain);
  if (!lastStamp) return stampChain;

  const lastBreakExists = breakInStamp && isAfter(new Date(breakInStamp.timestamp), new Date(clockInStamp.timestamp));

  if (lastBreakExists) {
    const newBreakStamp = {
      ...breakInStamp,
      timestamp: addSeconds(new Date(breakInStamp.timestamp), diffDuration).toISOString(),
      _sync: undefined,
    };
    stampChain.splice(stampChain.length - 2, 1, newBreakStamp);
  } else {
    const newBreakStamp = {
      ...lastStamp,
      ...extraParams,
      location: stampChain[stampChain.length - 2].location,
      timeAccount: TIME_ACCOUNTS.BREAK,
      clockInTimestamp: stampChain[0].clockInTimestamp,
      timestamp: addSeconds(new Date(lastStamp.timestamp), -newDuration).toISOString(),
      _sync: undefined,
    };
    stampChain.splice(stampChain.length - 1, 0, newBreakStamp);
  }

  return stampChain;
};

/**
 * calculateDurationForTimeAccount - Iterates over a stampchain and returns the total duration for a
 *                                   given time account in seconds
 *
 * @param {array} stampChain array of objects that contain timeAccount and timestamp
 * @param {integer} timeAccount
 *
 * @return {number} total duration of a timeAccount in seconds
 */
export const calculateDurationForTimeAccount = ({
  stampChain,
  timeAccount,
}: {
  stampChain: StampType[];
  timeAccount: number;
}) => {
  return stampChain.reduce((duration, stamp, index) => {
    if (stamp.timeAccount !== timeAccount) {
      return duration;
    }

    if (index === stampChain.length - 1) {
      return duration + differenceInSeconds(new Date(), new Date(stamp.timestamp));
    }

    return duration + differenceInSeconds(new Date(stampChain[index + 1].timestamp), new Date(stamp.timestamp));
  }, 0);
};

/**
 * closestStampchainEndsBeforeTimestamp - Retrieves the last stamp chain of the stamp chains that possesses a final stamp
 *                                        which occurs before the given timestamp
 *
 * @param {date} timestamp
 * @param {array} stampchains array of arrays
 *
 * @return {array} array which contains a final stamp that occurs before the given timestamp
 */
export const closestStampchainEndsBeforeTimestamp = (timestamp: string | Date, stampchains: StampType[][]) => {
  const stampchainsEndBefore = stampchains.filter((stampchain) => {
    const clockOutStamp = lastElement(stampchain);
    if (!clockOutStamp) return false;
    return isAfter(new Date(timestamp), new Date(clockOutStamp.timestamp));
  });

  return stampchainsEndBefore[stampchainsEndBefore.length - 1];
};

/**
 * closestStampchainStartsAfterTimestamp - Retrieves the first stamp chain of the stamp chains that possesses a start stamp
 *                                         which occurs after the given timestamp
 *
 * @param {string | Date} timestamp
 * @param {array} stampchains array of arrays
 *
 * @return {array} array which contains a final stamp that occurs after the given timestamp
 */
export const closestStampchainStartsAfterTimestamp = (timestamp: string | Date, stampchains: StampType[][]) => {
  const stampchainsStartAfter = stampchains.filter((stampchain) => {
    const clockInStamp = stampchain[0];
    return isAfter(new Date(clockInStamp.timestamp), new Date(timestamp));
  });

  return stampchainsStartAfter[0];
};

/**
 * durationBetweenStamps - Calculates the duration in seconds between two stamps
 *
 * @param {StampType} stamp1
 * @param {StampType} stamp2
 *
 * @return {string} seconds in 'PT0S' format
 */
const durationBetweenStamps = (stamp1: StampType, stamp2: StampType) => {
  if (!stamp1 || !stamp2) {
    return null;
  }
  const date1 = new Date(stamp1.timestamp);
  const date2 = new Date(stamp2.timestamp);
  const diffInSeconds = Math.abs(date2.getTime() - date1.getTime()) / 1000;
  return `PT${diffInSeconds}S`;
};

/**
 * extractStampChainDurations - Calculates work, break and total durations for a stampchain
 *
 * @param {array} stampChain
 *
 * @return {object}
 */
export const extractStampChainDurations = (stampChain: StampType[]) => {
  const workDuration = calculateDuration({ stampChain, timeAccount: TIME_ACCOUNTS.WORKING });
  const breakDuration = calculateDuration({ stampChain, timeAccount: TIME_ACCOUNTS.BREAK });
  const totalDuration = add(workDuration, breakDuration);

  return { workDuration, breakDuration, totalDuration };
};

/**
 * findActiveTimeAccount - Searches for the most recent stamp in the stamp chain array that is currently active
 *
 * @param {array} timestampChain
 *
 * @return {integer} which is a time account constant
 */
export const findActiveTimeAccount = (timestampChain: StampType[]): number => {
  if (isEmpty(timestampChain)) {
    return TIME_ACCOUNTS.NOT_WORKING;
  }

  const [currentStamp, ...reversedOtherStamps] = reverse(timestampChain);
  const isAfterNow = +new Date(currentStamp.timestamp) > +new Date();

  return isAfterNow ? findActiveTimeAccount(reverse(reversedOtherStamps)) : currentStamp.timeAccount;
};

/**
 * findLastStamp - Returns the last stamp for a given time account in a stampchain
 *                 Assumes the stampchain is ordered
 *
 * @param {array} stampChain
 * @param {integer} timeAccount const from TIME_ACCOUNTS
 *
 * @return {object} object which is the last stamp that belongs to the given time account
 */
const findLastStamp = ({ stampChain, timeAccount }: { stampChain: StampType[]; timeAccount: number }) =>
  stampChain.filter((stamp) => stamp.timeAccount === timeAccount).reverse()[0];

/**
 * findLastStamps - Returns all last stamps for work, break and not working time accounts
 *
 * @param {array} stampChain
 *
 * @return {object} object which contains the last stamps that belongs to 'working', 'break' and
 *                  'not working' time accounts
 */
export const findLastStamps = (stampChain: StampType[]) => {
  const clockInStamp = findLastStamp({ stampChain, timeAccount: TIME_ACCOUNTS.WORKING });
  const breakInStamp = findLastStamp({ stampChain, timeAccount: TIME_ACCOUNTS.BREAK });
  const clockOutStamp = findLastStamp({ stampChain, timeAccount: TIME_ACCOUNTS.NOT_WORKING });

  return { clockInStamp, breakInStamp, clockOutStamp };
};

/**
 * getAllStampsFromStampChains - Flattens an array of stampchains and returns all stamps in a single array
 *
 * @param {array} stampChains
 *
 * @return {array}
 */
export const getAllStampsFromStampChains = (stampChains: StampType[][]) =>
  stampChains.reduce((stamps, chain) => stamps.concat(chain), []);

/**
 * getStampsRelevantInDateRange - Retrieves all stamps in date range
 *
 * @param {array} stamps
 * @param {date} startTime
 * @param {date} endTime
 *
 * @return {array}
 */
export const getStampsRelevantInDateRange = (stamps: StampType[], startTime: Date, endTime: Date) => {
  const stampChains = makeStampChains(stamps);
  const relevantStampChains = stampChains.filter(stampChainRelevantInDateRangeFilter(startTime, endTime));
  return getAllStampsFromStampChains(relevantStampChains);
};

/**
 * getStampsWithChainStartingInDateRange - Filters a stamp chain array that occurs in a date range
 *
 * @param {array} stamps
 * @param {date} startTime
 * @param {date} endTime
 *
 * @return {array} stamp chain filtered
 */
export const getStampsWithChainStartingInDateRange = (stamps: StampType[], startTime: Date, endTime: Date) => {
  const stampChains = makeStampChains(stamps);
  const chainsStartingInDateRange = stampChains.filter(stampChainStartsInDateRangeFilter(startTime, endTime));
  return getAllStampsFromStampChains(chainsStartingInDateRange);
};

/**
 * insertTripletIntoStampChain - Inserts a triplet (with date, startTime and endTime) as a stamp into a stamp chain and sorts it
 *
 * @param {array} stampChain stamp chain that one wants to add the triplet to
 * @param {array} originalTriplet array of stamps
 * @param {object} triplet object containing date, startTime and endTime
 * @param {date} startTime
 * @param {object} extraParams object containing params like crew id, user id and group id
 *
 * @return {array} which is the stamp chain plus the stamps originated from the triplet
 */
export const insertTripletIntoStampChain = (
  stampChain: StampType[],
  originalTriplet: StampType[],
  triplet: TripletType,
  startTime: Date,
  extraParams: Record<string, unknown> = {},
  operation: string
) => {
  // Build new timestamps from form values
  let startTimestamp = buildDateTime(triplet.date, triplet.startTime);
  const endTimestamp = buildDateTime(triplet.date, triplet.endTime);

  // If the new triplet overlaps a stamp that is nor in the beginning neither in the end & it is not an edition of an existing triplet,
  // we throw an error
  if (
    stampChain.some(
      (stamp, index) =>
        index < stampChain.length - 1 &&
        index > 0 &&
        !originalTriplet.length &&
        isBefore(new Date(startTimestamp), new Date(stamp.timestamp)) &&
        isAfter(new Date(endTimestamp), new Date(stamp.timestamp))
    )
  ) {
    const error = { id: statusCodes.OVERLAPPING_PERIODS };
    throw error;
  } else if (triplet.startTime === triplet.endTime) {
    const error = { message: 'startTimeAndEndTimeCanNotBeEqual' };
    throw error;
  }

  // If given start time, where start time is the end time of last triplet, get seconds and milliseconds to avoid overlap
  if (startTime) {
    const seconds = getSeconds(new Date(startTime));
    const milliseconds = getMilliseconds(new Date(startTime));

    startTimestamp = addMilliseconds(addSeconds(startTimestamp, seconds), milliseconds);

    // If changed the start time, create a new stamp chain instead of updating the last one
    if (!isSameMinute(new Date(startTime), startTimestamp) && operation === 'add') {
      // eslint-disable-next-line no-param-reassign
      stampChain = [];
    }
  }

  // Find existing break stamp from original triplet when editing existing triplet
  const breakStamp = originalTriplet.find((stamp) => stamp.timeAccount === TIME_ACCOUNTS.BREAK);

  let stampChainWithoutEditedStamps = stampChain;

  // Triplet index necessary to insert modified triplet into the same place as original triplet in the stamp chain
  let tripletIndex = -100;

  // Depending on where the triplet will enter (in the end or not), this time account will vary between working (not the last stamp)
  // and not working (the last stamp)
  let lastAddedStampTimeAccount = TIME_ACCOUNTS.NOT_WORKING;

  // If the new triplet ends before the removed element, we will need to append this removed element into the end again
  let removedElement;

  // If there is an original triplet, filter out the original stamps and get the index to insert the new triplet in the correct place
  if (originalTriplet.length) {
    stampChainWithoutEditedStamps = stampChain.filter((stamp, index) => {
      const isNotInInterval = !isWithinInterval(new Date(stamp.timestamp), {
        start: new Date(originalTriplet[0].timestamp),
        end: new Date(lastElement(originalTriplet)!.timestamp),
      });

      if (!isNotInInterval && tripletIndex === -100) tripletIndex = index - 1;
      return isNotInInterval;
    });
    // If original triplet not given but start time yes, remove the last element in the stamp chain, since we are going to insert a new one
    // If the start of the new triplet is after the end, it means that the end will go to the following day, therefore the
    //    last element can be removed
    // If the last element happens before the end of the triplet, it means its time can be removed
  } else if (
    startTime &&
    lastElement(stampChain) &&
    (isAfter(new Date(startTimestamp), new Date(endTimestamp)) ||
      isBefore(new Date(lastElement(stampChain)!.timestamp), new Date(endTimestamp)))
  ) {
    removedElement = lastElement(stampChain);
    tripletIndex = stampChain.length - 1;
    stampChainWithoutEditedStamps = removeElement(stampChain.length - 1, stampChain);
  } else {
    stampChain.forEach((element, index) => {
      if (tripletIndex === -100 && isAfter(new Date(element.timestamp), new Date(startTimestamp)))
        tripletIndex = index - 1;
      lastAddedStampTimeAccount = TIME_ACCOUNTS.WORKING;
    });
  }

  // Pick other params for stamps
  const baseParamsFromTriplet = pick(['crewId', 'userId'], triplet);

  const lastElementFromTriplet = lastElement(originalTriplet) || {};

  // Generate clock in timestamp for modified/new stamps
  const clockInTimestamp = getTimestamp(
    // can't use optional chaining here due to test failing - it is not configured for tests to use optional chaining
    (stampChainWithoutEditedStamps[0] || {}).clockInTimestamp || startTimestamp
  ).toISOString();

  // If the removed element ends after the new end timestamp, we append it to the stampchain
  const appendRemovedElementToTheStampchain =
    removedElement &&
    isAfter(
      new Date(removedElement.timestamp),
      new Date(
        (
          stampChain.find(
            (element) =>
              isSameHour(new Date(element.timestamp), new Date(endTimestamp)) &&
              isSameMinute(new Date(element.timestamp), new Date(endTimestamp)) &&
              isSameSecond(new Date(element.timestamp), new Date(endTimestamp))
          ) || {}
        ).timestamp || endTimestamp
      )
    ) &&
    isBefore(new Date(startTimestamp), new Date(endTimestamp));

  if (appendRemovedElementToTheStampchain) lastAddedStampTimeAccount = TIME_ACCOUNTS.WORKING;

  // Generate new triplet with form values
  const newTriplet: StampType[] = [
    {
      ...(originalTriplet[0] || {}),
      ...extraParams,
      ...baseParamsFromTriplet,
      ...pick(['groupId', 'location', 'note'], triplet),
      timeAccount: TIME_ACCOUNTS.WORKING,
      clockInTimestamp,
      timestamp: getTimestamp(startTimestamp).toISOString(),
    },
    {
      timeAccount: lastAddedStampTimeAccount,
      ...lastElementFromTriplet,
      ...extraParams,
      ...baseParamsFromTriplet,
      ...pick(['timeCategory1Id', 'timeCategory2Id', 'groupId', 'location', 'note'], lastElementFromTriplet),
      clockInTimestamp,
      timestamp: getTimestamp(endTimestamp).toISOString(),
    },
    ...(appendRemovedElementToTheStampchain
      ? [
          {
            ...removedElement,
            ...extraParams,
            timeAccount: TIME_ACCOUNTS.NOT_WORKING,
          },
        ]
      : []),
  ];

  let newTripletWithAdjustedBreak: StampType[];
  // If triplet has new break value, adjust it
  if (triplet.break) {
    newTripletWithAdjustedBreak = adjustBreakDuration(
      newTriplet,
      parseInt(triplet.break, 10) * 60,
      baseParamsFromTriplet
    );
    // If break hasn't changed, insert original break stamp if it exists
  } else {
    newTripletWithAdjustedBreak = breakStamp ? [newTriplet[0], breakStamp, newTriplet[1]] : newTriplet;
  }

  // Insert new/modified triplet into original stamp chain in the correct place
  const aux = insertAfter(tripletIndex, stampChainWithoutEditedStamps, newTripletWithAdjustedBreak);
  const stamps = flatten(aux);
  // Normalize stamps so that when we have end time before start time we adjust the date so that the stamp spawns over the next day
  const normalizedStamps = normalizeDatesOfStamps(stamps);
  return {
    stamps: normalizedStamps.sort((a, b) => a.timestamp.localeCompare(b.timestamp)),
    deleteStampChainAt: stampChain.length ? stampChain[0].clockInTimestamp : undefined,
  };
};

/**
 * removeTripletFromStampChain - Removes a triplet (with date, startTime and endTime) from a stamp chain and sorts it
 *
 * @param {array} stampChain stamp chain that one wants to remove the triplet from
 * @param {array} originalTriplet array of stamps
 *
 * @return {array} which is the stamp chain minus the stamps originated from the triplet
 */

export const removeTripletFromStampChain = (stampChain: StampType[], triplet: StampType[]) => {
  const isClosedChain = isCompleted(stampChain);

  const isTripletBeginningOfStampChain = isSameSecond(
    new Date(triplet[0].timestamp),
    new Date(stampChain[0].timestamp)
  );

  // If there is an original triplet, filter out the original stamps and get the index to insert the new triplet in the correct place
  const stampChainWithoutEditedStamps = stampChain
    .filter((stamp) => {
      const isNotInInterval = !isWithinInterval(new Date(stamp.timestamp), {
        start: new Date(isTripletBeginningOfStampChain ? triplet[0].timestamp : triplet[1].timestamp),
        end: new Date(
          isTripletBeginningOfStampChain ? triplet[triplet.length - 2].timestamp : lastElement(triplet)!.timestamp
        ),
      });

      return isNotInInterval;
    })
    .map((stamp, index, array) => {
      if (array.length - 1 === index && isClosedChain) {
        return { ...stamp, timeAccount: TIME_ACCOUNTS.NOT_WORKING };
      }
      return stamp;
    });

  // Normalize stamps so that when we have end time before start time we adjust the date so that the stamp spawns over the next day
  const normalizedStamps = normalizeDatesOfStamps(stampChainWithoutEditedStamps);
  return {
    stamps: normalizedStamps,
    deleteStampChainAt: stampChain.length ? stampChain[0].clockInTimestamp : undefined,
  };
};

/**
 * isCompleted - Checks if stamp chain finished with the time account not working
 *
 * @param {array} timestampChain
 *
 * @return {boolean} true if stamp chain is not an empty array and if it is completed,
 *                   false otherwise
 */
export const isCompleted = (timestampChain: StampType[]) =>
  !isEmpty(timestampChain) && lastElement(timestampChain)!.timeAccount === TIME_ACCOUNTS.NOT_WORKING;

/**
 * isOverlongPeriod - Returns true if a stampChain has an overlong period, i.e. has more than 30 hours of work duration
 *
 * @param {array} stampChain
 *
 * @return {boolean} true if stamp chain has more than 30 hours of work duration
 *                   false otherwise
 */
export const isOverlongPeriod = (stampChain: StampType[]) => {
  const workDuration = calculateDuration({ stampChain, timeAccount: TIME_ACCOUNTS.WORKING });
  return asHours(workDuration) > 30;
};

/**
 * isSameStamp - Stamp is the same if timestamp, userId and crewId are the same
 *
 * @param {object} stamp1
 * @param {object} stamp2
 *
 * @return {boolean} true if stamps are the same,
 *                   false otherwise
 */
export const isSameStamp = (stamp1: StampType, stamp2: StampType) => _isSameStamp(stamp1, stamp2);
/**
 * makeStampChains - Creates stampchains out of an array of stamps
 *
 * @param {array} stamps
 *
 * @return {array} stamps are grouped together in chains by their clockInTimestamp, crewId and userId
                   stamps in a chain are sorted in ascending order of timestamps
 */
export const makeStampChains = (stamps: StampType[]) =>
  stamps
    .reduce<StampType[][]>((chains, stamp) => {
      const chainForStamp = chains.find(
        (chain) =>
          chain[0].crewId === stamp.crewId &&
          chain[0].userId === stamp.userId &&
          areTimestampsEqual(chain[0].clockInTimestamp, stamp.clockInTimestamp)
      );

      if (chainForStamp) {
        chainForStamp.push(stamp);
      } else {
        chains.push([stamp]);
      }

      return chains;
    }, [])
    .map((chain) => chain.sort(sortStampsAsc));

export const normalizeDatesOfStamps = (_stamps: StampType[] = [], index: number = 1): StampType[] => {
  const stamps = [..._stamps];
  if (stamps.length === index) {
    return stamps;
  }

  const stamp = stamps[index];
  const previousStamp = stamps[index - 1];
  const updatedStamp = {
    ...stamp,
    timestamp: getTimestamp(setTime(previousStamp.timestamp, formatIsoTime(stamp.timestamp))).toISOString(),
  };

  const shouldAddOneDay =
    previousStamp && isBefore(new Date(updatedStamp.timestamp), new Date(previousStamp.timestamp));

  stamps[index] = shouldAddOneDay
    ? { ...stamp, timestamp: addDays(new Date(updatedStamp.timestamp), 1).toISOString() }
    : updatedStamp;

  return normalizeDatesOfStamps(stamps, index + 1);
};

/**
 * sortStamps - Sorts stamps by userId, clockInTimestamp and timestamp
 *
 * @param {array} stamps
 *
 * @return {array}
 */
export const sortStamps = (stamps: StampType[]) =>
  stamps.concat().sort((a, b) => {
    if (a.userId !== b.userId) return a.userId < b.userId ? -1 : 1;
    if (!areTimestampsEqual(new Date(a.clockInTimestamp), new Date(b.clockInTimestamp)))
      return compareTimestamp(a.clockInTimestamp, b.clockInTimestamp);
    return compareTimestamp(a.timestamp, b.timestamp);
  });

/**
 * sortStampsAsc - Sort stamps in ascendent order
 *
 * @param {object} stamp1
 * @param {object} stamp2
 *
 * @return {integer} that can be used on a sorting function
 */
export const sortStampsAsc = (stamp1: StampType, stamp2: StampType) => {
  const compare = compareTimestamp(stamp1.timestamp, stamp2.timestamp);
  return isNaN(compare) ? 0 : compare;
};

/**
 * sortStampsByTimestamp - Sort stamps by timestamp
 *
 * @param {array} stamps
 *
 * @return {array}
 */
export const sortStampsByTimestamp = (stamps: StampType[]) =>
  stamps.concat().sort((a, b) => compareTimestamp(a.timestamp, b.timestamp));

/**
 * stampChainOpenFilter - Filter for stampchains which are open, i.e. last stamp is not a clock out
 *
 * @param {array} stampChain
 *
 * @return {boolean} true if last stamp of a stamp chain has time account different from not working,
 *                   false otherwise
 */
export const stampChainOpenFilter = (stampChain: StampType[]) => stampChain[stampChain.length - 1].timeAccount !== 0;

/**
 * sortStampChains - Sorts a list of stampchains by the clock in timestamp
 *
 * @param {array} stampChains array of stampchains
 */
export const sortStampChains = (stampChains: StampType[][]) =>
  stampChains.sort((a, b) => compareAsc(new Date(a[0].clockInTimestamp), new Date(b[0].clockInTimestamp)));

/**
 * stampChainRelevantInDateRangeFilter - Returns true if a stampchain is "touching" a date range,
 *                                       i.e. it starts, ends or is ongoing in the date range
 *
 * @param {date} startTime
 * @param {date} endTime
 *
 * @return {boolean} true is first stamp is before endTime and last stamp (time account working or break) after startTime,
 *                   false otherwise
 */
export const stampChainRelevantInDateRangeFilter = (startTime: Date, endTime: Date) => (stampChain: StampType[]) => {
  if (!stampChain.length) {
    return false;
  }

  const firstStampOfChain = stampChain[0];
  const lastStampOfChain = stampChain[stampChain.length - 1];

  return (
    // chain starts before the endTime of the timestamp
    new Date(firstStampOfChain.timestamp) <= endTime &&
    // and it is still open
    (lastStampOfChain.timeAccount !== 0 ||
      // or it is closed, but not before the startTime
      new Date(lastStampOfChain.timestamp) >= startTime)
  );
};

/**
 * stampChainStartsInDateRangeFilter - Returns true if a stampchain starts within a date range
 *
 * @param {date} startTime
 * @param {date} endTime
 *
 * @return {boolean}
 */
export const stampChainStartsInDateRangeFilter = (startTime: Date, endTime: Date) => (stampChain: StampType[]) => {
  if (!stampChain.length) {
    return false;
  }
  const firstStampOfChain = stampChain[0];
  return (
    // chain starts before the endTime of the timestamp
    new Date(firstStampOfChain.timestamp) <= endTime &&
    // and it does not start earlier then the startTime
    new Date(firstStampOfChain.timestamp) >= startTime
  );
};

/**
 * stampChainStartsOnDate - Returns true if a stampchain starts on a date
 *
 * @param {date} date
 *
 * @return {boolean}
 */
export const stampChainStartsOnDateFilter = (date: Date) => (stampChain: StampType[]) => {
  if (!stampChain.length) {
    return false;
  }
  const firstStampOfChain = stampChain[0];
  return (
    // chain starts on 'date'
    isSameDay(new Date(firstStampOfChain.timestamp), date)
  );
};

/**
 * stampOverlapsStampchain - Checks if a stamp overlaps a stampchain
 *
 * @param {object} stamp
 * @param {array} stampchain
 *
 * @return {boolean} true if stamp is within stamp chain,
 *                   false otherwise
 */
export const stampOverlapsStampchain = (stamp: StampType, stampchain: StampType[]) => {
  const start = new Date(stampchain[0].timestamp);
  const end = new Date(stampchain[stampchain.length - 1].timestamp);

  return isWithinInterval(new Date(stamp.timestamp), { start, end });
};

/**
 * stampsForOpenStampChainsToVirtualBookings - Generates virtual booking based on each pair of stamps of each
 *                                             stamp chain
 *
 * @param {array} stamps
 *
 * @return {array}
 */
export const stampsForOpenStampChainsToVirtualBookings = (stamps: StampType[]) => {
  const openStampChains = makeStampChains(stamps).filter(stampChainOpenFilter);

  const virtualBookings = new Array();
  openStampChains.forEach((stampChain) => {
    for (let i = 0; i < stampChain.length; i++) {
      const firstStampOfPair = stampChain[i];
      const secondStampOfPair = stampChain[i + 1];
      const virtualBooking = virtualBookingFromStampPair(firstStampOfPair, secondStampOfPair);
      virtualBookings.push(virtualBooking);
    }
  });
  return virtualBookings;
};

/**
 * toDuration - Converts from seconds to duration
 *
 * @param {integer} value
 *
 * @return {string}
 */
const toDuration = (value: number) => `PT${value}S`;

/**
 * virtualBookingFromStampPair - Generates object with additional data regarding two stamps
 *
 * @param {object} stamp1
 * @param {object} stamp2
 *
 * @return {object}
 */
const virtualBookingFromStampPair = (stamp1: StampType, stamp2: StampType) => {
  return {
    additionalData: {
      clockInTimeStamp: stamp1.clockInTimestamp,
      from: stamp1.timestamp,
      to: stamp2 ? stamp2.timestamp : null,
      note: stamp1.note,
      groupId: stamp1.groupId,
      timeCategory1Id: stamp1.timeCategory1Id,
      timeCategory2Id: stamp1.timeCategory2Id,
      startLocation: stamp1.location,
      endLocation: stamp2 ? stamp2.location : null,
      startTruncated: false,
      endTruncated: false,
      unprocessed: true,
    },
    duration: durationBetweenStamps(stamp1, stamp2),
    date: formatIso8601Date(stamp1.clockInTimestamp),
    crewId: stamp1.crewId,
    userId: stamp1.userId,
    timeAccount: stamp1.timeAccount,
  };
};

/**
 * calculateDuration - Function which calls two functions: calculateDurationForTimeAccount and toDuration
 *
 * @param {any} initialValue initial value one may pass to the first function that will be called in the chain
 *
 * @return {function} which is a reduce function that will call the two functions one at a time passing to the
 *                    first one the initial value and to the second one the result of the first one
 */
const calculateDuration = compose(calculateDurationForTimeAccount, toDuration);

/**
 * stampChainToTriplets - Groups stamps in stampchain in triplets
 *
 * @param {array} stampchain array of ordered stamps
 *
 * @return {array} array of arrays of triplets
 */
export const stampChainToTriplets = (stampchain: StampType[]) =>
  stampchain.reduce<StampType[][]>((triplets, stamp) => {
    const triplet = [];
    const lastTriplet = lastElement(triplets);

    if (stamp.timeAccount === TIME_ACCOUNTS.WORKING) {
      triplet.push(stamp);
      if (lastTriplet) lastTriplet.push(stamp);
    } else if (stamp.timeAccount === TIME_ACCOUNTS.BREAK) {
      if (lastTriplet) lastTriplet.push(stamp);
    } else if (stamp.timeAccount === TIME_ACCOUNTS.NOT_WORKING) {
      if (lastTriplet) lastTriplet.push(stamp);
    }

    if (triplet.length) return [...triplets, triplet];
    return triplets;
  }, []);
