import Pusher from 'pusher-js';
import { getEnv } from '../utils';
import { api } from '../lib';

let pusherServiceInternal = null;

export const pusherService = () => {
  if (pusherServiceInternal === null) {
    pusherServiceInternal = Promise.resolve()
      .then(() => api.httpHeaders())
      .then(
        (headers) =>
          new Pusher(getEnv(process.env.PUSHER_APP_KEY, 'PUSHER_APP_KEY'), {
            authEndpoint: `${getEnv(process.env.CREWMEISTER_API_DOMAIN, 'CREWMEISTER_API_DOMAIN')}/api/v2/pusher/auth`,
            auth: {
              headers,
            },
            cluster: 'eu',
            forceTLS: true,
          })
      );
  }
  return pusherServiceInternal;
};

export const disconnectPusher = () => {
  if (pusherServiceInternal) {
    pusherServiceInternal.then((result) => result.disconnect());
    pusherServiceInternal = null;
  }
};
