import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { collections, getEnv } from '../utils';
import { UI_STATE_KEY_USER_APP_IN_TERMINAL_MODE } from '../action-creators/ui-actions';
import * as selectors from '../selectors';

import { loadCurrentAuthentication } from '../action-creators/authentication-actions';
import { api, reduxStore } from '../lib';

import AppSwitcher from './app-switcher';

const { composeReverse } = collections;
const { selectAuthentication, selectCurrentAuthenticationLoading, selectPermissions, selectCrews } = selectors;

reduxStore.dispatch(loadCurrentAuthentication.unmemoized({ api }));

global.api = api;

const mapStateToProps = (state, props) => ({
  userAppInTerminalMode: state.ui.ui[UI_STATE_KEY_USER_APP_IN_TERMINAL_MODE],
  crews: selectCrews(state),
  authentication: selectAuthentication(state, props),
  permissions: selectPermissions(state),
  isLoading: selectCurrentAuthenticationLoading(state, props),
});

const salaryExportBeta = getEnv(process.env.SALARY_EXPORT_BETA, 'SALARY_EXPORT_BETA');
const AppSwitcherContainer = composeReverse(withRouter, connect(mapStateToProps))(AppSwitcher);

export default AppSwitcherContainer;
export { salaryExportBeta };
