import React, { useEffect, useState } from 'react';
import { asSeconds } from 'pomeranian-durations';

import MinutesInput from '../../../components/core/minutes-input';
import Button from '../../../components/core/button';
import Icon from '../../../components/core/icon';

import { COLORS, IDS_FOR_GOOGLE_TAG_MANAGER, THEMES } from '../../../constants';
import * as fonts from '../../../styles/fonts';
import { time } from '../../../utils';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';
import t from './translate';

const { formatIsoTime } = time;

export const SummaryScreen = (props) => {
  const {
    id,
    period,
    onUpdateBreak,
    onUpdateBookingsAndDurations,
    canEditTimeTrackingStamps,
    setIsSummaryScreenOpen,
    onSummaryClose,
    theme,
  } = props;

  const [breakDuration, setBreakDuration] = useState(period.breakDuration);
  const hasError = !canEditTimeTrackingStamps && asSeconds(breakDuration) + 59 < asSeconds(period.breakDuration);

  useEffect(() => {
    onUpdateBookingsAndDurations();
  }, []);

  const onBlur = () => {
    if (hasError) {
      setBreakDuration(period.breakDuration);
    }
  };

  const onChange = (value) => {
    if (asSeconds(value) >= 0 && asSeconds(value) <= asSeconds(period.totalDuration)) {
      setBreakDuration(value);
    }
  };

  const onDoneClick = () =>
    Promise.resolve()
      .then(() => onUpdateBreak({ duration: breakDuration }))
      .then(Promise.all([setIsSummaryScreenOpen(false), onSummaryClose && onSummaryClose()]));

  const styles = stylesWithThemes[theme];

  return (
    <section className={css(styles.container)}>
      <h1 className={css(styles.heading)}>
        {t('betweenTime', {
          startTime: formatIsoTime(period.clockInTimestamp),
          endTime: formatIsoTime(period.clockOutTimestamp),
        })}
      </h1>

      <div className={css(styles.wrapper)}>
        <Icon name="pause" className={css(styles.icon)} />
        <div className={css(styles.inputWrapper)}>
          <MinutesInput onBlur={onBlur} onChange={onChange} value={breakDuration} placeholder={t('break')} />
        </div>
        <If condition={hasError}>
          <label className={css(styles.errorMessage)}>{t('notice')}</label>
        </If>
      </div>
      <Button
        className={css(styles.button)}
        id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.DONE}
        data-e2e-test="time-tracking-done"
        onClick={onDoneClick}
        inline
        disabled={hasError}
      >
        {t('done')}
      </Button>
    </section>
  );
};

const stylesWithThemes = {
  [THEMES.ORANGE]: StyleSheet.create({
    container: {
      width: '100%',
      textAlign: 'center',
    },
    heading: {
      fontSize: '38px',
      color: COLORS.ORANGE_MAIN,
      marginBottom: '20px',
    },
    wrapper: {
      textAlign: 'center',
      margin: '0 auto 20px',
    },
    inputWrapper: {
      maxWidth: '80px',
      margin: '0 auto',
    },
    icon: {
      cursor: 'pointer',
      fontSize: '4vh',
      textAlign: 'center',
      color: COLORS.ORANGE_MAIN,
      marginBottom: '10px',
      display: 'inline-block',
    },
    errorMessage: {
      ...fonts.small,
      color: COLORS.RED_MAIN,
      lineHeight: 1,
    },
  }),
  [THEMES.TURQUOISE]: StyleSheet.create({
    container: {
      width: '100%',
      textAlign: 'center',
    },
    heading: {
      fontSize: '38px',
      color: COLORS.TURQUOISE_MAIN,
      marginBottom: '20px',
    },
    wrapper: {
      textAlign: 'center',
      margin: '0 auto 20px',
    },
    inputWrapper: {
      maxWidth: '80px',
      margin: '0 auto',
    },
    icon: {
      cursor: 'pointer',
      fontSize: '4vh',
      textAlign: 'center',
      color: COLORS.TURQUOISE_MAIN,
      marginBottom: '10px',
      display: 'inline-block',
    },
    errorMessage: {
      ...fonts.small,
      color: COLORS.RED_MAIN,
      lineHeight: 1,
    },
  }),
  [THEMES.TURQUOISE_WHITE]: StyleSheet.create({
    button: {
      backgroundColor: `${COLORS.TURQUOISE_MAIN} !important`,
    },
    container: {
      width: '100%',
      textAlign: 'center',
    },
    heading: {
      fontSize: '38px',
      color: COLORS.TURQUOISE_MAIN,
      marginBottom: '20px',
    },
    wrapper: {
      textAlign: 'center',
      margin: '0 auto 20px',
    },
    inputWrapper: {
      maxWidth: '80px',
      margin: '0 auto',
    },
    icon: {
      cursor: 'pointer',
      fontSize: '4vh',
      textAlign: 'center',
      color: COLORS.TURQUOISE_MAIN,
      marginBottom: '10px',
      display: 'inline-block',
    },
    errorMessage: {
      ...fonts.small,
      color: COLORS.RED_MAIN,
      lineHeight: 1,
    },
  }),
};

export default SummaryScreen;
