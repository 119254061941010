/**
 * copyToClipboard - copies a text to clipboard
 *
 * @param {HTMLDocument} document - the html document that will make the copy
 * @param {string} text - the text to be copied
 *
 * @return {boolean} Whether the copy was successful
 */
export const copyToClipboard = (document: HTMLDocument, text: string): boolean => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.style.top = '0';
  textarea.style.left = '0';
  textarea.style.position = 'fixed';
  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();
  const success = document.execCommand('copy');
  document.body.removeChild(textarea);
  return success;
};

/**
 * sleep - holds the execution (non blocking) for a fiven amount of time
 *
 * @param {number} ms - number that represents the miliseconds to wait before continuing execution
 *
 * @return {Promise<void>} returns a promise that will hold the execution for the amount of time provided
 */
export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
