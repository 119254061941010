import deepEqual from 'deep-equal';
import { ISO8601Duration, SHIFT_TYPE, V3Shift, V3TemplateShiftType } from '../types';
import { date as dateUtils, duration as durationUtils, shift as shiftUtils, time as timeUtils } from '../utils';
import { getValidUntilDateOffset } from '../utils/shift';

export class Shift implements V3Shift {
  workplaceId: number | null;
  userId: number | null;
  type: SHIFT_TYPE;
  transactionID: number | undefined;
  to: string;
  shiftOfferValidUntil: string | null;
  shiftOfferPublishStrategy: string | null;
  shiftOfferEligibleUsers: number[] | undefined;
  shiftOfferEligibleTeams: number[] | undefined;
  shiftOfferAssignmentStrategy: string | null;
  shiftOffer: boolean;
  publishedShiftId: number | null;
  notes: string | null;
  legacyId: string | null;
  id: number;
  hasDraft: boolean | undefined;
  from: string;
  crewId: number;
  breakStart: string | null;
  breakDuration: ISO8601Duration | null;

  constructor(shift: V3Shift) {
    this.id = shift.id;
    this.crewId = shift.crewId;
    this.userId = shift.userId;
    this.from = shift.from;
    this.to = shift.to;
    this.type = shift.type;
    this.publishedShiftId = shift.publishedShiftId;
    this.breakDuration = shift.breakDuration;
    this.breakStart = shift.breakStart;
    this.workplaceId = shift.workplaceId;
    this.notes = shift.notes;
    this.legacyId = shift.legacyId;
    this.shiftOffer = shift.shiftOffer;
    this.shiftOfferEligibleUsers = shift.shiftOfferEligibleUsers;
    this.shiftOfferEligibleTeams = shift.shiftOfferEligibleTeams;
    this.shiftOfferValidUntil = shift.shiftOfferValidUntil;
    this.shiftOfferAssignmentStrategy = shift.shiftOfferAssignmentStrategy;
    this.shiftOfferPublishStrategy = shift.shiftOfferPublishStrategy;
    this.hasDraft = shift.hasDraft;
    this.transactionID = shift.transactionID;
  }

  isOffer = () => !!this.shiftOffer;

  isSameTemplate = (templateShift: V3TemplateShiftType) => {
    const { parseSeconds } = durationUtils;

    const thisTemplateShift = this.toShiftTemplate(templateShift.templateId);

    return (
      this.crewId === templateShift.crewId &&
      this.userId === templateShift.userId &&
      thisTemplateShift.fromTime === templateShift.fromTime &&
      thisTemplateShift.fromOffset === templateShift.fromOffset &&
      thisTemplateShift.toTime === templateShift.toTime &&
      thisTemplateShift.toOffset === templateShift.toOffset &&
      ((!thisTemplateShift.breakStartOffset && !templateShift.breakStartOffset) ||
        thisTemplateShift.breakStartOffset === templateShift.breakStartOffset) &&
      ((!thisTemplateShift.breakStartTime && !templateShift.breakStartTime) ||
        thisTemplateShift.breakStartTime === templateShift.breakStartTime) &&
      ((!thisTemplateShift.breakDuration && !templateShift.breakDuration) ||
        parseSeconds(thisTemplateShift.breakDuration) === parseSeconds(templateShift.breakDuration)) &&
      thisTemplateShift.notes === templateShift.notes &&
      thisTemplateShift.workplaceId === templateShift.workplaceId
    );
  };

  toShiftTemplate = (templateId: number): V3TemplateShiftType => {
    const { startOfWeek } = dateUtils;
    const { getDateOffset } = shiftUtils;
    const { formatIsoTime } = timeUtils;

    const mondayDate = startOfWeek(new Date(this.from));

    const fromISO = new Date(this.from).toISOString();
    const toISO = new Date(this.to).toISOString();
    let breakISO;
    if (this.breakStart) breakISO = new Date(this.breakStart).toISOString();

    let shiftOfferValidUntilISO;
    if (this.shiftOffer && this.shiftOfferValidUntil)
      shiftOfferValidUntilISO = new Date(this.shiftOfferValidUntil).toISOString();

    return {
      ...(breakISO && this.breakDuration
        ? {
            breakDuration: this.breakDuration,
            breakStartOffset: getDateOffset(breakISO, mondayDate),
            breakStartTime: formatIsoTime(breakISO),
          }
        : {
            breakDuration: null,
          }),
      ...(shiftOfferValidUntilISO
        ? {
            isShiftOffer: this.shiftOffer,
            shiftOfferAssignmentStrategy: this.shiftOfferAssignmentStrategy,
            shiftOfferEligibleTeams: this.shiftOfferEligibleTeams,
            shiftOfferEligibleUsers: this.shiftOfferEligibleUsers,
            shiftOfferPublishStrategy: this.shiftOfferPublishStrategy,
            shiftOfferValidUntil: formatIsoTime(shiftOfferValidUntilISO),
            shiftOfferValidUntilOffset: getValidUntilDateOffset(shiftOfferValidUntilISO, mondayDate),
          }
        : {}),
      crewId: this.crewId,
      fromOffset: getDateOffset(fromISO, mondayDate),
      fromTime: formatIsoTime(fromISO),
      notes: this.notes,
      templateId,
      toOffset: getDateOffset(toISO, mondayDate),
      toTime: formatIsoTime(toISO),
      userId: this.userId,
      workplaceId: this.workplaceId,
    };
  };
}
