class EventBus {
  constructor() {
    this.subscribers = {};
  }

  subscribe(subscriber) {
    const eventName = subscriber.eventName;
    if (!this.subscribers[eventName]) {
      this.subscribers[eventName] = [];
    }
    this.subscribers[eventName].push(subscriber);
  }

  unsubscribe(subscriber) {
    const eventName = subscriber.eventName;
    if (this.subscribers[eventName]) {
      this.subscribers[eventName] = this.subscribers[eventName].filter((s) => s !== subscriber);
    }
  }

  publish(eventName, data) {
    if (this.subscribers[eventName]) {
      this.subscribers[eventName].forEach((subscriber) => subscriber.handleEvent(data));
    }
    if (window.receiveEventFromReact) {
      window.receiveEventFromReact({ name: eventName, data: data });
    }
  }
}

export default new EventBus();
