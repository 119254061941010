import { factory, sequence } from 'stahlwerk';
import { addDays, parseISO } from 'date-fns';

export const createSpecialDay = factory({
  id: sequence(),
  date: parseISO(new Date()),
  name: 'New Year',
});

export const createSpecialDayCollection = () => [
  { date: parseISO(addDays(new Date(), -5)), name: 'Neujahr' },
  { date: parseISO(addDays(new Date(), 6)), name: 'Silvester ' },
  { date: parseISO(addDays(new Date(), 6)), name: 'Mein Burtseltag =)' },
];
