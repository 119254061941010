import Headers, { FetchHeaders } from './v1/headers';
import UserContext from './user-context';

import { deprecate } from '../deprecate';

// V2
import { requestPasswordReset } from './v2/request-password-reset';
import { setPasswordByToken } from './v2/set-password-by-token';
import { updateTimestamp } from './v2/save-timestamp';
import { timeAheadOfServer, timeAheadOfServerApp } from './v2/time-offset';

import { execute } from './lib';
import { ROUTES } from './routes';
import { CookieStore } from './v2/headers';

export default class Api {
  constructor(tokenStore = new CookieStore()) {
    this.fetchHeaders = new FetchHeaders();
    this.resetHeaders();
    this.tokenStore = tokenStore;
  }

  resetHeaders() {
    this.updateContexts(new Headers());
  }

  updateContexts(headers) {
    this.userContext = new UserContext(headers);
  }

  ensureUserContext() {
    const headers = this.userContext.headers;
    const updateHeadersIfRequired = ({ csrfToken, cookie, dontProcess = false }) => {
      if (!dontProcess) {
        headers.setHeaders({ csrfToken, cookie });
      }
    };

    if (headers.csrfToken === '') {
      return this.fetchHeaders
        .fetchHeadersIfRequired()
        .then(updateHeadersIfRequired)
        .then(() => this.userContext);
    }
    return Promise.resolve(this.userContext);
  }

  setXLanguage(xLanguage) {
    const headers = this.userContext.headers;
    headers.setHeaders({ xLanguage });
  }

  setCmChannel(xCmChannel) {
    const headers = this.userContext.headers;
    headers.setHeaders({ xCmChannel });
  }

  setErrorHandler(handler) {
    this.errorHandler = handler;
  }

  httpHeaders() {
    return Promise.all([this.ensureUserContext(), this.tokenStore.toHeaders()]).then(([userContext, tokenHeaders]) => ({
      ...userContext.toHeaders(),
      ...tokenHeaders,
    }));
  }

  requestPasswordReset({ userIdentifier }) {
    return this.httpHeaders().then((headers) => requestPasswordReset({ headers, userIdentifier }));
  }

  setPasswordByToken({ token, password }) {
    return this.httpHeaders().then((headers) => setPasswordByToken({ headers, password, token }));
  }

  // currently it can't do more than update note, yet
  updateTimestamp({ crewId, userId, timestamp, note }) {
    const payload = { crewId, userId, timestamp, note };
    return this.httpHeaders().then((headers) => updateTimestamp({ headers, payload }));
  }

  persistableUserContext() {
    return this.userContext.serialize();
  }

  restoreUserContext(serializedString) {
    return this.userContext.deserialize(serializedString);
  }
  /*
   * timeAheadOfServer in milliseconds
   * negative: client is slower,
   * positive: client is faster
   */
  async timeAheadOfServer() {
    const headers = await this.httpHeaders();
    return await timeAheadOfServer(headers);
  }

  async timeAheadOfServerApp() {
    const headers = await this.httpHeaders();
    return await timeAheadOfServerApp(headers);
  }

  _execute(routeConfig, params) {
    return this.ensureUserContext().then(() => {
      if (params && 'withTraceId' in params) {
        const { withTraceId, ...otherParams } = params;
        return execute(routeConfig, this.userContext, this.tokenStore, otherParams, this.errorHandler, withTraceId);
      }
      return execute(routeConfig, this.userContext, this.tokenStore, params, this.errorHandler, false);
    });
  }
}

Object.keys(ROUTES).forEach((key) => {
  Api.prototype[key] = function _generatedMethod(params) {
    return this._execute(ROUTES[key], params);
  };

  (ROUTES[key].deprecatedNames || []).forEach((alias) => {
    Api.prototype[alias] = function _generatedMethod(params) {
      deprecate(`API function name ${alias} is deprecated. Use ${key} instead.`);
      return this[key](params);
    };
  });
});
