import isEqual from 'fast-deep-equal';
import { createBrowserHistory as createHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createMemoizeMiddleware from 'redux-memoize';
import optimist from 'redux-optimist';
import thunk from 'redux-thunk';

import createErrorReportingMiddleware from './error-reporting';

import { absenceBalancesReducer } from '../reducers/absence-balances-reducer';
import { absenceDaysCountReducer } from '../reducers/absence-days-count-reducer';
import { absenceEntitlementsReducer } from '../reducers/absence-entitlements-reducer';
import { absenceTypesReducer } from '../reducers/absence-types-reducer';
import { absencesReducer } from '../reducers/absences-reducer';
import { authenticationReducer } from '../reducers/authentication-reducer';
import { bookingsReducer } from '../reducers/bookings-reducer';
import { breakRulesReducer } from '../reducers/break-rules-reducer';
import { crewMembersReducer } from '../reducers/crew-members-reducer';
import { crewSettingsReducer } from '../reducers/crew-settings-reducer';
import { crewsReducer } from '../reducers/crews-reducer';
import { dayKindCalendarReducer } from '../reducers/day-kind-calendar-reducer';
import { dayKindsReducer } from '../reducers/day-kinds-reducer';
import { durationBalancesReducer } from '../reducers/duration-balances-reducer';
import { durationsReducer } from '../reducers/durations-reducer';
import { filesReducer } from '../reducers/filesReducer';
import { geofenceReducer } from '../reducers/geofence-reducer';
import { groupsReducer } from '../reducers/groups-reducer';
import { messagesReducer } from '../reducers/messages-reducer';
import { newsReducer } from '../reducers/news-reducer';
import { notificationsReducer } from '../reducers/notifications-reducer';
import { paramsReducer } from '../reducers/params-reducer';
import { permissionsReducer } from '../reducers/permissions-reducer';
import { publicHolidaysReducer } from '../reducers/public-holidays-reducer';
import { roomsReducer } from '../reducers/rooms-reducer';
import { roundingRulesReducer } from '../reducers/rounding-rules-reducer';
import { salaryExportsReducer } from '../reducers/salary-exports-reducer';
import { settingsDefinitionsReducer } from '../reducers/settingsDefinitionsReducer';
import { settingsValuesReducer } from '../reducers/settingsValuesReducer';
import { shiftsReducer } from '../reducers/shifts-reducer';
import { shiftsToPublishReducer } from '../reducers/shifts-to-publish-reducer';
import { stampsReducer } from '../reducers/stamps-reducer';
import { teamMembersReducer } from '../reducers/team-members-reducer';
import { teamsReducer } from '../reducers/teams-reducer';
import { templatesReducer } from '../reducers/templates-reducer';
import { terminalCrewMembersReducer } from '../reducers/terminal/crew-members-reducer';
import { terminalCrewsReducer } from '../reducers/terminal/crews-reducer';
import { timeCategoriesReducer } from '../reducers/time-categories-reducer';
import { userReferralsReducer } from '../reducers/user-referral-reducer';
import { userSettingsReducer } from '../reducers/user-settings-reducer';
import { usersReducer } from '../reducers/users-reducer';
import { workingTimeModelsReducer } from '../reducers/working-time-models-reducer';
import { workplacesReducer } from '../reducers/workplaces-reducer';

import { v3IntegrationsReducer } from '../reducers/v3/integrationsReducer';
import { v3MemberRolesReducer } from '../reducers/v3/memberRolesReducer';
import { v3ProductsReducer } from '../reducers/v3/productsReducer';
import { ShiftOfferRepliesReducer as ShiftOfferRepliesReducerV3 } from '../reducers/v3/shiftOfferRepliesReducer';
import { shiftsReducer as shiftsReducerV3 } from '../reducers/v3/shiftsReducer';
import { shiftsToPublishReducer as shiftsToPublishReducerV3 } from '../reducers/v3/shiftsToPublishReducer';
import { templateShiftsReducer as templateShiftsReducerV3 } from '../reducers/v3/templateShiftsReducer';
import { templatesReducer as templatesReducerV3 } from '../reducers/v3/templatesReducer';
import { v3UsageRightsReducer } from '../reducers/v3/usageRightsReducer';
import { workplacesReducer as workplacesReducerV3 } from '../reducers/v3/workplacesReducer';

import { uiReducer } from '../reducers/ui-reducer';
import { persistReducer } from './persist-reducer';

import { v3TeamsReducer } from '../reducers/v3/teamsReducer';
import { getEnv } from '../utils';
import { clearableMemoizeCache } from './';
import eventBusMiddleware from './eventBusMiddleware';

const tenDaysTimeToLiveInMs = 1000 * 60 * 60 * 24 * 10;

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const combinedReducers = optimist(
  combineReducers({
    boV3: combineReducers({
      shiftOfferReplies: ShiftOfferRepliesReducerV3,
      shifts: shiftsReducerV3,
      templates: templatesReducerV3,
      templateShifts: templateShiftsReducerV3,
      workplaces: workplacesReducerV3,
      products: v3ProductsReducer,
      teams: v3TeamsReducer,
      memberRoles: v3MemberRolesReducer,
      usageRights: v3UsageRightsReducer,
      integrations: v3IntegrationsReducer,
    }),
    bo: combineReducers({
      absenceBalances: absenceBalancesReducer,
      absenceDaysCount: absenceDaysCountReducer,
      absenceEntitlements: absenceEntitlementsReducer,
      absences: absencesReducer,
      absenceTypes: absenceTypesReducer,
      bookings: bookingsReducer,
      breakRules: breakRulesReducer,
      crewMembers: crewMembersReducer,
      crews: crewsReducer,
      crewSettings: crewSettingsReducer,
      dayKindCalendar: dayKindCalendarReducer,
      dayKinds: dayKindsReducer,
      durationBalances: durationBalancesReducer,
      durations: durationsReducer,
      files: filesReducer,
      geofences: geofenceReducer,
      groups: groupsReducer,
      messages: messagesReducer,
      news: newsReducer,
      permissions: permissionsReducer,
      publicHolidays: publicHolidaysReducer,
      rooms: roomsReducer,
      roundingRules: roundingRulesReducer,
      salaryExports: salaryExportsReducer,
      settingsDefinitions: settingsDefinitionsReducer,
      settingsValues: settingsValuesReducer,
      shifts: shiftsReducer,
      stamps: stampsReducer,
      templates: templatesReducer,
      teamMembers: teamMembersReducer,
      teams: teamsReducer,
      timeCategories: timeCategoriesReducer,
      users: usersReducer,
      userReferrals: userReferralsReducer,
      userSettings: userSettingsReducer,
      workingTimeModels: workingTimeModelsReducer,
      workplaces: workplacesReducer,
    }),
    terminal: combineReducers({
      crews: terminalCrewsReducer,
      crewMembers: terminalCrewMembersReducer,
    }),
    uiV3: combineReducers({
      shiftsToPublish: shiftsToPublishReducerV3,
    }),
    ui: combineReducers({
      notifications: notificationsReducer,
      params: persistReducer('params', paramsReducer),
      shiftsToPublish: shiftsToPublishReducer,
      ui: uiReducer,
    }),
    authentication: authenticationReducer,
  })
);

export const history = createHistory();
export const reduxStore = createStore(
  combinedReducers,
  composeEnhancer(
    applyMiddleware(
      createErrorReportingMiddleware({ dsn: getEnv(process.env.SENTRY_DSN, 'SENTRY_DSN') }),
      createMemoizeMiddleware({
        ttl: tenDaysTimeToLiveInMs,
        cache: clearableMemoizeCache,
        isEqual: isEqual,
      }),
      thunk,
      eventBusMiddleware
    )
  )
);

global.dispatch = reduxStore.dispatch;
global.store = reduxStore;
