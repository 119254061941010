import { factory, sequence, random, trait } from 'stahlwerk';

const guid = () => {
  const fn = () =>
    /* eslint-disable no-bitwise, one-var, eqeqeq */
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  fn.__isCallable = true;
  return fn;
};

export const createShift = factory({
  guid: guid(),
  crewId: sequence(),
  userId: sequence(),
  workplaceId: sequence(),
  transactionID: sequence(),
  pubState: random(['PUBLISHED', 'UNPUBLISHED_CREATE', 'UNPUBLISHED_DELETE', 'UNPUBLISHED_UPDATE']),
  date: '2000-01-01',
  from: '2000-01-01T08:00:00',
  to: '2000-01-01T18:00:00',
  breakDuration: 'PT3600S',
  isNextShift: false,
  open: trait({
    userId: null,
    pubState: 'PUBLISHED',
  }),
  published: trait({
    pubState: 'PUBLISHED',
  }),
  unpublishedCreate: trait({
    pubState: 'UNPUBLISHED_CREATE',
  }),
  unpublishedUpdate: trait({
    pubState: 'UNPUBLISHED_UPDATE',
  }),
  unpublishedDelete: trait({
    pubState: 'UNPUBLISHED_DELETE',
  }),
});
