import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from '../_external-deps/stylesheet';
import { COLORS } from '../constants';

import ListElement from './core/list-element';

export const List = ({
  className,
  inOrange,
  inOrangeLight,
  items,
  onSelect,
  style,
  withAvatar,
  withCheckbox,
  withoutSpaceBetween,
}) => (
  <div className={`${css(styles.container)} ${className}`} style={style}>
    {items.map((item, index) => {
      const { avatarUrl, disabledItem, forceClose, id, isSelected, label, secondLevel, value } = item;
      return (
        <ListElement
          avatarUrl={avatarUrl}
          className={css(styles.element, secondLevel && styles.extraPadding)}
          disabledItem={disabledItem}
          id={id}
          inOrange={inOrange}
          inOrangeLight={inOrangeLight}
          index={index}
          isSelected={isSelected}
          key={index}
          onSelect={(_value) => onSelect({ ...item, label, value, isSelected: _value, forceClose })}
          withAvatar={withAvatar}
          withCheckbox={withCheckbox}
          withoutSpaceBetween={withoutSpaceBetween}
        >
          {label}
        </ListElement>
      );
    })}
  </div>
);

List.propTypes = {
  items: PropTypes.array.isRequired,
  withCheckbox: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: COLORS.EGGSHELL_MAIN,
  },
  element: {
    padding: '12px 16px',
  },
  extraPadding: {
    paddingLeft: 40,
  },
});

export default List;
