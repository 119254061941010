/*
 * This is deprecated.
 * Use the enum in "workspaces/shared/src/types/stamp.ts" instead.
 */
export const TIME_ACCOUNTS = {
  STOP_WORK: 0,
  START_WORK: 1,
  START_BREAK: 2,

  ABSENCE: 4,
  PUBLIC_HOLIDAY: 7,
  COMPENSATION_ABSENCE: 8,
  COMPENSATORY_TIME_OFF: 21,
  SICK_LEAVE: 30,
  EDUCATION: 90,
  OTHER_ABSENCE: 91,

  BALANCE_CORRECTION_MANUAL: 9,
  BALANCE_CORRECTION_AUTOMATIC: 109,
  PAYOUT: 10,

  NOT_WORKING: 0,
  WORKING: 1,
  BREAK: 2,
};
