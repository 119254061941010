import produce from 'immer';

export const initialState = produce({ authentication: null, isLoadingCurrent: false }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const authenticationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_CONTEXT_SUCCESS':
        draft.authentication = {
          crewAuthentication: action.result.activeCrewId ? { crewId: action.result.activeCrewId } : null,
          userAuthentication: action.result.activeUserId ? { userId: action.result.activeUserId } : null,
        };
        if (action.result.activeAdminId) {
          draft.authentication.adminAuthentication = { adminId: action.result.activeAdminId };
        }
        break;
      case 'LOAD_CURRENT_AUTHENTICATIONS_REQUEST':
        draft.isLoadingCurrent = true;
        break;
      case 'LOAD_CURRENT_AUTHENTICATIONS_SUCCESS':
        draft.authentication = action.result;
        draft.isLoadingCurrent = false;
        break;
      case 'LOAD_CURRENT_AUTHENTICATIONS_FAILURE':
        return initialState;
      case 'SIGNOUT_CREW_SUCCESS':
        if (draft.authentication) {
          draft.authentication.crewAuthentication = null;
        }
        break;
      default:
      // nothing to do => immer returns the same object
    }
  });
