import { StyleSheet as _StyleSheet, flushToStyleTag } from 'aphrodite/no-important';

const globalSelectorHandler = (selector, _, generateSubtreeStyles) => {
  if (selector[0] !== '*') {
    return null;
  }

  return generateSubtreeStyles(selector.slice(1));
};

const nodeCssFn = (stylesheet) => {
  return Object.keys(stylesheet).reduce((previous, property) => {
    return { ...previous, [property]: stylesheet[property]._name };
  }, {});
};

const globalExtension = { selectorHandler: globalSelectorHandler };
const extendedAphrodite = _StyleSheet.extend([globalExtension]);

const StyleSheet = extendedAphrodite.StyleSheet;
const cssFn = global.document ? extendedAphrodite.css : nodeCssFn;
const css = (...params) => `${cssFn(params)}`;

export { css, flushToStyleTag, StyleSheet };
