export const ROUTES = {
  specialDaysList: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/special-days/list`,
    requiredParams: 'crewId'.split(' '),
    optionalParams: 'crewId year'.split(' '),
  },
  setAbsenceEntitlement: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/update-entitlement`,
    requiredParams: 'crewId year typeId number userId'.split(' '),
  },
  setPasswordToCrewPassword: {
    method: 'POST',
    buildUrl: ({ crewId, memberId }) => `/api/v2/crew/${crewId}/member/${memberId}`,
    requiredParams: 'crewId memberId password'.split(' '),
  },
  resetPasswordToCrewPassword: {
    method: 'POST',
    buildUrl: () => '/api/v2/set-password-for-member',
    requiredParams: 'crewId userId password'.split(' '),
  },
  roundingRules: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/rounding-rules`,
    requiredParams: 'crewId'.split(' '),
  },
  breakRules: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/break-rules`,
    requiredParams: 'crewId'.split(' '),
  },
  workingTimeModels: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/working-time-models`,
    requiredParams: 'crewId'.split(' '),
    optionalParams: 'userId'.split(' '),
  },
  createWorkingTimeModel: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/working-time-models`,
    requiredParams: 'crewId workingTimeModelDays validFrom'.split(' '),
    optionalParams: 'userId'.split(' '),
  },
  updateWorkingTimeModel: {
    method: 'PUT',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/working-time-models`,
    requiredParams: 'crewId workingTimeModelDays validFrom'.split(' '),
    optionalParams: 'userId'.split(' '),
  },
  deleteWorkingTimeModel: {
    method: 'DELETE',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/working-time-models`,
    requiredParams: 'crewId validFrom'.split(' '),
    optionalParams: 'userId'.split(' '),
  },
  salaryExport: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/salary-export`,
    requiredParams: 'crewId from to'.split(' '),
  },
  enableGroup: {
    method: 'POST',
    buildUrl: ({ crewId, groupId }) => `/api/v2/crew/${crewId}/group/${groupId}/enable`,
    requiredParams: 'crewId groupId'.split(' '),
  },
  disableGroup: {
    method: 'POST',
    buildUrl: ({ crewId, groupId }) => `/api/v2/crew/${crewId}/group/${groupId}/disable`,
    requiredParams: 'crewId groupId'.split(' '),
  },
  addGroup: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/group/`,
    requiredParams: 'crewId name'.split(' '),
  },
  updateGroup: {
    method: 'POST',
    buildUrl: ({ crewId, groupId }) => `/api/v2/crew/${crewId}/group/${groupId}/`,
    requiredParams: 'crewId groupId name'.split(' '),
  },
  addTimeCategory: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-category/`,
    requiredParams: 'crewId name'.split(' '),
  },
  updateTimeCategory: {
    method: 'PUT',
    buildUrl: ({ crewId, id }) => `/api/v2/crew/${crewId}/time-category/${id}/`,
    requiredParams: 'crewId id level name'.split(' '),
    optionalParams: 'disabled'.split(' '),
  },
  createTimeCategory: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-category/`,
    requiredParams: 'crewId name level'.split(' '),
  },
  updateCrewSettings: {
    method: 'PUT',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/settings/`,
    requiredParams: 'crewId'.split(' '),
    optionalParams: [
      'absenceManagementSeeAllAbsences',
      'shiftPlannerSeeAllShifts',
      'chatEnabled',
      'terminalViewMode',
      'terminalAuthenticationMode',
      'timeTrackingAllowedMobile',
      'timeTrackingAllowedTerminal',
      'timeTrackingAllowedOther',
      'shiftPlannerGracePeriodBegin',
      'shiftPlannerGracePeriodEnd',
      'createStampChainTimeLimit',
      'editStampChainTimeLimit',
      'absenceTypes',
    ],
  },
  updateMemberSettings: {
    method: 'PUT',
    buildUrl: ({ crewId, userId }) => `/api/v2/crew/${crewId}/settings/members/${userId}`,
    requiredParams: 'crewId userId'.split(' '),
    optionalParams: [
      'absenceManagementSeeAllAbsences',
      'shiftPlannerSeeAllShifts',
      'timeTrackingAllowedMobile',
      'timeTrackingAllowedTerminal',
      'timeTrackingAllowedOther',
      'createStampChainTimeLimit',
      'editStampChainTimeLimit',
      'absenceTypes',
    ],
  },
  updateCrew: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/`,
    requiredParams: 'crewId name ownerId'.split(' '),
  },
  timeTrackingStats: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/durations`,
    requiredParams: 'crewId granularity startDate endDate'.split(' '),
    optionalParams: 'userIds groupIds groupBy timeAccount'.split(' '),
  },
  durations: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/durations`,
    requiredParams: 'crewId from to'.split(' '),
    optionalParams: 'type userId timeCategory1Id timeCategory2Id groupBy enableCache'.split(' '),
  },
  durationBalances: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/duration-balances`,
    requiredParams: 'crewId date'.split(' '),
    optionalParams: 'userId type groupBy'.split(' '),
  },
  timeAccountBalances: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-account-balances`,
    requiredParams: 'crewId from to groupBy type'.split(' '),
    optionalParams: 'userId'.split(' '),
  },
  dayKinds: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/day-kinds`,
    requiredParams: 'crewId'.split(' '),
  },
  dayKindCalendar: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/day-kind-calendar`,
    requiredParams: 'crewId from to'.split(' '),
    optionalParams: 'userId dayKindId'.split(' '),
  },
  getBookings: {
    method: 'GET',
    deprecated: true,
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/bookings`,
    requiredParams: 'crewId from to'.split(' '),
    optionalParams: 'userIds groupIds timeAccount'.split(' '),
  },
  bookings: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/bookings`,
    requiredParams: 'crewId from to'.split(' '),
    optionalParams: 'userId timeAccount'.split(' '),
  },
  createBooking: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/bookings`,
    requiredParams: 'crewId userId date timeAccount duration'.split(' '),
    optionalParams: 'additionalData'.split(' '),
  },
  updateBooking: {
    method: 'PUT',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/bookings`,
    requiredParams: 'crewId userId date timeAccount duration'.split(' '),
    optionalParams: 'additionalData'.split(' '),
  },
  timestamps: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/stamps`,
    requiredParams: 'crewId startTime endTime'.split(' '),
    optionalParams: 'userIds starts_in_range'.split(' '),
  },
  deleteStampChains: {
    method: 'DELETE',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/stamp-chains`,
    requiredParams: 'userId crewId timestamp'.split(' '),
  },
  createStampChains: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/stamp-chains`,
    requiredParams: 'userId crewId stamps deleteStampChainAt'.split(' '),
  },
  forceCreateStampChains: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/stamp-chains-force-create`,
    requiredParams: 'userId stamps'.split(' '),
  },
  replaceStampChain: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/stamp-chain-replace`,
    requiredParams: 'userId crewId stamps userDates'.split(' '),
  },
  batchDeleteStampChain: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/stamp-chain-delete`,
    requiredParams: 'userId crewId stamps'.split(' '),
  },
  startWorkTimestamp: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/start-work`,
    requiredParams: 'crewId timestamp'.split(' '),
    optionalParams: 'userId timeCategory1Id timeCategory2Id location note'.split(' '),
  },
  stopWorkTimestamp: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/stop-work`,
    requiredParams: 'crewId timestamp'.split(' '),
    optionalParams: 'userId timeCategory1Id timeCategory2Id location'.split(' '),
  },
  startBreakTimestamp: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/start-break`,
    requiredParams: 'crewId timestamp'.split(' '),
    optionalParams: 'userId timeCategory1Id timeCategory2Id location'.split(' '),
  },
  fetchTimestampChain: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/stamps`,
    requiredParams: 'crewId timestamp'.split(' '),
    optionalParams: 'userId'.split(' '),
  },
  getSalaryExportConfigurations: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/salary-export-configurations`,
    requiredParams: 'crewId'.split(' '),
  },
  pricesConfig: {
    method: 'GET',
    buildUrl: () => '/api/v2/prices/config',
  },
  pricesCalculate: {
    method: 'GET',
    buildUrl: () => '/api/v2/prices/calculate',
    requiredParams: 'crewId productIds crewSize paymentPeriod voucherCode'.split(' '),
  },
  createSubscription: {
    method: 'POST',
    buildUrl: () => '/api/v2/subscription/create',
    requiredParams: [
      'crewId',
      'userId',
      'paymentPeriod',
      'productIds',
      'crewSize',
      'firstName',
      'lastName',
      'email',
      'company',
      'street',
      'extraAddressLine',
      'postalCode',
      'city',
      'country',
      'paymentMethod',
      'sepaMandateText',
    ],
    optionalParams: 'salutation vatId iban bic voucherCode startDate sendConfirmationEmail'.split(' '),
  },
  inviteUserWithEmail: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/invite/with-email`,
    requiredParams: 'crewId email name language'.split(' '),
  },
  inviteUserWithLoginName: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/invite/with-login-name`,
    requiredParams: 'crewId username name password passwordConfirmation language'.split(' '),
  },
  createGroup: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/group`,
    requiredParams: 'crewId name'.split(' '),
  },
  signInCrew: {
    method: 'GET', // TODO: this should be post
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/sign-in`,
    requiredParams: 'crewId'.split(' '),
    deprecated: true,
  },
  signOutCrew: {
    method: 'GET', // TODO: this should be post
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/sign-out`,
    requiredParams: 'crewId'.split(' '),
    deprecated: true,
  },
  isCrewSignedIn: {
    method: 'GET',
    buildUrl: () => '/api/v2/context',
    postProcess: (response) => !!response.activeCrewId,
  },
  isUserSignedIn: {
    deprecatedNames: ['isUserLoggedIn'],
    method: 'GET',
    buildUrl: () => '/api/v2/context',
    postProcess: (response) => !!response.activeUserId,
  },
  getContext: {
    method: 'GET',
    buildUrl: () => '/api/v2/context',
  },
  crew: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}`,
    requiredParams: 'crewId'.split(' '),
  },
  currentCrew: {
    method: 'GET',
    buildUrl: () => '/api/v2/crew/current',
  },
  crewAbsenceEntitlements: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/entitlements`,
    requiredParams: 'crewId'.split(' '),
    optionalParams: 'userId year'.split(' '),
  },
  crewAbsenceTypes: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/types`,
    requiredParams: 'crewId'.split(' '),
  },
  updateBreakForStamps: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-tracking/update-break-for-stamps`,
    requiredParams: 'crewId userId timestamp duration'.split(' '),
  },
  updateMember: {
    deprecatedNames: ['setCrewMemberRole'],
    method: 'POST',
    buildUrl: ({ crewId, memberId, userId }) => `/api/v2/crew/${crewId}/member/${userId || memberId}`,
    requiredParams: 'crewId'.split(' '),
    optionalParams: 'role firstWorkDayAt avatar name externalId employmentType userId memberId'.split(' '),
  },
  disableCrewMember: {
    method: 'POST',
    buildUrl: ({ crewId, userId, memberId }) => `/api/v2/crew/${crewId}/member/${userId || memberId}/disable`,
    requiredParams: 'crewId'.split(' '),
    optionalParams: 'userId memberId'.split(' '),
  },
  enableCrewMember: {
    method: 'POST',
    buildUrl: ({ crewId, userId, memberId }) => `/api/v2/crew/${crewId}/member/${userId || memberId}/enable`,
    requiredParams: 'crewId'.split(' '),
    optionalParams: 'userId memberId'.split(' '),
  },
  signUpForTrial: {
    method: 'POST',
    buildUrl: ({}) => '/api/v2/sign-up-crew-owner',
    requiredParams: 'email password name termsAccepted'.split(' '),
    optionalParams: 'language acquisitionTracking'.split(' '),
  },
  signUp: {
    method: 'POST',
    buildUrl: ({}) => '/api/v2/sign-up',
    requiredParams: 'email password name termsAccepted'.split(' '),
    optionalParams: 'language acquisitionTracking'.split(' '),
  },
  createUserAuthentication: {
    method: 'POST',
    buildUrl: () => '/api/v2/user/authentication',
    requiredParams: 'userIdentifier password'.split(' '),
    postProcess: (result, tokenStore) => {
      return Promise.resolve()
        .then(() => tokenStore.setToken('user', result))
        .then(() => result);
    },
  },
  createUserPinAuthentication: {
    method: 'POST',
    buildUrl: () => '/api/v2/user/pin-authentication',
    requiredParams: 'pin'.split(' '),
    optionalParams: 'userId'.split(' '),
    postProcess: (result, tokenStore) => {
      return Promise.resolve()
        .then(() => tokenStore.setToken('user', result))
        .then(() => result);
    },
  },
  deleteUserAuthentication: {
    method: 'DELETE',
    buildUrl: () => '/api/v2/user/authentication',
    optionalParams: 'all'.split(' '),
    postProcess: (result, tokenStore) => {
      return Promise.resolve()
        .then(() => tokenStore.removeToken('user'))
        .then(() => result);
    },
  },
  createCrewAuthentication: {
    method: 'POST',
    buildUrl: () => '/api/v2/crew/authentication',
    requiredParams: 'crewId'.split(' '),
    postProcess: (result, tokenStore) => {
      return Promise.resolve()
        .then(() => tokenStore.setToken('crew', result))
        .then(() => result);
    },
  },
  deleteCrewAuthentication: {
    method: 'DELETE',
    buildUrl: () => '/api/v2/crew/authentication',
    optionalParams: 'all'.split(' '),
    postProcess: (result, tokenStore) => {
      return Promise.resolve()
        .then(() => tokenStore.removeToken('crew'))
        .then(() => result);
    },
  },
  getFreshdeskUrl: {
    method: 'GET',
    buildUrl: () => '/api/v2/freshdesk/sso',
  },
  getUsers: {
    method: 'GET',
    buildUrl: () => '/api/v2/user',
    optionalParams: 'userId'.split(' '),
  },
  updateCurrentUser: {
    method: 'PUT',
    buildUrl: () => '/api/v2/user',
    optionalParams: 'password newPassword passwordConfirmation language email name username'.split(' '),
  },
  updateUser: {
    method: 'PUT',
    buildUrl: ({ userId }) => `/api/v2/user/${userId}`,
    requiredParams: 'userId'.split(' '),
    optionalParams: 'newPassword passwordConfirmation language email name username crewId'.split(' '),
  },
  getUserSettings: {
    method: 'GET',
    buildUrl: ({ userId }) => `/api/v2/user/${userId}/settings`,
    requiredParams: 'userId'.split(' '),
  },
  updateUserSettings: {
    method: 'PUT',
    buildUrl: ({ userId }) => `/api/v2/user/${userId}/settings`,
    requiredParams: 'userId'.split(' '),
    optionalParams: 'news reminders'.split(' '),
  },
  createUserPushNotificationToken: {
    method: 'POST',
    buildUrl: ({ userId }) => `/api/v2/user/${userId}/push-token`,
    requiredParams: 'userId token'.split(' '),
  },
  deleteUserPushNotificationToken: {
    method: 'DELETE',
    buildUrl: ({ userId }) => `/api/v2/user/${userId}/push-token`,
    requiredParams: 'userId tokens'.split(' '),
  },
  getPermissions: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/permission`,
    requiredParams: 'crewId'.split(' '),
  },
  // This is only going to be a placeholder we should use myCrews
  getCrews: {
    method: 'GET',
    buildUrl: () => '/api/v2/crew/new',
  },
  getCrewNew: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}`,
    requiredParams: 'crewId'.split(' '),
  },
  getTimeCategories: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/time-category`,
    requiredParams: 'crewId'.split(' '),
  },
  getGroups: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/group`,
    requiredParams: 'crewId'.split(' '),
  },
  getCrewSettings: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/settings`,
    requiredParams: 'crewId'.split(' '),
  },
  getMemberSettings: {
    method: 'GET',
    buildUrl: ({ crewId, userId }) => `/api/v2/crew/${crewId}/settings/members/${userId}`,
    requiredParams: 'crewId userId'.split(' '),
  },
  deleteMemberSettings: {
    method: 'DELETE',
    buildUrl: ({ crewId, userId }) => `/api/v2/crew/${crewId}/settings/members/${userId}`,
    requiredParams: 'crewId userId'.split(' '),
  },
  getCrewMembers: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/member`,
    requiredParams: 'crewId'.split(' '),
    optionalParams: 'userId disabledAt'.split(' '),
  },
  getGeofences: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/geofence`,
    requiredParams: 'crewId'.split(' '),
  },
  updateGeofence: {
    method: 'PUT',
    buildUrl: ({ crewId, id }) => `/api/v2/crew/${crewId}/geofence/${id}`,
    requiredParams: 'crewId id'.split(' '),
    optionalParams: 'latitude longitude radius'.split(' '),
  },
  createGeofence: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/geofence`,
    requiredParams: 'crewId groupId latitude longitude radius'.split(' '),
  },
  deleteGeofence: {
    method: 'DELETE',
    buildUrl: ({ crewId, id }) => `/api/v2/crew/${crewId}/geofence/${id}`,
    requiredParams: 'crewId id'.split(' '),
  },
  getShifts: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift`,
    requiredParams: 'crewId startDate endDate'.split(' '),
    optionalParams: 'userId workplaceId'.split(' '),
  },
  createShift: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift`,
    requiredParams: 'crewId from to workplaceId'.split(' '),
    optionalParams: 'userId breakDuration breakStart manningLevelGuid amount note'.split(' '),
  },
  updateShift: {
    method: 'PUT',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift`,
    requiredParams: 'crewId guid'.split(' '),
    optionalParams: 'userId from to breakDuration breakStart workplaceId note'.split(' '),
  },
  deleteShift: {
    method: 'DELETE',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift`,
    requiredParams: 'crewId guid'.split(' '),
  },
  transformShifts: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift/transform`,
    requiredParams: 'crewId values'.split(' '),
    preProcessParams: ({ crewId, values }) => ({
      crewId,
      values: JSON.stringify(values),
    }),
  },
  getShiftsDraft: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift-draft`,
    requiredParams: 'crewId startDate endDate'.split(' '),
    optionalParams: 'userId workplaceId'.split(' '),
  },
  createShiftDraft: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift-draft`,
    requiredParams: 'crewId from to workplaceId'.split(' '),
    optionalParams: 'userId breakDuration breakStart amount note'.split(' '),
  },
  updateShiftDraft: {
    method: 'PUT',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift-draft`,
    requiredParams: 'crewId guid'.split(' '),
    optionalParams: 'userId from to breakDuration breakStart workplaceId note'.split(' '),
  },
  deleteShiftDraft: {
    method: 'DELETE',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift-draft`,
    requiredParams: 'crewId guid'.split(' '),
  },
  transformShiftsDraft: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift-draft/transform`,
    requiredParams: 'crewId values'.split(' '),
    preProcessParams: ({ crewId, values }) => ({
      crewId,
      values: JSON.stringify(values),
    }),
  },
  publishShifts: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift-draft/publish`,
    requiredParams: 'crewId guids'.split(' '),
  },
  getWorkplaces: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/workplace`,
    requiredParams: 'crewId'.split(' '),
  },
  updateWorkplace: {
    method: 'PUT',
    buildUrl: ({ crewId, workplaceId }) => `/api/v2/crew/${crewId}/workplace/${workplaceId}`,
    requiredParams: 'crewId workplaceId'.split(' '),
    optionalParams: 'name disabledAt groupId'.split(' '),
  },
  createWorkplace: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/workplace`,
    requiredParams: 'crewId name'.split(' '),
    optionalParams: 'groupId'.split(' '),
  },
  getTemplates: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift-template`,
    requiredParams: 'crewId'.split(' '),
  },
  createTemplate: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift-template`,
    requiredParams: 'crewId name guids'.split(' '),
  },
  updateTemplate: {
    method: 'PUT',
    buildUrl: ({ crewId, templateId }) => `/api/v2/crew/${crewId}/shift-template/${templateId}`,
    requiredParams: 'crewId templateId name'.split(' '),
  },
  deleteTemplate: {
    method: 'DELETE',
    buildUrl: ({ crewId, templateId }) => `/api/v2/crew/${crewId}/shift-template/${templateId}`,
    requiredParams: 'crewId templateId'.split(' '),
  },
  applyTemplate: {
    method: 'POST',
    buildUrl: ({ crewId, templateId }) => `/api/v2/crew/${crewId}/shift-template/${templateId}/apply`,
    requiredParams: 'crewId templateId date'.split(' '),
  },
  copyShifts: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift/copy`,
    requiredParams: 'crewId originStartDate originEndDate targetStartDate targetEndDate'.split(' '),
  },
  nextShift: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/shift/next`,
    requiredParams: 'crewId datetime'.split(' '),
  },
  getNews: {
    method: 'GET',
    buildUrl: ({ userId }) => `/api/v2/users/${userId}/news`,
    requiredParams: 'userId'.split(' '),
    optionalParams: 'perPage page types'.split(' '),
  },
  getNewsCount: {
    method: 'GET',
    buildUrl: ({ userId }) => `/api/v2/users/${userId}/news/count`,
    requiredParams: 'userId'.split(' '),
  },
  createNews: {
    method: 'POST',
    buildUrl: ({ userId }) => `/api/v2/users/${userId}/news`,
    requiredParams: 'userId title text receiverId typeId actionLink'.split(' '),
  },
  updateNews: {
    method: 'PUT',
    buildUrl: ({ userId }) => `/api/v2/users/${userId}/news`,
    requiredParams: 'userId ids'.split(' '),
    optionalParams: 'seenAt readAt preserve'.split(' '),
  },
  deleteNews: {
    method: 'DELETE',
    buildUrl: ({ userId }) => `/api/v2/users/${userId}/news`,
    requiredParams: 'userId ids'.split(' '),
  },
  markAllAsSeen: {
    method: 'POST',
    buildUrl: ({ userId }) => `/api/v2/users/${userId}/news/mark-all-as-seen`,
    requiredParams: 'userId'.split(' '),
  },
  getUserRooms: {
    method: 'GET',
    buildUrl: ({ userId }) => `/api/v2/chat/users/${userId}/rooms`,
    requiredParams: 'userId'.split(' '),
  },
  createRoom: {
    method: 'POST',
    buildUrl: ({ userId }) => `/api/v2/chat/users/${userId}/rooms`,
    requiredParams: 'name userId type'.split(' '),
    optionalParams: 'crewId userIds'.split(' '),
  },
  updateRoom: {
    method: 'PUT',
    buildUrl: ({ userId, roomId }) => `/api/v2/chat/users/${userId}/rooms/${roomId}`,
    requiredParams: 'name roomId userId'.split(' '),
  },
  deleteRoom: {
    method: 'DELETE',
    buildUrl: ({ userId, roomId }) => `/api/v2/chat/users/${userId}/rooms/${roomId}`,
    requiredParams: 'roomId userId'.split(' '),
  },
  addUsersToRoom: {
    method: 'POST',
    buildUrl: ({ userId, roomId }) => `/api/v2/chat/users/${userId}/rooms/${roomId}/users`,
    requiredParams: 'userId roomId userIds'.split(' '),
  },
  removeUsersFromRoom: {
    method: 'DELETE',
    buildUrl: ({ userId, roomId }) => `/api/v2/chat/users/${userId}/rooms/${roomId}/users`,
    requiredParams: 'userId roomId userIds'.split(' '),
  },
  getRoomMessages: {
    method: 'GET',
    buildUrl: ({ userId, roomId }) => `/api/v2/chat/users/${userId}/rooms/${roomId}/messages`,
    requiredParams: 'userId roomId'.split(' '),
    optionalParams: 'perPage page'.split(' '),
  },
  createRoomMessage: {
    method: 'POST',
    buildUrl: ({ userId, roomId }) => `/api/v2/chat/users/${userId}/rooms/${roomId}/messages`,
    requiredParams: 'userId roomId message'.split(' '),
  },
  getUnreadMessages: {
    method: 'GET',
    buildUrl: ({ userId }) => `/api/v2/chat/users/${userId}/rooms/unread-messages`,
    requiredParams: 'userId'.split(' '),
  },
  updateRoomMessages: {
    method: 'PUT',
    buildUrl: ({ userId, roomId }) => `/api/v2/chat/users/${userId}/rooms/${roomId}/messages`,
    requiredParams: 'userId roomId messages'.split(' '),
  },
  getDefaultPublicHolidayCalendar: {
    method: 'GET',
    buildUrl: ({ crewId, country }) => `/api/v2/crew/${crewId}/public-holiday-calendar/${country}`,
    requiredParams: 'crewId country'.split(' '),
  },
  getPublicHolidayCalendar: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/public-holiday-calendar`,
    requiredParams: 'crewId'.split(' '),
  },
  createPublicHolidayCalendar: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/public-holiday-calendar`,
    requiredParams: 'crewId days'.split(' '),
  },
  updatePublicHolidayCalendar: {
    method: 'PUT',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/public-holiday-calendar`,
    requiredParams: 'crewId days'.split(' '),
  },
  createUsageRight: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/usage-right`,
    requiredParams: 'crewId subscriptionId maxCrewSize bitmask trial source validUntil'.split(' '),
  },
  getAbsenceBalances: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/absence-balances`,
    requiredParams: 'crewId date typeId'.split(' '),
    optionalParams: 'userIds'.split(' '),
  },
  getAbsenceDaysCount: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/absence-days-count`,
    requiredParams: 'crewId from to groupBy'.split(' '),
    optionalParams: 'userIds typeIds'.split(' '),
  },
  requestAbsence: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/absence/request`,
    requiredParams: 'crewId userId startDayPart endDayPart startDate endDate typeId'.split(' '),
    optionalParams: 'memberNote'.split(' '),
  },
  getAbsences: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/absences`,
    requiredParams: 'crewId from to'.split(' '),
    optionalParams: 'userId status'.split(' '),
  },
  createAbsence: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/absence/create`,
    requiredParams: 'crewId userId startDayPart endDayPart startDate endDate typeId'.split(' '),
    optionalParams: 'memberNote admitterNote'.split(' '),
  },
  rejectAbsence: {
    method: 'PUT',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/absence/reject`,
    requiredParams: 'userId crewId typeId startDate endDate startDayPart endDayPart'.split(' '),
    optionalParams: 'admitterNote'.split(' '),
  },
  deleteAbsence: {
    method: 'DELETE',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/absence`,
    requiredParams: 'crewId userId typeId startDate endDate startDayPart endDayPart'.split(' '),
  },
  grantAbsence: {
    method: 'PUT',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/absence-management/absence/grant`,
    requiredParams: 'crewId userId typeId startDate endDate startDayPart endDayPart'.split(' '),
    optionalParams: 'admitterNote'.split(' '),
  },
  getReferralCode: {
    method: 'GET',
    buildUrl: () => `/api/v2/user/referral_voucher`,
  },
  getTeams: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/teams`,
    requiredParams: 'crewId'.split(' '),
  },
  createTeam: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/teams`,
    requiredParams: 'crewId name'.split(' '),
  },
  updateTeam: {
    method: 'PUT',
    buildUrl: ({ crewId, id }) => `/api/v2/crew/${crewId}/teams/${id}/`,
    requiredParams: 'crewId id name'.split(' '),
  },
  deleteTeam: {
    method: 'DELETE',
    buildUrl: ({ crewId, id }) => `/api/v2/crew/${crewId}/teams/${id}/`,
    requiredParams: 'crewId id'.split(' '),
  },
  getTeamMembers: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/team-members`,
    requiredParams: 'crewId'.split(' '),
    optionalParams: 'userId teamId'.split(' '),
  },
  createTeamMembersForMember: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/team-members/member/`,
    requiredParams: 'crewId userId teamIds'.split(' '),
  },
  createTeamMembersForTeam: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/team-members/team/`,
    requiredParams: 'crewId teamId userIds'.split(' '),
  },
  getSettingsDefinitions: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/workforce-management/settings`,
    requiredParams: 'crewId'.split(' '),
  },
  getSettingsValues: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/workforce-management/settings/values`,
    requiredParams: 'crewId'.split(' '),
  },
  postSettingValues: {
    method: 'POST',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/workforce-management/settings/values/setting-group/`,
    requiredParams: 'crewId values'.split(' '),
    preProcessParams: ({ values }) => values,
  },
  deleteSettingValues: {
    method: 'DELETE',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/workforce-management/settings/values/setting-group/`,
    requiredParams: 'crewId values'.split(' '),
    preProcessParams: ({ values }) => values,
  },
  spreadsheetRequest: {
    method: 'POST',
    buildUrl: ({ crewId, fileCategory, from, language, listParam, to }) =>
      `/api/v2/crew/${crewId}/workforce-management/spreadsheet/request?fileCategory=${fileCategory}&from=${from}&language=${language}&to=${to}${listParam}`,
    requiredParams: 'crewId fileCategory from language listParam to'.split(' '),
  },
  getListFiles: {
    method: 'GET',
    buildUrl: ({ crewId }) => `/api/v2/crew/${crewId}/workforce-management/files`,
    requiredParams: 'category crewId userId'.split(' '),
    optionalParams: 'statuses notExpired'.split(' '),
  },
};
