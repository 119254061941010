/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction, buildDangerouslyTranslateNeverWithUserContent } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    greeting: 'Hallo',
    info: 'Bitte geben Sie das Passwort ein um fortzufahren',
    onPasswordSubmitError: `
      Wir konnten Ihnen keine Anleitung zum Zurücksetzen des Passworts zusenden.
      Wenn Sie keine Email hinterlegt haben, kontaktieren Sie Ihren Vorgesetzten.

    `,
    onPasswordSubmitSuccess: 'Passwort-Reset-Anweisungen versendet, bitte überprüfen Sie Ihre E-Mails.',
    password: 'Passwort',
    passwordFailed: 'Falsches Passwort. Bitte das korrekte Passwort angeben.',
    resetPassword: 'Passwort zurücksetzen',
    submit: 'Fortfahren',
    submitFailed: 'Anmelden fehlgeschlagen. Bitte Login und Passwort überprüfen.',
  },
  en: {
    greeting: 'Hello',
    info: 'Please enter your password to continue',
    onPasswordSubmitError: `
      We were not able to send you instruction to reset your password.
      If you don't have an email, contact your manager.
    `,
    onPasswordSubmitSuccess: 'We have sent you instruction to reset your password. Please check your email.',
    password: 'Password',
    passwordFailed: 'Wrong password. Please enter the correct password.',
    resetPassword: 'Reset Password',
    submit: 'Submit',
    submitFailed: 'Sign-in failed. Wrong credentials?',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
