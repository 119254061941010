import { createSelector } from 'reselect';

import { selectCurrentUserId } from './authentication-selectors';
import { selectCurrentCrewId } from './crews-selectors';
import { createPropsFinder, createPropsSelector } from './utils';

export const selectBookingsFromState = (state) => state.bo.bookings.bookings;

export const selectBookingsInCurrentCrew = createSelector(
  selectBookingsFromState,
  selectCurrentCrewId,
  (bookings, crewId) => bookings.filter((booking) => booking.crewId === crewId)
);

export const selectBookingsByUserId = createSelector(selectBookingsFromState, selectCurrentUserId, (bookings, userId) =>
  bookings.filter((item) => item.userId === userId)
);

export const selectBookingsByProps = createPropsSelector(selectBookingsFromState);
export const findBookingsByProps = createPropsFinder(selectBookingsFromState);
