import React, { Component } from 'react';
import { asyncComponent } from 'react-async-component';
import { css } from '../../_external-deps/stylesheet';
import Button from './button';
import { styles } from './map';

const promisify = (fn, ...args) => {
  return new Promise((resolve, reject) => {
    fn(...args, resolve, reject);
  });
};

const RADIUS = {
  SMALL: 150,
  MEDIUM: 250,
  LARGE: 350,
};

class Template extends Component {
  componentDidMount() {
    this.map.on('moveend', this.onZoomMoveEnd);
    this.map.on('zoomend', this.onZoomMoveEnd);
    this.onSizeChange();
  }

  componentWillUnmount() {
    this.map.off('moveend', this.onZoomMoveEnd);
    this.map.off('zoomend', this.onZoomMoveEnd);
    this.map.clearAllEventListeners();
  }

  onZoomMoveEnd = () => {
    const zoom = this.map.getZoom();
    const { lat, lng } = this.map.getCenter();
    Promise.resolve()
      .then(() => this.props.onValueChange(zoom, 'zoom'))
      .then(() => this.props.onValueChange([{ lat, lng }], 'markers'))
      .then(() => promisify(this.onSizeChange));
  };

  sizeCalculator = (radius) => {
    const { zoom } = this.props;
    const percentage =
      (40075016.686 * Math.abs(Math.cos((this.map.getCenter().lat / 180) * Math.PI))) / Math.pow(2, zoom + 8);
    return Math.ceil(radius / percentage);
  };

  onSizeChange = () => {
    const size = this.sizeCalculator(this.props.radius);
    this.props.onValueChange(size, 'size');
  };

  onRadiusChange = (radius) => {
    Promise.resolve()
      .then(() => this.props.onValueChange(radius, 'radius'))
      .then(() => promisify(this.onSizeChange));
  };

  render() {
    const { disabled, id, radius } = this.props;
    const { Map, Control, CircleMarker } = this.props.components;

    return (
      <Map
        id={id}
        disabled={disabled}
        markers={this.props.markers}
        onRef={(map) => {
          this.map = map;
        }}
        zoom={this.props.zoom}
        className={`${css(styles.map)} ${this.props.className}`}
      >
        <Control position="topright">
          <div>
            <Button
              active={radius === RADIUS.SMALL}
              disabled={disabled}
              id={id && `${id}-radius-small`}
              inline
              narrow
              onClick={() => this.onRadiusChange(RADIUS.SMALL)}
              small
              withBackground
            >
              150m
            </Button>
            <Button
              active={radius === RADIUS.MEDIUM}
              disabled={disabled}
              id={id && `${id}-radius-medium`}
              inline
              narrow
              onClick={() => this.onRadiusChange(RADIUS.MEDIUM)}
              small
              withBackground
            >
              250m
            </Button>
            <Button
              active={radius === RADIUS.LARGE}
              disabled={disabled}
              id={id && `${id}-radius-medium`}
              inline
              narrow
              onClick={() => this.onRadiusChange(RADIUS.LARGE)}
              small
              withBackground
            >
              350m
            </Button>
          </div>
        </Control>
        <CircleMarker
          ref={(circleMarker) => {
            this.circleMarker = circleMarker;
          }}
          center={[this.props.markers[0].lat, this.props.markers[0].lng]}
          radius={this.props.size}
        />
        <If condition={this.map}>
          <CircleMarker
            ref={(circleMarker) => {
              this.circleMarker = circleMarker;
            }}
            center={[this.props.markers[0].lat, this.props.markers[0].lng]}
            radius={this.sizeCalculator(10)}
            fillOpacity={1}
          />
        </If>
      </Map>
    );
  }
}

// Prevent the map to only render half of the container.
css(styles.map);
css(styles.globals);

export default asyncComponent({
  resolve: async () => {
    const components = await import('../../_external-deps/map');
    return { default: (props) => <Template {...props} components={components} /> };
  },
});
