import React from 'react';
import Button from '../../components/core/button';
import PasswordInput from '../../components/core/password-input';
import { isForm } from '../../components/core/form';
import LogoWithHeading from '../../components/core/logo-with-heading';
import t from './translate';
import AuthenticationLinks from '../../components/core/authentication-links';
import { IDS_FOR_GOOGLE_TAG_MANAGER } from '../../constants';

const NewPasswordRecovery = isForm(({ errors = {}, withoutWebsiteLink = false }) => (
  <div>
    <LogoWithHeading withoutLink={withoutWebsiteLink}>{t('heading')}</LogoWithHeading>

    <PasswordInput placeholder={t('password')} name="password" errors={errors.password} required />

    <PasswordInput
      placeholder={t('passwordConfirmation')}
      name="passwordConfirmation"
      errors={errors.passwordConfirmation}
      required
    />

    <Button id={IDS_FOR_GOOGLE_TAG_MANAGER.USER.PASSWORD_RECOVERY_RESET} type="submit" spacingBottom>
      {t('submit')}
    </Button>
    <AuthenticationLinks signIn />
  </div>
));

export default NewPasswordRecovery;
