import { createSelector } from 'reselect';
import { selectCurrentUserId } from './authentication-selectors';
import { selectCurrentCrewId } from './crews-selectors';

export const selectTeamMembersFromState = (state) => state.bo.teamMembers.teamMembers;

export const selectTeamMembersForCurrentCrew = createSelector(
  selectTeamMembersFromState,
  selectCurrentCrewId,
  (teamMembers, crewId) => teamMembers.filter((teamMember) => teamMember.crewId === crewId)
);

export const selectTeamMembersByMember = createSelector(
  selectTeamMembersForCurrentCrew,
  (_, props) => parseInt(props.userId, 10),
  (teamMembers, userId) => teamMembers.filter((teamMember) => teamMember.userId === userId)
);

export const selectTeamMembersForCurrentTeam = createSelector(
  selectTeamMembersForCurrentCrew,
  (_, props) => props.teamId,
  (teamMembers, teamId) => teamMembers.filter((teamMember) => teamMember.teamId === teamId)
);

export const selectTeamMembersForCurrentUserAndCrew = createSelector(
  selectTeamMembersFromState,
  selectCurrentCrewId,
  selectCurrentUserId,
  (teamMembers, crewId, userId) =>
    teamMembers.filter((teamMember) => teamMember.userId === userId && teamMember.crewId === crewId)
);
