import PropTypes from 'prop-types';
import React from 'react';

import { THEMES } from '../../constants';
import { COLORS } from '../../styles/config';
import * as fonts from '../../styles/fonts';
import { css, StyleSheet } from '../../_external-deps/stylesheet';
import Icon from './icon';

class GenericLabel extends React.Component {
  state = { isChanging: false, hadSameValue: false };

  /* eslint-disable-next-line camelcase*/
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value && !nextProps.withoutChangeAnimation) {
      global.clearTimeout(this.timeout);
      this.setState({ isChanging: true });
      this.timeout = global.setTimeout(() => {
        this.setState({ isChanging: false });
      }, 250);
    }
  }
  componentWillUnmount() {
    global.clearTimeout(this.timeout);
  }

  render() {
    const {
      alignRight,
      children,
      errors,
      hint,
      iconName,
      isExpanded,
      isFocused,
      labelRight,
      nowrap,
      onIconClick,
      placeholder,
      placeholderInfo,
      required,
      theme,
      withoutBorder,
      withoutErrors,
    } = this.props;

    const stylesForTheme = getStylesForTheme({ theme });

    return (
      <div
        className={css(
          styles.textInputContainer,
          !isFocused && this.state.isChanging && styles.scale,
          isFocused && styles.isFocused
        )}
      >
        <label
          className={css(
            styles.placeholder,
            isExpanded && styles.placeholderAsLabel,
            alignRight && styles.alignRight,
            isExpanded && !isFocused && styles.labelExpandedButNotFocused
          )}
        >
          <span>
            {placeholder} {required && <span className={css(styles.asterisk)}>*</span>}
          </span>
          {placeholderInfo && (
            <span className={css(styles.placeholderInfo, isExpanded && styles.placeholderInfoExpanded)}>
              {placeholderInfo}
            </span>
          )}
        </label>

        <div
          className={css(
            styles.childrenWrapper,
            withoutBorder ? styles.withoutBorder : styles.borderBottom,
            (isFocused || this.state.isChanging) && styles.borderBottomFocused
          )}
        >
          <div
            className={css(
              placeholder && styles.topLabelSpacing,
              styles.text,
              iconName && styles.iconOffset,
              alignRight && styles.alignRight,
              nowrap && styles.nowrap,
              stylesForTheme.label
            )}
          >
            {children}
            {iconName && (
              <Icon
                name={iconName}
                className={css(styles.withIcon, !isExpanded && styles.hideWithIcon)}
                onClick={onIconClick}
              />
            )}
            {labelRight && <span className={css(styles.labelRight)}>{labelRight}</span>}
          </div>
        </div>

        <If condition={!withoutErrors || hint !== undefined}>
          <div className={css(styles.subField)}>
            <Choose>
              <When condition={errors && !!errors.length}>
                <span className={css(styles.error)}>
                  {errors[0].message}
                  &nbsp;
                </span>
              </When>
              <When condition={hint !== undefined}>
                <span className={css(styles.hint, isExpanded && styles.hintExpanded)}>{hint}</span>
              </When>
            </Choose>
          </div>
        </If>
      </div>
    );
  }
}

const TextWithPlaceholder = (props) => <GenericLabel isExpanded withoutBorder {...props} />;

TextWithPlaceholder.propTypes = {
  placeholder: PropTypes.string,
  placeholderInfo: PropTypes.string,
  hint: PropTypes.string,
  labelRight: PropTypes.string,
  isExpanded: PropTypes.bool,
  isFocused: PropTypes.bool,
  errors: PropTypes.array,
  children: PropTypes.any.isRequired,
  alignRight: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  withoutErrors: PropTypes.bool,
  iconName: PropTypes.string,
  nowrap: PropTypes.bool,
};

// naming reference
// http://3.bp.blogspot.com/_c7WMO7KDCu0/TL5naYV8o3I/AAAAAAAAAGo/wstDGkGcJB0/s1600/editor10.gif
const PLACEHOLDER_LABEL_HEIGHT = 20;
const INPUT_DECENT_HEIGHT = 2;

const getStylesForTheme = ({ theme }) =>
  StyleSheet.create({
    label: {
      ...(theme === THEMES.ORANGE ? { color: COLORS.ORANGE_MEDIUM } : {}),
    },
  });

export const styles = StyleSheet.create({
  topLabelSpacing: {
    paddingTop: PLACEHOLDER_LABEL_HEIGHT,
  },
  text: {
    ...fonts.medium,
    paddingBottom: INPUT_DECENT_HEIGHT,
    display: 'flex',
  },
  iconOffset: {
    paddingRight: '22px',
  },
  textInputContainer: {
    width: '100%',
    position: 'relative',
    transition: 'transform 250ms',
  },
  textInput: {
    ...fonts.medium,
    backgroundColor: 'transparent',
    position: 'relative',
    outline: 0,
    transition: 'all 200ms',
    lineHeight: 'normal',
    width: '100%',
    minWidth: '0', // Fix minutes text in Firefox
    paddingTop: '0', // Fix browser styles
    paddingBottom: INPUT_DECENT_HEIGHT + 5,
    marginBottom: -(INPUT_DECENT_HEIGHT + 5),

    opacity: 0, // Hides --:-- on time and date input when label is not expanded.

    borderWidth: 0,
    borderRadius: 0, // Fixes iOS rounded corners

    // Input type time has a slightly bigger height than input type text.
    // http://stackoverflow.com/a/14124925/3002239
    boxSizing: 'content-box',
    height: '20px',

    ':-webkit-autofill': {
      // prevent webkit color change onAutofill
      transition: 'background-color 9999s, color 9999s',
    },
  },
  textInputExpanded: {
    opacity: 1, // Shows --:-- on time and date input when label is expanded.
  },
  borderBottom: {
    boxShadow: `0px 1px 0px 0px ${COLORS.GREY_EXTRA_LIGHT}`,
    transition: 'all 200ms',

    ':hover': {
      borderBottomColor: COLORS.GREY_MEDIUM,
    },
  },
  withoutBorder: {
    boxShadow: `0px 0px 0px 0px ${COLORS.GREY_EXTRA_LIGHT}`,
  },
  borderBottomFocused: {
    boxShadow: `0px 2px 0px 0px ${COLORS.ORANGE_MAIN}`,
    ':hover': {
      boxShadow: `0px 2px 0px 0px ${COLORS.ORANGE_MAIN}`,
    },
  },
  placeholder: {
    ...fonts.medium,
    position: 'absolute',
    color: COLORS.ORANGE_MEDIUM,
    top: PLACEHOLDER_LABEL_HEIGHT,
    left: 0,
    transition: 'all 0.2s ease',
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  placeholderInfo: {
    ...fonts.small,
    color: COLORS.GREY_MAIN,
    opacity: 0,
    transition: 'all 200ms',
  },
  placeholderInfoExpanded: {
    opacity: 1,
  },
  hint: {
    ...fonts.small,
    color: COLORS.GREY_MAIN,
    opacity: 0,
    transition: 'all 200ms',
  },
  hintExpanded: {
    color: COLORS.ORANGE_MAIN,
    opacity: 1,
  },
  placeholderAsLabel: {
    ...fonts.small,
    color: COLORS.ORANGE_MAIN,
    top: 0,
  },
  labelExpandedButNotFocused: {
    color: COLORS.GREY_MAIN,
  },
  subField: {
    padding: '4px 0',
  },
  error: {
    ...fonts.small,
    color: COLORS.RED_MAIN,
    lineHeight: 1,
  },
  asterisk: {
    color: COLORS.RED_MAIN,
  },
  alignRight: {
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  withIcon: {
    position: 'absolute',
    width: '20px',
    color: COLORS.GREY_MAIN,
    right: '0',
    bottom: '8px',
    paddingLeft: '2px',
  },
  hideWithIcon: {
    transition: 'opacity 0.4s',
    opacity: '0',
  },
  childrenWrapper: {
    position: 'relative',
  },
  scale: {
    transform: 'scale(1.06)',
  },
  isFocused: {
    backgroundColor: COLORS.ORANGE_OPAQUE,
  },
  labelRight: {
    paddingLeft: '5px',
    marginTop: '3px',
    lineHeight: 1,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
});

export default TextWithPlaceholder;
export const InputWithLabel = (props) => <GenericLabel {...props} />;
