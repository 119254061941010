import { memoize } from 'redux-memoize';
import { delay } from 'promise-frites';
import { dispatchAsyncFnStatusActions } from '../lib';
import { dataTypes, string as stringUtils } from '../utils';

const { isArray } = dataTypes;
const { toSnakeCase } = stringUtils;

const ATC_PROCESSING_DELAY_SECONDS = 2;
export const loadDurationBalances = memoize({}, ({ api, ...params }) => async (dispatch) => {
  await delay(ATC_PROCESSING_DELAY_SECONDS);
  if (params.callTimeAccountBalancesEndpoint) {
    const fnParams = {
      crewId: params.crewId,
      from: params.from,
      to: params.to,
      ...('userId' in params ? { userId: isArray(params.userId) ? params.userId : [params.userId] } : {}),
      groupBy: params.groupBy,
      type: 'overtime',
    };
    return dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'LOAD_DURATION_BALANCES_V2',
      fnParams,
      fn: () => api.timeAccountBalances(fnParams),
    });
  }
  const fnParams = {
    crewId: params.crewId,
    date: isArray(params.date) ? params.date : [params.date],
    ...('userId' in params ? { userId: isArray(params.userId) ? params.userId : [params.userId] } : {}),
    ...('groupBy' in params ? { groupBy: toSnakeCase(params.groupBy) } : {}),
    ...('type' in params ? { type: toSnakeCase(params.type) } : {}),
  };
  return dispatchAsyncFnStatusActions({
    dispatch,
    actionName: 'LOAD_DURATION_BALANCES',
    fnParams,
    fn: () => api.durationBalances(fnParams),
  });
});
