import React from 'react';
import { StyleSheet, css } from '../../../_external-deps/stylesheet';
import { TIME_ACCOUNTS, Z_INDEX, COLORS, FONT_STYLES, IDS_FOR_GOOGLE_TAG_MANAGER } from '../../../constants';
import { event as eventUtils, array as arrayUtils } from '../../../utils';
import Icon from '../../../components/core/icon';

const { debounceEvent } = eventUtils;
const { sortByKey } = arrayUtils;

const fitsIntoSpace = (numberOfItems, tileSize) => {
  const margin = 10;
  const offsetTop = 135;
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const outerTileSize = tileSize + 2 * margin;
  const count = Math.floor(viewportWidth / outerTileSize) * Math.floor((viewportHeight - offsetTop) / outerTileSize);
  return numberOfItems <= count;
};

const tileSize = (amount) => {
  const sizes = [180, 170, 160, 150, 140, 130, 120, 110, 100, 90];
  return sizes.slice(0, -1).find((size) => fitsIntoSpace(amount, size)) || 90;
};

const WORKING_STATES = {
  [TIME_ACCOUNTS.WORKING]: () => <div className={css(styles.workingState, styles.working)} />,
  [TIME_ACCOUNTS.BREAK]: () => (
    <div className={css(styles.workingState, styles.break)}>
      <Icon name="pause" className={css(styles.icon)} />
    </div>
  ),
  default: () => <div className={css(styles.workingState, styles.notWorking)} />,
};

const MemberWorkingState = ({ timeAccount }) => (WORKING_STATES[timeAccount] || WORKING_STATES.default)();

const SingleMember = ({ id, member, onSignUpOpen, size }) => (
  <li
    id={id}
    onClick={() => onSignUpOpen({ userId: member.userId })}
    className={css(styles.member)}
    style={{
      backgroundImage: `url(${member.avatarUrl})`,
      width: size,
      height: size,
    }}
    data-e2e-test="terminal-member"
  >
    <MemberWorkingState timeAccount={member.activeTimeAccount} />
    <div className={css(styles.memberName)}>{member.name}</div>
  </li>
);

class MemberList extends React.Component {
  state = {
    size: 180,
  };

  onResize = debounceEvent(() => {
    this.setState({ size: tileSize(this.props.members.length) });
  }, 100);

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    const { members, onSignUpOpen } = this.props;
    const { size } = this.state;
    return (
      <ul className={css(styles.list)}>
        {sortByKey(members, 'name').map((member, index) => (
          <SingleMember
            id={`${IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL.CLICK_MEMBER_TILE}-${index}`}
            key={member.id}
            member={member}
            onSignUpOpen={onSignUpOpen}
            size={size}
          />
        ))}
      </ul>
    );
  }
}

const pulse = {
  '0%': { opacity: 0.7 },
  '50%': { opacity: 1 },
  '100%': { opacity: 0.7 },
};

const styles = StyleSheet.create({
  workingState: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: Z_INDEX.MEDIUM,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '40px',
  },
  working: {
    background: COLORS.MAIN_COLOR_MEDIUM_LITE,
    animationName: [pulse],
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
  },
  break: {
    background: COLORS.MAIN_COLOR_MEDIUM_LITE,
  },
  notWorking: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 1) 0%,rgba(0,0,0,0) 50%)',
    opacity: 0.3,
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    margin: 'auto',
    justifyContent: 'center',
  },
  member: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    width: 180,
    height: 180,
    margin: 10,
    backgroundColor: COLORS.GREY_EXTRA_LIGHT,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    listStyle: 'none',
    ...FONT_STYLES.medium,
    color: COLORS.WHITE,
  },
  memberName: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    zIndex: Z_INDEX.MEDIUM,
  },
  icon: {
    fontSize: '140px',
  },
});

export { MemberList };
