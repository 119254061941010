import React from 'react';
import PropTypes from 'prop-types';

import { setSyncedInterval, clearSyncedInterval } from 'synced-interval';
import { event as eventUtils } from '../../utils';
import CircleProgress from './circle-progress';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { FONT_STYLES, COLORS, Z_INDEX } from '../../constants';

const MAX_WAIT_TIME_IN_SECONDS = 30;
const WAIT_TIME_OFFSET = 10;

const { throttleEvent } = eventUtils;

class InactivityAction extends React.Component {
  static defaultProps = {
    startAfterSeconds: WAIT_TIME_OFFSET,
    maxInactivitySeconds: MAX_WAIT_TIME_IN_SECONDS,
  };

  constructor(props) {
    super(props);
    this.state = { remainingSeconds: this.props.maxInactivitySeconds + this.props.startAfterSeconds };
  }

  startInterval() {
    clearSyncedInterval(this.interval);
    this.interval = setSyncedInterval(() => {
      const remainingSeconds = this.state.remainingSeconds;

      if (!this.props.isActive) {
        return;
      }
      if (remainingSeconds <= 0) {
        clearSyncedInterval(this.interval);
        return this.props.callback();
      }

      this.setState({ remainingSeconds: remainingSeconds - 1 });
    }, 1000);
  }

  componentDidMount() {
    this.onUserInteraction = throttleEvent(() => {
      this.setState({ remainingSeconds: this.props.maxInactivitySeconds + this.props.startAfterSeconds });
      this.startInterval();
    }, 1000);

    global.document.addEventListener('mousemove', this.onUserInteraction);
    global.document.addEventListener('click', this.onUserInteraction);
    this.startInterval();
  }

  componentWillUnmount() {
    global.document.removeEventListener('mousemove', this.onUserInteraction);
    global.document.removeEventListener('click', this.onUserInteraction);
    clearSyncedInterval(this.interval);
  }

  render() {
    const isShown = this.state.remainingSeconds <= this.props.maxInactivitySeconds && this.props.isActive;
    const progress = this.state.remainingSeconds / this.props.maxInactivitySeconds;
    const normalizedProgress = progress <= 1 ? progress * 100 : 100;
    return (
      isShown && (
        <div className={css(styles.inactivity)}>
          <CircleProgress progress={normalizedProgress} value={this.state.remainingSeconds} />
          <div className={css(styles.message)}>{this.props.message}</div>
        </div>
      )
    );
  }
}

InactivityAction.propTypes = {
  callback: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  maxInactivitySeconds: PropTypes.number,
  startAfterSeconds: PropTypes.number,
  message: PropTypes.string,
};

const styles = StyleSheet.create({
  inactivity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '20px',
    padding: '10px',
    background: COLORS.EGGSHELL_MAIN,
    position: 'fixed',
    maxWidth: '600px',
    width: '100%',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, 0)',
    boxShadow: '0 0 50px 15px rgba(136, 116, 100, 0.3)',
    zIndex: Z_INDEX.INACTIVITY,
  },
  message: {
    ...FONT_STYLES.medium,
    padding: '20px',
  },
});

export default InactivityAction;
