import { factory, sequence } from 'stahlwerk';

export const createWorkplace = factory({
  id: sequence(),
  name: sequence((id) => `Workplace ${id}`),
  crewId: sequence(),
  _sync: {
    userId: sequence(),
  },
});
