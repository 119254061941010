import t from '../lib/translate';
import errorMessages from './error-messages';

export const confirmPassword = ({ password, passwordConfirmation, ...params }) => {
  if (password !== passwordConfirmation) {
    // eslint-disable-next-line no-throw-literal
    throw {
      errors: {
        passwordConfirmation: [{ message: t(errorMessages.PASSWORDS_MISMATCH) }],
      },
    };
  }

  return { password, passwordConfirmation, ...params };
};
