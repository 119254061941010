// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck FIXME: remove this line
// Once the PERMISSION for 3-column Terminal is available,
//  1. We have to use the commented code!
//  2. Delete the code for query parameter isBetaUser

import { FC } from '@crewmeister/shared';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';

import { CrewType } from '../../../types';

import TriggerCallbackEachSeconds from '../../../components/core/auto-refresh';
import LoadingSpinnerWithText from '../../../components/core/loading-spinner-with-text';
import Modal from '../../../components/core/modal';
import { TERMINAL_VIEW_MODES } from '../../../constants';
import CrewMaintenance from '../../../containers/crew-maintenance';
import Terminal3ColumnOverview from '../../../containers/terminal-3-column-overview';
import TerminalOverview from '../../../containers/terminal-overview';
import TerminalPinCode from '../../../containers/terminal-pin-code';
import TerminalSignIn from '../../../containers/terminal-sign-in';
import TerminalTimeTracking from '../../../containers/terminal-time-tracking';
import PasswordRecoveryReset from '../../../containers/user-password-recovery-reset';
import AuthenticationLayout from '../../../layouts/authentication';
import * as selectors from '../../../selectors';
import { updateGTMDataLayerContext } from '../../../utils';
import { Menu } from './menu';

const { findByProps } = selectors;

const isCrewInMaintenance = (crewId: number, crews: CrewType[]) => {
  const maintenanceResult = findByProps(crews, { id: crewId }, {}, 'inMaintenance');
  if (crews.length !== 0) {
    if (crews[0].id === null && crews[0].inMaintenance === true) {
      return true;
    }
  }
  return maintenanceResult;
};

const asNumber = (strNumber: string) => parseInt(strNumber, 10);

type TerminalAppProps = {
  autoRefreshCallback: string;
  autoRefreshPermissionsCallback: string;
  crew?: CrewType;
  crews: CrewType[];
  isSubmitting: string;
  terminalViewMode: string;
  terminalAuthenticationMode: string;
  permissions: Record<string, boolean>;
  setIsSubmitting: string;
  userId: string;
};

export const TerminalApp: FC<TerminalAppProps> = ({
  autoRefreshCallback,
  autoRefreshPermissionsCallback,
  crew,
  crews,
  isSubmitting,
  terminalViewMode,
  terminalAuthenticationMode,
  permissions,
  setIsSubmitting,
  userId,
}) => {
  const crewId = crew && crew.id;

  if (!crewId || !terminalViewMode) return <LoadingSpinnerWithText withLoadingIcon fullscreen />;

  const crewIsInMaintenance = isCrewInMaintenance(crewId, crews);

  useEffect(() => {
    updateGTMDataLayerContext({
      crewId: crewId,
      terminalViewMode: terminalViewMode,
      terminalAuthenticationMode: terminalAuthenticationMode,
    });
  }, [crewId, terminalViewMode, terminalAuthenticationMode]);

  const shouldUsePinCodeTerminal =
    terminalViewMode === TERMINAL_VIEW_MODES.PIN_CODE && permissions.canUsePinCodeTerminal;
  const shouldUse3ColumnTerminal =
    terminalViewMode === TERMINAL_VIEW_MODES.THREE_COLUMN && permissions.canUseThreeColumnTerminal;
  const shouldUseBasicTerminal = !shouldUsePinCodeTerminal && !shouldUse3ColumnTerminal;
  return (
    <>
      {crewIsInMaintenance && <CrewMaintenance crewId={crewId} />}

      {!crewIsInMaintenance && (
        <Switch>
          <Route
            path="/password-recovery-reset"
            component={() => (
              <AuthenticationLayout>
                <PasswordRecoveryReset withoutWebsiteLink />
              </AuthenticationLayout>
            )}
          />
          <Route>
            <div>
              <Menu crewName={crew && crew.name} />
              <TriggerCallbackEachSeconds callback={autoRefreshCallback} seconds={60} active />
              <TriggerCallbackEachSeconds callback={autoRefreshPermissionsCallback} seconds={600} active />

              {shouldUsePinCodeTerminal && <TerminalPinCode userId={userId} crewId={crewId} />}

              {shouldUse3ColumnTerminal && <Terminal3ColumnOverview userId={userId} crewId={crewId} />}

              {shouldUseBasicTerminal && (
                <>
                  <TerminalOverview userId={userId} crewId={crewId} />
                  <Route
                    path="/login/:userId"
                    render={({ match, history }) => {
                      return (
                        <Modal
                          isOpen
                          padding
                          onClose={() => {
                            history.push('/');
                          }}
                          preventClosing={isSubmitting}
                        >
                          <TerminalSignIn
                            crewId={crewId}
                            userId={asNumber(match.params.userId)}
                            setPreventClosing={setIsSubmitting}
                          />
                        </Modal>
                      );
                    }}
                  />

                  {userId && <TerminalTimeTracking crewId={crewId} userId={userId} />}
                </>
              )}
            </div>
          </Route>
        </Switch>
      )}
    </>
  );
};

export default TerminalApp;
