import React from 'react';
import { isForm } from '../../../components/core/form';
import TextInput from '../../../components/core/text-input';
import t from '../translate';

export const TimeCategoryListEditName = isForm(({ errors, timeCategory, onSubmit }) => (
  <TextInput
    onBlur={(_, evt) => onSubmit(evt.target.form)}
    placeholder={t('name')}
    name="name"
    errors={errors.name}
    initialValue={timeCategory.name}
    required
  />
));

export default TimeCategoryListEditName;
