import PropTypes from 'prop-types';
import React from 'react';
import { collections, object } from '../../utils';
import { css } from '../../_external-deps/stylesheet';
import NativeInput from './native-input';
import withLabel from './with-label';
import withDisabled from './with-disabled';

const { compose } = collections;
const { pick, renameKey } = object;

const AVAILABLE_PROPS = [
  'id',
  'value',
  'onChange',
  'step',
  'onFocus',
  'onBlur',
  'styles',
  'required',
  'disabled',
  'name',
  'type',
  'autoFocus',
  'autoComplete',
  'pattern',
  'title',
  'maxLength',
  'data-e2e-test',
];

const propsToHTMLAttributes = (props) =>
  compose(pick(AVAILABLE_PROPS), renameKey('initialValue', 'initialvalue'))(props);

const TextInputComponent = withDisabled(
  withLabel(({ disabled, isExpanded, styles, ...props }) => (
    <NativeInput
      {...propsToHTMLAttributes(props)}
      className={css(styles.textInput, isExpanded && styles.textInputExpanded)}
      disabled={disabled}
    />
  ))
);

TextInputComponent.propTypes = {
  placeholder: PropTypes.string.isRequired,
  placeholderInfo: PropTypes.string,
  hint: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.array,
  initialValue: PropTypes.string,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
};

export default TextInputComponent;
