import React from 'react';
import { executeWhenUnresponsive, rethrowError } from 'promise-frites';
import translate from '../../../lib/translate';
import LoadingSpinnerWithText from '../../../components/core/loading-spinner-with-text';

export class LoadingProxy extends React.Component {
  state = { disabled: false, loadingText: translate('Data is being processed') };

  render() {
    const disableScreen = () => this.setState({ disabled: true });
    const enableScreen = () => this.setState({ disabled: false });

    const notifyUserOnLongRequest = executeWhenUnresponsive({
      finally: () => this.setState({ loadingText: translate('Data is being processed') }),
    });

    const triggerLoading =
      (action) =>
      (...args) =>
        Promise.resolve()
          .then(disableScreen)
          .then(notifyUserOnLongRequest(() => action(...args)))
          .then(enableScreen)
          .catch(rethrowError(enableScreen));

    const childProps = this.props.children.props;
    const { onStartWork, onStopWork, onStartBreak, onUpdateBreak } = childProps;

    const { disabled } = this.state;
    if (disabled) {
      return <LoadingSpinnerWithText text={this.state.loadingText} theme={this.props.theme} />;
    }
    return React.cloneElement(this.props.children, {
      ...childProps,
      onStartWork: triggerLoading(onStartWork),
      onStopWork: triggerLoading(onStopWork),
      onStartBreak: triggerLoading(onStartBreak),
      onUpdateBreak: triggerLoading(onUpdateBreak),
    });
  }
}

export default LoadingProxy;
