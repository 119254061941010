import { isString } from './data-types';

/**
 * fromStringToInteger — Converts the given string to integer.
 *
 * @param element (e.g. "10.2")
 * @returns {number} string converted to integer (e.g. 10)
 */
export const fromStringToInteger = <T>(element: T): number | T =>
  isString(element) && !isNaN(element as unknown as number) ? parseInt(element, 10) : element;
