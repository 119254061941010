import deepEqual from 'deep-equal';
import curry from 'lodash.curry';
import { areArraysDifferent } from './array';
import { areTypesDifferent, isArray, isObject, isString } from './data-types';

/**
 * areObjectsDifferent - Checks if the given objects are different
 *
 * @param {object} object1
 * @param {object} object2
 * @param {array} properties
 * @param {integer} depthLimit
 *
 * @return {boolean} return true if both objects are different, false otherwise
 */
export const areObjectsDifferent = (
  object1: any = {},
  object2: any = {},
  properties: string[] = [],
  depthLimit: number = 3
): boolean => {
  // Stop recursion if it exceeds the depth limit passed originally
  if (depthLimit < 0) return false;

  if (!isObject(object1) || !isObject(object2)) {
    return object1 !== object2;
  }

  // Retrieve the properties of the objects
  let keysOfObject1 = Object.getOwnPropertyNames(object1);
  let keysOfObject2 = Object.getOwnPropertyNames(object2);

  // Filter them, according to the 'properties' given
  if (properties.length) {
    keysOfObject1 = keysOfObject1.filter((key) => properties.find((property) => property === key));
    keysOfObject2 = keysOfObject2.filter((key) => properties.find((property) => property === key));
  }

  // Check if the objects have the same properties
  if (!deepEqual(keysOfObject1.sort(), keysOfObject2.sort())) return true;

  // Iterate over the objects
  return keysOfObject1.some((key) => {
    const value1 = object1[key];
    const value2 = object2[key];

    if (isString(value1) || isString(value2)) return !isString(value1) || !isString(value2) || value1 !== value2;

    // Check if the values have different types
    const areDifferent: boolean | undefined = areTypesDifferent(value1, value2, depthLimit);
    if (areDifferent !== undefined) return areDifferent;

    // If values are arrays, call the function to check if they are different
    if (isArray(value1)) return areArraysDifferent(value1, value2, depthLimit);

    return value1 !== value2;
  });
};

/**
 * except - Generates a function responsible for returning the given object without
 *          the given keys
 *
 * @param {object} object
 * @param {array} keys array of strings which each element represents a property in @param object
 *
 * @return {function} the new curried function responsible for returning the given object
 *                    without the given keys
 */
export const except = <T, K extends string>(object: Record<K, T>, keys: Array<Partial<K>>) =>
  curry(
    (object: Record<K, T>, keys: Partial<K>[]): Omit<T, K> =>
      (Object.keys(object) as K[]).reduce((newObject, key) => {
        if (isArray(keys) ? keys.includes(key) : keys === key) {
          return newObject;
        }
        return { ...newObject, [key]: object[key] };
      }, {} as T)
  )(object, keys);

/**
 * findPropertyValueInsideObject - Search in object the key which contains value in the given property
 *
 * @param {object} object (ex: { a: { value: 1 }, b: { value: 2 } })
 * @param {string or number} property (ex: 'value')
 * @param {any} value (ex: 1)
 *
 * @returns {object} (ex: { value: 1 })
 */
export const findPropertyValueInsideObject = (object: any, property: number | string, value: number | string) => {
  const index = Object.keys(object).find((key: string) => object[key][property] === value);
  if (index) return object[index];
};

/**
 * **getAllPropertyValuesWhoseNameStartsWith** - Retrieves an object's properties which start with the provided string
 *
 * @param {object} object (ex: {paramOne: 1, paramTwo: 2, paramThree: 3})
 * @param {string} propertyNameStartsWith (ex: 'paramT')
 *
 * @return {array} the property names which start with **propertyNameStartsWith** (ex: [2, 3])
 */
export const getAllPropertyValuesWhoseNameStartsWith = (object: any, propertyNameStartsWith: string) =>
  getAllPropertyNamesStartingWith(object, propertyNameStartsWith).map((propName) => object[propName]);

/**
 * **getAllPropertyNamesStartingWith** - Retrieves an object's property names which start with the provided string
 *
 * @param {object} object (ex: {paramOne: true, paramTwo: false, paramThree: true})
 * @param {string} propertyNameStartsWith (ex: 'paramT')
 *
 * @return {array} the property names which start with **propertyNameStartsWith** (ex: ['paramTwo', 'paramThree'])
 */
export const getAllPropertyNamesStartingWith = (object: any, propertyNameStartsWith: string) =>
  Object.getOwnPropertyNames(object).filter((propName) => propName && propName.startsWith(propertyNameStartsWith));

/**
 * getPassedPropertyOrTheFirstOne - Try to retrieve the passed property. If it does not exist, it will try to
 *                                  retrieve the first property of the object. If the object is empty, it will return null.
 *
 * @param {object} object
 * @param {string or number} propertyName
 *
 * @return {any} the value contained in the property of the object
 */
export const getPassedPropertyOrTheFirstOne = (object: any, propertyName: string) => {
  if (object[propertyName]) {
    return object[propertyName];
  } else if (Object.keys(object).length) {
    const firstProperty = Object.keys(object)[0];
    return object[firstProperty];
  }
  return null;
};

/**
 * getProperty - Generates a function that retrieves an object's property called @param propertyName
 *
 * @param {integer or string} propertyName
 *
 * @return {function} which retrieves @param propertyName property of the passed object
 */
export const getProperty =
  (propertyName: string) =>
  (object: any = {}) =>
    object[propertyName];

/**
 * isEmpty - Checks if an object possesses any property
 *
 * @param {object} obj
 *
 * @return {boolean} true if @param obj is empty,
 *                   false otherwise
 */
export const isEmpty = (obj: any) => Object.keys(obj).length === 0;

/**
 * mapObjectValues - Maps each property of the object @param obj accordingly with the function @param fn
 *
 * @param {function} fn (ex: (value) => value * 10)
 * @param {object} obj (ex: {a: 1, b: 2, c: 3})
 *
 * @return {object} which possesses the same properties as @param obj but with values set accordingly with
 *                  the result of the original value passed in the function @param fn
 *                  (ex: {a: 10, b: 20, c: 30})
 */
export const mapObjectValues = (fn: (props: any) => void, obj: any) => {
  return Object.keys(obj).reduce((result: any, key: string) => {
    result[key] = fn(obj[key]); // eslint-disable-line no-param-reassign
    return result;
  }, {});
};

/**
 * objectToArray - Converts an object to an array
 *
 * @param {object} object (ex: {a: 1, b: 2, c: 3})
 *
 * @return {array} (ex: [1, 2, 3])
 */
export const objectToArray = <T>(object: Record<string, T>) => Object.values(object);

/**
 * pick - Generates a function responsible for returning the given object with just
 *        the given keys
 *
 * @param {array} keys array of strings which each element represents a property in @param object
 * @param {object} object
 *
 * @return {function} the new curried function responsible for returning the given object
 *                    with just the given keys
 */
export const pick = curry((keys: string[], object: any) =>
  keys.reduce((o: any, key: string) => {
    if (!object.hasOwnProperty(key)) {
      return o;
    }
    o[key] = object[key]; // eslint-disable-line no-param-reassign
    return o;
  }, {})
);

/**
 * pushValueToKey - Pushes @param value to the @param obj in the @param key property
 *
 * @param {object} obj (ex: {a: [1, 2, 3], b: [4, 5]})
 * @param {integer or string} key (ex: 'a')
 * @param {any} value (ex: 'dog')
 *
 * @return {object} (ex: {a: [1, 2, 3, 'dog'], b: [4, 5]})
 */
export const pushValueToKey = (obj: any, key: any, value: any) => {
  if (obj[key]) {
    obj[key].push(value);
  } else {
    // eslint-disable-next-line no-param-reassign
    obj[key] = [value];
  }
  return obj;
};

/**
 * reduceObject - Generates a curried function that reduces @param object according to the function @param reduceFn
 *                passing @param initialValue as initial value
 *
 * @param {object} object which one wants to reduce
 * @param {function} reduceFn reducing function
 * @param {any} initialValue initial value for the reducing function @param reduceFn
 *
 * @return {function} curried function that reduces @param object according to the function @param reduceFn
 *                    passing @param initialValue as initial value
 */
export const reduceObject = curry((object: any, reduceFn: () => void, initialValue: any) =>
  Object.keys(object)
    .map((key) => object[key])
    .reduce(reduceFn, initialValue)
);

/**
 * renameKey - Renames an object's property @param before to @param after
 *
 * @param {integer or string} before old property name which one wants to rename (ex: 'a')
 * @param {integer os string} after new property name (ex: 'b')
 * @param {object} obj (ex: {a: 1})
 *
 * @return {object} which is the @param obj with the property @param before renamed to @param after
 *                  ex: {b: 1}
 */
export const renameKey = curry((before: string, after: string, obj: any) => {
  if (!(before in obj)) {
    return obj;
  }
  const clone = { ...obj, [after]: obj[before] };
  delete clone[before];
  return clone;
});

/**
 * sameObject - Checks if two objects have the same @param keys property values
 *
 * @param {object} object1
 * @param {object} object2
 * @param {array} keys array of properties which one wants to use to compare the two given objects
 *
 * @return {boolean} true if @param object1 equals @param object2 comparing just the @param key properties,
 *                   false otherwise
 */
export const sameObject = (object1: any, object2: any, keys: string[]) => {
  let _object1: any = {};
  let _object2: any = {};

  if (keys && keys.length) {
    keys.forEach((key: string) => {
      _object1[key] = object1[key];
      _object2[key] = object2[key];
    });
  } else {
    _object1 = object1;
    _object2 = object2;
  }

  return deepEqual(_object1, _object2);
};

/**
 * setValueToKey - Sets @param value to @param key property of the @param obj
 *
 * @param {object} obj (ex: {a: 1, b: 2})
 * @param {integer or string} key (ex: 'a')
 * @param {any} value (ex: 3)
 *
 * @return {object} (ex: {a: 3, b: 2})
 */
export const setValueToKey = (obj: any, key: string, value: any) => {
  // eslint-disable-next-line no-param-reassign
  obj[key] = value;
  return obj;
};
