import PropTypes from 'prop-types';
import React from 'react';
import LoadingDots from './loading-dots';

const LoadingText = ({ hidden = false, children, className = '' }) => {
  const shownStyles = { opacity: 1, transition: 'all 100ms' };
  const hiddenStyles = { ...shownStyles, opacity: 0 };

  return (
    <div style={hidden ? hiddenStyles : shownStyles} className={className}>
      {children}
      <LoadingDots hidden={hidden} />
    </div>
  );
};

LoadingText.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default LoadingText;
