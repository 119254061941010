import React from 'react';

import { COLORS } from '../../../constants';
import translate, { getTranslationLanguage } from '../../../lib/translate';
import { date, duration, time } from '../../../utils';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';

const { formatIsoTime } = time;
const { formatDateDefault } = date;
const { formatIsoDuration } = duration;

const PeriodItem = ({ label, children }) => (
  <span className={css(periodItemStyles.container)}>
    <span className={css(periodItemStyles.label)}>{label}</span>
    <span className={css(periodItemStyles.children)}>{children}</span>
  </span>
);

const periodItemStyles = StyleSheet.create({
  container: {
    display: 'inline-block',
    margin: '10px',
    cursor: 'not-allowed',
    verticalAlign: 'top',
  },
  children: {
    textAlign: 'left',
    display: 'block',
    color: COLORS.GREY_MAIN,
    fontSize: '18px',
  },
  label: {
    color: COLORS.GREY_MEDIUM,
    textAlign: 'left',
    display: 'block',
    fontSize: '14px',
  },
});

export const CompletedTrackingScreen = ({ period }) => {
  return (
    <section className={css(styles.container)}>
      <h1 className={css(styles.heading)}>
        {translate('A time period is already present for the current time frame.')}
      </h1>
      <p>
        <PeriodItem label={translate('Date')}>
          {formatDateDefault(period.clockInTimestamp, getTranslationLanguage())}
        </PeriodItem>
        <PeriodItem label={translate('Start')}>{formatIsoTime(period.clockInTimestamp)}</PeriodItem>
        <PeriodItem label={translate('End')}>{formatIsoTime(period.clockOutTimestamp)}</PeriodItem>
        <PeriodItem label={translate('Break')}>{formatIsoDuration(period.breakDuration)}</PeriodItem>
        <PeriodItem label={translate('Note')}>
          <span className={css(styles.noteTruncation)} title={period.note}>
            {period.note}
          </span>
        </PeriodItem>
      </p>
      <p className={css(styles.info)}>
        {translate('You can track times again at {{time}}.', {
          time: formatIsoTime(period.clockOutTimestamp),
        })}
      </p>
    </section>
  );
};

const styles = StyleSheet.create({
  heading: {
    fontSize: '28px',
    color: COLORS.GREY_MAIN,
    marginBottom: '20px',
  },
  container: {
    width: '100%',
    textAlign: 'center',
  },
  info: {
    fontSize: '14px',
    color: COLORS.GREY_MAIN,
  },
  noteTruncation: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '6em',
    overflow: 'hidden',
  },
});

export default CompletedTrackingScreen;
