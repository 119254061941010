import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';

import { BREAKPOINTS, COLORS, FONT_STYLES, Z_INDEX } from '../../constants';
import t from '../../lib/translate';
import { dataTypes } from '../../utils';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import ButtonMaterialUi from './button-material-ui';
import Icon from './icon';

const { isFunction } = dataTypes;

export const ModalRefContext = React.createContext(() => {});

const dontPropagateEventToChildren = (preventClosing, fn) => (event) => {
  if (event.target === event.currentTarget && !preventClosing) {
    fn(event);
  }
};

class Modal extends React.Component {
  state = {
    isConfirmationDialogOpen: false,
  };

  constructor(props) {
    super(props);
  }
  static propTypes = {
    children: PropTypes.node.isRequired,
    confirmationMessageBeforeClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    displayConfirmationMessage: PropTypes.bool,
    fullscreen: PropTypes.bool,
    halfscreen: PropTypes.bool,
    isOpen: PropTypes.bool,
    onCancelConfirmation: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    padding: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    top: PropTypes.bool,
    whiteButton: PropTypes.bool,
    whiteBackground: PropTypes.bool,
  };

  onKeyDown = (event) => {
    if (this.props.isOpen && event.key === 'Escape' && !this.props.preventClosing) {
      if (!this.props.confirmationMessageBeforeClose) this.props.onClose();
      else this.setIsConfirmationDialogOpen(true);
    }
  };

  componentDidMount() {
    global.document.addEventListener('keydown', this.onKeyDown);
    if (this.props.preventBackgroundScrolling) {
      global.document.body.style.overflow = 'hidden';
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.displayConfirmationMessage && this.props.displayConfirmationMessage) {
      this.setIsConfirmationDialogOpen(true);
    }
  }

  componentWillUnmount() {
    global.document.removeEventListener('keydown', this.onKeyDown);
    if (this.props.preventBackgroundScrolling) {
      global.document.body.style.overflow = null;
    }
  }

  setIsConfirmationDialogOpen = (isConfirmationDialogOpen) => this.setState({ isConfirmationDialogOpen });

  render() {
    const {
      children,
      className,
      confirmationMessageBeforeClose,
      containerClassName,
      fullscreen,
      halfscreen,
      idForCloseButton,
      innerStyle = {},
      isOpen,
      onCancelConfirmation,
      onClose,
      padding,
      preventClosing,
      roundedBorders,
      showCloseButton,
      styles,
      top,
      visibleOverflow,
      whiteButton,
      wide,
      withBlur,
      withMinWidth = false,
      whiteBackground = false,
    } = this.props;
    const { isConfirmationDialogOpen } = this.state;

    const defaultStyles = { content: { ...styles } };

    const onCloseConfirmationDialog = () => {
      if (isFunction(onCancelConfirmation)) onCancelConfirmation();
      this.setIsConfirmationDialogOpen(false);
    };

    const onRequestClose = (event) => {
      if (!confirmationMessageBeforeClose) onClose(event);
      else this.setIsConfirmationDialogOpen(true);
    };

    return (
      <ModalRefContext.Consumer>
        {(setModalRef) => (
          <ReactModal
            ariaHideApp={false}
            className={`${css(
              isOpen ? backdropStyle.open : backdropStyle.closed,
              top && modalStyles.top
            )} ${containerClassName}`}
            isOpen={isOpen}
            onRequestClose={dontPropagateEventToChildren(preventClosing, onRequestClose)}
            overlayClassName={css(modalStyles.overlay, backdropStyle.default, withBlur && modalStyles.blur)}
            portalClassName={css(isOpen && backdropStyle.default)}
            style={defaultStyles}
          >
            <div
              ref={(ref) => {
                if (isOpen) setModalRef(ref);
              }}
              className={`${css(
                modalStyles.default,
                fullscreen && modalStyles.fullscreen,
                halfscreen && modalStyles.halfscreen,
                wide && modalStyles.wide,
                withMinWidth && modalStyles.withMinWidth,
                padding && modalStyles.padding,
                visibleOverflow && modalStyles.visibleOverflow,
                isOpen ? modalStyles.open : modalStyles.closed,
                roundedBorders && modalStyles.roundedBorders,
                whiteBackground && modalStyles.whiteBackground
              )} overflow-scrolling-fix ${className}`}
              style={{ ...styles, ...innerStyle }}
            >
              {showCloseButton && (
                <div
                  className={css(modalStyles.closeIcon, whiteButton && modalStyles.closeIconWhite)}
                  data-e2e-test="close-icon"
                >
                  <Icon id={idForCloseButton} name="close" onClick={onRequestClose} />
                </div>
              )}
              {isOpen && isConfirmationDialogOpen && (
                <div className={css(modalStyles.containerForConfirmationMessage)}>
                  <div className={css(modalStyles.confirmationMessageContainer)}>{confirmationMessageBeforeClose}</div>
                  <div className={css(modalStyles.buttonsContainer)}>
                    <ButtonMaterialUi
                      className={css(modalStyles.cancelButton)}
                      filled
                      label={t('Cancel')}
                      onClick={onCloseConfirmationDialog}
                    />
                    <ButtonMaterialUi label={t('Confirm')} onClick={onClose} />
                  </div>
                </div>
              )}
              {/* use display hidden to keep changes from cancel alert */}
              {isOpen && (
                <div className={css(isConfirmationDialogOpen && modalStyles.hidden, modalStyles.contentContainer)}>
                  {children}
                </div>
              )}
            </div>
          </ReactModal>
        )}
      </ModalRefContext.Consumer>
    );
  }
}

const backdropStyle = StyleSheet.create({
  default: {
    alignItems: 'center',
    background: 'rgba(0,0,0,0.2)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: Z_INDEX.OVERLAY,
  },
  open: {
    opacity: 1,
    transform: 'scale(1)',
    transition: 'opacity 200ms',
    ':focus': { outline: 0 },
  },
  closed: {
    opacity: 0,
    transform: 'scale(0)',
    transition: 'opacity 200ms, transform 0ms 200ms',
  },
});

const modalStyles = StyleSheet.create({
  blur: {
    backdropFilter: 'blur(6px)',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    marginRight: '30px !important',
  },
  confirmationMessageContainer: {
    ...FONT_STYLES.base,
    fontSize: 16,
    marginBottom: 30,
  },
  containerForConfirmationMessage: {
    backgroundColor: COLORS.BACKGROUND,
    borderRadius: 5,
    padding: 40,
  },
  default: {
    alignSelf: 'center',
    backgroundColor: COLORS.EGGSHELL_MAIN,
    maxHeight: '95vh',
    overflow: 'auto',
    position: 'relative',
    transition: 'all 200ms',
    [BREAKPOINTS.mobilePortraitMode]: {
      width: '90vw',
      overflow: 'scroll',
    },
    [BREAKPOINTS.ipadPortraitModeMax]: {
      overflow: 'scroll',
    },
    [BREAKPOINTS.smallestMobilePortraitModeMax]: {
      width: '100vw',
      overflow: 'scroll',
    },
  },
  fullscreen: {
    height: '80vh',
    maxWidth: '980px',
    width: '95vw',
  },
  hidden: {
    display: 'none',
  },
  top: {
    alignItems: 'flex-start',
    paddingTop: '20px',
  },
  open: {
    opacity: 1,
    transform: 'scale(1) translateX(0)',
  },
  closed: {
    opacity: 0,
    transform: 'scale(0.5) translateY(100px)',
  },
  closeIcon: {
    color: COLORS.ORANGE_MAIN,
    fontSize: '30px',
    position: 'absolute',
    right: '20px',
    top: '20px',
    zIndex: Z_INDEX.OVERLAY,
  },
  closeIconWhite: {
    background: COLORS.WHITE,
    borderRadius: '50%',
    boxShadow: '0 0 20px rgba(0,0,0,0.3)',
    height: '1.2em',
    lineHeight: 1.2,
    width: '1.2em',
  },
  contentContainer: {
    height: '100%',
  },
  padding: {
    padding: '20px',
  },
  halfscreen: {
    maxWidth: '980px',
  },
  wide: {
    width: '80vw',
  },
  withMinWidth: {
    minWidth: '50vw',
  },
  visibleOverflow: {
    overflow: 'visible',
  },
  roundedBorders: {
    borderRadius: 8,
  },
  overlay: {
    zIndex: 0,
  },
  whiteBackground: {
    backgroundColor: COLORS.WHITE,
  },
});

export default Modal;
