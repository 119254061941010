import url from 'url';

const urlDefaults = {
  protocol: 'https',
};

let theUglyStaticUrlPrefix = '';
export const buildUrl = (urlPath) => theUglyStaticUrlPrefix + urlPath;
export const update = (urlObj) => {
  theUglyStaticUrlPrefix = url.format({ ...urlDefaults, ...urlObj });
};
