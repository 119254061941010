import fetch from './fetch';
import { NetworkError } from '../../error';
import { INVALID_CSRF_TOKEN } from '../v2/status-codes';

export default class Headers {
  cookie = '';
  csrfToken = '';
  xLanguage = global.__CREWMEISTER_LANGUAGE;
  xCmChannel = '';

  setHeaders({ cookie, csrfToken, xLanguage, xCmChannel }) {
    if (cookie) {
      this.cookie = cookie;
    }

    if (csrfToken) {
      this.csrfToken = csrfToken;
    }

    if (xLanguage) {
      this.xLanguage = xLanguage;
    }

    if (xCmChannel) {
      this.xCmChannel = xCmChannel;
    }
  }

  toJson() {
    const headers = {
      Cookie: this.cookie,
      'X-CSRF-Token': this.csrfToken,
    };

    if (this.xLanguage) {
      headers['X-Language'] = this.xLanguage;
    }

    if (this.xCmChannel) {
      headers['X-cmChannel'] = this.xCmChannel;
    }

    const removeEmptyValues = (previous, key) => {
      if (headers[key] === '') return previous;
      return { ...previous, [key]: headers[key] };
    };

    const initialValue = {};
    return Object.keys(headers).reduce(removeEmptyValues, initialValue);
  }

  toSerializable() {
    const data = {
      cookie: this.cookie,
      csrfToken: this.csrfToken,
    };

    if (this.xLanguage) {
      data['X-Language'] = this.xLanguage;
    }

    if (this.xCmChannel) {
      data['X-cmChannel'] = this.xCmChannel;
    }

    return JSON.stringify(data);
  }

  fromSerializable(serializedString) {
    try {
      const deserialized = JSON.parse(serializedString);
      this.cookie = deserialized.cookie;
      this.csrfToken = deserialized.csrfToken;
      this.xLanguage = deserialized.xLanguage;
    } catch (e) {
      throw Error(`Error deserializing context. (${e.message})`);
    }
  }
}

export function extractCsrfTokenFromSite(content) {
  try {
    return content.match(/<.*name="csrf-token".*\/>/)[0].match(/content="(.*?)"/)[1];
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
}

const processCsrfToken = (headers) =>
  headers.then((res) => res.text()).then((htmlSourceCode) => extractCsrfTokenFromSite(htmlSourceCode));

export function extractCrewmeisterCookie(setCookie) {
  if (setCookie) {
    const parts = setCookie.split(';');
    const cookies = parts.filter((part) => part.startsWith('_crewmeister='));
    return cookies[0] || '';
  }
  return '';
}

const processCookie = (headers) => headers.then((res) => extractCrewmeisterCookie(res.headers.get('set-cookie')));

export class FetchHeaders {
  constructor() {
    this.fetchingStatus = 'NOT_FETCHED';
    this.fetchingPromise = null;
  }

  _fetchHeaders() {
    this.fetchingStatus = 'FETCHING';
    const fetchedHeaders = fetch.csrfToken();

    // Save the Promise, so that it can be passed to the subsequent API calls to wait on!
    this.fetchingPromise = Promise.all([processCsrfToken(fetchedHeaders), processCookie(fetchedHeaders)]);
    return this.fetchingPromise
      .then(([csrfToken, cookie]) => ({ csrfToken, cookie }))
      .catch(() => {
        this.fetchingStatus = 'NOT_FETCHED';
        throw new NetworkError('Can not retrieve CSRF Token', INVALID_CSRF_TOKEN);
      });
  }

  fetchHeadersIfRequired() {
    if (this.fetchingStatus === 'FETCHING') {
      // Request is being fetched, wait for that promise to resolve
      return this.fetchingPromise
        .then(() => ({ dontProcess: true }))
        .catch(() => {
          throw new NetworkError('Can not retrieve CSRF Token', INVALID_CSRF_TOKEN);
        });
    }
    // Prevent user from being logged out if the csrf token call fails the first time
    return (
      this._fetchHeaders()
        .then((headers) => {
          this.fetchingStatus = 'FETCHED';
          this.fetchingPromise = null;
          return headers;
        })
        // We mock the csrfToken and cookie values to '' as that is the value being checked for the token when deciding
        // that this function should be called further. We set dontProcess to true as this means that in the case the endpoint fails,
        // we won't set the headers, but instead take the old values.
        .catch(() => {
          const headers = {
            csrfToken: '',
            cookie: '',
            dontProcess: true,
          };
          return headers;
        })
    );
  }
}
