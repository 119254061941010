import { DateString } from './dateString';

export enum FileExtension {
  XLSX = 'xlsx',
  ZIP = 'zip',
}

export enum FileCategory {
  SPREADSHEET = 'spreadsheet',
  SPREADSHEET_ZIPPED = 'spreadsheet-zipped',
  SPREADSHEET_ZIPPED_V3 = 'spreadsheet_zipped',
}

export enum FileProcessStatus {
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
}

export type FileDetailsType = {
  id: number;
  crewId: number;
  userId: number;
  status: FileProcessStatus;
  category: FileCategory;
  storageIdName: string;
  name: string;
  extension: FileExtension;
  expireAt: DateString;
  updatedAt: DateString;
  createdAt: DateString;
};
