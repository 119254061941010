/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
/* eslint-disable max-len */
import { buildDangerouslyTranslateNeverWithUserContent, buildScopedTranslationFunction } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    cancel: 'Abbrechen',
    enter4DigitPin: 'Bitte gebe deine 4 stellige Pin ein um loszulegen:',
    forgotYourPin: 'Pin vergessen?',
    goodAfternoon: 'Guten Tag!',
    goodEvening: 'Guten Abend!',
    goodMorning: 'Guten Morgen!',
    inactivity: 'Aufgrund Inaktivität werden Sie in Kürze automatisch ausgeloggt.',
    openFullApplication: 'Zur vollen Anwendung wechseln',
    searchUserHeading: 'Bitte wählen Sie Ihren Account in der Liste aus',
    searchUserPlaceHolder: 'Suchen...',
  },
  en: {
    cancel: 'Cancel',
    enter4DigitPin: 'Please enter your 4-digit PIN to get started:',
    forgotYourPin: 'Forgot your pin?',
    goodAfternoon: 'Good Afternoon!',
    goodEvening: 'Good Evening!',
    goodMorning: 'Good Morning!',
    inactivity: 'Due to inactivity you will be automatically signed out shortly.',
    openFullApplication: 'Open the full Crewmeister App',
    searchUserHeading: 'Please select your account from the list:',
    searchUserPlaceHolder: 'Search...',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
