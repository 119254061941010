import { ICON_NAMES, NEWS_TYPES } from '../constants';

export const newsTypeIcons = (type) => {
  switch (type) {
    case NEWS_TYPES.TIME_TRACKING:
      return ICON_NAMES.TIME_TRACKING;
    case NEWS_TYPES.SHIFT_PLANNER:
      return ICON_NAMES.SHIFT_PLANNER;
    case NEWS_TYPES.ABSENCE_MANAGEMENT:
      return ICON_NAMES.ABSENCE_MANAGEMENT;
    case NEWS_TYPES.TIME_EVALUATION:
      return ICON_NAMES.FILE_EXCEL;
    default:
      return ICON_NAMES.ALARM;
  }
};
