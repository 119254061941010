import { cloneDeep } from 'lodash';

/**
 * clone - Generates an identical object/array/element of the one passed but with no reference
 *         between them
 *
 * @param {T} element
 *
 * @return {T}
 */
export const clone = <T>(element: T) => cloneDeep(element) as T;

/**
 * compose - Aligns a set of functions in ascending order so one can call them in order at
 *           once with interdependent params
 *
 * @param {...functions} fns multiple functions passed each one as a param (fn1, fn2, fn3)
 *
 * @return {function} of which one can pass the initial value to and then all
 *                    the functions passed previously will get called one by one in ascending order
 *                    where the result of the previous one called will serve as the param
 *                    for the next one
 *                    ex: fn3(fn2(fn1(initialValue)))
 */
type ArityOneFn = (arg: any) => any;
type PickLastInArray<T extends any[]> = T extends [...rest: infer U, argn: infer L] ? L : never;
// @ts-ignore
type LastFnReturnType<T extends any[]> = ReturnType<PickLastInArray<T>>;
// @ts-ignore
type FirstFnReturnType<T extends any[]> = ReturnType<T[0]>;
// type FirstFnParameterType<T extends any[]> = Parameters<PickLastInArray<T>>[any];

export const compose =
  <T extends ArityOneFn[]>(...fns: T) =>
  (initialValue: any): LastFnReturnType<T> =>
    fns.reduce((previous, fn) => fn(previous), initialValue);

/**
 * composeReverse - Aligns a set of functions in descending order so one can call them in order at
 *                  once with an interdependent param
 *
 * @param {...functions} fns multiple functions passed each one as a param (fn1, fn2, fn3)
 *
 * @return {function} of which one can pass the initial value to and then all
 *                    the functions passed previously will get called one by one in descending
 *                    order where the result of the previous one called will serve as the param
 *                    for the next one
 *                    ex: fn1(fn2(fn3(initialValue)))
 */
export const composeReverse =
  <T extends ArityOneFn[]>(...fns: T) =>
  (initialValue: any): FirstFnReturnType<T> =>
    fns.reduceRight((previous, fn) => fn(previous), initialValue);

/**
 * pipe - Aligns a set of functions in ascending order with interdependent params and call them
 *        passing as the first param the @param initialValue
 *
 * @param {any} initialValue which will serve as the param for the first function called
 * @param {...functions} fns multiple functions passed each one as a param (fn1, fn2, fn3)
 *
 * @return {any} the result of the call of the generated function created by the alignment of all
 *               the functions passed in @param fns called one by one in ascending order where the
 *               result of the previous one called will serve as the param for the next one
 *               ex: fn1(fn2(fn3(initialValue)))
 */
export const pipe = (initialValue: unknown, ...fns: Function[]) =>
  fns.reduce((previous, fn) => fn(previous), initialValue);
