import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadRoundingRules = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_ROUNDING_RULES',
        fnParams: { crewId },
        fn: (params) => api.roundingRules(params),
      })
);
