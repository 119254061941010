/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction, buildDangerouslyTranslateNeverWithUserContent } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    closeWindow: 'Fenster schließen',
    enterUserApp: 'Zur vollen Anwendung wechseln',
    inactivity: 'Aufgrund Inaktivität werden Sie in Kürze automatisch ausgeloggt.',
    leaveTerminalMode: 'Terminalmodus verlassen',
  },
  en: {
    closeWindow: 'Close window',
    enterUserApp: 'Enter the full crewmeister app',
    inactivity: 'Due to inactivity you will be automatically signed out shortly.',
    leaveTerminalMode: 'Leave Terminal Mode',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
