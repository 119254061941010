export function ApiError(message, id, errors) {
  this.name = this.constructor.name;
  this.message = message;
  this.id = id;
  this.errors = errors;

  this.equals = (_id) => this.id === _id;
  this.isOneOf = (klass) => this instanceof klass;
}
ApiError.prototype = new Error();

export class NetworkError extends ApiError {
  // This is a special error, that can only occur when using
  // this library, therefore it is OK to have this special error class.
}
