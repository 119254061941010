import React from 'react';
import { StyleSheet, css } from '../../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES } from '../../../constants';
import IconTooltip from '../../../components/core/icon-tooltip';
import t from '../translate';

export const AboutCrewSignIn = () => (
  <div className={css(styles.wrapper)}>
    <p className={css(styles.heading)}>{t('info')}</p>
    <IconTooltip iconName="info" bigText>
      <div className={css(styles.marginBottom)}>{t('help')}</div>
      <div>{t('exit')}</div>
    </IconTooltip>
  </div>
);

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    margin: 'auto',
    alignItems: 'center',
  },
  heading: {
    ...FONT_STYLES.medium,
    color: COLORS.BROWN_MAIN,
    margin: 0,
  },
  marginBottom: {
    marginBottom: 12,
  },
});
