/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction } from './translate';

const errorsTranslations = buildScopedTranslationFunction({
  de: {
    memberDisabledInTimespan: `
      Für Zeiträume, in denen Mitarbeiter inaktiv waren, können keine Änderungen gespeichert werden.
    `,
  },
  en: {
    memberDisabledInTimespan: `
      You cannot save changes for times in which an employee was inactive.
    `,
  },
});

export default errorsTranslations;
