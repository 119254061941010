import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../../lib';
import { onEnterTerminal } from './ui-actions';

export const loadTerminalCrew = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_TERMINAL_CREW',
        fnParams: { crewId: Number.parseInt(crewId, 10) },
        fn: async (params) => {
          await dispatch(onEnterTerminal({ api }));
          return api.getCrewNew(params);
        },
      })
);
