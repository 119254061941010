import PropTypes from 'prop-types';
import React from 'react';

class AutoRefreshTimeout extends React.Component {
  constructor(props) {
    super(props);
    this.activeUpdate(this.props);
  }

  activeUpdate(props) {
    if (props.active) {
      this.interval = setInterval(props.callback, props.seconds * 1000);
    } else {
      this.interval = clearInterval(this.interval);
    }
  }

  /* eslint-disable-next-line camelcase*/
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.props.active) {
      this.activeUpdate(nextProps);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return false;
  }
}

AutoRefreshTimeout.propTypes = {
  callback: PropTypes.func.isRequired,
  seconds: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
};

export default AutoRefreshTimeout;
