import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib/dispatch-async-fn-status-actions';

export const loadAbsenceBalances = memoize(
  {},
  ({ api, crewId, date, typeId, userIds }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_ABSENCE_BALANCES',
        fnParams: { crewId, date, typeId, ...(userIds ? { userIds } : {}) },
        fn: (params) => api.getAbsenceBalances(params),
      })
);
