import React, { useState } from 'react';
import { event as eventUtils } from '../../utils';

import { COLORS, FONT_STYLES, ICON_NAMES } from '../../constants';
import { FC } from '../../types';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import Icon from './icon';

const { debounceEvent } = eventUtils;

type SearchV2Props = {
  className?: string;
  id?: string;
  inOrange?: boolean;
  onChange?: (value: string) => void;
  placeholder: string;
};

const SearchInput: FC<SearchV2Props> = ({ className, id, inOrange, onChange, placeholder }) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const debouncedOnChange = debounceEvent((value) => {
    if (onChange) return onChange(value);
  }, 200);

  return (
    <div className={`${css(styles.wrapper)} ${className}`}>
      <div className={css(styles.inputWrapper)}>
        {!(hasFocus || inputValue) && <div className={css(inOrange && styles.placeholderInOrange)}>{placeholder}</div>}
        <input
          id={id}
          type="text"
          className={css(styles.input, inOrange && styles.inputInOrange)}
          onClick={() => setHasFocus(true)}
          onBlur={debounceEvent(() => setHasFocus(false), 200)}
          onChange={(evt) => {
            const { value } = evt.target;
            if (onChange) {
              debouncedOnChange(value);
            }
            setInputValue(value);
          }}
        />
      </div>
      <Icon name={ICON_NAMES.SEARCH} className={css(styles.icon, inOrange && styles.iconInOrange)} />
    </div>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...FONT_STYLES.small,
    color: COLORS.GREY_DARK,
    fontSize: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    position: 'relative',
    maxWidth: '100%',
  },
  placeholderInOrange: {
    color: COLORS.ORANGE_MEDIUM_LIGHT,
  },
  icon: {
    color: COLORS.GREY_DARK,
    fontSize: 24,
    marginLeft: 16,
  },
  iconInOrange: {
    color: COLORS.ORANGE_MAIN,
  },
  inputWrapper: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    content: '""',
    alignItems: 'center',
    cursor: 'text',
  },
  input: {
    ...FONT_STYLES.small,
    color: COLORS.GREY_DARK,
    fontSize: 16,
    backgroundColor: 'transparent',
    position: 'absolute',
    outline: 0,
    width: '100%',
    minWidth: '0',
    paddingTop: '0',
    borderWidth: 0,
    borderRadius: 0,
    height: '100%',
  },
  inputInOrange: {
    color: COLORS.ORANGE_DARK,
    fontWeight: 600,
    letterSpacing: 1.2,
  },
});

export default SearchInput;
