import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../constants';
import { FileDetailsType } from '../types';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;

interface FileDetailsState {
  files: FileDetailsType[];
}

const initialState: FileDetailsState = {
  files: [],
};

export const filesReducer = (
  state = initialState,
  action: AnyAction & { crewId: number; result: FileDetailsType[] }
): FileDetailsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${ACTION_NAMES.LOAD_LIST_FILES}_SUCCESS`:
        applyMinimalChangesToArray(draft.files, action.result, (file) => file.crewId === action.crewId);
        break;

      default:
    }
  });
