/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction, buildDangerouslyTranslateNeverWithUserContent } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    cancel: 'Abbrechen',
    submitFailed: 'Anmelden fehlgeschlagen. Bitte Login und Passwort überprüfen.',
  },
  en: {
    cancel: 'Cancel',
    submitFailed: 'Sign-in failed. Wrong credentials?',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
