import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ timeCategories: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const timeCategoriesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_TIME_CATEGORIES_SUCCESS':
        applyMinimalChangesToArray(
          draft.timeCategories,
          action.result,
          (timeCategory) => timeCategory.crewId === action.crewId
        );
        break;
      case 'CREATE_TIME_CATEGORY_SUCCESS':
      case 'UPDATE_TIME_CATEGORY_SUCCESS':
      case 'ENABLE_TIME_CATEGORY_SUCCESS':
      case 'DISABLE_TIME_CATEGORY_SUCCESS':
        applyMinimalChangesToArray(
          draft.timeCategories,
          action.result,
          (timeCategory) =>
            timeCategory.crewId === action.crewId &&
            timeCategory.id === action.id &&
            timeCategory.level === action.level
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
