import { factory, trait, sequence, random } from 'stahlwerk';
import { addDays, eachDayOfInterval } from 'date-fns';
import { ABSENCE_TYPES } from '../constants';
import { createAbsenceDay } from './absence-day';
import { formatIso8601Date } from '../utils/date';

export const createAbsence = factory({
  id: sequence(),
  typeId: random([1, 2, 3]),
  startFraction: random([1, 2]),
  endFraction: random([1, 2]),
  startDayPart: random(['morning', 'afternoon']),
  endDayPart: random(['morning', 'afternoon']),
  startDate: formatIso8601Date(addDays(new Date(), 1)),
  endDate: formatIso8601Date(addDays(new Date(), 7)),
  userId: sequence(),
  crewId: sequence(),
  confirmedAt: null,
  rejectedAt: null,

  memberNote: '7 Tage Schützenfest.',
  admitterNote: void 0,

  halfDayMorning: trait({
    startFraction: 0,
    endFraction: 2,
    startDayPart: 'morning',
    endDayPart: 'morning',
    startDate: formatIso8601Date(new Date()),
    endDate: formatIso8601Date(new Date()),
  }),
  halfDayAfternoon: trait({
    startFraction: 2,
    endFraction: 0,
    startDayPart: 'afternoon',
    endDayPart: 'afternoon',
    startDate: formatIso8601Date(new Date()),
    endDate: formatIso8601Date(new Date()),
  }),
  singleDay: trait({
    startFraction: 2,
    endFraction: 2,
    startDayPart: 'morning',
    endDayPart: 'afternoon',
    startDate: formatIso8601Date(new Date()),
    endDate: formatIso8601Date(new Date()),
  }),
  granted: trait({
    confirmedAt: formatIso8601Date(new Date()),
    admitterNote: "I'll join you.",
  }),
  rejected: trait({
    rejectedAt: formatIso8601Date(new Date()),
    admitterNote: 'I heard Schützenfest was canceled =(',
  }),
  pending: trait({
    confirmedAt: null,
    rejectedAt: null,
  }),
  yesterday: trait({
    startDate: formatIso8601Date(addDays(new Date(), -1)),
    endDate: formatIso8601Date(addDays(new Date(), -1)),
  }),
  vacation: trait({
    typeId: ABSENCE_TYPES.vacation,
  }),
  sickness: trait({
    typeId: ABSENCE_TYPES.sickness,
  }),
  compensatoryTimeOff: trait({
    typeId: ABSENCE_TYPES.compensatoryTimeOff,
  }),
  withAbsenceDays: trait({
    absenceDays: eachDayOfInterval({ start: addDays(new Date(), 1), end: addDays(new Date(), 7) }).map((date) =>
      createAbsenceDay({ date: formatIso8601Date(date) })
    ),
  }),
});
