import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadWorkplaces = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_WORKPLACES',
        fnParams: { crewId },
        fn: (params) => api.getWorkplaces(params),
      })
);

export const updateWorkplace =
  ({ api, crewId, workplaceId, name, disabledAt, groupId }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'UPDATE_WORKPLACE',
      fnParams: {
        crewId: Number.parseInt(crewId, 10),
        workplaceId,
        groupId,
        ...((name || name === '') && { name }),
        disabledAt: disabledAt ? new Date().toISOString() : null,
      },
      fn: (params) => api.updateWorkplace(params),
    });

export const createWorkplace =
  ({ api, crewId, name }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'CREATE_WORKPLACE',
      fnParams: { crewId: Number.parseInt(crewId, 10), name },
      fn: (params) => api.createWorkplace(params),
    });
