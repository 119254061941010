import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ roundingRules: [] }, () => {});

const actionResultWithCrewId = (action) =>
  action.result.map((resultItem) => ({ ...resultItem, crewId: action.crewId }));

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const roundingRulesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_ROUNDING_RULES_SUCCESS':
        applyMinimalChangesToArray(
          draft.roundingRules,
          actionResultWithCrewId(action),
          (rule) => rule.crewId === action.crewId
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
