import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { asyncComponent } from 'react-async-component';
import React from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';

class Template extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (deepEqual(this.props.markers, nextProps.markers)) {
      return null;
    }
    return true;
  }

  componentDidMount() {
    if (this.map) {
      this.map.scrollWheelZoom.disable();
      global.document.addEventListener('onMarkerClick', this.handleMarkerClick);
    }
  }

  handleMarkerClick = ({ detail }) => {
    const [lat, lng] = detail.location.replace(/\/$/, '').split(/(?=[+-])/);
    this.map.flyTo([lat, lng], 17);
  };

  componentWillUnmount() {
    global.document.removeEventListener('onMarkerClick', this.handleMarkerClick);
  }

  render() {
    const { markers = [] } = this.props;
    const { Popup, Map, Marker, MarkerClusterGroup, center } = this.props.components;
    return (
      <Map
        onRef={(map) => {
          this.map = map;
        }}
        className={`${css(styles.map)} ${this.props.className}`}
        center={center}
        markers={markers}
        noConnectionLineBetweenMarkers={this.props.noConnectionLineBetweenMarkers}
      >
        <MarkerClusterGroup maxClusterRadius="30">
          {markers.map((pin, index) => (
            <Marker
              id={this.props.id}
              index={index}
              default
              position={[pin.lat, pin.lng]}
              color={pin.color}
              label={pin.label}
              key={index}
              image={pin.image}
              username={pin.username}
            >
              <Popup>
                <span>{pin.description}</span>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </Map>
    );
  }
}

Template.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export const styles = StyleSheet.create({
  map: {
    width: '100%',
    height: '300px',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 0, // Fixes https://github.com/crewmeister/server-app/issues/2101
  },
  // adapted from https://github.com/YUzhva/react-leaflet-markercluster/blob/563e543dd3c5c09d8ea2091f4e9ec9434b20df6a/dist/styles.css
  globals: {
    '*.marker-cluster-small': {
      backgroundColor: 'rgba(181, 226, 140, 0.6)',
    },
    '*.marker-cluster-small div': {
      backgroundColor: 'rgba(110, 204, 57, 0.6)',
    },
    '*.marker-cluster-medium': {
      backgroundColor: 'rgba(241, 211, 87, 0.6)',
    },
    '*.marker-cluster-medium div': {
      backgroundColor: 'rgba(240, 194, 12, 0.6)',
    },
    '*.marker-cluster-large': {
      backgroundColor: 'rgba(253, 156, 115, 0.6)',
    },
    '*.marker-cluster-large div': {
      backgroundColor: 'rgba(241, 128, 23, 0.6)',
    },
    '*.marker-cluster': {
      backgroundClip: 'padding-box',
      borderRadius: 20,
    },
    '*.marker-cluster div': {
      width: 30,
      height: 30,
      marginLeft: 5,
      marginTop: 5,
      textAlign: 'center',
      borderRadius: 15,
      font: '12px "Helvetica Neue", Arial, Helvetica, sans-serif',
    },
    '*.marker-cluster span': {
      lineHeight: '30px',
    },
    '*.leaflet-cluster-anim .leaflet-marker-icon': {
      transition: 'transform 0.3s ease-out, opacity 0.3s ease-in',
    },
    '*.leaflet-cluster-anim .leaflet-marker-shadow': {
      transition: 'transform 0.3s ease-out, opacity 0.3s ease-in',
    },
    '*.leaflet-cluster-spider-leg': {
      /* stroke-dashoffset (duration and function) should match with
      leaflet-marker-icon transform in order to track it exactly */
      transition: 'stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in',
    },
  },
});

// Prevent the map to only render half of the container.
css(styles.map);
css(styles.globals);

export default asyncComponent({
  resolve: async () => {
    const components = await import('../../_external-deps/map');
    return { default: (props) => <Template {...props} components={components} /> };
  },
});
