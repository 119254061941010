import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, FONT_STYLES } from '../../constants';

const ButtonOrLink = ({ id, color = COLORS.ORANGE_MAIN, customStyle = '', ...props }) => {
  const style = {
    ...(props.isSelected && color ? { borderColor: color, color: color } : {}),
    ...(props.level ? { paddingLeft: (props.level + 1) * 20 } : {}),
  };

  return (
    <Choose>
      <When condition={props.href}>
        <NavLink
          id={id}
          style={style}
          children={props.children}
          to={props.href}
          className={css(styles.item, props.vertical ? styles.verticalItem : styles.horizontalItem)}
          activeClassName={css(styles.active)}
          exact
        />
      </When>
      <Otherwise>
        <button
          id={id}
          children={props.children}
          type="button"
          onClick={props.onClick}
          style={style}
          className={css(
            styles.item,
            props.vertical ? styles.verticalItem : styles.horizontalItem,
            props.isSelected && styles.active,
            customStyle
          )}
        />
      </Otherwise>
    </Choose>
  );
};

ButtonOrLink.propTypes = {
  isSelected: PropTypes.bool,
  color: PropTypes.string,
  level: PropTypes.number,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  item: {
    ...FONT_STYLES.medium,
    fontWeight: '700',
    border: 'none',
    padding: '3px 23px',
    textAlign: 'left',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    borderRadius: 0,
    width: '100%',
    ':hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    ':focus': {
      outline: 'none',
    },
  },
  verticalItem: {
    borderLeft: '3px solid transparent',
  },
  horizontalItem: {
    borderBottom: '3px solid transparent',
  },
});

export default ButtonOrLink;
