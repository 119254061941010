/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction, buildDangerouslyTranslateNeverWithUserContent } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    offline: `
      <b>Sie sind offline</b>: Ihre Verbindung zum Internet ist unterbrochen.
    Wir verbinden Sie erneut mit Crewmeister sobald die Verbindung wieder hergestellt wurde`,
  },
  en: {
    offline: `
    <b>You are offline</b>: Your connection to the internet has been interrupted.
    We will connect you to crewmeister again as soon as the connection has been re-established`,
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
