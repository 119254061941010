import React from 'react';

import Button from '../../../components/core/button';

import { COLORS, FONT_STYLES, IDS_FOR_GOOGLE_TAG_MANAGER } from '../../../constants';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';
import t from '../translate';

const OverlongPeriod = ({ id, onStopWork }) => (
  <div className={css(styles.wrapper)}>
    <section>
      <h1 className={css(styles.heading)}>{t('overlongPeriod')}</h1>
      <Button id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.STOP_WORK} onClick={onStopWork} primary inline>
        <span>{t('stopWork')}</span>
      </Button>
    </section>
  </div>
);

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    textAlign: 'center',
  },
  heading: {
    ...FONT_STYLES.medium,
    color: COLORS.GREY_MAIN,
    marginBottom: '20px',
  },
});
export default OverlongPeriod;
