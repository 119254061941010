import produce from 'immer';
import { createSelector } from 'reselect';

import { selectCurrentCrewId } from '../crews-selectors';
import { createPropsSelector, createPropsFinder } from '../utils';

import { V3Workplace } from '../../types';

export const v3SelectWorkplaces = (state: any): V3Workplace[] =>
  (state.boV3.workplaces && state.boV3.workplaces.workplaces) || produce([], () => {});

export const v3SelectWorkplacesForCurrentCrew = createSelector(
  v3SelectWorkplaces,
  selectCurrentCrewId,
  (workplaces, crewId) => workplaces.filter((workplace: V3Workplace) => !!workplace && workplace.crewId === crewId)
);

export const v3SelectWorkplacesByProps = createPropsSelector(v3SelectWorkplacesForCurrentCrew);

export const v3FindWorkplaceByProps = createPropsFinder(v3SelectWorkplacesForCurrentCrew);
