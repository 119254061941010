import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_AVATAR } from '../../constants';

class Image extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string,
  };
  defaultFallbackSrc = DEFAULT_AVATAR;

  constructor(props) {
    super(props);
    this.state = { loadingError: false };
  }

  registerImage = (domElem) => {
    if (domElem) {
      domElem.addEventListener('error', this.setLoadingError);
      this.ref = domElem;
    }
  };

  setLoadingError = () => {
    this.setState({ loadingError: true });
  };

  componentWillUnmount() {
    if (this.ref) {
      this.ref.removeEventListener('error', this.setLoadingError);
    }
  }

  render() {
    const { src, ...props } = this.props;
    const fallbackSrc = this.props.fallbackSrc || this.defaultFallbackSrc;

    const imageSrc = this.state.loadingError ? fallbackSrc : src;

    return <img ref={this.registerImage} src={imageSrc} {...props} />;
  }
}

export default Image;
