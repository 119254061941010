import { CrewmeisterApiV3 } from '../../apiV3';

export enum POST_PROCESS_FUNCTION_NAME {
  SET_USER_TOKEN = 'SET_USER_TOKEN',
  REMOVE_USER_TOKEN = 'REMOVE_USER_TOKEN',
  SET_CREW_TOKEN = 'SET_CREW_TOKEN',
  REMOVE_CREW_TOKEN = 'REMOVE_CREW_TOKEN',
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum RSQL_OPERATOR {
  EQUALS = '==',
  GREATER_THAN_OR_EQUALS = '>=',
  LESSER_THAN_OR_EQUALS = '<=',
}

export type Resource = Record<string, any>;

export type PatchResource = {
  operation: HTTP_METHOD;
  resource: Resource;
};

export interface Filter {
  [index: string]: number | Date | undefined;
  id?: number;
  crewId?: number;
  userId?: number;
  from?: Date;
  to?: Date;
}

export interface PageContext {
  [index: string]: number | undefined;
  page?: number;
  pageSize?: number;
}

export interface ServiceMethods {
  getOne: (id: number | string, async?: boolean) => Promise<unknown>;
  get: (
    filter: Filter,
    operators: Record<string, RSQL_OPERATOR>,
    pageContext?: PageContext,
    async?: boolean,
    sortBy?: string
  ) => Promise<unknown>;
  createOne: (resource: Resource, async?: boolean) => Promise<unknown>;
  create: (resources: Resource[], async?: boolean) => Promise<unknown>;
  patchOne: (id: number, patchData: Resource, async?: boolean) => Promise<unknown>;
  patch: (patchData: PatchResource[], async?: boolean) => Promise<unknown>;
  putOne: (id: number, resource: Resource, async?: boolean) => Promise<unknown>;
  put: (resources: Resource[], async?: boolean) => Promise<unknown>;
  deleteOne: (id: number, async?: boolean) => Promise<unknown>;
  delete: (ids: number[], async?: boolean) => Promise<unknown>;
}

export interface v3DefaultAction {
  apiV3: CrewmeisterApiV3;
}

export enum ERROR_CODES {
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
  OUT_OF_RANGE = 'OUT_OF_RANGE',
}

export enum ERROR_REASONS {
  INCOMPATIBLE = 'INCOMPATIBLE',
  NOT_EXISTING_OR_NO_PERMISSIONS = 'NOT_EXISTING_OR_NO_PERMISSIONS',
  TOO_BIG = 'TOO_BIG',
  UNAVAILABLE = 'UNAVAILABLE',
}
