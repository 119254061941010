import { factory, trait, sequence, random } from 'stahlwerk';
import { parseISO } from 'date-fns';
import { DAY_KINDS } from '../constants';

export const createDayKind = factory({
  dayKindId: sequence(),
  offset: random([0, 2]),
  fraction: random([1, 2]),
  date: parseISO(new Date()),
  userId: sequence(),
  crewId: sequence(),

  additionalData: {
    name: 'Christmas',
  },

  halfDayMorning: trait({
    offset: 0,
    fraction: 2,
  }),
  halfDayAfternoon: trait({
    offset: 1,
    fraction: 2,
  }),
  singleDay: trait({
    offset: 0,
    fraction: 1,
  }),
  holiday: trait({
    dayKindId: DAY_KINDS.HOLIDAY,
  }),
  work: trait({
    dayKindId: DAY_KINDS.WORK,
  }),
  offWork: trait({
    dayKindId: DAY_KINDS.OFF_WORK,
  }),
});
