import Button from '@material-ui/core/Button';
import LensIcon from '@material-ui/icons/Lens';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import React, { useEffect, useState } from 'react';

import { COLORS, FONT_STYLES } from '../../constants';
import { dataTypes } from '../../utils';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import t from './translate';

const { isNumber } = dataTypes;

const addNumber = (number, setPinCode) => setPinCode((currentPinCode) => [number, ...currentPinCode.slice(0, -1)]);

const removeLastNumber = (pinCode, setPinCode) => setPinCode([...pinCode.slice(1), null]);

const NumberButton = ({ className, number, setPinCode }) =>
  React.useMemo(
    () => (
      <Button className={className} onClick={() => addNumber(number, setPinCode)}>
        {number}
      </Button>
    ),
    [className, number]
  );

export const PinCodeInput = ({ label, onInputFinish, wide, labelSmall, isSubmitting }) => {
  const [pinCode, setPinCode] = useState([null, null, null, null]);
  const resetPinInput = () => setPinCode([null, null, null, null]);
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

  const handleKeyDown = (event) => {
    if (event.key >= 0 && event.key < 10) addNumber(+event.key, setPinCode);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (!isSubmitting && pinCode.every((code) => code !== null)) {
      onInputFinish(pinCode.reverse().join(''), resetPinInput);
    }
  }, [pinCode]);

  const styles = createStyles(wide);

  return React.useMemo(
    () => (
      <div className={css(styles.keyboardContainer)}>
        <If condition={label}>
          <div className={css(styles.labelContainer)}>
            <div className={css(styles.label, labelSmall && styles.labelSmall)}>{label}</div>
          </div>
        </If>

        <div className={css(styles.iconsContainer)}>
          {pinCode.map((code, index) => (
            <Choose>
              <When condition={isNumber(code)}>
                <LensIcon className={css(styles.icon)} key={`number-ellipse-${index}`} />
              </When>
              <Otherwise>
                <PanoramaFishEyeIcon className={css(styles.icon)} key={`number-ellipse-${index}`} />
              </Otherwise>
            </Choose>
          ))}
        </div>

        <div className={css(styles.buttonsContainer)}>
          {numbers.map((number) => (
            <div className={css(styles.numberContainer)} key={`number-container-${number}`}>
              <NumberButton className={css(styles.button)} number={number} pinCode={pinCode} setPinCode={setPinCode} />
            </div>
          ))}

          <div className={css(styles.numberContainer)}>
            <Button className={css(styles.cancelButton)} onClick={() => removeLastNumber(pinCode, setPinCode)}>
              {t('cancel')}
            </Button>
          </div>
        </div>
      </div>
    ),
    [onInputFinish, pinCode, wide]
  );
};

const createStyles = (wide) =>
  StyleSheet.create({
    button: {
      backgroundColor: `${COLORS.ORANGE_OPAQUE} !important`,
      borderRadius: '50% !important',
      color: `${COLORS.ORANGE_MAIN} !important`,
      fontSize: '28px !important',
      fontWeight: '600 !important',
      height: `${wide ? '85px' : '60px'} !important`,
      width: `${wide ? '85px' : '60px'} !important`,
      ['@media screen and (max-height: 495px)']: {
        height: '60px !important',
        width: '60px !important',
      },
    },
    buttonsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      width: 303,
    },
    cancelButton: {
      color: `${COLORS.ORANGE_MAIN} !important`,
      fontSize: '13px !important',
      textAlign: 'center',
      textTransform: 'none !important',
    },
    icon: {
      color: COLORS.ORANGE_MAIN,
      flex: '0 0 20%',
      height: '16px !important',
      marginBottom: 40,
      width: '16px !important',
      ['@media screen and (max-height: 530px)']: {
        marginBottom: 0,
      },
    },
    iconsContainer: {
      display: 'flex',
      height: 'fit-content',
      justifyContent: 'center',
      width: '100%',
    },
    keyboardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      maxWidth: wide ? 409 : 240,
    },
    label: {
      ...FONT_STYLES.base,
      color: COLORS.GREY_DARK,
      display: 'flex',
      flex: '1 0 100%',
      fontSize: 22,
      fontWeight: 600,
      justifyContent: 'center',
      marginBottom: 40,
    },
    labelSmall: {
      fontSize: 16,
    },
    labelContainer: {
      display: 'flex',
      height: 'fit-content',
      justifyContent: 'center',
      width: '100%',
      textAlign: 'center',
      ['@media screen and (max-height: 594px)']: {
        display: 'none',
      },
    },
    numberContainer: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 26%',
      justifyContent: 'center',
      margin: 8,
    },
  });

export default PinCodeInput;
