const NOTIFICATION_DISPLAY_TIME = 10000;

const buildNotificationActionCreator =
  (type) =>
  ({ message }) =>
  (dispatch) => {
    const id = +new Date() + Math.random();

    dispatch({
      type: 'ADD_NOTIFICATION',
      message: message,
      notificationType: type,
      id: id,
    });

    global.setTimeout(() => dispatch(removeNotification({ id })), NOTIFICATION_DISPLAY_TIME);
  };

export const addErrorNotification = buildNotificationActionCreator('error');
export const addSuccessNotification = buildNotificationActionCreator('success');
export const addInfoNotification = buildNotificationActionCreator('info');

export const removeNotification = ({ id }) => ({
  type: 'REMOVE_NOTIFICATION',
  id: id,
});
