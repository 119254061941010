import React from 'react';
import { ERRORS } from '../constants';

/**
 * lazyLoadWithErrorHandling - Try to Lazy Load the React component & handle errors if any.
 *
 * @param {React.Component} lazyComponent The React Component to be lazy-loaded
 * @param {String} errorName The error.name property to check against. For e.g., 'ChunkLoadError'.
 *
 * @return {Promise} Returns a Promise representing the loading status of the React Component (lazyComponent)
 */
const lazyLoadWithErrorHandling = (lazyComponent, errorName) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        if (error.name === errorName) {
          window.location.reload();
        } else {
          reject(error);
          return;
        }
      });
  });
};

/**
 * lazyLoader - Handle the Lazy Loading of React components and any errors that arise.
 * React.lazy shouldn't be called directly, instead call lazyLoader.
 *
 * 29 Sept 2020: Currently, 'ChunkLoadError' is being received quite frequently. This function handles it.
 *
 * @param {String} lazyComponentModule The module to be lazy-loaded
 * @param {String} errorName The error.name property to check against. For e.g., 'ChunkLoadError'.
 *
 * @return {Promise} Returns a Promise representing the loading status of the React Component (lazyComponent)
 */

const lazyLoader = (lazyComponentModule, errorName = ERRORS.chunkLoadError) =>
  React.lazy(() => lazyLoadWithErrorHandling(lazyComponentModule, errorName));

export { lazyLoader };
