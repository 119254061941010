import Headers from './v1/headers';

export default class UserContext {
  user = {};

  constructor(headers = new Headers()) {
    this.headers = headers;
  }

  toHeaders() {
    return this.headers.toJson();
  }

  serialize() {
    return this.headers.toSerializable();
  }

  deserialize(s) {
    this.headers.fromSerializable(s);
  }
}
