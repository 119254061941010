import PropTypes from 'prop-types';
import React from 'react';
import { css } from '../../_external-deps/stylesheet';
import NativeInput from './native-input';
import withLabel from './with-label';

const PasswordInputComponent = withLabel(
  ({ value, onChange, step, onFocus, onBlur, styles, isExpanded, name, autoFocus, onClick }) => (
    <NativeInput
      type="password"
      className={css(styles.textInput, isExpanded && styles.textInputExpanded)}
      value={value}
      onChange={onChange}
      step={step}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      name={name}
      autoFocus={autoFocus}
    />
  )
);

PasswordInputComponent.propTypes = {
  placeholder: PropTypes.string.isRequired,
  placeholderInfo: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.array,
  initialValue: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default PasswordInputComponent;
