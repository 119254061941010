import { memoize } from 'redux-memoize';

import { CrewmeisterApiV3, RESOURCES } from '@crewmeister/shared';
import { dispatchAsyncFnStatusActions } from '../../../src/lib';
import { ACTION_NAMES } from '../../constants';
import { AsynchronousDispatchFunction, RSQL_OPERATOR } from '../../types';

export const v3LoadProducts = memoize(
  { ttl: 5000 },
  ({ apiV3, crewId }: { apiV3: CrewmeisterApiV3; crewId: number }): AsynchronousDispatchFunction =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: ACTION_NAMES.V3_LOAD_PRODUCTS,
        fnParams: { crewId },
        fn: async (params) => {
          return (await apiV3.getService(RESOURCES.PRODUCTS)).get(
            { id: 4 },
            { id: RSQL_OPERATOR.LESSER_THAN_OR_EQUALS }
          );
        },
      })
);

export const v3LoadMemberRoles = memoize(
  { ttl: 5000 },
  ({ apiV3, crewId }: { apiV3: CrewmeisterApiV3; crewId: number }): AsynchronousDispatchFunction =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: ACTION_NAMES.V3_LOAD_MEMBER_ROLES,
        fnParams: { crewId },
        fn: async (params) => {
          return (await apiV3.getService(RESOURCES.MEMBER_ROLES)).get(
            { crewId: params.crewId },
            { crewId: RSQL_OPERATOR.EQUALS }
          );
        },
      })
);

export const v3LoadUsageRights = memoize(
  { ttl: 5000 },
  ({ apiV3, crewId }: { apiV3: CrewmeisterApiV3; crewId: number }): AsynchronousDispatchFunction =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: ACTION_NAMES.V3_LOAD_USAGE_RIGHTS,
        fnParams: { crewId },
        fn: async (params) => {
          return (await apiV3.getService(RESOURCES.USAGE_RIGHTS)).get(
            { crewId: params.crewId },
            { crewId: RSQL_OPERATOR.EQUALS }
          );
        },
      })
);

type CreateMemberRoleParams = {
  crewId: number;
  userId: number;
  roleType: string;
  productId?: number;
  teamId?: number;
};

export const v3CreateMemberRole = memoize(
  { ttl: 0 },
  ({
      apiV3,
      createMemberRoleParams,
    }: {
      apiV3: CrewmeisterApiV3;
      createMemberRoleParams: CreateMemberRoleParams;
    }): AsynchronousDispatchFunction =>
    (dispatch) => {
      return dispatchAsyncFnStatusActions({
        dispatch,
        actionName: ACTION_NAMES.V3_CREATE_MEMBER_ROLE,
        fnParams: { createMemberRoleParams },
        fn: async (params) => (await apiV3.getService(RESOURCES.MEMBER_ROLES)).createOne(params.createMemberRoleParams),
      });
    }
);

export const v3UpdateMemberRole = memoize(
  { ttl: 0 },
  ({
      apiV3,
      id,
      updateMemberRoleParams,
    }: {
      apiV3: CrewmeisterApiV3;
      id: number;
      updateMemberRoleParams: CreateMemberRoleParams;
    }): AsynchronousDispatchFunction =>
    (dispatch) => {
      return dispatchAsyncFnStatusActions({
        dispatch,
        actionName: ACTION_NAMES.V3_UPDATE_MEMBER_ROLE,
        fnParams: { id, updateMemberRoleParams },
        fn: async (params) => {
          return (await apiV3.getService(RESOURCES.MEMBER_ROLES)).putOne(params.id, params.updateMemberRoleParams);
        },
      });
    }
);

export const v3DeleteMemberRole = memoize(
  { ttl: 0 },
  ({ apiV3, id }: { apiV3: CrewmeisterApiV3; id: number }): AsynchronousDispatchFunction =>
    (dispatch) => {
      return dispatchAsyncFnStatusActions({
        dispatch,
        actionName: ACTION_NAMES.V3_DELETE_MEMBER_ROLE,
        fn: async () => (await apiV3.getService(RESOURCES.MEMBER_ROLES)).deleteOne(id),
      });
    }
);

export const v3LoadTeams = memoize(
  { ttl: 0 },
  ({ apiV3, crewId }: { apiV3: CrewmeisterApiV3; crewId: number }): AsynchronousDispatchFunction =>
    (dispatch) => {
      return dispatchAsyncFnStatusActions({
        dispatch,
        actionName: ACTION_NAMES.V3_LOAD_TEAMS,
        fnParams: { crewId },
        fn: async (params) =>
          (await apiV3.getService(RESOURCES.TEAMS)).get({ crewId: params.crewId }, { crewId: RSQL_OPERATOR.EQUALS }),
      });
    }
);
