/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { ReactElement, ReactNode, MouseEvent } from 'react';
import { Link as RRLink, NavLink as RRNavLink } from 'react-router-dom';

type LinkProps = {
  children: ReactNode;
  scrollToTop?: boolean;
  to: string;
  target?: string;
  activeClassName?: string;
  onClick: (evt: MouseEvent) => void;
};

const enhanceWithScrollToTop = (props: LinkProps) => (evt: MouseEvent) => {
  if (props.scrollToTop) {
    global.scrollTo(0, 0);
  }
  if (props.onClick) {
    return props.onClick(evt);
  }
};

const Link: (props: LinkProps) => ReactElement = ({ scrollToTop = false, ...props }) =>
  props.activeClassName ? (
    <RRNavLink {...props} onClick={enhanceWithScrollToTop({ ...props, scrollToTop })} />
  ) : (
    <RRLink {...props} onClick={enhanceWithScrollToTop({ ...props, scrollToTop })} />
  );

export default Link;
