import { channel, dispatchAsyncFnStatusActions } from '../../lib';

export const onEnterTerminal =
  ({ api }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'ENTER_TERMINAL_MODE',
      fn: () => api.setCmChannel('terminal'),
    });

export const onExitTerminal =
  ({ api }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'EXIT_TERMINAL_MODE',
      fn: () => api.setCmChannel(channel),
    });
