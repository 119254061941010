import { factory, sequence, random, trait } from 'stahlwerk';
import { TIME_ACCOUNTS } from '../constants';

export const createBooking = factory({
  date: '200-01-01',
  crewId: sequence(),
  duration: random((rand) => `PT${Math.floor(rand * 3660)}S`),
  timeAccount: TIME_ACCOUNTS.WORKING,
  userId: sequence(),
  absence: trait({
    timeAccount: TIME_ACCOUNTS.ABSENCE,
    additionalData: {
      typeId: 1,
    },
  }),
  additionalData: {
    clockInTimestamp: '2000-01-01T12:00:00',
    date: '2000-01-01',
    endLocation: null,
    endTruncated: null,
    from: '2000-01-01T12:00:00',
    groupId: null,
    note: null,
    startLocation: null,
    startTruncated: null,
    to: '2000-01-01T12:20:00',
  },
  work: trait({
    timeAccount: TIME_ACCOUNTS.WORKING,
  }),
  break: trait({
    timeAccount: TIME_ACCOUNTS.BREAK,
  }),
  publicHoliday: trait({
    timeAccount: TIME_ACCOUNTS.PUBLIC_HOLIDAY,
    additionalData: {
      typeId: 1,
    },
  }),
  payout: trait({
    timeAccount: TIME_ACCOUNTS.PAYOUT,
    additionalData: {},
  }),
  manualCorrection: trait({
    timeAccount: TIME_ACCOUNTS.BALANCE_CORRECTION_MANUAL,
    additionalData: {},
  }),
  automaticCorrection: trait({
    timeAccount: TIME_ACCOUNTS.BALANCE_CORRECTION_AUTOMATIC,
    additionalData: {},
  }),
  working: trait({ timeAccount: TIME_ACCOUNTS.WORKING }),
  resting: trait({ timeAccount: TIME_ACCOUNTS.BREAK }),
  truncated: trait({
    additionalData: {
      clockInTimestamp: '2000-01-01T12:00:00',
      endLocation: null,
      endTruncated: true,
      from: '2000-01-01T12:00:00',
      groupId: null,
      note: null,
      startLocation: null,
      startTruncated: true,
      to: '2000-01-01T12:20:00',
    },
  }),
  withGroup: trait({
    additionalData: {
      clockInTimestamp: '2000-01-01T12:00:00',
      date: '2000-01-01',
      endLocation: null,
      endTruncated: null,
      from: '2000-01-01T12:00:00',
      groupId: 1,
      note: null,
      startLocation: null,
      startTruncated: null,
      to: '2000-01-01T12:20:00',
    },
  }),
  withNote: trait({
    additionalData: {
      clockInTimestamp: '2000-01-01T12:00:00',
      endLocation: null,
      endTruncated: null,
      from: '2000-01-01T12:00:00',
      groupId: null,
      startLocation: null,
      startTruncated: null,
      to: '2000-01-01T12:20:00',
      note: 'This is a note',
    },
  }),
  withLocation: trait({
    additionalData: {
      clockInTimestamp: '2000-01-01T12:00:00',
      endLocation: '+48.107848999004084+11.58192979847729/',
      endTruncated: null,
      from: '2000-01-01T12:00:00',
      groupId: null,
      note: null,
      startLocation: '+48.103848999003213+11.58292979820921/',
      startTruncated: null,
      to: '2000-01-01T12:20:00',
    },
  }),
  open: trait({
    additionalData: {
      clockInTimestamp: '2000-01-01T12:00:00',
      endLocation: null,
      endTruncated: null,
      from: '2000-01-01T12:00:00',
      groupId: null,
      note: null,
      startLocation: null,
      startTruncated: null,
      to: null,
    },
  }),
  oldFormat: trait({
    clockInTimestamp: '2000-01-01T12:00:00',
    endLocation: null,
    endTruncated: true,
    from: '2000-01-01T12:00:00',
    groupId: null,
    note: null,
    startLocation: null,
    startTruncated: true,
    to: '2000-01-01T12:20:00',
  }),
});

export const createMapCoordinates = factory({
  color: '#FF9419',
  description: 'A fitting description goes here',
  label: 'A',
  lat: 48.1235971,
  lang: 11.600213799999999,
  timestamp: '2000-01-01',
});
