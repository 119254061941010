import * as selectors from '../selectors';
import { sliceString } from './string';

const { findByProps } = selectors;

/**
 * getTimeCategoryNamesByIds - Retrieves the time category names
 *
 * @param {array} timeCategories array with all the desired time categories
 * @param {integer} timeCategory1Id integer which is the id of the first time category
 * @param {integer} timeCategory2Id integer which is the id of the second time category
 * @param {integer} maxStringLength maximum string length that will be returned by this function
 *
 * @return {string} string with the time category names concatenated or
 *                  the "null id group" name if no id matches the elements in the array or
 *                  undefined if there is no match in the array
 */
export const getTimeCategoryNamesByIds = (timeCategories, timeCategory1Id, timeCategory2Id, maxStringLength) => {
  const timeCategory1Label =
    timeCategory1Id && findByProps(timeCategories, { level: 1, id: timeCategory1Id }, {}, 'name');
  const timeCategory2Label =
    timeCategory2Id && findByProps(timeCategories, { level: 2, id: timeCategory2Id }, {}, 'name');

  if (timeCategory1Label && timeCategory2Label) {
    return `${sliceString(timeCategory1Label, maxStringLength / 2)} / ${sliceString(
      timeCategory2Label,
      maxStringLength / 2
    )}`;
  } else if (timeCategory1Label || timeCategory2Label) {
    return sliceString(timeCategory1Label || timeCategory2Label, maxStringLength);
  }

  return sliceString(findByProps(timeCategories, { id: null }, {}, 'name'), maxStringLength);
};

/**
 * timeCategoriesToSelect - Filters the array of the time categories by the given level and maps the array
 *                          to a selection format
 *
 * @param {array} timeCategories list of time categories
 * @param {integer} level 1 or 2
 * @param {string} noTimeCategoryLabel translation of "no time category"
 *
 * @return {array} array which each element has "value" and "label"
 */
export const timeCategoriesToSelect = (timeCategories = [], level) =>
  timeCategories
    .filter((category) => category.level === level && !category.disabled)
    .map((category) => ({
      value: category.id || 'null',
      label: category.name,
    }));

export const isSelectedTimeCategoryEnabled = (timeCategories, timeCategoryId) => {
  if (!timeCategoryId) return true;
  return timeCategories.some((category) => category.id === timeCategoryId && !category.disabled);
};
