export const hexToRgba = (hex, opacity) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return opacity
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`
    : `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`;
};

const MAIN_COLOR = {
  // #ff9419
  r: 255,
  g: 148,
  b: 25,
  toRgb() {
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  },

  toRgba(opacity) {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${opacity})`;
  },
};

export const COLORS = {
  BLACK: '#000000',
  BLACK_OPAQUE: 'rgba(0, 0, 0, 0.8)',
  NONE: 'transparent',
  OVERLAY: 'rgba(255, 255, 255, 0.8)',
  BACKGROUND: '#fefcfa',
  BACKGROUND_OPAQUE: 'rgba(254, 252, 250, 0.9)',

  WHITE: '#ffffff',
  WHITE_OPAQUE: 'rgba(254, 252, 250, 0.9)',

  YELLOW_DARK: '#ebc20c',
  YELLOW_MAIN: '#ffd000',
  YELLOW_MEDIUM: '#ffe884',
  YELLOW_MEDIUM_2: '#fab005',
  YELLOW_LIGHT: '#fff5c5',
  YELLOW_OPAQUE: 'rgba(255, 208, 0, 0.06)',
  YELLOW_OPAQUE_2: 'rgba(250, 176, 5, 0.1)',

  ORANGE_DARK: '#EE8A17',
  ORANGE_MAIN: '#FF9419',
  ORANGE_MAIN_LIGHTER: '#FAB005',
  ORANGE_MAIN_BACKGROUND: '#FEECD8',
  ORANGE_MEDIUM: '#FFA947',
  ORANGE_MEDIUM_LIGHT: '#F9B45B',
  ORANGE_LIGHT: '#FFDEBA',
  ORANGE_LIGHT_1: '#FFEABB',
  ORANGE_LIGHT_2: '#FBE8D5',
  ORANGE_EXTRA_LIGHT: '#FFF9F3',
  ORANGE_EXTRA_LIGHT_2: '#fff4e4',
  ORANGE_OPAQUE: 'rgba(255, 148, 25, 0.06)',

  BLUE_DARK: '#0E73C6',
  BLUE_MAIN: '#208DE6',
  BLUE_MEDIUM: '#49AEED',
  BLUE_LIGHT: '#D2E8FA',
  BLUE_EXTRA_LIGHT: '#EDF6FD',
  BLUE_OPAQUE: 'rgba(32, 141, 230, 0.06)',

  RED_DARK: '#CC071E',
  RED_MAIN: '#F50722',
  RED_MEDIUM: '#FF4855',
  RED_MEDIUM_LIGHT: '#FCB4BC',
  RED_LIGHT: '#FDCDD3',
  RED_EXTRA_LIGHT: '#FFF4F5',
  RED_OPAQUE: 'rgba(245, 7, 34, 0.06)',

  PINK_DARK: '#950282',
  PINK_MAIN: '#ce0cb6',
  PINK_MEDIUM: '#ed7adf',
  PINK_LIGHT: '#ffd2f9',
  PINK_EXTRA_LIGHT: '#FBE7F8',
  PINK_OPAQUE: 'rgba(206, 12, 182, 0.06)',

  VIOLET_DARK: '#520c7a',
  VIOLET_MAIN: '#8a23c5',
  VIOLET_MEDIUM: '#d183fe',
  VIOLET_LIGHT: '#eac6ff',
  VIOLET_EXTRA_LIGHT: '#F3E9F9',
  VIOLET_OPAQUE: 'rgb(138, 35, 197, 0.06)',

  GREEN_DARK: '#29A76A',
  GREEN_MAIN: '#3DD189',
  GREEN_MEDIUM: '#5EDDAC',
  GREEN_LIGHT: '#D7F5E6',
  GREEN_EXTRA_LIGHT: '#ECFAF3',
  GREEN_OPAQUE: 'rgba(61, 209, 137, 0.06)',

  TURQUOISE_DARK: '#46A3A3',
  TURQUOISE_MAIN: '#58BFBF',
  TURQUOISE_MEDIUM: '#7DD1D2',
  TURQUOISE_LIGHT: '#DEF2F2',
  TURQUOISE_EXTRA_LIGHT: '#EEF9F9',
  TURQUOISE_OPAQUE: 'rgba(88, 191, 191, 0.06)',
  TURQUOISE_OPAQUE_2: 'rgba(88, 191, 191, 0.1)',
  TURQUOISE_OPAQUE_3: 'rgba(88, 191, 191, 0.4)',
  TURQUOISE_LIGHT_2: 'rgb(204, 235, 235, 0.5)',

  EGGSHELL_DARK: 'rgba(136, 116, 100, 0.18)',
  EGGSHELL_MAIN: '#fefdfb',
  EGGSHELL_MEDIUM: 'rgba(136, 116, 100, 0.05)',
  EGGSHELL_LIGHT: '#fefefe',

  GREY_DARK: '#42403e',
  GREY_MAIN: '#6b6b6a',
  GREY_MEDIUM: '#91908f',
  GREY_LIGHT: '#B0B0B0',
  GREY_EXTRA_LIGHT: '#CECECE',
  GREY_EXTRA_LIGHT_1: '#E7E7E6',
  GREY_EXTRA_LIGHT_2: '#f7f7f7',
  GREY_OPAQUE: 'rgba(222, 222, 222, 0.1)',
  GREY_TEXT: '#656565',

  BROWN_DARK: '#887464',
  BROWN_MAIN: '#b39b88',
  BROWN_MEDIUM: '#d8c5b5',
  BROWN_LIGHT: '#ebe4df',
  BROWN_EXTRA_LIGHT: '#f8f5f2',
  BROWN_OPAQUE: 'rgba(235, 228, 223, 0.6)',

  MAIN_COLOR_A_BIT_LITER: MAIN_COLOR.toRgba(0.85),
  MAIN_COLOR_MEDIUM_LITE: MAIN_COLOR.toRgba(0.4),
  MAIN_COLOR_LITE: MAIN_COLOR.toRgba(0.2),
  MAIN_COLOR_EXTRA_LITE: MAIN_COLOR.toRgba(0.06),

  MAIN_COLOR_14: MAIN_COLOR.toRgba(0.14),
  MAIN_COLOR_10: MAIN_COLOR.toRgba(0.1),
  MAIN_COLOR_8: MAIN_COLOR.toRgba(0.08),
  MAIN_COLOR_6: MAIN_COLOR.toRgba(0.06),
};

export const ABSENCE_MANAGEMENT_THEMES = {
  0: {
    regular: hexToRgba(COLORS.GREY_MAIN, 1),
    light: hexToRgba(COLORS.GREY_MAIN, 0.8),
    extraLight: hexToRgba(COLORS.GREY_MAIN, 0.7),
    transparent: hexToRgba(COLORS.GREY_MAIN, 0.1),
  },
  1: {
    regular: hexToRgba(COLORS.BLUE_MAIN, 1),
    light: hexToRgba(COLORS.BLUE_MAIN, 0.8),
    extraLight: hexToRgba(COLORS.BLUE_MAIN, 0.7),
    transparent: hexToRgba(COLORS.BLUE_MAIN, 0.1),
  },
  2: {
    regular: hexToRgba(COLORS.PINK_MAIN, 1),
    light: hexToRgba(COLORS.PINK_MAIN, 0.8),
    extraLight: hexToRgba(COLORS.PINK_MAIN, 0.7),
    transparent: hexToRgba(COLORS.PINK_MAIN, 0.1),
  },
  3: {
    regular: hexToRgba(COLORS.VIOLET_MAIN, 1),
    light: hexToRgba(COLORS.VIOLET_MAIN, 0.8),
    extraLight: hexToRgba(COLORS.VIOLET_MAIN, 0.7),
    transparent: hexToRgba(COLORS.VIOLET_MAIN, 0.1),
  },
  4: {
    regular: hexToRgba(COLORS.TURQUOISE_MAIN, 1),
    light: hexToRgba(COLORS.TURQUOISE_MAIN, 0.8),
    extraLight: hexToRgba(COLORS.TURQUOISE_MAIN, 0.7),
    transparent: hexToRgba(COLORS.TURQUOISE_MAIN, 0.1),
  },
  5: {
    regular: hexToRgba(COLORS.GREEN_MAIN, 1),
    light: hexToRgba(COLORS.GREEN_MAIN, 0.8),
    extraLight: hexToRgba(COLORS.GREEN_MAIN, 0.7),
    transparent: hexToRgba(COLORS.GREEN_MAIN, 0.1),
  },
};

export const ABSENCE_MANAGEMENT_REJECTED = {
  regular: COLORS.RED_MAIN,
  light: COLORS.RED_MAIN,
  extraLight: COLORS.RED_MAIN,
};
