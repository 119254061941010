/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    placeholder: 'Kategorie suchen',
  },
  en: {
    placeholder: 'Search category',
  },
});

export default translate;
