import { env } from '../utils';
import HttpRequest from './HttpRequest';

import { COOKIE_NAME } from './types';
import { POST_PROCESS_FUNCTION_NAME } from '../types';
import { RESOURCES } from '..';
import { CookieStore } from './CookieStore';

const { getEnv } = env;

const getBearerToken = (token: string) => `Bearer ${token}`;

const getV2HeaderName = (cookieName: string) => `X-${cookieName}`;

export class CrewmeisterApiV3 {
  #cookieStore: CookieStore;
  #domain: string;

  constructor(domain: string, cookieStore: CookieStore = new CookieStore()) {
    this.#cookieStore = cookieStore;
    this.#domain = domain || getEnv(process.env.CREWMEISTER_API_DOMAIN);
  }

  async getService(resource: RESOURCES, postProcessFunctionName?: POST_PROCESS_FUNCTION_NAME, async?: boolean) {
    const headers = new Headers();

    const v3UserToken = await this.#cookieStore.getToken(COOKIE_NAME.V3_USER_TOKEN);
    const v3CrewToken = await this.#cookieStore.getToken(COOKIE_NAME.V3_CREW_TOKEN);

    const v2UserId = await this.#cookieStore.getToken(COOKIE_NAME.V2_USER_ID);
    const v2CrewId = await this.#cookieStore.getToken(COOKIE_NAME.V2_CREW_ID);
    const v2UserToken = await this.#cookieStore.getToken(COOKIE_NAME.V2_USER_TOKEN);
    const v2CrewToken = await this.#cookieStore.getToken(COOKIE_NAME.V2_CREW_TOKEN);

    if (v3UserToken) {
      headers.append('Authorization', getBearerToken(v3UserToken));
    } else if (v3CrewToken) {
      headers.append('Authorization', getBearerToken(v3CrewToken));
    } else {
      if (
        ![RESOURCES.AUTHENTICATION_USER, RESOURCES.AUTHENTICATION_CREW, RESOURCES.AUTHENTICATION_PIN].includes(resource)
      ) {
        if (v2UserId && v2UserToken) {
          headers.append(getV2HeaderName(COOKIE_NAME.V2_USER_ID), v2UserId);
          headers.append(getV2HeaderName(COOKIE_NAME.V2_USER_TOKEN), v2UserToken);
        } else if (v2CrewId && v2CrewToken) {
          headers.append(getV2HeaderName(COOKIE_NAME.V2_CREW_ID), v2CrewId);
          headers.append(getV2HeaderName(COOKIE_NAME.V2_CREW_TOKEN), v2CrewToken);
        }
      }
    }

    if (async) {
      headers.append('X-Write-Async', 'true');
    } else {
      headers.delete('X-Write-Async');
    }

    return new HttpRequest(
      this.#domain + resource,
      headers,
      this.#getPostProcessFunction(postProcessFunctionName)
    ).getMethods();
  }

  setToken = (postProcessFunctionName: POST_PROCESS_FUNCTION_NAME, v3Token: string) => {
    switch (postProcessFunctionName) {
      case POST_PROCESS_FUNCTION_NAME.SET_USER_TOKEN:
        return this.#setToken(COOKIE_NAME.V3_USER_TOKEN)(v3Token);
      case POST_PROCESS_FUNCTION_NAME.SET_CREW_TOKEN:
        return this.#setToken(COOKIE_NAME.V3_CREW_TOKEN)(v3Token);
      default:
        return undefined;
    }
  };

  removeToken = (postProcessFunctionName: POST_PROCESS_FUNCTION_NAME) => {
    switch (postProcessFunctionName) {
      case POST_PROCESS_FUNCTION_NAME.REMOVE_USER_TOKEN:
        return this.#removeToken(COOKIE_NAME.V3_USER_TOKEN);
      case POST_PROCESS_FUNCTION_NAME.REMOVE_CREW_TOKEN:
        return this.#removeToken(COOKIE_NAME.V3_CREW_TOKEN);
      default:
        return undefined;
    }
  };

  #getPostProcessFunction = (postProcessFunctionName?: POST_PROCESS_FUNCTION_NAME) => {
    switch (postProcessFunctionName) {
      case POST_PROCESS_FUNCTION_NAME.SET_USER_TOKEN:
        return this.#setToken(COOKIE_NAME.V3_USER_TOKEN);
      case POST_PROCESS_FUNCTION_NAME.REMOVE_USER_TOKEN:
        return () => this.#removeToken(COOKIE_NAME.V3_USER_TOKEN);
      case POST_PROCESS_FUNCTION_NAME.SET_CREW_TOKEN:
        return this.#setToken(COOKIE_NAME.V3_CREW_TOKEN);
      case POST_PROCESS_FUNCTION_NAME.REMOVE_CREW_TOKEN:
        return () => this.#removeToken(COOKIE_NAME.V3_CREW_TOKEN);
      default:
        return undefined;
    }
  };

  #setToken = (name: COOKIE_NAME) => (v3Token?: string) =>
    v3Token && this.#cookieStore.setToken(name, v3Token, this.#domain);

  #removeToken = (name: COOKIE_NAME) => this.#cookieStore.removeToken(name, this.#domain);
}
