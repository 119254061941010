import React, { ReactElement } from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';

type LoadingDotsProps = {
  hidden?: boolean;
  middle?: boolean;
};

const LoadingDots: (props: LoadingDotsProps) => ReactElement = ({ hidden, middle = false }) => (
  <span className={css(styles.wrapper, hidden && styles.hidden, middle && styles.middle) as string}>
    <span className={css(styles.animation, styles.first) as string}>.</span>
    <span className={css(styles.animation, styles.second) as string}>.</span>
    <span className={css(styles.animation, styles.third) as string}>.</span>
  </span>
);

const styles = StyleSheet.create({
  wrapper: {
    opacity: 1,
    transition: 'opacity 200ms',
    ':before': {
      content: '" "',
    },
    ':after': {
      content: '" "',
    },
  },
  hidden: {
    opacity: 0,
  },
  middle: {
    position: 'relative',
    top: '-0.5ex',
  },
  animation: {
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationName: {
      from: { opacity: 0.2 },
      to: { opacity: 1 },
    },
  },
  first: {},
  second: {
    animationDelay: '0.2s',
  },
  third: {
    animationDelay: '0.4s',
  },
});

export default LoadingDots;
