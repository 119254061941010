import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;

const initialState = produce({ crewSettings: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const crewSettingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // actually, we load crew AND user settings in this case
      case 'LOAD_CREW_SETTINGS_SUCCESS':
        applyMinimalChangesToArray(draft.crewSettings, action.result, (settings) => settings.crewId === action.crewId);
        break;
      case 'LOAD_MEMBER_SETTINGS_SUCCESS':
        applyMinimalChangesToArray(
          draft.crewSettings,
          action.result,
          (settings) => settings.crewId === action.crewId && settings.userId === action.userId
        );
        break;
      case 'DELETE_MEMBER_SETTINGS_SUCCESS':
        applyMinimalChangesToArray(
          draft.crewSettings,
          [],
          (settings) => settings.crewId === action.crewId && settings.userId === action.userId
        );
        break;
      case 'UPDATE_CREW_SETTINGS_SUCCESS':
        applyMinimalChangesToArray(
          draft.crewSettings,
          action.result,
          (settings) => settings.crewId === action.crewId && !settings.userId
        );
        break;
      case 'UPDATE_MEMBER_SETTINGS_SUCCESS':
        applyMinimalChangesToArray(
          draft.crewSettings,
          action.result,
          (setting) => setting.crewId === action.crewId && setting.userId === action.userId
        );
        break;
      case 'LOAD_BLANK_TIME_TRACKING_SETTINGS_SUCCESS':
        applyMinimalChangesToArray(
          draft.crewSettings,
          action.result,
          (setting) => setting.crewId === action.crewId && setting.userId === action.userId
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
