import produce from 'immer';
import { array } from '../../utils';

const initialState = produce({ members: [] }, () => {});
const { applyMinimalChangesToArray } = array;
// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const terminalCrewMembersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_TERMINAL_CREW_MEMBERS_SUCCESS':
        applyMinimalChangesToArray(draft.members, action.result, (member) => member.crewId === action.crewId);
        break;
      case 'SIGNOUT_CREW_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
