import { createSelector } from 'reselect';

type StateFn = (state: any, props?: any) => any;

const isListEmpty = (obj: any) => typeof obj.isEmpty === 'function' && obj.isEmpty();
const isEmpty = (obj: any) =>
  obj === null || Object.keys(obj).length === 0 || typeof obj === 'string' || isListEmpty(obj);
const toArray = (value: any) => (value && value.forEach ? value : [value]);
const extractKey = (obj: any, key: any) => obj[key];

const compare = (a: any, b: any, key: any): any => {
  const valueA = extractKey(a, key);
  const valueB = extractKey(b, key);
  return isEmpty(valueA) || isEmpty(valueB)
    ? toArray(valueA).filter((n: any) => toArray(valueB).includes(n)).length !== 0
    : commonProperties(valueA, valueB).every((nestedKey) => compare(valueA, valueB, nestedKey));
};

const commonProperties = (a: any, b: any) => {
  const keysA = a ? Object.keys(a) : [];
  const keysB = b ? Object.keys(b) : [];
  return keysA.filter((n) => keysB.includes(n));
};

export const extractFromCollection = (collection: any[], key: any) => {
  return collection.reduce((accumulator: any[], item: any) => {
    accumulator.push(extractKey(item, key));
    return accumulator;
  }, []);
};

interface Props {
  filter?: () => {};
}

/**
 * Creates a generic filter selector which automatically matches the given props.
 * See tests for usage.
 * @param stateFn - the function which responds the collection
 * @returns function
 */
/**
 * !! NOTE: This is a faulty selector, it should not be used anymore.
 * Its usage will be completely removed in the future.
 */
export const createPropsSelector = (stateFn: StateFn) =>
  createSelector(
    stateFn,
    // @ts-ignore
    (_, inclusiveProps = {}) => inclusiveProps,
    // @ts-ignore
    (_, inclusiveProps = {} as Props) => inclusiveProps.filter || (() => true),
    (_: any, __: any, exclusiveProps = {}) => exclusiveProps,
    (_: any, __: any, exclusiveProps = {} as Props) => exclusiveProps.filter || (() => true),
    (_: any, __: any, ___: any, extractionKey: any) => extractionKey,
    // @ts-ignore
    (collection: any[] = [], inclusiveProps, inclusiveFilter, exclusiveProps, exclusiveFilter, extractionKey) => {
      const filteredCollection = collection.filter((item: any) => {
        const commonInclusiveProps = commonProperties(item, inclusiveProps);
        const commonExclusiveProps = commonProperties(item, exclusiveProps);

        return (
          commonInclusiveProps.every((key) => compare(item, inclusiveProps, key)) &&
          (isEmpty(commonExclusiveProps) || commonExclusiveProps.some((key) => !compare(item, exclusiveProps, key))) &&
          inclusiveFilter(item, inclusiveProps) &&
          exclusiveFilter(item, exclusiveProps)
        );
      });

      return extractionKey ? extractFromCollection(filteredCollection, extractionKey) : filteredCollection;
    }
  ) as (collection: any, inclusiveProps: any, exclusiveProps?: any, extractionKey?: any) => any[];

/**
 * Creates a generic finder which automatically matches the given props.
 * See tests for usage.
 * @param stateFn - the function which responds the collection
 * @returns function
 */
export const createPropsFinder = (stateFn: StateFn) =>
  createSelector(createPropsSelector(stateFn), (collection = []) => collection[0]);

/**
 * Creates a generic filter selector which automatically matches the given props.
 * See tests for usage.
 * @param collection - the collection which will be filtered (array)
 * @param inclusiveProps - an object defining all properties which need to be present in the collection
 * @param exclusiveProps - an object defining all properties which shouldn't to be present in the collection
 * @param extractionKey - when given an array containing the values of this key will be returned
 * @returns array
 */
export const selectByProps = createPropsSelector((state: any, props?: any) => state);

/**
 * Works exactly like selectByProps but instead of returning a collection it returns a single record
 * if no record was found responds undefined
 * @param collection - the collection which will be filtered (array)
 * @param inclusiveProps - an object defining all properties which need to be present in the collection
 * @param exclusiveProps - an object defining all properties which shouldn't to be present in the collection
 * @param extractionKey - when given an array containing the values of this key will be returned
 * @returns object
 */
export const findByProps = createSelector(
  selectByProps,
  (collection: Record<string, unknown>[] = []) => collection[0]
) as (
  collection: any[],
  inclusiveProps?: Record<string, string | number | Array<string> | ((props: any) => boolean)>,
  exclusiveProps?: Record<string, string | number>,
  extractionKey?: string
) => unknown;
