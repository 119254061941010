import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { usePreview } from 'react-dnd-preview';
import { TouchBackend } from 'react-dnd-touch-backend';

import Image from '../../../components/core/image';
import { COLORS, FONT_STYLES, TIME_ACCOUNTS } from '../../../constants';
import { string as stringUtils, isTouchDevice } from '../../../utils';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';

import { MembersColumn } from './members-column';
import { isWorkingMembersColumn } from './utils';

const { trimStringWithMultipleWords } = stringUtils;

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

const DragPreviewForTouchBackend = () => {
  const { display, itemType, item, style: memberStyle } = usePreview();
  if (!display) {
    return null;
  }

  const member = item.member;
  return (
    <div className={css(styles.memberWrapper)} style={memberStyle}>
      <Image
        className={css(styles.memberPhoto, styles.shadow, isWorkingMembersColumn(itemType) && styles.memberPhotoFramed)}
        src={member.avatarUrl || ''}
        alt={member.name}
      />
      <div className={css(styles.memberName)}>{trimStringWithMultipleWords(member.name, 10, 18, 2)}</div>
    </div>
  );
};

const activeTimeAccounts = [
  {
    headingText: 'clockedOut',
    overlayText: 'dragHereToClockOut',
    overlayIcon: 'clock-finished',
    memberType: `${TIME_ACCOUNTS.NOT_WORKING}`,
    memberTypesAccepted: [`${TIME_ACCOUNTS.WORKING}`],
  },
  {
    headingText: 'clockedIn',
    overlayText: 'dragHereToClockIn',
    overlayIcon: 'clock-working',
    memberType: `${TIME_ACCOUNTS.WORKING}`,
    memberTypesAccepted: [`${TIME_ACCOUNTS.NOT_WORKING}`, `${TIME_ACCOUNTS.BREAK}`],
  },
  {
    headingText: 'break',
    overlayText: 'dragHereToBreak',
    overlayIcon: 'clock-break',
    memberType: `${TIME_ACCOUNTS.BREAK}`,
    memberTypesAccepted: [`${TIME_ACCOUNTS.NOT_WORKING}`, `${TIME_ACCOUNTS.WORKING}`],
  },
];

const MembersColumnComponent = ({
  getSettingsForMember,
  style,
  timeAccount,
  moveMemberToList,
  canMoveMemberToList,
  membersGroupedByTimeAccount,
  setIsOverlayOpen,
  setMemberForOverlay,
  onStartBreak,
  onStartWork,
  onStopWork,
  isOnClockInColumn,
  isDroppingFromBreakColumn,
  crewHasTimeCategories,
}) =>
  React.useMemo(
    () => (
      <MembersColumn
        getSettingsForMember={getSettingsForMember}
        style={style}
        memberStyles={[styles.memberPhoto, styles.memberPhotoFramed, styles.memberName]}
        moveMemberToList={moveMemberToList}
        canMoveMemberToList={canMoveMemberToList}
        members={membersGroupedByTimeAccount[timeAccount]}
        setIsOverlayOpen={setIsOverlayOpen}
        setMemberForOverlay={setMemberForOverlay}
        onStartBreak={onStartBreak}
        onStartWork={onStartWork}
        onStopWork={onStopWork}
        {...activeTimeAccounts[timeAccount]}
        isOnClockInColumn={isOnClockInColumn}
        isDroppingFromBreakColumn={isDroppingFromBreakColumn}
        crewHasTimeCategories={crewHasTimeCategories}
      />
    ),
    [membersGroupedByTimeAccount[timeAccount].map((member) => member.id)]
  );

const Members3ColumnList = (props) => {
  const { members, crewHasTimeCategories } = props;

  const [membersGroupedByTimeAccount, setMembersGroupedByTimeAccount] = useState(
    Array(3)
      .fill(null)
      .map(() => [])
  );

  const [isDroppingFromBreakColumn, setIsDroppingFromBreakColumn] = useState(false);

  // Calculate members groups on change of props 'members'.
  useEffect(() => {
    const membersArray = Array(3)
      .fill(null)
      .map(() => []);
    members.forEach((member) => membersArray[member.activeTimeAccount].push(member));
    setMembersGroupedByTimeAccount(membersArray);
  }, [members]);

  // On Drag End, manually move member from source list to destination list.
  const moveMemberToList = ({
    member,
    type,
    destinationType,
    setSelectedTimeCategory1Id,
    setSelectedTimeCategory2Id,
  }) => {
    setSelectedTimeCategory1Id(undefined);
    setSelectedTimeCategory2Id(undefined);
    const memberLists = [...membersGroupedByTimeAccount];

    // Remove member from source list
    const index = memberLists[type].findIndex((el) => el.id === member.id);
    memberLists[type].splice(index, 1);

    // Add member to destination list
    memberLists[destinationType] = [...memberLists[destinationType], member].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    setMembersGroupedByTimeAccount(memberLists);
  };

  const canMoveMemberToList = (item, destinationType, setOpenedTimeCategories) => {
    setOpenedTimeCategories(false);
    setIsDroppingFromBreakColumn(item.type === '2');
    return item && activeTimeAccounts[item.type].memberTypesAccepted.indexOf(destinationType) > -1;
  };

  return (
    <DndProvider
      backend={backendForDND}
      options={isTouchDevice() && { enableMouseEvents: true, enableKeyboardEvents: true }}
    >
      {isTouchDevice() && <DragPreviewForTouchBackend />}
      <div className={css(styles.wrapper)}>
        {[TIME_ACCOUNTS.NOT_WORKING, TIME_ACCOUNTS.WORKING, TIME_ACCOUNTS.BREAK].map((timeAccount, index) => (
          <MembersColumnComponent
            {...props}
            canMoveMemberToList={canMoveMemberToList}
            key={`members-column-component-${timeAccount}`}
            membersGroupedByTimeAccount={membersGroupedByTimeAccount}
            moveMemberToList={moveMemberToList}
            style={styles[`column${index + 1}`]}
            timeAccount={timeAccount}
            isOnClockInColumn={index === 1}
            isDroppingFromBreakColumn={isDroppingFromBreakColumn}
            crewHasTimeCategories={crewHasTimeCategories}
          />
        ))}
      </div>
    </DndProvider>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    ...FONT_STYLES.base,
  },
  column1: {
    flex: 3,
  },
  column2: {
    flex: 4,
    backgroundColor: COLORS.TURQUOISE_LIGHT_2,
  },
  column3: {
    flex: 2,
    backgroundColor: COLORS.TURQUOISE_OPAQUE_2,
  },
  memberWrapper: {
    zIndex: 2,
    textAlign: 'center',
    width: 110,
  },
  memberPhoto: {
    backgroundColor: COLORS.WHITE,
    height: 88,
    width: 88,
    borderRadius: '50%',
  },
  shadow: {
    boxShadow: '0 6px 20px 0 rgba(0,0,0,0.19)',
  },
  memberPhotoFramed: {
    height: 85,
    width: 85,
    border: `3px solid ${COLORS.TURQUOISE_MAIN}`,
  },
  memberName: {
    margin: '8px 18px 0',
    textAlign: 'center',
    color: COLORS.GREY_TEXT,
    fontSize: 16,
    overflow: 'hidden',
  },
});

export default Members3ColumnList;
