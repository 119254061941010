import produce from 'immer';
import { ACTION_NAMES } from '../../constants';
import { SignOutActionType, V3TemplateType } from '../../types';
import { array } from '../../utils';

const initialState = produce({ templates: [] }, () => {});
const { applyMinimalChangesToArray } = array;

type V3TemplatesAction = {
  result: V3TemplateType;
  type: ACTION_NAMES.V3_CREATE_TEMPLATE;
} & Pick<V3TemplateType, 'crewId' | 'name'>;

type V3ShiftsSliceInRedux = { templates: V3TemplateType[] };

export const templatesReducer = (
  state = initialState,
  action: V3TemplatesAction | SignOutActionType
): V3ShiftsSliceInRedux =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_CREATE_TEMPLATE}_SUCCESS`:
      case `${ACTION_NAMES.V3_UPDATE_TEMPLATE}_SUCCESS`: {
        const result = action?.result?.resourceAfterWrite || action?.result;
        applyMinimalChangesToArray(draft.templates, result, (template) => {
          return template.id === result.id;
        });
        break;
      }

      case `${ACTION_NAMES.V3_DELETE_TEMPLATE}_SUCCESS`: {
        const result = action?.result?.resourceBeforeWrite || action?.result;
        applyMinimalChangesToArray<V3TemplateType>(draft.templates, [], (template) => {
          return template.id === result.id;
        });
        break;
      }

      case `${ACTION_NAMES.V3_GET_TEMPLATES}_SUCCESS`: {
        const result = action?.result?.content;
        applyMinimalChangesToArray(draft.templates, result);
        break;
      }

      case `${ACTION_NAMES.SIGNOUT_USER}_SUCCESS`:
        return initialState;

      default:
      // nothing to do => immer returns the same object
    }
  });
