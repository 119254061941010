/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    description:
      'Ihre Mitarbeiter können ihre Zeitstempelungen jeweils einer Zeitkategorie zuordnen. Nutzen Sie Zeitkategorien z.B. für Kunden, Projekte oder Tätigkeiten. Erfasste Zeiten können dann pro Zeitkategorie detailliert ausgewertet werden.',
    firstTimeCategory: '1. Zeitkategorie',
    heading: 'Zeitkategorien',
    hideDisabledCategories: 'Deaktivierte Kategorien verbergen',
    secondTimeCategory: '2. Zeitkategorie',
    showDisabledCategories: 'Deaktivierte Kategorien anzeigen',
  },
  en: {
    description:
      'Your employees can assign tracked times to time categories. You can use time categories to represent customers, projects, or activities. You can conduct detailed analyses on the times based on the time categories.',
    firstTimeCategory: '1st Time Category',
    heading: 'Time categories',
    hideDisabledCategories: 'Hide disabled categories',
    secondTimeCategory: '2nd Time Category',
    showDisabledCategories: 'Show disabled categories',
  },
});

export default translate;
