import { createSelector } from 'reselect';
import { selectCurrentCrewId } from './crews-selectors';
import { createPropsSelector } from './utils';
import { themeForAbsenceTypeId } from '../utils/absence';

export const selectAbsenceTypes = (state) => state.bo.absenceTypes.absenceTypes;

export const selectAbsenceTypesByProps = createSelector(createPropsSelector(selectAbsenceTypes), (absenceTypes) =>
  absenceTypes.map((absenceType) => ({ ...absenceType, theme: themeForAbsenceTypeId(absenceType.id) }))
);

export const findAbsenceTypeByProps = createSelector(selectAbsenceTypesByProps, (absenceTypes) => absenceTypes[0]);

export const selectAbsenceTypesForCurrentCrew = createSelector(
  selectAbsenceTypes,
  selectCurrentCrewId,
  (absenceTypes, crewId) => {
    const typesForCrew = absenceTypes.filter((absenceType) => absenceType.crewId === crewId);
    return typesForCrew.map((absenceType) => ({ ...absenceType, theme: themeForAbsenceTypeId(absenceType.id) }));
  }
);

export const selectAbsenceTypeForCrewById = createSelector(
  selectAbsenceTypesForCurrentCrew,
  (_, props) => props.absenceTypeId,
  (absenceTypes, absenceTypeId) => absenceTypes.find((absenceType) => absenceType.id === absenceTypeId)
);
