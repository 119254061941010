import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ groups: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const groupsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_GROUPS_SUCCESS':
        applyMinimalChangesToArray(draft.groups, action.result, (group) => group.crewId === action.crewId);
        break;
      case 'ADD_GROUP_SUCCESS':
      case 'UPDATE_GROUP_SUCCESS':
      case 'ENABLE_GROUP_SUCCESS':
      case 'DISABLE_GROUP_SUCCESS':
        applyMinimalChangesToArray(
          draft.groups,
          action.result,
          (group) => group.crewId === action.crewId && group.id === action.groupId
        );
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
