import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../../constants';
import { V3Products } from '../../types';

type V3ProductAction = AnyAction & { crewId: number; result: { content: V3Products[] } };

type V3ProductsSliceInRedux = { products: V3Products[] };

const initialState = produce({ products: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const v3ProductsReducer = (state = initialState, action: V3ProductAction): V3ProductsSliceInRedux =>
  produce(state, (draft: V3ProductsSliceInRedux) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_LOAD_PRODUCTS}_SUCCESS`:
        draft.products = action.result.content;
        break;
      default:
      // nothing to do => immer returns the original object
    }
  });
