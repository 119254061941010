import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setPasswordByToken } from '../../action-creators';
import { collections, confirmPassword } from '../../utils';

import { api, readPropertyFromLocation, wrapWithNotification } from '../../lib';
import t from './translate';

import NewPasswordRecovery from './organism';

const { compose, composeReverse } = collections;

const mapDispatchToProps = (dispatch, props) => {
  const token = readPropertyFromLocation(props, 'password-reset-token', '');
  return {
    onSubmit: compose(confirmPassword, ({ password }) =>
      wrapWithNotification(
        async () => {
          await dispatch(setPasswordByToken({ api, token, password }));
          props.history.push('/');
        },
        { dispatch, success: t('passwordUpdatedSuccess'), error: t('invalidToken') }
      )
    ),
  };
};

const NewPasswordRecoveryContainer = composeReverse(withRouter, connect(null, mapDispatchToProps))(NewPasswordRecovery);

NewPasswordRecoveryContainer.propTypes = {
  withoutWebsiteLink: PropTypes.bool,
};

NewPasswordRecoveryContainer.displayName = 'NewPasswordRecoveryContainer';

export default NewPasswordRecoveryContainer;
