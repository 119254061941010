import { memoize } from 'redux-memoize';
import { actions } from '@crewmeister/shared';

import { ACTION_NAMES } from '../constants';
import { dispatchAsyncFnStatusActions } from '../lib';
import { setLanguage } from '../lib/translate';
import { setUiStateValue } from './ui-actions';
import { loadCurrentAuthentication, signInUser } from './authentication-actions';
import { v3SignInUser } from './v3';

const { loadCrewMembers } = actions;

export const loadUser = memoize(
  {},
  ({ api, userId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: ACTION_NAMES.LOAD_USER,
        fnParams: { userId },
        fn: (params) =>
          api.getUsers(params).then((users) => {
            const user = users[0];
            setLanguage(user.language);
            return user;
          }),
      })
);

export const loadReferralCode = memoize(
  {},
  ({ api, userId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: ACTION_NAMES.LOAD_REFERRAL_CODE,
        fnParams: { userId },
        fn: () =>
          api.getReferralCode().then((response) => {
            const referralObject = response[0];
            return referralObject;
          }),
      })
);

export const updateCurrentUser =
  ({ api, userId, password, newPassword, passwordConfirmation, language, email, name, username }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: ACTION_NAMES.UPDATE_USER_PROFILE,
      fnParams: { userId, password, newPassword, passwordConfirmation, language, email, name, username },
      fn: (params) =>
        api.updateCurrentUser(params).then((user) => {
          setLanguage(user.language);
          return user;
        }),
    });

export const updateUser =
  ({ api, userId, crewId, newPassword, passwordConfirmation, email, name, username }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: ACTION_NAMES.UPDATE_USER_PROFILE,
      fnParams: { userId, newPassword, passwordConfirmation, email, name, username, crewId },
      fn: (params) => api.updateUser(params),
      afterSuccessFn: () => dispatch(loadCrewMembers.unmemoized({ api, crewId, userIds: [userId] })),
    });

export const setPasswordByToken =
  ({ api, token, password }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: ACTION_NAMES.SET_PASSWORD_BY_TOKEN,
      fnParams: {
        token,
        password,
      },
      fn: (params) => api.setPasswordByToken(params),
    });

export const signUpUser =
  ({ api, apiV3, email, password, name, termsAccepted, language, acquisitionTracking }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: ACTION_NAMES.SIGN_UP_USER,
      fnParams: { email, password, name, termsAccepted, language, acquisitionTracking },
      fn: (params) => api.signUp(params),
      afterSuccessFn: async () => {
        await dispatch(signInUser({ api, identifier: email, password }));
        await dispatch(v3SignInUser({ apiV3, username: email, password }));
        await dispatch(loadCurrentAuthentication({ api }));
        await dispatch(setUiStateValue({ key: 'firstSignIn', value: true }));
        await dispatch(setUiStateValue({ key: 'productContext', value: parseContextFromJSON(acquisitionTracking) }));
      },
    });

const parseContextFromJSON = (string) => {
  try {
    return JSON.parse(string).context;
  } catch (e) {
    return;
  }
};
