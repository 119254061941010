import MaterialButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { COLORS, FONT_STYLES } from '../../constants';
import { dataTypes, object as objectUtils } from '../../utils';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import LoadingDots from './loading-dots';

const { isFunction } = dataTypes;
const { pick } = objectUtils;

const ButtonMaterialUi = withStyles({
  root: {
    ...pick(['fontFamily'], FONT_STYLES.base),
    fontWeight: 700,
    '&.MuiButton-textPrimary': {
      backgroundColor: COLORS.WHITE,
      color: COLORS.ORANGE_MEDIUM,
    },
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: COLORS.ORANGE_LIGHT_2,
    },
    '&.MuiButton-textPrimary:hover': {
      backgroundColor: COLORS.ORANGE_EXTRA_LIGHT,
      borderColor: COLORS.ORANGE_MAIN,
    },
    '&.MuiButton-containedPrimary': {
      backgroundColor: COLORS.ORANGE_MEDIUM,
      color: COLORS.WHITE,
    },
    '&.MuiButton-outlinedPrimary': {
      backgroundColor: COLORS.WHITE,
      border: `1px solid ${COLORS.ORANGE_MEDIUM}`,
      color: COLORS.ORANGE_MEDIUM,
    },
    '&.MuiButton-outlinedPrimary:hover': {
      borderColor: COLORS.ORANGE_MAIN,
      backgroundColor: COLORS.ORANGE_EXTRA_LIGHT,
    },
  },
})(
  ({
    backgroundColor,
    biggerFontSize,
    children,
    className,
    disabled,
    filled,
    label,
    noBackgroundColor = false,
    noTextTransform,
    noWrap,
    onHoverBackgroundColor = COLORS.ORANGE_EXTRA_LIGHT,
    outlined,
    theme,
    small,
    withLoadingDots,
    ...props
  }) => {
    const styles = getStyles({ backgroundColor, onHoverBackgroundColor });

    return (
      <MaterialButton
        className={`
          ${noTextTransform && css(styles.noTextTransform)} 
          ${backgroundColor && css(styles.backgroundColor)}
          ${biggerFontSize && css(styles.biggerFontSize)} 
          ${noBackgroundColor && css(styles.noBackgroundColor)} 
          ${noWrap && css(styles.noWrap)}
          ${className}`}
        color={theme === 'warning' ? 'secondary' : 'primary'}
        disabled={disabled}
        {...(small ? { size: 'small' } : {})}
        {...(filled ? { variant: 'contained' } : {})}
        {...(outlined ? { variant: 'outlined' } : {})}
        {...props}
      >
        {isFunction(label) ? label() : label}
        {children}
        {withLoadingDots && <LoadingDots />}
      </MaterialButton>
    );
  }
);

ButtonMaterialUi.propTypes = {
  onClick: PropTypes.func,
  withLoadingDots: PropTypes.bool,
};

const getStyles = ({ backgroundColor, onHoverBackgroundColor }) =>
  StyleSheet.create({
    backgroundColor: {
      backgroundColor: `${backgroundColor} !important`,
    },
    biggerFontSize: {
      fontSize: '16px !important',
    },
    noBackgroundColor: {
      backgroundColor: 'transparent !important',
      ':hover': {
        backgroundColor: `${onHoverBackgroundColor} !important`,
      },
    },
    noTextTransform: {
      textTransform: 'unset !important',
    },
    noWrap: {
      whiteSpace: 'noWrap',
    },
  });

export default ButtonMaterialUi;
