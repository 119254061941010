/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
/* eslint-disable max-len */
import { buildScopedTranslationFunction, buildDangerouslyTranslateNeverWithUserContent } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    exit: 'Den Terminal-Modus kann nur der Administrator einer Crew wieder verlassen. Dafür klickt der Administrator auf seinen Namen und gibt sein Passwort ein. Über den Link „Zur vollen Anwendung“ rechts unten im auftauchenden Fenster, kann der Administrator den Crewmeister Terminal verlassen und zurück zu seinem vollen Crewmeister Profil kehren.',
    help: 'Sie befinden sich gerade im Crewmeister Terminal zur Zeiterfassung. Über diesen können mehrere Mitarbeiter schnell über ein einziges Gerät ihre Zeiten stempeln. Dafür klickt man einfach auf den eigenen Namen, gibt sein Passwort ein und stempelt wie gewohnt.',
    info: 'Klicken Sie auf Ihren Namen um Zeiten zu erfassen',
  },
  en: {
    exit: 'To exit the terminal on this device, you need to be an admin. To do so, the admin needs to click on his name and login by entering his password. Now, clicking on the link on the bottom right corner „Open full application“ closes the terminal and opens the full account of the admin.',
    help: 'You are right now in the crewmeister terminal for time tracking. With this terminal, multiple employees can track their times comfortably with only one device. Just click on your name, enter your password and track your time.',
    info: 'Click on your name to track times',
  },
});

export const dangerouslyTranslateNeverWithUserContent = buildDangerouslyTranslateNeverWithUserContent(translate);
export default translate;
