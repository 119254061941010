import produce from 'immer';
import { array } from '../utils';

const { applyMinimalChangesToArray } = array;
const initialState = produce({ notifications: [] }, () => {});

export const notificationsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'ADD_NOTIFICATION':
        applyMinimalChangesToArray(
          draft.notifications,
          {
            id: action.id,
            message: action.message,
            type: action.notificationType,
            isVisible: true,
          },
          (notification) => notification.id === action.id
        );
        break;
      case 'REMOVE_NOTIFICATION':
        draft.notifications
          .filter((notification) => notification.id === action.id)
          .forEach((notification) => (notification.isVisible = false)); // eslint-disable-line no-param-reassign
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });
