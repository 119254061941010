import { isSameDay } from 'date-fns';
import { DAY_KINDS } from '../constants';
import { isWithinRange } from './date';

const OFFSET_MORNING = 0;
const OFFSET_AFTERNOON = 1;
const HALF_DAY_FRACTION = 2;
export const getDaysToDeductFromDayKindCalendar = (absence, dayKindCalendar) =>
  dayKindCalendar
    .filter(
      (dayKind) =>
        dayKind.dayKindId === DAY_KINDS.WORK && isWithinRange(dayKind.date, absence.startDate, absence.endDate)
    )
    .reduce((acc, dayKind) => {
      const dayToDeduct = dayKind.fraction === HALF_DAY_FRACTION ? 0.5 : 1;

      // One-day absence
      if (
        isSameDay(new Date(absence.startDate), new Date(absence.endDate)) &&
        absence.startDayPart === absence.endDayPart
      ) {
        if (
          dayKind.fraction === HALF_DAY_FRACTION &&
          ((absence.startDayPart === 'morning' && dayKind.offset === OFFSET_AFTERNOON) ||
            (absence.startDayPart === 'afternoon' && dayKind.offset === OFFSET_MORNING))
        )
          return acc; // The absence lasts only on a half-day off period
        return acc + 0.5;
      }

      // First day of absence
      if (isSameDay(new Date(dayKind.date), new Date(absence.startDate)) && absence.startDayPart === 'afternoon') {
        if (dayKind.fraction === HALF_DAY_FRACTION && dayKind.offset === OFFSET_MORNING) return acc;
        return acc + 0.5;
      }

      // Last day of absence
      if (isSameDay(new Date(dayKind.date), new Date(absence.endDate)) && absence.endDayPart === 'morning') {
        if (dayKind.fraction === HALF_DAY_FRACTION && dayKind.offset === OFFSET_AFTERNOON) return acc;
        return acc + 0.5;
      }

      return acc + dayToDeduct;
    }, 0);
