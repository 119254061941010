import { createSelector } from 'reselect';
import produce from 'immer';
import { selectCurrentCrewId } from './crews-selectors';
import { selectMembersInCurrentCrew } from './crew-members-selectors';
import { selectCurrentUserId } from './authentication-selectors';

const selectRoomsFromState = (state) => state.bo.rooms.rooms || produce([], () => {});

const selectRooms = createSelector(selectRoomsFromState, (rooms) => rooms.concat());

export const selectCurrentRoomId = (_state, props) => (props && props.roomId ? props.roomId : null);

export const selectRoomIds = (_state, props) => props.rooms.map((r) => r.id);

export const selectCurrentRoom = createSelector(selectRoomsFromState, selectCurrentRoomId, (rooms, roomId) =>
  rooms.find((room) => room.id === roomId)
);

export const selectUserRooms = createSelector(selectCurrentUserId, selectRooms, (userId, rooms) =>
  rooms.filter((room) => room.users.some((user) => user.id === userId))
);

export const selectCrewRooms = createSelector(selectUserRooms, selectCurrentCrewId, (rooms, crewId) =>
  rooms.filter((room) => room.crewId === crewId)
);

export const selectCrewRoomsWithoutDeletedMembers = createSelector(
  selectCrewRooms,
  selectMembersInCurrentCrew,
  (rooms, members) => {
    const validMemberIds = members.map((member) => member.userId);
    return rooms
      .map((room) => ({ ...room, users: room.users.filter((user) => validMemberIds.includes(user.id)) }))
      .filter((room) => room.users.length > 1);
  }
);
