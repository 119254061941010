import { statusCodes } from '@crewmeister/shared';

import { addDays, endOfDay, startOfDay } from 'date-fns';
import * as actions from '../../action-creators';
import { api, wrapWithNotification } from '../../lib';
import { date, location, stamp } from '../../utils';

import t from './translate';

const { formatIso8601Date } = date;
const { addStampFnForSetting } = stamp;

const {
  createStartBreakStamp,
  createStartWorkStamp,
  createStopWorkStamp,
  loadBookingsWithDelay,
  loadDurationsWithDelay,
  loadDurationBalances,
  loadStamps,
  updateStamp,
  updateStampBreak,
} = actions;

const updateBookings = (crewId, userId, timestamp, bookingsForUserCrewInRange) => {
  return loadBookingsWithDelay({
    api,
    crewId: crewId,
    userId: [userId],
    from: formatIso8601Date(startOfDay(new Date(timestamp))),
    to: formatIso8601Date(endOfDay(new Date(timestamp))),
    bookingsForUserCrewInRange: bookingsForUserCrewInRange,
  });
};

const updateDurations = (crewId, userId, timestamp, durationsForUserCrewInRange) =>
  loadDurationsWithDelay({
    api,
    typeName: ['work', 'break', 'workDifference'],
    crewId,
    userId,
    from: formatIso8601Date(startOfDay(new Date(timestamp))),
    to: formatIso8601Date(endOfDay(new Date(timestamp))),
    granularity: 'day',
    byUser: true,
    durationsForUserCrewInRange: durationsForUserCrewInRange,
  });

export const fetchLocation = (dispatch) => () =>
  wrapWithNotification(() => location.fetchAccuratePosition({ timeout: 15 * 1000 }), {
    dispatch,
    success: t('positionRetrieved'),
    error: (e) => {
      if (e.code === location.LOCATION_ERROR_CODES.permissionDenied) return t('permissionDenied');
      if (e.code === location.LOCATION_ERROR_CODES.timeout) return t('timeout', { accuracy: e.accuracy });
      return t('positionUnavailable');
    },
  });

export const buildAddStamp = (apiFn, dispatch) => (params) =>
  addStampFnForSetting(params.useLocation)({
    gpsFn: fetchLocation(dispatch),
    addStampFn: (_location) =>
      apiFn({
        ...params,
        location: location ? location.toIsoLocation(_location) : void 0,
      }),
  });

export const loadActiveStamps = (ownProps) =>
  (ownProps.unmemoized ? loadStamps.unmemoized : loadStamps)({
    api,
    crewId: ownProps.crewId,
    startTime: startOfDay(addDays(new Date(), -2)).toISOString(),
    endTime: endOfDay(addDays(new Date(), 1)).toISOString(),
  });

export const loadStampsAfterAutoClockOut = (ownProps, lastStamp) =>
  (ownProps.unmemoized ? loadStamps.unmemoized : loadStamps)({
    api,
    crewId: ownProps.crewId,
    startTime: lastStamp
      ? startOfDay(new Date(lastStamp)).toISOString()
      : startOfDay(addDays(new Date(), -2)).toISOString(),
    endTime: endOfDay(addDays(new Date(), 1)).toISOString(),
  });

export const onStartWork = (dispatch, ownProps) =>
  buildAddStamp(
    (params) =>
      dispatch(createStartWorkStamp({ api, ...params }))
        .then((res) => {
          if (params.success) params.success();
          if (ownProps.success) ownProps.success(res.timeAccount);
        })
        .then(() => {
          if (ownProps.setSelectedTimeCategory1Id) ownProps.setSelectedTimeCategory1Id(params.timeCategory1Id);
          if (ownProps.setSelectedTimeCategory2Id) ownProps.setSelectedTimeCategory2Id(params.timeCategory2Id);
        }),
    dispatch
  );

export const onStartBreak = (dispatch, ownProps) =>
  buildAddStamp(async (params) => {
    const res = await dispatch(createStartBreakStamp({ api, ...params }));
    if (params.success) params.success();
    if (ownProps.success) ownProps.success(res.timeAccount);
  }, dispatch);

export const onStopWork = (dispatch, ownProps) =>
  buildAddStamp(async (params) => {
    try {
      const res = await dispatch(createStopWorkStamp({ api, ...params }));
      await dispatch(loadActiveStamps(ownProps));
      if (params.success) params.success();
      if (ownProps.success) ownProps.success(res.timeAccount);
      return ownProps.setIsSummaryScreenOpen ? ownProps.setIsSummaryScreenOpen(true) : true;
    } catch (e) {
      if (e.id === statusCodes.TIME_ACCOUNT_NOT_ALLOWED) {
        await dispatch(loadStampsAfterAutoClockOut(ownProps, params.lastStamp));
      }
    }
  }, dispatch);

export const onUpdateBookingsAndDurations = (dispatch, ownProps) => async (params) => {
  if (!ownProps.inTerminal) {
    await Promise.all([
      dispatch(updateBookings(ownProps.crewId, ownProps.userId, params.timestamp, params.bookingsForUserCrewInRange)),
      dispatch(updateDurations(ownProps.crewId, ownProps.userId, params.timestamp, params.durationsForUserCrewInRange)),
      dispatch(
        loadDurationBalances.unmemoized({
          api,
          date: [formatIso8601Date(new Date(params.timestamp))],
          crewId: ownProps.crewId,
          userId: ownProps.userId,
          groupBy: ['userId'],
        })
      ),
    ]);
  }
};

export const onUpdateNote = (dispatch, ownProps) => async (params) => {
  await dispatch(updateStamp({ api, ...params }));
  if (params.success) params.success();
  if (ownProps.success) ownProps.success();
};

export const onUpdateBreak = (dispatch, ownProps) => async (params) => {
  const res = await dispatch(updateStampBreak({ api, ...params }));
  if (params.success) params.success();
  if (ownProps.success) ownProps.success(res.timeAccount);
  if (!ownProps.inTerminal) {
    await Promise.all([
      dispatch(updateBookings(ownProps.crewId, ownProps.userId, params.timestamp, params.bookingsForUserCrewInRange)),
      dispatch(updateDurations(ownProps.crewId, ownProps.userId, params.timestamp, params.durationsForUserCrewInRange)),
    ]);
  }
};
