import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';

import Button from '../../components/core/button';
import InactivityAction from '../../components/core/inactivity-action';
import SearchInput from '../../components/core/SearchV2';
import LoadingSpinnerWithText from '../../components/core/loading-spinner-with-text';
import P from '../../components/core/paragraph';
import List from '../../components/list';

import { COLORS, IDS_FOR_GOOGLE_TAG_MANAGER, THEMES, TIME_ACCOUNTS } from '../../constants';
import { time } from '../../utils';
import { css, StyleSheet } from '../../_external-deps/stylesheet';

import TerminalMemberTimeTrackingInfo from '../terminal-member-time-tracking-info';
import TerminalSignIn from '../terminal-sign-in';
import TerminalSignInWithPinCode from '../terminal-sign-in-with-pin-code';
import t from './translate';
import { history } from '../../lib';

const { getGreetingLabel } = time;

const ButtonWithOptions = ({ timeAccount, onClick, children }) => (
  <Button className={css(stylesWithTimeAccount[timeAccount].button)} narrow inline onClick={onClick}>
    {children}
  </Button>
);

const ButtonsContainer = ({ timeAccount, onClickButton1, onClickButton2, buttonComponent1, buttonComponent2 }) => (
  <div className={css(styles.buttonContainer)}>
    <ButtonWithOptions timeAccount={timeAccount} onClick={onClickButton1}>
      {buttonComponent1}
    </ButtonWithOptions>
    <ButtonWithOptions timeAccount={timeAccount} onClick={onClickButton2}>
      {buttonComponent2}
    </ButtonWithOptions>
  </div>
);

const SelectMemberFromList = ({ listItems, setMemberSelected, setActiveTimeAccount }) => {
  const [searchValue, setSearchValue] = useState('');
  const filteredListItems = listItems.filter((item) => item.member.name.toLowerCase().indexOf(searchValue) !== -1);
  const searchInputOnChange = (value) => setSearchValue(value === '' ? value : value.toLowerCase());

  return (
    <React.Fragment>
      <P>{t('searchUserHeading')}</P>
      <div className={css(styles.searchContainer)}>
        <div className={css(styles.searchInputFilter)}>
          <SearchInput
            id={IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL_PIN_CODE.SEARCH_USER}
            placeholder={t('searchUserPlaceHolder')}
            onChange={searchInputOnChange}
            dimPlaceHolderText
            inOrange
          />
        </div>
        <div className={css(styles.list)}>
          <List
            style={{ backgroundColor: COLORS.ORANGE_OPAQUE }}
            items={filteredListItems}
            onSelect={({ member }) => {
              setMemberSelected(member);
              setActiveTimeAccount(member.activeTimeAccount);
            }}
            inOrange
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export const Organism = ({ enterUserApp, loadCrewMembers, loadTimeTrackingInformation, members, signOutUser }) => {
  if (!members.length) return <LoadingSpinnerWithText theme={THEMES.TURQUOISE_WHITE} withLoadingIcon fullscreen />;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [usePassword, setUsePassword] = useState(false);
  const [memberSelected, setMemberSelected] = useState(false);
  const [activeTimeAccount, setActiveTimeAccount] = useState(0);

  useEffect(() => {
    loadCrewMembers();
  }, [memberSelected]);

  const getUpdatedMember = (memberCurrentlySelected) =>
    members.find((member) => member.id === memberCurrentlySelected.id);

  const successAction = (timeAccount) => {
    if (timeAccount !== undefined) {
      setActiveTimeAccount(timeAccount);
    }
    loadCrewMembers();
  };

  const listItems = members.map((member, index) => ({
    id: IDS_FOR_GOOGLE_TAG_MANAGER.TERMINAL_PIN_CODE.SELECT_USER_FROM_DROPDOWN + index,
    label: member.name,
    value: index,
    member,
    isSelected: false,
  }));

  const performSignOutUserActions = () => {
    setIsAuthenticated(false);
    setMemberSelected(false);
    setUsePassword(false);
    signOutUser();
  };

  return (
    <React.Fragment>
      <If condition={!!isAuthenticated}>
        <InactivityAction
          callback={performSignOutUserActions}
          startAfterSeconds={10}
          maxInactivitySeconds={30}
          isActive
          message={t('inactivity')}
        />
      </If>

      <div
        className={css(
          styles.mainContainer,
          isAuthenticated && activeTimeAccount === TIME_ACCOUNTS.WORKING && styles.memberWorking,
          isAuthenticated && activeTimeAccount === TIME_ACCOUNTS.BREAK && styles.memberOnBreak
        )}
      >
        <Choose>
          <When condition={isAuthenticated}>
            <ButtonsContainer
              timeAccount={activeTimeAccount || getUpdatedMember(memberSelected).activeTimeAccount}
              onClickButton1={performSignOutUserActions}
              onClickButton2={() => {
                enterUserApp();
                history.push('/');
              }}
              buttonComponent1={
                <div className={css(styles.closeIconContainer)}>
                  <CloseIcon style={{ fontSize: 48 }} />
                </div>
              }
              buttonComponent2={
                <div className={css(styles.openFullApplicationContainer)}>
                  <div> {t('openFullApplication')} </div>
                  <div className={css(styles.arrowForwardIosIconContainer)}>
                    <ArrowForwardIosIcon style={{ fontSize: 14 }} />
                  </div>
                </div>
              }
            />
            <Choose>
              <When condition={memberSelected}>
                <TerminalMemberTimeTrackingInfo
                  activeTimeAccount={activeTimeAccount}
                  className={css(styles.stampWatchContainer)}
                  member={getUpdatedMember(memberSelected)}
                  showPauseInMinutes
                  success={successAction}
                  theme={THEMES.TURQUOISE_WHITE}
                  withLoadingSpinner
                  withoutClockedInSinceInStampWatch
                  withoutDateTimeInfo
                  withoutTimeInfo
                  wide
                  onSummaryClose={performSignOutUserActions}
                  isIn3ColumnTerminalMode={false}
                  shouldLoadStamps={false}
                />
              </When>
              <Otherwise>
                <LoadingSpinnerWithText theme={THEMES.TURQUOISE_WHITE} withLoadingIcon fullscreen />
              </Otherwise>
            </Choose>
          </When>
          <Otherwise>
            <div className={css(styles.greetingLabel)}>{t(getGreetingLabel())}</div>
            <Choose>
              <When condition={usePassword}>
                <Choose>
                  <When condition={!memberSelected}>
                    <SelectMemberFromList
                      listItems={listItems}
                      setMemberSelected={setMemberSelected}
                      setActiveTimeAccount={setActiveTimeAccount}
                      setUsePassword={setUsePassword}
                    />
                  </When>
                  <Otherwise>
                    <TerminalSignIn
                      crewId={memberSelected.crewId}
                      userId={memberSelected.userId}
                      setPreventClosing={() => {}}
                      success={() => {
                        loadTimeTrackingInformation(memberSelected)();
                        setIsAuthenticated(true);
                        setUsePassword(false);
                      }}
                      withoutHeader
                      withLoadingSpinner
                      withoutRedirection
                    />
                  </Otherwise>
                </Choose>
                <Button
                  key={'cancel'}
                  className={css(styles.button)}
                  inline
                  narrow
                  onClick={() => {
                    setMemberSelected(false);
                    setUsePassword(false);
                  }}
                >
                  {t('cancel')}
                </Button>
              </When>
              <Otherwise>
                <TerminalSignInWithPinCode
                  label={t('enter4DigitPin')}
                  success={(userId) => {
                    const authenticatedMember = members.find((member) => member.userId === userId);
                    setMemberSelected(authenticatedMember);
                    setActiveTimeAccount(authenticatedMember.activeTimeAccount);
                    loadTimeTrackingInformation(authenticatedMember)();
                    setIsAuthenticated(true);
                    setUsePassword(false);
                  }}
                  wide
                />
                <Button
                  key={'forgotPin'}
                  className={css(styles.button)}
                  inline
                  narrow
                  onClick={() => setUsePassword(true)}
                >
                  {t('forgotYourPin')}
                </Button>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  arrowForwardIosIconContainer: { marginLeft: '13px' },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 48px',
    position: 'absolute',
    top: 10,
    width: '100%',
  },
  closeIconContainer: { marginRight: '13px' },
  button: {
    bottom: 44,
    fontSize: '16px !important',
    fontWeight: 'bold !important',
    position: 'absolute !important',
    right: 48,
    textTransform: 'none !important',
  },
  greetingLabel: {
    color: COLORS.GREY_DARK,
    fontSize: 50,
    fontWeight: 'bold',
    marginBottom: 48,
    ['@media screen and (max-height: 660px)']: {
      display: 'none',
    },
  },
  mainContainer: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    left: 0,
    overflowY: 'auto',
    position: 'absolute',
    right: 0,
    top: 68,
  },
  memberOnBreak: {
    backgroundColor: COLORS.TURQUOISE_LIGHT,
  },
  memberWorking: {
    backgroundColor: COLORS.TURQUOISE_MAIN,
  },
  openFullApplicationContainer: { display: 'flex', flexDirection: 'row' },
  stampWatchContainer: {
    marginTop: 33,
  },
  searchContainer: {
    border: `1px solid ${COLORS.ORANGE_MAIN}`,
    borderRadius: 12,
    boxShadow: `inset 0 0 2px ${COLORS.ORANGE_MAIN}`,
    padding: 1,
    marginTop: 24,
  },
  searchInputFilter: {
    padding: '12px 16px',
    borderBottom: `solid 1px ${COLORS.ORANGE_MAIN}`,
    display: 'flex',
  },
  list: {
    maxHeight: '100%',
    height: 400,
    width: 400,
    maxWidth: '100%',
    overflow: 'auto',
  },
});

const stylesWithTimeAccount = {
  [TIME_ACCOUNTS.WORKING]: StyleSheet.create({
    button: {
      color: `${COLORS.WHITE} !important`,
      textTransform: 'none !important',
    },
  }),
  [TIME_ACCOUNTS.NOT_WORKING]: StyleSheet.create({
    button: {
      color: `${COLORS.TURQUOISE_MAIN} !important`,
      textTransform: 'none !important',
    },
  }),
  [TIME_ACCOUNTS.BREAK]: StyleSheet.create({
    button: {
      color: `${COLORS.TURQUOISE_MAIN} !important`,
      textTransform: 'none !important',
    },
  }),
};

export default Organism;
