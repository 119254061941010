import React from 'react';
import { StyleSheet, css } from '../../_external-deps/stylesheet';
import { COLORS, IDS_FOR_GOOGLE_TAG_MANAGER, THEMES } from '../../constants';
import * as Fonts from '../../styles/fonts';
import { event as eventUtils } from '../../utils';
import translate from '../../lib/translate';

import Icon from './icon';
import LoadingText from './loading-text';

const { debounceEvent } = eventUtils;

export class NoteEditor extends React.Component {
  MAX_NOTE_LENGTH = 1000;
  state = { isOpen: false, note: '', isLoading: false };

  constructor(props) {
    super(props);
    this.state = { ...this.state, note: props.note };
  }

  saveNote = debounceEvent(() => {
    this.props.onSave({ note: this.state.note });
  }, 1000);

  render() {
    const onUpdateNote = (event) => {
      const note = event.target.value;
      if (note.length <= this.MAX_NOTE_LENGTH) {
        this.setState({ note });
        this.saveNote(note);
      }
    };
    const { disabled, theme } = this.props;
    const isUnsaved = this.state.note !== this.props.note;

    const styles = stylesWithThemes[theme];

    return (
      <div className={css(styles.container)}>
        <Icon name="notes" theme={theme.toLowerCase()} className={css(styles.icon, disabled && styles.disabled)} />
        <div className={css(disabled ? styles.disabled : styles.textareaMoreContentAvailable)}>
          {disabled ? (
            <div>{this.state.note || translate('Notes')}</div>
          ) : (
            <textarea
              id={this.props.id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.NOTES}
              disabled={disabled}
              placeholder={translate('Notes')}
              className={css(styles.textarea)}
              onChange={onUpdateNote}
              value={this.state.note || ''}
            />
          )}
        </div>
        <LoadingText hidden={!isUnsaved}>{translate('Saving')}</LoadingText>
      </div>
    );
  }
}

const placeholderStylesWithTheme = {
  [THEMES.ORANGE]: { ...Fonts.base, textAlign: 'center', fontSize: '18px', color: COLORS.ORANGE_MAIN },
  [THEMES.TURQUOISE]: { ...Fonts.base, textAlign: 'center', fontSize: '18px', color: COLORS.TURQUOISE_MAIN },
  [THEMES.TURQUOISE_WHITE]: { ...Fonts.base, textAlign: 'center', fontSize: '18px', color: COLORS.WHITE, opacity: 1 },
};

const stylesWithThemes = {
  [THEMES.ORANGE]: StyleSheet.create({
    container: {
      ...Fonts.base,
      textAlign: 'center',
      fontSize: '18px',
      position: 'relative',
    },
    icon: {
      color: COLORS.ORANGE_MAIN,
      display: 'block',
      fontSize: '25px',
    },
    button: {
      ...Fonts.base,
      color: COLORS.ORANGE_MAIN,
      background: COLORS.WHITE,
      border: 'none',
    },
    disabled: {
      color: COLORS.MAIN_COLOR_MEDIUM_LITE,
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
    },
    textarea: {
      ...Fonts.base,
      cursor: 'pointer',
      display: 'block',
      boxSizing: 'border-box',
      border: 'none',
      padding: '10px',
      paddingBottom: '20px',
      borderRadius: '2px',
      width: '100%',
      height: '80px',
      fontSize: '18px',
      color: COLORS.ORANGE_MAIN,
      backgroundColor: 'transparent',

      '::-webkit-input-placeholder': placeholderStylesWithTheme.orange,
      '::-moz-placeholder': placeholderStylesWithTheme.orange,
      ':-moz-placeholder': placeholderStylesWithTheme.orange,
      ':-ms-input-placeholder': placeholderStylesWithTheme.orange,
    },
    textareaMoreContentAvailable: {
      position: 'relative',
      ':before': {
        content: '""',
        top: 0,
        left: 0,
        width: '100%',
        height: '20px',
        display: 'block',
        position: 'absolute',
        background: 'linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
      },
      ':after': {
        content: '""',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '20px',
        display: 'block',
        position: 'absolute',
        background: 'linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
      },
    },
  }),
  [THEMES.TURQUOISE]: StyleSheet.create({
    container: {
      ...Fonts.base,
      textAlign: 'center',
      fontSize: '18px',
      position: 'relative',
    },
    icon: {
      color: COLORS.TURQUOISE_MAIN,
      display: 'block',
      fontSize: '25px',
    },
    button: {
      ...Fonts.base,
      color: COLORS.ORANGE_MAIN,
      background: COLORS.WHITE,
      border: 'none',
    },
    disabled: {
      color: COLORS.TURQUOISE_OPAQUE_3,
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
    },
    textarea: {
      ...Fonts.base,
      cursor: 'pointer',
      display: 'block',
      boxSizing: 'border-box',
      border: 'none',
      padding: '10px',
      paddingBottom: '20px',
      borderRadius: '2px',
      width: '100%',
      height: '80px',
      fontSize: '18px',
      color: COLORS.TURQUOISE_MAIN,
      backgroundColor: 'transparent',

      '::-webkit-input-placeholder': placeholderStylesWithTheme.turquoise,
      '::-moz-placeholder': placeholderStylesWithTheme.turquoise,
      ':-moz-placeholder': placeholderStylesWithTheme.turquoise,
      ':-ms-input-placeholder': placeholderStylesWithTheme.turquoise,
    },
    textareaMoreContentAvailable: {
      position: 'relative',
      ':before': {
        content: '""',
        top: 0,
        left: 0,
        width: '100%',
        height: '20px',
        display: 'block',
        position: 'absolute',
        background: 'linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
      },
      ':after': {
        content: '""',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '20px',
        display: 'block',
        position: 'absolute',
        background: 'linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
      },
    },
  }),
  [THEMES.TURQUOISE_WHITE]: StyleSheet.create({
    container: {
      ...Fonts.base,
      textAlign: 'center',
      fontSize: '18px',
      position: 'relative',
    },
    icon: {
      color: COLORS.WHITE,
      display: 'block',
      fontSize: '25px',
    },
    button: {
      ...Fonts.base,
      color: COLORS.ORANGE_MAIN,
      background: COLORS.WHITE,
      border: 'none',
    },
    disabled: {
      color: COLORS.TURQUOISE_OPAQUE_3,
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
    },
    textarea: {
      ...Fonts.base,
      cursor: 'pointer',
      display: 'block',
      boxSizing: 'border-box',
      border: 'none',
      padding: '10px',
      paddingBottom: '20px',
      borderRadius: '2px',
      width: '100%',
      height: '80px',
      fontSize: '18px',
      color: COLORS.WHITE,
      backgroundColor: 'transparent',

      '::-webkit-input-placeholder': placeholderStylesWithTheme['turquoise-white'],
      '::-moz-placeholder': placeholderStylesWithTheme['turquoise-white'],
      ':-moz-placeholder': placeholderStylesWithTheme['turquoise-white'],
      ':-ms-input-placeholder': placeholderStylesWithTheme['turquoise-white'],
    },
    textareaMoreContentAvailable: {
      position: 'relative',
      ':before': {
        content: '""',
        top: 0,
        left: 0,
        width: '100%',
        height: '20px',
        display: 'block',
        position: 'absolute',
        background: 'transparent',
      },
      ':after': {
        content: '""',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '20px',
        display: 'block',
        position: 'absolute',
        background: 'transparent',
      },
    },
  }),
};

export default NoteEditor;
