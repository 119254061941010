import produce from 'immer';
import { array, date } from '../utils';
import { PUB_STATE } from '../constants';

const initialState = produce({ shifts: [] }, () => {});

const { isWithinRange } = date;
const { applyMinimalChangesToArray } = array;

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const shiftsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const isDraftAction = action.type.includes('DRAFT');
    const shouldShiftBeDraft = (shift, expectedDraftState = true) =>
      expectedDraftState ? shift.pubState !== PUB_STATE.PUBLISHED : shift.pubState === PUB_STATE.PUBLISHED;
    switch (action.type) {
      case 'LOAD_SHIFTS_SUCCESS':
      case 'LOAD_SHIFTS_DRAFT_SUCCESS':
        applyMinimalChangesToArray(
          draft.shifts,
          action.result,
          (shift) =>
            shift.crewId === action.crewId &&
            shouldShiftBeDraft(shift, isDraftAction) &&
            isWithinRange(shift.from, action.startDate, action.endDate) &&
            isWithinRange(shift.to, action.startDate, action.endDate)
        );
        break;
      case 'APPLY_TEMPLATE_SUCCESS': {
        const guids = action.result.map((shift) => shift.guid);
        applyMinimalChangesToArray(draft.shifts, action.result, (shift) =>
          guids.some(
            (requestedShift) => requestedShift && requestedShift.guid === shift.guid && shouldShiftBeDraft(shift)
          )
        );
        break;
      }
      case 'TRANSFORM_SHIFTS_DRAFT_SUCCESS': {
        const requestedShifts = action.values.map((value) => value[0]);
        const newShifts = action.result.filter(
          (shift) =>
            !requestedShifts.some(
              (requestedShift) => requestedShift && requestedShift.guid === shift.guid && shouldShiftBeDraft(shift)
            )
        );
        const doNotMatchAnyExistingShift = () => false;
        applyMinimalChangesToArray(draft.shifts, newShifts, doNotMatchAnyExistingShift);

        const updatedShifts = action.result.filter((shift) =>
          requestedShifts.some(
            (requestedShift) => requestedShift && requestedShift.guid === shift.guid && shouldShiftBeDraft(shift)
          )
        );
        applyMinimalChangesToArray(draft.shifts, updatedShifts, (shift) =>
          requestedShifts.some(
            (requestedShift) => requestedShift && requestedShift.guid === shift.guid && shouldShiftBeDraft(shift)
          )
        );
        break;
      }
      case 'PUBLISH_SHIFTS_SUCCESS': {
        // Remove all the drafts that are were released
        applyMinimalChangesToArray(draft.shifts, [], (shift) =>
          action.guids.some((requestGuid) => requestGuid === shift.guid)
        );
        // Update the state with the response shifts
        applyMinimalChangesToArray(draft.shifts, action.result, (shift) =>
          action.guids.some((requestedShift) => requestedShift.guid === shift.guid)
        );
        break;
      }
      case 'CREATE_OR_UPDATE_SHIFT_DRAFT_BEGIN':
      case 'CREATE_OR_UPDATE_SHIFT_DRAFT_SUCCESS':
        applyMinimalChangesToArray(
          draft.shifts,
          action.result,
          (shift) => shift.guid === action.guid && shouldShiftBeDraft(shift)
        );
        break;
      case 'CREATE_OR_UPDATE_SHIFT_DRAFT_COMMIT':
        applyMinimalChangesToArray(
          draft.shifts,
          action.result,
          (shift) =>
            (shift.guid === action.guid && shouldShiftBeDraft(shift)) || shift.transactionID === action.optimist.id
        );
        break;
      case 'COPY_SHIFTS_SUCCESS':
        applyMinimalChangesToArray(draft.shifts, action.result, (shift) => shift.guid === action.guid);
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the original object
    }
  });
