import React from 'react';
import Toggle from '../../../components/core/ToggleV1';
import { StyleSheet, css } from '../../../_external-deps/stylesheet';
import t from '../translate';

const TimeCategoryListEditActivation = ({ timeCategory, onSubmit }) => (
  <div className={css(styles.bottomSpacing)}>
    <Toggle
      onChange={() => onSubmit(!timeCategory.disabled)}
      checked={!timeCategory.disabled}
      placeholderRight={timeCategory.disabled ? t('disabledTimeCategory') : t('enabledTimeCategory')}
    />
  </div>
);

const styles = StyleSheet.create({
  bottomSpacing: {
    marginBottom: 15,
  },
});

export default TimeCategoryListEditActivation;
