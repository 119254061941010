import produce from 'immer';
import { array } from '../utils';

import { DEFAULT_AVATAR } from '../constants';

const { applyMinimalChangesToArray } = array;

const initialState = produce({ members: [] }, () => {});

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const crewMembersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LOAD_CREW_MEMBERS_SUCCESS':
        applyMinimalChangesToArray(
          draft.members,
          action.result.map(addAvatarPropertiesToMember),
          (member) =>
            member.crewId === action.crewId &&
            (action.userIds ? action.userIds.some((userId) => member.userId === userId) : true)
        );
        break;
      case 'UPDATE_MEMBER_SUCCESS':
        applyMinimalChangesToArray(
          draft.members,
          addAvatarPropertiesToMember(action.result),
          (member) => member.crewId === action.crewId && member.userId === action.userId
        );
        break;
      case 'MEMBER_AVATAR_UPDATED':
        draft.members
          .filter((member) => member.crewId === action.crewId && member.userId === action.userId)
          .forEach((member) => {
            member.avatarUrl = action.avatarUrl;
            member = { ...addAvatarPropertiesToMember(member) };
          });
        break;
      case 'SIGNOUT_USER_SUCCESS':
        return initialState;
      default:
      // nothing to do => immer returns the same object
    }
  });

// make sure to call this on each member you want to add to the store,
// so we can be sure these additional properties are always available
export const addAvatarPropertiesToMember = (member) => ({
  ...member,
  hasAvatar: !!member.avatarUrl,
  avatarUrl: member.avatarUrl ? member.avatarUrl : DEFAULT_AVATAR,
});
