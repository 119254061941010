import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '../../action-creators';
import { api, apiV3 } from '../../lib';
import * as selectors from '../../selectors';
import { collections } from '../../utils';
import { enterUserApp } from '../terminal-time-tracking/utils';

import Organism from './organism';
import { loadTimeTrackingInformationForMember } from '../terminal-pin-code/utils';

const { signOutUser, v3SignOutUser } = actions;
const { composeReverse } = collections;
const { selectCurrentUserId, selectSettingsForMemberOrCrew } = selectors;

const mapStateToProps = (state, props) => ({
  isSignedIn: selectCurrentUserId(state) === props.member.userId,
  crewSettings: selectSettingsForMemberOrCrew(state, props),
});

const mapDispatchToProps = (dispatch, props) => ({
  enterUserApp: enterUserApp(dispatch),
  loadTimeTrackingInformation: loadTimeTrackingInformationForMember(dispatch, props),
  signOutUser: (isSignedIn) => {
    props.setIsOpen(false);
    if (isSignedIn) {
      dispatch(signOutUser({ api, clearCache: false }));
      dispatch(v3SignOutUser({ apiV3 }));
    }
  },
});

const Terminal3ColumnMemberOverlayContainer = composeReverse(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Organism);

export default Terminal3ColumnMemberOverlayContainer;
