import { factory, sequence, trait } from 'stahlwerk';
import { ABSENCE_TYPES } from '../constants';

export const createAbsenceDayCount = factory({
  id: sequence(),
  crewId: sequence(),
  typeId: ABSENCE_TYPES.vacation,
  userId: sequence(),
  from: new Date(2100, 2, 20),
  to: new Date(2100, 2, 22),
  date: new Date(2100, 2, 21),
  month: 2,
  year: 2100,

  unknown: trait({
    typeId: ABSENCE_TYPES.unknown,
  }),
  vacation: trait({
    typeId: ABSENCE_TYPES.vacation,
  }),
  sickness: trait({
    typeId: ABSENCE_TYPES.sickness,
  }),
  compensatoryTimeOff: trait({
    typeId: ABSENCE_TYPES.compensatoryTimeOff,
  }),
  education: trait({
    typeId: ABSENCE_TYPES.education,
  }),
  other: trait({
    typeId: ABSENCE_TYPES.other,
  }),
});
