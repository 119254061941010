import { TIME_ACCOUNTS } from '../../../constants';

const isWorkingMembersColumn = (memberType) => memberType === `${TIME_ACCOUNTS.WORKING}`;

const setScrollable = (columnRef, isScrollable) => {
  const element = columnRef.current;
  // eslint-disable-next-line no-unused-expressions
  element.scrollHeight > element.clientHeight ? isScrollable(true) : isScrollable(false);
};

export { isWorkingMembersColumn, setScrollable };
