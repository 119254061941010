import { memoize } from 'redux-memoize';
import { dispatchAsyncFnStatusActions } from '../lib';

export const loadDayKinds = memoize(
  {},
  ({ api, crewId }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_DAY_KINDS',
        fnParams: { crewId },
        fn: (params) => api.dayKinds(params),
      })
);
