import React from 'react';
import OverlayMessage from '../../components/core/overlay-message';
import { dangerouslyTranslateNeverWithUserContent } from './translate';

class Offline extends React.Component {
  changeStatus = () => {
    this.forceUpdate();
  };
  componentDidMount() {
    window.addEventListener('online', this.changeStatus);
    window.addEventListener('offline', this.changeStatus);
  }

  componentWillUnmout() {
    window.removeEventListener('online', this.changeStatus);
    window.removeEventListener('offline', this.changeStatus);
  }

  render() {
    return (
      <OverlayMessage display={(window || {}).navigator && !window.navigator.onLine}>
        {dangerouslyTranslateNeverWithUserContent('offline')}
      </OverlayMessage>
    );
  }
}

export default Offline;
