import React, { useEffect, useState } from 'react';

import Button from '../../../components/core/button';
import Heading from '../../../components/core/heading';
import Icon from '../../../components/core/icon';
import Modal from '../../../components/core/modal';
import { COLORS, IDS_FOR_GOOGLE_TAG_MANAGER, THEMES } from '../../../constants';
import TimeCategorySelectionContainer from '../../../containers/time-category-selection';
import TimeCategoryTabsContainer from '../../../containers/time-category-tabs';

import { timeCategory as timeCategoryUtils } from '../../../utils';
import { css, StyleSheet } from '../../../_external-deps/stylesheet';
import t from '../translate';

const { getTimeCategoryNamesByIds } = timeCategoryUtils;

export const TimeCategoriesOverlay = (props) => {
  const {
    id,
    canSeeSecondTimeCategory,
    disabled,
    iconName,
    isTimeCategoryOverlayOpen,
    onChange,
    setIsTimeCategoryOverlayOpen,
    theme,
    timeCategories,
    isOnWorkingScreen,
  } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTimeCategory1Id, setSelectedTimeCategory1Id] = useState(props.selectedTimeCategory1Id);
  const [selectedTimeCategory2Id, setSelectedTimeCategory2Id] = useState(props.selectedTimeCategory2Id);
  const [timeCategoryNames, setTimeCategoryNames] = useState([]);

  useEffect(() => {
    updateTimeCategories();
    const timeCategoryNamesByIds = getTimeCategoryNamesByIds(
      timeCategories,
      props.selectedTimeCategory1Id,
      props.selectedTimeCategory2Id,
      35
    );
    setTimeCategoryNames(timeCategoryNamesByIds ? timeCategoryNamesByIds.split('/') : []);
  }, [props.selectedTimeCategory1Id, props.selectedTimeCategory2Id]);

  useEffect(() => {
    if (!canSeeSecondTimeCategory && isTimeCategoryOverlayOpen) {
      onClose();
    }
  }, [selectedTimeCategory1Id]);

  const styles = stylesWithThemes[theme];

  const styleForPinCodeTerminal = theme === THEMES.TURQUOISE_WHITE ? isOnWorkingScreen : false;

  const showSecondTab = timeCategories.some((category) => category.level === 2);

  const updateTimeCategories = () => {
    setSelectedTimeCategory1Id(props.selectedTimeCategory1Id);
    setSelectedTimeCategory2Id(props.selectedTimeCategory2Id);
  };

  const onCancel = () => {
    updateTimeCategories();
    setIsTimeCategoryOverlayOpen(false);
  };

  const onClose = () => {
    setIsTimeCategoryOverlayOpen(false);
    onChange(selectedTimeCategory1Id, selectedTimeCategory2Id);
    setSelectedTabIndex(0);
  };

  const onOpen = () => {
    updateTimeCategories();
    setIsTimeCategoryOverlayOpen(!isTimeCategoryOverlayOpen);
  };

  const onFormChange = (level) => {
    setSelectedTabIndex(level === 1 ? level : selectedTabIndex);
  };

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.centralizedDiv)}>
        <Icon
          name={iconName}
          theme={theme.toLowerCase()}
          className={css(styles.icon, disabled && styles.disabled, !styleForPinCodeTerminal && styles.iconNotWorking)}
        />
        <Button
          className={css(styles.timeCategoryButton)}
          data-e2e-test={props['data-e2e-test']}
          disabled={disabled}
          displayType={theme.toLowerCase()}
          id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.TIME_CATEGORIES.OPEN}
          inline
          narrow={styleForPinCodeTerminal ? undefined : true}
          onClick={onOpen}
          spacingBottom
          style={{ textTransform: 'none', paddingTop: 10 }}
        >
          {timeCategoryNames.map((text, index) => (
            <div key={index}>
              {text} {index === 0 && timeCategoryNames.length === 2 && ' / '}
            </div>
          ))}
        </Button>
      </div>

      <Modal isOpen={isTimeCategoryOverlayOpen} className={css(styles.modal)} padding onClose={onCancel}>
        <Heading className={css(styles.header)}>{t('header')}</Heading>

        <TimeCategoryTabsContainer
          {...props}
          Component={TimeCategorySelectionContainer}
          customTabName
          internalComponentStyles={
            canSeeSecondTimeCategory ? styles.internalComponentStyles : styles.internalComponentStylesExtended
          }
          onFormChange={onFormChange}
          onTabChange={setSelectedTabIndex}
          selectedTabIndex={selectedTabIndex}
          selectedTimeCategory1Id={selectedTimeCategory1Id}
          selectedTimeCategory2Id={selectedTimeCategory2Id}
          setSelectedTimeCategory1Id={setSelectedTimeCategory1Id}
          setSelectedTimeCategory2Id={setSelectedTimeCategory2Id}
          showSecondTab={showSecondTab}
          tabContainerFullWidth
          withSearchBar
        />

        <div className={css(styles.buttonContainer)}>
          <Button
            id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.TIME_CATEGORIES.CANCEL}
            inline
            narrow
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
          {canSeeSecondTimeCategory && (
            <Button
              id={id + IDS_FOR_GOOGLE_TAG_MANAGER.STAMP_WATCH.TIME_CATEGORIES.SAVE}
              className={css(styles.saveButton)}
              inline
              onClick={onClose}
            >
              {t('save')}
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};

const stylesWithThemes = {
  [THEMES.ORANGE]: StyleSheet.create({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    centralizedDiv: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '31px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    disabled: {
      color: COLORS.MAIN_COLOR_MEDIUM_LITE,
      cursor: 'not-allowed',
    },
    header: {
      fontSize: '22px',
      margin: '0 0 28px 20px !important',
    },
    icon: {
      color: COLORS.ORANGE_MAIN,
      display: 'block',
      fontSize: '25px',
      position: 'relative',
    },
    internalComponentStyles: {
      maxHeight: '384px',
      overflowY: 'scroll',
    },
    internalComponentStylesExtended: {
      maxHeight: '476px',
      overflowY: 'scroll',
    },
    modal: {
      height: 'fit-content',
      width: '500px',
    },
    saveButton: {
      marginLeft: '18px',
    },
    timeCategoryButton: {
      flexDirection: 'column',
      height: 'fit-content',
    },
  }),
  [THEMES.TURQUOISE]: StyleSheet.create({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      color: COLORS.RED_MAIN,
      background: COLORS.RED_LIGHT,
    },
    centralizedDiv: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '31px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    disabled: {
      color: COLORS.TURQUOISE_OPAQUE_3,
      cursor: 'not-allowed',
    },
    header: {
      fontSize: '22px',
      margin: '0 0 28px 20px !important',
    },
    icon: {
      color: COLORS.TURQUOISE_MAIN,
      display: 'block',
      fontSize: '25px',
      position: 'relative',
    },
    internalComponentStyles: {
      maxHeight: '384px',
      overflowY: 'scroll',
    },
    internalComponentStylesExtended: {
      maxHeight: '476px',
      overflowY: 'scroll',
    },
    modal: {
      height: 'fit-content',
      width: '500px',
    },
    saveButton: {
      marginLeft: '18px',
    },
    timeCategoryButton: {
      flexDirection: 'column',
      height: 'fit-content',
    },
  }),
  [THEMES.TURQUOISE_WHITE]: StyleSheet.create({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      color: COLORS.RED_MAIN,
      background: COLORS.RED_LIGHT,
    },
    centralizedDiv: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '31px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    disabled: {
      color: COLORS.MAIN_COLOR_MEDIUM_LITE,
      cursor: 'not-allowed',
    },
    header: {
      fontSize: '22px',
      margin: '0 0 28px 20px !important',
    },
    icon: {
      color: COLORS.WHITE,
      display: 'block',
      fontSize: '25px',
      position: 'relative',
    },
    iconNotWorking: {
      color: COLORS.TURQUOISE_MAIN,
    },
    internalComponentStyles: {
      maxHeight: '384px',
      overflowY: 'scroll',
    },
    internalComponentStylesExtended: {
      maxHeight: '476px',
      overflowY: 'scroll',
    },
    modal: {
      height: 'fit-content',
      width: '500px',
    },
    saveButton: {
      marginLeft: '18px',
    },
    timeCategoryButton: {
      flexDirection: 'column',
      height: 'fit-content',
    },
  }),
};

export default TimeCategoriesOverlay;
