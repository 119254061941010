/* eslint sort-keys: ["error", "asc", {caseSensitive: false}] */
import { buildScopedTranslationFunction } from '../../lib/translate';

const translate = buildScopedTranslationFunction({
  de: {
    heading: 'Neues Passwort setzen',
    invalidToken: `
      Dieser Link zum Passwort zurücksetzen ist nicht mehr aktuell.
      Bitte öffnen Sie den Link in der neuesten Email zum Passwort zurücksetzen die Sie von Crewmeister erhalten haben.
    `,
    password: 'Passwort',
    passwordConfirmation: 'Passwort Bestätigung',
    passwordUpdatedError: `
      Das Formular ist noch nicht korrekt ausgefüllt.
      Bitte überprüfen Sie die markierten Felder.
    `,
    passwordUpdatedSuccess: 'Neues Passwort gespeichert. Bitte versuchen Sie sich jetzt damit anzumelden.',
    submit: 'Passwort zurücksetzen',
  },
  en: {
    heading: 'Set new password',
    invalidToken: `
      This link is outdated.
      Please open the link in the latest email you have received from Crewmeister to recover your password.
    `,
    password: 'Password',
    passwordConfirmation: 'Password Confirmation',
    passwordUpdatedError: 'The form has not yet been filled out correctly. Please check the marked fields.',
    passwordUpdatedSuccess: 'Password was changed successfully',
    submit: 'Reset password',
  },
});

export default translate;
